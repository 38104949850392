import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { mailbox } from '../../../shared/utils/constants';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
};

const AttachmentsDropzone = ({ name, children }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: mailbox.ATTACHMENT_MAX_SIZE,
    onDrop: (files) =>
      setFieldValue(name, [
        ...values[name],
        ...files.map((f) => ({
          file: f,
          id: uuidv4(),
          isEntityFile: false,
          isForwardAttachment: false,
        })),
      ]),
  });

  return (
    <div
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      className="relative flex shrink-0 grow flex-col overflow-auto p-4"
    >
      <input {...getInputProps()} />
      <div
        className={classnames(
          'absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-gray-200 transition-opacity duration-150 dark:bg-gray-700',
          isDragActive ? 'z-50 opacity-90' : 'z-[-1] opacity-0',
        )}
      >
        <FontAwesomeIcon icon={['fad', 'arrow-down-to-square']} size="5x" />
        <div className="mt-2 text-sm">
          {t('common:dropType', { type: t('common:attachment').toLowerCase() })}
        </div>
      </div>
      {children}
    </div>
  );
};

AttachmentsDropzone.propTypes = propTypes;

export default AttachmentsDropzone;
