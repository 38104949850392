import { getIn, useFormikContext } from 'formik';

import DatePicker, { DatePickerProps } from '../DatePicker';

type CallbackProps =
  | {
      clearable: true;
      callback?: (date: Date | null, inputValue: string | null, cleared: boolean) => void;
    }
  | {
      clearable?: false;
      callback?: (date: Date, inputValue: string, cleared: boolean) => void;
    };

type Props = Omit<
  DatePickerProps,
  'value' | 'onChange' | 'error' | 'inputCallbacks' | 'clearable'
> & {
  name: string;
} & CallbackProps;

const FormikDatePicker: React.FC<Props> = ({ clearable, callback, name, ...rest }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const value = getIn(values, name);
  const error = getIn(touched, name) ? getIn(errors, name) : undefined;

  return (
    <DatePicker
      clearable={clearable}
      value={value}
      error={error}
      onChange={(date: Date | null, dateString: string | null, cleared: boolean) => {
        if (cleared) {
          setFieldTouched(name);
        }

        setFieldValue(name, dateString);

        if (clearable) {
          callback?.(date, dateString, cleared);
        } else if (date && dateString) {
          callback?.(date, dateString, cleared);
        }
      }}
      inputCallbacks={{
        onBlur: () => setFieldTouched(name),
        onChange: () => setFieldTouched(name),
      }}
      {...rest}
    />
  );
};

export default FormikDatePicker;
