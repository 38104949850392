import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useCreateTask, useUpdateTask } from '../../../mutations';
import CasePropTypes from '@/shared/prop-types/CasePropTypes';
import { sentencize } from '@/shared/utils/helpers';
import TaskPropTypes from '../../../shared/prop-types/TaskPropTypes';
import { useGetCase } from '@/queries';

import KpcModal from '../../../shared/components/2.0/layout/KpcModal';
import TaskCreator from '../../../shared/components/tasks/TaskCreator';
import { TaskIcon } from '../../../shared/icons/Icons';

const propTypes = {
  caseId: PropTypes.string,
  currentCase: CasePropTypes,
  hideStatus: PropTypes.bool,
  initialTask: TaskPropTypes,
  toggle: PropTypes.shape({
    hide: PropTypes.func.isRequired,
  }),
};

const AddEditTaskModal = ({
  caseId,
  currentCase: currentCaseFromContext,
  toggle,
  initialTask,
  hideStatus = false,
}) => {
  const { t } = useTranslation();
  const { caseId: paramsCaseId } = useParams();
  const { currentCase: fetchedCase } = useGetCase(caseId);

  const currentCase = useMemo(
    () => currentCaseFromContext || fetchedCase,
    [currentCaseFromContext, fetchedCase],
  );

  const { updateTask, isUpdatingTask } = useUpdateTask({
    callback: () => {
      toggle.hide();
    },
    caseId: paramsCaseId,
  });
  const { createTask, isCreatingTask } = useCreateTask({
    callback: () => {
      toggle.hide();
    },
    caseId: paramsCaseId,
  });

  const handleSubmit = (task, caseStatus) => {
    if (isCreatingTask || isUpdatingTask) return;
    const apiData = { ...task, caseId: currentCase?.id, caseStatus };

    if (initialTask) {
      updateTask(apiData);
    } else {
      createTask(apiData);
    }
  };

  return (
    <KpcModal
      className="w-1/2 overflow-x-visible overflow-y-visible"
      title={sentencize(t(!initialTask ? 'addType' : 'editType', { type: t('task') }))}
      toggle={toggle}
      icon={<TaskIcon />}
    >
      <TaskCreator
        hideStatus={hideStatus}
        currentCase={currentCase}
        showSubmit
        handleSubmit={handleSubmit}
        onCancelAvailable
        onCancel={toggle.hide}
        initialTask={initialTask}
      />
    </KpcModal>
  );
};

AddEditTaskModal.propTypes = propTypes;

export default AddEditTaskModal;
