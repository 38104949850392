import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KpcTooltip from './Tooltips/KpcTooltip';

const IconCheckbox = ({ id, checkedIcon, uncheckedIcon, onClick, isChecked, tooltipText }) => {
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    if (isChecked == null) {
      return;
    }
    setChecked(isChecked);
  }, [isChecked]);

  const setValues = () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    onClick(newCheckedValue);
  };

  return (
    <label
      htmlFor={id}
      style={{ display: 'inline-block', transition: 'all .3s', cursor: 'pointer' }}
    >
      <input
        id={id}
        style={{ opacity: 0, display: 'inline-block' }}
        type="checkbox"
        onChange={setValues}
        checked={checked}
      />
      {checked ? (
        <KpcTooltip title={tooltipText} key={id}>
          <span className="checked-icon">{checkedIcon}</span>
        </KpcTooltip>
      ) : (
        <KpcTooltip title={tooltipText} key={id}>
          <span className="unchecked-icon">{uncheckedIcon}</span>
        </KpcTooltip>
      )}
    </label>
  );
};

export default IconCheckbox;

IconCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checkedIcon: PropTypes.node.isRequired,
  uncheckedIcon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string.isRequired,
};
