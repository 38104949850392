import dayjs, { Dayjs } from 'dayjs';
import { EventImpl } from '@fullcalendar/core/internal';

import { AddEditAppointmentFormikValues } from '@/containers/Calendars/Components/FormikValues';
import { Event } from '~/common/types/calendars/events';
import { RouterOutputs } from '@/config/trpc';

type LinkedCalendarAutoComplete = RouterOutputs['calendar']['getLinkedAutoComplete'][number];

export const formatAppointmentForCalendar = (app: Event, currentEmail: string) => {
  const { caseId, title, description, id, when } = app;
  const { from, until, allDay } = when;

  const dateFormat = allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm';

  return {
    allDay,
    caseId,
    description,
    editable: app.organizer.email === currentEmail,
    end: dayjs.utc(until).local().format(dateFormat),
    id,
    start: dayjs.utc(from).local().format(dateFormat),
    title,
  };
};

const formatCalendarDate = (date: Dayjs, allDay?: boolean) =>
  date.utc(allDay).format(`YYYY-MM-DD${allDay ? '' : 'THH:mm:ss'}`);

export const formatCalendarWhen = (event: EventImpl) => {
  const { startStr, endStr, allDay } = event;
  const start = dayjs(startStr);
  const end = dayjs(endStr);

  let endTime = null;

  if (allDay && end.diff(start, 'days') > 1) {
    endTime = formatCalendarDate(end, allDay);
  } else if (!allDay && !endStr) {
    const tmp = start.clone();
    endTime = formatCalendarDate(tmp.add(1, 'h'));
  } else if (!allDay) {
    endTime = formatCalendarDate(end, allDay);
  }

  return {
    allDay,
    endTime,
    startTime: formatCalendarDate(start, allDay),
  };
};

export const getDefaultCalendar = (linkedCalendars: LinkedCalendarAutoComplete[]) => {
  if (linkedCalendars.length === 0) return null;

  const personal = linkedCalendars.find((c) => c.isDefault && !c.isTeamCalendar);
  return personal ?? linkedCalendars.find((c) => c.isDefault);
};

export const formDataToApiData = (formData: AddEditAppointmentFormikValues) => {
  const { start, end, allDay, ...rest } = formData;

  return {
    ...rest,
    linkedCalendarId: formData.linkedCalendarId as string,
    title: formData.title as string,
    when: {
      allDay,
      endTime: end,
      startTime: start as string,
    },
  };
};
