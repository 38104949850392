import { Autocomplete, Skeleton, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetBuildings } from '../../../../queries';

const propTypes = {
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
};

const BuildingFilter = ({ callback = () => {}, name = '', defaultValue = [], label = null }) => {
  const { t } = useTranslation('common');
  const { buildings, buildingsIsLoading } = useGetBuildings();

  const getOptions = useCallback(
    (value) => `${value.name} (${value.address.addressLine}, ${value.address.city})`,
    [],
  );

  return buildingsIsLoading ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={buildings}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={getOptions}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:buildings')} />
      )}
    />
  );
};

BuildingFilter.propTypes = propTypes;

export default BuildingFilter;
