import { FormControl, FormHelperText, FormLabel, IconButton, Input } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useFormikContext } from 'formik';

import { getMomentObject } from '../utils/helpers';

type Props = {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
};

const FormikYearPicker: React.FC<Props> = ({
  name,
  label,
  className,
  required = false,
  ...rest
}) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{
    [name: string]: unknown;
  }>();

  return (
    <MobileDatePicker
      {...rest}
      views={['year']}
      openTo="year"
      inputFormat="YYYY"
      onChange={(val) => setFieldValue(name, val == null ? null : val.format('YYYY'))}
      value={getMomentObject(values[name], 'YYYY')}
      renderInput={(params) => (
        <FormControl required={required} error={!!errors[name] && touched[name]}>
          {label && <FormLabel>{label}</FormLabel>}
          {/* @ts-ignore */}
          <Input
            {...params.inputProps}
            className={className}
            fullWidth
            onBlur={() => setFieldTouched(name, true)}
            endDecorator={
              values[name] ? (
                <IconButton
                  variant="plain"
                  color="neutral"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFieldValue(name, null);
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'xmark']} />
                </IconButton>
              ) : null
            }
          />
          {touched[name] && errors[name] && (
            <FormHelperText>{touched[name] && errors[name]}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormikYearPicker;
