import { getIn, useFormikContext } from 'formik';
import { IconButton, Input, InputProps } from '@mui/joy';
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers';
import { useEffect, useRef, useState } from 'react';

import { Dayjs } from 'dayjs';

import useDayjs from '@/shared/hooks/useDayjs';

import { ClearIcon } from '@/shared/icons/Icons';
import FormField from '../input/FormField';

type Props = {
  label: string;
  name: string;
  required?: boolean;
} & Omit<MobileDateTimePickerProps<Dayjs, Dayjs>, 'onChange' | 'value' | 'renderInput'>;

const FormikDateTimePicker: React.FC<Props> = ({ name, required, label, ...rest }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const { dayjs } = useDayjs();

  const isSettingDate = useRef(false);

  const value = getIn(values, name);

  const [inputValue, setInputValue] = useState<Dayjs | null>(
    value ? dayjs.utc(value).local() : null,
  );

  const error = getIn(touched, name) ? getIn(errors, name) : '';

  useEffect(() => {
    if (!value || isSettingDate.current) return;

    const newInputValue = dayjs.utc(value).local();

    if (!inputValue || !newInputValue.isSame(inputValue)) {
      setInputValue(newInputValue);
    }
  }, [dayjs, inputValue, value]);

  return (
    <MobileDateTimePicker
      {...rest}
      ampm={false}
      onAccept={(newValue) => {
        setFieldValue(name, newValue?.utc().toISOString());
      }}
      onOpen={() => {
        isSettingDate.current = true;
      }}
      onClose={() => {
        isSettingDate.current = false;
      }}
      onChange={(newInputValue) => setInputValue(newInputValue)}
      value={inputValue}
      renderInput={(params) => (
        <FormField label={label} error={error} required={required}>
          <Input
            {...(params.inputProps as InputProps)}
            ref={params.inputRef}
            fullWidth
            onBlur={() => setFieldTouched(name, true)}
            endDecorator={
              !!value && (
                <IconButton
                  variant="plain"
                  color="neutral"
                  className="rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setInputValue(null);
                    setFieldValue(name, null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          />
        </FormField>
      )}
    />
  );
};

export default FormikDateTimePicker;
