import React from 'react';

type Props = {
  className?: string;
  fileData: string;
  onLoad?: () => void;
};

const TXTRenderer: React.FC<Props> = ({ fileData, onLoad, className }) => {
  onLoad?.();

  return <span className={className}>{fileData}</span>;
};

export default TXTRenderer;
