import PropTypes from 'prop-types';

const FilePropTypes = PropTypes.shape({
  id: PropTypes.string,
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
  contentType: PropTypes.string,
  uploadDate: PropTypes.string,
  uploadedBy: PropTypes.string,
  categoryDescription: PropTypes.string,
  isPublic: PropTypes.bool,
  seen: PropTypes.bool,
  readOnly: PropTypes.bool,
});

export default FilePropTypes;
