import { CircularProgress } from '@mui/joy';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { transformHtml } from '../../../shared/utils/helpers';
import { useEmailBodyByBodyUrl } from '../../../queries';

const propTypes = {
  bodyUrl: PropTypes.string,
  className: PropTypes.string,
};

const EmailLocalBodyPreview = ({ bodyUrl, className = '' }) => {
  const { data: body, isLoading: bodyLoading } = useEmailBodyByBodyUrl(bodyUrl, {
    enabled: !!bodyUrl,
    select: transformHtml,
  });

  if (!bodyUrl) {
    return null;
  }

  return (
    <div className={classnames('mt-4 flex flex-col overflow-hidden', className)}>
      {bodyLoading ? (
        <div className="my-6 text-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="scrollbar-mailbox flex overflow-auto whitespace-pre-line">
          <div className="email-body min-w-0">{body}</div>
        </div>
      )}
    </div>
  );
};

EmailLocalBodyPreview.propTypes = propTypes;

export default EmailLocalBodyPreview;
