import { memo, useContext } from 'react';
import { MenuItem } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { checkFileCanBePreviewed, imageFileTypes } from '@/shared/utils/helpers';
import downloadFile from '@/shared/utils/fileHelpers';

import DeleteAction from '@/shared/components/DeleteAction';
import DocumentAccessibility from '../../documents/DocumentAccessibility';
import DropDownMore from '@/shared/components/2.0/DropDownMore';
import EditRowCell from '@/shared/components/2.0/customTableCells/EditRowCell';
import { FileContext } from '@/FileContext';

type RowData = {
  canHandleAccess: string;
  contentType: string;
  readOnly: boolean;
  rowId: string;
  id: string;
  fileName: string;
  fileUri: string;
  extension: string;
};

type Props = {
  canEdit: boolean;
  rowData: RowData;
  isSpreadsheetFiles: boolean;
  openDocumentPreview: (rowId: string) => void;
  onDeleteFileCallback: (rowId: string) => void;
  value: string;
  toggles: {
    accessibility: {
      show: () => void;
    };
    create: {
      show: () => void;
    };
    edit: {
      show: () => void;
      value: boolean;
    };
  };
  setSelectedId: (id: string | null) => void;
  setSelectedItems: (ids: RowData[]) => void;
};

const DocumentIdCustomCell: React.FC<Props> = ({
  canEdit,
  rowData,
  isSpreadsheetFiles,
  openDocumentPreview,
  onDeleteFileCallback,
  value,
  toggles,
  ...rest
}) => {
  const { readOnly, canHandleAccess } = rowData;
  const { t } = useTranslation();
  const { files, deleteFile, currentTeamCanDeleteOverride } = useContext(FileContext);
  const isFilePreviewable = checkFileCanBePreviewed(rowData.contentType);
  const { setSelectedId, setSelectedItems } = rest;

  // @ts-ignore
  if (!files.some((f) => f.id === value)) {
    return null;
  }

  if (toggles.edit?.value) {
    // @ts-ignore
    return readOnly ? null : <EditRowCell rowData={rowData} toggles={toggles} {...rest} />;
  }

  return (
    <div className="flex justify-end">
      {canHandleAccess && (
        <DocumentAccessibility
          // @ts-ignore
          file={files.find((f) => f.id === value)}
        />
      )}
      <DropDownMore>
        {canHandleAccess && (
          <MenuItem
            onClick={() => {
              setSelectedItems([rowData]);
              toggles.accessibility.show();
            }}
          >
            {t('accessibility')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            downloadFile({
              contentType: rowData.contentType,
              extension: rowData.extension,
              fileName: rowData.fileName,
              fileUri: rowData.fileUri,
              id: rowData.id,
            })
          }
        >
          {t('download')}
        </MenuItem>
        {isFilePreviewable && (
          <MenuItem onClick={() => openDocumentPreview(value)}>{t('preview')}</MenuItem>
        )}
        {!readOnly && canEdit && (
          <MenuItem
            onClick={() => {
              setSelectedId(rowData.rowId);
              toggles.create.show();
            }}
          >
            {t('edit')}
          </MenuItem>
        )}
        {canEdit &&
          (!readOnly || currentTeamCanDeleteOverride) &&
          ((isSpreadsheetFiles && imageFileTypes.includes(rowData.contentType)) ||
            !isSpreadsheetFiles) && (
            <DeleteAction
              dropdown
              onDelete={() => {
                // @ts-ignore
                deleteFile(value);
                onDeleteFileCallback(value);
              }}
            />
          )}
      </DropDownMore>
    </div>
  );
};

export default memo(DocumentIdCustomCell);
