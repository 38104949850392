import { IconButton } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PinIcon } from '../../../../../../shared/icons/Icons';
import Tooltip from '../../../../../../shared/components/2.0/Tooltip';

const TimelinePinAction = ({ id, isPinned, updateIsPinned }) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={isPinned ? t('common:unpin') : t('common:pin')}>
      <IconButton
        variant="plain"
        color={isPinned ? 'primary' : 'neutral'}
        onClick={() => {
          updateIsPinned(id, !isPinned);
        }}
      >
        <PinIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TimelinePinAction;

TimelinePinAction.propTypes = {
  id: PropTypes.string.isRequired,
  isPinned: PropTypes.bool.isRequired,
  updateIsPinned: PropTypes.func.isRequired,
};
