import { useAsync, UseAsyncReturn } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { useState } from 'react';

interface UseDebouncedSearchResult {
  inputText: string;
  searchResults: UseAsyncReturn<
    void | (string | ((input: string) => Promise<never>))[],
    (
      | string
      | ((input: string) => void | Promise<(string | ((input: string) => Promise<never>))[]>)
    )[]
  >;
  setInputText: (value: string) => void;
}

function useDebouncedSearch(
  searchFunction: (
    input: string,
  ) => Promise<(((input: string) => Promise<never>) | string)[]> | void,
  delay: number,
): UseDebouncedSearchResult {
  // Handle the input text state
  const [inputText, setInputText] = useState('');

  // Debounce the original search async function: only compute this value once
  const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(searchFunction, delay));

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  const searchResults = useAsync(
    async () => debouncedSearchFunction(inputText),
    [debouncedSearchFunction, inputText],
  );

  // Return everything needed for the hook consumer
  return {
    inputText,
    searchResults,
    setInputText,
  };
}

export default useDebouncedSearch;
