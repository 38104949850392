import * as ibantools from 'ibantools';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@mui/joy';

import FormField, { FieldProps } from '@/shared/components/2.0/input/FormField';

import {
  AtBicValue,
  BeBicValue,
  BicValueKey,
  ChBicValue,
  DeBicValue,
  EsBicValue,
  FrBicValue,
  LiBicValue,
  LuBicValue,
  McBicValue,
  NlBicValue,
} from '~/common/enums/bicValues';
import { Nullable } from '~/common/types';

import { removeWhitespaces } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';

type BicValueOption = {
  label: string;
  value: BicValueKey;
};

type Props =
  {
    iban?: Nullable<string>;
    value: Nullable<BicValueKey>;
    onChange: (value: Nullable<BicValueKey>) => void;
    disabled?: boolean;
  }
  & FieldProps;

const SelectBic: React.FC<Props> = ({ disabled, iban, onChange, value, ...fieldProps }) => {
  const [selectedValue, setSelectedValue] = useState<Nullable<BicValueOption>>(null);

  const { data: ibanValidationResult } = trpc.finance.iban.validate.useQuery(
    iban as string,
    {
      enabled:
        !!iban &&
        ibantools.isValidIBAN(removeWhitespaces(iban)),
    },
  );

  const ibanCountryCode = useMemo(() => {
    if (iban && iban.length >= 2) {
      return iban.substring(0, 2).toUpperCase();
    }
    setSelectedValue(null);
    return null;
  }, [iban]);

  const bicValueOptions = useMemo(() => {
    switch (ibanCountryCode) {
      case "AT":
        return Object.values(AtBicValue);
      case "BE":
        return Object.values(BeBicValue);
      case "CH":
        return Object.values(ChBicValue);
      case "DE":
        return Object.values(DeBicValue);
      case "ES":
        return Object.values(EsBicValue);
      case "FR":
        return Object.values(FrBicValue);
      case "LI":
        return Object.values(LiBicValue);
      case "LU":
        return Object.values(LuBicValue);
      case "MC":
        return Object.values(McBicValue);
      case "NL":
        return Object.values(NlBicValue);
      default:
        return [];
    }
  }, [ibanCountryCode]);

  const bicAutoCompleteOptions = useMemo(() => bicValueOptions.map((bicValue) => ({
    label: bicValue as string,
    value: bicValue,
  })).sort((a, b) => a.label.localeCompare(b.label)), [bicValueOptions]);

  useEffect(() => {
    if (value === selectedValue?.value) {
      return;
    }
    const bicValue = bicAutoCompleteOptions.find((option) => option.value === value);
    setSelectedValue(bicValue ?? null);
  }, [bicAutoCompleteOptions, value]);

  const handleChange = useCallback((option: Nullable<BicValueOption>) => {
    setSelectedValue(option);
    onChange((option?.value as Nullable<BicValueKey>) ?? null);
  }, []);

  useEffect(() => {
    if (!ibanValidationResult?.isValid) {
      return;
    }
    const newBicValue = bicAutoCompleteOptions.find((option) => option.value === ibanValidationResult.bic.bic);
    handleChange(newBicValue ?? null);
  }, [bicAutoCompleteOptions, bicValueOptions, handleChange, ibanValidationResult]);

  return (
    <FormField {...fieldProps}>
      <Autocomplete
        disabled={disabled || !bicAutoCompleteOptions.length}
        onChange={(_, newValue) => handleChange(newValue)}
        value={selectedValue}
        options={bicAutoCompleteOptions}
      />
    </FormField>
  );
};

export default SelectBic;
