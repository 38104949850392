import { useMutation, useQueryClient } from '@tanstack/react-query';

import caseApi from '../../shared/api/cases/case';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useUpdateCaseEntityInfo = () => {
  const queryClient = useQueryClient();
  const { sendDefaultError } = useNotification();

  const { mutate } = useMutation({
    mutationFn: async (data) => caseApi.putCaseEntityInfo(data),
    onError: async (err, { caseId }, { previousData }) => {
      queryClient.setQueryData([queryIds.cases.GET_CASE_ENTITIES, caseId], previousData);
      sendDefaultError(err);
    },
    onMutate: async ({ caseId, entityId, ...rest }) => {
      const queryKey = [queryIds.cases.GET_CASE_ENTITIES, caseId];
      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (oldData) =>
        oldData.map((entity) => {
          if (entity.id === entityId) {
            return { ...entity, ...rest };
          }
          return entity;
        }),
      );

      return { previousData };
    },
  });

  return mutate;
};

export default useUpdateCaseEntityInfo;
