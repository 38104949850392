import { useQuery } from '@tanstack/react-query';

import { buildingApi } from '../../shared/api';

import { Building } from '~/common/types/building';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

type Options = {
  buildingId?: string;
  callback?: (building: Building) => void;
  withCases?: boolean;
};

const useGetBuilding = (options: Options) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    enabled: !!options.buildingId,
    onError: (error) => {
      sendDefaultError(error);
    },
    onSuccess: (building) => {
      if (options.callback) {
        options.callback(building);
      }
    },
    queryFn: () =>
      buildingApi.getBuilding(options.buildingId!, {
        withCases: options?.withCases,
      }),
    queryKey: [queryIds.buildings.GET_BUILDING, options.buildingId, options?.withCases],
  });

  return {
    building: data,
    buildingIsFetching: isFetching,
    buildingIsLoading: isLoading,
  };
};

export default useGetBuilding;
