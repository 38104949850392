import { Attributes, createElement, useMemo } from 'react';
import { Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { ColumnProps, HeadCellProps } from '@/shared/hooks/table/types';
import { Obj } from '~/common/types';

import useTruncated from '@/shared/hooks/useTruncated';

type Props = Obj &
  HeadCellProps & {
    type?: string | null;
    column: ColumnProps;
  } & Attributes;

const DefaultHeadCell: React.FC<Props> = (props) => {
  const { type = null, column } = props;

  const { childRef, containerRef, isTruncated } = useTruncated();
  const { t } = useTranslation();

  const label = useMemo(() => {
    const { title, key } = column;

    if (!type && !title) return '';

    return title ? t(title) : t(`_${type}.columns.${key}`);
  }, [column, t, type]);

  if (column.customHeaderComponent) {
    return createElement(column.customHeaderComponent, props);
  }

  return (
    <div ref={containerRef}>
      <div className="inline-block" ref={childRef}>
        {!isTruncated ? (
          <div className="inline-block whitespace-nowrap">{label}</div>
        ) : (
          <Tooltip className="whitespace-nowrap" placement="top" title={label}>
            <div className="inline-block">{label}</div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default {
  DefaultHeadCell,
};
