import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Nullable } from '~/common/types';

import { containsOnlyDigits } from '~/common/utils/utils';
import { trpc } from '@/config/trpc';

export const CODE_BELGIUM = 'BE';
export const LENGTH_BELGIAN_NUMBER = 10;
export const PREFIX_LENGTH = 2;
export const FORBIDDEN_CHARS = ['\\.', '\\-', '\\/', ' '];

// Iceland has 5-6 chars
const MIN_LENGTH_NUMBER = 5;
// China has 18 digits
const MAX_LENGTH_NUMBER = 18;

enum ErrorType {
  Schema = 'Schema',
  Company = 'Company',
}

type Error = {
  message: string;
  type: ErrorType;
};

type Input = {
  countryCode: Nullable<string>;
  vatNumber: Nullable<string>;
};

type ValidationResult = {
  errors: Error[];
  foundCompany: Nullable<string>;
  validAgainstCompany: Nullable<boolean>;
  validAgainstSchema: boolean;
};

const useSchema = (required = false) => {
  const { t } = useTranslation('errors');

  const errorMap: z.ZodErrorMap = (error, ctx) => {
    let message: string | undefined;

    switch (error.code) {
      case z.ZodIssueCode.invalid_type:
        if (error.expected !== 'string') {
          break;
        }

        if (error.path[0] === 'countryCode') {
          message = t('vat.noCode');
        }
        if (error.path[0] === 'vatNumber') {
          message = t('vat.noNumber');
        }
        break;
      default:
        message = error.message;
    }

    return { message: message || ctx.defaultError };
  };

  const countryCodeSchema = z.string().length(PREFIX_LENGTH, t('vat.noCountryCode'));

  const vatNumberSchemas = {
    belgium: z
      .string()
      .length(LENGTH_BELGIAN_NUMBER, t('vat.belgium.invalidLength'))
      .refine((value) => !value || containsOnlyDigits(value), {
        message: t('vat.belgium.onlyDigitsAllowed'),
      }),
    international: z
      .string()
      .min(MIN_LENGTH_NUMBER, t('vat.international.tooShort'))
      .max(MAX_LENGTH_NUMBER, t('vat.international.tooLong'))
      .refine((value) => !FORBIDDEN_CHARS.some((char) => value.includes(char)), {
        message: t('vat.international.noDots'),
      }),
  };

  const baseSchema = z.object({
    countryCode: required ? countryCodeSchema : countryCodeSchema.nullable(),
  });

  const belgianSchema = baseSchema
    .extend({
      vatNumber: required ? vatNumberSchemas.belgium : vatNumberSchemas.belgium.nullable(),
    })
    .refine(({ vatNumber, countryCode }) => !!countryCode || !vatNumber, {
      message: t('vat.noCode'),
    });

  const internationalSchema = baseSchema
    .extend({
      vatNumber: required
        ? vatNumberSchemas.international
        : vatNumberSchemas.international.nullable(),
    })
    .refine(({ vatNumber, countryCode }) => !!countryCode || !vatNumber, {
      message: t('vat.noCode'),
    })
    .refine(
      ({ vatNumber, countryCode }) => {
        if (!vatNumber || !countryCode) {
          return true;
        }

        return (
          vatNumber.substring(0, PREFIX_LENGTH).toLocaleLowerCase() !==
          countryCode.toLocaleLowerCase()
        );
      },
      {
        message: t('vat.international.duplicateCountryCode'),
      },
    );

  return { belgianSchema, errorMap, internationalSchema };
};

const mergeErrors = (errors: z.typeToFlattenedError<Input, string>) => {
  const { fieldErrors, formErrors } = errors;

  const mergedErrors = Object.values(fieldErrors)
    .flatMap((error) => error)
    .concat(formErrors);

  return mergedErrors.map((error) => ({
    message: error,
    type: ErrorType.Schema,
  }));
};

const initialValidationResult = {
  errors: [],
  foundCompany: null,
  validAgainstCompany: null,
  validAgainstSchema: true,
};

const useValidation = (input: Input, options = { required: false }) => {
  const { required } = options;

  const [validationResult, setValidationResult] =
    useState<ValidationResult>(initialValidationResult);
  const [hasValidated, setHasValidated] = useState(false);

  const { countryCode, vatNumber } = input;

  const { data: vat } = trpc.company.vat.useQuery(
    {
      countryCode: countryCode || '',
      vatNumber: vatNumber || '',
    },
    {
      enabled: !!countryCode && !!vatNumber && hasValidated && validationResult.validAgainstSchema,
    },
  );
  const { belgianSchema, internationalSchema, errorMap } = useSchema(required);
  const { t } = useTranslation('errors');

  const removeErrors = (errors: Error[], type: ErrorType) =>
    errors.filter((error) => error.type !== type);

  const resetCompanyValidation = () => {
    setValidationResult((prevResult) => ({
      ...prevResult,
      foundCompany: null,
      validAgainstCompany: null,
    }));
  };

  const checkForMatchingCompany = useCallback(() => {
    if (!vat || !hasValidated || !validationResult.validAgainstSchema) {
      return;
    }

    const { isValid, name } = vat;

    let foundCompany: Nullable<string> | undefined;
    const errors: Error[] = [];

    if (isValid) {
      foundCompany = name;
    } else {
      errors.push({ message: t('vat.notFound'), type: ErrorType.Company });
    }

    setValidationResult((prevResult) => ({
      ...prevResult,
      errors,
      foundCompany: foundCompany || null,
      validAgainstCompany: isValid,
    }));
  }, [vat, validationResult.validAgainstSchema, hasValidated, t]);

  const validateInput = () => {
    const isBelgianNumber = input.countryCode === CODE_BELGIUM;
    const schema = isBelgianNumber ? belgianSchema : internationalSchema;
    const schemaResult = schema.safeParse(input, { errorMap });
    const validAgainstSchema = schemaResult.success;

    let errors: Error[] = [];

    if (!validAgainstSchema) {
      const zodErrors = schemaResult.error.flatten();

      errors = mergeErrors(zodErrors);
    }

    checkForMatchingCompany();
    setValidationResult((prevResult) => ({
      ...prevResult,
      errors: validAgainstSchema ? removeErrors(prevResult.errors, ErrorType.Schema) : errors,
      validAgainstSchema,
    }));
    setHasValidated(true);
  };

  useEffect(() => checkForMatchingCompany(), [checkForMatchingCompany]);

  useEffect(() => {
    if (!hasValidated) {
      return;
    }

    resetCompanyValidation();
    validateInput();
  }, [countryCode, hasValidated]);

  return {
    validateInput,
    validationResult,
  };
};

export default useValidation;
