import { IconName, IconPrefix, SizeProp } from '@fortawesome/fontawesome-svg-core';
import React, { ButtonHTMLAttributes, FC, ReactNode, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import KpcTooltip from '../Tooltips/KpcTooltip';

type IconButtonProps = {
  icon?: IconName;
  iconComponent?: ReactNode;
  iconStyle?: IconPrefix;
  onClick?: () => void;
  tooltip?: string;
  color?: string;
  size?: SizeProp;
  className?: string;
  disabled?: boolean;
  submit?: boolean;
  noPointer?: boolean;
  position?: Record<string, string>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton: FC<IconButtonProps> = ({
  icon,
  iconComponent,
  iconStyle = 'fad',
  onClick = () => {},
  tooltip,
  color = 'primary',
  size = '1x',
  className = '',
  disabled = false,
  submit = false,
  noPointer = false,
  position,
  ...rest
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const fullClass = `icon__only ${className}`;
  const tooltipProps = {
    title: tooltip ?? '',
    ...(tooltip?.length ? tooltip?.length <= 0 && { open: false } : ''),
  };

  return (
    <KpcTooltip
      disableInteractive
      {...tooltipProps}
      style={{
        cursor: noPointer ? 'default' : 'pointer',
        ...position,
      }}
    >
      <span ref={ref} {...rest}>
        <button
          style={{
            opacity: disabled ? '0.4' : '1',
            pointerEvents: disabled ? 'none' : 'inherit',
          }}
          type={submit ? 'submit' : 'button'}
          disabled={disabled}
          onClick={onClick}
          className={fullClass}
        >
          {iconComponent &&
            React.cloneElement(iconComponent as React.ReactElement, { color, size })}
          {!iconComponent && icon && (
            <FontAwesomeIcon size={size} icon={[iconStyle, icon]} color={color} />
          )}
        </button>
      </span>
    </KpcTooltip>
  );
};

export default IconButton;
