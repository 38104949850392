import { Button } from '@mui/joy';
import styled from 'styled-components';
import { colorAccent, colorAdditional, colorPrimary } from '../../../../../../shared/utils/palette';

export const TypeName = styled.span`
  color: ${colorPrimary};
  font-weight: 500;
  font-size: 1.25rem;
`;

export const CreatedByField = styled.p`
  font-weight: 500;
`;

export const DescriptionField = styled.span`
  width: 120px;
  display: inline-block;
`;

export const InfoSection = styled.div`
  font-size: 0.8rem !important;
`;
