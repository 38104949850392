const CaseChannel = {
  Assistance: 'Assistance',
  Case: 'Case',
  Email: 'Email',
  Letter: 'Letter',
  Meeting: 'Meeting',
  OnSite: 'OnSite',
  Other: 'Other',
  Phone: 'Phone',
  QRScan: 'QRScan',
  Template: 'Template',
};

export default CaseChannel;
