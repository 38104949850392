import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption, Nullable } from '~/common/types';
import { CaseRole, DamageType } from '~/common/enums';
import { decapitalize, isNullOrEmpty, sentencize } from '@/shared/utils/helpers';
import { useGetCase, useGetCompany } from '@/queries';
import useGetFsmaCompaniesAutocomplete from '@/queries/fsma/useGetFsmaCompaniesAutocomplete';

type GeneralInformation = {
  description: string;
  insurerCentralCompany: Nullable<AutocompleteOption>;
  remark: Nullable<string>;
  damageType: Nullable<AutocompleteOption>;
};

export type GeneralInformationManager = {
  get: {
    damageTypeAutocomplete: AutocompleteOption[];
    fsmaAutocomplete: AutocompleteOption[];
    fsmaAutocompleteIsLoading: boolean;
  } & GeneralInformation;
  set: {
    damageType: (selectedDamageType: Nullable<AutocompleteOption>) => void;
    description: (description: string) => void;
    remark: (remark: Nullable<string>) => void;
    insurerCentralCompany: (insurerCentralCompany: Nullable<AutocompleteOption>) => void;
  };
  validate: () => boolean;
  isValid: Nullable<boolean>;
  errors: string[];
};

export const useGeneralInformationManager: (
  caseId: Nullable<string>,
) => GeneralInformationManager = (caseId) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<Nullable<boolean>>(null);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [insurerCompanyId, setInsurerCompanyId] = useState<Nullable<string>>(null);
  const [generalInformation, updateGeneralInformation] = useState<GeneralInformation>({
    damageType: null,
    description: '',
    insurerCentralCompany: null,
    remark: null,
  });

  const { currentCase, currentCaseIsLoading } = useGetCase(caseId);
  const { fsmaCompaniesAutocomplete, fsmaCompaniesAutocompleteIsLoading } =
    useGetFsmaCompaniesAutocomplete();
  const { company } = useGetCompany(insurerCompanyId);

  const updateInsurerCentralCompany = useCallback(
    (centralCompanyId: string) => {
      const insurerToSelect = fsmaCompaniesAutocomplete.find(
        (fsmaCompany) => fsmaCompany.value === centralCompanyId,
      );

      updateGeneralInformation((prev) => ({
        ...prev,
        insurerCentralCompany: insurerToSelect as AutocompleteOption,
      }));
    },
    [fsmaCompaniesAutocomplete],
  );

  useEffect(() => {
    if (initialized || currentCaseIsLoading || !currentCase || fsmaCompaniesAutocompleteIsLoading) {
      return;
    }

    setInitialized(true);

    updateGeneralInformation((prev) => ({
      ...prev,
      description: currentCase.description || '',
      remark: currentCase.remark || '',
    }));

    const possibleInsurers = currentCase?.relations
      ?.filter((relation) => relation.role === CaseRole.Insurer)
      ?.filter((relation) => relation.companyId || relation.teamCentralCompanyId);

    if (!possibleInsurers?.length) {
      return;
    }

    const insurerTeamCentralCompanyId = possibleInsurers.find(
      (relation) => relation.teamCentralCompanyId,
    )?.teamCentralCompanyId;

    if (insurerTeamCentralCompanyId) {
      updateInsurerCentralCompany(insurerTeamCentralCompanyId);
      return;
    }

    setInsurerCompanyId(possibleInsurers.find((relation) => relation.companyId)?.companyId || null);
  }, [
    currentCaseIsLoading,
    currentCase,
    fsmaCompaniesAutocompleteIsLoading,
    initialized,
    updateInsurerCentralCompany,
  ]);

  useEffect(() => {
    if (!company?.centralCompanyId) {
      return;
    }
    updateInsurerCentralCompany(company.centralCompanyId);
  }, [company?.centralCompanyId, updateInsurerCentralCompany]);

  const validate = useCallback(() => {
    const newErrors: string[] = [];
    if (isNullOrEmpty(generalInformation.description)) {
      newErrors.push(sentencize(t('errors:xIsRequired', { field: t('description') })));
    }
    if (!generalInformation.damageType) {
      newErrors.push(sentencize(t('errors:xIsRequired', { field: t('damageType') })));
    }
    setErrors(newErrors);
    setIsValid(!newErrors.length);
    return !newErrors.length;
  }, [generalInformation.damageType, generalInformation.description, t]);

  useEffect(() => {
    if (isValid === null) {
      // Only start automatically validation after initial validation
      return;
    }
    validate();
  }, [generalInformation, isValid, validate]);

  const damageTypeAutocomplete = useMemo(
    () =>
      Object.entries(DamageType).map(([value, label]) => ({
        label: t(`damage.${decapitalize(label)}`),
        value,
      })),
    [t],
  );

  return {
    errors,
    get: {
      damageType: generalInformation.damageType,
      damageTypeAutocomplete,
      description: generalInformation.description,
      fsmaAutocomplete: fsmaCompaniesAutocomplete.sort(({ label: a }, { label: b }) =>
        a.localeCompare(b),
      ),
      fsmaAutocompleteIsLoading: fsmaCompaniesAutocompleteIsLoading,
      insurerCentralCompany: generalInformation.insurerCentralCompany,
      remark: generalInformation.remark,
    },
    isValid,
    set: {
      damageType: (selectedDamageType: Nullable<AutocompleteOption>) =>
        updateGeneralInformation((prev) => ({
          ...prev,
          damageType: selectedDamageType,
        })),
      description: (description: string) =>
        updateGeneralInformation((prev) => ({
          ...prev,
          description,
        })),
      insurerCentralCompany: (insurerCentralCompany: Nullable<AutocompleteOption>) =>
        updateGeneralInformation((prev) => ({
          ...prev,
          insurerCentralCompany,
        })),
      remark: (remark: Nullable<string>) =>
        updateGeneralInformation((prev) => ({
          ...prev,
          remark,
        })),
    },
    validate,
  } as const;
};
