import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '../../CaseContext';
import { getCurrentTranslation } from '@/shared/utils/helpers';
import { useGetCaseClassification } from '@/queries';

import CaseNotificationCardDetails from './CaseNotificationCardDetails';
import CaseNotificationCardEditForm from './Forms/CaseNotificationCardEditForm';
import useNotification from '../../../../shared/hooks/UseNotification';

const propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func.isRequired,
};

const CaseNotificationCard = ({ isEditing = false, setIsEditing }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { currentCase, refetchCase } = useContext(CaseContext);
  const { caseType } = currentCase;

  const { t } = useTranslation();
  const { sendNotification } = useNotification();

  const { caseClassifications, classificationsLoading } = useGetCaseClassification({
    params: {
      caseType,
    },
  });

  const sortedCaseClassification = useMemo(
    () =>
      [...caseClassifications].sort((a, b) => {
        const translationA = getCurrentTranslation(a.translations, language) ?? '';
        const translationB = getCurrentTranslation(b.translations, language) ?? '';

        return translationA.localeCompare(translationB);
      }),
    [caseClassifications, language],
  );

  const onSuccess = () => {
    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:_case.title') }),
      variant: 'success',
    });
    setIsEditing(false);

    refetchCase();
  };

  return (
    <div>
      {!classificationsLoading && isEditing && (
        <CaseNotificationCardEditForm
          caseClassifications={sortedCaseClassification}
          onCancel={() => setIsEditing(false)}
          onSuccess={onSuccess}
        />
      )}
      {!classificationsLoading && !isEditing && (
        <CaseNotificationCardDetails caseClassifications={sortedCaseClassification} />
      )}
    </div>
  );
};

CaseNotificationCard.propTypes = propTypes;

export default CaseNotificationCard;
