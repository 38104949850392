import React, { createContext, useContext, useMemo, useReducer } from 'react';

import { EmailAccount, EmailMessage, MailboxFolder } from '~/common/types/mailbox';
import { localStorageKeys } from '@/shared/utils/constants';
import { Nullable } from '~/common/types';

type MailboxState = {
  checkedEmailIds: string[];
  emailAccount: Nullable<EmailAccount>;
  folders: MailboxFolder[];
  isCompose: boolean;
  isEmailListVisible: boolean;
  isForward: boolean;
  isReplyAll: boolean;
  isRetry: boolean;
  replyEmail: Nullable<EmailMessage>;
  selectedEmail: Nullable<EmailMessage>;
  selectedFolder: Nullable<string>;
};

type MailBoxContextType = {
  clearCompose: () => void;
  setCheckedEmailIds: (ids: string[]) => void;
  setEmailAccount: (emailAccount: EmailAccount, clear?: boolean) => void;
  setSelectedFolder: (folderId: Nullable<string>) => void;
  setSelectedEmail: (email: Nullable<EmailMessage>) => void;
  setFolders: (folders: MailboxFolder[]) => void;
  state: MailboxState;
  updateState: (payload: Partial<MailboxState>) => void;
};

const MAILBOX_ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
};

const mailboxReducer = (
  state: MailboxState,
  action: { type: string; payload: Partial<MailboxState> },
) => {
  switch (action.type) {
    case MAILBOX_ACTIONS.UPDATE_STATE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

const INITIAL_STATE: MailboxState = {
  checkedEmailIds: [],
  emailAccount: null,
  folders: [],
  isCompose: false,
  isEmailListVisible: true,
  isForward: false,
  isReplyAll: false,
  isRetry: false,
  replyEmail: null,
  selectedEmail: null,
  selectedFolder: null,
};

const MailboxContext = createContext({} as MailBoxContextType);

export const MailboxStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(mailboxReducer, INITIAL_STATE);

  const contextValue = useMemo(
    () => ({
      clearCompose: () =>
        dispatch({
          payload: {
            isCompose: false,
            isEmailListVisible: true,
            isForward: false,
            isReplyAll: false,
            replyEmail: null,
          },
          type: MAILBOX_ACTIONS.UPDATE_STATE,
        }),
      setCheckedEmailIds: (ids: string[]) =>
        dispatch({ payload: { checkedEmailIds: ids }, type: MAILBOX_ACTIONS.UPDATE_STATE }),
      setEmailAccount: (emailAccount: EmailAccount, clear = true) => {
        const payload = {
          emailAccount,
          selectedFolder: null,
          ...(clear && {
            replyEmail: null,
            selectedEmail: null,
          }),
        };
        dispatch({ payload, type: MAILBOX_ACTIONS.UPDATE_STATE });

        localStorage.setItem(
          localStorageKeys.mailbox.SELECTED_EMAIL_LINK_ID,
          emailAccount?.linkId ?? '',
        );
      },
      setFolders: (folders: MailboxFolder[]) =>
        dispatch({
          payload: { folders: folders.sort((a, b) => a.name.localeCompare(b.name)) },
          type: MAILBOX_ACTIONS.UPDATE_STATE,
        }),
      setSelectedEmail: (email: Nullable<EmailMessage>) =>
        dispatch({ payload: { selectedEmail: email }, type: MAILBOX_ACTIONS.UPDATE_STATE }),
      setSelectedFolder: (folderId: Nullable<string>) =>
        dispatch({ payload: { selectedFolder: folderId }, type: MAILBOX_ACTIONS.UPDATE_STATE }),
      state,
      updateState: (payload: Partial<MailboxState>) =>
        dispatch({ payload, type: MAILBOX_ACTIONS.UPDATE_STATE }),
    }),
    [state],
  );

  return <MailboxContext.Provider value={contextValue}>{children}</MailboxContext.Provider>;
};

export const useMailboxContext = () => useContext(MailboxContext);
