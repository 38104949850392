import PropTypes from 'prop-types';

import AddressPropTypes from './AddressPropTypes';
import CaseRelationPropTypes from './CaseRelationPropTypes';
import CaseStatusPropTypes from './CaseStatusPropTypes';

const CasePropTypes = PropTypes.shape({
  address: AddressPropTypes,
  allowExecutionOnTimeAndExpenseBasis: PropTypes.bool,
  appointmentAmPm: PropTypes.bool,
  appointmentDate: PropTypes.string,
  appointmentForSpecifications: PropTypes.bool,
  appointmentTime: PropTypes.string,
  building: PropTypes.shape({
    address: AddressPropTypes,
    buildingId: PropTypes.string,
    centralCompanyId: PropTypes.string,
    name: PropTypes.string,
    teamId: PropTypes.string,
    vatNumber: PropTypes.string,
  }),
  caseGroupId: PropTypes.string,
  caseGroupReference: PropTypes.string,
  id: PropTypes.string,
  caseType: PropTypes.oneOf(['Case', 'Repair', 'InsuranceClaim', 'Complaint']),
  channel: PropTypes.string,
  classificationDescription: PropTypes.string,
  classificationId: PropTypes.string,
  contractDescription: PropTypes.string,
  contractId: PropTypes.string,
  creationDate: PropTypes.string,
  currentTeamReference: PropTypes.string,
  description: PropTypes.string,
  executeOnTimeAndExpenseBasis: PropTypes.bool,
  incidentDate: PropTypes.string,
  isComplaintCase: PropTypes.bool,
  isInsuranceClaimCase: PropTypes.bool,
  isRepairCase: PropTypes.bool,
  lastModified: PropTypes.string,
  linkedComplaintCaseId: PropTypes.string,
  linkedComplaintCaseReference: PropTypes.string,
  policyId: PropTypes.string,
  policyNumber: PropTypes.string,
  priority: PropTypes.string,
  reference: PropTypes.string,
  relations: PropTypes.arrayOf(CaseRelationPropTypes),
  remark: PropTypes.string,
  requireEstimatePhotos: PropTypes.bool,
  serviceRequestIdSyncedOn: PropTypes.string,
  status: CaseStatusPropTypes,
  teamIsOwner: PropTypes.bool,
  whitelabelFileUri: PropTypes.string,
});

export default CasePropTypes;
