import { useEffect, useState } from 'react';
import { Button } from '@mui/joy';
import classNames from 'classnames';
import { Swiper } from 'swiper/types';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import { imageFileTypes, msDocFileTypes, pdfFileTypes, txtFileTypes } from '@/shared/utils/helpers';
import downloadFile from '@/shared/utils/fileHelpers';

import { CancelIcon, DownloadIcon } from '../../../icons/Icons';
import DocumentSkeleton from '../../2.0/skeletons/DocumentSkeleton';
import ImageRenderer from './Renderers/ImageRenderer';
import KpcModal from '../../2.0/layout/KpcModal';
import MSDocRenderer from './Renderers/MSDocRenderer';
import PDFRenderer from './Renderers/PDFRenderer';
import TXTRenderer from './Renderers/TXTRenderer';

export type FileProps = {
  contentType: string;
  fileBytes?: string;
  fileName: string;
  fileUri: string;
  id?: string;
};

type Props<T> = {
  file: FileProps;
  onClose?: () => void;
  onDownload?: (file: FileProps) => Promise<void>;
  swiper?: Swiper;
  toggle: UseToggle<T>;
};

const DocumentPreviewModal = <T,>({ toggle, file, onDownload, onClose, swiper }: Props<T>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [fileData, setFileData] = useState<string | null>(null);

  const { t } = useTranslation(['common', 'errors']);

  const onLoadRenderer = () => {
    setIsLoading(false);
  };

  const onErrorRenderer = () => {
    setError(t('unsupportedFileType'));
  };

  const download = async () => {
    if (onDownload) {
      await onDownload(file);
      return;
    }

    await downloadFile(file);
  };

  useEffect(() => {
    const { contentType, fileUri } = file;

    if (!txtFileTypes.includes(contentType)) {
      setFileData(fileUri);
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      setFileData(fileReader.result as string);
    });

    fetch(fileUri)
      .then((data) => data.blob())
      .then((blob) => {
        fileReader.readAsText(blob);
      });
  }, [file]);

  const rendererClassName = classNames({ hidden: isLoading });

  const getFileToDisplay = () => {
    const { contentType, fileUri } = file;

    if (txtFileTypes.includes(contentType)) {
      return (
        <TXTRenderer
          className={rendererClassName}
          fileData={fileData || ''}
          onLoad={onLoadRenderer}
        />
      );
    }
    if (msDocFileTypes.includes(contentType)) {
      return (
        <MSDocRenderer
          className={rendererClassName}
          uri={fileUri}
          onLoad={onLoadRenderer}
          onError={onErrorRenderer}
        />
      );
    }
    if (pdfFileTypes.includes(contentType)) {
      return (
        <PDFRenderer
          className={rendererClassName}
          uri={fileUri}
          onLoad={onLoadRenderer}
          onError={onErrorRenderer}
        />
      );
    }
    return <span>{t('unsupportedFileType')}</span>;
  };

  const isImage = imageFileTypes.includes(file.contentType);

  return (
    <KpcModal
      className="h-[90vh] w-1/2 gap-0"
      hideClose={isImage}
      noDivider
      toggle={toggle}
      actions={
        <div className="flex justify-end gap-4">
          <Button
            startDecorator={<CancelIcon />}
            color="neutral"
            variant="outlined"
            onClick={toggle.hide}
          >
            {t('cancel')}
          </Button>
          <Button disabled={isLoading} startDecorator={<DownloadIcon />} onClick={() => download()}>
            {t('download')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      {isImage ? (
        <ImageRenderer
          className={rendererClassName}
          download={download}
          isLoading={isLoading}
          onLoad={onLoadRenderer}
          onError={onErrorRenderer}
          swiper={swiper}
          toggle={toggle}
          uri={file.fileUri}
        />
      ) : (
        <div className="mt-6 h-full overflow-y-auto">
          {isLoading && <DocumentSkeleton />}
          {error && !fileData && <span>{error}</span>}
          {!!fileData && !error && getFileToDisplay()}
        </div>
      )}
    </KpcModal>
  );
};

export default DocumentPreviewModal;
