import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { queryIds } from '../../shared/utils/constants';

const useEmailBodyByBodyUrl = (bodyUrl, params) =>
  useQuery({
    queryFn: async () => {
      const response = await axios.get(bodyUrl);
      return response.data;
    },
    queryKey: [queryIds.mailbox.LOCAL_EMAIL_BODY, bodyUrl],
    staleTime: 60000 * 5,
    ...params,
  });

export default useEmailBodyByBodyUrl;
