import qs from 'qs';

import createRestClient from '@/shared/api/restClient';

const restClient = createRestClient(null, true);

const downloadEnvelopes = (envelopeTrackingIds: string[]) =>
  restClient.get('/finance/accounting/envelope/download', {
    params: { envelopeTrackingIds },
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob',
  });

export default {
  downloadEnvelopes,
};
