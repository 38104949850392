import { Autocomplete, Skeleton, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useGetContacts } from '../../../../queries';

const propTypes = {
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
};

const ContactFilter = ({ callback = () => {}, name = '', defaultValue = [], label = null }) => {
  const { t } = useTranslation('common');
  const { contactsAutocomplete, contactsIsLoading } = useGetContacts();

  return contactsIsLoading ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={contactsAutocomplete}
      isOptionEqualToValue={(opt, val) => opt.value === val.value}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(opt) => opt.label}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:contacts')} />
      )}
    />
  );
};

ContactFilter.propTypes = propTypes;

export default ContactFilter;
