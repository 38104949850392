import { useQuery } from '@tanstack/react-query';

import caseApi from '@/shared/api/cases/case';
import { queryIds } from '@/shared/utils/constants';
import { Nullable } from '~/common/types';

const useGetCaseFiles = (caseId: Nullable<string>) => {
  const { data, isLoading } = useQuery({
    enabled: !!caseId,
    queryFn: () => caseApi.getCaseFiles(caseId!),
    queryKey: [queryIds.cases.GET_CASE_FILES, caseId!],
  });

  return {
    caseFiles: data || [],
    caseFilesLoading: isLoading,
  };
};

export default useGetCaseFiles;
