import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import { typographyClasses } from '@mui/joy/Typography';

type Props = { teaser: string };

const TwoSidedLayout = ({ children, teaser }: React.PropsWithChildren<Props>) => (
  <Container
    sx={(theme) => ({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      minHeight: '100vh',
      position: 'relative',
      py: 10,
      [theme.breakpoints.up(834)]: {
        flexDirection: 'row',
        gap: 6,
      },
      [theme.breakpoints.up(1199)]: {
        gap: 12,
      },
    })}
  >
    <Box
      sx={(theme) => ({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 999,
        gap: '1rem',
        maxWidth: '50ch',
        textAlign: 'center',
        [theme.breakpoints.up(834)]: {
          alignItems: 'flex-start',
          minWidth: 420,
          textAlign: 'initial',
        },
        [`& .${typographyClasses.root}`]: {
          textWrap: 'balance',
        },
      })}
    >
      {children}
    </Box>
    <AspectRatio
      ratio={600 / 520}
      variant="outlined"
      maxHeight={300}
      className="rounded-xl border-none"
      sx={(theme) => ({
        alignSelf: 'stretch',
        bgcolor: 'background.level2',
        flexBasis: '50%',
        minWidth: 300,
        [theme.breakpoints.up(834)]: {
          '--AspectRatio-maxHeight': '520px',
          '--AspectRatio-minHeight': '400px',
          alignSelf: 'initial',
          flexGrow: 1,
        },
      })}
    >
      <img src={teaser} alt="teaser" />
    </AspectRatio>
  </Container>
);

export default TwoSidedLayout;
