import { forwardRef } from 'react';

import NumericFormatAdapter, { NumericFormatAdapterProps } from './NumericFormatAdapter';

type Props = NumericFormatAdapterProps;

const PREFIX = '€';

const CurrencyFormatAdapter = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <NumericFormatAdapter
    {...props}
    prefix={PREFIX}
    ref={ref}
    allowNegative={false}
    fixedDecimalScale
  />
));

export default CurrencyFormatAdapter;
