import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '../../../shared/api';

import { queryIds } from '../../../shared/utils/constants';
import useNotification from '../../../shared/hooks/UseNotification';

const useUpdateMandate = ({ callback = () => {} }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => caseApi.updateRepairMandate(data),
    onError: (error) => {
      sendDefaultError(error);
    },
    onSuccess: (_, { caseId }) => {
      queryClient.invalidateQueries([queryIds.cases.repair.GET_REPAIR_MANDATE, caseId]);

      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('mandate') }),
        variant: 'success',
      });
      callback();
    },
  });

  return {
    isSubmittingMandate: isLoading,
    updateMandate: (data) => mutate(data),
  };
};

export default useUpdateMandate;
