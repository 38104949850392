import PropTypes from 'prop-types';

import AddressPropTypes from './AddressPropTypes';
import PhoneNumberPropTypes from './PhoneNumberPropTypes';

const { string, shape } = PropTypes;

const ContactPropTypes = shape({
  address: AddressPropTypes,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: PhoneNumberPropTypes,
  mobilePhoneNumber: PhoneNumberPropTypes,
});

export default ContactPropTypes;
