import { Alert, Button, Checkbox, Grid, IconButton } from '@mui/joy';
import { getIn, setIn, useFormikContext } from 'formik';
import { lowerFirst, sumBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, TAutocompleteOption } from '~/common/types';
import { DivisionType } from '~/common/enums';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  convertPercentageToCommonFraction,
  percentagesToCommonDenominator,
} from '@/shared/utils/mathUtils';
import { formatAsCurrency, roundTo } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import useDayjs from '@/shared/hooks/useDayjs';

import {
  ShareValues,
  validateIndividualDivision,
} from '../../Invoices/ToValidate/hooks/useInvoiceValidationFormikContext';

import { AddIcon, DeleteIcon } from '@/shared/icons/Icons';
import FormikAutocomplete from '@/shared/components/2.0/formik/FormikAutocomplete';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type DivisionValues = {
  divisionType: Nullable<DivisionType>;
  shares: {
    buildingRelationId: Nullable<string>;
    percentage: Nullable<string>;
    share: number;
    unitId: string;
  }[];
};

type Props<T> = {
  bookYearId: Nullable<string>;
  homeownerAssociationId: Nullable<string>;
  divisionTypeName: string;
  onSave?: (values: DivisionValues) => void;
  sharesName: string;
  toggle: UseToggle<T>;
  totalAmount: number;
};

const IndividualDivisionModal = <T,>({
  sharesName,
  divisionTypeName,
  totalAmount,
  toggle,
  bookYearId,
  homeownerAssociationId,
  onSave,
}: Props<T>) => {
  const { t } = useTranslation();
  const { setFieldValue, setValues, values } = useFormikContext();

  const [showOldRelations, setShowOldRelations] = useState(false);

  const { dayjs } = useDayjs();

  const divisions = getIn(values, sharesName) as ShareValues[];

  const handleAddEmptyShare = () => {
    const updatedValues = setIn(values, sharesName, [...divisions, {}]);
    setValues(updatedValues);
  };

  const { data: buildingUnitRelations } = trpc.hoa.building.relation.list.useQuery(
    {
      bookYearId: bookYearId as string,
      homeownerAssociationId: homeownerAssociationId as string,
      options: {
        includeOldRelations: showOldRelations,
      },
    },
    {
      enabled: !!bookYearId && !!homeownerAssociationId,
    },
  );

  const updateShares = (percentage: number, index: number) => {
    const currentShares = getIn(values, sharesName) as ShareValues[];

    currentShares[index] = {
      ...currentShares[index],
      percentage,
    };

    const percentages = currentShares.map((share) => share.percentage || 0);
    const commonDenominator = percentagesToCommonDenominator(percentages);
    currentShares.forEach((share, i) => {
      const [numerator] = convertPercentageToCommonFraction(
        share.percentage || 0,
        commonDenominator,
      );
      setFieldValue(`${sharesName}[${i}].share`, numerator);
    });
  };

  const relationOptions = useMemo(() => {
    if (!buildingUnitRelations) {
      return [];
    }

    return buildingUnitRelations.flatMap((relation) =>
      relation.units.map((unit) => {
        let useDisplay = null;
        const startDate = unit.startUse ? dayjs(unit.startUse).format('DD/MM/YYYY') : null;
        const endDate = unit.endUse ? dayjs(unit.endUse).format('DD/MM/YYYY') : null;
        if (startDate || endDate) {
          useDisplay = ` (${startDate ?? '...'} - ${endDate ?? '...'})`;
        }
        return {
          endUse: unit.endUse,
          label: `${relation.displayName} - ${unit.registrationNumber}${useDisplay ?? ''}`,
          value: {
            buildingRelationId: relation.relationId,
            key: `${relation.relationId}-${unit.unitId}`,
            unitId: unit.unitId,
          },
        };
      }),
    );
  }, [buildingUnitRelations, dayjs]);

  const handleRemoveShare = (index: number) => {
    const updatedValues = setIn(
      values,
      sharesName,
      divisions.filter((_, i) => i !== index),
    );
    setValues(updatedValues);
  };

  const amounts = useMemo(() => {
    const amountToDivide = totalAmount || 0;
    const dividedAmount = sumBy(divisions, (share) => share.amount || 0);

    return {
      divided: dividedAmount,
      leftToDivide: amountToDivide - dividedAmount,
      toDivide: amountToDivide,
    };
  }, [divisions, totalAmount]);

  const errors = useMemo(() => {
    const validationResult = validateIndividualDivision({
      divisionKey: null,
      grossAmount: totalAmount,
      shares: divisions,
    });

    return validationResult.errors;
  }, [divisions, totalAmount]);

  // purely for a quick fix for miscBookingLines
  // can be removed again (along with onSave prop) if a non formik controlled version is created
  const handleSave = () => {
    onSave?.(values as DivisionValues);
    toggle.hide();
  };

  return (
    <KpcModal
      backdropBehavior="noCloseOnClick"
      description={t('_inboundHoaInvoice.actions.amountToDivide', {
        amountLeftToDivide: formatAsCurrency(amounts.leftToDivide, true),
        totalAmountToDivide: formatAsCurrency(amounts.toDivide, true),
      })}
      title={t('_inboundHoaInvoice.actions.applyIndividualDivision')}
      toggle={toggle}
    >
      <div>
        <div className="mb-4">
          <FormikAutocomplete
            name={divisionTypeName}
            isRequired
            label={t('divisionType.title')}
            placeholder={t('divisionType.placeholder')}
            options={
              Object.values(DivisionType).map((v) => ({
                label: t(`divisionType.options.${lowerFirst(v)}`),
                value: v,
              })) as TAutocompleteOption<DivisionType>[]
            }
          />
        </div>
        {divisions.map((division, i) => (
          <Grid
            key={`${division.unitId}_${division.buildingRelationId}`}
            container
            columnSpacing={2}
            className="mb-4"
          >
            <Grid xs={5}>
              <FormikAutocomplete
                name={`${sharesName}[${i}].unitRelationShare`}
                isRequired
                label={t('relation')}
                onChangeCallback={(value) => {
                  const selected = value as { unitId: string; buildingRelationId: string };
                  setFieldValue(
                    `${sharesName}[${i}].buildingRelationId`,
                    selected?.buildingRelationId,
                  );
                  setFieldValue(`${sharesName}[${i}].unitId`, selected?.unitId);
                }}
                options={relationOptions.filter((option) => {
                  const selectedOptions = divisions
                    .filter((_o, index) => index !== i)
                    .map((share) => ({
                      buildingRelationId: share.buildingRelationId,
                      unitId: share.unitId,
                    }));
                  return !selectedOptions.some(
                    (selected) =>
                      selected.unitId === option.value.unitId &&
                      selected.buildingRelationId === option.value.buildingRelationId,
                  );
                })}
              />
            </Grid>
            <Grid xs={3}>
              <FormikTextField
                name={`${sharesName}[${i}].amount`}
                label={t('amount')}
                type="number"
                disabled={getIn(values, divisionTypeName) !== DivisionType.Amount}
                required
                onChangeCallback={(value) => {
                  if (getIn(values, divisionTypeName) !== DivisionType.Amount) {
                    return;
                  }
                  const amount = value ?? 0;
                  const grossAmount = totalAmount ?? 0;
                  const percentage = roundTo((amount / grossAmount) * 100, 4);
                  setFieldValue(`${sharesName}[${i}].percentage`, percentage);
                  updateShares(percentage, i);
                }}
              />
            </Grid>
            <Grid xs={3}>
              <FormikTextField
                name={`${sharesName}[${i}].percentage`}
                min={0}
                max={
                  100 -
                  divisions
                    .filter((_, index) => index !== i)
                    .reduce((acc, share) => acc + (share.percentage || 0), 0)
                }
                label={t('percentage')}
                type="number"
                required
                disabled={getIn(values, divisionTypeName) !== DivisionType.Percentage}
                onChangeCallback={(value) => {
                  if (getIn(values, divisionTypeName) !== DivisionType.Percentage) {
                    return;
                  }
                  const percentage = value ?? 0;
                  const grossAmount = totalAmount ?? 0;
                  const amount = roundTo((grossAmount / 100) * percentage, 2);
                  setFieldValue(`${sharesName}[${i}].amount`, amount);
                  updateShares(percentage, i);
                }}
              />
            </Grid>
            <Grid xs={1}>
              <IconButton
                color="danger"
                variant="plain"
                className="mt-5"
                onClick={() => handleRemoveShare(i)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Checkbox
          className="mb-4"
          color="primary"
          checked={showOldRelations}
          onChange={() => setShowOldRelations(!showOldRelations)}
          label={t('showOldContacts')}
        />
        {errors?.length > 0 && (
          <Alert color="danger" className="mb-2">
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        <div className="float-right flex">
          <IconButton
            className="mr-2"
            onClick={handleAddEmptyShare}
            disabled={divisions.length >= relationOptions.length}
          >
            <AddIcon />
          </IconButton>
          <Button disabled={!!errors.length} onClick={handleSave}>
            Ok
          </Button>
        </div>
      </div>
    </KpcModal>
  );
};

export default IndividualDivisionModal;
