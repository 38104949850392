import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { IconButton } from '@mui/joy';
import TopbarBlogNotifications from './TopbarBlogNotifications';
import TopbarProfile from './TopbarProfile';
import TopbarQuickAction from './TopbarQuickAction';
import TopbarSearch from './TopbarSearch';
import TopbarTasks from './TopbarTasks';
import TopbarTeam from './TopbarTeam';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import logo from '@/shared/img/logo/logo_light.svg';
import { MenuIcon } from '@/shared/icons/Icons';

const Topbar = () => {
  const { setUi } = useContext(AppContext);

  return (
    <div className="fixed top-0 z-topbar flex h-[60px] w-full bg-white">
      <div className="flex w-1/2 items-center">
        <IconButton
          className="mx-4 rounded-full"
          size="md"
          variant="plain"
          onClick={() => setUi({ type: actions.TOGGLE_MAIN_MENU })}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" className="h-10 pr-4">
          <img src={logo} alt="Logo" className="h-full w-full object-contain" />
        </Link>
        <TopbarSearch className="ml-8 w-96" />
      </div>
      <div className="flex grow items-center justify-end space-x-4 px-4">
        <TopbarTeam />
        <TopbarQuickAction />
        <TopbarTasks />
        <TopbarBlogNotifications />
        <TopbarProfile />
      </div>
    </div>
  );
};

export default Topbar;
