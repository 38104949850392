import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuButton = ({ title, iconStyle, icon, onClick, iconComponent, className }) => (
  <button type="button" className={`topbar__menu__button ${className}`} onClick={onClick}>
    <div
      className="topbar__menu__button-container"
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      {iconComponent &&
        React.cloneElement(iconComponent, { className: 'topbar__menu__button-icon' })}
      {icon &&
        Array.isArray(icon) &&
        icon.map((i) => (
          <FontAwesomeIcon key={i} icon={[iconStyle, i]} className="topbar__menu__button-icon" />
        ))}
      {icon && !Array.isArray(icon) && (
        <FontAwesomeIcon icon={[iconStyle, icon]} className="topbar__menu__button-icon" />
      )}
      <span className="topbar__menu__button-title">{title}</span>
    </div>
  </button>
);

MenuButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  iconComponent: PropTypes.node,
  iconStyle: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MenuButton.defaultProps = {
  iconStyle: 'fad',
  icon: null,
  iconComponent: null,
  className: '',
};

/** @component */
export default MenuButton;
