import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const FormikStepper = ({ labels, data, setData }) => {
  const { errors } = useFormikContext();

  const handleStep = (index) => {
    if (data?.step === 0 && errors.buildingSelector) {
      return;
    }

    if (data?.step === 2 && (errors.admins || errors.reference || errors.priority)) {
      return;
    }

    if (data?.step === 3 && (errors.damageType || errors.description || errors.incidentDate)) {
      return;
    }

    if (data?.step === 4 && errors.caseRelations) {
      return;
    }

    if (data.step + 1 < index) {
      return;
    }
    setData({ ...data, step: index });
  };

  return (
    <Stepper nonLinear activeStep={data.step} className="mb-3" alternativeLabel>
      {labels.map((label, index) => (
        <Step key={label}>
          <StepButton color="inherit" onClick={() => handleStep(index)}>
            <StepLabel>{label}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

FormikStepper.propTypes = {
  data: PropTypes.shape({
    step: PropTypes.number.isRequired,
  }).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormikStepper;
