import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '../../utils/helpers';
import useGetEnums from '../../../queries/enums/useGetEnums';

const workDayButtonPropTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  short: PropTypes.bool.isRequired,
  workday: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
};

const WorkdayButton = ({ isSelected, workday, short, onClick }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      startDecorator={
        isSelected ? (
          <FontAwesomeIcon icon={['fad', 'check-circle']} style={{ color: 'lime' }} />
        ) : (
          <FontAwesomeIcon icon={['fad', 'circle-xmark']} style={{ color: 'red' }} />
        )
      }
      value={workday.value}
      key={workday.value}
      color="neutral"
      variant="outlined"
      onClick={onClick}
    >
      {short ? t(`common:weekDayShort${workday.name}`) : t(`common:${decapitalize(workday.name)}`)}
    </Button>
  );
};

WorkdayButton.propTypes = workDayButtonPropTypes;

const selectWorkdaysPropTypes = {
  selectedWorkdays: PropTypes.arrayOf(PropTypes.number),
  setSelectedWorkdays: PropTypes.func.isRequired,
  short: PropTypes.bool,
};

const SelectWorkdays = ({ selectedWorkdays = [], setSelectedWorkdays, short = false }) => {
  const {
    enums: { workdays },
  } = useGetEnums();

  const selectDay = (day) => {
    if (selectedWorkdays.includes(day)) {
      setSelectedWorkdays(selectedWorkdays.filter((sd) => sd !== day));
    } else {
      setSelectedWorkdays([...selectedWorkdays, day]);
    }
  };

  return (
    <div className="flex flex-wrap gap-4">
      {workdays.map((w) => (
        <WorkdayButton
          isSelected={selectedWorkdays.includes(w.value)}
          workday={w}
          key={w.value}
          onClick={() => selectDay(w.value)}
          short={short}
        />
      ))}
    </div>
  );
};

SelectWorkdays.propTypes = selectWorkdaysPropTypes;

export default SelectWorkdays;
