import { useQuery } from '@tanstack/react-query';

import { contactApi } from '@/shared/api';

import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetContact = (contactId?: string) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!contactId,
    onError: sendDefaultError,
    queryFn: () => contactApi.getContact(contactId!),
    queryKey: [queryIds.contacts.GET_CONTACT, contactId!],
  });

  return {
    contact: data || null,
    contactIsLoading: isLoading && !!contactId,
  };
};

export default useGetContact;
