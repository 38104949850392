import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import financeApi from '../../shared/api/finance/finance';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useUpdateSpreadsheetRemark = (caseGroupId) => {
  const { t } = useTranslation('common');
  const { sendDefaultError, sendNotification } = useNotification();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    async (data) => financeApi.updateSpreadsheetRemark(data),
    {
      onError: (error, { previousData }) => {
        sendDefaultError(error);
        queryClient.setQueryData(
          [queryIds.finance.GET_SPREADSHEETS_BY_CASE_GROUP, caseGroupId],
          previousData,
        );
      },
      onMutate: async ({ id, remark }) => {
        const spreadsheets = await queryClient.getQueryData([
          queryIds.finance.GET_SPREADSHEETS_BY_CASE_GROUP,
          caseGroupId,
        ]);
        if (spreadsheets?.some((si) => si.id === id) ?? false) {
          await queryClient.cancelQueries([
            queryIds.finance.GET_SPREADSHEETS_BY_CASE_GROUP,
            caseGroupId,
          ]);
          queryClient.setQueryData(
            [queryIds.finance.GET_SPREADSHEETS_BY_CASE_GROUP, caseGroupId],
            (prev) => prev.map((si) => (si.id === id ? { ...si, remark } : si)),
          );
        }
        return { previousData: spreadsheets };
      },
      onSuccess: () => {
        sendNotification({
          header: t('success'),
          message: t('typeSuccessfullyUpdated', { type: t('remark') }),
          variant: 'success',
        });
      },
    },
  );

  return {
    isUpdatingSpreadsheetRemark: isLoading,
    updateSpreadsheetRemark: (data, options = {}) => mutate(data, options),
  };
};

export default useUpdateSpreadsheetRemark;
