import CaseChannel from '../../shared/enums/CaseChannel';
import CaseStatus from '../../shared/enums/CaseStatus';
import { decapitalize } from '../../shared/utils/helpers';
import { sorting } from '../../shared/utils/constants';

export const getPendingFilter = (t) => ({
  label: t(decapitalize(CaseStatus.Pending)),
  value: CaseStatus.Pending,
});

export const getOpenFilter = (t) => ({
  label: t(decapitalize(CaseStatus.Open)),
  value: CaseStatus.Open,
});

export const getClosedFilter = (t) => ({
  label: t(decapitalize(CaseStatus.Closed)),
  value: CaseStatus.Closed,
});

export const getCancelledFilter = (t) => ({
  label: t(decapitalize(CaseStatus.Cancelled)),
  value: CaseStatus.Cancelled,
});

export const getNonPendingStatusFilters = (t) => [
  getOpenFilter(t),
  getClosedFilter(t),
  getCancelledFilter(t),
];

export const getChannelFilters = (t) => [
  {
    label: t(decapitalize(CaseChannel.Assistance)),
    value: CaseChannel.Assistance,
  },
  {
    label: t(decapitalize(CaseChannel.Case)),
    value: CaseChannel.Case,
  },
  {
    label: t(decapitalize(CaseChannel.Email)),
    value: CaseChannel.Email,
  },
  {
    label: t(decapitalize(CaseChannel.Letter)),
    value: CaseChannel.Letter,
  },
  {
    label: t(decapitalize(CaseChannel.Meeting)),
    value: CaseChannel.Meeting,
  },
  {
    label: t(decapitalize(CaseChannel.OnSite)),
    value: CaseChannel.OnSite,
  },
  {
    label: t(decapitalize(CaseChannel.Other)),
    value: CaseChannel.Other,
  },
  {
    label: t(decapitalize(CaseChannel.Phone)),
    value: CaseChannel.Phone,
  },
  {
    label: t(decapitalize(CaseChannel.QRScan)),
    value: CaseChannel.QRScan,
  },
  {
    label: t(decapitalize(CaseChannel.Template)),
    value: CaseChannel.Template,
  },
];

export const getDefaultFilters = () => ({
  autocomplete: {
    administrators: [],
    channels: [],
    classifications: [],
    contracts: [],
    statuses: [],
    statusTranslations: [],
  },
  button: {
    orderDirection: sorting.orderDirection.DESC,
  },
  checkbox: {
    filterCases: false,
    filterComplaints: false,
    filterInsuranceClaims: false,
    filterRepairCases: false,
    onlyCasesForMyTeam: false,
    onlyCasesWithOpenTasks: false,
    onlyCasesWithoutOpenTasks: false,
  },
  input: { search: '' },
  select: {
    groupBy: 'caseReferenceId',
    orderBy: sorting.orderBy.CASE_GROUP_REFERENCE,
  },
});
