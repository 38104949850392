import { Box, Chip, TextField } from '@mui/material';
import { Popper as PopperUnstyled, Portal } from '@mui/base';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button } from '@mui/joy';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AddIcon, CompanyIcon, ContactIcon, TeamIcon, ValidCompanyIcon } from '../../icons/Icons';
import { getAllPagedData, sentencize } from '../../utils/helpers';
import {
  useGetCase,
  useGetCentralCompanies,
  useGetCompanies,
  useGetContacts,
} from '../../../queries';
import API from '../../api/ApiService';
import ReferencePropType from '../../prop-types/ReferencePropType';
import { trpc } from '@/config/trpc';
import useNotification from '../../hooks/UseNotification';
import useOutsideAlerter from '../../hooks/UseOutsideAlerter';

import AddCentralCompanyForm from '../../../containers/CentralCompanies/AddCentralCompanyForm';
import AddEditCompanyForm from '../../../containers/Companies/AddEditCompany/AddEditCompanyForm';
import AddEditContactForm from '../../../containers/Contacts/AddEditContact/AddEditContactForm';
import { formatContactDisplayName } from '~/common/utils/utils';
import KpcTooltip from '../Tooltips/KpcTooltip';
import MenuButton from '../buttons/MenuButton';

const ITEM_LIMIT = 5;

const propTypes = {
  caseId: PropTypes.string,
  centralCompanyIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  companiesToExclude: PropTypes.arrayOf(PropTypes.string),
  contactsToExclude: PropTypes.arrayOf(PropTypes.string),
  disabledCompanyIds: PropTypes.arrayOf(PropTypes.string),
  disabledContactIds: PropTypes.arrayOf(PropTypes.string),
  disabledTeamIds: PropTypes.arrayOf(PropTypes.string),
  disablePortal: PropTypes.bool,
  errorText: PropTypes.string,
  hideTeamsThatCannotManageInsuranceClaims: PropTypes.bool,
  isRequired: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onCentralCompanySelectChanged: PropTypes.func,
  onCompanySelectChanged: PropTypes.func,
  onCompanyWithTeamSelectChanged: PropTypes.func,
  onContactSelectChanged: PropTypes.func,
  onTeamSelectChanged: PropTypes.func,
  popperContainer: ReferencePropType,
  preselectedCompanyIds: PropTypes.arrayOf(PropTypes.string),
  preselectedContactIds: PropTypes.arrayOf(PropTypes.string),
  selectOnly: PropTypes.bool,
  selectSingle: PropTypes.bool,
  showCaseRelationSuggestions: PropTypes.bool,
  showCentralCompanies: PropTypes.bool,
  showCompanies: PropTypes.bool,
  showCompaniesWithTeams: PropTypes.bool,
  showContacts: PropTypes.bool,
  showTeams: PropTypes.bool,
  teamIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  teamsToExclude: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  caseId: null,
  centralCompanyIdsToSelect: [],
  companiesToExclude: [],
  contactsToExclude: [],
  disabledCompanyIds: [],
  disabledContactIds: [],
  disabledTeamIds: [],
  disablePortal: true,
  errorText: '',
  hideTeamsThatCannotManageInsuranceClaims: false,
  isRequired: false,
  isTouched: true,
  label: '',
  onBlur: () => {},
  onCentralCompanySelectChanged: () => {},
  onCompanySelectChanged: () => {},
  onCompanyWithTeamSelectChanged: () => {},
  onContactSelectChanged: () => {},
  onTeamSelectChanged: () => {},
  popperContainer: null,
  preselectedCompanyIds: [],
  preselectedContactIds: [],
  selectOnly: false,
  selectSingle: false,
  showCaseRelationSuggestions: false,
  showCentralCompanies: false,
  showCompanies: false,
  showCompaniesWithTeams: false,
  showContacts: false,
  showTeams: false,
  teamIdsToSelect: [],
  teamsToExclude: [],
};

const SelectCRM = ({
  showContacts,
  showCompanies,
  showCentralCompanies,
  showTeams,
  showCompaniesWithTeams,
  centralCompanyIdsToSelect,
  onContactSelectChanged,
  onCompanySelectChanged,
  onCentralCompanySelectChanged,
  onCompanyWithTeamSelectChanged,
  onTeamSelectChanged,
  contactsToExclude,
  companiesToExclude,
  teamsToExclude,
  label,
  selectOnly,
  selectSingle,
  isRequired,
  isTouched,
  errorText,
  teamIdsToSelect,
  caseId,
  showCaseRelationSuggestions,
  disablePortal,
  popperContainer,
  hideTeamsThatCannotManageInsuranceClaims,
  onBlur,
  preselectedContactIds,
  preselectedCompanyIds,
  disabledContactIds,
  disabledCompanyIds,
  disabledTeamIds,
}) => {
  if (
    !showContacts &&
    !showCompanies &&
    !showCentralCompanies &&
    !showTeams &&
    !showCompaniesWithTeams
  ) {
    throw new Error(
      'SelectCRM component must have at least one entity to show. Eg: contacts = true.',
    );
  }

  const areTeamsDataUsed = showTeams || showCompaniesWithTeams;

  const selectorMenuRef = React.useRef();
  const { isInside, setOutsideAlerterActive } = useOutsideAlerter(selectorMenuRef);

  const { currentCase, currentCaseIsLoading } = useGetCase(caseId);

  const { contacts } = useGetContacts();
  const { companies } = useGetCompanies();
  const { centralCompanies } = useGetCentralCompanies(showCentralCompanies);

  const { t } = useTranslation(['common', 'errors']);
  const { sendNotification, sendDefaultError } = useNotification();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [formattedContacts, setFormattedContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const [formattedCompanies, setFormattedCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [formattedCompaniesWithTeams, setFormattedCompaniesWithTeams] = useState([]);
  const [selectedCompaniesWithTeams, setSelectedCompaniesWithTeams] = useState([]);

  const [formattedCentralCompanies, setFormattedCentralCompanies] = useState([]);
  const [selectedCentralCompanies, setSelectedCentralCompanies] = useState([]);
  const [selectedCentralCompaniesInited, setSelectedCentralCompaniesInited] = useState(false);

  const [formattedTeams, setFormattedTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamsInited, setTeamsInited] = useState(false);

  const [addingNewContact, setAddingNewContact] = useState(false);
  const [addingNewCompany, setAddingNewCompany] = useState(false);
  const [addingNewCentralCompany, setAddingNewCentralCompany] = useState(false);
  const [canSubmitNewCentralCompany, setCanSubmitNewCentralCompany] = useState(false);
  const [isSubmittingNewCentralCompany, setIsSubmittingNewCentralCompany] = useState(false);

  const checkItemSelected = useMemo(
    () =>
      selectedCentralCompanies.length ||
      selectedCompanies.length ||
      selectedContacts.length ||
      selectedTeams.length ||
      selectedCompaniesWithTeams.length,
    [
      selectedCentralCompanies.length,
      selectedCompanies.length,
      selectedContacts.length,
      selectedTeams.length,
      selectedCompaniesWithTeams.length,
    ],
  );

  const [query, setQuery] = useState('');

  const collapseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
    setAddingNewContact(false);
    setAddingNewCompany(false);
    setAddingNewCentralCompany(false);
    setQuery('');

    if (isInside) {
      onBlur();
    }
  }, [isInside, onBlur, setOutsideAlerterActive]);

  const handleSelectOrDeselect = React.useCallback(
    (object, selectList, selectedItems, updateSelectList, updateSelectedItems, callback) => {
      let currentSelectList = selectList;
      let currentSelectedItems = selectedItems;
      const currentIsAlreadySelected = object.id
        ? currentSelectedItems.some((i) => i.id === object.id)
        : currentSelectedItems.some((i) => i.companyId === object.companyId);
      // Deselecting a previous selected item
      if (currentIsAlreadySelected) {
        if (!selectSingle) {
          // Deselect current option
          currentSelectedItems = currentSelectedItems.filter((i) => i.id !== object.id);
          // Add option back to the select list
          currentSelectList = [...currentSelectList, object];
        } else {
          // Deselect current option
          currentSelectedItems = [];
          // Add option back to the select list
          currentSelectList = [...currentSelectList, object];
        }
        updateSelectedItems(
          currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)),
        );
        updateSelectList(currentSelectList.sort((a, b) => a.display.localeCompare(b.display)));
        callback(currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)));
        return;
      }

      // Selecting a new item
      if (!selectSingle) {
        // Select current option
        currentSelectedItems = [...currentSelectedItems, object];
        // Remove option from the select list
        currentSelectList = currentSelectList.filter((i) => i.id !== object.id);
      } else {
        if (currentSelectedItems.length > 0) {
          const [currentlySelected] = currentSelectedItems;
          // Add option back to the select list
          currentSelectList = [...currentSelectList, currentlySelected];
        }
        currentSelectedItems = [object];
        currentSelectList = currentSelectList.filter((i) => i.id !== object.id);
        collapseSelect();
      }
      updateSelectedItems(currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)));
      updateSelectList(currentSelectList.sort((a, b) => a.display.localeCompare(b.display)));
      callback(currentSelectedItems.sort((a, b) => a.display.localeCompare(b.display)));
    },
    [collapseSelect, selectSingle],
  );

  const handleContactClick = React.useCallback(
    (contact) => {
      handleSelectOrDeselect(
        contact,
        formattedContacts,
        selectedContacts,
        setFormattedContacts,
        setSelectedContacts,
        onContactSelectChanged,
      );

      if (selectSingle) {
        setFormattedCompanies((prev) => [...prev, ...(selectedCompanies ?? [])]);
        setFormattedCentralCompanies((prev) => [...prev, ...(selectedCentralCompanies ?? [])]);
        setFormattedTeams((prev) => [...prev, ...(selectedTeams ?? [])]);
        setFormattedCompaniesWithTeams((prev) => [...prev, ...(selectedCompaniesWithTeams ?? [])]);

        setSelectedCompanies([]);
        setSelectedCentralCompanies([]);
        setSelectedTeams([]);
        setSelectedCompaniesWithTeams([]);
      }
    },
    [
      handleSelectOrDeselect,
      selectedTeams,
      selectedCentralCompanies,
      formattedContacts,
      selectedContacts,
      onContactSelectChanged,
      selectedCompanies,
      selectSingle,
      selectedCompaniesWithTeams,
    ],
  );

  const handleCompanyClick = React.useCallback(
    (company) => {
      handleSelectOrDeselect(
        company,
        formattedCompanies,
        selectedCompanies,
        setFormattedCompanies,
        setSelectedCompanies,
        onCompanySelectChanged,
      );

      if (selectSingle) {
        setFormattedContacts((prev) => [...prev, ...(selectedContacts ?? [])]);
        setFormattedCentralCompanies((prev) => [...prev, ...(selectedCentralCompanies ?? [])]);
        setFormattedTeams((prev) => [...prev, ...(selectedTeams ?? [])]);
        setFormattedCompaniesWithTeams((prev) => [...prev, ...(selectedCompaniesWithTeams ?? [])]);

        setSelectedContacts([]);
        setSelectedCentralCompanies([]);
        setSelectedTeams([]);
        setSelectedCompaniesWithTeams([]);
      }
    },
    [
      handleSelectOrDeselect,
      selectedTeams,
      formattedCompanies,
      selectedCompanies,
      onCompanySelectChanged,
      selectSingle,
      selectedContacts,
      selectedCentralCompanies,
      selectedCompaniesWithTeams,
    ],
  );

  const handleCompanyWithTeamClick = React.useCallback(
    (company) => {
      handleSelectOrDeselect(
        company,
        formattedCompaniesWithTeams,
        selectedCompaniesWithTeams,
        setFormattedCompaniesWithTeams,
        setSelectedCompaniesWithTeams,
        onCompanyWithTeamSelectChanged,
      );

      if (selectSingle) {
        setFormattedCompanies((prev) => [...prev, ...(selectedCompanies ?? [])]);
        setFormattedContacts((prev) => [...prev, ...(selectedContacts ?? [])]);
        setFormattedCentralCompanies((prev) => [...prev, ...(selectedCentralCompanies ?? [])]);
        setFormattedTeams((prev) => [...prev, ...(selectedTeams ?? [])]);

        setSelectedCompanies([]);
        setSelectedContacts([]);
        setSelectedCentralCompanies([]);
        setSelectedTeams([]);
      }
    },
    [
      formattedCompaniesWithTeams,
      selectedCompaniesWithTeams,
      handleSelectOrDeselect,
      selectedTeams,
      selectedCompanies,
      selectSingle,
      selectedContacts,
      selectedCentralCompanies,
      onCompanyWithTeamSelectChanged,
    ],
  );

  const handleCentralCompanyClick = React.useCallback(
    (centralCompany) => {
      handleSelectOrDeselect(
        centralCompany,
        formattedCentralCompanies,
        selectedCentralCompanies,
        setFormattedCentralCompanies,
        setSelectedCentralCompanies,
        onCentralCompanySelectChanged,
      );

      if (selectSingle) {
        setFormattedContacts((prev) => [...prev, ...(selectedContacts ?? [])]);
        setFormattedCompanies((prev) => [...prev, ...(selectedCompanies ?? [])]);
        setFormattedTeams((prev) => [...prev, ...(selectedTeams ?? [])]);
        setFormattedCompaniesWithTeams((prev) => [...prev, ...(selectedCompaniesWithTeams ?? [])]);

        setSelectedContacts([]);
        setSelectedCompanies([]);
        setSelectedTeams([]);
        setSelectedCompaniesWithTeams([]);
      }
    },
    [
      handleSelectOrDeselect,
      selectedTeams,
      selectSingle,
      formattedCentralCompanies,
      selectedContacts,
      selectedCompanies,
      selectedCentralCompanies,
      onCentralCompanySelectChanged,
      selectedCompaniesWithTeams,
    ],
  );

  const handleTeamClick = React.useCallback(
    (team) => {
      handleSelectOrDeselect(
        team,
        formattedTeams,
        selectedTeams,
        setFormattedTeams,
        setSelectedTeams,
        onTeamSelectChanged,
      );
      if (selectSingle) {
        setFormattedCompanies((prev) => [...prev, ...(selectedCompanies ?? [])]);
        setFormattedCentralCompanies((prev) => [...prev, ...(selectedCentralCompanies ?? [])]);
        setFormattedContacts((prev) => [...prev, ...(selectedContacts ?? [])]);
        setFormattedCompaniesWithTeams((prev) => [...prev, ...(selectedCompaniesWithTeams ?? [])]);

        setSelectedCompanies([]);
        setSelectedCentralCompanies([]);
        setSelectedContacts([]);
        setSelectedCompaniesWithTeams([]);
      }
    },
    [
      handleSelectOrDeselect,
      selectedTeams,
      selectSingle,
      onTeamSelectChanged,
      formattedTeams,
      selectedContacts,
      selectedCompanies,
      selectedCentralCompanies,
      selectedCompaniesWithTeams,
    ],
  );

  const formatContact = useCallback((contact) => {
    let display = formatContactDisplayName(contact);
    if (contact.address && contact.address.city && contact.address.city.length > 0) {
      display = `${display} (${contact.address.city})`;
    }
    return {
      display,
      id: contact.id,
      ...contact,
    };
  }, []);

  const { mutateAsync: createContact } = trpc.contact.create.useMutation({
    onError: sendDefaultError,
    onSuccess: (response) => {
      sendNotification({
        header: t('common:success'),
        message: t('common:typeSuccessfullyAdded', { type: t('common:contact') }),
        variant: 'success',
      });
      setAddingNewContact(false);
      handleContactClick(formatContact(response));
    },
  });

  const handleCreateContact = async (contact) => {
    const { addressLine, postalCode, city, country, ...formBody } = contact;

    await createContact({
      ...formBody,
      address: {
        addressLine,
        city,
        country,
        postalCode,
      },
    });
  };

  const formatCentralCompany = useCallback(
    (centralCompany) => ({
      display: `${centralCompany.name} (${centralCompany.vatNumber})`,
      ...centralCompany,
    }),
    [],
  );

  const handleCreateCentralCompany = async (centralCompany) => {
    const existingCentralCompany = centralCompanies.find(
      (cc) => cc.vatNumber === centralCompany.vat,
    );

    if (existingCentralCompany) {
      setAddingNewCentralCompany(false);
      handleCentralCompanyClick(
        formattedCentralCompanies.find((fcc) => fcc.id === existingCentralCompany.id),
      );
      return;
    }
    const response = await API.postAddCentralCompany(centralCompany);

    if (response.serviceError != null || response.status !== 201) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyAdded', { type: t('common:centralCompany') }),
      variant: 'success',
    });

    setAddingNewCentralCompany(false);
    handleCentralCompanyClick(formatCentralCompany(response.data));
  };

  /* Prepare contact data */

  const filteredContacts = useMemo(() => {
    if (!query || query.length === 0) {
      return formattedContacts;
    }
    return formattedContacts.filter((c) => c.display.toLowerCase().includes(query));
  }, [formattedContacts, query]);

  const formatContacts = useCallback(
    () =>
      setFormattedContacts(
        contacts
          .filter((contact) => !selectedContacts.some((s) => s.id === contact.id))
          .filter((contact) => !contactsToExclude.some((id) => id === contact.id))
          .map((contact) => formatContact(contact))
          .sort((a, b) => a.display.localeCompare(b.display)),
      ),
    [contacts, formatContact, selectedContacts, contactsToExclude],
  );

  useEffect(() => {
    formatContacts();
  }, [formatContacts]);

  useEffect(() => {
    if (contacts) {
      setSelectedContacts(
        contacts.filter((c) => preselectedContactIds.includes(c.id)).map((c) => formatContact(c)),
      );
    }
  }, [preselectedContactIds, contacts, setSelectedContacts, formatContact]);

  /* Prepare company data */

  const filteredCompanies = useMemo(() => {
    if (!query || query.length === 0) {
      return formattedCompanies;
    }
    return formattedCompanies.filter((c) => c.display.toLowerCase().includes(query));
  }, [formattedCompanies, query]);

  const formatCompany = useCallback(
    (company) => ({
      display: company.displayName,
      id: company.id,
      linkedToCentralCompany: 'centralCompanyId' in company && company.centralCompanyId !== null,
      ...company,
    }),
    [],
  );

  const formatCompanies = useCallback(
    () =>
      setFormattedCompanies(
        companies
          .filter((company) => !selectedCompanies.some((c) => c.id === company.id))
          .filter((company) => !companiesToExclude.some((id) => id === company.id))
          .map((company) => formatCompany(company))
          .sort((a, b) => a.display.localeCompare(b.display)),
      ),
    [companies, companiesToExclude, formatCompany, selectedCompanies],
  );

  useEffect(() => {
    formatCompanies();
  }, [formatCompanies]);

  useEffect(() => {
    if (companies) {
      setSelectedCompanies(
        companies.filter((c) => preselectedCompanyIds.includes(c.id)).map((c) => formatCompany(c)),
      );
    }
  }, [preselectedCompanyIds, companies, setSelectedCompanies, formatCompany]);

  /* Prepare central company data */

  const filteredCentralCompanies = useMemo(() => {
    if (!query || query.length === 0) {
      return formattedCentralCompanies;
    }
    return formattedCentralCompanies.filter((c) => c.display.toLowerCase().includes(query));
  }, [formattedCentralCompanies, query]);

  const formatCentralCompanies = useCallback(() => {
    setFormattedCentralCompanies(
      centralCompanies
        .filter(
          (centralCompany) => !selectedCentralCompanies.some((cc) => cc.id === centralCompany.id),
        )
        .map((centralCompany) => formatCentralCompany(centralCompany))
        .sort((a, b) => a.display.localeCompare(b.display)),
    );
  }, [centralCompanies, formatCentralCompany, selectedCentralCompanies]);

  useEffect(() => {
    formatCentralCompanies();
  }, [formatCentralCompanies]);

  useEffect(() => {
    if (
      !selectedCentralCompaniesInited &&
      centralCompanyIdsToSelect.length > 0 &&
      formattedCentralCompanies.length > 0
    ) {
      formattedCentralCompanies
        .filter((cc) => centralCompanyIdsToSelect.includes(cc.id))
        .forEach((cc) => handleCentralCompanyClick(cc));
      setSelectedCentralCompaniesInited(true);
    }
  }, [
    selectedCentralCompaniesInited,
    centralCompanyIdsToSelect,
    formattedCentralCompanies,
    handleCentralCompanyClick,
  ]);

  const handleCreateCompany = async (fullCompany) => {
    const response = await API.postAddCompany(fullCompany);

    if (response.serviceError != null || response.status !== 201) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyAdded', { type: t('common:company') }),
      variant: 'success',
    });
    handleCompanyClick(formatCompany(response.data));
  };

  /* Prepare team data */

  const filteredTeams = useMemo(() => {
    if (!query || query.length === 0) {
      return formattedTeams;
    }
    return formattedTeams.filter((c) => c.display.toLowerCase().includes(query));
  }, [formattedTeams, query]);

  const [allTeams, setAllTeams] = useState([]);
  const [caseTeamsInited, setCaseTeamsInited] = useState(false);

  useEffect(() => {
    if (!caseId || currentCaseIsLoading || !currentCase) {
      return;
    }
    const { relations } = currentCase;
    if (!showCaseRelationSuggestions && relations != null && relations.length > 0) {
      const teamsToShow = allTeams.filter((f) => relations.some((cr) => cr.teamId === f.id));
      setFormattedTeams([...teamsToShow]);
      setCaseTeamsInited(true);
    }
  }, [caseId, currentCase, currentCaseIsLoading, allTeams, showCaseRelationSuggestions]);

  const [teamsPreselectInited, setTeamsPreselectInited] = useState(false);

  useEffect(() => {
    if ((caseId != null && !caseTeamsInited) || !teamsInited) {
      return;
    }
    if (!teamsPreselectInited && teamIdsToSelect.length > 0 && allTeams.length > 0) {
      setSelectedTeams(allTeams.filter((team) => teamIdsToSelect.includes(team.id)));
      setTeamsPreselectInited(true);
    }
  }, [allTeams, teamsInited, teamsPreselectInited, caseId, caseTeamsInited, teamIdsToSelect]);

  useEffect(() => {
    if (!areTeamsDataUsed || teamsInited) {
      return;
    }

    async function getData() {
      const response = await getAllPagedData(() => API.fetchGetCompaniesWithTeams());

      if (response.serviceError == null && response.status === 200) {
        const formatted = response.data
          .map((company) =>
            company.teams.map((team) => ({
              canHandleInsuranceCases: team.canHandleInsuranceCases,
              canReceiveInsuranceCasesFromCurrentTeam: team.canReceiveInsuranceCasesFromCurrentTeam,
              companyId: company.id,
              display: `${company.companyName} (${team.name})`,
              id: team.teamId,
            })),
          )
          .flat()
          .filter((team) => !teamsToExclude.some((id) => team.id === id))
          .filter((team) =>
            hideTeamsThatCannotManageInsuranceClaims
              ? team.canHandleInsuranceCases && team.canReceiveInsuranceCasesFromCurrentTeam
              : true,
          )
          .sort((a, b) => a.display.localeCompare(b.display));

        setAllTeams([...formatted]);
        if (caseId == null || caseId.length === 0 || showCaseRelationSuggestions) {
          setFormattedTeams([...formatted].filter((team) => !teamIdsToSelect.includes(team.id)));
        }
      }
    }

    setTeamsInited(true);
    getData();
  }, [areTeamsDataUsed, teamsInited, caseId, hideTeamsThatCannotManageInsuranceClaims]);

  useLayoutEffect(() => {
    if (!isInside) {
      collapseSelect();
    }
  }, [isInside, collapseSelect]);

  /* Renders */

  const getSelectedChips = React.useMemo(() => {
    const allChips = [];
    if (selectedContacts && selectedContacts.length > 0) {
      const selectedContactsChips = selectedContacts.map((contact) => {
        const disabled = disabledContactIds.includes(contact.id);
        return (
          <KpcTooltip key={contact.id} title={disabled ? t('common:contactCannotBeChanged') : ''}>
            <Chip
              icon={<ContactIcon />}
              label={contact.display}
              onDelete={() => handleContactClick(contact)}
              disabled={disabled}
            />
          </KpcTooltip>
        );
      });
      allChips.push(selectedContactsChips);
    }
    if (selectedCompanies && selectedCompanies.length > 0) {
      const selectedCompanyChips = selectedCompanies.map((company) => {
        const disabled = disabledCompanyIds.includes(company.id);
        return (
          <KpcTooltip key={company.id} title={disabled ? t('common:companyCannotBeChanged') : ''}>
            <Chip
              icon={
                company.linkedToCentralCompany ? (
                  <>
                    <CompanyIcon className="ms-2" />
                    <ValidCompanyIcon className="ms-1" color="keypoint" />
                  </>
                ) : (
                  <CompanyIcon />
                )
              }
              label={company.display}
              onDelete={() => handleCompanyClick(company)}
            />
          </KpcTooltip>
        );
      });
      allChips.push(selectedCompanyChips);
    }
    if (selectedCompaniesWithTeams && selectedCompaniesWithTeams.length > 0) {
      const selectedCompanyChips = selectedCompaniesWithTeams.map((company) => {
        const disabled = disabledCompanyIds.includes(company.companyId);
        return (
          <KpcTooltip
            key={`${company.id}-${company.companyId}`}
            title={disabled ? t('common:companyCannotBeChanged') : ''}
          >
            <Chip
              icon={
                company.linkedToCentralCompany ? (
                  <>
                    <CompanyIcon className="ms-2" />
                    <ValidCompanyIcon className="ms-1" color="keypoint" />
                  </>
                ) : (
                  <CompanyIcon />
                )
              }
              label={company.display}
              onDelete={() => handleCompanyWithTeamClick(company)}
            />
          </KpcTooltip>
        );
      });
      allChips.push(selectedCompanyChips);
    }
    if (selectedCentralCompanies && selectedCentralCompanies.length > 0) {
      const selectedCentralCompanyChips = selectedCentralCompanies.map((centralCompany) => (
        <Chip
          icon={
            <>
              <CompanyIcon className="ms-2" />
              <ValidCompanyIcon className="ms-1" />
            </>
          }
          key={centralCompany.id}
          label={centralCompany.display}
          onDelete={() => handleCentralCompanyClick(centralCompany)}
        />
      ));
      allChips.push(selectedCentralCompanyChips);
    }
    if (selectedTeams && selectedTeams.length > 0) {
      const selectedTeamsChips = selectedTeams.map((team) => {
        const disabled = disabledTeamIds.includes(team.id);
        return (
          <KpcTooltip key={team.id} title={disabled ? t('common:teamCannotBeChanged') : ''}>
            <Chip icon={<TeamIcon />} label={team.display} onDelete={() => handleTeamClick(team)} />
          </KpcTooltip>
        );
      });
      allChips.push(selectedTeamsChips);
    }
    return allChips && allChips.length > 0 && allChips;
  }, [
    selectedContacts,
    selectedCompanies,
    selectedCentralCompanies,
    selectedTeams,
    selectedCompaniesWithTeams,
    disabledContactIds,
    t,
    handleContactClick,
    disabledCompanyIds,
    handleCompanyClick,
    handleCentralCompanyClick,
    disabledTeamIds,
    handleTeamClick,
    handleCompanyWithTeamClick,
  ]);

  const anchorRef = React.useRef();

  const getCompanyMenuButton = React.useCallback(
    (company) => (
      <Button
        className="w-full justify-start"
        variant="plain"
        color="neutral"
        key={company.id}
        startDecorator={
          company.linkedToCentralCompany ? (
            <>
              <CompanyIcon />
              <ValidCompanyIcon />
            </>
          ) : (
            <CompanyIcon />
          )
        }
        onClick={() => handleCompanyClick(company)}
      >
        {company.display}
      </Button>
    ),
    [handleCompanyClick],
  );

  const getContactMenuButton = React.useCallback(
    (contact) => (
      <Button
        className="w-full justify-start"
        variant="plain"
        color="neutral"
        key={contact.id}
        startDecorator={<ContactIcon />}
        onClick={() => handleContactClick(contact)}
      >
        {contact.display}
      </Button>
    ),
    [handleContactClick],
  );

  const getTeamMenuButton = React.useCallback(
    (team) => (
      <Button
        className="w-full justify-start"
        variant="plain"
        color="neutral"
        key={team.id}
        startDecorator={<TeamIcon />}
        onClick={() => handleTeamClick(team)}
      >
        {team.display}
      </Button>
    ),
    [handleTeamClick],
  );

  const getCompanyWithTeamButton = React.useCallback(
    (companyWithTeam) => (
      <Button
        className="w-full justify-start"
        variant="plain"
        color="neutral"
        key={`company-with-team-${companyWithTeam.id}-${companyWithTeam.companyId}`}
        startDecorator={<CompanyIcon />}
        onClick={() => handleCompanyWithTeamClick(companyWithTeam)}
      >
        {companyWithTeam.display}
      </Button>
    ),
    [handleCompanyWithTeamClick],
  );

  useEffect(() => {
    const teamsWithCompanyData = filteredTeams.map((team) => ({
      ...formattedCompanies.find((company) => company.id === team.companyId),
      ...team,
    }));

    const mappedCompanies = filteredCompanies
      .filter((company) => !filteredTeams.some((team) => team.companyId === company.id))
      .map((company) => ({
        ...company,
        companyId: company.id,
        id: null,
      }));

    setFormattedCompaniesWithTeams([...teamsWithCompanyData, ...mappedCompanies]);
  }, [filteredCompanies, filteredTeams, formattedCompanies]);

  const allCompanies = React.useMemo(() => {
    const companiesToShow =
      showCompanies && filteredCompanies.length > 0
        ? filteredCompanies.map(getCompanyMenuButton)
        : [];

    let centralCompaniesToShow =
      showCentralCompanies && filteredCentralCompanies.length > 0
        ? filteredCentralCompanies.map((centralCompany) => (
            <MenuButton
              key={centralCompany.id}
              title={centralCompany.display}
              iconComponent={
                <>
                  <CompanyIcon />
                  <ValidCompanyIcon />
                </>
              }
              onClick={() => handleCentralCompanyClick(centralCompany)}
            />
          ))
        : [];

    if (companiesToShow.length > 0 && centralCompaniesToShow.length > 0) {
      centralCompaniesToShow = centralCompaniesToShow.filter(
        (cc) => !companiesToShow.some((c) => c.centralCompanyId === cc.id),
      );
    }

    return companiesToShow.concat(centralCompaniesToShow);
  }, [
    showCompanies,
    filteredCompanies,
    getCompanyMenuButton,
    showCentralCompanies,
    filteredCentralCompanies,
    handleCentralCompanyClick,
  ]);

  const allSuggestions = React.useMemo(() => {
    if (!showCaseRelationSuggestions || !caseId || currentCaseIsLoading || !currentCase) {
      return [];
    }
    const { relations } = currentCase;
    const relationCompanies = showCompanies
      ? filteredCompanies
          .filter((comp) => relations.some((cr) => cr.companyId === comp.id))
          .map(getCompanyMenuButton)
      : [];

    const relationContacts = showContacts
      ? filteredContacts
          .filter((cont) => relations.some((cr) => cr.contactId === cont.id))
          .map(getContactMenuButton)
      : [];

    const relationTeams = showTeams
      ? (filteredTeams ?? [])
          .filter((team) => relations.some((cr) => cr.teamId === team.id))
          .map(getTeamMenuButton)
      : [];

    return relationCompanies.concat(relationContacts).concat(relationTeams);
  }, [
    caseId,
    currentCase,
    currentCaseIsLoading,
    showCaseRelationSuggestions,
    showCompanies,
    filteredCompanies,
    getCompanyMenuButton,
    showContacts,
    filteredContacts,
    getContactMenuButton,
    showTeams,
    filteredTeams,
    getTeamMenuButton,
  ]);

  const getLabel = React.useMemo(() => label || t('common:search.label'), [label, t]);

  const helperText = React.useMemo(() => {
    if (errorText != null && errorText.length > 0) {
      return errorText;
    }
    if (isRequired && !checkItemSelected && isTouched) {
      return t('errors:fieldIsRequired');
    }
    return null;
  }, [checkItemSelected, errorText, isRequired, isTouched, t]);

  const handleBlur = () => {
    if (isInside) {
      return;
    }
    onBlur();
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      collapseSelect();
    }
  };

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });

  const hasSelectOptionsToShow =
    (showTeams && filteredTeams.length > 0) ||
    (showCompanies && filteredCompanies.length > 0) ||
    (showContacts && filteredContacts.length > 0) ||
    (showCentralCompanies && filteredCentralCompanies.length > 0) ||
    (showCompaniesWithTeams && formattedCompaniesWithTeams.length > 0) ||
    addingNewContact ||
    addingNewCompany ||
    addingNewCentralCompany;

  const hasButtonsToShow =
    !selectOnly &&
    (showContacts || showCompanies || showCentralCompanies || showCompaniesWithTeams);

  return (
    <div className="custom__selector">
      {isSelectOpen && (
        <Portal disablePortal={disablePortal} container={popperContainer?.current}>
          <button
            className="topbar__back"
            aria-label="topbar__back"
            type="button"
            onClick={collapseSelect}
          />
        </Portal>
      )}
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
        }}
      >
        <TextField
          className={textFieldClasses}
          sx={{
            '& .MuiInputBase-root': {
              flexWrap: 'wrap',
            },
          }}
          size="small"
          id="input-with-sx"
          label={isRequired ? `${getLabel} *` : getLabel}
          variant="standard"
          onChange={({ target }) => setQuery(target.value.toLowerCase())}
          onFocus={() => {
            setIsSelectOpen(true);
            setOutsideAlerterActive(true);
          }}
          autoComplete="off"
          fullWidth
          error={
            (isRequired && !checkItemSelected && isTouched) ||
            (errorText != null && errorText.length > 0)
          }
          helperText={helperText}
          InputProps={{
            startAdornment: getSelectedChips,
          }}
          ref={anchorRef}
          onBlur={() => handleBlur()}
          value={query}
          onKeyDown={handleKeyDown}
        />
      </Box>
      <PopperUnstyled
        disablePortal={disablePortal}
        container={popperContainer?.current}
        open={isSelectOpen && (hasSelectOptionsToShow || hasButtonsToShow)}
        anchorEl={anchorRef?.current}
        className="custom__selector-wrap"
        placement="bottom-start"
      >
        <div className="flex w-[500px] flex-col rounded-xl p-4 shadow-xl" ref={selectorMenuRef}>
          {!addingNewContact && !addingNewCompany && (
            <>
              {showCaseRelationSuggestions && allSuggestions.length > 0 && (
                <>
                  <h5>{t('common:suggestions')}</h5>
                  <div>{allSuggestions}</div>
                </>
              )}
              {showContacts && filteredContacts.length > 0 && (
                <>
                  {showCaseRelationSuggestions && allSuggestions.length > 0 && <hr />}
                  <h5 className="text-primary">{t('common:contacts')}</h5>

                  {filteredContacts.filter((d, i) => i < ITEM_LIMIT).map(getContactMenuButton)}
                  {filteredContacts.length > ITEM_LIMIT && (
                    <span className="py-2">{t('common:moreResultsAreHiddenRefineFilter')}</span>
                  )}
                </>
              )}
              {(showCompanies || showCentralCompanies) && allCompanies.length > 0 && (
                <>
                  {((showContacts && filteredContacts.length > 0) ||
                    (showCaseRelationSuggestions && allSuggestions.length > 0)) && <hr />}
                  <h5 className="text-primary">{t('common:companies')}</h5>

                  {allCompanies.filter((d, i) => i < ITEM_LIMIT).map((company) => company)}
                  {allCompanies.length > ITEM_LIMIT && (
                    <span className="py-2">{t('common:moreResultsAreHiddenRefineFilter')}</span>
                  )}
                </>
              )}
              {showTeams && filteredTeams.length > 0 && (
                <>
                  {((showContacts && filteredContacts.length > 0) ||
                    ((showCompanies || showCentralCompanies) && allCompanies.length > 0) ||
                    (showCaseRelationSuggestions && allSuggestions.length > 0)) && <hr />}
                  <h5 className="text-primary">{t('common:teams')}</h5>
                  <div>
                    {filteredTeams.filter((d, i) => i < ITEM_LIMIT).map(getTeamMenuButton)}
                    {filteredTeams.length > ITEM_LIMIT && (
                      <span className="py-2">{t('common:moreResultsAreHiddenRefineFilter')}</span>
                    )}
                  </div>
                </>
              )}
              {showCompaniesWithTeams && formattedCompaniesWithTeams.length > 0 && (
                <>
                  {((showContacts && filteredContacts.length > 0) ||
                    ((showCompanies || showCentralCompanies) && allCompanies.length > 0) ||
                    (showCaseRelationSuggestions && allSuggestions.length > 0)) && <hr />}
                  <h5 className="text-primary">{t('companies')}</h5>
                  <div>
                    {formattedCompaniesWithTeams
                      .filter((d, i) => i < ITEM_LIMIT)
                      .map(getCompanyWithTeamButton)}
                    {formattedCompaniesWithTeams.length > ITEM_LIMIT && (
                      <span className="py-2">{t('common:moreResultsAreHiddenRefineFilter')}</span>
                    )}
                  </div>
                </>
              )}
              {hasButtonsToShow && (
                <>
                  <hr />
                  {!selectOnly && showContacts && (
                    <Button
                      className="justify-start"
                      variant="plain"
                      color="neutral"
                      startDecorator={<AddIcon />}
                      onClick={() => setAddingNewContact(true)}
                    >
                      {sentencize(t('common:addType', { type: t('common:_contact.title') }))}
                    </Button>
                  )}
                  {!selectOnly && (showCompanies || showCompaniesWithTeams) && (
                    <Button
                      className="justify-start"
                      variant="plain"
                      color="neutral"
                      startDecorator={<AddIcon />}
                      onClick={() => setAddingNewCompany(true)}
                    >
                      {sentencize(t('common:addType', { type: t('common:_company.title') }))}
                    </Button>
                  )}
                  {!selectOnly && showCentralCompanies && (
                    <Button
                      className="justify-start"
                      variant="plain"
                      color="neutral"
                      startDecorator={<AddIcon />}
                      onClick={() => setAddingNewCentralCompany(true)}
                    >
                      {sentencize(t('common:addType', { type: t('common:centralCompany') }))}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          {addingNewContact && (
            <>
              <h5 className="text-primary">
                {sentencize(t('common:addType', { type: t('common:_contact.title') }))}
              </h5>
              <div className="p-3">
                <AddEditContactForm
                  minified
                  onCancel={() => setAddingNewContact(false)}
                  onSubmit={handleCreateContact}
                />
              </div>
            </>
          )}
          {addingNewCompany && (
            <>
              <h5 className="text-primary">
                {sentencize(t('common:addType', { type: t('common:_company.title') }))}
              </h5>
              <div className="p-3">
                <AddEditCompanyForm
                  minified
                  onCancel={() => setAddingNewCompany(false)}
                  onSubmit={handleCreateCompany}
                />
              </div>
            </>
          )}
          {addingNewCentralCompany && (
            <>
              <h5 className="text-primary">
                {sentencize(t('common:addType', { type: t('common:centralCompany') }))}
              </h5>
              <div className="p-3">
                <AddCentralCompanyForm
                  canSubmit={canSubmitNewCentralCompany}
                  isSubmitting={isSubmittingNewCentralCompany}
                  onCancel={() => {
                    setAddingNewCentralCompany(false);
                    setCanSubmitNewCentralCompany(false);
                  }}
                  onSubmit={(centralCompany) => {
                    setIsSubmittingNewCentralCompany(true);
                    handleCreateCentralCompany(centralCompany);
                    setIsSubmittingNewCentralCompany(false);
                    setCanSubmitNewCentralCompany(false);
                  }}
                  setCanSubmit={setCanSubmitNewCentralCompany}
                />
              </div>
            </>
          )}
        </div>
      </PopperUnstyled>
    </div>
  );
};

SelectCRM.propTypes = propTypes;
SelectCRM.defaultProps = defaultProps;

export default SelectCRM;
