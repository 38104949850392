import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { caseApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

type Options = {
  callback?: (res: any) => void;
  redirect?: boolean;
};

const useCreateRepairCase = (options: Options) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { isLoading, mutate, mutateAsync } = useMutation({
    mutationFn: caseApi.createRepairCase,
    onError: sendDefaultError,
    onSuccess: (res) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASES]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyAdded', { type: t('_case.title') }),
        variant: 'success',
      });

      if (options.redirect) {
        navigate(`/case/${res}`);
      }

      if (options.callback) {
        options.callback(res);
      }
    },
  });
  return {
    createRepairCase: mutate,
    createRepairCaseAsync: mutateAsync,
    isCreatingRepairCase: isLoading,
  };
};

export default useCreateRepairCase;
