import { CrmOptionType } from '~/common/enums';

import {
  AddIcon,
  CircleIcon,
  CompanyIcon,
  ContactIcon,
  HomeOwnerAssociationIcon,
  InfoIcon,
} from '@/shared/icons/Icons';

type Props = {
  type: CrmOptionType | 'default';
};

const typeToIcon: Record<CrmOptionType | 'default', React.ReactNode> = {
  [CrmOptionType.ActionAdd]: <AddIcon />,
  [CrmOptionType.BuildingSuggestion]: <HomeOwnerAssociationIcon />,
  [CrmOptionType.Company]: <CompanyIcon />,
  [CrmOptionType.Team]: <CompanyIcon />,
  [CrmOptionType.CentralCompany]: <CompanyIcon />,
  [CrmOptionType.Contact]: <ContactIcon />,
  default: <CircleIcon />,
  [CrmOptionType.Info]: <InfoIcon />,
};

const CrmTypeIcon: React.FC<Props> = ({ type }) => typeToIcon[type];

export default CrmTypeIcon;
