import PropTypes from 'prop-types';

import PaymentRequestPropTypes from './PaymentRequestPropTypes';
import SpreadsheetStatus from '../enums/SpreadsheetStatus';

const SpreadsheetPropTypes = PropTypes.shape({
  deliveredByTeamId: PropTypes.string,
  deliveredByTeamName: PropTypes.string,
  deliveredByUserId: PropTypes.string,
  deliveredByusername: PropTypes.string,
  deliveryDate: PropTypes.string,
  description: PropTypes.string,
  documentDate: PropTypes.string,
  documentNr: PropTypes.string,
  fileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      caseFileId: PropTypes.string,
      id: PropTypes.string,
      isMainFile: PropTypes.bool,
    }),
  ),
  grossAmount: PropTypes.number,
  id: PropTypes.string,
  isEstimate: PropTypes.bool,
  managerTeamId: PropTypes.string,
  metamazeOrganisationId: PropTypes.string,
  metamazeProjectId: PropTypes.string,
  metamazeUploadId: PropTypes.string,
  metamazeUploadStatus: PropTypes.string,
  netAmount: PropTypes.number,
  paymentRequests: PropTypes.arrayOf(PaymentRequestPropTypes),
  remark: PropTypes.string,
  status: PropTypes.oneOf([
    SpreadsheetStatus.Accepted,
    SpreadsheetStatus.Draft,
    SpreadsheetStatus.DraftInScan,
    SpreadsheetStatus.Failed,
    SpreadsheetStatus.ReadyForValidation,
    SpreadsheetStatus.Rejected,
  ]),
  vatAmount: PropTypes.number,
});

export default SpreadsheetPropTypes;
