import React from 'react';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '@/shared/utils/helpers';

export type EnumValueCellProps = {
  translationKey?: string;
  translationNamespace?: string;
  type?: string;
  value: string;
};

const EnumValueCell: React.FC<EnumValueCellProps> = ({
  translationKey = '',
  translationNamespace = 'common',
  type,
  value,
}) => {
  const { t } = useTranslation();
  if (value == null) return '-';

  return (
    <div className="break-words">
      {t(
        `${translationNamespace}:${type ? `${decapitalize(type)}:` : translationKey}${decapitalize(
          value,
        )}`,
      ) || '-'}
    </div>
  );
};

export default EnumValueCell;
