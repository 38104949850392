import { DefaultErrorShape } from '@trpc/server';
import { lowerFirst } from 'lodash';
import { TRPCClientErrorLike } from '@trpc/client';
import { useQueryClient } from '@tanstack/react-query';

import { EntityType } from '~/common/enums';
import { Task } from '@/types/trpc/task';

import { queryIds } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import useMutateWithSnackbar from '@/shared/hooks/useMutateWithSnackbar';

type Options = {
  onError?: (error: TRPCClientErrorLike<DefaultErrorShape>) => void;
  onSuccess?: (task: Task) => void;
};

const useTaskActions = (options?: Options) => {
  const utils = trpc.useUtils();
  const queryClient = useQueryClient();

  const { mutateAsync: finishTaskAsync, isLoading: isFinishingTask } =
    trpc.task.finish.useMutation();
  const { mutateAsync: snoozeTaskAsync, isLoading: isSnoozingTask } =
    trpc.task.snooze.useMutation();

  const entityKey = lowerFirst(EntityType.Task);

  const handleSuccess = (task: Task) => {
    const { caseId, inboundHoaInvoiceId, meetingId } = task;

    utils.task.topDueTasks.invalidate();

    if (caseId) utils.task.caseTasks.invalidate({ caseId });

    // will have to be extended with trpcUtils (eventually without using queryClient directly), once queries below are converted to TRPC
    if (meetingId) {
      queryClient.invalidateQueries({ queryKey: [queryIds.meetings.GET_MEETING_TASKS, meetingId] });
    }
    if (inboundHoaInvoiceId) {
      queryClient.invalidateQueries({
        queryKey: [queryIds.finance.inbound.hoa.GET_INVOICE_TASKS, inboundHoaInvoiceId],
      });
    }

    options?.onSuccess?.(task);
  };

  const finishTask = useMutateWithSnackbar({
    entityKey,
    mutateAsync: finishTaskAsync,
    onError: options?.onError,
    onSuccess: handleSuccess,
    snackbar: {
      error: 'errors:failedToUpdateType',
      success: 'typeSuccessfullyFinished',
    },
  });

  const snoozeTask = useMutateWithSnackbar({
    entityKey,
    mutateAsync: snoozeTaskAsync,
    onError: options?.onError,
    onSuccess: handleSuccess,
    snackbar: {
      error: 'errors:failedToUpdateType',
      success: 'typeSuccessfullySnoozed',
    },
  });

  return {
    finishTask,
    isFinishingTask,
    isSnoozingTask,
    snoozeTask,
  };
};

export default useTaskActions;
