import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { sentencize } from '@/shared/utils/helpers';
import useDayjs from '@/shared/hooks/useDayjs';

const useAddEditAppointmentValidation = () => {
  const { t } = useTranslation(['common', 'errors']);
  const { dayjs } = useDayjs();

  return {
    validationSchema: yup.object().shape({
      allDay: yup.bool(),
      caseId: yup.string().nullable(),
      description: yup.string().nullable(),
      end: yup
        .string()
        .nullable()
        .when('allDay', {
          is: false,
          then: yup.string().nullable().required(t('errors:fieldIsRequired')),
        })
        .test(
          'endShouldBeLater',
          sentencize(t('errors:shouldBeLaterThenX', { from: t('startDate') })),
          (value, schema) => {
            const { parent } = schema;
            if (!value || !parent.start || parent.allDay) {
              return true;
            }

            const fromDate = dayjs(parent.start);
            const untilDate = dayjs(value);

            return fromDate < untilDate;
          },
        ),
      linkedCalendarId: yup.string().nullable(),
      start: yup
        .string()
        .nullable()
        .required(t('errors:fieldIsRequired'))
        .test(
          'startShouldBeEarlier',
          sentencize(t('errors:shouldBeBeforeX', { until: t('endDate') })),
          (value, schema) => {
            const { parent } = schema;
            if (!value || !parent.end) {
              return true;
            }

            const fromDate = dayjs(value);
            const untilDate = dayjs(parent.end);

            return fromDate < untilDate;
          },
        ),
      tasks: yup
        .array()
        .nullable()
        .test('taskDataShouldBeValid', (value) => (value && value[0] ? value[0].isValid : true)),
      title: yup
        .string()
        .nullable()
        .required(t('errors:fieldIsRequired'))
        .max(1024, t('errors:fieldMaxLengthIsX', { max: 1024 })),
    }),
  };
};

export default useAddEditAppointmentValidation;
