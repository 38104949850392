import { AutocompleteOption, ListItemContent } from '@mui/joy';
import { getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '../../../utils/helpers';

import Autocomplete from '../Autocomplete';

const propTypes = {
  freeSolo: PropTypes.bool,
  isRequired: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
};

const FormikEnumAutocomplete = ({
  freeSolo = false,
  isRequired = false,
  multiple = false,
  name,
  onChangeCallback = () => {},
  options,
  ...rest
}) => {
  const { t } = useTranslation('common');
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();

  const formattedOptions = options
    .map((o) => ({ label: o.name, value: o.value }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const onChange = ({ value }) => {
    if (multiple) {
      const vals = value.map((v) => v.label);
      setFieldValue(name, vals);
      onChangeCallback(vals);
      return;
    }

    setFieldValue(name, value.label);
    onChangeCallback(value.label);
  };

  const getValue = () => {
    const val = getIn(values, name);
    if (!val) {
      return null;
    }
    if (multiple) {
      return formattedOptions.filter((o) => t(val.includes(o.label)));
    }

    return formattedOptions.find((o) => o.label === t(val));
  };

  return (
    <Autocomplete
      {...rest}
      name={name}
      noOptionsText={t('common:noOptions')}
      multiple={multiple}
      isRequired={isRequired}
      freeSolo={freeSolo}
      onBlur={() => setFieldTouched(name, true)}
      options={formattedOptions}
      getOptionLabel={(option) => {
        if (option?.label == null) {
          return '';
        }
        return t(`${decapitalize(option.label)}`);
      }}
      renderOption={(props, option) => (
        <AutocompleteOption {...props}>
          <ListItemContent className="pl-2">{t(decapitalize(option.label))}</ListItemContent>
        </AutocompleteOption>
      )}
      value={getValue()}
      error={touched[name] && !!errors[name]}
      onChange={onChange}
      helperText={touched[name] ? errors[name] : undefined}
    />
  );
};

FormikEnumAutocomplete.propTypes = propTypes;

export default FormikEnumAutocomplete;
