import { useQuery } from '@tanstack/react-query';

import { components } from '~/common/types/kpc.d';
import { Country } from '.';
import { GeocodeResponse } from '~/backend/services/geocode';

import config from '@/config/config';
import createRestClient from '@/shared/api/restClient';
import { queryIds } from '@/shared/utils/constants';

type Province = components['schemas']['KeypointConnect.DDD.Enums.Province'];

type Params = {
  country?: Country;
  province?: Province;
};

const client = createRestClient(config.baseURL);

const useGetPostalCodes = (params: Params = {}, options = { enabled: true }) => {
  const { data, isLoading } = useQuery({
    enabled: options.enabled,
    queryFn: (): Promise<GeocodeResponse[]> => client.get('/geocode', { params }),
    queryKey: [queryIds.geocode.GET_POSTAL_CODES, params],
  });

  return {
    postalCodes: data,
    postalCodesAreLoading: isLoading,
  };
};

export default useGetPostalCodes;
