import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../shared/api/ApiService';
import TagType from '../../../shared/enums/TagType';
import actionTypes from '../../actions/actionTypes';

function* handleGetTags() {
  const buildingTagsResponse = yield call(API.fetchGetBuildingTags);
  const companyTagsResponse = yield call(API.fetchGetCompanyTags);
  const contactTagsResponse = yield call(API.fetchGetContactTags);

  if (buildingTagsResponse.serviceError != null || buildingTagsResponse.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(buildingTagsResponse.serviceError?.message ?? buildingTagsResponse.data.title);
    return;
  }

  if (companyTagsResponse.serviceError != null || companyTagsResponse.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(companyTagsResponse.serviceError?.message ?? companyTagsResponse.data.title);
    return;
  }

  if (contactTagsResponse.serviceError != null || contactTagsResponse.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(contactTagsResponse.serviceError?.message ?? contactTagsResponse.data.title);
    return;
  }

  yield put({
    type: actionTypes.GET_TAGS_SUCCESS,
    buildingTags: buildingTagsResponse.data,
    companyTags: companyTagsResponse.data,
    contactTags: contactTagsResponse.data,
  });
}

function* handleGetSingleTag(action) {
  let response = null;
  switch (action.tagType) {
    case TagType.Building: {
      response = yield call(API.fetchGetSingleBuildingTag, action.id);
      break;
    }
    case TagType.Company: {
      response = yield call(API.fetchGetSingleCompanyTag, action.id);
      break;
    }
    case TagType.Contact: {
      response = yield call(API.fetchGetSingleContactTag, action.id);
      break;
    }
    default:
      break;
  }

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_TAGS_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.ADD_OR_UPDATE_TAG,
    tag: response.data,
    tagType: action.tagType,
  });
}

function* watcherTagSaga() {
  yield takeLatest(actionTypes.GET_TAGS_REQUESTED, handleGetTags);
  yield takeLatest(actionTypes.GET_SINGLE_TAG_REQUESTED, handleGetSingleTag);
}

export default watcherTagSaga;
