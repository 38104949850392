import { Badge, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { iconButton } from '@/shared/utils/constants';
import ROUTES from '@/containers/App/Router/Routes';
import useDayjs from '@/shared/hooks/useDayjs';
import useGetRecentBlogs from '@/queries/blogs/useGetRecentBlogs';
import { useUserRole } from '@/queries/index';

import { BlogIcon } from '@/shared/icons/Icons';

const menuFooterStyle = 'flex justify-center text-xs uppercase text-red-500';

const TopbarBlogNotifications = () => {
  const { dayjs } = useDayjs();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAdmin } = useUserRole();

  const { recentBlogs, recentBlogsIsLoading } = useGetRecentBlogs();

  return (
    <Dropdown>
      <Badge badgeContent={recentBlogs.length} badgeInset={iconButton.badge.INSET}>
        <MenuButton
          className="rounded-full"
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain' } }}
        >
          <BlogIcon />
        </MenuButton>
      </Badge>
      <Menu className="w-80" placement="bottom-end">
        {!recentBlogsIsLoading &&
          recentBlogs.map(({ id, timeStamp, releaseNbr, description, url }) => (
            <MenuItem
              key={id}
              className="flex justify-between space-x-2 p-2 text-xs"
              onClick={(e) => window.open(url, '_blank')}
            >
              <p>
                <span className="text-red-500">{releaseNbr}</span> - {description}
              </p>
              <p className="self-start">{dayjs(timeStamp).format('DD/MM')}</p>
            </MenuItem>
          ))}
        {!recentBlogs.length && <MenuItem className={menuFooterStyle}> {t('noNewItems')}</MenuItem>}
        {isAdmin && !!recentBlogs.length && (
          <MenuItem
            onClick={() => {
              navigate(ROUTES.ADMINISTRATION);
            }}
            className={menuFooterStyle}
          >
            {t('showAll')}
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

export default TopbarBlogNotifications;
