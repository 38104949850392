import {
  AutomaticMessageTemplate,
  CreateManualMessageTemplateData,
  ManualMessageTemplate,
  UpdateManualMessageTemplateData,
} from '@/types/messageTemplates';
import createRestClient from '../restClient';
import { ResolvedTemplateResponse } from '~/common/types/email/template';

const restClient = createRestClient();

const getAutomaticMessageTemplates: () => Promise<AutomaticMessageTemplate[]> = () =>
  restClient.get('team/automatic-message-templates');

const getManualMessageTemplates: () => Promise<ManualMessageTemplate[]> = () =>
  restClient.get('team/message-templates');

const getManualMessageTemplate: (id: string) => Promise<ManualMessageTemplate> = (id) =>
  restClient.get(`team/message-template?templateId=${id}`);

const getAutomaticMessageTemplate = (id: string) =>
  restClient.get(`team/automatic-message-template?templateId=${id}`);

const getMessageTemplateReceivers = (context: string) =>
  restClient.get(`enum/message-template/receivers${context != null ? `?context=${context}` : ''}`);

const getMessageTemplateTriggers = (context: string) =>
  restClient.get(`enum/message-template/triggers${context != null ? `?context=${context}` : ''}`);

const getMessageTemplateIdentifiers = (context: string) =>
  restClient.get(
    `enum/message-template/identifiers${context != null ? `?context=${context}` : ''}`,
  );

const deleteMessageTemplate = (templateId: string) =>
  restClient.delete(`team/message-template?templateId=${templateId}`);

const getMessageTemplateContentResolve = (templateId: string, caseId: string) =>
  restClient.get<ResolvedTemplateResponse>(
    `/messagetemplate/resolve-content/${templateId}/case/${caseId}`,
  );

const createAutomaticMessageTemplate = (data: any) =>
  restClient.post('team/automatic-message-template', data);

const createManualMessageTemplate = (data: CreateManualMessageTemplateData) =>
  restClient.post('team/message-template', data);

const updateAutomaticMessageTemplate = (data: any) =>
  restClient.put('team/automatic-message-template', data);

const updateManualMessageTemplate = (data: UpdateManualMessageTemplateData) =>
  restClient.put('team/message-template', data);

const updateAutomaticMessageTemplateContent = (data: any) =>
  restClient.put('team/automatic-message-template/content', data);

export default {
  createAutomaticMessageTemplate,
  createManualMessageTemplate,
  deleteMessageTemplate,
  getAutomaticMessageTemplate,
  getAutomaticMessageTemplates,
  getManualMessageTemplate,
  getManualMessageTemplates,
  getMessageTemplateContentResolve,
  getMessageTemplateIdentifiers,
  getMessageTemplateReceivers,
  getMessageTemplateTriggers,
  updateAutomaticMessageTemplate,
  updateAutomaticMessageTemplateContent,
  updateManualMessageTemplate,
};
