import PropTypes from 'prop-types';

const PhoneCallToUpdatePropTypes = PropTypes.shape({
  id: PropTypes.string,
  calleeRelationId: PropTypes.string,
  callDate: PropTypes.string,
  note: PropTypes.string,
  phoneCallOutcome: PropTypes.string,
  isInbound: PropTypes.bool,
});

export default PhoneCallToUpdatePropTypes;
