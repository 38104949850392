import * as yup from 'yup';
import { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';

import PhoneCallOutcome from '../../shared/enums/PhoneCallOutcome';
import PhoneCallToUpdatePropTypes from '../../shared/prop-types/PhoneCallToUpdatePropTypes';
import PhoneCallType from '../../shared/enums/PhoneCallType';

import { getToday, getTodayUtc } from '../../shared/utils/helpers';
import { CaseContext } from '../Cases/CaseContext';
import FormikCaseEntityOptions from '../../shared/components/2.0/formik/FormikCaseEntityOptions';
import FormModal from '../../shared/components/2.0/layout/FormModal';
import PhoneCallFormBody from './PhoneCallFormBody';
import useRegisterPhoneCallMutation from '../../mutations/case/useRegisterPhoneCallMutation';
import useUpdatePhoneCallMutation from '../../mutations/case/useUpdatePhoneCallMutation';

const propTypes = {
  canEditAccess: PropTypes.bool,
  phoneCallToUpdate: PhoneCallToUpdatePropTypes,
  toggle: PropTypes.shape({
    hide: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
};

const PhoneCallModal = ({ toggle, phoneCallToUpdate = null, canEditAccess = true }) => {
  const { currentCase } = useContext(CaseContext);
  const { t } = useTranslation();
  const popperContainer = useRef();

  const [phoneCallType, setPhoneCallType] = useState(
    phoneCallToUpdate?.isInbound === false ? PhoneCallType.Outbound : PhoneCallType.Inbound,
  );

  const { registerPhoneCall, isRegisteringPhoneCall } = useRegisterPhoneCallMutation({
    callback: toggle.hide,
  });
  const { updatePhoneCall, isUpdatingPhoneCall } = useUpdatePhoneCallMutation({
    callback: toggle.hide,
  });

  const handleSubmit = async (phoneCall) => {
    if (isUpdatingPhoneCall || isRegisteringPhoneCall) {
      return;
    }

    const requestData = {
      callDate: phoneCall.callDate,
      calleeRelationId: phoneCall.calleeRelationId,
      caseId: currentCase.id,
      caseStatus: phoneCall.caseStatus,
      isInbound: phoneCallType === PhoneCallType.Inbound,
      note: phoneCall.note,
      outcome:
        phoneCallType === PhoneCallType.Outbound
          ? phoneCall.phoneCallOutcome
          : PhoneCallOutcome.Success,
      showOnClientPage: phoneCall.showOnClientPage,
      tasks: phoneCall.tasks,
      teamsWithAccess: phoneCall.teamsWithAccess,
    };

    if (phoneCallToUpdate) {
      requestData.phoneCallId = phoneCallToUpdate.id;
      updatePhoneCall(requestData);
    } else {
      registerPhoneCall(requestData);
    }
  };

  const handleChange = (event, callType) => {
    setPhoneCallType(callType);
  };

  return (
    <FormModal
      size="lg"
      toggle={toggle}
      handleSubmit={handleSubmit}
      isMutating={isRegisteringPhoneCall || isUpdatingPhoneCall}
      initialValues={{
        callDate: phoneCallToUpdate
          ? phoneCallToUpdate.callDate
          : getTodayUtc().format('YYYY-MM-DDTHH:mm:ss'),
        calleeRelationId: phoneCallToUpdate ? phoneCallToUpdate.calleeRelationId : '',
        note: phoneCallToUpdate ? phoneCallToUpdate.note : '',
        phoneCallOutcome: phoneCallToUpdate ? phoneCallToUpdate.phoneCallOutcome : null,
        task: null,
      }}
      validationSchema={yup.object().shape({
        callDate: yup
          .date()
          .max(getToday().endOf('day'), t('errors:dateCannotBeInTheFuture'))
          .nullable()
          .required(t('errors:fieldIsRequired')),
        calleeRelationId: yup.string().nullable().required(t('errors:fieldIsRequired')),
        phoneCallOutcome: yup
          .string()
          .nullable()
          .test(
            'phoneCallOutcome',
            t('errors:fieldIsRequired'),
            (value) => !(value == null && phoneCallType === PhoneCallType.Outbound),
          ),
        task: yup
          .object()
          .nullable()
          .test('taskDataShouldBeValid', (value) => (value && value[0] ? value[0].isValid : true)),
      })}
      headerComponent={
        <ToggleButtonGroup
          className="mb-3"
          size="large"
          value={phoneCallType}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton size="large" value={PhoneCallType.Inbound}>
            {t('common:inboundPhoneCall')}
          </ToggleButton>
          <ToggleButton size="large" value={PhoneCallType.Outbound}>
            {t('common:outboundPhoneCall')}
          </ToggleButton>
        </ToggleButtonGroup>
      }
      title={t('common:addTelephoneCall')}
    >
      <PhoneCallFormBody
        caseId={currentCase.id}
        isOutboundCall={phoneCallType === PhoneCallType.Outbound}
        popoverAnchorRef={popperContainer}
      />
      <FormikCaseEntityOptions entity={phoneCallToUpdate} canEditAccess={canEditAccess} />

      <div ref={popperContainer} />
    </FormModal>
  );
};

PhoneCallModal.propTypes = propTypes;

export default PhoneCallModal;
