import { useQuery } from '@tanstack/react-query';

import { companyApi } from '@/shared/api';

import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetCompany = (companyId?: string) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!companyId,
    onError: sendDefaultError,
    queryFn: () => companyApi.getCompany(companyId!),
    queryKey: [queryIds.companies.GET_COMPANY, companyId!],
  });

  return {
    company: data || null,
    companyIsLoading: isLoading && !!companyId,
  };
};

export default useGetCompany;
