import config from '@/config/config';
import createRestClient from '../restClient';

const restClient = createRestClient(config.baseURL);

const getBlogs = () => restClient.get('/blog/list');

const getRecentBlogs = () => restClient.get('/blog/recent');

export default {
  getBlogs,
  getRecentBlogs,
};
