import { Card, Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '@/shared/utils/helpers';
import FormikPolicySelector from '@/shared/formik/FormikPolicySelector';
import { InsurancePolicyIconPretty } from '@/shared/icons/Icons';

const propTypes = {
  onSuggestedPoliciesFound: PropTypes.func,
};

const CaseDataStep = ({ onSuggestedPoliciesFound = () => {} }) => {
  const { t } = useTranslation('common');
  const [suggestedPolicies, setSuggestedPolicies] = useState([]);

  const { values } = useFormikContext();
  const { policySelector } = values;

  const policies = !policySelector ? null : policySelector.policies;

  useEffect(() => {
    onSuggestedPoliciesFound(suggestedPolicies);
  }, [suggestedPolicies, onSuggestedPoliciesFound]);

  return (
    <div>
      <h3 style={{ fontWeight: 'bold' }}>{t('common:policy')}</h3>
      <p style={{ marginBottom: '20px', marginTop: '0px' }}>
        {suggestedPolicies !== null && suggestedPolicies.length > 0
          ? t('common:policiesWereFoundInTheDatabaseThatCanBeLinkedToThisBuilding')
          : t('common:noPoliciesWereFoundInTheDatabaseThatCanBeLinkedToThisBuilding')}
      </p>
      <FormikPolicySelector
        label={t('common:policies')}
        id="policySelector"
        isAddress={values.buildingSelector?.isAddress}
        name="policySelector"
        onSuggestedPoliciesFound={(suggested) => setSuggestedPolicies(suggested)}
        address={values.buildingSelector?.address}
        buildingId={values.buildingSelector?.id}
        disablePortal={false}
      />
      <Card
        style={{
          margin: 'auto',
          marginTop: '30px',
          width: '70%',
        }}
      >
        {policies !== null && policies.length > 0 && (
          <div>
            {policies.map((p) => (
              <div key={p.id} style={{ display: 'flex', marginTop: '20px' }}>
                <div style={{ display: 'inline-block', marginRight: '25px' }}>
                  <InsurancePolicyIconPretty />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Row>
                    <Col xs={4} md={4}>
                      {t('common:policyType')}
                    </Col>
                    <Col>
                      {p.insurancePolicyType
                        ? t(`insurancePolicy:${decapitalize(p.insurancePolicyType)}`)
                        : '-'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} md={4}>
                      {t('common:policyNumber')}
                    </Col>
                    <Col>{p.policyNumber ?? '-'}</Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

CaseDataStep.propTypes = propTypes;

export default CaseDataStep;
