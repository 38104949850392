export default {
  ACCOUNTING_ENVELOPES: '/accounting/envelopes',
  ACCOUNTING_STATEMENTS: '/accounting/statements',
  ADMINISTRATION: '/administration',
  ADMINISTRATION_BLOG: 'blog',
  ADMINISTRATION_FSMA: 'fsma',
  ADMINISTRATION_KBO: 'kbo',
  ADMINISTRATION_SMS: 'sms',
  ADMINISTRATION_UNUSED_OCTOPUS_DOSSIERS: 'octopus/unused-dossiers',
  BATCH_COMMUNICATION_COMBINED_OVERVIEW: '/batches/created',
  BATCH_COMMUNICATION_DETAIL: '/batch/:batchId',
  BATCH_COMMUNICATION_OVERVIEW: '/batches',
  BUILDING_DETAIL: '/building/:buildingId',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL: 'homeowner-association/:homeownerAssociationId',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_BUDGET: 'budget',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_COMMUNICATION: 'communication',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_COSTS: 'costs',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_DOCUMENTS: 'documents',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_FINANCE: 'finance',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_MEETINGS: 'meetings',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_METERREADINGS: 'meter-readings',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_PROVISIONS: 'provisions',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_SETTINGS: 'settings',
  BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_SYNDICMANAGEMENT: 'syndicManagement',
  BUILDING_UNIT_DETAIL: '/building/:buildingId/unit/:unitId',
  BUILDINGS_OVERVIEW: '/buildings',
  CALENDAR: '/calendar',
  CALLBACK: '/callback',
  CASE_DETAIL: '/case/:caseId',
  CASE_DETAIL_NAVIGATE: '/case',
  CASE_MAILBOX: 'mailbox',
  CASE_OVERVIEW: '/cases',
  CASE_OVERVIEW_SEARCH: '/cases?search=',
  COMPANIES_OVERVIEW: '/companies',
  COMPANY_DETAIL: '/company/:companyId',
  CONTACT_DASHBOARD: '/contacts',
  CONTACT_DETAIL: '/contact/:contactId',
  CONTACT_EDIT: '/contact/:contactId/edit',
  CONTRACT_DETAIL: '/contract/:contractId',
  DETAILS_MAILBOX: 'mailbox',
  FINANCE: '/finance/:caseGroupId',
  HOA_DEBTOR_MANAGEMENT: '/debtors/hoa',
  HOME: '/',
  HOMEOWNER_ASSOCIATION_DETAIL: '/homeowner-association/:homeownerAssociationId',
  HOMEOWNER_ASSOCIATION_DETAIL_BUDGET: 'budget',
  HOMEOWNER_ASSOCIATION_DETAIL_COMMUNICATION: 'communication',
  HOMEOWNER_ASSOCIATION_DETAIL_COSTS: 'costs',
  HOMEOWNER_ASSOCIATION_DETAIL_DOCUMENTS: 'documents',
  HOMEOWNER_ASSOCIATION_DETAIL_FINANCE: 'finance',
  HOMEOWNER_ASSOCIATION_DETAIL_MEETINGS: 'meetings',
  HOMEOWNER_ASSOCIATION_DETAIL_METERREADINGS: 'meter-readings',
  HOMEOWNER_ASSOCIATION_DETAIL_PROVISIONS: 'provisions',
  HOMEOWNER_ASSOCIATION_DETAIL_SETTINGS: 'settings',
  HOMEOWNER_ASSOCIATION_DETAIL_SYNDICMANAGEMENT: 'syndicManagement',
  HOMEOWNER_ASSOCIATION_MEETING_DETAIL:
    '/homeowner-association/:homeownerAssociationId/meeting/:meetingId',
  INSIGHTS: '/insights',
  INVOICE_MANAGEMENT: '/invoices',
  INVOICE_UPLOAD: '/invoice',
  INVOICE_VALIDATE: '/invoice/:invoiceId/validate',
  INVOICES_TO_VALIDATE: '/invoices#toValidate',
  MAILBOX: '/mailbox',
  MEETING_DETAIL: '/meeting/:meetingId',
  MEETING_DETAIL_NAVIGATE: '/meeting',
  MEETING_IN_PROGRESS:
    '/homeowner-association/:homeownerAssociationId/meeting/:meetingId/meeting-in-progress',
  MEETING_REGISTRATION:
    '/homeowner-association/:homeownerAssociationId/meeting/:meetingId/meeting-registration',
  MEETINGS_OVERVIEW: '/meetings',
  MEETINGS_PLANNING: '/meetings/planning',
  NOTFOUND: '/notfound',
  POLICY_DETAIL: '/policy/:policyId',
  POLICY_OVERVIEW: '/policies',
  REPAIR_DEBTOR_MANAGEMENT: '/debtors/repair',
  SPREADSHEET_DETAIL: '/spreadsheet/:spreadsheetId',
  SUPPORT_OVERVIEW: '/support',
  TASKS_OVERVIEW: '/tasks',
  TEAM_SETTINGS: '/team/settings',
  TEAM_SETTINGS_ASSETS: 'assets',
  TEAM_SETTINGS_BUILDING_PORTAL: 'building-portal',
  TEAM_SETTINGS_CASE: 'case',
  TEAM_SETTINGS_COMMUNICATION: 'communication',
  TEAM_SETTINGS_EMAILACCOUNT_DETAIL: 'email-accounts',
  TEAM_SETTINGS_FINANCE: 'finance',
  TEAM_SETTINGS_GENERAL: 'general',
  TEAM_SETTINGS_INTEGRATIONS: 'integrations',
  TEAM_SETTINGS_LABELS: 'labels',
  TEAM_SETTINGS_MEETINGS: 'meetings',
  TEAM_SETTINGS_MEETINGS_MEETINGTYPE_DETAIL: 'meeting-type/:meetingTypeId',
  TEAM_SETTINGS_PERFORMANCES: 'performances',
  TEAM_SETTINGS_REPAIR: 'repair',
  TEAM_SETTINGS_TASKTYPES: 'task-types',
  TEAM_SETTINGS_TEAMMEMBERS: 'team-members',
  USER_SETTINGS: '/user/settings',
  USER_SETTINGS_CALENDARS: 'calendars',
  USER_SETTINGS_EMAILACCOUNTS: 'email-accounts',
  USER_SETTINGS_GENERAL: 'general',
};
