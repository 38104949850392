import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import API from '../../../api/ApiService';

const TeamUserFilter = ({ callback, name, defaultValue, label }) => {
  const { t } = useTranslation('common');

  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await API.fetchGetTeamUsers();

      if (response.serviceError != null || response.status !== 200) {
        setTeamUsers([]);
        return;
      }

      setTeamUsers(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    }

    getData();
  }, []);

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={teamUsers}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:users')} />
      )}
    />
  );
};

TeamUserFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      teamName: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
};

TeamUserFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
  label: null,
};

export default TeamUserFilter;
