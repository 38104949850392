import styled from 'styled-components';
import { colorAccent, colorAdditional } from '../../utils/palette';

const ChatActionButton = styled.button`
  background: transparent;
  border: none;
  height: 24px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;

  svg {
    height: 12px;
    width: 12px;
    color: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {
    svg {
      color: ${colorAccent};
    }
  }
`;

export default ChatActionButton;
