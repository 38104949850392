import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

import { Link } from '@mui/joy';
import { useAuth } from 'react-oidc-context';

import { OpenIcon } from '@/shared/icons/Icons';
import TwoSidedLayout from '../TwoSidedLayout';

import logo from '~/frontend/shared/img/logo-vera.png';

type Props = {
  teaser: string;
};

const HeroLeft01: React.FC<Props> = ({ teaser }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <TwoSidedLayout teaser={teaser}>
      <Typography
        endDecorator={<img src={logo} alt="vera-logo" />}
        level="h1"
        className="space-y-4 font-medium text-primary"
      >
        {t('app.title')}
      </Typography>
      <Typography className="font-medium">{t('app.startPage.hero01.subtitle')}</Typography>
      <Typography className="text-md">{t('app.startPage.hero01.description')}</Typography>

      <Button size="lg" endDecorator={<OpenIcon />} onClick={() => auth.signinRedirect()}>
        {t('app.actions.signIn.short')}
      </Button>

      <Typography className="font-normal">
        {t('app.startPage.hero01.member')}{' '}
        <Link href="https://auth.keypoint.be/Account/RequestRegistrationCode">
          {t('app.startPage.hero01.register')}
        </Link>
      </Typography>
    </TwoSidedLayout>
  );
};

export default HeroLeft01;
