import { RelationType } from '~/common/enums';
import { RouterOutputs } from '@/config/trpc';

type CaseRouter = RouterOutputs['case'];

export type Case = CaseRouter['byId'];

export type CaseRelation = Case['relations'][number];

export type RelationOption = CaseRouter['relationOptions'][number];

export const isTeamRelationOption = (
  option: RelationOption,
): option is RelationOption & { companyId: string } => option.type === RelationType.Team;
