import { ColorPaletteProp, IconButton } from '@mui/joy';
import { hideDetailsRow, showDetailsRow } from 'ka-table/actionCreators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type KeypointTableDetailsButtonProps = {
  dispatch: (action: Record<string, unknown>) => void;
  rowKeyValue: unknown;
  isDetailsRowShown: boolean;
  customIconComponent?: React.ReactNode;
  disabled?: boolean;
  color?: ColorPaletteProp;
  invisible?: boolean;
};

const KeypointTableDetailsButton: React.FC<KeypointTableDetailsButtonProps> = ({
  dispatch,
  rowKeyValue,
  isDetailsRowShown,
  customIconComponent = null,
  disabled = false,
  color = 'neutral',
  invisible = false,
}) => (
  <IconButton
    style={{
      display: 'inline',
    }}
    className={invisible ? 'invisible' : ''}
    variant="plain"
    disabled={disabled}
    color={color}
    onClick={(e) => {
      e.stopPropagation();
      dispatch(isDetailsRowShown ? hideDetailsRow(rowKeyValue) : showDetailsRow(rowKeyValue));
    }}
  >
    {customIconComponent ?? (
      <FontAwesomeIcon
        size="1x"
        icon={['fas', isDetailsRowShown ? 'chevron-up' : 'chevron-down']}
        color={color}
      />
    )}
  </IconButton>
);

export default KeypointTableDetailsButton;
