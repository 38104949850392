const PolicyStatus = {
  valid: 'Valid',
  validUntil: 'ValidUntil',
  validFromFirstPayment: 'ValidFromFirstPaymentOnwards',
  suspended: 'Suspended',
  cancelled: 'Cancelled',
  terminated: 'Terminated',
};

export default PolicyStatus;
