import { Accept, useDropzone } from 'react-dropzone';
import { Chip, FormControl, FormHelperText } from '@mui/joy';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import FileItem from '../2.0/FileItem';

type FileInputProps = {
  maxFiles?: number;
  maxFileSize?: number;
  accept?: Accept;
  callback?: (files: File[]) => void;
  clearFilesAfterUpload?: boolean;
  showSelected?: boolean;
  bodyText?: Nullable<string>;
  showIcon?: boolean;
  allowRemove?: boolean;
};

const FileInput: React.FC<FileInputProps> = ({
  allowRemove = true,
  maxFiles = 0,
  maxFileSize,
  accept = {},
  callback = () => {},
  clearFilesAfterUpload = false,
  showSelected = false,
  bodyText = null,
  showIcon = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [filesAdded, setFilesAdded] = useState(false);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    maxSize: maxFileSize,
    onDrop: () => {
      setFilesAdded(false);
    },
  });

  const { t } = useTranslation(['common', 'errors']);

  useEffect(() => {
    if (acceptedFiles.length && !filesAdded) {
      setFilesAdded(true);
      if (maxFiles === 1) {
        setFiles(acceptedFiles);
        callback(acceptedFiles);
        return;
      }

      const unAddedFiles = acceptedFiles.filter(
        (file) => !files.some((f) => f.name === file.name && f.size === file.size),
      );

      if (clearFilesAfterUpload) {
        setFiles([]);
        callback(unAddedFiles);
      } else {
        setFiles([...files, ...unAddedFiles]);
        callback([...files, ...unAddedFiles]);
      }
    }
  }, [acceptedFiles, clearFilesAfterUpload, files, filesAdded, maxFiles]);

  const removeFile = (fileToRemove: File) => {
    const newFiles = files.filter(
      (file) =>
        file.name !== fileToRemove.name ||
        (file.name === fileToRemove.name && file.size !== fileToRemove.size),
    );
    setFiles(newFiles);
    callback(newFiles);
  };

  return (
    <FormControl error={fileRejections.length > 0} className="mb-2">
      <div {...getRootProps({ className: 'dropzone mb-2 border-2 border-red-500 border-dashed' })}>
        <input {...getInputProps()} />
        <div>
          {showIcon && <FontAwesomeIcon icon={['fad', 'arrow-down-to-square']} size="5x" />}
          <p>{bodyText ?? t('common:uploadFiles')}</p>
        </div>
      </div>
      {showSelected && (
        <div className="flex flex-wrap gap-2">
          {files.map((file) => (
            <FileItem
              key={`${file.name}-${file.size}`}
              file={{ contentType: file.type, fileName: file.name, fileSize: file.size }}
              onRemove={allowRemove ? () => removeFile(file) : undefined}
            />
          ))}
        </div>
      )}
      {fileRejections.length > 0 && (
        <>
          <FormHelperText className="mt-4">{t('errors:errors')}</FormHelperText>
          <div className="mt-2 flex flex-col gap-2">
            {fileRejections.map((rejection) => (
              <Chip key={rejection.file.name} variant="soft" color="danger">
                {rejection.file.name} - {rejection.errors.map((error) => error.message).join(', ')}
              </Chip>
            ))}
          </div>
        </>
      )}
    </FormControl>
  );
};

export default FileInput;
