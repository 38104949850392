import { Button, Chip, ChipDelete, FormHelperText } from '@mui/joy';
import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../api/ApiService';
import { AppContext } from '@/shared/context/context';

import { AdministratorIcon } from '../../../icons/Icons';
import AvatarWithColor from '../AvatarWithColor';
import TextField from '../input/TextField';
import Tooltip from '../Tooltip';

const ITEM_LIMIT = 5;

const propTypes = {
  callback: PropTypes.func.isRequired,
  caseId: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  selectSingle: PropTypes.bool,
  teamUserIdsToExclude: [],
  usersToSelect: PropTypes.arrayOf(PropTypes.string),
};

const SelectTeamUser = ({
  callback,
  caseId,
  disabled,
  isRequired,
  isTouched,
  label,
  selectSingle,
  teamUserIdsToExclude = [],
  usersToSelect,
  ...rest
}) => {
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  const [isSelectOpen, setIsSelectOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const [teamUsers, setTeamUsers] = React.useState([]);
  const [selectedTeamUsers, setSelectedTeamUsers] = React.useState([]);
  const [selectedTeamUsersInited, setSelectedTeamUsersInited] = React.useState(false);
  const collapseSelect = () => {
    setIsSelectOpen(false);
  };

  const fetchCurrentTeamUsers = React.useCallback(async () => {
    const response = await API.fetchGetTeamUsers();

    if (response.serviceError != null || response.status !== 200 || response.data.length < 1) {
      setTeamUsers([]);
      return;
    }

    const current = {
      team: currentTeam.name,
      teamId: currentTeam.id,
      users: response.data.map((r) => ({
        isAdmin: false,
        name: r.name,
        teamUserId: r.id,
      })),
    };
    setTeamUsers([current]);
  }, [currentTeam]);

  const fetchCaseTeamUsers = React.useCallback(async () => {
    const response = await API.fetchGetTeamUsersWithAccessToCase(caseId);

    if (response.serviceError != null || response.status !== 200) {
      setTeamUsers([]);
      return;
    }

    if (response.data.length > 0) {
      const teamsWithAccess = [];
      for (let i = 0; i < response.data.length; i += 1) {
        const item = response.data[i];
        if (!teamsWithAccess.some((tu) => tu.teamId === item.teamId)) {
          teamsWithAccess.push({
            team: item.team,
            teamId: item.teamId,
            users: response.data
              .filter((r) => r.teamId === item.teamId)
              .map((r) => ({
                isAdmin: r.isAdmin,
                name: r.username,
                teamUserId: r.teamUserId,
              })),
          });
        }
      }
      setTeamUsers(teamsWithAccess);
    } else {
      setTeamUsers([]);
    }
  }, [caseId]);

  React.useEffect(() => {
    if (caseId == null) {
      fetchCurrentTeamUsers();
    } else {
      fetchCaseTeamUsers();
    }
  }, [caseId, fetchCaseTeamUsers, fetchCurrentTeamUsers]);

  const handleSelect = (team, teamId, user) => {
    const { name, teamUserId, isAdmin } = user;
    const currentlySelected = selectedTeamUsers;
    const updated = {
      isAdmin,
      name,
      team,
      teamId,
      teamUserId,
    };
    let updatedList = null;
    if (!selectSingle) {
      updatedList = [...currentlySelected, updated];
    } else {
      updatedList = [updated];
    }
    setSelectedTeamUsers(updatedList);
    let callbackValue;
    if (selectSingle) {
      callbackValue = updatedList[0]?.teamUserId;
    } else {
      callbackValue = updatedList.map((u) => u.teamUserId);
    }
    callback(callbackValue);
    setQuery('');
  };

  const getSelectedChips = () => {
    if (!selectedTeamUsers || selectedTeamUsers.length === 0) {
      return null;
    }

    const handleDeselect = (teamUserId) => {
      const currentlySelected = selectedTeamUsers;
      const updated = currentlySelected.filter((s) => s.teamUserId !== teamUserId);
      setSelectedTeamUsers(updated);
      callback(updated.map((u) => u.teamUserId));
    };

    return selectedTeamUsers.map((u) => {
      const { team, teamUserId, name, isAdmin } = u;
      const display = isAdmin ? (
        <div className="flex items-center">
          <AvatarWithColor name={name} size="sm" />
          <FontAwesomeIcon icon={['fad', 'user-crown']} className="ms-1" />
        </div>
      ) : (
        <AvatarWithColor name={name} size="sm" />
      );

      return (
        <Tooltip key={teamUserId} disableFocusListener title={`${name} (${team})`}>
          <Chip
            variant="outlined"
            className="mr-1"
            startDecorator={display}
            endDecorator={
              <ChipDelete disabled={disabled} onClick={() => handleDeselect(teamUserId)} />
            }
          />
        </Tooltip>
      );
    });
  };

  React.useEffect(() => {
    if (
      selectedTeamUsersInited ||
      usersToSelect == null ||
      usersToSelect.length === 0 ||
      teamUsers == null ||
      teamUsers.length === 0
    ) {
      return;
    }

    let addedTeamUsers = [];
    teamUsers.forEach((team) =>
      team.users
        .filter((tu) => usersToSelect.includes(tu.teamUserId))
        .forEach((tu) => {
          const { name, teamUserId, isAdmin } = tu;
          addedTeamUsers = [
            ...addedTeamUsers,
            {
              isAdmin,
              name,
              team: team.team,
              teamId: team.teamId,
              teamUserId,
            },
          ];
        }),
    );
    setSelectedTeamUsers(addedTeamUsers);
    setSelectedTeamUsersInited(true);
  }, [selectedTeamUsersInited, teamUsers, usersToSelect]);

  const anchorRef = React.useRef();

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });
  return (
    <ClickAwayListener onClickAway={collapseSelect}>
      <div className="custom__selector" {...rest}>
        <div className="flex grow-0 items-end">
          <TextField
            label={label}
            isRequired={isRequired}
            inputClassName={textFieldClasses}
            id="input-with-sx"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
            onFocus={() => setIsSelectOpen(true)}
            autoComplete="off"
            disabled={disabled}
            error={isTouched && isRequired && !selectedTeamUsers.length}
            helperText={
              isTouched && isRequired && !selectedTeamUsers.length
                ? t('errors:fieldIsRequired')
                : ''
            }
            startDecorator={getSelectedChips()}
            textfieldRef={anchorRef}
          />
        </div>
        <PopperUnstyled
          open={isSelectOpen}
          className="custom__selector-wrap"
          anchorEl={anchorRef?.current}
          placement="bottom-start"
        >
          <div className="max-h-[40vh] overflow-y-auto rounded-xl bg-white p-4 shadow-xl">
            {teamUsers
              .filter(
                (tu) =>
                  tu.team.toLowerCase().includes(query.toLowerCase()) ||
                  tu.users.some((u) => u.name.toLowerCase().includes(query.toLowerCase())),
              )
              .filter((tu) =>
                tu.users.some((u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId)),
              )
              .map((tu) => {
                const { team, users, teamId } = tu;
                const filteredUsers = users
                  .filter((u) => !teamUserIdsToExclude.some((id) => id === u.teamUserId))

                  .filter((u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId))
                  .filter(
                    (u) =>
                      team.toLowerCase().includes(query.toLowerCase()) ||
                      u.name.toLowerCase().includes(query.toLowerCase()),
                  );

                return (
                  <div key={teamId} className="flex flex-col space-y-4">
                    <h5 className="text-primary">{team}</h5>
                    <div className="flex flex-col space-y-2">
                      {filteredUsers
                        .filter((_, i) => i < ITEM_LIMIT)
                        .map((u) => {
                          const { name, teamUserId, isAdmin } = u;
                          return (
                            <Button
                              className="w-full justify-start"
                              variant="plain"
                              color="neutral"
                              startDecorator={
                                <>
                                  <AvatarWithColor name={name} size="sm" className="mr-1" />
                                  {isAdmin && <AdministratorIcon />}
                                </>
                              }
                              key={teamUserId}
                              onClick={() => handleSelect(team, teamId, u)}
                            >
                              {name}
                            </Button>
                          );
                        })}
                      {filteredUsers.length > ITEM_LIMIT && (
                        <FormHelperText>{t('moreResultsAreHiddenRefineFilter')}</FormHelperText>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </PopperUnstyled>
      </div>
    </ClickAwayListener>
  );
};

SelectTeamUser.propTypes = propTypes;

SelectTeamUser.defaultProps = {
  caseId: null,
  disabled: false,
  isRequired: false,
  isTouched: false,
  label: null,
  selectSingle: false,
  usersToSelect: [],
};

export default SelectTeamUser;
