import * as yup from 'yup';
import { IconButton, Tooltip } from '@mui/joy';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import multiDownload from 'multi-download';
import PropTypes from 'prop-types';
import { SortDirection } from 'ka-table/enums';
import { useTranslation } from 'react-i18next';

import { customCellWidth, localStorageKeys } from '@/shared/utils/constants';
import {
  filterStringWithAutoCompleteFilters,
  getAllowedUploadTypes,
  imageFileTypes,
  pdfFileTypes,
} from '../../../utils/helpers';
import { AppContext } from '@/shared/context/context';
import { FileContext } from '@/FileContext';
import { useBulkUpdateFileAccessibility } from '@/mutations';
import useDocumentTypeOptions from '@/shared/hooks/useDocumentTypeOptions';
import { useGetCase } from '@/queries';
import { useMailboxContext } from '@/containers/Mailbox/store';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';
import useToggleHeight from '@/shared/hooks/useToggleHeight';

import {
  AccessibilityIcon,
  DeleteIcon,
  DownloadIcon,
  EmailIcon,
  FileIcon,
  FoldersIcon,
} from '../../../icons/Icons';
import Autocomplete from '../../2.0/autocomplete/AutoComplete';
import ContentTypeCustomCell from '../../2.0/customTableCells/ContentTypeCustomCell';
import DataTable from '../../2.0/DataTable';
import DateCell from '../../2.0/customTableCells/DateCell';
import DocumentFilenameCustomCell from '../../2.0/customTableCells/DocumentFilenameCustomCell';
import DocumentIdCustomCell from '../../2.0/customTableCells/DocumentIdCustomCell';
import DocumentPreviewModal from './DocumentPreviewModal';
import DocumentRelation from '../../../enums/DocumentRelation';
import DocumentsAccessibilityForm from './DocumentsAccessibilityForm';
import DocumentStatusCustomCell from '../../2.0/customTableCells/DocumentStatusCustomCell';
import DocumentsTypeForm from './DocumentsTypeForm';
import DocumentTypeFilter from './DocumentTypeFilter';
import DocumentUpdateForm from './DocumentUpdateForm';
import FileInput from '../../inputs/FileInput';
import FormSidebarModal from '../../2.0/layout/FormSidebarModal';
import SendCaseEmail from '@/containers/Cases/Email/SendCaseEmail';
import TableRowSelect from '../../2.0/customTableCells/TableRowSelect';

const calculateHeight = (count) => {
  const baseHeight = 205;
  const maxHeight = 445;
  const additionalHeight = 80;

  if (!count) {
    return baseHeight;
  }

  const heightOptions = {
    0: baseHeight,
    1: baseHeight,
    2: baseHeight,
    3: baseHeight + additionalHeight,
    4: baseHeight + 2 * additionalHeight,
    5: maxHeight,
  };

  return heightOptions[count] || maxHeight;
};

const getDefaultTableOptions = (t, canEdit, documentRelation) => ({
  columns: {
    list: [
      {
        allowDeselect: true,
        customCellComponent: TableRowSelect,
        key: 'rowId',
        width: customCellWidth.XS,
      },
      {
        customCellComponent: ContentTypeCustomCell,
        key: 'contentType',
        title: t('preview'),
        width: customCellWidth.S,
      },
      {
        customCellComponent: DocumentFilenameCustomCell,
        key: 'fileName',
        title: t('fileName'),
      },
      {
        key: 'type',
        title: t('type'),
        visible: [DocumentRelation.Case, DocumentRelation.MeetingType].includes(documentRelation),
      },
      {
        key: 'uploadedBy',
        title: t('addedBy'),
        visible: documentRelation !== DocumentRelation.CaseTemplate,
      },
      {
        customCellComponent: DateCell,
        key: 'uploadDate',
        sortDirection: SortDirection.Descend,
        title: t('addedOn'),
        visible: documentRelation !== DocumentRelation.CaseTemplate,
      },
      {
        customCellComponent: DocumentStatusCustomCell,
        key: 'seen',
        title: t('status'),
        visible: ![DocumentRelation.CaseTemplate, DocumentRelation.MeetingType].includes(
          documentRelation,
        ),
        width: customCellWidth.S,
      },
      {
        customCellComponent: DocumentIdCustomCell,
        key: 'id',
        width: 100,
      },
    ],
  },
  filter: {
    component: DocumentTypeFilter,
    defaultFilters: {
      autocomplete: {
        type: [],
      },
    },
    handler: ({ data, filters }) => {
      if (!data || !filters) {
        return data;
      }

      const filteredByType = filterStringWithAutoCompleteFilters({
        data,
        filters: filters.autocomplete.type,
        key: 'type',
      });

      return filteredByType;
    },
    localStorageKey: localStorageKeys.tags.FILTERS,
  },
  rowKeyField: 'id',
  search: ({ searchText, rowData }) => {
    const queries = searchText.split(' ');
    return queries.every((query) => rowData.fileName.toLowerCase().includes(query));
  },
});

const useDocumentUpdateValidation = () => {
  const { t } = useTranslation();

  return yup.object({
    fileName: yup.string().nullable().required(t('errors:fieldIsRequired')),
    type: yup.string().nullable(),
  });
};

const propTypes = {
  canEdit: PropTypes.bool,
  canHandleAccess: PropTypes.bool.isRequired,
  caseId: PropTypes.string,
  documentRelation: PropTypes.oneOf([
    DocumentRelation.Case,
    DocumentRelation.CaseTemplate,
    DocumentRelation.Contact,
    DocumentRelation.Building,
    DocumentRelation.MeetingType,
    DocumentRelation.Policy,
    DocumentRelation.Spreadsheet,
    DocumentRelation.Unit,
    DocumentRelation.Meeting,
  ]).isRequired,
  isBulkDeleteAllowed: PropTypes.bool,
  onCreateFilesCallback: PropTypes.func,
  onDeleteFileCallback: PropTypes.func,
  showIfNoFiles: PropTypes.bool,
};

const { VITE_MAX_FILE_SIZE, VITE_MAX_FILES_PER_ENTITY } = import.meta.env;

const DocumentTab = ({
  caseId,
  documentRelation,
  canEdit = true,
  canHandleAccess = true,
  isBulkDeleteAllowed = false,
  onCreateFilesCallback = () => {},
  onDeleteFileCallback = () => {},
  showIfNoFiles = true,
}) => {
  const [fileToPreview, setFileToPreview] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [documentType, setDocumentType] = useState('');

  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();
  const { sendNotification } = useNotification();
  const { files, createFiles, updateFile, updateFilesType, deleteFiles, isMutating } =
    useContext(FileContext);
  const { updateState } = useMailboxContext();

  const { currentCase } = useGetCase(caseId);
  const { bulkUpdateFileAccessibility, isBulkUpdatingFileAccessibility } =
    useBulkUpdateFileAccessibility();

  const documentTypeSuggestions = useDocumentTypeOptions();
  const documentUpdateValidationSchema = useDocumentUpdateValidation();

  const createToggle = useToggle();
  const deleteToggle = useToggle();
  const accessibilityToggle = useToggle();
  const editTableRowToggle = useToggle();
  const filterToggle = useToggleHeight({
    localStorageKey: localStorageKeys.tags.SHOW_FILTERS,
    offsetY: 280,
  });
  const sendEmailToggle = useToggle();
  const changeTypeToggle = useToggle();
  const documentPreviewToggle = useToggle();

  const caseTeamsOptions = useMemo(() => {
    if (currentCase && currentCase.relations) {
      return currentCase.relations
        .filter((rel) => !!rel.teamId)
        .map((rel) => ({ label: rel.displayName || '-', value: rel.teamId }));
    }
    return [];
  }, [currentCase]);

  const [teamsWithAccess, setTeamsWithAccess] = useState(caseTeamsOptions);

  const isSpreadsheetFiles = documentRelation === DocumentRelation.Spreadsheet;

  const downloadFiles = () => {
    const selectedIds = selectedItems.map((selectedItem) => selectedItem.id);
    const filesToLoad = files.filter((f) => selectedIds.includes(f.id)).map((f) => f.fileUri);

    multiDownload(filesToLoad, {
      rename: ({ index }) => filesToLoad[index].fileName,
    });
  };

  useEffect(() => {
    if (
      (deleteToggle.value || accessibilityToggle.value || changeTypeToggle.value) &&
      selectedItems.some((doc) => doc.readOnly)
    ) {
      sendNotification({
        header: t('warning'),
        message: t('notAuthorizedToModifyDocuments'),
        variant: 'warning',
      });
      changeTypeToggle.hide();
      deleteToggle.hide();
      accessibilityToggle.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteToggle.value, accessibilityToggle.value, changeTypeToggle.value]);

  const areSelectedEditable = useMemo(
    () => selectedItems.every((doc) => !doc.readOnly),
    [selectedItems],
  );

  const initialDocumentData = useMemo(() => {
    const item = files.find((file) => file.id === selectedId);
    return item ? { fileName: item.fileName, type: item.type } : null;
  }, [selectedId, files]);

  const openDocumentPreview = useCallback(
    (id) => {
      const file = files.find((f) => f.id === id);

      setFileToPreview({ ...file, parentId: file.id });
      documentPreviewToggle.show();
    },
    [files],
  );

  const handleUpdateFile = async (values) => {
    updateFile(
      { id: selectedId, ...values },
      {
        onSuccess: () => {
          createToggle.hide();
        },
      },
    );
  };

  const handleUpdateFilesType = async (values) => {
    updateFilesType(
      { fileIds: selectedItems.map((item) => item.id), newType: values.type },
      {
        onSuccess: () => {
          changeTypeToggle.hide();
        },
      },
    );
  };

  const handleAccessibilityBulkUpdate = async (values) => {
    bulkUpdateFileAccessibility(
      { caseId, fileIds: selectedItems.map((file) => file.id), ...values },
      { onSuccess: () => accessibilityToggle.hide() },
    );
  };

  const teamsWithAccessChanged = async (data) => {
    const { value: newTeams } = data;

    if (!newTeams.some((team) => team.value === currentTeam.id)) {
      newTeams.push({ label: currentTeam.name, value: currentTeam.id });
    }

    setTeamsWithAccess(newTeams);
  };

  const handleDeleteFiles = async () => {
    const ids = selectedItems.map((item) => item.id);
    deleteFiles(ids, {
      onSuccess: () => {
        setSelectedItems([]);
        deleteToggle.hide();
      },
    });
  };

  const spreadsheetFileTypes = useMemo(
    () => getAllowedUploadTypes(imageFileTypes.concat(pdfFileTypes)),
    [],
  );

  return (
    <div className="mt-4">
      {canEdit && (
        <FileInput
          showIcon
          accept={isSpreadsheetFiles ? spreadsheetFileTypes : {}}
          clearFilesAfterUpload
          maxFileSize={parseInt(VITE_MAX_FILE_SIZE, 10)}
          maxFiles={VITE_MAX_FILES_PER_ENTITY - files.length}
          callback={async (newFiles) => {
            const response = await createFiles(newFiles, {
              teamsWithAccess: teamsWithAccess.map((team) => team.value),
              type: documentType,
            });
            onCreateFilesCallback(response);
          }}
        />
      )}

      {(showIfNoFiles || files?.length > 0) && (
        <DataTable
          disableActions={!canEdit}
          subtitleContent={
            documentRelation === DocumentRelation.Case && (
              <div className="flex space-x-2">
                <Autocomplete
                  limitTags={2}
                  label={t('accessibility')}
                  info={t('_case.forms.document.accessibilityInfo')}
                  multiple
                  options={caseTeamsOptions}
                  onChange={teamsWithAccessChanged}
                  value={teamsWithAccess}
                />
                <Autocomplete
                  label={t('type')}
                  info={t('_case.forms.document.typeInfo')}
                  freeSolo
                  options={documentTypeSuggestions}
                  value={documentType}
                  onChange={({ value }) => setDocumentType(value)}
                />
              </div>
            )
          }
          height={calculateHeight(files.length)}
          rowId="id"
          data={files}
          config={getDefaultTableOptions(t, canEdit, documentRelation)}
          toggles={{
            accessibility: accessibilityToggle,
            create: createToggle,
            delete: deleteToggle,
            edit: editTableRowToggle,
            filter: filterToggle,
          }}
          className="p-[unset]"
          customActions={
            <div className="flex items-center space-x-2 border-x px-2">
              {documentRelation === DocumentRelation.Case && (
                <Tooltip title={t('accessibility')}>
                  <IconButton
                    onClick={accessibilityToggle.show}
                    disabled={!selectedItems.length}
                    variant="soft"
                  >
                    <AccessibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t('sendEmail')}>
                <IconButton
                  onClick={() => {
                    updateState({
                      isForward: false,
                      isReplyAll: false,
                      isRetry: false,
                      replyEmail: null,
                    });
                    sendEmailToggle.show();
                  }}
                  disabled={!selectedItems.length}
                  variant="soft"
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('downloadSelectedFiles')}>
                <IconButton onClick={downloadFiles} disabled={!selectedItems.length} variant="soft">
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('changeDocumentType')}>
                <IconButton
                  onClick={changeTypeToggle.show}
                  disabled={!selectedItems.length}
                  variant="soft"
                >
                  <FoldersIcon iconStyle="fas" />
                </IconButton>
              </Tooltip>
            </div>
          }
          selectedItems={selectedItems}
          setSelectedId={setSelectedId}
          setSelectedItems={setSelectedItems}
          defaultActions={{
            columnsSelection: false,
            create: false,
            delete: isBulkDeleteAllowed,
          }}
          openDocumentPreview={openDocumentPreview}
          canHandleAccess={canHandleAccess}
          canEdit={canEdit}
          isSpreadsheetFiles={isSpreadsheetFiles}
          onDeleteFileCallback={onDeleteFileCallback}
        />
      )}

      {fileToPreview && (
        <DocumentPreviewModal toggle={documentPreviewToggle} file={fileToPreview} />
      )}

      {createToggle.value && (
        <FormSidebarModal
          icon={<FileIcon />}
          title={t('_case.forms.document.title')}
          toggle={createToggle}
          handleSubmit={handleUpdateFile}
          isMutating={isMutating}
          initialValues={initialDocumentData}
          onClose={() => setSelectedId(null)}
          validationSchema={documentUpdateValidationSchema}
        >
          <DocumentUpdateForm />
        </FormSidebarModal>
      )}

      {changeTypeToggle.value && areSelectedEditable && (
        <FormSidebarModal
          icon={<FoldersIcon iconStyle="fas" />}
          title={t('changeDocumentType')}
          toggle={changeTypeToggle}
          handleSubmit={handleUpdateFilesType}
          isMutating={isMutating}
          initialValues={{ type: '' }}
          validationSchema={yup.object({
            type: yup.string().nullable(),
          })}
        >
          <DocumentsTypeForm />
        </FormSidebarModal>
      )}

      {deleteToggle.value && areSelectedEditable && (
        <FormSidebarModal
          description={t('modals.delete.description')}
          handleSubmit={handleDeleteFiles}
          icon={<DeleteIcon />}
          isDelete
          isMutating={isMutating}
          title={t('modals.delete.title')}
          toggle={deleteToggle}
        />
      )}

      {sendEmailToggle.value && (
        <SendCaseEmail
          caseId={caseId}
          toggle={sendEmailToggle}
          initialSubject={currentCase.currentTeamReference}
          initialFiles={selectedItems}
        />
      )}

      {accessibilityToggle.value && areSelectedEditable && (
        <FormSidebarModal
          enableReinitialize
          title={t('_case.forms.documentAccessibility.title')}
          icon={<AccessibilityIcon />}
          toggle={accessibilityToggle}
          initialValues={{
            isPublic: selectedItems.every((file) => file.isPublic),
            teamsWithAccess:
              selectedItems.length === 1
                ? selectedItems[0].teamsWithAccess.map((team) => team.id)
                : [currentTeam.id],
          }}
          isMutating={isBulkUpdatingFileAccessibility}
          handleSubmit={handleAccessibilityBulkUpdate}
        >
          <DocumentsAccessibilityForm
            files={selectedItems}
            accessOptions={caseTeamsOptions}
            currentTeamId={currentTeam.id}
          />
        </FormSidebarModal>
      )}
    </div>
  );
};

DocumentTab.propTypes = propTypes;

export default DocumentTab;
