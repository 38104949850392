import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/joy';
import { trpc } from '@/config/trpc';

import { CaseContext } from '../../CaseContext';
import useToggle from '@/shared/hooks/UseToggle';
import { WarningIcon } from '@/shared/icons/Icons';

const ServiceNotSyncedAlert = () => {
  const { t } = useTranslation();
  const { currentCase } = useContext(CaseContext);
  const displayWarningToggle = useToggle();
  const [company, setCompany] = useState('');
  const { data: contract } = trpc.contract.byId.useQuery(currentCase.contractId as string, {
    enabled: currentCase.contractId !== null,
  });
  useEffect(() => {
    displayWarningToggle.hide();
    if (contract?.ethiasSyncServiceRequestId && !currentCase.serviceRequestIdSyncedOn) {
      setCompany('Ethias');
      displayWarningToggle.show();
      return;
    }
    if (currentCase.contractInvoicingFlow !== 'AxaCsv' && !!currentCase.axaServiceReference) {
      setCompany('AXA');
      displayWarningToggle.show();
    }
  }, [contract, currentCase, displayWarningToggle]);

  return (
    <div>
      {displayWarningToggle.value && (
        <Alert
          startDecorator={<WarningIcon />}
          className="mb-4 items-center justify-center self-center rounded-xl bg-[#f8e3d2] text-[#fd811d]"
        >
          <div className="flex items-center">
            <span className="inline-block max-w-5xl truncate">
              {t('serviceNotSynced', { company })}
            </span>
          </div>
        </Alert>
      )}
    </div>
  );
};
export default ServiceNotSyncedAlert;
