import { cloneElement, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import API from '../../../../../shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import CaseEntityPropTypes from '../../../../../shared/prop-types/CaseEntityPropTypes';
import CaseRelationPropTypes from '../../../../../shared/prop-types/CaseRelationPropTypes';
import { useGetCaseEntityAccess } from '../../../../../queries';
import useNotification from '../../../../../shared/hooks/UseNotification';
import useToggle from '../../../../../shared/hooks/UseToggle';
import { useUpdateCaseEntityInfo } from '../../../../../mutations';

import { AccessibilityIcon } from '../../../../../shared/icons/Icons';
import IconButton from '../../../../../shared/components/buttons/IconButton';
import KpcModal from '../../../../../shared/components/2.0/layout/KpcModal';
import TextCheckbox from '../../../../../shared/components/checkboxes/TextCheckbox';

const propTypes = {
  customButton: PropTypes.node,
  entity: CaseEntityPropTypes.isRequired,
  followCursor: PropTypes.bool,
  relations: PropTypes.arrayOf(CaseRelationPropTypes).isRequired,
  shouldNotBeShownOnPublicPage: PropTypes.bool,
};

const CaseEntityAccessibility = ({
  entity,
  relations,
  shouldNotBeShownOnPublicPage,
  customButton = null,
  followCursor = false,
}) => {
  const { currentTeam } = useContext(AppContext);

  const { t } = useTranslation();

  const modalToggle = useToggle();

  const { sendDefaultError, sendNotification } = useNotification();

  const { teamsWithAccessToEntity, areTeamsWithAccessLoading, refetchTeamsWithAccess } =
    useGetCaseEntityAccess(entity.caseId, entity.id);
  const updateCaseEntityInfo = useUpdateCaseEntityInfo();

  const allTeamsWithAccessToCase = useMemo(
    () =>
      relations
        ?.filter((rel) => rel.hasAccess && rel.teamId != null)
        .map((rel) => {
          const { teamId, displayName } = rel;
          return {
            displayName,
            teamId,
          };
        }) ?? [],
    [relations],
  );

  const tooltipTitle = useMemo(() => {
    if (!teamsWithAccessToEntity) return null;

    const withAccess = (
      teamsWithAccessToEntity.length
        ? allTeamsWithAccessToCase.filter((twa) => teamsWithAccessToEntity.includes(twa.teamId))
        : allTeamsWithAccessToCase
    )
      .map((twa) => twa.displayName)
      .join(', ');

    return `${withAccess}\n${entity.showOnClientPage ? t('isBeingShownOnTheClientPage') : ''}`;
  }, [teamsWithAccessToEntity, t, entity.showOnClientPage, allTeamsWithAccessToCase]);

  const getCustomButton = () => {
    if (customButton == null) {
      return null;
    }
    return cloneElement(customButton, {
      onClick: modalToggle.show,
    });
  };

  const handleUpdateShowOnClientPage = (showOnClientPage) =>
    updateCaseEntityInfo(
      { caseId: entity.caseId, entityId: entity.id, showOnClientPage },
      {
        onSuccess: () => {
          sendNotification({
            header: t('success'),
            message: t('typeSuccessfullyUpdated', { type: t('accessibility') }),
            variant: 'success',
          });
        },
      },
    );

  const handleUpdateAccessibility = async (teamId, hasAccess) => {
    const data = {
      caseId: entity.caseId,
      entityId: entity.id,
      hasAccess,
      teamId,
    };

    const response = await API.putUpdateCaseEntityAccess(data);
    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    refetchTeamsWithAccess();
    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', { type: t('accessibility') }),
      variant: 'success',
    });
  };

  return (
    <>
      <Tooltip
        followCursor={followCursor}
        title={tooltipTitle && <span className="whitespace-pre-line">{tooltipTitle}</span>}
      >
        {customButton != null ? (
          getCustomButton()
        ) : (
          <IconButton iconComponent={<AccessibilityIcon />} onClick={modalToggle.show} />
        )}
      </Tooltip>
      <KpcModal title={t('accessibility')} toggle={modalToggle}>
        {areTeamsWithAccessLoading || currentTeam?.id == null ? (
          <Skeleton height={120} />
        ) : (
          <>
            {allTeamsWithAccessToCase.map((teamWithAccessToCase) => {
              const hasAccessToEntity =
                !teamsWithAccessToEntity?.length ||
                teamsWithAccessToEntity?.some((team) => team === teamWithAccessToCase.teamId);
              const isCurrentTeam = currentTeam.id === teamWithAccessToCase.teamId;
              return (
                <div key={teamWithAccessToCase.teamId}>
                  <TextCheckbox
                    isDefaultChecked={hasAccessToEntity}
                    yesText={t('teamXHasAccess', { team: teamWithAccessToCase.displayName })}
                    noText={t('teamXHasNoAccess', { team: teamWithAccessToCase.displayName })}
                    callback={(hasAccess) =>
                      handleUpdateAccessibility(teamWithAccessToCase.teamId, hasAccess)
                    }
                    disabled={isCurrentTeam}
                    disabledInfo={t('cannotChangeTheAccessibilityOfYourOwnTeam')}
                  />
                </div>
              );
            })}
            {!shouldNotBeShownOnPublicPage &&
              (!teamsWithAccessToEntity?.length ||
                allTeamsWithAccessToCase.length === teamsWithAccessToEntity?.length) && (
                <div className="mt-2 border-t">
                  <TextCheckbox
                    isDefaultChecked={entity.showOnClientPage}
                    yesText={t('showOnClientPage')}
                    noText={t('doNotShowOnClientPage')}
                    callback={(showOnClientPage) => handleUpdateShowOnClientPage(showOnClientPage)}
                  />
                </div>
              )}
          </>
        )}
      </KpcModal>
    </>
  );
};

CaseEntityAccessibility.propTypes = propTypes;

export default CaseEntityAccessibility;
