import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Avatar,
  Card,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { CaseRole, DamageType } from '~/common/enums';
import { AppContext } from '@/shared/context/context';
import { decapitalize } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import useDayjs from '@/shared/hooks/useDayjs';

import {
  CalendarIcon,
  ClockIcon,
  FileIcon,
  InfoIcon,
  InsurancePolicyIcon,
} from '@/shared/icons/Icons';
import DamageTypeIcon from '@/shared/icons/DamageTypeIcon';
import { FormikValues } from './AddRepairCaseStepper';

const Summary = () => {
  const { ui } = useContext(AppContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { values } = useFormikContext<FormikValues>();
  const { formatDateTime, formatDate } = useDayjs();

  const { data: building } = trpc.building.byId.useQuery(values.buildingId as string, {
    enabled: !!values.buildingId,
  });

  const insurer = values?.relations?.find((relation) => relation.role === CaseRole.Insurer);
  const requestor = values?.relations?.find((relation) => relation.isRequestor);
  const clients = values?.relations?.filter((relation) => relation.isClient);

  const currency = new Intl.NumberFormat(language, {
    currency: 'EUR',
    style: 'currency',
  });

  return (
    <AccordionGroup className="rounded-lg bg-gray-100">
      {ui.case.stepper.repairCase.step > 1 && (
        <Accordion>
          <AccordionSummary>
            <Typography
              endDecorator={
                <Tooltip title={t('repairCaseForm.summary.description')}>
                  <span>
                    <InfoIcon />
                  </span>
                </Tooltip>
              }
              level="title-sm"
            >
              {t('repairCaseForm.summary.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid grid-cols-3 gap-4">
              {ui.case.stepper.repairCase.step > 1 && !ui.case.toggles.showAddress && (
                <Card className="flex flex-row items-center">
                  <Avatar>1</Avatar>
                  <div className="flex flex-col">
                    <Typography level="title-lg">{t('address')}</Typography>
                    <Typography>{building?.name || ''} </Typography>
                    <Typography>{building?.address.addressLine || ''}</Typography>
                    <Typography startDecorator={building?.address.country || ''}>{`${
                      building?.address.postalCode || ''
                    } ${building?.address.city || ''}`}</Typography>
                  </div>
                </Card>
              )}

              {ui.case.stepper.repairCase.step > 1 && ui.case.toggles.showAddress && (
                <Card className="flex flex-row items-center">
                  <Avatar>1</Avatar>
                  <div className="flex flex-col">
                    <Typography level="title-lg">{t('address')}</Typography>
                    <Typography>{values.address.addressLine || ''} </Typography>
                    <Typography>
                      {values.address.postalCode || ''} {values.address.city || ''}
                    </Typography>
                  </div>
                </Card>
              )}

              {ui.case.stepper.repairCase.step > 2 && (
                <Card className="flex flex-row items-center">
                  <Avatar>2</Avatar>
                  <div className="flex flex-col space-y-2">
                    <div>
                      <Typography level="title-lg">{t('policies')}</Typography>
                      <div className="flex flex-wrap gap-2">
                        {values.policies.map((policy) => (
                          <Typography startDecorator={<InsurancePolicyIcon />}>
                            {policy.policyNumber}
                          </Typography>
                        ))}
                      </div>
                    </div>
                    <div>
                      <Typography level="title-lg">{t('excess')}</Typography>
                      <Typography>{currency.format(values.excessAmount)}</Typography>
                    </div>
                  </div>
                </Card>
              )}

              {ui.case.stepper.repairCase.step > 3 && (
                <Card className="flex flex-row items-center">
                  <Avatar>3</Avatar>
                  <div className="flex flex-col space-y-2">
                    <Typography level="title-lg">{t('damageDate')}</Typography>
                    <Typography startDecorator={<CalendarIcon />}>
                      {formatDate(values.incidentDate)}
                    </Typography>
                    {values.notificationDate && (
                      <>
                        <Typography level="title-lg">{t('dateAndTimeOfReceipt')}</Typography>
                        <Typography startDecorator={<ClockIcon />}>
                          {formatDateTime(values.notificationDate)}
                        </Typography>
                      </>
                    )}
                  </div>
                </Card>
              )}

              {ui.case.stepper.repairCase.step > 4 && (
                <Card className="flex flex-row items-center">
                  <Avatar>4</Avatar>
                  <div className="flex flex-col space-y-2">
                    <Typography level="title-lg">{t('damageType')}</Typography>
                    <Typography
                      startDecorator={
                        <DamageTypeIcon damageType={values.damageType as DamageType} />
                      }
                    >
                      {t(`damage.${decapitalize(values.damageType)}`)}
                    </Typography>
                    <Typography level="title-lg">{t('description')}</Typography>
                    <Typography>{values.description}</Typography>
                  </div>
                </Card>
              )}

              {ui.case.stepper.repairCase.step > 5 && (
                <Card className="flex flex-row items-center">
                  <Avatar>5</Avatar>
                  <div className="flex flex-col space-y-2">
                    <Typography level="title-lg">{t('insurer')}</Typography>
                    <Typography>{insurer?.label}</Typography>
                    <Typography level="title-lg">{t('requestor')}</Typography>
                    <Typography>{requestor?.label}</Typography>
                    <Typography level="title-lg">{`${t('client')} / ${t('clients')}`}</Typography>
                    {clients?.map((client) => (
                      <div>
                        <Typography>{client.label}</Typography>
                      </div>
                    ))}
                  </div>
                </Card>
              )}
              {ui.case.stepper.repairCase.step > 6 && (
                <Card className="flex flex-row items-center">
                  <Avatar>6</Avatar>
                  <div className="flex flex-col space-y-2">
                    <Typography level="title-lg">{t('amountOfDocuments')}</Typography>
                    <Typography startDecorator={<FileIcon />}>
                      {ui.files.temporary.length || 0}
                    </Typography>
                  </div>
                </Card>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </AccordionGroup>
  );
};

export default Summary;
