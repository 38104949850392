import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import SelectDamageTypes from '../components/select/SelectDamageType';

const FormikDamageTypeSelector = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();

  const updateAndCallback = useCallback(
    (damageType) => {
      setFieldValue(name, damageType, true);
    },
    [name, setFieldValue],
  );

  return <SelectDamageTypes callback={updateAndCallback} initialDamageType={values.damageType} />;
};

FormikDamageTypeSelector.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikDamageTypeSelector;
