import PropTypes from 'prop-types';
import Gender from '../enums/Gender';
import Language from '../enums/Language';
import AddressPropTypes from './AddressPropTypes';
import PaymentPropTypes from './PaymentPropTypes';

const PaymentRequestPropTypes = PropTypes.shape({
  id: PropTypes.string,
  amount: PropTypes.number,
  payments: PropTypes.arrayOf(PaymentPropTypes),
  contact: PropTypes.shape({
    gender: PropTypes.oneOf([Gender.Male, Gender.Female, Gender.Unknown]),
    displayName: PropTypes.string,
    caseRelationId: PropTypes.string,
    language: PropTypes.oneOf([Language.Dutch, Language.English, Language.French]),
    address: AddressPropTypes,
    emailAddresses: PropTypes.arrayOf(PropTypes.string),
  }),
});

export default PaymentRequestPropTypes;
