import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { decapitalize } from '../../../utils/helpers';

const InsurancePolicyTpeFilter = ({ callback, name, defaultValue, valuesToSelect }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Autocomplete
        noOptionsText={t('common:noOptions')}
        multiple
        options={valuesToSelect}
        fullWidth
        value={defaultValue}
        onChange={(_, values) => callback(name, values)}
        getOptionLabel={(option) => t(`insurancePolicy:${decapitalize(option)}`)}
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t('common:type')} />
        )}
      />
    </div>
  );
};

InsurancePolicyTpeFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  valuesToSelect: PropTypes.arrayOf(PropTypes.string),
};

InsurancePolicyTpeFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
  valuesToSelect: [],
};

export default InsurancePolicyTpeFilter;
