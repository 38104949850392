import { useContext } from 'react';
import { useQueries } from '@tanstack/react-query';

import { AppContext } from '@/shared/context/context';
import enumApi from '../../shared/api/enums/enum';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetEnums = () => {
  const {
    ui: { currentTeamId },
  } = useContext(AppContext);

  const { sendDefaultError } = useNotification();

  const enums = useQueries({
    onError: (error) => {
      sendDefaultError(error);
    },
    queries: [
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getAddressFunctions,
        queryKey: [queryIds.enums.GET_ADDRESS_FUNCTIONS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getBuildingRelationRoles,
        queryKey: [queryIds.enums.GET_BUILDING_CONTACT_ROLES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCaseChannels,
        queryKey: [queryIds.enums.GET_CASE_CHANNELS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCaseInvolvementTypes,
        queryKey: [queryIds.enums.GET_CASE_INVOLVEMENT_TYPES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCaseRoles,
        queryKey: [queryIds.enums.GET_CASE_ROLES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getContractorAbsences,
        queryKey: [queryIds.enums.GET_CONTRACTOR_ABSENCES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getContractorFeeTypes,
        queryKey: [queryIds.enums.GET_CONTRACTOR_FEE_TYPES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCorporateForms,
        queryKey: [queryIds.enums.GET_CORPORATE_FORMS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCountries,
        queryKey: [queryIds.enums.GET_COUNTRIES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getCurrencies,
        queryKey: [queryIds.enums.GET_CURRENCIES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getDischargeCollectionModes,
        queryKey: [queryIds.enums.GET_DISCHARGE_COLLECTION_MODES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getExcessTypes,
        queryKey: [queryIds.enums.GET_EXCESS_TYPES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getGenders,
        queryKey: [queryIds.enums.GET_GENDERS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getLanguages,
        queryKey: [queryIds.enums.GET_LANGUAGES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getMessageTemplateContexts,
        queryKey: [queryIds.enums.GET_MESSAGE_TEMPLATE_CONTEXTS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getPaymentFrequencies,
        queryKey: [queryIds.enums.GET_PAYMENT_FREQUENCIES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getPhoneCallOutcomes,
        queryKey: [queryIds.enums.GET_PHONE_CALL_OUTCOMES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getPolicyStatus,
        queryKey: [queryIds.enums.GET_POLICY_STATUS, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getPriorities,
        queryKey: [queryIds.enums.GET_PRIORITIES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getProvinces,
        queryKey: [queryIds.enums.GET_PROVINCES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getRecurrencePatternFrequencyChoices,
        queryKey: [queryIds.enums.GET_RECURRENCE_PATTERN_FREQUENCY_CHOICES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getRecurrencePatternMonthlyChoices,
        queryKey: [queryIds.enums.GET_RECURRENCE_PATTERN_MONTHLY_CHOICES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getRecurrencePatternYearlyChoices,
        queryKey: [queryIds.enums.GET_RECURRENCE_PATTERN_YEARLY_CHOICES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getRecurrenceRangeEndChoices,
        queryKey: [queryIds.enums.GET_RECURRENCE_RANGE_END_CHOICES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getRenewalCollectionModes,
        queryKey: [queryIds.enums.GET_RENEWAL_COLLECTION_MODES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getUnities,
        queryKey: [queryIds.enums.GET_UNITIES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getVATCodes,
        queryKey: [queryIds.enums.GET_VAT_CODES, currentTeamId],
        staleTime: Infinity,
      },
      {
        enabled: !!currentTeamId,
        queryFn: enumApi.getWorkdays,
        queryKey: [queryIds.enums.GET_WORKDAYS, currentTeamId],
        staleTime: Infinity,
      },
    ],
  });

  const [
    addressFunctions,
    buildingRelationRoles,
    caseChannels,
    caseInvolvementTypes,
    caseRoles,
    contractorAbsences,
    contractorFeeTypes,
    corporateForms,
    countries,
    currencies,
    dischargeCollectionModes,
    excessTypes,
    genders,
    languages,
    messageTemplateContexts,
    paymentFrequencies,
    phoneCallOutcomes,
    policyStatus,
    priorities,
    provinces,
    recurrencePatternFrequencyChoices,
    recurrencePatternMonthlyChoices,
    recurrencePatternYearlyChoices,
    recurrenceRangeEndChoices,
    renewalCollectionModes,
    unities,
    vatCodes,
    workdays,
  ] = enums;

  const mappedEnums = {
    addressFunctions: addressFunctions.data || [],
    buildingRelationRoles: buildingRelationRoles.data || [],
    caseChannels: caseChannels.data || [],
    caseInvolvementTypes: caseInvolvementTypes.data || [],
    caseRoles: caseRoles.data || [],
    contractorAbsences: contractorAbsences.data || [],
    contractorFeeTypes: contractorFeeTypes.data || [],
    corporateForms: corporateForms.data || [],
    countries: countries.data || [],
    currencies: currencies.data || [],
    dischargeCollectionModes: dischargeCollectionModes.data || [],
    excessTypes: excessTypes.data || [],
    genders: genders.data || [],
    languages: languages.data || [],
    messageTemplateContexts: messageTemplateContexts.data || [],
    paymentFrequencies: paymentFrequencies.data || [],
    phoneCallOutcomes: phoneCallOutcomes.data || [],
    policyStatus: policyStatus.data || [],
    priorities: priorities.data || [],
    provinces: provinces.data || [],
    recurrencePatternFrequencyChoices: recurrencePatternFrequencyChoices.data || [],
    recurrencePatternMonthlyChoices: recurrencePatternMonthlyChoices.data || [],
    recurrencePatternYearlyChoices: recurrencePatternYearlyChoices.data || [],
    recurrenceRangeEndChoices: recurrenceRangeEndChoices.data || [],
    renewalCollectionModes: renewalCollectionModes.data || [],
    unities: unities.data || [],
    vatCodes: vatCodes.data || [],
    workdays: workdays.data || [],
  };

  const success = enums.every((query) => query.isSuccess);
  const error = enums.find((query) => query.isError);

  return {
    enums: mappedEnums,
    error,
    success,
  };
};

export default useGetEnums;
