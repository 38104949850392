import { useMutation, useQueryClient } from '@tanstack/react-query';

import { caseApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

type Options = {
  callback?: () => void;
};

const useUpdateEntityTeamInfo = (options?: Options) => {
  const { sendDefaultError } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useMutation({
    mutationFn: caseApi.updateEntityTeamInfo,
    onError: sendDefaultError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryIds.cases.GET_CASE_ENTITIES] });

      if (options?.callback) {
        options.callback();
      }
    },
  });

  return {
    isUpdatingEntityTeamInfo: isLoading,
    updateEntityTeamInfo: mutate,
    updateEntityTeamInfoAsync: mutateAsync,
  };
};

export default useUpdateEntityTeamInfo;
