import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CaseRole } from '~/common/enums';
import { decapitalize } from '@/shared/utils/helpers';
import { Nullable } from '~/common/types';

type Props = {
  rowData: {
    parentRelationId: Nullable<string>;
    role: Nullable<CaseRole>;
    subordinate: Nullable<string>;
  };
};

const RelationRoleCustomCell: React.FC<Props> = ({ rowData }) => {
  const { t } = useTranslation();
  const { parentRelationId, role, subordinate } = rowData;

  if (!role && !parentRelationId && !subordinate) {
    return null;
  }

  return (
    <div>
      {(parentRelationId != null || subordinate != null) && (
        <FontAwesomeIcon icon={['far', 'arrow-turn-down-right']} />
      )}
      {role && <span className="break-all">{t(decapitalize(role))}</span>}
    </div>
  );
};

export default memo(RelationRoleCustomCell);
