import { createElement, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Autocomplete as MuiAutocomplete,
  Tooltip,
} from '@mui/joy';

import { AutocompleteChangedType, Nullable, TAutocompleteOption } from '~/common/types';
import { InfoIcon } from '@/shared/icons/Icons';

type AutocompleteProps<Multiple extends boolean = false> = {
  disabled?: boolean;
  error?: boolean;
  freeSolo?: boolean;
  helperText?: string;
  icon?: () => JSX.Element;
  info?: string;
  isRequired?: boolean;
  label?: string;
  limitTags?: number;
  loading?: boolean;
  multiple?: Multiple;
  name: string;
  onBlur?: () => void;
  onChange: (option: AutocompleteChangedType<Multiple>) => void;
  options: TAutocompleteOption<unknown>[];
  placeholder?: string;
  value?: Multiple extends true
    ? TAutocompleteOption<unknown>[]
    : Nullable<TAutocompleteOption<unknown>>;
  disableClearable?: boolean;
};

const Autocomplete = <Multiple extends boolean = false>({
  disabled = false,
  error = false,
  freeSolo = false,
  helperText,
  icon,
  info,
  isRequired = false,
  label,
  loading = false,
  multiple,
  name,
  onBlur,
  onChange,
  options,
  disableClearable = false,
  placeholder = undefined,
  value = undefined,
  ...rest
}: AutocompleteProps<Multiple>) => {
  const [uncontrolledValue, setUncontrolledValue] = useState<
    Multiple extends true ? TAutocompleteOption<unknown>[] : Nullable<TAutocompleteOption<unknown>>
  >(
    (multiple ? [] : null) as Multiple extends true
      ? TAutocompleteOption<unknown>[]
      : Nullable<TAutocompleteOption<unknown>>,
  );

  const handleValueChange = (
    changedValue: Nullable<TAutocompleteOption<unknown>> | TAutocompleteOption<unknown>[],
  ) => {
    if (multiple) {
      (onChange as (options: AutocompleteChangedType<true>) => void)({
        name,
        type: 'autocomplete',
        value: changedValue as TAutocompleteOption<unknown>[],
      });
    } else {
      (onChange as (options: AutocompleteChangedType<false>) => void)({
        name,
        type: 'autocomplete',
        value: changedValue as Nullable<TAutocompleteOption<unknown>>,
      });
    }
    if (value === undefined) {
      setUncontrolledValue(
        changedValue as Multiple extends true
          ? TAutocompleteOption<unknown>[]
          : Nullable<TAutocompleteOption<unknown>>,
      );
    }
  };

  return (
    <FormControl className="w-full flex-1" size="sm" error={error}>
      {label && (
        <FormLabel required={isRequired} className={error ? 'invalid' : ''}>
          {label}
          {info && (
            <Tooltip title={info}>
              <span className="ml-1">
                {/* @ts-ignore */}
                <InfoIcon color="keypoint" />
              </span>
            </Tooltip>
          )}
        </FormLabel>
      )}
      <MuiAutocomplete
        {...rest}
        disableClearable={disableClearable}
        freeSolo={freeSolo}
        clearOnBlur={!freeSolo}
        disabled={disabled || loading}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        onBlur={onBlur}
        onChange={(_, val) => handleValueChange(val as Nullable<TAutocompleteOption<unknown>[]>)}
        onInputChange={(_, val) => (freeSolo ? handleValueChange({ label: val, value: val }) : {})}
        options={options}
        placeholder={placeholder}
        startDecorator={icon ? createElement(icon) : undefined}
        isOptionEqualToValue={(
          option: TAutocompleteOption<unknown>,
          val: TAutocompleteOption<unknown>,
        ) => option.value === val.value}
        value={value || uncontrolledValue}
      />
      {helperText && <FormHelperText className="px-1 text-xs">{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Autocomplete;
