import { Box, CardContent, Collapse, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { Input } from '@mui/joy';
import PropTypes from 'prop-types';
import { search } from 'ka-table/actionCreators';
import { useTranslation } from 'react-i18next';

import { AddIcon, SearchIcon } from '../../icons/Icons';
import ColumnSettings from './ColumnSettings';
import FilterType from './filters/FilterType';
import FilterWrapper from './filters/FilterWrapper';
import IconButton from '../buttons/IconButton';
import useDebouncedSearch from '../../hooks/UseDebouncedSearch';

const TableActionBar = ({
  columns,
  dispatch,
  showFilter,
  clearFilterCallback,
  isFilterActive,
  filters,
  callback,
  onAdd,
  hideDisplayMenu,
  buttons,
  predicates,
  filterLocalStorageKey,
  cachedFiltersInitializedCallback,
  hideSearchField,
  delay,
  onAddCustomRender,
}) => {
  const [isDisplayMenuOpen, setIsDisplayMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const toggleDisplayMenu = () => {
    setIsDisplayMenuOpen(!isDisplayMenuOpen);
  };
  const useSearchInTable = () => useDebouncedSearch((text) => dispatch(search(text)), delay);
  const { setInputText } = useSearchInTable();
  return (
    <div className="overflow-visible rounded-xl bg-white">
      <Toolbar>
        {!hideSearchField && (
          <Box
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
            }}
          >
            <Input
              startDecorator={<SearchIcon />}
              placeholder={t('search.label')}
              onChange={(event) => {
                setInputText(event.currentTarget.value.toLowerCase());
              }}
            />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <div style={{ position: 'relative' }}>
              {showFilter && (
                <IconButton
                  icon="filter-list"
                  tooltip={t('filter')}
                  onClick={toggleFilter}
                  color={isFilterActive ? 'accent' : 'keypoint'}
                  size="2x"
                />
              )}
              {isFilterActive && (
                <IconButton
                  iconStyle="fad"
                  icon="circle-xmark"
                  color="keypoint"
                  tooltip={t('clearFilter')}
                  onClick={clearFilterCallback}
                  style={{
                    bottom: '20px',
                    left: '20px',
                    position: 'absolute',
                  }}
                />
              )}
            </div>
            {!hideDisplayMenu && (
              <IconButton icon="eye" tooltip={t('display')} onClick={toggleDisplayMenu} size="2x" />
            )}
            {onAdd != null && (
              <IconButton
                iconComponent={<AddIcon />}
                tooltip={t('add')}
                onClick={onAdd}
                color="keypoint"
                className="ms-3"
                size="2x"
              />
            )}
            {onAddCustomRender != null && onAddCustomRender()}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '6px',
              paddingTop: '12px',
            }}
          >
            {buttons.length > 0 && buttons.map((b) => b)}
          </Box>
        </Box>
      </Toolbar>
      {isDisplayMenuOpen && (
        <button
          className="case__back"
          aria-label="case__back"
          type="button"
          onClick={toggleDisplayMenu}
        />
      )}
      <Collapse in={isDisplayMenuOpen} className="case__menu-wrap">
        <div className="case__menu">
          <Box
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <ColumnSettings columns={columns} dispatch={dispatch} />
          </Box>
        </div>
      </Collapse>
      {filters != null && (
        <Collapse in={isFilterOpen}>
          <CardContent>
            <FilterWrapper
              filterLocalStorageKey={filterLocalStorageKey}
              callback={callback}
              cachedFiltersInitializedCallback={cachedFiltersInitializedCallback}
              filters={filters}
              predicates={predicates}
              hideFilter={() => setIsFilterOpen(false)}
            />
          </CardContent>
        </Collapse>
      )}
    </div>
  );
};

TableActionBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  showFilter: PropTypes.bool,
  clearFilterCallback: PropTypes.func,
  isFilterActive: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([
        FilterType.String,
        FilterType.Bool,
        FilterType.Case,
        FilterType.CaseStatus,
        FilterType.CaseType,
        FilterType.Tag,
        FilterType.TeamUser,
        FilterType.CustomEnumSelect,
        FilterType.InsurancePolicyType,
        FilterType.Building,
        FilterType.Contact,
        FilterType.Company,
        FilterType.Team,
        FilterType.StringOptions,
      ]),
      predicate: PropTypes.func,
      yesText: PropTypes.string,
      noText: PropTypes.string,
      defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  callback: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  hideDisplayMenu: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.node),
  predicates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      predicate: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
    }),
  ).isRequired,
  filterLocalStorageKey: PropTypes.string.isRequired,
  cachedFiltersInitializedCallback: PropTypes.func.isRequired,
  hideSearchField: PropTypes.bool,
  delay: PropTypes.number.isRequired,
  onAddCustomRender: PropTypes.func,
};

TableActionBar.defaultProps = {
  clearFilterCallback: () => {},
  showFilter: false,
  isFilterActive: false,
  onAdd: null,
  filters: null,
  hideDisplayMenu: false,
  buttons: [],
  hideSearchField: false,
  onAddCustomRender: null,
};

export default React.memo(TableActionBar);
