import { FormControl, FormLabel } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentencize } from '../../utils/helpers';

import CaseEntityOption from './CaseEntityOption';
import FormikTextField from './formik/FormikTextField';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const RemarkCaseEntityOption = ({ name }) => {
  const { t } = useTranslation();

  return (
    <CaseEntityOption title={sentencize(t('addType', { type: t('remark') }))}>
      <FormControl className="mb-4">
        <FormLabel>{t('remark')}</FormLabel>
        <FormikTextField name={name} />
      </FormControl>
    </CaseEntityOption>
  );
};

RemarkCaseEntityOption.propTypes = propTypes;

export default RemarkCaseEntityOption;
