import { useQuery } from '@tanstack/react-query';

import fsmaApi from '@/shared/api/fsma/fsma';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetFsmaCompaniesAutocomplete = () => {
  const { sendDefaultError } = useNotification();
  const { data, isLoading } = useQuery({
    onError: sendDefaultError,
    queryFn: async () => {
      const data = await fsmaApi.getFsmaCompanies();
      return data.map((fsmaCompany) => ({
        label: fsmaCompany.centralCompanyName,
        value: fsmaCompany.centralCompanyId,
      }));
    },
    queryKey: [queryIds.fsma.GET_FSMA_COMPANIES_AUTOCOMPLETE],
  });

  return {
    fsmaCompaniesAutocomplete: data || [],
    fsmaCompaniesAutocompleteIsLoading: isLoading,
  };
};

export default useGetFsmaCompaniesAutocomplete;
