import config from '@/config/config';
import createRestClient from '../restClient';

import {
  CreateMeetingSettingsForTypeForHomeownerAssociationRequest,
  UpdateMeetingSettingsForTypeForHomeownerAssociationRequest,
} from '~/common/types/meeting/homeownerAssociationMeetingSettingsRequests';
import { HomeownerAssociationMeetingTypeSettings } from '~/common/types/meeting/homeownerAssociationMeetingSettings';

const client = createRestClient(config.baseURL);

const getMeetingTypeSettingsForHomeownerAssociation = (
  homeownerAssociationId: string,
): Promise<HomeownerAssociationMeetingTypeSettings[]> =>
  client.get(`/homeownerAssociationMeetingSettings/${homeownerAssociationId}/meeting-type`);

const createSettingsForMeetingTypeForHomeownerAssociation = (
  data: CreateMeetingSettingsForTypeForHomeownerAssociationRequest,
) => client.post(`/homeownerAssociationMeetingSettings/meeting-type`, data);

const updateSettingsForMeetingTypeForHomeownerAssociation = (
  data: UpdateMeetingSettingsForTypeForHomeownerAssociationRequest,
) => client.put(`/homeownerAssociationMeetingSettings/meeting-type`, data);

const deleteSettingsForMeetingTypeForHomeownerAssociation = (id: string) =>
  client.delete(`/homeownerAssociationMeetingSettings/meeting-type/${id}`);

export default {
  createSettingsForMeetingTypeForHomeownerAssociation,
  deleteSettingsForMeetingTypeForHomeownerAssociation,
  getMeetingTypeSettingsForHomeownerAssociation,
  updateSettingsForMeetingTypeForHomeownerAssociation,
};
