import { useQuery } from '@tanstack/react-query';

import { policyApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetPolicy = (policyId) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!policyId,
    onError: (error) => sendDefaultError(error),
    queryFn: () => policyApi.getPolicy(policyId),
    queryKey: [queryIds.policies.GET_POLICY, policyId],
  });

  return {
    policy: data,
    policyIsLoading: isLoading,
  };
};

export default useGetPolicy;
