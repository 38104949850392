import config from '@/config/config';
import createRestClient from '../restClient';

const client = createRestClient(config.baseURL);

const getCentralCompaniesAutocomplete = (params) =>
  client.get('centralCompany/list/autocomplete', { params });

const getCentralCompaniesVatAutocomplete = (params) =>
  client.get('centralCompany/vat/autocomplete', { params });

const restClient = createRestClient();

const getCentralCompanies = () => restClient.get('centralCompany/list');

const createCentralCompany = (data) => restClient.post('centralCompany', data);

const getCentralCompany = (centralCompanyId) =>
  restClient.get(`centralCompany/${centralCompanyId}`);

export default {
  createCentralCompany,
  getCentralCompanies,
  getCentralCompaniesAutocomplete,
  getCentralCompaniesVatAutocomplete,
  getCentralCompany,
};
