import { AutocompleteOption } from '~/common/types';
import config from '@/config/config';
import createRestClient from '../restClient';

const client = createRestClient(config.baseURL);

const getJournalsAutocomplete = (
  accountingSystemDossierId: number,
  accountingSystemBookYearId: number,
): Promise<AutocompleteOption[]> =>
  client.get(
    `/accountingSystem/${accountingSystemDossierId}/${accountingSystemBookYearId}/journals/autocomplete`,
  );

export default {
  getJournalsAutocomplete,
};
