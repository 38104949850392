import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChatBubble from './ChatBubble';
import ChatNewMessageForm from './ChatNewMessageForm';
import ChatSearch from './ChatSearch';
import { MessagesProps } from '../../prop-types/ChatProps';
import { useSearchFilter } from '@/shared/hooks/UseFilters';

const propTypes = {
  canSendMessage: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  messages: MessagesProps.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const Chat = ({ messages, onUpdate, onDelete, canSendMessage = false, isLoading }) => {
  const [chatBubbleCount, setChatBubbleCount] = useState();
  const chatRef = useRef();
  const chatBubbleRef = useRef();
  const { t } = useTranslation();

  const { debounceSearch, search } = useSearchFilter();
  const isSearching = !!search.length;

  const filteredMessages = useMemo(() => {
    if (!isSearching) {
      return messages;
    }

    const loweredQuery = search.toLowerCase();
    const filtered = messages.filter(
      (msg) =>
        msg.message.toLowerCase().includes(loweredQuery) ||
        msg.displayName.toLowerCase().includes(loweredQuery),
    );
    return filtered;
  }, [search, messages, isSearching]);

  useLayoutEffect(() => {
    if (chatBubbleRef.current) {
      setChatBubbleCount(chatBubbleRef.current.children.length);
    }
  }, [filteredMessages]);

  useLayoutEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        behavior: 'instant',
        top: chatRef.current.scrollHeight,
      });
    }
  }, [chatBubbleCount, chatRef]);

  return (
    <div
      ref={chatRef}
      className="flex h-[calc(100vh-326px)] flex-col justify-between overflow-y-auto bg-white"
    >
      <ChatSearch onSearch={debounceSearch} />
      <div
        ref={chatBubbleRef}
        className={classNames('flex grow flex-col justify-start', {
          'justify-center': !filteredMessages.length,
        })}
      >
        {!filteredMessages.length && <p className="my-4 text-center">{t('noDataToDisplay')}</p>}
        {!!filteredMessages.length &&
          filteredMessages
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((item) => (
              <ChatBubble key={item.id} message={item} onUpdate={onUpdate} onDelete={onDelete} />
            ))}
      </div>
      {canSendMessage && <ChatNewMessageForm isLoading={isLoading} />}
    </div>
  );
};

Chat.propTypes = propTypes;

export default Chat;
