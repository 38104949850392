import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact } from '~/common/types/contact/contact';
import ContactFormikValues from '~/common/types/contact/ContactFormikValues';
import { Nullable } from '~/common/types';
import { sentencize } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import { useCreateContact } from '@/mutations';
import { useGetContact } from '@/queries';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import AddEditContactForm from './AddEditContactForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props = {
  contactId?: string;
  toggle: ReturnType<typeof useToggle>;
};

const AddEditContactModal: React.FC<Props> = ({ toggle, contactId }) => {
  const [contact, setContact] = useState<Nullable<Contact>>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation('common');
  const { sendNotification, sendDefaultError } = useNotification();
  const { contact: currentContact, contactIsLoading: currentContactIsLoading } =
    useGetContact(contactId);

  const handleClose = () => {
    setContact(null);
    toggle.hide();
  };

  const handleSuccess = (
    id: string,
    message: 'typeSuccessfullyUpdated' | 'typeSuccessfullyAdded',
  ) => {
    sendNotification({
      header: t('success'),
      message: t(`${message}`, { type: t('contact') }),
      onSuccessRoute: `/contact/${id}`,
      variant: 'success',
    });
    handleClose();
  };

  const { mutate: createContact, isLoading: createContactIsLoading } = useCreateContact();
  const { mutate: updateContact, isLoading: updateContactIsLoading } =
    trpc.contact.update.useMutation({
      onError: (error: unknown) => sendDefaultError(error),
      onSuccess: (data: Contact) => handleSuccess(data.id, 'typeSuccessfullyUpdated'),
    });

  useEffect(() => {
    if (!contactId || currentContactIsLoading) {
      return;
    }
    setIsEditing(true);
    setContact(currentContact);
  }, [contactId, currentContact, currentContactIsLoading]);

  const submit = (formData: ContactFormikValues) => {
    const { addressLine, postalCode, city, country, ...formBody } = formData;

    if (isEditing && contactId) {
      updateContact(
        {
          contactId,
          ...formBody,
          address: {
            addressLine,
            city,
            country,
            postalCode,
          },
        },
        { onSuccess: ({ id }) => handleSuccess(id, 'typeSuccessfullyUpdated') },
      );
      return;
    }

    createContact(
      {
        ...formBody,
        address: {
          addressLine,
          city,
          country,
          postalCode,
        },
      },
      { onSuccess: ({ id }) => handleSuccess(id, 'typeSuccessfullyAdded') },
    );
  };

  return (
    <KpcModal toggle={toggle} title={sentencize(t('addType', { type: t('contact') }))}>
      <AddEditContactForm
        onSubmit={submit}
        onCancel={() => handleClose()}
        contact={contact || undefined}
        isSubmitting={createContactIsLoading || updateContactIsLoading}
      />
    </KpcModal>
  );
};

export default AddEditContactModal;
