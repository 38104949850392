import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormGroup, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  formatAsCurrency,
  getEnumValues,
  getMaxDecimalPrecisionValue,
} from '../../../../../../shared/utils/helpers';
import CasePaymentPropTypes from '../../../../../../shared/prop-types/CasePaymentPropTypes';
import CasePaymentType from '../../../../../../shared/enums/CasePaymentType';
import FormikAutocomplete from '../../../../../../shared/formik/FormikAutocomplete';
import FormikCaseRelationSelector from '../../../../../../shared/formik/FormikCaseRelationSelector';
import FormikCurrencyInput from '../../../../../../shared/formik/FormikCurrencyInput';
import FormikEnumSelector from '../../../../../../shared/formik/FormikEnumSelector';
import FormikTextField from '../../../../../../shared/formik/FormikTextField';
import FormikYesNo from '../../../../../../shared/formik/FormikYesNo';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';
import TaskCreator from '../../../../../../shared/components/tasks/TaskCreator';

const AddEditPaymentForm = ({
  onSubmit,
  onCancel,
  initialPayment,
  caseId,
  inbound,
  inboundPayments,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  const popperContainerRef = React.useRef();
  const formRef = React.useRef();

  const handleTaskChange = (task) => {
    if (!task.teamTaskTypeId && !task.systemTaskType && !task.description) {
      formRef.current.setFieldValue('tasks', []);
      return;
    }

    formRef.current.setFieldValue('tasks', [task]);
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={{
          amount: initialPayment?.amount,
          completed: initialPayment?.completed ?? true,
          description: initialPayment?.description,
          linkedPaymentId: initialPayment?.linkedPaymentId,
          relationId: initialPayment?.relationId,
          task: null,
          type: initialPayment?.type,
        }}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .nullable()
            .required(t('errors:fieldIsRequired'))
            .max(
              getMaxDecimalPrecisionValue(9, 2),
              t('errors:maxValueX', { val: formatAsCurrency(getMaxDecimalPrecisionValue(9, 2)) }),
            )
            .test(
              'amountShouldBePositive',
              t('errors:valueMustBePositive'),
              (value) => value == null || value >= 0,
            ),
          completed: yup.bool(),
          description: yup.string().nullable().required(t('errors:fieldIsRequired')),
          tasks: yup
            .array()
            .nullable()
            .test('taskDataShouldBeValid', (value) =>
              value && value[0] ? value[0].isValid : true,
            ),

          type: yup.string().nullable().required(t('errors:fieldIsRequired')),
        })}
      >
        <Form>
          <FormGroup className="mb-3">
            <Grid container columnSpacing={2}>
              <Grid item md={8}>
                <FormikEnumSelector
                  id="type"
                  name="type"
                  label={t('common:type')}
                  enumValues={getEnumValues(CasePaymentType)}
                  required
                />
              </Grid>
              <Grid item md={4}>
                <FormikYesNo
                  id="completed"
                  name="completed"
                  yesText={t('common:transactionCompleted')}
                  noText={t('common:transactionNotCompleted')}
                />
              </Grid>
            </Grid>
          </FormGroup>

          <FormGroup className="mb-3">
            <FormikCaseRelationSelector
              selectSingle
              id="relationId"
              name="relationId"
              label={t('common:relation')}
              caseId={caseId}
              popperContainer={popperContainerRef}
              disablePortal={false}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormikTextField
              id="description"
              name="description"
              label={t('common:description')}
              required
              multiline
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormikCurrencyInput id="amount" name="amount" label={t('common:amount')} required />
          </FormGroup>

          {!inbound && !initialPayment?.inbound && (
            <FormGroup className="mb-3">
              <FormikAutocomplete
                id="linkedPaymentId"
                name="linkedPaymentId"
                label={t('common:linkedTo')}
                isOptionEqualToValue={(opt, val) => opt.id === val || opt.id === val.id}
                options={inboundPayments}
                getOptionLabel={(opt) => {
                  if (opt === null) {
                    return '';
                  }

                  const value = inboundPayments.find((c) => c.id === opt.id || c.id === opt);
                  return value
                    ? `${value.description} ${formatAsCurrency(value.amount, true)}`
                    : '';
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.description} {formatAsCurrency(option.amount, true)}
                  </li>
                )}
                getValue={(val) => val?.id ?? val}
              />
            </FormGroup>
          )}

          {!initialPayment && (
            <FormGroup className="mb-2">
              <TaskCreator callback={handleTaskChange} linkBtn />
            </FormGroup>
          )}
          <ModalFooter onCancel={onCancel} />
        </Form>
      </Formik>
      <div ref={popperContainerRef} />
    </>
  );
};

AddEditPaymentForm.propTypes = {
  caseId: PropTypes.string.isRequired,
  inbound: PropTypes.bool,
  inboundPayments: PropTypes.arrayOf(CasePaymentPropTypes).isRequired,
  initialPayment: CasePaymentPropTypes,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddEditPaymentForm.defaultProps = {
  inbound: null,
  initialPayment: null,
};

export default AddEditPaymentForm;
