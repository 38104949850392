import { FormControl, FormLabel, Input, Radio, RadioGroup, Sheet, Textarea } from '@mui/joy';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { DamageType } from '~/common/enums';
import { FormikValues } from './AddRepairCaseStepper';

import DamageTypeIcon from '@/shared/icons/DamageTypeIcon';
import { decapitalize } from '@/shared/utils/helpers';
import HeaderStep from './HeaderStep';
import { ValidIcon } from '@/shared/icons/Icons';

const damageTypes = Object.values(DamageType);

const DamageClaimStep = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.damage.title')}
        description={t('repairCaseForm.damage.description')}
        tooltipMessage={t('repairCaseForm.damage.tooltip')}
      />

      <RadioGroup
        value={values.damageType}
        overlay
        className="grid w-full grid-cols-3 gap-4 overflow-auto"
      >
        {damageTypes.map((damageType) => (
          <Sheet
            className={classNames('rounded-lg border p-4 shadow-sm', 'flex items-center space-x-4')}
            key={damageType}
          >
            <Radio
              value={damageType}
              checkedIcon={<ValidIcon />}
              onChange={(e) => {
                setFieldValue('damageType', e.target.value);
              }}
            />

            <div>
              <DamageTypeIcon damageType={damageType} />
            </div>
            <FormLabel>{t(`damage.${decapitalize(damageType)}`)}</FormLabel>
          </Sheet>
        ))}
      </RadioGroup>

      <FormControl>
        <FormLabel required>{t('description')}</FormLabel>
        <Input
          placeholder={t('typeHere')}
          value={values.description}
          onChange={(e) => setFieldValue('description', e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t('remark')}</FormLabel>
        <Textarea
          value={values.remark}
          placeholder={t('typeHere')}
          onChange={(e) => setFieldValue('remark', e.target.value)}
        />
      </FormControl>
    </>
  );
};

export default DamageClaimStep;
