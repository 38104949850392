import { components } from '~/common/types/kpc.d';
import { ReadAllRequest } from '~/backend/services/fileManager';

import { FileManagerFile } from '@/types/documents';

import config from '@/config/config';
import createRestClient from '../restClient';

export type DownloadRequest =
  components['schemas']['KeypointConnect.Files.Backend.Rest.FileManager.V1.DTOs.Requests.DownloadRequest'];
export type ToggleShowOnPortalRequest =
  components['schemas']['KeypointConnect.Files.Backend.Rest.FileManager.V1.DTOs.Requests.ToggleRequest'];

const client = createRestClient(config.baseURL, true);
const restClient = createRestClient(config.baseURL, true);

const downloadFiles = (data: DownloadRequest) =>
  restClient.post('/filemanager/download', data, { responseType: 'blob' });

const getUri = (id: string) =>
  client.get<string>('/filemanager/uri', {
    params: { id },
  });

const readAllFiles = (data: ReadAllRequest) =>
  client.post<{ files: FileManagerFile[] }>('/filemanager/readallfiles', data);

const toggleShowOnPortal = (data: ToggleShowOnPortalRequest) =>
  restClient.put('/filemanager/showonportal', data);

export default {
  downloadFiles,
  getUri,
  readAllFiles,
  toggleShowOnPortal,
};
