import 'react-phone-input-2/lib/plain.css';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const DEFAULT_COUNTRY_BE = '32';

export enum PhoneNumberType {
  Mobile = 'Mobile',
  Landline = 'Landline',
}

type Props = {
  callBack: (number: string, dialCode: string) => void;
  hasError?: boolean;
  readOnly?: boolean;
  size?: string;
  value?: string;
} & PhoneInputProps;

const InternationalPhoneNumber: React.FC<Props> = ({
  callBack,
  hasError = false,
  readOnly = false,
  size = 'h-[1.917rem] w-full',
  value = '',
  ...rest
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (number: string, data: CountryData) => {
    setInputValue(number);
    callBack(number.substring(data.dialCode.length), data.dialCode);
  };

  useEffect(() => {
    if (value === null) {
      return;
    }

    if (value) {
      setInputValue(value);
    } else {
      setInputValue(DEFAULT_COUNTRY_BE);
    }
  }, [value]);

  return (
    <PhoneInput
      country="be"
      preferredCountries={['be']}
      regions="europe"
      value={inputValue}
      countryCodeEditable
      enableSearch
      searchPlaceholder={t('search.placeholder')}
      onChange={handleOnChange}
      disableDropdown
      buttonStyle={{ borderEndStartRadius: '6px', borderTopLeftRadius: '6px' }}
      inputProps={{
        className: classNames(
          'flex pl-11 pr-4 text-sm',
          'rounded-md border border-gray-300 bg-white',
          'focus:ring-2 focus:outline-none',
          {
            'focus:ring-[#0b6bcb] focus:border-transparent': !hasError,
            'ring-[0.5px] border-transparent ring-red-700': hasError,
          },
          size,
        ),
        readOnly,
      }}
      {...rest}
    />
  );
};

export default InternationalPhoneNumber;
