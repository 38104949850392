import { Avatar, AvatarProps } from '@mui/joy';
import classNames from 'classnames';
import { useMemo } from 'react';

import { Nullable } from '~/common/types';

import { getContrastingTextColor, stringToUniqueColor } from '@/shared/utils/color';
import { getAvatarInitials } from '@/shared/utils/helpers';

const FALLLBACK_BG = '#e2e8f0';

export const getSizeProps = (size: AvatarProps['size']) => {
  switch (size) {
    case 'sm':
      return {
        fontSize: 12,
        height: 24,
        width: 24,
      };
    case 'lg':
      return {
        fontSize: 20,
        height: 44,
        width: 44,
      };
    default:
      return {
        fontSize: 16,
        height: 32,
        width: 32,
      };
  }
};

type Props = Omit<AvatarProps, 'children' | 'sx'> & {
  name?: Nullable<string>;
};

const AvatarWithColor: React.FC<Props> = ({ name, size, className, ...avatarProps }) => {
  const { initials, sx } = useMemo(() => {
    const color = name ? stringToUniqueColor(name) : FALLLBACK_BG;

    return {
      initials: getAvatarInitials(name) || undefined,
      sx: {
        background: color,
        color: getContrastingTextColor(color),
        ...getSizeProps(size),
      },
    };
  }, [name, size]);

  return (
    <Avatar className={classNames('rounded-full p-0.5', className)} sx={sx} {...avatarProps}>
      {initials}
    </Avatar>
  );
};

export default AvatarWithColor;
