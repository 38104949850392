import { Input } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '../../icons/Icons';

type Props = {
  onSearch: (value: string) => void;
};

const ChatSearch: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 z-sticky bg-white px-4 py-4">
      <Input
        startDecorator={<SearchIcon />}
        className="rounded-2xl"
        type="text"
        name="search"
        placeholder={t('common:search.placeholder')}
        onChange={({ target }) => onSearch(target.value)}
      />
    </div>
  );
};

export default ChatSearch;
