import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Task } from '~/common/types/task/task';

import { AppContext } from '@/shared/context/context';
import { distinct } from '@/shared/utils/helpers';

import IconButton from '@/shared/components/buttons/IconButton';
import { SnoozeIcon } from '@/shared/icons/Icons';
import { useTaskActions } from '@/mutations';

type Props = {
  task: Task;
};

const SnoozeTaskButton: React.FC<Props> = ({ task, ...rest }) => {
  const { currentTeam } = useContext(AppContext);
  const { snoozeTask } = useTaskActions();
  const { t } = useTranslation();

  const isFinished = !!task.completedBy?.length || !!task.completedDate;

  const canEditTask = useMemo(() => {
    if (task && currentTeam) {
      const taskExecutors = task.taskUsers.filter((tu) => tu.isAssigned);
      const executorTeams = taskExecutors.map((te) => te.teamId).filter(distinct);

      return executorTeams.includes(currentTeam.id);
    }

    return false;
  }, [currentTeam, task]);

  return (
    <div {...rest}>
      <IconButton
        key={task.id}
        className="snooze-task-button"
        iconComponent={<SnoozeIcon />}
        tooltip={isFinished ? t('common:cannotChangeFinishedTask') : t('common:snooze')}
        onClick={() => snoozeTask(task.id)}
        disabled={isFinished || !canEditTask}
      />
    </div>
  );
};

export default SnoozeTaskButton;
