import CaseStatusType from '../../enums/CaseStatusType';
import CaseType from '../../enums/CaseType';

export const isAdmin = ({ relations, currentTeam, currentTeamUser }) => {
  if (!relations || !currentTeam || !currentTeamUser) return false;

  const currentTeamRelation = relations.find(
    (rel) => rel.teamId === currentTeam.id && rel.hasAccess,
  );
  if (!currentTeamRelation) return false;

  const { administrators } = currentTeamRelation;
  if (!administrators || administrators.length === 0) {
    return false;
  }

  return administrators.some((admin) => admin.teamUserId === currentTeamUser.id);
};

export const formatCaseType = ({ isCase, isComplaint, isInsuranceClaim, isRepairCase }) => {
  if (isComplaint) return CaseType.Complaint;
  if (isInsuranceClaim) return CaseType.InsuranceClaim;
  if (isRepairCase) return CaseType.Repair;
  if (isCase) return CaseType.Case;

  throw new Error('No case type specified');
};

export const formatCases = ({ cases, currentTeam, currentTeamUser }) => {
  const formattedCases = cases.map((caseItem) => {
    const { caseGroupReference, relations } = caseItem;
    const userIsAdministrator = isAdmin({ currentTeam, currentTeamUser, relations });
    const caseReferencePrefix = currentTeam?.caseReferencePrefix || 'KPC';
    const formattedCaseGroupReference = `${caseReferencePrefix}-${caseGroupReference}`;
    return {
      ...caseItem,
      caseGroupReference: formattedCaseGroupReference,
      type: formatCaseType(caseItem),
      userIsAdministrator,
    };
  });

  return formattedCases;
};

export const canEditCase = ({ currentCase, currentTeam, ownRelation }) => {
  if (
    !currentCase ||
    !currentCase.status ||
    !currentTeam.allowCaseManagement ||
    (currentCase.status.statusType === CaseStatusType.Pending &&
      (!ownRelation ||
        !(ownRelation.isRequestor || ownRelation.isCaseCreator) ||
        !ownRelation.hasAccess))
  ) {
    return false;
  }
  return true;
};
