enum LiBicValue {
  EFGBLI22 = 'EFGBLI22',
  LILALI2X = 'LILALI2X',
  NBANLI22 = 'NBANLI22',
  HYIBLI22 = 'HYIBLI22',
  VPBVLI2X = 'VPBVLI2X',
  SERBLI22 = 'SERBLI22',
  BLFLLI2X = 'BLFLLI2X',
  BFRILI22 = 'BFRILI22',
  VOAGLI22 = 'VOAGLI22',
  SFBALI22 = 'SFBALI22',
}

export default LiBicValue;

