import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  FormControl,
  FormLabel,
  Input,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useContext, useMemo } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import ReferencePropType from '../../../../../../shared/prop-types/ReferencePropType';
import useGetEnums from '../../../../../../queries/enums/useGetEnums';

import { CompanyIcon, ContactIcon, TeamIcon } from '@/shared/icons/Icons';
import FormHelperText from '@/shared/components/2.0/forms/FormHelperText';
import MandateFormBody from '../../../MandateFormBody';
import PhoneCallOutcome from '../../../../../../shared/enums/PhoneCallOutcome';
import useDayjs from '@/shared/hooks/useDayjs';

const clientIconMap = {
  Company: <CompanyIcon />,
  Contact: <ContactIcon />,
  Team: <TeamIcon />,
};

const propTypes = {
  editing: PropTypes.bool,
  popoverAnchorRef: ReferencePropType,
};

const IntakeCallFormBody = ({ editing = false }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { currentCase } = useContext(CaseContext);
  const { caseRelationOptions } = currentCase.relatedData;
  const { dayjs } = useDayjs();
  const { t } = useTranslation();
  const {
    enums: { phoneCallOutcomes },
  } = useGetEnums();

  const phoneCallOutcome = useMemo(
    () => phoneCallOutcomes.find((option) => option.name === values.phoneCallOutcome) || null,
    [values.phoneCallOutcome, phoneCallOutcomes],
  );

  return (
    <div className="space-y-2">
      <FormControl>
        <FormLabel required>{t('dateAndTimeOfReceipt')}</FormLabel>
        <DateTimePicker
          value={dayjs(values.callDate)}
          disableFuture
          ampm={false}
          PopperProps={{ placement: 'top-start' }}
          InputProps={{ className: 'h-8 text-sm rounded-md' }}
          onChange={(value) => {
            setFieldValue('callDate', value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <FormHelperText error={errors.callDate} touched={touched.callDate} />
      </FormControl>
      <FormControl>
        <FormLabel required>{t('client')}</FormLabel>
        <Autocomplete
          value={caseRelationOptions.find(
            (option) => option.relationId === values.calleeRelationId,
          )}
          onChange={(e, option) => {
            setFieldValue('r', option.value);
            setFieldValue('calleeRelationId', option.relationId);
          }}
          options={caseRelationOptions}
          renderOption={(props, option) => (
            <AutocompleteOption {...props} key={option.value}>
              <ListItemDecorator>{clientIconMap[option.type]}</ListItemDecorator>
              <ListItemContent> {option.label}</ListItemContent>
              {!!option.data.roles.length && (
                <ListItemDecorator>
                  {option.data.roles.map((role) => (
                    <Chip>{role}</Chip>
                  ))}
                </ListItemDecorator>
              )}
            </AutocompleteOption>
          )}
        />
        <FormHelperText error={errors.calleeRelationId} touched={touched.calleeRelationId} />
      </FormControl>

      <FormControl>
        <FormLabel>{t('note')}</FormLabel>
        <Input
          value={values.note}
          onChange={(e) => {
            setFieldValue('note', e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel required>{t('phoneCallOutcome')}</FormLabel>
        <Autocomplete
          value={phoneCallOutcome}
          onChange={(e, option) => {
            setFieldValue('phoneCallOutcome', option.name);
          }}
          getOptionLabel={(option) => option.name}
          options={phoneCallOutcomes}
        />
        <FormHelperText error={errors.phoneCallOutcome} touched={touched.phoneCallOutcome} />
      </FormControl>

      {(values?.phoneCallOutcome == null || values.phoneCallOutcome === PhoneCallOutcome.Success) &&
        !editing && (
          <>
            <hr className="mt-4" />
            <MandateFormBody
              selectedCompanyVat={
                currentCase.relations.find((rel) => values.calleeRelationId === rel.id)?.vat
              }
            />
          </>
        )}
    </div>
  );
};

IntakeCallFormBody.propTypes = propTypes;

export default IntakeCallFormBody;
