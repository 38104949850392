import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/joy';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { formatBytes, getFileTypeIconForContentType } from '@/shared/utils/helpers';
import { Nullable } from '~/common/types';

import { CancelIcon } from '@/shared/icons/Icons';

type DocumentProps = {
  fileName: string;
  fileSize: number;
  contentType?: Nullable<string>;
};

type FileProps = {
  name: string;
  size: number;
  contentType?: Nullable<string>;
};

type FileItemProps = {
  file: FileProps | DocumentProps;
  onRemove?: () => void;
};

const isFileProps = (file: FileProps | DocumentProps): file is FileProps =>
  (file as FileProps).name !== undefined;

const FileItem: React.FC<FileItemProps> = ({ file, onRemove }) => (
  <div className="flex w-48 items-center justify-between rounded border border-slate-200 bg-slate-50 p-2 dark:border-zinc-600 dark:bg-zinc-700">
    <div className="flex min-w-0 items-center">
      <FontAwesomeIcon
        className="mr-2 text-xl"
        icon={['fad', getFileTypeIconForContentType(file.contentType) as IconName]}
      />
      <div className="ml-2 mr-2 flex flex-col overflow-hidden">
        <div className="truncate text-xs">{isFileProps(file) ? file.name : file.fileName}</div>
        <span className="text-xxs">
          {formatBytes(isFileProps(file) ? file.size : file.fileSize)}
        </span>
      </div>
    </div>
    <div>
      {!!onRemove && (
        <IconButton variant="plain" color="neutral" onClick={onRemove}>
          <CancelIcon />
        </IconButton>
      )}
    </div>
  </div>
);

export default FileItem;
