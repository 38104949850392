import { AutocompleteOption, Nullable, PaginatedAutocomplete, TagResponse } from '~/common/types';
import {
  Building,
  BuildingImport,
  BuildingRelation,
  BuildingRelationImport,
  BuildingUnit,
  CreateBuildingImportRequest,
  CreateBuildingRelationImportRequest,
  CreateBuildingRelationRequest,
  CreateBuildingRequest,
  DeactivateBuildingRelationRequest,
  ExternalBuilding,
  SimpleBuilding,
  SimpleBuildingRelation,
  SimpleBuildingUnit,
  UpdateBuildingCompanyRelation,
  UpdateBuildingContactRelation,
} from '~/common/types/building';
import { BuildingResponse } from '~/backend/types/building';
import { CaseLink } from '~/common/types/case/case';
import config from '@/config/config';
import createRestClient from '../restClient';
import { ExternalDataProvider } from '~/common/enums';
import { UpdatePortalSettingsFormikValues } from '@/containers/Buildings/Details/forms/FormikValues';

const client = createRestClient(config.baseURL);

const getBuildingsAutocompleteWithCases = (
  params: Record<string, unknown>,
): Promise<AutocompleteOption[]> => client.get(`building/autocomplete/with-cases`, { params });

const getBuildingsAutocomplete = (
  params: Record<string, unknown>,
): Promise<PaginatedAutocomplete> => client.get('/building/autocomplete', { params });

const updateBuildingPortalSettings = (data: UpdatePortalSettingsFormikValues) =>
  client.put('/building/portal-settings', data);

const getBuildings = (urlParams: { [key: string]: unknown }): Promise<Building[]> =>
  client.get('/building', { params: urlParams });

const getBuildingsForHomeownerAssociation = (
  homeownerAssociationId: string,
): Promise<SimpleBuilding[]> =>
  client.get(`/building/homeownerAssociation/${homeownerAssociationId}`);

const getBuilding = (
  buildingId: string,
  params: Record<string, unknown>,
): Promise<
  Building & {
    contactsAutocomplete: AutocompleteOption[];
  }
> => client.get(`/building/${buildingId}`, { params });

const getBuildingContacts = (buildingId: string): Promise<BuildingRelation[]> =>
  client.get(`/building/${buildingId}/contacts`);

const restClient = createRestClient();

const getExternalBuildings = (
  provider: ExternalDataProvider,
  page: number,
  filter: Nullable<string>,
): Promise<ExternalBuilding[]> =>
  client.get('external/buildings', { params: { filter, page, provider } });

const getBuildingTags = (): Promise<TagResponse[]> => restClient.get(`building/tags`);

const deleteBuilding = (buildingId: string) => restClient.delete(`building/${buildingId}`);

const createBuilding = (data: CreateBuildingRequest): Promise<BuildingResponse> =>
  restClient.post('building', data);

const createBuildingRelation = (data: CreateBuildingRelationRequest) =>
  client.post('building/relation', data);

const updateBuildingCompanyRelationInfo = (data: UpdateBuildingCompanyRelation) =>
  client.put('building/relation/company', data);

const updateBuildingContactRelationInfo = (data: UpdateBuildingContactRelation) =>
  client.put('building/relation/contact', data);

const deactivateBuildingRelation = (data: DeactivateBuildingRelationRequest) =>
  client.put('building/relation/deactivate', data);

const deleteTag = (id: string) => restClient.delete('building/tag', { data: { tagId: id } });

// @ts-ignore
const updateBuilding = (data) => restClient.put('building', data);

// @ts-ignore
const createTag = (tag) => restClient.post('building/tag', tag);

// @ts-ignore
const updateTag = (tag) => restClient.put('building/tag', tag);

// @ts-ignore
const unLinkTag = (tag) => restClient.put('building/tag.unlink', tag);

// @ts-ignore
const linkTag = (tag) => restClient.put('building/tag.link', tag);

const getSimpleBuildingRelations = (buildingId: string): Promise<SimpleBuildingRelation[]> =>
  client.get(`/building/${buildingId}/relation`);

const getSimpleBuildingRelationsForHoa = (hoaId: string): Promise<SimpleBuildingRelation[]> =>
  client.get(`/building/relations/homeownerAssociation/${hoaId}`);

const getBuildingSummary = (buildingId: string): Promise<SimpleBuilding> =>
  client.get(`/building/${buildingId}/summary`);

const getLinkedCases = (buildingId: string): Promise<CaseLink[]> =>
  client.get(`/building/${buildingId}/cases`);

const getBuildingUnits = (buildingId: string): Promise<SimpleBuildingUnit[]> =>
  client.get(`/building/${buildingId}/units`);

const getBuildingUnitsByHoaId = (hoaId: string): Promise<BuildingUnit[]> =>
  client.get(`/building/units/homeownerAssociation/${hoaId}`);

const downloadBuildingImportFile: (buildingImportId: string) => Promise<string> = (
  buildingImportId,
) => client.get(`/building/import/${buildingImportId}/download`);

const getBuildingImports = (): Promise<BuildingImport[]> => client.get('/building/import/list');

const deleteBuildingImport = (id: string) => client.delete(`/building/import/${id}`);

const createBuildingImport = ({
  data,
  onProgress,
}: {
  data: CreateBuildingImportRequest;
  onProgress: CallableFunction;
}) =>
  client.postForm('/building/import', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

const downloadBuildingRelationImportFile: (
  buildingId: string,
  importId: string,
) => Promise<string> = (buildingId, importId) =>
  client.get(`/building/buildingRelationImport/${buildingId}/${importId}/download`);

const getBuildingRelationImports = (buildingId: string): Promise<BuildingRelationImport[]> =>
  client.get(`/building/buildingRelationImport/list/${buildingId}`);

const deleteBuildingRelationImport = (buildingId: string, id: string) =>
  client.delete(`/building/buildingRelationImport/${buildingId}/${id}`);

const createBuildingRelationImport = ({
  data,
  onProgress,
}: {
  data: CreateBuildingRelationImportRequest;
  onProgress: CallableFunction;
}) =>
  client.postForm('/building/buildingRelationImport', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

export default {
  createBuilding,
  createBuildingImport,
  createBuildingRelation,
  createBuildingRelationImport,
  createTag,
  deactivateBuildingRelation,
  deleteBuilding,
  deleteBuildingImport,
  deleteBuildingRelationImport,
  deleteTag,
  downloadBuildingImportFile,
  downloadBuildingRelationImportFile,
  getBuilding,
  getBuildingContacts,
  getBuildingImports,
  getBuildingRelationImports,
  getBuildings,
  getBuildingsAutocomplete,
  getBuildingsAutocompleteWithCases,
  getBuildingsForHomeownerAssociation,
  getBuildingSummary,
  getBuildingTags,
  getBuildingUnits,
  getBuildingUnitsByHoaId,
  getExternalBuildings,
  getLinkedCases,
  getSimpleBuildingRelations,
  getSimpleBuildingRelationsForHoa,
  linkTag,
  unLinkTag,
  updateBuilding,
  updateBuildingCompanyRelationInfo,
  updateBuildingContactRelationInfo,
  updateBuildingPortalSettings,
  updateTag,
};
