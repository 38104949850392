enum CaseRole {
  InsuranceIntermediary = 'InsuranceIntermediary',
  Insurer = 'Insurer',
  InsuranceEngineer = 'InsuranceEngineer',
  PropertyManager = 'PropertyManager',
  PolicyHolder = 'PolicyHolder',
  Tenant = 'Tenant',
  OwnerResident = 'OwnerResident',
  OwnerSecond = 'OwnerSecond',
  OwnerLessor = 'OwnerLessor',
  InsurerOppositeParty = 'InsurerOppositeParty',
  ExpertOppositeParty = 'ExpertOppositeParty',
  CourtExpert = 'CourtExpert',
  OwnCounterExpert = 'OwnCounterExpert',
  Lawyer = 'Lawyer',
  LawyerOppositeParty = 'LawyerOppositeParty',
  Contractor = 'Contractor',
  LeakSearchCompany = 'LeakSearchCompany',
  OppositeParty = 'OppositeParty',
  Other = 'Other',
}

export default CaseRole;
