import { useQuery } from '@tanstack/react-query';

import { luceneApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';

type Params = {
  countryCode: string;
  term: string;
};

type Options = {
  enabled?: boolean;
};

const useGetPostalCodeCity = (params: Params, options: Options = {}) => {
  const enabled =
    options.enabled !== undefined
      ? options.enabled
      : !!(params.term && params.term.length && params.countryCode === 'BE');

  const { data, error, isFetching } = useQuery({
    enabled,
    queryFn: async () => {
      const result = await luceneApi.getPostalCodeCity(params);

      const formatted = result.data.map(({ id, postalCode, city }) => ({
        city,
        id,
        label: `${postalCode} - ${city}`,
        postalCode,
      }));

      return {
        formatted,
        result,
      };
    },
    queryKey: [queryIds.lucene.GET_POSTALCODE_CITY, params.term, params.countryCode],
  });

  return {
    error,
    isFetchingPostalCodeCity: isFetching,
    postalCodeCity: data?.result.data || [],
    postalCodeCityAutoComplete: data?.formatted || [],
  };
};
export default useGetPostalCodeCity;
