import { useQuery } from '@tanstack/react-query';

import { contractorApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';

const useGetContractorsAvailableForRepair = () => {
  const { data, error, isLoading } = useQuery({
    queryFn: () => contractorApi.getContractorsAvailableForRepair(),
    queryKey: [queryIds.companies.GET_CONTRACTORS_AVAILABLE_FOR_REPAIR],
  });

  return {
    contractors: data ?? [],
    contractorsError: error,
    contractorsLoading: isLoading,
  };
};

export default useGetContractorsAvailableForRepair;
