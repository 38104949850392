import { GetLinkedPoliciesRequest, Policy } from '@/types/policies';
import config from '@/config/config';
import createRestClient from '@/shared/api/restClient';
import { encode } from '@/shared/api/helpers';

import {
  CreatePolicyRelationRequest,
  DeletePolicyRelationRequest,
  PolicyImport,
  UpdatePolicyRelationDetailsRequest,
  UpdatePolicyRelationRequest,
  UpdatePolicyRequest,
} from '~/common/types/policy/policy';

const client = createRestClient(config.baseURL);

const getPolicyImports = (): Promise<PolicyImport[]> => client.get('/policy/import/list');

const deletePolicyimport = (id: string) => client.delete(`/policy/import/${id}`);

const restClient = createRestClient();

const createPolicyImport = ({
  data,
  onProgress,
}: {
  data: FormData;
  onProgress: CallableFunction;
}) =>
  restClient.postForm('policyimport', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

const downloadPolicyImportFile: (policyImportId: string) => Promise<string> = (policyImportId) =>
  restClient.get(`/policyImport/${policyImportId}/download`);

const getPolicies: () => Promise<Policy[]> = () => restClient.get('policy');

const getPoliciesLinkedToAddressOrBuilding: (
  data: GetLinkedPoliciesRequest,
) => Promise<Policy[]> = (data) => restClient.get(`policy/linked-policies?${encode(data)}`);

const getPolicy: (id: string) => Promise<Policy> = (id) => restClient.get(`policy/${id}`);

const deletePolicy = (id: string) => restClient.delete(`policy/${id}`);

const updatePolicy = (data: UpdatePolicyRequest) => client.put('/policy', data);

const createPolicyRelation = (data: CreatePolicyRelationRequest) =>
  client.post('/policy/relation', data);

const updatePolicyRelation = (data: UpdatePolicyRelationRequest) =>
  client.put('/policy/relation', data);

const updatePolicyRelationDetails = (data: UpdatePolicyRelationDetailsRequest) =>
  client.put('/policy/relation/details', data);

const deletePolicyRelation = (data: DeletePolicyRelationRequest) =>
  client.delete('/policy/relation', { data });

export default {
  createPolicyImport,
  createPolicyRelation,
  deletePolicy,
  deletePolicyimport,
  deletePolicyRelation,
  downloadPolicyImportFile,
  getPolicies,
  getPoliciesLinkedToAddressOrBuilding,
  getPolicy,
  getPolicyImports,
  updatePolicy,
  updatePolicyRelation,
  updatePolicyRelationDetails,
};
