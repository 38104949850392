import { Button, IconButton, Tab, TabList, TabPanel, Tabs, Tooltip } from '@mui/joy';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '../../CaseContext';
import CaseStatusType from '@/shared/enums/CaseStatusType';
import CaseType from '@/shared/enums/CaseType';

import { CancelIcon, EditIcon, RepairCaseIcon } from '@/shared/icons/Icons';
import CaseBuildingCard from './CaseBuildingCard';
import CaseNotificationCard from './CaseNotificationCard';
import CasePoliciesCard from './CasePoliciesCard';
import formatContractorSkillDefinition from '../../util';
import { getCaseTypeTranslation } from '@/shared/utils/helpers';
import PaymentsOverview from './Payments/PaymentsOverview';
import RequestRIKModal from '@/containers/Cases/CaseLayout/modals/RequestRIKModal';
import useToggle from '@/shared/hooks/UseToggle';

const TABS = {
  address: 'address',
  caseDetails: 'case',
  payments: 'payments',
  policy: 'policy',
};

const CaseGeneralMultiCard = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { currentCase, ownRelation, canEditCase } = useContext(CaseContext);

  const [tabKey, setTabKey] = useState(TABS.caseDetails);
  const [isEditing, setIsEditing] = useState(false);

  const requestRikToggle = useToggle();

  const hasPolicyCard =
    currentCase.caseType === CaseType.Repair || currentCase.caseType === CaseType.InsuranceClaim;
  const hasPaymentsCard =
    currentCase.caseType === CaseType.Case || currentCase.caseType === CaseType.InsuranceClaim;

  const caseTypeTitle = useMemo(() => {
    if (currentCase.caseType === CaseType.Repair) {
      return currentCase.contractorSkillDefinition
        ? formatContractorSkillDefinition(
            currentCase.contractorSkillDefinition.translations,
            language,
          )
        : getCaseTypeTranslation(t, currentCase.caseType);
    }
    return getCaseTypeTranslation(t, currentCase.caseType);
  }, [t, currentCase, language]);
  return (
    <div id="general" className="rounded-2xl bg-white p-4">
      <Tabs
        value={tabKey}
        onChange={(e, value) => {
          setIsEditing(false);
          setTabKey(value);
        }}
      >
        <TabList>
          <div className="flex grow space-x-2">
            <Tab value={TABS.caseDetails}>{caseTypeTitle}</Tab>
            <Tab value={TABS.address}>
              {currentCase.building?.name ? t('buildingDetails') : t('_address.label')}
            </Tab>
            {hasPolicyCard && <Tab value={TABS.policy}>{t('policy')}</Tab>}
            {hasPaymentsCard && <Tab value={TABS.payments}>{t('payments')}</Tab>}
          </div>
          <div className="flex items-center">
            {ownRelation.isOwner &&
              (currentCase.caseType === CaseType.InsuranceClaim ||
                currentCase.caseType === CaseType.Case) && (
                <>
                  <Button
                    className="mr-3"
                    variant="solid"
                    startDecorator={<RepairCaseIcon />}
                    onClick={() => requestRikToggle.show(currentCase.id)}
                  >
                    {t('requestRepairInKind.title')}
                  </Button>
                  {requestRikToggle.value && requestRikToggle.state && (
                    <RequestRIKModal toggle={requestRikToggle} />
                  )}
                </>
              )}
            {(ownRelation.isOwner ||
              (currentCase.status.statusType === CaseStatusType.Pending &&
                (ownRelation.isRequestor || ownRelation.isCaseCreator))) &&
              canEditCase &&
              tabKey !== TABS.payments &&
              (isEditing ? (
                <Tooltip title={t('close')}>
                  <IconButton onClick={() => setIsEditing(false)} color="danger">
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t('edit')}>
                  <IconButton onClick={() => setIsEditing(true)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ))}
          </div>
        </TabList>

        <TabPanel value={TABS.caseDetails}>
          <CaseNotificationCard isEditing={isEditing} setIsEditing={setIsEditing} />
        </TabPanel>
        <TabPanel value={TABS.address}>
          <CaseBuildingCard isEditing={isEditing} setIsEditing={setIsEditing} />
        </TabPanel>
        <TabPanel value={TABS.policy}>
          <CasePoliciesCard isEditing={isEditing} setIsEditing={setIsEditing} />
        </TabPanel>
        <TabPanel value={TABS.payments}>
          <PaymentsOverview />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default CaseGeneralMultiCard;
