import { Dropdown, IconButton, Menu, MenuButton } from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

import { EllipsisIcon } from '../../icons/Icons';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disablePortal: PropTypes.bool,
  primaryChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const DropDownMore = ({ disablePortal, primaryChildren, children }) => (
  <div className="dropdown-more-mui">
    {primaryChildren && primaryChildren}
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { color: 'primary', variant: 'outlined' } }}
      >
        <EllipsisIcon />
      </MenuButton>
      <Menu
        disablePortal={disablePortal}
        keepMounted
        className="dropdown-more__menu"
        placement="right-end"
      >
        {children}
      </Menu>
    </Dropdown>
  </div>
);

DropDownMore.propTypes = propTypes;
DropDownMore.defaultProps = {
  disablePortal: false,
  primaryChildren: null,
};

export default DropDownMore;
