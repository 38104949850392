import { useQuery } from '@tanstack/react-query';

import { buildingApi } from '@/shared/api';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';

import useNotification from '@/shared/hooks/UseNotification';

const useGetSimpleBuildingRelations = (buildingId?: Nullable<string>) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!buildingId,
    onError: sendDefaultError,
    queryFn: () => buildingApi.getSimpleBuildingRelations(buildingId!),
    queryKey: [queryIds.buildings.GET_SIMPLE_BUILDING_RELATIONS, buildingId],
  });

  return {
    relations: data || [],
    relationsAreLoading: isLoading,
  };
};

export default useGetSimpleBuildingRelations;
