import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const FormikAutocomplete = ({
  name,
  getValue,
  label,
  freeSolo,
  required,
  onChangeCallback,
  multiple,
  ...rest
}) => {
  const { values, setValues, errors, touched, setTouched } = useFormikContext();
  const { t } = useTranslation(['common']);

  const onChange = (e, value) => {
    if (Array.isArray(value)) {
      const vals = value.map((val) => getValue(val));

      setValues({ ...values, [name]: vals });
      onChangeCallback(vals);
      return;
    }
    const val = getValue(value);

    if (val === values[name]) {
      setValues({ ...values, [name]: null });
      onChangeCallback(null);
      return;
    }

    setValues({ ...values, [name]: val });
    onChangeCallback(val);
  };

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      clearOnBlur={!freeSolo}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      {...rest}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      onChange={onChange}
      onInputChange={(_, data) => {
        if (!freeSolo) {
          return;
        }
        setValues({ ...values, [name]: data });
      }}
      value={values[name] ?? ''}
      renderInput={(params) => (
        <TextField
          required={required}
          label={label}
          variant="standard"
          error={touched[name] && errors[name] != null}
          helperText={touched[name] ? errors[name] : ''}
          {...params}
        />
      )}
    />
  );
};

FormikAutocomplete.propTypes = {
  freeSolo: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
};

FormikAutocomplete.defaultProps = {
  freeSolo: false,
  multiple: false,
  onChangeCallback: () => {},
  required: false,
};

export default FormikAutocomplete;
