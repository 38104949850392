import IntegrationPartner from '../../../common/enums/integration/integrationPartner';
import actionTypes from '../actions/actionTypes';

const initialState = {
  error: false,
  hasActiveCompanyWebIntegration: false,
  hasActiveExactOnlineIntegration: false,
  hasActiveIsabel6Integration: false,
  hasActiveOctopusIntegration: false,
  hasActiveOrganimmoIntegration: false,
  integrations: [],
  loading: false,
};

const integrations = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_TEAM:
      return initialState;
    case actionTypes.GET_INTEGRATIONS_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        hasActiveCompanyWebIntegration: action.integrations?.some(
          (i) => i.integrationPartner === IntegrationPartner.CompanyWeb && i.isActive,
        ),
        hasActiveExactOnlineIntegration: action.integrations?.some(
          (i) => i.integrationPartner === IntegrationPartner.ExactOnline && i.isActive,
        ),
        hasActiveIsabel6Integration: action.integrations?.some(
          (i) => i.integrationPartner === IntegrationPartner.Isabel6 && i.isActive,
        ),
        hasActiveOctopusIntegration: action.integrations?.some(
          (i) => i.integrationPartner === IntegrationPartner.Octopus && i.isActive,
        ),
        hasActiveOrganimmoIntegration: action.integrations?.some(
          (i) => i.integrationPartner === IntegrationPartner.Organimmo && i.isActive,
        ),
        integrations: action.integrations,
        loading: false,
      };
    case actionTypes.GET_INTEGRATIONS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default integrations;
