import { TFunction } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { decapitalize, formatDataForAutoComplete } from '../../shared/utils/helpers';
import { AutocompleteOption } from '~/common/types';
import { Language } from '~/common/enums';
import { MessageTemplate } from '~/common/types/team/messageTemplates';
import { queryIds } from '../../shared/utils/constants';
import { teamApi } from '@/shared/api';
import useNotification from '../../shared/hooks/UseNotification';

const getDescription = (template: MessageTemplate, contentLanguage: Language, t: TFunction) => {
  if (template.description) {
    return `${template.description} [${contentLanguage}]`;
  }

  if (template.triggers) {
    const translatedTriggers = template.triggers
      .map((tr) => t(`common:${decapitalize(tr)}`))
      .join(', ');

    return `${t(
      `common:${decapitalize(template.receiver)}`,
    )} - ${translatedTriggers} [${contentLanguage}]`;
  }

  return `${t(`common:${decapitalize(template.receiver)}`)} - [${contentLanguage}]`;
};

export type MessageTemplateFilter = {
  (template: MessageTemplate): boolean;
};

export type MessageTemplateFormatted = AutocompleteOption & {
  isAutomaticTemplate: boolean;
};

const useMessageTemplates = (filter: MessageTemplateFilter | null = null) => {
  const { sendDefaultError } = useNotification();
  const { t } = useTranslation('common');

  const { data, isLoading } = useQuery({
    enabled: !!filter,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      let result = await teamApi.getAllTemplates();
      result = result.filter(filter as MessageTemplateFilter);

      const formatted: AutocompleteOption[] = formatDataForAutoComplete({
        data: result,
        label: 'description',
        value: 'id',
      });

      const contents = result
        .map((template) =>
          template.contents.map((content) => ({
            context: template.context,
            description: getDescription(template, content.language, t),
            isAutomaticTemplate: template.isAutomaticTemplate,
            ...content,
          })),
        )
        .flat();
      const contentsFormatted: AutocompleteOption[] = formatDataForAutoComplete({
        data: contents,
        label: 'description',
        value: 'id',
      });

      const contentsWithIsAutomaticTemplate: MessageTemplateFormatted[] = contentsFormatted.map(
        (formattedElement: any) => {
          const correspondingElementInContents = contents.find(
            (element) => element.id === formattedElement.value,
          );

          return correspondingElementInContents
            ? {
                ...formattedElement,
                isAutomaticTemplate: correspondingElementInContents.isAutomaticTemplate,
              }
            : formattedElement;
        },
      );

      return { contents, contentsWithIsAutomaticTemplate, formatted, result };
    },
    queryKey: [queryIds.team.MESSAGE_TEMPLATES],
  });

  return {
    areMessageTemplatesLoading: isLoading,
    messageTemplates: data?.result ?? [],
    messageTemplatesAutocomplete: data?.formatted ?? [],
    messageTemplatesContents: data?.contents ?? [],
    messageTemplatesContentsAutocomplete: data?.contentsWithIsAutomaticTemplate ?? [],
  };
};

export default useMessageTemplates;
