import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '@/shared/api/cases/case';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useUpdateCaseEntityRemark = (caseId: string) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: caseApi.putCaseEntityRemark,
    onError: (error) => {
      sendDefaultError(error);
    },
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('remark') }),
        variant: 'success',
      });
      queryClient.invalidateQueries([queryIds.cases.GET_CASE_ENTITIES, caseId]);
    },
  });

  return {
    isUpdateCaseEntityRemarkLoading: isLoading,
    updateCaseEntityRemark: mutate,
  };
};

export default useUpdateCaseEntityRemark;
