import actionTypes from '../actions/actionTypes';

const initialState = {
  invitations: [],
  settings: null,
  currentTeamUser: null,
  loading: true,
  error: false,
};

const usersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_USER_TEAM_SETTINGS_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_USER_TEAM_SETTINGS_SUCCESS:
      return { ...state, loading: false, settings: action.settings };
    case actionTypes.GET_USER_TEAM_SETTINGS_FAILED:
      return { ...state, loading: false, error: true };
    case actionTypes.GET_USER_TEAM_INVITATIONS_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_USER_TEAM_INVITATIONS_SUCCESS:
      return { ...state, loading: false, invitations: action.invitations };
    case actionTypes.GET_USER_TEAM_INVITATIONS_FAILED:
      return { ...state, loading: false, error: true };
    case actionTypes.GET_CURRENT_TEAM_USER_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_CURRENT_TEAM_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTeamUser: action.currentTeamUser,
        settings: action.settings,
      };
    case actionTypes.GET_CURRENT_TEAM_USER_FAILED:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default usersReducer;
