import { TeamUser, TeamWithInvitation } from '~/common/types/team/team';
import {
  UpdateQrSettingsRequest,
  UpdateQRTokenBuildingLinkRequest,
} from '~/common/types/team/teamRequests';
import config from '@/config/config';
import createRestClient from '../restClient';
import { MessageTemplate } from '~/common/types/team/messageTemplates';
import { QRToken } from '~/common/types/team/qrTokens';
import { TeamResponse } from '~/common/types/team/teamResponse';
import { User } from '~/common/types/team/user';

const restClient = createRestClient(config.baseURL);

const acceptTeamInvitation = (data: { teamId: string; teamInvitationId: string }): Promise<any> =>
  createRestClient().post('team/invitation.accept', data);

const getCurrentTeam = (): Promise<TeamResponse> => restClient.get('/team');

const getCurrentTeamUser = (): Promise<User> => restClient.get('/team/user');

const getCurrentTeamUsers = (): Promise<TeamUser[]> => createRestClient().get(`team/users`);

const getTeamsAndInvitations = (): Promise<TeamWithInvitation[]> =>
  restClient.get('/team/teams-and-invitations');

const updateQrSettings = (data: UpdateQrSettingsRequest) =>
  restClient.put(`team/qr-settings`, data);

const getAllTemplates = (): Promise<MessageTemplate[]> =>
  restClient.get('team/all-message-templates');

const getQRTokens = (): Promise<QRToken[]> => restClient.get('team/qr-token.list');

const createQRToken = () => restClient.post('team/qr-token');

const updateQRTokenBuildingLink = (data: UpdateQRTokenBuildingLinkRequest) =>
  restClient.put('team/qr-token', data);

export default {
  acceptTeamInvitation,
  createQRToken,
  getAllTemplates,
  getCurrentTeam,
  getCurrentTeamUser,
  getCurrentTeamUsers,
  getQRTokens,
  getTeamsAndInvitations,
  updateQrSettings,
  updateQRTokenBuildingLink,
};
