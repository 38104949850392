import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Chip, TextField } from '@mui/material';
import CaseType from '../../../enums/CaseType';
import { getCaseTypeTranslation } from '../../../utils/helpers';
import { CaseIcon, ComplaintCaseIcon, InsuredCaseIcon, RepairCaseIcon } from '../../../icons/Icons';

const CaseTypeFilter = ({ callback, name, defaultValue }) => {
  const { t } = useTranslation('common');
  const possibleCaseTypes = [
    CaseType.InsuranceClaim,
    CaseType.Repair,
    CaseType.Complaint,
    CaseType.Case,
  ];

  const getOption = React.useCallback(
    (caseType) => {
      switch (caseType) {
        case CaseType.Complaint:
          return (
            <>
              <i className="me-2">
                <ComplaintCaseIcon />
              </i>{' '}
              {getCaseTypeTranslation(t, caseType)}
            </>
          );
        case CaseType.InsuranceClaim:
          return (
            <>
              <i className="me-2">
                <InsuredCaseIcon />
              </i>{' '}
              {getCaseTypeTranslation(t, caseType)}
            </>
          );
        case CaseType.Repair:
          return (
            <>
              <i className="me-2">
                <RepairCaseIcon />
              </i>{' '}
              {getCaseTypeTranslation(t, caseType)}
            </>
          );
        default:
          return (
            <>
              <i className="me-2">
                <CaseIcon />
              </i>{' '}
              {getCaseTypeTranslation(t, caseType)}
            </>
          );
      }
    },
    [t],
  );

  const getOptionRender = React.useCallback(
    (props, caseType) => <li {...props}>{getOption(caseType)}</li>,
    [getOption],
  );

  return (
    <div>
      <Autocomplete
        noOptionsText={t('common:noOptions')}
        multiple
        options={possibleCaseTypes}
        fullWidth
        value={defaultValue}
        onChange={(e, values) => callback(name, values)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} label={getOption(option)} key={option} />
          ))
        }
        renderOption={getOptionRender}
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t('common:type')} />
        )}
      />
    </div>
  );
};

CaseTypeFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
};

CaseTypeFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
};

export default CaseTypeFilter;
