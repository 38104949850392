import { Grid } from '@mui/joy';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { decapitalize, formatAsCurrency, sentencize } from '../../../../shared/utils/helpers';
import { CaseContext } from '../../CaseContext';
import CaseType from '../../../../shared/enums/CaseType';
import ExcessType from '../../../../shared/enums/ExcessType';
import LinkPolicyButton from '../../../../shared/components/buttons/LinkPolicyButton';
import useUpdateInsurancePolicy from '../../../../mutations/case/insurance/useUpdateInsurancePolicy';
import useUpdateRepairPolicy from '../../../../mutations/case/repair/useUpdateRepairPolicy';

import DeleteAction from '../../../../shared/components/DeleteAction';

const CasePoliciesCardDetails = () => {
  const { currentCase, canEditCase } = useContext(CaseContext);

  const { t } = useTranslation();

  const { updateRepairPolicy } = useUpdateRepairPolicy();
  const { updateInsurancePolicy } = useUpdateInsurancePolicy();

  const hasExcess = currentCase.excessType && currentCase.excessType !== ExcessType.None;

  const linkOrUnlinkCasePolicy = (policy, isUnlink) => {
    const policyId = policy.isPolicyNumber ? null : policy.id;
    const casePolicy = {
      caseId: currentCase.id,
      policyId: isUnlink ? null : policyId,
      policyNumber: policy.policyNumber,
    };

    if (currentCase.caseType === CaseType.Repair) {
      updateRepairPolicy(casePolicy);
    } else {
      updateInsurancePolicy(casePolicy);
    }
  };

  const getPolicyNumber = (casePolicy) => {
    if (casePolicy.policyId != null) {
      return <Link to={`/policy/${casePolicy.policyId}`}>{casePolicy.policyNumber}</Link>;
    }
    return casePolicy.policyNumber ?? '-';
  };

  return (
    <div className="d-flex">
      <div style={{ flexGrow: 1 }}>
        {!currentCase.policies ? (
          <Grid container columnSpacing={2}>
            <Grid item md={4}>{`${t('policyNumber')}:`}</Grid>
            <Grid item md={8}>
              -
            </Grid>
          </Grid>
        ) : (
          <div>
            {currentCase.policies?.map((p) => (
              <div key={p.id} className="border-b-2 py-1.5">
                <Grid container columnSpacing={2} className="flex items-center">
                  <Grid item md={4}>{`${t('policyNumber')}:`}</Grid>
                  <Grid item md={8}>
                    {getPolicyNumber(p)}
                    {canEditCase && p.policyId && (
                      <DeleteAction
                        onDelete={() => linkOrUnlinkCasePolicy(p, true)}
                        tooltip={sentencize(`${t('unlinkX', { type: t('policy') })}`)}
                        isUnlink
                      />
                    )}
                    {canEditCase && !p.policyId && (
                      <LinkPolicyButton
                        policyNumber={p.policyNumber}
                        onPolicySelection={(policy) => linkOrUnlinkCasePolicy(policy, false)}
                      />
                    )}
                  </Grid>
                </Grid>
                {p.policyId && (
                  <div key={p.id}>
                    <Grid container columnSpacing={2}>
                      <Grid item md={4}>{`${t('policyType')}:`}</Grid>
                      <Grid item md={8}>
                        {t(`insurancePolicy:${decapitalize(p.policyType)}`)}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="mt-2">
          {hasExcess ? (
            <>
              <Grid container columnSpacing={2}>
                <Grid item md={4}>{`${t('excessType')}:`}</Grid>
                <Grid item md={8}>
                  {t(`${decapitalize(currentCase.excessType)}`)}
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item md={4}>{`${t('excess')}:`}</Grid>
                <Grid item md={8}>
                  {currentCase.excessAmount
                    ? `€ ${formatAsCurrency(currentCase.excessAmount)}`
                    : '-'}
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item md={4}>{`${t('increasedExcess')}:`}</Grid>
                <Grid item md={8}>
                  {currentCase.excessIncreasedAmount
                    ? `€ ${formatAsCurrency(currentCase.excessIncreasedAmount)}`
                    : '-'}
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item md={4}>{`${t('excessRemark')}:`}</Grid>
                <Grid item md={8}>
                  {currentCase.excessRemark ?? '-'}
                </Grid>
              </Grid>
            </>
          ) : (
            <div>{t('noX', { type: t('excess') })}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CasePoliciesCardDetails;
