import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  ActionContainer,
  InvoiceContainer,
} from '../../../FinancialManagement/BodySections/Transactions/Styles/Styles';
import { colorGreen, colorPrimary, colorRed } from '../../../../../shared/utils/palette';
import { decapitalize, formatAsCurrency, sentencize } from '../../../../../shared/utils/helpers';
import { CommentIcon } from '../../../../../shared/icons/Icons';
import DeleteAction from '../../../../../shared/components/DeleteAction';
import DropDownMore from '../../../../../shared/components/dropdowns/DropDownMore';
import IconButton from '../../../../../shared/components/buttons/IconButton';
import KpcTooltip from '../../../../../shared/components/Tooltips/KpcTooltip';

const PaymentContainer = styled(InvoiceContainer)`
  min-height: 100px;
  height: fit-content;
  padding-bottom: 1rem;
`;

const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: ${({ inbound }) => (inbound ? 'end' : 'start')};
`;

const TotalsWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  font-size: 0.75rem;
`;

const TotalDisplay = styled.span`
  color: ${({ completed }) => (completed ? colorGreen : colorRed)};
`;

const DescriptionWrapper = styled.p`
  white-space: pre-wrap;
  padding-bottom: 4px;
`;

const LinkDisplay = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;

  background-color: ${colorPrimary};

  text-align: center;
  font-size: 0.75rem;
  color: white;
`;

const PaymentDisplay = ({ payment, openRemarkModal, onEdit, onRemove, canEdit }) => {
  const { t } = useTranslation(['common', 'finance']);

  return (
    <Wrapper inbound={payment.inbound}>
      <PaymentContainer>
        <ActionContainer>
          {(canEdit || payment.remark) && (
            <KpcTooltip
              title={
                payment.remark
                  ? payment.remark
                  : sentencize(t('common:addType', { type: t('common:remark') }))
              }
            >
              <IconButton
                disabled={!canEdit}
                iconComponent={<CommentIcon iconStyle={payment.remark ? 'fad' : 'far'} />}
                onClick={openRemarkModal}
                color="keypoint"
              />
            </KpcTooltip>
          )}
          {canEdit && (
            <DropDownMore>
              <MenuItem onClick={onEdit}>{t('common:edit')}</MenuItem>
              {(!payment.inbound || !payment.linkNumber) && (
                <DeleteAction dropdown onDelete={onRemove} />
              )}
            </DropDownMore>
          )}
        </ActionContainer>
        <p>{t(`common:${decapitalize(payment.type)}`)}</p>
        <p>{payment.relationDisplayName}</p>
        <DescriptionWrapper>{payment.description}</DescriptionWrapper>
        <TotalsWrapper>
          {t('finance:total')}:{' '}
          <TotalDisplay completed={payment.completed}>
            {formatAsCurrency(payment.amount, true)}
          </TotalDisplay>
        </TotalsWrapper>
        {!!payment.linkNumber && <LinkDisplay>{payment.linkNumber}</LinkDisplay>}
      </PaymentContainer>
    </Wrapper>
  );
};

PaymentDisplay.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  openRemarkModal: PropTypes.func.isRequired,
  payment: PropTypes.shape().isRequired,
};

export default PaymentDisplay;
