enum LuBicValue {
  BCEELULL = 'BCEELULL',
  BILLLULL = 'BILLLULL',
  BGLLLULL = 'BGLLLULL',
  BSUILULL = 'BSUILULL',
  BLUXLULL = 'BLUXLULL',
  CCRALULL = 'CCRALULL',
  FOTNLULL = 'FOTNLULL',
  CELLLULL = 'CELLLULL',
  BMECLULL = 'BMECLULL',
  BAERLULU = 'BAERLULU',
  BSCHLULL = 'BSCHLULL',
  CITILULX = 'CITILULX',
  DEUTLULL = 'DEUTLULL',
  DBSALULL = 'DBSALULL',
  NOLALULL = 'NOLALULL',
  WBWCLULL = 'WBWCLULL',
  SGABLU2S = 'SGABLU2S',
  SGABLULL = 'SGABLULL',
  HAUKLULL = 'HAUKLULL',
  ESSELULL = 'ESSELULL',
  CHASLULX = 'CHASLULX',
  UBSWLULL = 'UBSWLULL',
  MUGCLULL = 'MUGCLULL',
  CRESLULL = 'CRESLULL',
  NIKOLULL = 'NIKOLULL',
  DEGRLULL = 'DEGRLULL',
  SEBKLULL = 'SEBKLULL',
  SNCILUL1 = 'SNCILUL1',
  GENOLULL = 'GENOLULL',
  CCPLLULL = 'CCPLLULL',
  BKCHLULL = 'BKCHLULL',
  AGRILULA = 'AGRILULA',
  SPLBLUL1 = 'SPLBLUL1',
  BBDELULL = 'BBDELULL',
  BPCPLULL = 'BPCPLULL',
  DGZFLULI = 'DGZFLULI',
  UBNLLULL = 'UBNLLULL',
  STBCLULL = 'STBCLULL',
  BLICLULX = 'BLICLULX',
  BSAFLULL = 'BSAFLULL',
  BNPALULS = 'BNPALULS',
  ERBKLULL = 'ERBKLULL',
  UNCRLULL = 'UNCRLULL',
  RBOSLULL = 'RBOSLULL',
  HSHNLULB = 'HSHNLULB',
  BBDALULX = 'BBDALULX',
  PRIBLULL = 'PRIBLULL',
  BKBKLULL = 'BKBKLULL',
  BBHCLULL = 'BBHCLULL',
  MHTBLULL = 'MHTBLULL',
  VPBVLULL = 'VPBVLULL',
  CMCILUL1 = 'CMCILUL1',
  NATXLULL = 'NATXLULL',
  PICTLULX = 'PICTLULX',
  BKCHLULA = 'BKCHLULA',
  HANDLULB = 'HANDLULB',
  CHASLULW = 'CHASLULW',
  DEUTLULB = 'DEUTLULB',
  PBNKLULL = 'PBNKLULL',
  FISPLU22 = 'FISPLU22',
  HAVLLULL = 'HAVLLULL',
  CEDELULL = 'CEDELULL',
  BPMOLULS = 'BPMOLULS',
  EFGBLULX = 'EFGBLULX',
  DELELULL = 'DELELULL',
  FIBKLULL = 'FIBKLULL',
  ICBKLULL = 'ICBKLULL',
  SWQBLULL = 'SWQBLULL',
  PARBLULL = 'PARBLULL',
  CRESLULX = 'CRESLULX',
  IRVTLULX = 'IRVTLULX',
  FETALULL = 'FETALULL',
  MEOILUL1 = 'MEOILUL1',
  ADVZLULL = 'ADVZLULL',
  ICBKLULU = 'ICBKLULU',
  CITCLULL = 'CITCLULL',
  CBPXLULL = 'CBPXLULL',
  PPLXLULL = 'PPLXLULL',
  SBOSLULX = 'SBOSLULX',
  KEYTLULL = 'KEYTLULL',
  BACALULL = 'BACALULL',
  BBPPLULL = 'BBPPLULL',
  LOCYLULL = 'LOCYLULL',
  BESCLULL = 'BESCLULL',
  BMARLU2M = 'BMARLU2M',
  SAFRLULL = 'SAFRLULL',
  BEMOLULL = 'BEMOLULL',
  HSBCLULL = 'HSBCLULL',
  PCBCLULL = 'PCBCLULL',
  PCBCLULX = 'PCBCLULX',
  GAZPLULL = 'GAZPLULL',
  MIRALULL = 'MIRALULL',
  ALLFLULL = 'ALLFLULL',
  COMMLULL = 'COMMLULL',
  CMBCLULL = 'CMBCLULL',
  ABOCLULL = 'ABOCLULL',
  ABOCLULB = 'ABOCLULB',
  RRBALULL = 'RRBALULL',
  HYVELULL = 'HYVELULL',
  CNORLULX = 'CNORLULX',
  BCIRLULL = 'BCIRLULL',
  BARCLULL = 'BARCLULL',
  ESSELU22 = 'ESSELU22',
  CAIXLULL = 'CAIXLULL',
  CRBALULL = 'CRBALULL',
  USBKLU2L = 'USBKLU2L',
  GOLDLULL = 'GOLDLULL',
  CHDBLU22 = 'CHDBLU22',
  INVLLULL = 'INVLLULL',
  OLKILUL1 = 'OLKILUL1',
  SOXALULL = 'SOXALULL',
  PANXLUL2 = 'PANXLUL2',
  EBPBLUL2 = 'EBPBLUL2',
  EWUBLUL2 = 'EWUBLUL2',
  RAPSLUL1 = 'RAPSLUL1',
  KBLXLULL = 'KBLXLULL',
  DOCKLU22 = 'DOCKLU22',
  IBNXLULM = 'IBNXLULM',
  PPLXLUL2 = 'PPLXLUL2',
  MUGCLULX = 'MUGCLULX',
  BOFALULL = 'BOFALULL',
  SATYLUL1 = 'SATYLUL1',
  MAGYLUL1 = 'MAGYLUL1',
  SSWILUL1 = 'SSWILUL1',
  DOCKLUL2 = 'DOCKLUL2',
  VPAYLUL2 = 'VPAYLUL2',
  PIPOLUL2 = 'PIPOLUL2',
  VVIDLUL2 = 'VVIDLUL2',
  MOLULU22 = 'MOLULU22',
  BEILLULL = 'BEILLULL',
  BCLXLULL = 'BCLXLULL',
}

export default LuBicValue;
