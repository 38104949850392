import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '../../shared/api/cases/case';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useRejectCase = () => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => caseApi.putRejectCase(data),
    onError: (error) => {
      sendDefaultError(error);
    },
    onSuccess: (_, { caseId }) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASE, caseId]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('case_one') }),
        variant: 'success',
      });
    },
  });

  return {
    isRejectingCase: isLoading,
    rejectCase: mutate,
  };
};

export default useRejectCase;
