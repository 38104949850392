import { useQuery } from '@tanstack/react-query';

import contractApi from '../../shared/api/contract/contract';
import { formatDataForAutoComplete } from '../../shared/utils/helpers';
import { queryIds } from '../../shared/utils/constants';

const useGetContracts = (options = {}) => {
  const { data, error, isLoading } = useQuery({
    enabled: options.enabled ?? true,
    queryFn: async () => {
      let result = await contractApi.getContracts();

      if (options.hideInactiveContracts) {
        result = result.filter((c) => c.active);
      }

      const formatted = formatDataForAutoComplete({
        data: result,
        label: 'description',
        value: 'id',
      });

      return { formatted, result };
    },
    queryKey: [queryIds.contracts.GET_CONTRACTS, options.hideInactiveContracts],
  });

  return {
    contracts: data?.result || [],
    contractsAutocomplete: data?.formatted || [],
    contractsError: error,
    contractsIsLoading: isLoading,
  };
};

export default useGetContracts;
