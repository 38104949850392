import PropTypes from 'prop-types';

import CaseEmailPreviewActions from './CaseEmailPreviewActions';
import Email from '@/shared/components/2.0/email/Components/Email';
import EmailLocalBodyPreview from './EmailLocalBodyPreview';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

import TogglePropTypes from '@/shared/prop-types/TogglePropTypes';

const propTypes = {
  email: PropTypes.shape({
    bcc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    bodyUrl: PropTypes.string,
    cc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    dateEpoch: PropTypes.number,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        contentType: PropTypes.string,
        fileName: PropTypes.string,
        id: PropTypes.string,
        isAttachment: PropTypes.bool,
      }),
    ),
    from: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    id: PropTypes.string,
    status: PropTypes.string,
    subject: PropTypes.string,
    to: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
  }),
  openCompose: PropTypes.func.isRequired,
  toggle: TogglePropTypes,
};

const CaseViewEmailModal = ({ toggle, email, openCompose }) => (
  <KpcModal toggle={toggle}>
    <Email
      areAttachmentsCaseFiles
      email={email}
      emailActions={
        <CaseEmailPreviewActions
          email={email}
          emailBodyEnabled={toggle.value}
          actionCallback={() => {
            toggle.hide();
            openCompose();
          }}
        />
      }
    >
      <EmailLocalBodyPreview bodyUrl={email.bodyUrl} />
    </Email>
  </KpcModal>
);
CaseViewEmailModal.propTypes = propTypes;

export default CaseViewEmailModal;
