import { closeRowEditors, saveRowEditors, updateEditorValue } from 'ka-table/actionCreators';
import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { addEscEnterKeyEffect, isEmptyObject, isObject } from '../../../utils/helpers';
import { CancelIcon, SaveIcon } from '../../../icons/Icons';
import IconButton from '../../buttons/IconButton';

const GenericEditor = (props) => {
  const { column, rowKeyValue, dispatch, innerComponentRender, valueTransformer, hideSaveButtons } =
    props;

  const isAddingNew = rowKeyValue == null || (isObject(rowKeyValue) && isEmptyObject(rowKeyValue));

  const updateValue = (val) => {
    dispatch(updateEditorValue(rowKeyValue ?? 'newRowKeyValue', column.key, valueTransformer(val)));
  };

  const onSave = useCallback(() => {
    if (!isAddingNew) {
      dispatch(
        saveRowEditors(rowKeyValue, {
          validate: true,
        }),
      );
    }
  }, [dispatch, isAddingNew, rowKeyValue]);

  const onCancel = useCallback(() => {
    dispatch(closeRowEditors(rowKeyValue));
  }, [dispatch, rowKeyValue]);

  useEffect(() => {
    addEscEnterKeyEffect(onCancel, onSave);
  }, [onCancel, onSave]);

  const { t } = useTranslation('common');

  return (
    <div className="d-flex">
      {innerComponentRender({ isAddingNew, updateValue })}
      {!isAddingNew && !hideSaveButtons && (
        <div className="table-editor-buttons align-self-end">
          <IconButton
            className="mt-auto"
            iconComponent={<SaveIcon />}
            color="keypoint"
            tooltip={t('common:save')}
            onClick={onSave}
            style={{ marginTop: 'auto' }}
          />
          <IconButton
            className="mt-auto"
            iconComponent={<CancelIcon />}
            color="red"
            tooltip={t('common:cancel')}
            onClick={onCancel}
          />
        </div>
      )}
    </div>
  );
};

export default GenericEditor;

GenericEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  hideSaveButtons: PropTypes.bool,
  innerComponentRender: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rowKeyValue: PropTypes.any.isRequired,
  valueTransformer: PropTypes.func,
};

GenericEditor.defaultProps = {
  hideSaveButtons: false,
  valueTransformer: (val) => val,
};
