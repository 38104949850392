import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  uri: string;
  onLoad?: () => void;
  onError?: () => void;
};

const MSDocRenderer: React.FC<Props> = ({ uri, onLoad, onError, className }) => (
  <iframe
    className={classNames('h-full w-full border-none', className)}
    id="msdoc-iframe"
    title="msdoc-iframe"
    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(uri)}`}
    onLoad={onLoad}
    onError={onError}
  />
);

export default MSDocRenderer;
