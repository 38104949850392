import * as yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddressPropTypes from '../../../../../shared/prop-types/AddressPropTypes';
import CaseTypePropTypes from '../../../../../shared/prop-types/CaseTypePropTypes';
import EditCaseBuildingFormBody from './EditCaseBuildingFormBody';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

const EditCaseBuildingForm = ({
  onCancel,
  onSubmit,
  address,
  buildingId,
  worksiteRegistrationNbr,
  caseType,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  const initialValues = useMemo(
    () => ({
      addressLine: address.addressLine ?? '',
      buildingId,
      city: address.city ?? '',
      country: address.country ?? null,
      postalCode: address.postalCode ?? '',
      worksiteRegistrationNbr: worksiteRegistrationNbr ?? '',
    }),
    [address, worksiteRegistrationNbr, buildingId],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        addressLine: yup
          .string()
          .nullable()
          .when('buildingId', {
            is: (bId) => bId == null,
            then: yup.string().nullable().required(t('errors:fieldIsRequired')),
          }),
        buildingId: yup.string().nullable(),
        city: yup.string().nullable(),
        country: yup.string().nullable(),
        postalCode: yup.string().nullable(),
        worksiteRegistrationNbr: yup.string().nullable(),
      })}
    >
      <Form>
        <EditCaseBuildingFormBody buildingId={buildingId} caseType={caseType} />
        <ModalFooter onCancel={onCancel} />
      </Form>
    </Formik>
  );
};

EditCaseBuildingForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  address: AddressPropTypes,
  buildingId: PropTypes.string,
  worksiteRegistrationNbr: PropTypes.string,
  caseType: CaseTypePropTypes.isRequired,
};

EditCaseBuildingForm.defaultProps = {
  address: null,
  buildingId: null,
  worksiteRegistrationNbr: '',
};

export default EditCaseBuildingForm;
