import { IconButton } from '@mui/joy';
import { MobileTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

import { ClearIcon } from '@/shared/icons/Icons';
import useDayjs from '@/shared/hooks/useDayjs';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
};

const defaultProps = {
  onChangeCallback: () => {},
  required: false,
};

const FormikTimePicker = ({ name, required, onChangeCallback, ...rest }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const { getUtcDateObject, formatTimeWithSeconds } = useDayjs();

  const clearInput = (e) => {
    e.stopPropagation();
    setFieldValue(name, null);
  };

  return (
    <MobileTimePicker
      {...rest}
      ampm={false}
      onChange={(val) => {
        const v = val ? formatTimeWithSeconds(val) : null;
        setFieldValue(name, v);
        onChangeCallback(v);
      }}
      value={getUtcDateObject(values[name], 'HH:mm:ss').local()}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          error={touched[name] && errors[name] != null}
          helperText={touched[name] ? errors[name] : null}
          onBlur={() => setFieldTouched(name, true)}
          required={required}
          fullWidth
          InputProps={
            values[name] && {
              endAdornment: (
                <IconButton variant="plain" onClick={clearInput}>
                  <ClearIcon />
                </IconButton>
              ),
            }
          }
        />
      )}
    />
  );
};

FormikTimePicker.propTypes = propTypes;
FormikTimePicker.defaultProps = defaultProps;

export default FormikTimePicker;
