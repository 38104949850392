import { AutocompleteOption } from '~/common/types';
import createRestClient from '../restClient';

import config from '@/config/config';

const restClient = createRestClient(config.baseURL);

const getEmailAccountsAutocomplete = (
  params: Record<string, unknown>,
): Promise<AutocompleteOption[]> => restClient.get(`mailbox/accounts/autocomplete`, { params });

export default {
  getEmailAccountsAutocomplete,
};
