import { Attributes, createElement } from 'react';

import { CellProps, ColumnProps } from '@/shared/hooks/table/types';
import { Obj } from '~/common/types';

type Props = Obj &
  Omit<CellProps, 'column'> & {
    column: ColumnProps;
  } & Attributes;

const DefaultTableCell: React.FC<Props> = (props) => {
  const { column, value } = props;

  if (column.customCellComponent) {
    return createElement(column.customCellComponent, props);
  }

  return <div className="break-words">{value || '-'}</div>;
};

export default {
  DefaultTableCell,
};
