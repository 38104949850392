import { Chip, ChipDelete, Tooltip } from '@mui/joy';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CaseRelationPropTypes from '../../../../../shared/prop-types/CaseRelationPropTypes';
import RelationType from '../../../../../shared/enums/RelationType';

import { useGetCompany, useGetContact } from '../../../../../queries';
import API from '../../../../../shared/api/ApiService';
import { formatContactDisplayName } from '~/common/utils/utils';
import useCompanyForRelationByCentralCompany from '../../../../../shared/hooks/useCompanyForRelationByCentralCompany';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import {
  CompanyIcon,
  ContactIcon,
  DeleteIconAlt,
  TeamIcon,
  UnlinkIcon,
} from '@/shared/icons/Icons';
import ConfirmationModal from '@/shared/components/ConfirmationModal';
import SelectCRM from '@/shared/components/select/SelectCRM';

const propTypes = {
  caseId: PropTypes.string.isRequired,
  caseRelationToUpdate: CaseRelationPropTypes.isRequired,
  currentCaseRelations: PropTypes.arrayOf(CaseRelationPropTypes),
};

const TeamRelationCrmLink = ({ caseId, caseRelationToUpdate, currentCaseRelations = [] }) => {
  const { t } = useTranslation('common');

  const contactsToExclude = useMemo(
    () =>
      currentCaseRelations
        .map((rel) => {
          const { contactId } = rel;
          return contactId;
        })
        .filter((contactId) => contactId != null),
    [currentCaseRelations],
  );

  const companiesToExclude = useMemo(
    () =>
      currentCaseRelations
        .map((rel) => {
          const { companyId } = rel;
          return companyId;
        })
        .filter((companyId) => companyId != null),
    [currentCaseRelations],
  );

  const { data: linkedContact, isLoading: linkedContactIsLoading } = useGetContact(
    caseRelationToUpdate?.contactId,
  );
  const { company: linkedCompany, companyIsLoading: linkedCompanyIsLoading } = useGetCompany(
    caseRelationToUpdate?.companyId,
  );

  const { existingCrmCompany } = useCompanyForRelationByCentralCompany(
    caseRelationToUpdate,
    caseRelationToUpdate.relationType === RelationType.Team && !caseRelationToUpdate.companyId,
  );

  const crmIsAlreadyLinked = useMemo(() => {
    if (caseRelationToUpdate == null) {
      return false;
    }
    const { companyId, contactId, relationType } = caseRelationToUpdate;

    return (
      (relationType === RelationType.Company && companyId !== null) ||
      (relationType === RelationType.Contact && contactId !== null) ||
      (relationType === RelationType.Team && (companyId || existingCrmCompany))
    );
  }, [caseRelationToUpdate, existingCrmCompany]);

  const currentRelationDisplayLabel = useMemo(() => {
    if (caseRelationToUpdate == null) {
      return '';
    }
    const { companyId, contactId, relationType } = caseRelationToUpdate;

    switch (relationType) {
      case RelationType.Company: {
        if (!companyId || linkedCompanyIsLoading || !linkedCompany) {
          return null;
        }
        return linkedCompany.displayName;
      }
      case RelationType.Contact: {
        if (!contactId || linkedContactIsLoading || !linkedContact) {
          return null;
        }
        return formatContactDisplayName(linkedContact);
      }
      case RelationType.Team: {
        if (linkedCompany?.displayName) return linkedCompany.displayName;
        if (existingCrmCompany) return existingCrmCompany.companyName;
        return null;
      }
      default:
        return null;
    }
  }, [
    caseRelationToUpdate,
    existingCrmCompany,
    linkedCompany,
    linkedCompanyIsLoading,
    linkedContact,
    linkedContactIsLoading,
  ]);

  const currentRelationIcon = useMemo(() => {
    if (caseRelationToUpdate == null) {
      return null;
    }
    const { relationType } = caseRelationToUpdate;
    switch (relationType) {
      case RelationType.Company:
        return <CompanyIcon />;
      case RelationType.Team:
        return <TeamIcon />;
      case RelationType.Contact:
        return <ContactIcon />;
      default:
        return null;
    }
  }, [caseRelationToUpdate]);

  const disconnectConfirmationToggle = useToggle();

  const { sendDefaultError } = useNotification();

  const disconnectCurrentRelation = useCallback(async () => {
    const { id: relationId } = caseRelationToUpdate;
    const response = await API.deleteRemoveCaseRelationCrmLinkForTeam(caseId, relationId);

    if (response.serviceError != null || response.status !== 204) {
      sendDefaultError(response);
    }
  }, [caseId, sendDefaultError, caseRelationToUpdate]);

  const connectNewRelation = useCallback(
    async (companyId, contactId) => {
      const postBody = {
        companyId,
        contactId,
      };
      const { id: relationId } = caseRelationToUpdate;
      const response = await API.postAddCaseRelationCrmLinkForTeam(caseId, relationId, postBody);

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
      }
    },
    [caseId, sendDefaultError, caseRelationToUpdate],
  );

  const connectNewCompany = useCallback(
    async (selectedCompanies) => {
      if (selectedCompanies == null || selectedCompanies.length === 0) {
        return;
      }
      await connectNewRelation(selectedCompanies[0].id, null);
    },
    [connectNewRelation],
  );

  const connectNewContact = useCallback(
    async (selectedContacts) => {
      if (selectedContacts == null || selectedContacts.length === 0) {
        return;
      }
      await connectNewRelation(null, selectedContacts[0].id);
    },
    [connectNewRelation],
  );

  // const addTeamToNetwork = useCallback(async () => {
  //   const { teamCentralCompanyId } = caseRelationToUpdate;
  //   if (teamCentralCompanyId == null) {
  //     return;
  //   }

  //   const response = await API.postAddCompanyBasedOnCentralCompany(teamCentralCompanyId);

  //   if (response.serviceError != null || response.status !== 201) {
  //     sendDefaultError(response);
  //   }
  // }, [caseRelationToUpdate, sendDefaultError]);

  return (
    <>
      {crmIsAlreadyLinked && (
        <>
          <Chip
            variant="outlined"
            size="md"
            startDecorator={currentRelationIcon}
            endDecorator={
              caseRelationToUpdate.relationType === RelationType.Team ? null : (
                <Tooltip title={t('disconnect')}>
                  <ChipDelete onDelete={() => disconnectConfirmationToggle.show()} color="danger">
                    <UnlinkIcon />
                  </ChipDelete>
                </Tooltip>
              )
            }
          >
            {currentRelationDisplayLabel}
          </Chip>
          <ConfirmationModal
            icon={<DeleteIconAlt />}
            color="danger"
            onConfirm={disconnectCurrentRelation}
            toggle={disconnectConfirmationToggle}
          />
        </>
      )}
      {!crmIsAlreadyLinked && caseRelationToUpdate.relationType !== RelationType.Team && (
        <SelectCRM
          selectSingle
          id="crmSelector"
          name="crmSelector"
          label={t('clickHereToAddInvolvedParty')}
          showCompanies={caseRelationToUpdate.relationType === RelationType.Company}
          showContacts={caseRelationToUpdate.relationType === RelationType.Contact}
          companiesToExclude={companiesToExclude}
          contactsToExclude={contactsToExclude}
          onContactSelectChanged={connectNewContact}
          onCompanySelectChanged={connectNewCompany}
        />
      )}
      {/* NOTE: temporarily hidden */}
      {/* {!crmIsAlreadyLinked && */}
      {/*   !companyTeamsLoading && */}
      {/*   caseRelationToUpdate.relationType === RelationType.Team && ( */}
      {/*     <Button */}
      {/*       className="mb-0" */}
      {/*       variant="outline-secondary" */}
      {/*       type="button" */}
      {/*       size="lg" */}
      {/*       onClick={addTeamToNetwork} */}
      {/*     > */}
      {/*       <i> */}
      {/*         <AddIcon /> */}
      {/*       </i>{' '} */}
      {/*       {t('common:linkThisTeamToANewCompany')} */}
      {/*     </Button> */}
      {/*   )} */}
    </>
  );
};

TeamRelationCrmLink.propTypes = propTypes;

export default TeamRelationCrmLink;
