import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useGetCompanies } from '../../../../queries';

const propTypes = {
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
};

const CompanyFilter = ({ callback = () => {}, name = '', defaultValue = [], label = null }) => {
  const { t } = useTranslation('common');
  const { companies, companiesAreLoading } = useGetCompanies();

  return companiesAreLoading ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={companies}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(option) => option.displayName}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:companies')} />
      )}
    />
  );
};

CompanyFilter.propTypes = propTypes;

export default CompanyFilter;
