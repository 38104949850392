import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import signalRMessages from '../../../../redux/actions/signalRMessages';

import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../../CaseContext';
import DocumentRelation from '../../../../shared/enums/DocumentRelation';
import Documents from '../../../../shared/components/documents/Documents';
import { FileContext } from '../../../../FileContext';
import KpcCard from '../../../../shared/components/2.0/layout/KpcCard';

const CaseDocumentsCard = () => {
  const { socket } = useContext(AppContext);
  const { currentCase, canEditCase } = useContext(CaseContext);

  const { t } = useTranslation('common');
  const caseId = currentCase.id;

  const { setFiles } = useContext(FileContext);

  const onFileReadUpdate = useCallback(
    (eventCaseId, fileId, read) => {
      if (caseId === eventCaseId) {
        setFiles((prev) => prev.map((f) => (f.id === fileId ? { ...f, read } : f)));
      }
    },
    [caseId, setFiles],
  );

  useEffect(() => {
    socket.on(signalRMessages.CaseFileReadUpdated, onFileReadUpdate);

    return () => socket.off(signalRMessages.CaseFileReadUpdated);
  }, [onFileReadUpdate, socket]);

  return (
    <KpcCard id="caseDocuments" title={t('documents')}>
      <Documents
        canEdit={canEditCase}
        caseId={currentCase.id}
        documentRelation={DocumentRelation.Case}
        isBulkDeleteAllowed
      />
    </KpcCard>
  );
};

export default CaseDocumentsCard;
