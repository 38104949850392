import { appInsights } from './AppInsights';

const OidcLogger = {
  debug: (message: string, details: string, ...args: unknown[]) => {
    if (appInsights == null) {
      console.debug({ message: `${message} ${details}`, ...args });
      return;
    }
    appInsights.trackTrace({
      message: `${message} ${details}`,
      properties: args,
      severityLevel: 0,
    }); // 0 = Verbose
  },
  info: (message: string, details: string, ...args: unknown[]) => {
    if (appInsights == null) {
      console.info({ message: `${message} ${details}`, ...args });
      return;
    }

    appInsights.trackTrace({
      message: `${message} ${details}`,
      properties: args,
      severityLevel: 1,
    }); // 1 = Information
  },
  warn: (message: string, details: string, ...args: unknown[]) => {
    if (appInsights == null) {
      console.warn({ message: `${message} ${details}`, ...args });
      return;
    }
    appInsights.trackTrace({
      message: `${message} ${details}`,
      properties: args,
      severityLevel: 2,
    }); // 2 = Warning
  },
  error: (message: string, details: string, ...args: unknown[]) => {
    if (appInsights == null) {
      console.error({ message: `${message} ${details}`, ...args });
      return;
    }
    appInsights.trackException({ exception: new Error(`${message} ${details}`), ...args });
  },
};

export default OidcLogger;
