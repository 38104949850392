import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  createFilterOptions,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from '@mui/joy';
import { useContext, useState } from 'react';
import { lowerFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { CrmOptionType, Gender, Language } from '~/common/enums';
import { getAvatarInitials, isValidEmailAddress } from '@/shared/utils/helpers';
import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import { FormikValues } from './AddRepairCaseStepper';

import { AddIcon, CompanyIcon, ContactIcon, TeamIcon } from '@/shared/icons/Icons';
import AddressForm from './AddressForm';
import FormikIbanInput from '@/shared/formik/FormikIbanInput';
import FormikInternationalPhoneNumberInput from '@/shared/components/2.0/formik/FormikInternationalPhoneNumberInput';
import FormikVatInput from '@/shared/formik/FormikVatInput';
import HeaderStep from './HeaderStep';
import { PhoneNumberType } from '@/shared/components/phoneNumber/InternationalPhoneNumber';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

const filter = createFilterOptions<string>();

const InfoModal = () => {
  const { ui, setUi } = useContext(AppContext);
  const {
    setFieldTouched,
    setFieldValue,
    values: { relations },
  } = useFormikContext<FormikValues>();

  const { t } = useTranslation();

  const [currentId, setCurrentId] = useState<string>(
    ui.case.stepper.repairCase.involvedParties.id as string,
  );

  const entity = relations.find((relation) => relation.id === currentId);
  const entityIndex = relations.findIndex((relation) => relation.id === currentId);

  const isContact = entity?.type === CrmOptionType.Contact;
  const isCompany = entity?.type === CrmOptionType.Company;

  const languages = Object.values(Language).map((lan) => ({
    label: t(lowerFirst(lan)),
    value: lan,
  }));

  const genders = Object.values(Gender).map((gen) => ({
    label: t(lowerFirst(gen)),
    value: gen,
  }));

  return (
    <Modal
      open={ui.case.toggles.involvedPartyInfo}
      onClose={() => {
        setUi({
          type: actions.CASE_TOGGLE_INVOLVED_PARTY_INFO,
        });
      }}
    >
      <ModalDialog className="w-2/3">
        <ModalClose />

        <div className="flex items-center space-x-4">
          <Avatar>
            {isContact ? <ContactIcon /> : isCompany ? <CompanyIcon /> : <TeamIcon />}
          </Avatar>
          <HeaderStep
            title={t('repairCaseForm.involvedParties.modal.title')}
            description={t('repairCaseForm.involvedParties.modal.description')}
            tooltipMessage={t('repairCaseForm.involvedParties.modal.tooltip')}
          />
        </div>
        <Divider />

        <FormControl>
          <div className="flex space-x-4">
            <IconButton
              disabled={relations.length === 1 || relations[0].id === currentId}
              onClick={() => {
                setCurrentId(
                  relations[entityIndex === 0 ? relations.length - 1 : entityIndex - 1].id,
                );
              }}
            >
              <ArrowLeft />
            </IconButton>
            <Autocomplete
              className="grow"
              value={relations.find((relation) => relation.id === currentId)}
              options={relations}
              groupBy={(option) => option.type}
              slots={{ listbox: VirtualListBoxAdapter }}
              renderOption={(props, option) => [props, option] as React.ReactNode}
              renderGroup={(params) => params as unknown as React.ReactNode}
              onChange={(_e, newValue) => {
                setCurrentId(newValue?.id as string);
              }}
            />
            <IconButton
              disabled={relations.length === 1 || relations[relations.length - 1].id === currentId}
              onClick={() => {
                setCurrentId(
                  relations[entityIndex === relations.length - 1 ? 0 : entityIndex + 1].id,
                );
              }}
            >
              <ArrowRight />
            </IconButton>
          </div>
        </FormControl>

        <div className="flex space-x-8 p-4">
          <Avatar className="h-36 w-36 self-center">
            <Typography className="text-3xl">{getAvatarInitials(entity?.label)}</Typography>
          </Avatar>

          <div className="grid grow grid-cols-2 gap-4">
            {isContact && (
              <>
                <FormControl>
                  <FormLabel> {t('firstName')}</FormLabel>
                  <Input
                    value={entity?.firstName}
                    onChange={(e) => {
                      setFieldValue(`relations.${entityIndex}.firstName`, e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel> {t('lastName')}</FormLabel>
                  <Input
                    value={entity?.lastName}
                    onChange={(e) => {
                      setFieldValue(`relations.${entityIndex}.lastName`, e.target.value);
                    }}
                  />
                </FormControl>
              </>
            )}

            <FormControl>
              <FormLabel> {t('language')}</FormLabel>
              <Autocomplete
                value={languages.find((lan) => lan.value === entity?.language)}
                disableClearable
                options={languages}
                onChange={(_e, newValue) => {
                  setFieldValue(`relations.${entityIndex}.language`, newValue?.value as Language);
                }}
              />
            </FormControl>

            {isContact && (
              <FormControl>
                <FormLabel> {t('gender')}</FormLabel>
                <Autocomplete
                  value={genders.find((gen) => gen.value === entity?.gender)}
                  disableClearable
                  options={genders}
                  onChange={(_e, newValue) => {
                    setFieldValue(`relations.${entityIndex}.gender`, newValue.value);
                  }}
                />
              </FormControl>
            )}

            {isCompany && (
              <FormControl>
                <FormLabel required>{t('companyName')}</FormLabel>
                <Input
                  value={entity?.companyName}
                  onChange={(e) =>
                    setFieldValue(`relations.${entityIndex}.companyName`, e.target.value)
                  }
                />
              </FormControl>
            )}

            <FormikInternationalPhoneNumberInput
              type={PhoneNumberType.Landline}
              label={t('phoneNumber')}
              name={`relations.${entityIndex}.phoneNumber`}
              value={`${entity?.phoneNumber?.countryCode ?? ''}${
                entity?.phoneNumber?.number ?? ''
              }`}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <FormikInternationalPhoneNumberInput
              type={PhoneNumberType.Mobile}
              label={t('mobilePhoneNumber')}
              name={`relations.${entityIndex}.mobilePhoneNumber`}
              value={`${entity?.mobilePhoneNumber?.countryCode ?? ''}${
                entity?.mobilePhoneNumber?.number ?? ''
              }`}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        {isCompany && (
          <FormControl>
            <FormLabel>{t('vatNumber')}</FormLabel>
            <FormikVatInput
              name={`relations.${entityIndex}.vatNumber`}
              defaultValue={entity?.vatNumber}
            />
          </FormControl>
        )}

        <FormControl disabled>
          <FormLabel>{t('iban')}</FormLabel>
          <FormikIbanInput name={`relations.${entityIndex}.iban`} />
        </FormControl>

        <FormControl>
          <FormLabel> {t('emailAddresses')}</FormLabel>
          <Autocomplete
            placeholder={t('typeHere')}
            multiple
            freeSolo
            value={entity?.emailAddresses}
            options={entity?.emailAddresses || []}
            getOptionLabel={(option) => option}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = entity?.emailAddresses.includes(inputValue);
              const isValid = isValidEmailAddress(inputValue);
              if (inputValue !== '' && !isExisting && isValid) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            onChange={(_e, newValue) => {
              setFieldValue(`relations.${entityIndex}.emailAddresses`, newValue);
            }}
            renderOption={(props, option) => {
              const isExisting = entity?.emailAddresses.includes(option);
              return (
                <AutocompleteOption {...props}>
                  {!isExisting && (
                    <ListItemDecorator>
                      <AddIcon />
                    </ListItemDecorator>
                  )}
                  {option}
                </AutocompleteOption>
              );
            }}
          />
        </FormControl>

        <>
          {entity && isContact && (
            <AddressForm name={`relations.${entityIndex}.address`} address={entity.address} />
          )}
          {entity &&
            isCompany &&
            entity.companyAddresses.map(({ function: addressFunction, address }, index) => (
              <div key={index} className="flex flex-col space-y-4">
                <Typography startDecorator={<CompanyIcon />} level="title-sm" className="py-2">
                  {t(lowerFirst(addressFunction))}
                </Typography>
                <AddressForm
                  name={`relations.${entityIndex}.companyAddresses.${index}.address`}
                  address={address}
                />
              </div>
            ))}
        </>

        <FormControl>
          <FormLabel> {t('remark')}</FormLabel>
          <Textarea
            placeholder={t('typeHere')}
            value={entity?.remark}
            onChange={(e) => {
              setFieldValue(`relations.${entityIndex}.remark`, e.target.value);
            }}
          />
        </FormControl>
      </ModalDialog>
    </Modal>
  );
};
export default InfoModal;
