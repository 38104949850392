import classNames from 'classnames';

type Props = {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  id?: string;
  title?: string;
  subtitle?: string;
  className?: string;
};

const KpcCard: React.FC<Props> = ({ actions, children, id, title, subtitle, className }) => (
  <div id={id} className={classNames('rounded-2xl bg-white p-4', className)}>
    {(!!title || !!actions) && (
      <div className="flex items-center justify-between pb-4">
        {!!title && <h4>{title}</h4>}
        {actions}
      </div>
    )}
    {!!subtitle && <p className="pb-4 text-xs italic">{subtitle}</p>}
    {children}
  </div>
);

export default KpcCard;
