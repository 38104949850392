import { useContext, useMemo } from 'react';
import moment from 'moment';
import { Skeleton } from '@mui/material';

import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/nl';

import { CaseContext } from '../CaseContext';
import CaseDocumentsCard from './BodySections/CaseDocumentsCard';
import CaseEntityType from '../../../shared/enums/CaseEntityType';
import CaseGeneralMultiCard from './BodySections/CaseGeneralMultiCard';
import CaseRelationsCard from './BodySections/CaseRelationsCard';
import CaseStatusType from '../../../shared/enums/CaseStatusType';
import CaseType from '../../../shared/enums/CaseType';
import { FileContext } from '../../../FileContext';
import RepairTimelineCard from '../Repair/Timeline/RepairTimelineCard';
import TimelineCard from './BodySections/Timeline/TimelineCard';

const CaseContentBody = () => {
  const { currentCase, caseEntities } = useContext(CaseContext);
  const { files } = useContext(FileContext);

  const timelineEntities = useMemo(() => {
    const mappedFiles = files.map((file) => {
      const { uploadDate, ...rest } = file;
      return {
        ...rest,
        created: uploadDate.slice(0, -1), // removing timezone info for correct sorting
        id: file.id,
        isPinned: false,
        isSeen: true,
        type: CaseEntityType.File,
      };
    });
    return caseEntities
      .concat(mappedFiles)
      .sort(
        (a, b) =>
          new Date(b.emailDate?.slice(0, -1) ?? b.callDate ?? b.created) -
          new Date(a.emailDate?.slice(0, -1) ?? a.callDate ?? a.created),
      )
      .sort((a, b) => Number(b.isPinned) - Number(a.isPinned));
  }, [caseEntities, files]);

  return currentCase.id == null || moment == null ? (
    <>
      <Skeleton variant="rectangular" height={250} />
      <Skeleton variant="rectangular" height={250} />
    </>
  ) : (
    <div className="flex-col space-y-4">
      <CaseGeneralMultiCard />
      {currentCase.caseType === CaseType.Repair &&
        currentCase.status.statusType !== CaseStatusType.Pending && (
          <RepairTimelineCard
            intakeCalls={caseEntities.filter(
              (entity) => entity.type === CaseEntityType.PhoneCall && entity.isIntakeCall,
            )}
          />
        )}
      <CaseRelationsCard />
      <CaseDocumentsCard />
      <TimelineCard caseEntities={timelineEntities} />
    </div>
  );
};

export default CaseContentBody;
