import { useTranslation } from 'react-i18next';

import WrappedText from '../../../../../../shared/components/WrappedText';

import { CreatedByField, DescriptionField, InfoSection, TypeName } from '../ItemStyles/Item-Styles';
import NoteItemPropTypes from '../../../../../../shared/prop-types/NoteItemPropTypes';

const propTypes = {
  message: NoteItemPropTypes.isRequired,
};

const TextMessageItem = ({ message }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <TypeName>{t('common:sms')}</TypeName>
      <CreatedByField>{message.createdByUser}</CreatedByField>

      <br />

      <InfoSection>
        <DescriptionField>{t('common:message')}: </DescriptionField>
        <WrappedText>{message.note}</WrappedText>
      </InfoSection>

      <InfoSection>
        <DescriptionField>{t('common:to')}: </DescriptionField>
        <span>{message.callee}</span>
      </InfoSection>
    </>
  );
};

export default TextMessageItem;

TextMessageItem.propTypes = propTypes;
