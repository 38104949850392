import PropTypes from 'prop-types';

const PaymentPropTypes = PropTypes.shape({
  id: PropTypes.string,
  amount: PropTypes.number,
  date: PropTypes.string,
  paymentReference: PropTypes.string,
  myIban: PropTypes.string,
  otherIban: PropTypes.string,
  accountingSystemReference: PropTypes.string,
  paymentRequestInfos: PropTypes.arrayOf(
    PropTypes.shape({
      paymentRequestId: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
});

export default PaymentPropTypes;
