import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../shared/api/ApiService';
import actionTypes from '../../actions/actionTypes';

function* handleGetFsmaNumber() {
  const response = yield call(API.fetchGetFsmaNumberList);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_FSMANUMBER_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.GET_FSMANUMBER_SUCCESS,
    fsmaNumbers: response.data,
  });
}

function* handleGetSingleFsmaNumber(action) {
  const response = yield call(API.fetchGetFsmaNumberById, action.id);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_FSMANUMBER_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.ADD_OR_UPDATE_FSMANUMBER,
    fsmaNumber: response.data,
  });
}

function* watcherFsmaNumberSaga() {
  yield takeLatest(actionTypes.GET_FSMANUMBER_REQUESTED, handleGetFsmaNumber);
  yield takeLatest(actionTypes.GET_SINGLE_FSMANUMBER_REQUESTED, handleGetSingleFsmaNumber);
}

export default watcherFsmaNumberSaga;
