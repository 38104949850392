import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormGroup, Row } from 'react-bootstrap';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../../../../shared/api/ApiService';
import CasePropTypes from '../../../../../../shared/prop-types/CasePropTypes';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikTextField from '../../../../../../shared/formik/FormikTextField';
import FormikYesNo from '../../../../../../shared/formik/FormikYesNo';
import useNotification from '../../../../../../shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import {
  CancelIcon,
  DeleteIconAlt,
  SaveIcon,
  UndoIcon,
} from '../../../../../../shared/icons/Icons';
import ConfirmationModal from '../../../../../../shared/components/ConfirmationModal';

const OrderMaterialsEstimatedDeliveryDate = ({
  currentCase,
  nextStep,
  isCaseOwner,
  isContractor,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubFieldsVisible, setIsSubFieldsVisible] = useState(currentCase.mustOrderMaterials);
  const [isUndoVisible, setIsUndoVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(currentCase.mustOrderMaterials);
  const [isRegisterVisible, setIsRegisterVisible] = useState(!currentCase.mustOrderMaterials);
  const [isFromCancel, setIsFromCancel] = useState(false);
  const [isMustOrderMaterialsChanged, setIsMustOrderMaterialsChanged] = useState(false);
  const [isMaterialsOrderDescriptionChanged, setIsMaterialsOrderDescriptionChanged] =
    useState(false);
  const [isMaterialsOrderDeliveryDateChanged, setIsMaterialsOrderDeliveryDateChanged] =
    useState(false);

  const formikRef = useRef();

  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const deleteConfirmationToggle = useToggle();

  useEffect(() => {
    setIsUndoVisible(
      isMustOrderMaterialsChanged ||
        isMaterialsOrderDeliveryDateChanged ||
        isMaterialsOrderDescriptionChanged,
    );
    setIsEditVisible(
      currentCase.mustOrderMaterials &&
        (isMaterialsOrderDeliveryDateChanged || isMaterialsOrderDescriptionChanged),
    );
    setIsCancelVisible(isSubFieldsVisible && currentCase.mustOrderMaterials);
    setIsRegisterVisible(!currentCase.mustOrderMaterials);
  }, [
    isMustOrderMaterialsChanged,
    isMaterialsOrderDeliveryDateChanged,
    isMaterialsOrderDescriptionChanged,
    isSubFieldsVisible,
    isEditVisible,
    currentCase.mustOrderMaterials,
  ]);

  const submit = async (formData) => {
    const payload = { caseId: currentCase.id, ...formData };
    setIsSubmitting(true);
    const response = await API.putUpdateMaterialsOrderDeliveryDateInfo(payload);
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', {
        type: t('orderMaterialsEstimatedDeliveryTime'),
      }),
      variant: 'success',
    });
  };

  const cancel = () => {
    formikRef.current.setFieldValue('mustOrderMaterials', false);
    formikRef.current.setFieldValue('materialsOrderDescription', null);
    formikRef.current.setFieldValue('materialsOrderDeliveryDate', null);
    setIsMustOrderMaterialsChanged(false);
    setIsMaterialsOrderDescriptionChanged(false);
    setIsMaterialsOrderDeliveryDateChanged(false);
    setIsSubFieldsVisible(false);
    setIsFromCancel(true);
    formikRef.current.handleSubmit();
  };

  const onCancel = () => {
    deleteConfirmationToggle.show();
  };

  const onUndo = () => {
    formikRef.current.setFieldValue('mustOrderMaterials', currentCase.mustOrderMaterials);
    formikRef.current.setFieldValue(
      'materialsOrderDescription',
      currentCase.materialsOrderDescription,
    );
    formikRef.current.setFieldValue(
      'materialsOrderDeliveryDate',
      currentCase.materialsOrderDeliveryDate,
    );
    setIsMustOrderMaterialsChanged(false);
    setIsMaterialsOrderDescriptionChanged(false);
    setIsMaterialsOrderDeliveryDateChanged(false);
    if (!currentCase.mustOrderMaterials) {
      setIsSubFieldsVisible(false);
    }
  };

  const onSubmit = async (formData) => {
    if (isSubmitting) {
      return;
    }

    submit(formData);

    if (!isFromCancel) {
      nextStep();
    }

    setIsFromCancel(false);
    setIsMustOrderMaterialsChanged(false);
    setIsMaterialsOrderDescriptionChanged(false);
    setIsMaterialsOrderDeliveryDateChanged(false);
  };

  const callClientViaTelephone = useMemo(() => {
    const client = currentCase.relations.find((r) => r.isClient);
    const clientWithEmailOrSms =
      client && (client.emailAddresses.length > 0 || client.mobilePhoneNumber.number);
    return !clientWithEmailOrSms;
  }, [currentCase.relations]);

  const isCaseOwnerOrContractor = isCaseOwner || isContractor;
  const orderMaterialsStepStarted =
    currentCase.mustOrderMaterials !== null && currentCase.mustOrderMaterials !== undefined;

  return (
    <div className="mb-1 ms-3 mt-1">
      {!isCaseOwnerOrContractor && !orderMaterialsStepStarted && (
        <p className="color-keypoint">{t('orderMaterialsNotStarted')}</p>
      )}
      {!isCaseOwnerOrContractor && currentCase.mustOrderMaterials === false && (
        <p className="color-keypoint">{t('orderNoMaterials')}</p>
      )}
      {(isCaseOwnerOrContractor ||
        (orderMaterialsStepStarted && currentCase.mustOrderMaterials)) && (
        <Row>
          <ConfirmationModal
            icon={<DeleteIconAlt />}
            color="danger"
            onConfirm={cancel}
            toggle={deleteConfirmationToggle}
          >
            {t('areYouSure')}
          </ConfirmationModal>
          <Formik
            innerRef={formikRef}
            onSubmit={onSubmit}
            initialValues={{
              materialsOrderDeliveryDate: currentCase?.materialsOrderDeliveryDate,
              materialsOrderDescription: currentCase?.materialsOrderDescription ?? '',
              mustOrderMaterials: currentCase?.mustOrderMaterials ?? false,
            }}
            validationSchema={yup.object().shape(
              {
                materialsOrderDeliveryDate: yup
                  .string()
                  .nullable()
                  .when(['mustOrderMaterials', 'materialsOrderDescription'], {
                    is: (mustOrderMaterials, materialsOrderDescription) =>
                      mustOrderMaterials && !materialsOrderDescription,
                    then: yup.string().nullable().required(t('errors:fieldIsRequired')),
                  }),
                materialsOrderDescription: yup
                  .string()
                  .nullable()
                  .when(['mustOrderMaterials', 'materialsOrderDeliveryDate'], {
                    is: (mustOrderMaterials, materialsOrderDeliveryDate) =>
                      mustOrderMaterials && !materialsOrderDeliveryDate,
                    then: yup.string().nullable().required(t('errors:fieldIsRequired')),
                  }),
                mustOrderMaterials: yup.bool().nullable().required(t('errors:fieldIsRequired')),
              },
              [['materialsOrderDeliveryDate', 'materialsOrderDescription']],
            )}
          >
            <Form>
              <FormGroup className="mb-3">
                <FormikYesNo
                  name="mustOrderMaterials"
                  yesText={t('orderAnyMaterials')}
                  noText={t('orderNoMaterials')}
                  isDefaultChecked={false}
                  disabled={isCaseOwnerOrContractor ? isSubFieldsVisible : true}
                  onChangeCallback={(checked) => {
                    setIsMustOrderMaterialsChanged(checked);
                    setIsSubFieldsVisible(checked);
                  }}
                />
              </FormGroup>
              {isSubFieldsVisible && (
                <FormGroup className="mb-3">
                  <FormikTextField
                    id="materialsOrderDescription"
                    name="materialsOrderDescription"
                    label={t('orderMaterialsDescription')}
                    fullWidth
                    disabled={!isCaseOwnerOrContractor}
                    required
                    onChangeCallback={(value) => {
                      setIsMaterialsOrderDescriptionChanged(
                        value !== currentCase.materialsOrderDescription,
                      );
                    }}
                  />
                </FormGroup>
              )}
              {isSubFieldsVisible && (
                <FormGroup className="mb-3">
                  <FormikDatePicker
                    name="materialsOrderDeliveryDate"
                    label={t('orderMaterialsDeliveryDate')}
                    disabled={!isCaseOwnerOrContractor}
                    callback={(value) =>
                      setIsMaterialsOrderDeliveryDateChanged(
                        value !== currentCase.materialsOrderDeliveryDate,
                      )
                    }
                    options={{
                      disablePast: true,
                      format: 'YYYY-MM-DD',
                    }}
                    required
                  />
                </FormGroup>
              )}
              {isCaseOwnerOrContractor && (
                <>
                  <FormGroup>
                    {!callClientViaTelephone && (
                      <div color="keypoint">{t('orderMaterialsClientInformed')}</div>
                    )}
                    {callClientViaTelephone && (
                      <div color="keypoint">{t('orderMaterialsCallClient')}</div>
                    )}
                  </FormGroup>
                  <div className="flex justify-end space-x-4">
                    {isUndoVisible && (
                      <Button
                        startDecorator={<UndoIcon />}
                        color="neutral"
                        variant="outlined"
                        size="lg"
                        onClick={onUndo}
                      >
                        {t('undo')}
                      </Button>
                    )}
                    {isCancelVisible && (
                      <Button
                        startDecorator={<CancelIcon />}
                        color="neutral"
                        variant="outlined"
                        size="lg"
                        onClick={onCancel}
                      >
                        {t('cancel')}
                      </Button>
                    )}
                    {isEditVisible && (
                      <Button startDecorator={<SaveIcon />} type="submit">
                        {t('edit')}
                      </Button>
                    )}
                    {isRegisterVisible && (
                      <Button startDecorator={<SaveIcon />} type="submit">
                        {t('register')}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Form>
          </Formik>
        </Row>
      )}
    </div>
  );
};

OrderMaterialsEstimatedDeliveryDate.propTypes = {
  currentCase: CasePropTypes.isRequired,
  isCaseOwner: PropTypes.bool.isRequired,
  isContractor: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default OrderMaterialsEstimatedDeliveryDate;
