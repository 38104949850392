import i18next from 'i18next';
import { useQuery } from '@tanstack/react-query';

import caseClassificationApi from '../../shared/api/caseClassification/caseClassification';
import { formatCaseClassificationForAutoComplete } from '../../shared/utils/helpers';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetCaseClassification = (options = { params: {} }) => {
  const { language } = i18next;
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: options.enabled ?? true,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await caseClassificationApi.getCaseClassification({ params: options.params });
      const formatted = formatCaseClassificationForAutoComplete(result, language);

      return { formatted, result };
    },
    queryKey: [queryIds.cases.GET_CASE_CLASSIFICATIONS, options.params?.caseType],
  });

  return {
    caseClassificationIsLoading: isLoading,
    caseClassifications: data?.result ?? [],
    caseClassificationsAutocomplete: data?.formatted || [],
  };
};

export default useGetCaseClassification;
