import { FormControl, FormHelperText, FormLabel, Input, Typography } from '@mui/joy';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import useDebounce from '../hooks/UseDebounce';
import { useGetPostalCodeCity } from '@/queries';

import PostalCodeCitySelector, {
  CitySuggestion,
} from '../components/addresses/PostalCodeCitySelector';
import FormikCountrySelector from '../components/2.0/formik/FormikCountrySelector';

const DEBOUNCE_TIMEOUT = 500;

type AddressFormikValues = {
  addressLine: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  postalCode: Nullable<string>;
};

type Props = {
  disabled?: boolean;
  required?: boolean;
};

const AddressForm: React.FC<Props> = ({ disabled = false, required = false }) => {
  const { t } = useTranslation(['common', 'errors']);
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<AddressFormikValues>();

  const debouncedCountry = useDebounce(values.country, DEBOUNCE_TIMEOUT);
  const debouncedCity = useDebounce(values.city, DEBOUNCE_TIMEOUT);
  const debouncedPostalCode = useDebounce(values.postalCode, DEBOUNCE_TIMEOUT);

  const addressFilled = !!debouncedCity && !!debouncedPostalCode && !!debouncedCountry;

  const { postalCodeCity } = useGetPostalCodeCity(
    {
      countryCode: debouncedCountry,
      term: debouncedPostalCode,
    },
    {
      enabled: addressFilled && debouncedCountry === 'BE',
    },
  );

  const postalCodeMismatch = useMemo(
    () =>
      addressFilled &&
      !!postalCodeCity &&
      !!postalCodeCity.length &&
      !postalCodeCity.some((city) => city.city === debouncedCity),
    [addressFilled, postalCodeCity, debouncedCity],
  );

  const handleSuggestionSelection = (suggestion: Nullable<CitySuggestion>) => {
    if (!suggestion) {
      return;
    }

    const { city, postalCode } = suggestion;

    if (city !== values.city) {
      setFieldValue('city', city);
    }
    if (postalCode !== values.postalCode) {
      setFieldValue('postalCode', postalCode);
    }
  };

  const hasError = (key: keyof typeof values) => !!touched[key] && !!errors[key];

  return (
    <>
      <FormikCountrySelector
        required={required}
        label={t('country.label')}
        name="country"
        className="mb-4"
        disabled={disabled}
      />
      <FormControl error={hasError('addressLine')} className="mb-4">
        <FormLabel required={required}>{t('streetAndNumber')}</FormLabel>
        <Input
          value={values.addressLine || ''}
          onChange={(e) => {
            setFieldTouched('addressLine');
            setFieldValue('addressLine', e.target.value);
          }}
          disabled={disabled}
          onBlur={() => setFieldTouched('addressLine')}
        />
        {hasError('addressLine') && <FormHelperText>{errors.addressLine}</FormHelperText>}
      </FormControl>
      <div className="flex flex-row gap-4">
        <FormControl disabled={disabled} error={hasError('postalCode')} className="mb-4 w-1/3">
          <FormLabel required={required}>{t('postalCode')}</FormLabel>
          <PostalCodeCitySelector
            disabled={disabled}
            value={values.postalCode}
            onInputChange={(value) => {
              if (value !== values.postalCode) {
                setFieldTouched('postalCode');
                setFieldValue('postalCode', value);
              }
            }}
            onBlur={() => setFieldTouched('postalCode')}
            country={values.country}
            city={values.city}
            type="postalCode"
            onChange={handleSuggestionSelection}
          />
          {hasError('postalCode') && (
            <FormHelperText className="px-1 text-xs">{errors.postalCode}</FormHelperText>
          )}
        </FormControl>
        <FormControl disabled={disabled} error={hasError('city')} className="mb-4 w-2/3">
          <FormLabel required={required}>{t('city')}</FormLabel>
          <PostalCodeCitySelector
            disabled={disabled}
            required={required}
            value={values.city}
            onInputChange={(value) => {
              if (value !== values.city) {
                setFieldTouched('city');
                setFieldValue('city', value);
              }
            }}
            onBlur={() => setFieldTouched('city')}
            country={values.country}
            postalCode={values.postalCode}
            type="city"
            onChange={handleSuggestionSelection}
          />
          {hasError('city') && (
            <FormHelperText className="px-1 text-xs">{errors.city}</FormHelperText>
          )}
        </FormControl>
      </div>
      {postalCodeMismatch && (
        <Typography color="warning" className="px-1 text-xs">
          {t('errors:postalCodeAndCityMismatch')}
        </Typography>
      )}
    </>
  );
};

export default AddressForm;
