import { Badge, Box, Dropdown, IconButton, Menu, MenuButton, MenuItem, Tooltip } from '@mui/joy';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect } from 'react';
import { AppContext } from '@/shared/context/context';
import { layout } from '@/shared/utils/constants';
import ROUTES from '../../App/Router/Routes';
import signalRMessages from '@/redux/actions/signalRMessages';
import { trpc } from '@/config/trpc';
import useDayjs from '@/shared/hooks/useDayjs';
import { useTaskActions } from '@/mutations';
import useToggle from '@/shared/hooks/UseToggle';
import useViewport from '@/shared/hooks/useViewport';

import { InlineSpinner, SnoozeIcon, TaskIcon, WarningIcon } from '@/shared/icons/Icons';
import PriorityIcon from '@/shared/icons/PriorityIcon';

const TopBarTasks = () => {
  const { socket } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contentHeight } = useViewport(500);

  const { formatDate, isFutureDate } = useDayjs();

  const fetchTasksToggle = useToggle();

  const { data: tasksDueAmount, refetch: refetchtasksDueAmount } =
    trpc.task.taskDueAmount.useQuery();

  const { data: tasks, isLoading: tasksIsLoading } = trpc.task.topDueTasks.useQuery(undefined, {
    enabled: fetchTasksToggle.value,
  });

  const preventPropagation = (e) => {
    if (['a', 'button', 'path'].includes(e.target.nodeName.toLowerCase())) {
      return;
    }
    e.stopPropagation();
  };

  const { finishTask, snoozeTask } = useTaskActions();

  useEffect(() => {
    socket?.on(signalRMessages.TaskCreated, () => refetchtasksDueAmount());
    socket?.on(signalRMessages.TaskUpdated, () => refetchtasksDueAmount());
    socket?.on(signalRMessages.TaskFinished, () => refetchtasksDueAmount());
    socket?.on(signalRMessages.TaskSnoozed, () => refetchtasksDueAmount());

    return () => {
      socket?.off(signalRMessages.TaskCreated);
      socket?.off(signalRMessages.TaskUpdated);
      socket?.off(signalRMessages.TaskFinished);
      socket?.off(signalRMessages.TaskSnoozed);
    };
  }, [socket, refetchtasksDueAmount]);

  return (
    <Dropdown>
      <Badge badgeContent={tasksDueAmount} badgeInset={2}>
        <MenuButton
          className="rounded-full"
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain' } }}
          onClick={fetchTasksToggle.show}
        >
          <TaskIcon />
        </MenuButton>
      </Badge>

      <Menu
        className="w-80 overflow-y-auto"
        style={{ maxHeight: contentHeight }}
        placement="bottom-end"
        onClose={fetchTasksToggle.hide}
      >
        {tasksIsLoading && <InlineSpinner />}
        {tasks?.map((task) => (
          <MenuItem key={task.id} className="flex py-2 text-xs">
            <Box className="grow" onClick={preventPropagation}>
              <p>
                <PriorityIcon priority={task.priority} />
                {task.linkDescription == null || !task.entityType ? (
                  task.description
                ) : (
                  <Link to={`/${task.entityType.toLowerCase()}/${task.entityId}`}>
                    {task.linkDescription}
                  </Link>
                )}
              </p>
              <p>
                {!isFutureDate(task.dueDate) && (
                  <Tooltip
                    title={t('common:dueDateHasBeenPassed')}
                    sx={{ zIndex: layout.layers.TOOLTIP }}
                  >
                    <i className="text-orange-500">
                      <WarningIcon /> {formatDate(task.dueDate)}
                    </i>
                  </Tooltip>
                )}
              </p>
              <p className="line-clamp-5">
                <span className="font-semibold">{task.teamTaskType ?? task.systemTaskType}: </span>
                {task.description}
              </p>
            </Box>
            <Box className="flex items-center" onClick={preventPropagation}>
              <IconButton
                variant="plain"
                onClick={(e) => {
                  e.stopPropagation();
                  snoozeTask(task.id);
                }}
              >
                <SnoozeIcon />
              </IconButton>
              <IconButton
                variant="plain"
                onClick={(e) => {
                  e.stopPropagation();
                  finishTask(task.id);
                }}
              >
                <TaskIcon />
              </IconButton>
            </Box>
          </MenuItem>
        ))}

        <MenuItem
          onClick={() => {
            navigate(ROUTES.TASKS_OVERVIEW);
          }}
          className="flex justify-center pt-2 text-xs uppercase text-red-500"
        >
          {t('showAll')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default TopBarTasks;
