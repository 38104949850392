import { httpBatchLink, httpLink, loggerLink, splitLink } from '@trpc/client';
import { Log, WebStorageStateStore } from 'oidc-client-ts';
import React, { useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { HistoryRouter } from 'redux-first-history/rr6';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { registerLicense } from '@syncfusion/ej2-base';
import { SnackbarProvider } from 'notistack';

import { history, sagaMiddleware, store } from './redux';
import { i18nConfig, i18nInitFormatters } from './translations';
import { localStorageKeys, reactQueryClient } from './shared/utils/constants';
import AuthWrapper from './containers/App/Wrappers/AuthWrapper';
import config from './config/config';
import { getAccessToken } from './shared/utils/authUtils';
import { languageMap } from '~/common/constants';
import OidcLogger from './containers/App/OidcLogger ';
import rootSaga from './redux/sagas/rootSaga';
import { trpc } from './config/trpc';

import './tailwind.css';
import './syncfusion.css';

registerLicense(config.syncFusionKey);

sagaMiddleware.run(rootSaga);

i18n.use(LanguageDetector).init(i18nConfig);
i18nInitFormatters(i18n);

Log.setLogger(OidcLogger);
Log.setLevel(Log.ERROR);

const getBaseUri = () => {
  const getUrl = window.location;
  return `${getUrl.protocol}//${getUrl.host}`;
};

const oidcConfig = {
  authority: config.authority,
  automaticSilentRenew: true,
  client_id: config.cliendId,
  loadUserInfo: true,
  monitorSession: true,
  onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
  redirect_uri: getBaseUri(),
  response_type: 'code',
  scope: 'api openid roles profile',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const Main = () => {
  const [queryClient] = useState(reactQueryClient);
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition(op) {
            return Boolean(op.context.skipBatch);
          },
          false: httpBatchLink({
            async headers() {
              return {
                access_token: getAccessToken(),
                language: languageMap[i18n.language as keyof typeof languageMap] || 'English',
                team: localStorage.getItem(localStorageKeys.teamId) || undefined,
              };
            },
            // Limit max url length to prevent errors like 414 Request-URI Too Large or 413 Payload Too Large
            maxURLLength: 5000,
            url: config.tprcURL,
          }),
          true: httpLink({
            async headers() {
              return {
                access_token: getAccessToken(),
                language: languageMap[i18n.language as keyof typeof languageMap] || 'English',
                team: localStorage.getItem(localStorageKeys.teamId) || undefined,
              };
            },
            url: config.tprcURL,
          }),
        }),
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
      ],
    }),
  );

  return (
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <AuthProvider {...oidcConfig}>
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider variant="success">
                  <HistoryRouter history={history}>
                    <AuthWrapper />
                  </HistoryRouter>
                </SnackbarProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </trpc.Provider>
          </AuthProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>
  );
};

const root = document.getElementById('root') as HTMLElement;
ReactDOM.createRoot(root).render(<Main />);
