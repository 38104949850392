import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryIds } from '../../shared/utils/constants';
import { taskApi } from '../../shared/api';
import useNotification from '../../shared/hooks/UseNotification';

import { decapitalize, makeId } from '../../shared/utils/helpers';

const useGetTaskTypes = () => {
  const { sendDefaultError } = useNotification();
  const { t } = useTranslation('common');

  const { data, isLoading } = useQuery({
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await taskApi.getTaskTypes();
      const formattedTaskTypes = result.map((tt) => ({
        dueDays: tt.dueDays,
        isSystemDefault: !tt.id,
        label: tt.systemTaskType ? t(`common:${decapitalize(tt.description)}`) : tt.description,
        systemTaskType: tt.systemTaskType,
        value: tt.id ?? tt.systemTaskType,
      }));

      const resultWithId = result.map((tt) =>
        tt.id ? tt : { ...tt, id: makeId(5), isSystemTaskType: true },
      );

      return {
        formattedTaskTypes,
        result,
        resultWithId,
      };
    },
    queryKey: [queryIds.tasks.GET_TASK_TYPES],
  });

  return {
    taskTypeAutoComplete: data?.formattedTaskTypes ?? [],
    taskTypes: data?.result || [],
    taskTypesIsLoading: isLoading,
    taskTypesWithId: data?.resultWithId || [],
  };
};

export default useGetTaskTypes;
