import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import API from '../../../../../shared/api/ApiService';
import CaseType from '../../../../../shared/enums/CaseType';
import { getCurrentTranslation } from '../../../../../shared/utils/helpers';
import { useGetEnums } from '../../../../../queries';

import FormikAutocomplete from '../../../../../shared/formik/FormikAutocomplete';
import FormikEnumSelector from '../../../../../shared/formik/FormikEnumSelector';
import FormikTeamUserSelector from '../../../../../shared/formik/FormikTeamUserSelector';
import FormikTextField from '../../../../../shared/formik/FormikTextField';
import FormikYesNo from '../../../../../shared/formik/FormikYesNo';
import Row from '../../../../../shared/components/2.0/layout/Row';

const CaseInfoStep = () => {
  const { t } = useTranslation('common');
  const { language } = i18n;
  const [caseClassifications, setCaseClassifications] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  const { automaticReference } = values;
  const { reference } = values;

  const {
    enums: { priorities },
  } = useGetEnums();

  useEffect(() => {
    const fetchTeamCaseClassifications = async () => {
      const response = await API.fetchGetTeamCaseClassifications(CaseType.InsuranceClaim);

      if (response.serviceError != null || response.status !== 200) {
        setCaseClassifications([]);
        return;
      }

      setCaseClassifications(
        response.data.sort((a, b) => {
          const translationA = getCurrentTranslation(a.translations, language) ?? '';
          const translationB = getCurrentTranslation(b.translations, language) ?? '';
          return translationA.localeCompare(translationB);
        }),
      );
    };
    fetchTeamCaseClassifications();
  }, [language]);

  useEffect(() => {
    setFieldValue('reference', '', true);
  }, [automaticReference, setFieldValue]);

  return (
    <>
      <div>
        <h3 style={{ fontWeight: 'bold' }}>{t('common:caseInfo')}</h3>
        <p style={{ marginBottom: '20px', marginTop: '0px' }}>
          {t('common:enterAReferenceForYourCase')}
        </p>
        <div>
          <FormikYesNo
            name="automaticReference"
            yesText={t('common:automaticNumber')}
            noText={t('common:manualReference')}
            infoText={automaticReference ? t('common:automaticReferenceWillBeAssigned') : null}
          />
        </div>
        {!automaticReference && (
          <div style={{ marginTop: '15px' }}>
            <FormikTextField
              name="reference"
              label={t('common:reference')}
              variant="standard"
              defaultValue={reference}
              autoFocus
              required
            />
          </div>
        )}
      </div>
      <div className="mt-3">
        <Row>
          <div className="flex-grow basis-1">
            <FormikTeamUserSelector name="admins" label={t('common:administrators')} required />
          </div>
          <div className="flex-grow basis-1">
            <FormikEnumSelector
              id="priority"
              name="priority"
              label={t('common:priority')}
              enumValues={priorities}
              required
            />
          </div>
        </Row>
      </div>
      <div className="mt-3">
        <div>
          <FormikAutocomplete
            id="classificationId"
            name="classificationId"
            label={t('common:classification')}
            options={caseClassifications}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(opt) => {
              if (opt === null) return '';
              const currentCaseClassification = caseClassifications.find(
                (c) => c.id === opt.id || c.id === opt,
              );
              const currentTranslation = getCurrentTranslation(
                currentCaseClassification?.translations,
                language,
              );
              return currentTranslation ?? '';
            }}
            getValue={(val) => val?.id ?? val}
          />
        </div>
      </div>
    </>
  );
};

export default CaseInfoStep;
