import TagType from '../../shared/enums/TagType';
import actionTypes from '../actions/actionTypes';

const initialState = {
  buildingTags: [],
  companyTags: [],
  contactTags: [],
  loading: false,
  error: false,
};

const tagsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_TAGS_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.REMOVE_TAG: {
      const { tagType } = action;
      switch (tagType) {
        case TagType.Building:
          return {
            ...state,
            buildingTags: state.buildingTags.filter((tag) => tag.id !== action.id),
          };
        case TagType.Company:
          return {
            ...state,
            companyTags: state.companyTags.filter((tag) => tag.id !== action.id),
          };
        case TagType.Contact:
          return {
            ...state,
            contactTags: state.contactTags.filter((tag) => tag.id !== action.id),
          };
        default:
          return state;
      }
    }
    case actionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        buildingTags: action.buildingTags,
        companyTags: action.companyTags,
        contactTags: action.contactTags,
      };
    case actionTypes.GET_TAGS_FAILED:
      return { ...state, loading: false, error: true };
    case actionTypes.ADD_OR_UPDATE_TAG: {
      const { tag, tagType } = action;
      switch (tagType) {
        case TagType.Building: {
          if (state.buildingTags.some((bt) => bt.id === tag.id)) {
            // Update
            return {
              ...state,
              buildingTags: state.buildingTags.map((obj) => {
                if (obj.id === tag.id) {
                  return { ...tag };
                }
                return obj;
              }),
            };
          }
          // Add
          return { ...state, buildingTags: [...state.buildingTags, tag] };
        }
        case TagType.Company: {
          if (state.companyTags.some((bt) => bt.id === tag.id)) {
            // Update
            return {
              ...state,
              companyTags: state.companyTags.map((obj) => {
                if (obj.id === tag.id) {
                  return { ...tag };
                }
                return obj;
              }),
            };
          }
          // Add
          return { ...state, companyTags: [...state.companyTags, tag] };
        }
        case TagType.Contact: {
          if (state.contactTags.some((bt) => bt.id === tag.id)) {
            // Update
            return {
              ...state,
              contactTags: state.contactTags.map((obj) => {
                if (obj.id === tag.id) {
                  return { ...tag };
                }
                return obj;
              }),
            };
          }
          // Add
          return { ...state, contactTags: [...state.contactTags, tag] };
        }
        default:
          break;
      }
      return state;
    }
    default:
      return state;
  }
};

export default tagsReducer;
