import { IconButton, Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useToggle from '../hooks/UseToggle';

import { DeleteIconAlt, UnlinkIcon } from '../icons/Icons';
import ConfirmationModal from './ConfirmationModal';

const DeleteAction = (props) => {
  const { onDelete, dropdown, tooltip, icon, bodyText, dropdownText, disabled, isUnlink } = props;

  const { t } = useTranslation();

  const confirmationToggle = useToggle();

  return (
    <>
      {dropdown ? (
        <MenuItem
          disabled={disabled}
          onClick={() => {
            confirmationToggle.show();
          }}
        >
          <span>{dropdownText ?? t('remove')}</span>
        </MenuItem>
      ) : (
        <Tooltip title={tooltip ?? (isUnlink ? t('unlink') : t('remove'))}>
          <IconButton
            variant="plain"
            disabled={disabled}
            color="danger"
            onClick={() => confirmationToggle.show()}
          >
            <FontAwesomeIcon icon={['fas', icon ?? (isUnlink ? 'link-slash' : 'trash-alt')]} />
          </IconButton>
        </Tooltip>
      )}

      <ConfirmationModal
        onConfirm={() => onDelete()}
        icon={isUnlink ? <UnlinkIcon /> : <DeleteIconAlt />}
        color="danger"
        toggle={confirmationToggle}
      >
        {bodyText}
      </ConfirmationModal>
    </>
  );
};

export default DeleteAction;

DeleteAction.propTypes = {
  bodyText: PropTypes.string,
  disabled: PropTypes.bool,
  dropdown: PropTypes.bool,
  dropdownText: PropTypes.string,
  icon: PropTypes.string,
  isUnlink: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

DeleteAction.defaultProps = {
  bodyText: null,
  disabled: false,
  dropdown: false,
  dropdownText: null,
  icon: null,
  isUnlink: false,
  tooltip: null,
};
