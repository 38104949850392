import { Chip, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isValidEmailAddress } from '../../utils/helpers';

const EmailAddressInput = ({
  onChange,
  initialEmailAddresses,
  values,
  disabled,
  acceptSingleEmail,
  required,
}) => {
  const { t } = useTranslation('common');
  const emailRef = useRef();

  const [emailAddresses, setEmailAddresses] = useState([...initialEmailAddresses]);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    if (emailAddresses === values || (values == null && emailAddresses.length === 0)) {
      return;
    }
    setEmailAddresses(values ?? []);
  }, [emailAddresses, values]);

  const handleDelete = (email) => {
    const copy = emailAddresses;
    const index = copy.indexOf(email);
    if (index < 0) {
      return;
    }
    copy.splice(index, 1);
    setEmailAddresses([...copy]);
    onChange(copy);
  };

  const checkEmails = useCallback(
    (e) => {
      let { value } = emailRef.current;
      value = value.trim();

      if (acceptSingleEmail) {
        setInvalidEmail(false);

        if (isValidEmailAddress(value)) {
          onChange(value);
        } else {
          setInvalidEmail(true);
          onChange(null);
        }
      } else {
        if (e.which !== 9 && e.which !== 13 && e.which !== 32 && e.type !== 'blur') {
          return;
        }

        e.preventDefault();
        e.stopPropagation();
        if (!isValidEmailAddress(value) || emailAddresses.indexOf(value) >= 0) {
          setInvalidEmail(true);
        } else {
          setInvalidEmail(false);
          const newData = [...emailAddresses, value];
          setEmailAddresses(newData);
          onChange(newData);
          emailRef.current.value = '';
        }
      }
    },
    [acceptSingleEmail, emailAddresses, onChange],
  );

  useEffect(() => {
    if (emailRef && emailRef.current) {
      const currentEmailRef = emailRef.current;

      if (acceptSingleEmail) {
        currentEmailRef.addEventListener('keyup', checkEmails, false);

        return () => {
          currentEmailRef.removeEventListener('keyup', checkEmails, false);
        };
      }

      currentEmailRef.addEventListener('keydown', checkEmails, false);

      return () => {
        currentEmailRef.removeEventListener('keydown', checkEmails, false);
      };
    }

    return undefined;
  }, [acceptSingleEmail, checkEmails]);

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': true,
  });

  return (
    <TextField
      className={textFieldClasses}
      inputRef={emailRef}
      required={required}
      variant="standard"
      error={invalidEmail}
      fullWidth
      defaultValue=""
      label={t('email_one')}
      disabled={disabled}
      onBlur={checkEmails}
      InputProps={
        emailAddresses && emailAddresses.length > 0
          ? {
              startAdornment: emailAddresses.map((email) => (
                <Chip
                  key={email}
                  tabIndex={-1}
                  label={email}
                  onDelete={!disabled ? () => handleDelete(email) : () => false}
                />
              )),
            }
          : null
      }
    />
  );
};

EmailAddressInput.propTypes = {
  onChange: PropTypes.func,
  initialEmailAddresses: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
  acceptSingleEmail: PropTypes.bool,
  required: PropTypes.bool,
};

EmailAddressInput.defaultProps = {
  onChange: () => {},
  initialEmailAddresses: [],
  disabled: false,
  values: [],
  acceptSingleEmail: false,
  required: false,
};

export default EmailAddressInput;
