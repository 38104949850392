import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  inputClassName?: string;
  isRequired?: boolean;
  label: string;
  size?: string;
  textfieldRef?: any;
  value?: string;
};

const TextField: React.FC<Props> = ({
  className,
  error = false,
  helperText,
  inputClassName,
  isRequired,
  label,
  size = 'sm',
  textfieldRef,
  ...rest
}) => (
  // @ts-ignore
  <FormControl className={`${className} w-full flex-1`} size={size} error={error}>
    <FormLabel component="div" className={classNames('truncate', error ? 'invalid' : '')}>
      {label}
      {isRequired && ' *'}
    </FormLabel>
    <Input className={inputClassName} ref={textfieldRef} {...rest} />
    <FormHelperText className={error ? 'validation-message' : ''}>{helperText}</FormHelperText>
  </FormControl>
);

export default TextField;
