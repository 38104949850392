import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

export type AddEditTeamFormikValues = {
  allowCaseManagement: boolean;
  allowSyndicManagement: boolean;
  centralCompanyId: Nullable<string>;
  maxMailboxes: number;
  maxQrTokens: number;
  maxUsers: number;
  name: Nullable<string>;
  workdays: Nullable<string>;
};

const useAddTeamFormConfig = () => {
  const { t } = useTranslation(['common', 'errors']);

  // * initial values can be tweaked if edit functionality is needed for modal later
  return {
    initialValues: {
      allowCaseManagement: true,
      allowSyndicManagement: false,
      centralCompanyId: null,
      maxMailboxes: 0,
      maxQrTokens: 0,
      maxUsers: 1,
      name: null,
      workdays: null,
    },
    validationSchema: yup.object().shape({
      allowCaseManagement: yup.bool().nullable(),
      allowSyndicManagement: yup.bool().nullable(),
      centralCompanyId: yup.string().nullable().required(t('errors:fieldIsRequired')),
      maxMailboxes: yup.number().nullable().min(0, t('errors:valueMustBePositive')),
      maxQrTokens: yup.number().nullable().min(0, t('errors:valueMustBePositive')),
      maxUsers: yup.number().nullable().min(0, t('errors:valueMustBePositive')),
      name: yup.string().nullable().required(t('errors:fieldIsRequired')),
      workdays: yup.number().nullable(),
    }),
  };
};

export default useAddTeamFormConfig;
