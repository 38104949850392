import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import API from '@/shared/api/ApiService';
import { CaseContext } from '../../CaseContext';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import { sentencize } from '@/shared/utils/helpers';
import useNotification from '../../../../shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import AddUpdateCaseRelationForm from './Forms/AddUpdateCaseRelationForm';
import CaseRelationsOverview from '@/containers/Cases/CaseLayout/BodySections/Components/CaseRelationsOverview';
import TeamRelationCrmLink from './Components/TeamRelationCrmLink';

const CaseRelationsCard = () => {
  const { currentCase } = useContext(CaseContext);

  const { t } = useTranslation('common');
  const addEditToggle = useToggle();

  const relationToUpdate = React.useMemo(() => {
    if (!addEditToggle.state) {
      return null;
    }
    return currentCase.relations.find((rel) => rel.id === addEditToggle.state);
  }, [currentCase, addEditToggle.state]);

  const handleCloseAddingRelationModal = () => {
    addEditToggle.hide();
  };

  const { sendDefaultError, sendNotification } = useNotification();

  const onAddNewRelation = async (data) => {
    const {
      companyId,
      contactId,
      teamId,
      vatnumber: vat,
      iban,
      companyName,
      phoneNumber,
      mobilePhoneNumber,
      emailAddresses,
      language,
      gender,
      firstName,
      lastName,
      involvementType,
      role,
      subordinate: subordinateTo,
      isClient,
      isRequestor,
      ...addressData
    } = data;

    const apiData = {
      address: { ...addressData },
      caseId: currentCase.id,
      companyId,
      companyName,
      contactId,
      emailAddresses,
      firstName,
      gender,
      iban,
      involvementType,
      isClient,
      isRequestor,
      language,
      lastName,
      mobilePhoneNumber,
      phoneNumber,
      role,
      subordinateTo,
      teamId,
      vat,
    };

    const response = await API.postAddRelationToCase(apiData);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    handleCloseAddingRelationModal();
    sendNotification({
      header: t('common:success'),
      message: sentencize(t('common:typeSuccessfullyAdded', { type: t('common:involvedParty') })),
      variant: 'success',
    });
  };

  const onUpdateRelation = async (data) => {
    const {
      vatnumber: vat,
      iban,
      companyName,
      phoneNumber,
      mobilePhoneNumber,
      emailAddresses,
      language,
      gender,
      firstName,
      lastName,
      involvementType,
      role,
      subordinate: subordinateTo,
      isClient,
      isRequestor,
      pushCrmChanges,
      ...addressData
    } = data;

    const apiData = {
      address: { ...addressData },
      caseId: currentCase.id,
      companyName,
      emailAddresses,
      firstName,
      gender,
      iban,
      involvementType,
      isClient,
      isRequestor,
      language,
      lastName,
      mobilePhoneNumber,
      phoneNumber,
      pushCrmChanges,
      relationId: relationToUpdate.id,
      role,
      subordinateTo,
      vat,
    };

    const response = await API.putUpdateCaseRelation(apiData);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    handleCloseAddingRelationModal();
    sendNotification({
      header: t('common:success'),
      message: sentencize(t('common:typeSuccessfullyUpdated', { type: t('common:involvedParty') })),
      variant: 'success',
    });
  };

  const onSubmit = (data) => {
    if (relationToUpdate != null) {
      onUpdateRelation(data);
      return;
    }
    onAddNewRelation(data);
  };

  return (
    <div id="caseInvolvedParties">
      <CaseRelationsOverview editToggle={addEditToggle} />
      <KpcModal
        className="w-2/3"
        title={
          relationToUpdate != null
            ? sentencize(t('common:editType', { type: t('common:involvedParty') }))
            : sentencize(t('common:addType', { type: t('common:involvedParty') }))
        }
        toggle={addEditToggle}
      >
        <>
          {relationToUpdate != null && (
            <>
              <TeamRelationCrmLink
                caseRelationToUpdate={relationToUpdate}
                caseId={currentCase.id}
                currentCaseRelations={currentCase.relations}
              />
              <hr />
            </>
          )}
          <AddUpdateCaseRelationForm
            buildingId={currentCase.building?.buildingId}
            onSubmit={onSubmit}
            onCancel={handleCloseAddingRelationModal}
            currentCaseRelations={currentCase.relations}
            editMode={relationToUpdate != null}
            caseRelationToUpdate={relationToUpdate}
            caseId={currentCase.id}
          />
        </>
      </KpcModal>
    </div>
  );
};

export default CaseRelationsCard;
