import { IconButton, Tooltip } from '@mui/joy';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { useCallback, useContext, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize, formatUtcDate, getMomentDescription } from '@/shared/utils/helpers';
import { useDeleteCaseEntity, useUpdateCaseEntityInfo, useUpdateEntityTeamInfo } from '@/mutations';
import { CaseContext } from '@/containers/Cases/CaseContext';
import CaseEntityPropTypes from '@/shared/prop-types/CaseEntityPropTypes';
import CaseEntityType from '@/shared/enums/CaseEntityType';

import { AccessibilityIcon, FileIcon, NoteIcon, PhoneIcon, SmsIcon } from '@/shared/icons/Icons';
import CaseEntityAccessibility from '../Components/CaseEntityAccessibility';
import CaseEntityRemark from '../Components/CaseEntityRemark';
import DeleteAction from '@/shared/components/DeleteAction';
import DocumentAccessibility from '@/shared/components/documents/DocumentAccessibility';
import DocumentItem from './TimelineItems/DocumentItem';
import EmailItem from './TimelineItems/EmailItem';
import { FileContext } from '@/FileContext';
import NoteItem from './TimelineItems/NoteItem';
import PhoneCallItem from './TimelineItems/PhoneCallItem';
import TextMessageItem from './TimelineItems/TextMessageItem';
import TimelinePinAction from './Components/TimelinePinAction';
import useNotification from '@/shared/hooks/UseNotification';

const propTypes = {
  items: PropTypes.arrayOf(CaseEntityPropTypes).isRequired,
  loadMoreItems: PropTypes.func.isRequired,
};

const TimelineSection = ({ items, loadMoreItems }) => {
  const { currentCase, canEditCase } = useContext(CaseContext);

  const { t } = useTranslation('common');
  const updateCaseEntityInfo = useUpdateCaseEntityInfo();
  const { updateEntityTeamInfo, isUpdatingEntityTeamInfo } = useUpdateEntityTeamInfo();

  const [updatedEntityId, setUpdatedEntityId] = useState('');
  const deleteCaseEntity = useDeleteCaseEntity();

  const { sendNotification } = useNotification();

  const handleUpdateIsPinned = (entityId, isPinned, entityType) =>
    updateCaseEntityInfo(
      {
        caseId: currentCase.id,
        entityId,
        isPinned,
      },
      {
        onSuccess: () => {
          sendNotification({
            header: t('common:success'),
            message: t(
              isPinned ? 'common:typeSuccessfullyPinned' : 'common:typeSuccessfullyUnpinned',
              {
                type: t(`common:${decapitalize(entityType)}`),
              },
            ),
            variant: 'success',
          });
        },
      },
    );

  const getTimelineItem = useCallback(
    (entity) => {
      switch (entity.type) {
        case CaseEntityType.PhoneCall:
          return <PhoneCallItem key={entity.id} phoneCall={entity} />;
        case CaseEntityType.File:
          return <DocumentItem key={entity.id} file={entity} caseId={currentCase.id} />;
        case CaseEntityType.Note:
          return <NoteItem key={entity.id} note={entity} />;
        case CaseEntityType.TextMessage:
          return <TextMessageItem key={entity.id} message={entity} />;
        case CaseEntityType.Email:
          return <EmailItem key={entity.id} email={entity} />;
        default:
          return null;
      }
    },
    [currentCase],
  );

  const getTimelineItemIcon = (type) => {
    switch (type) {
      case CaseEntityType.PhoneCall:
        return <PhoneIcon style={{ height: '25px', width: '25px' }} />;
      case CaseEntityType.File:
        return <FileIcon iconStyle="fa" style={{ height: '25px', width: '25px' }} />;
      case CaseEntityType.Note:
        return <NoteIcon iconStyle="fa" style={{ height: '25px', width: '25px' }} />;
      case CaseEntityType.TextMessage:
        return <SmsIcon iconStyle="fa" style={{ height: '25px', width: '25px' }} />;
      case CaseEntityType.Email:
        return (
          <FontAwesomeIcon icon={['fa', 'envelope']} style={{ height: '25px', width: '25px' }} />
        );
      default:
        return null;
    }
  };

  const { files } = useContext(FileContext);

  const handleScrollMore = (e) => {
    const divWrapper = e.currentTarget;
    const scrollMore = divWrapper.scrollTop + 1 > divWrapper.scrollHeight - divWrapper.offsetHeight;
    if (scrollMore) {
      loadMoreItems();
    }
  };

  return (
    <div className="max-h-[1000px] overflow-auto" onScroll={handleScrollMore}>
      {!items?.length && <p>{t('common:noOptions')}</p>}
      {items.map((item) => {
        const itemDate = item.emailDate ?? item.callDate ?? item.created;

        return (
          getTimelineItem(item) && (
            <TimelineItem
              key={item.id}
              className={classnames(!item.isSeen ? 'email-unread' : '', 'before:p-0')}
            >
              <TimelineSeparator className="pr-4">
                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                <TimelineDot color="primary">{getTimelineItemIcon(item.type)}</TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
              </TimelineSeparator>
              <div
                className={classnames(
                  'm-1 rounded-xl',
                  item.isPinned
                    ? 'pinnedTimelineItem outline outline-2 outline-blue-500'
                    : 'unpinnedTimelineItem',
                )}
              >
                <TimelineContent className="h-full rounded-xl border bg-background p-4">
                  <Tooltip
                    className="float-right text-xs"
                    title={formatUtcDate(itemDate, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ss')}
                  >
                    <span>
                      {getMomentDescription(moment, itemDate, 'YYYY-MM-DDTHH:mm:ss', true, true)}
                    </span>
                  </Tooltip>
                  {getTimelineItem(item)}
                  {item.type === CaseEntityType.File && files.some((f) => f.id === item.id) && (
                    <DocumentAccessibility file={files.find((f) => f.id === item.id)} />
                  )}
                  {item.type === CaseEntityType.Email && (
                    <Tooltip
                      title={item.isSeen ? t('markAsUnread') : t('markAsRead')}
                      className="float-right"
                    >
                      <IconButton
                        variant="plain"
                        disabled={isUpdatingEntityTeamInfo && item.id === updatedEntityId}
                        onClick={() => {
                          updateEntityTeamInfo({ caseId: item.caseId, entityId: item.id });
                          setUpdatedEntityId(item.id);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.isSeen ? ['fas', 'envelope-open'] : ['fas', 'envelope-dot']}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {canEditCase && item.type !== CaseEntityType.File && (
                    <div style={{ float: 'right', paddingBottom: '10px' }}>
                      <TimelinePinAction
                        id={item.id}
                        isPinned={item.isPinned}
                        updateIsPinned={() =>
                          handleUpdateIsPinned(item.id, !item.isPinned, item.type)
                        }
                      />
                      {item.createdByCurrentTeam && (
                        <>
                          <CaseEntityAccessibility
                            relations={currentCase?.relations}
                            entity={item}
                            customButton={
                              <IconButton variant="plain" color="neutral">
                                <AccessibilityIcon
                                  iconStyle={item.showOnClientPage ? 'fas' : 'fad'}
                                />
                              </IconButton>
                            }
                          />
                          <DeleteAction onDelete={() => deleteCaseEntity(item)} />
                        </>
                      )}
                    </div>
                  )}
                  {item.type === CaseEntityType.Email && (
                    <div className="float-right">
                      <CaseEntityRemark entity={item} />
                    </div>
                  )}
                </TimelineContent>
              </div>
            </TimelineItem>
          )
        );
      })}
    </div>
  );
};

export default TimelineSection;

TimelineSection.propTypes = propTypes;
