import { FormControl, Input, IconButton as MuiIconButton, Tooltip } from '@mui/joy';
import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSearchFilter } from '../../hooks/UseFilters';
import useToggle from '../../hooks/UseToggle';

import { ClearIcon, SearchIcon } from '../../icons/Icons';
import IconButton from '../buttons/IconButton';

const propTypes = {
  fixed: PropTypes.bool,
  placeholder: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
};

const DataTableSearch = ({ placeholder = null, setSearchText, fixed }) => {
  const { t } = useTranslation();
  const searchToggle = useToggle();
  const { debounceSearch, search: searchString } = useSearchFilter({ startQueryLength: 1 });
  const inputRef = useRef();

  useEffect(() => {
    setSearchText(searchString);
  }, [searchString]);

  const handleChange = (val) => {
    if (val == null) return;
    debounceSearch(val);
  };

  const handleBlur = () => {
    if (inputRef.current) {
      const child = inputRef.current.children.namedItem('search');
      if (!child.value || child.value === '') {
        searchToggle.hide();
      }
    }
  };

  const handleChangeEvent = (e) => {
    handleChange(e.currentTarget.value);
  };

  const handleClear = () => {
    if (inputRef.current) {
      const child = inputRef.current.children.namedItem('search');
      if (child) {
        child.value = '';
        handleChange('');
        child.focus();
      }
    }
  };

  return (
    <>
      {!searchToggle.value && !fixed && (
        <Tooltip title={t('search.label')}>
          <MuiIconButton onClick={searchToggle.show} className="align-self-start">
            <SearchIcon />
          </MuiIconButton>
        </Tooltip>
      )}

      {(searchToggle.value || fixed) && (
        <FormControl className="flex-1" size="sm">
          <Input
            autoFocus={!fixed}
            ref={inputRef}
            startDecorator={<SearchIcon />}
            placeholder={placeholder ?? t('common:search.placeholder')}
            label="Search"
            name="search"
            onChange={(e) => handleChangeEvent(e)}
            endDecorator={<IconButton onClick={handleClear} iconComponent={<ClearIcon />} />}
            onBlur={handleBlur}
          />
        </FormControl>
      )}
    </>
  );
};

DataTableSearch.propTypes = propTypes;

export default memo(DataTableSearch);
