export const LoadingSpinner = () => (
  <svg className="load__icon">
    <path fill="#f50057" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  </svg>
);

export const LoadingSpinnerWrapper = () => (
  <div className="load">
    <div className="load__icon-wrap">
      <LoadingSpinner />
    </div>
  </div>
);
