import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useEffect, useState } from 'react';

import { AutocompleteOption as AutocompleteOptionType, Nullable } from '~/common/types';
import useGetHomeownerAssociationsAutocomplete from '@/queries/homeownerAssociation/useGetHomeownerAssociationsAutocomplete';

import { BuildingIcon, InlineSpinner } from '@/shared/icons/Icons';
import AutocompleteListboxWithSelectAll from '@/shared/components/2.0/autocomplete/AutocompleteListboxWithSelectAll';

export type SelectHomeownerAssociationProps<Multiple extends boolean | undefined = undefined> = {
  error?: string;
  isRequired?: boolean;
  label?: string;
  multiple?: Multiple;
  onChange: Multiple extends true
    ? (options: AutocompleteOptionType[]) => void
    : (option: AutocompleteOptionType | null) => void;
  placeholder?: string;
  value?: Multiple extends true ? string[] : string | null;
  withSelectAllButton?: boolean;
  [key: string]: unknown;
};

const SelectHomeownerAssociation = <Multiple extends boolean | undefined = undefined>({
  error,
  isRequired = false,
  label,
  multiple = false,
  onChange,
  value,
  withSelectAllButton = false,
  ...rest
}: SelectHomeownerAssociationProps<Multiple>) => {
  const [selectedValues, setSelectedValues] = useState<
    Nullable<AutocompleteOptionType> | AutocompleteOptionType[]
  >(multiple ? [] : null);

  const { homeownerAssociationsAutocomplete, isHomeownerAssociationAutocompleteLoading } =
    useGetHomeownerAssociationsAutocomplete();

  useEffect(() => {
    if (isHomeownerAssociationAutocompleteLoading) {
      return;
    }
    if (!multiple) {
      setSelectedValues(
        homeownerAssociationsAutocomplete.find((hoa) => hoa.value === (value as string | null)) ??
          null,
      );
      return;
    }
    setSelectedValues(
      homeownerAssociationsAutocomplete.filter((hoa) => (value as string[])?.includes(hoa.value)) ??
        [],
    );
  }, [
    homeownerAssociationsAutocomplete,
    isHomeownerAssociationAutocompleteLoading,
    multiple,
    value,
  ]);

  const handleChange = (option: AutocompleteOptionType | AutocompleteOptionType[] | null) => {
    setSelectedValues(option);

    if (!multiple) {
      (onChange as (option: AutocompleteOptionType | null) => void)(
        option as AutocompleteOptionType | null,
      );
      return;
    }
    const options = (option as AutocompleteOptionType[] | null) ?? [];
    (onChange as (options: AutocompleteOptionType[]) => void)(options);
  };

  const restProps =
    multiple && withSelectAllButton
      ? {
          ...rest,
          slotProps: {
            listbox: {
              handleSelectAll: () => handleChange(homeownerAssociationsAutocomplete),
            },
          },
          slots: {
            listbox: AutocompleteListboxWithSelectAll,
          },
        }
      : { ...rest };

  return (
    <FormControl error={!!error} className="grow">
      {label && <FormLabel required={isRequired}>{label}</FormLabel>}
      <Autocomplete
        isOptionEqualToValue={(option, newValue) => option.value === newValue.value}
        onChange={(_, option) => handleChange(option)}
        options={homeownerAssociationsAutocomplete as AutocompleteOptionType[]}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.value}>
            <ListItemDecorator>
              <BuildingIcon />
            </ListItemDecorator>
            <ListItemContent>
              {option.label}

              {option.data && option.data.description ? (
                <FormHelperText>{option.data.description as string}</FormHelperText>
              ) : null}
            </ListItemContent>
          </AutocompleteOption>
        )}
        startDecorator={
          isHomeownerAssociationAutocompleteLoading ? <InlineSpinner /> : <BuildingIcon />
        }
        value={selectedValues}
        multiple={multiple}
        {...restProps}
      />
      {error && <FormHelperText className="px-1 text-xs">{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectHomeownerAssociation;
