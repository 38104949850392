import { Col, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikTimePicker from '../../../../../../shared/formik/FormikTimePicker';
import FormikYesNo from '../../../../../../shared/formik/FormikYesNo';

const ScheduleRepairStartFormBody = ({ disabled, firstAppointmentDate }) => {
  const { t } = useTranslation('common');
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.startOfWorksTime != null) {
      setFieldValue('startOfWorkAmPm', null);
    }
  }, [setFieldValue, values.startOfWorksTime]);

  return (
    <FormGroup className="mb-3">
      <Row>
        <Col xs={5}>
          <FormikDatePicker
            name="repairStartDate"
            label={t('date')}
            disabled={disabled}
            options={{
              format: 'YYYY-MM-DD',
              minDate: firstAppointmentDate,
            }}
            clearable
          />
        </Col>
        <Col xs={4}>
          <FormikTimePicker
            id="repairStartTime"
            name="repairStartTime"
            label={t('common:time')}
            disabled={disabled}
          />
        </Col>
        {values.repairStartTime == null && (
          <Col xs={3}>
            <FormikYesNo
              id="repairStartAmPm"
              name="repairStartAmPm"
              noText={t('common:morning')}
              yesText={t('common:afternoon')}
              isDefaultChecked={false}
              disabled={disabled}
            />
          </Col>
        )}
      </Row>
    </FormGroup>
  );
};

ScheduleRepairStartFormBody.propTypes = {
  disabled: PropTypes.bool,
  firstAppointmentDate: PropTypes.string,
};

ScheduleRepairStartFormBody.defaultProps = {
  disabled: true,
  firstAppointmentDate: null,
};

export default ScheduleRepairStartFormBody;
