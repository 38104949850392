import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { Case, UpdateCaseStatus } from '@/types/cases';
import caseApi from '@/shared/api/cases/case';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useUpdateCaseCaseStatus = () => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: UpdateCaseStatus) => caseApi.putCaseStatus(data),
    onError: (error: AxiosError) => {
      sendDefaultError(error);
    },
    onSuccess: (_, { caseId }) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASE, caseId]);

      const currentCase = queryClient.getQueryData<Case>([queryIds.cases.GET_CASE, caseId]);
      if (currentCase?.caseGroupId) {
        queryClient.invalidateQueries([
          queryIds.cases.GET_CASES_IN_GROUP,
          currentCase?.caseGroupId,
        ]);
      }
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('case_one') }),
        variant: 'success',
      });
    },
  });

  return {
    isUpdatingCaseStatus: isLoading,
    updateCaseStatus: mutate,
  };
};

export default useUpdateCaseCaseStatus;
