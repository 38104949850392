import { useQuery } from '@tanstack/react-query';

import { companyApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';

import useNotification from '@/shared/hooks/UseNotification';

const useGetCompanies = () => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    onError: sendDefaultError,
    queryFn: async () => {
      const result = await companyApi.getCompanies();

      const contractors = result.filter((company) => company.isContractor);
      const companies = result.filter((company) => !company.isContractor);

      return {
        allCompaniesContractors: result,
        formatted: {
          companies: companies.map((company) => ({
            label: `${company.displayName} - ${company.vatNumber}`,
            value: company.id,
          })),
          contractors: contractors.map((company) => ({
            label: `${company.displayName} - ${company.vatNumber}`,
            value: company.id,
          })),
        },
      };
    },
    queryKey: [queryIds.companies.GET_COMPANIES],
  });

  return {
    companies: data?.allCompaniesContractors || [],
    companiesAreFetching: isFetching,
    companiesAreLoading: isLoading,
    companiesAutocomplete: data?.formatted.companies || [],
    contractorsAutocomplete: data?.formatted.contractors || [],
  };
};

export default useGetCompanies;
