import { Autocomplete, AutocompleteOption, FormControl, FormLabel, Typography } from '@mui/joy';
import classNames from 'classnames';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';
import ROUTES from '@/containers/App/Router/Routes';
import useAcceptTeamInvitation from '@/mutations/team/useAcceptTeamInvitation';

import { InlineSpinner, TeamIcon } from '@/shared/icons/Icons';

import logo from '@/shared/img/logo/logo_light.svg';
import { trpc } from '@/config/trpc';

const SplashScreen = () => {
  const { setUi } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = trpc.team.teamsAndInvitations.useQuery();
  const { acceptTeamInvitationAsync } = useAcceptTeamInvitation();

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="w-[500px] space-y-4 rounded-xl border p-4 shadow-md">
        <img src={logo} alt="Logo" />
        <FormControl>
          <FormLabel required>{t('selectFromOneOfYourTeams')}</FormLabel>
          <Autocomplete
            startDecorator={isLoading && <InlineSpinner />}
            disableClearable
            disabled={!data?.length}
            placeholder={isLoading ? t('loading') : t('search.placeholder')}
            options={data || []}
            groupBy={(option) => option.type}
            onChange={async (_e, option) => {
              if (option.invitationId) {
                await acceptTeamInvitationAsync(
                  {
                    teamId: option.value,
                    teamInvitationId: option.invitationId,
                  },
                  {
                    onSuccess: () => {
                      setUi({
                        payload: option.value,
                        type: 'SAVE_CURRENT_TEAM_ID',
                      });

                      navigate(ROUTES.HOME);
                    },
                  },
                );
              }
              setUi({
                payload: option.value,
                type: 'SAVE_CURRENT_TEAM_ID',
              });
              navigate(ROUTES.HOME);
            }}
            renderOption={(props, option) => (
              <AutocompleteOption {...props}>
                <Typography
                  startDecorator={<TeamIcon />}
                  className={classNames('text-xs', {
                    'text-red-500': option.type === 'invitation',
                  })}
                >
                  {option.label}
                </Typography>
              </AutocompleteOption>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default SplashScreen;
