import { useMutation, useQueryClient } from '@tanstack/react-query';

import { caseApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useUpdateAxaServiceReference = () => {
  const queryClient = useQueryClient();
  const { sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation(caseApi.updateAxaServiceReference, {
    onError: (err, data, previousData) => {
      queryClient.setQueryData([queryIds.cases.GET_CASE, data.caseId], previousData);

      sendDefaultError(err);
    },
    onSuccess: (_, { caseId }) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASE, caseId]);
    },
  });

  return {
    isUpdatingAxaServiceReference: isLoading,
    updateAxaServiceReference: mutate,
  };
};

export default useUpdateAxaServiceReference;
