import { Button, Divider, FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AddIcon, InsurancePolicyIcon } from '../../../icons/Icons';
import { decapitalize, makeId } from '../../../utils/helpers';
import ModalFooter from '../../2.0/forms/ModalFooter';

const ITEM_LIMIT = 5;

const PolicySelectorList = ({
  filteredPolicies,
  suggestedPolicies,
  handleSelectOrDeselect,
  selectorMenuRef,
  isAddingPolicyNumber,
  setIsAddingPolicyNumber,
  enablePolicyNumberInput,
}) => {
  const { t } = useTranslation(['common', 'insurancePolicy']);
  const [policyNumber, setPolicyNumber] = useState('');

  const handleOnSave = () => {
    setPolicyNumber('');
    setIsAddingPolicyNumber(false);
    handleSelectOrDeselect({ id: makeId(7), isPolicyNumber: true, policyNumber });
  };

  return (
    <div className="flex flex-col space-y-2 rounded-bl-xl rounded-br-xl p-4" ref={selectorMenuRef}>
      {!isAddingPolicyNumber && (
        <>
          {!!suggestedPolicies.length && (
            <>
              <h5 className="text-primary">{t('common:suggestions')}</h5>
              <div className="flex flex-col">
                {suggestedPolicies
                  .filter((d, i) => i < ITEM_LIMIT)
                  .map((policy) => (
                    <Button
                      startDecorator={<InsurancePolicyIcon />}
                      className="grow justify-start text-left font-normal"
                      variant="plain"
                      color="neutral"
                      key={policy.id}
                      onClick={() => handleSelectOrDeselect(policy)}
                    >
                      {`${policy.policyNumber}${
                        policy.insurancePolicyType
                          ? ` - ${t(`insurancePolicy:${decapitalize(policy.insurancePolicyType)}`)}`
                          : ''
                      } `}
                    </Button>
                  ))}
                {suggestedPolicies.length > ITEM_LIMIT && (
                  <FormHelperText className="justify-center text-xs">
                    {t('common:moreResultsAreHiddenRefineFilter')}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
          {filteredPolicies.length > 0 && (
            <>
              <h5 className="text-primary">{t('common:policies')}</h5>
              <div className="flex flex-col">
                {filteredPolicies
                  .filter((d, i) => i < ITEM_LIMIT)
                  .map((policy) => (
                    <Button
                      startDecorator={<InsurancePolicyIcon />}
                      className="grow justify-start text-left font-normal"
                      variant="plain"
                      color="neutral"
                      key={policy.id}
                      onClick={() => handleSelectOrDeselect(policy)}
                    >
                      {`${policy.policyNumber}${
                        policy.insurancePolicyType
                          ? ` - ${t(`insurancePolicy:${decapitalize(policy.insurancePolicyType)}`)}`
                          : ''
                      } `}
                    </Button>
                  ))}
                {filteredPolicies.length > ITEM_LIMIT && (
                  <FormHelperText className="justify-center text-xs">
                    {t('common:moreResultsAreHiddenRefineFilter')}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
          {enablePolicyNumberInput && (
            <div className="flex flex-col ">
              <Divider />
              <Button
                variant="plain"
                color="neutral"
                className="justify-start text-left font-normal"
                startDecorator={<AddIcon />}
                onClick={() => setIsAddingPolicyNumber(true)}
              >
                {t('common:addPolicyNumber')}
              </Button>
            </div>
          )}
        </>
      )}
      {isAddingPolicyNumber && (
        <>
          <h5 className="text-primary">{t('common:addPolicyNumber')}</h5>
          <FormControl className="p-4">
            <FormLabel>{t('common:policyNumber')}</FormLabel>
            <Input value={policyNumber} onChange={({ target }) => setPolicyNumber(target.value)} />

            <ModalFooter onCancel={() => setIsAddingPolicyNumber(false)} onSubmit={handleOnSave} />
          </FormControl>
        </>
      )}
    </div>
  );
};

PolicySelectorList.propTypes = {
  enablePolicyNumberInput: PropTypes.bool,
  filteredPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      policyNumber: PropTypes.string,
    }),
  ).isRequired,
  handleSelectOrDeselect: PropTypes.func.isRequired,
  isAddingPolicyNumber: PropTypes.bool,
  selectorMenuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  setIsAddingPolicyNumber: PropTypes.func.isRequired,
  suggestedPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      policyNumber: PropTypes.string,
    }),
  ),
};

PolicySelectorList.defaultProps = {
  enablePolicyNumberInput: true,
  isAddingPolicyNumber: false,
  suggestedPolicies: [],
};

export default PolicySelectorList;
