import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressIcon, InlineSpinner } from '../../../icons/Icons';
import { useGetPostalCodeCity } from '../../../../queries';
import { useSearchFilter } from '../../../hooks/UseFilters';

import CountriesAutocomplete from '../autocomplete/CountriesAutocomplete';

const AddressForm = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useFormikContext();
  const { debounceSearch, search } = useSearchFilter();
  const { t } = useTranslation();

  const { postalCodeCityAutoComplete, error, isFetchingPostalCodeCity } = useGetPostalCodeCity({
    countryCode: values.country,
    term: search,
  });

  const [selectedValue, setSelectedValue] = useState({
    city: values.city || '',
    postalCode: values.postalCode || '',
  });
  const [inputValue, setInputValue] = useState('');

  const hasError = (field) => errors[field] && touched[field];

  const handlePostalCodeCity = (e, val) => {
    e?.preventDefault();
    setSelectedValue(val);

    if (val) {
      setFieldValue('city', val.city);
      setFieldValue('postalCode', val.postalCode);
      return;
    }

    setFieldValue('city', null);
    setFieldValue('postalCode', null);
    setInputValue('');
  };

  const handleInputChange = (e, val) => {
    e?.preventDefault();
    if (val) {
      setInputValue(val);
      debounceSearch(val);
    } else {
      setInputValue('');
    }
  };

  const handleCityChange = (e) => {
    setFieldValue('city', e.target.value);
    setSelectedValue((prev) => ({ ...prev, city: e.target.value }));
  };
  return (
    <div className="flex flex-col space-y-2">
      {error && <Alert color="danger">{t('errors:postalCodeCityError')}</Alert>}
      <CountriesAutocomplete />
      {values.country === 'BE' && (
        <>
          <FormControl error={hasError('postalCode')}>
            <FormLabel>{t('postalCode')}</FormLabel>
            <Autocomplete
              name="postalCode"
              value={selectedValue}
              onChange={(e, val) => handlePostalCodeCity(e, val)}
              inputValue={inputValue}
              onInputChange={(e, val) => handleInputChange(e, val)}
              options={postalCodeCityAutoComplete}
              endDecorator={isFetchingPostalCodeCity && <InlineSpinner />}
              getOptionLabel={(option) => option.postalCode}
              renderOption={(props, option) => (
                <AutocompleteOption {...props}>
                  <ListItemDecorator>
                    <AddressIcon />
                  </ListItemDecorator>
                  <ListItemContent>
                    {option.postalCode} ({option.city})
                  </ListItemContent>
                </AutocompleteOption>
              )}
            />
            <FormHelperText error={errors.postalCode} touched={touched.postalCode} />
          </FormControl>
          <FormControl error={hasError('city')}>
            <FormLabel>{t('city')}</FormLabel>
            <Input name="city" value={selectedValue?.city} onChange={(e) => handleCityChange(e)} />
            <FormHelperText error={errors.city} touched={touched.city} />
          </FormControl>
        </>
      )}
      {values.country !== 'BE' && (
        <>
          <FormControl error={hasError('postalCode')}>
            <FormLabel>{t('postalCode')}</FormLabel>
            <Input name="postalCode" value={values.postalCode} onChange={handleChange} />
            <FormHelperText error={errors.postalCode} touched={touched.postalCode} />
          </FormControl>
          <FormControl error={hasError('city')}>
            <FormLabel>{t('city')}</FormLabel>
            <Input name="city" value={values.city} onChange={handleChange} />
            <FormHelperText error={errors.city} touched={touched.city} />
          </FormControl>
        </>
      )}
      <FormControl error={hasError('addressLine')}>
        <FormLabel>{t('_address.label')}</FormLabel>
        <Input value={values.addressLine} name="addressLine" size="sm" onChange={handleChange} />
        <FormHelperText error={errors.addressLine} touched={touched.addressLine} />
      </FormControl>
    </div>
  );
};

export default AddressForm;
