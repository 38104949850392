import React, { useContext, useState } from 'react';
import { IconButton } from '@mui/joy';
import { Popper as PopperUnstyled } from '@mui/base';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { LinkIcon } from '../../icons/Icons';
import SelectPolicy from '../select/SelectPolicy';
import Tooltip from '../2.0/Tooltip';

const Popup = styled(PopperUnstyled)`
  z-index: 101;
`;

const propTypes = {
  onPolicySelection: PropTypes.func.isRequired,
  policyNumber: PropTypes.string.isRequired,
};

const LinkPolicyButton = ({ onPolicySelection, policyNumber }) => {
  const { currentCase } = useContext(CaseContext);

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');
  const anchorEl = React.useRef();

  const onPolicyChanged = (selectedPolicies) => {
    if (selectedPolicies) {
      const policy = selectedPolicies.length > 0 ? selectedPolicies[0] : selectedPolicies;
      policy.policyNumber = policyNumber;
      onPolicySelection(policy);
      setIsOpen(false);
    }
  };

  return (
    <>
      {isOpen && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={() => setIsOpen(false)}
        />
      )}
      <Tooltip title={t('linkX', { type: t('policy') })}>
        <IconButton
          ref={anchorEl}
          variant="plain"
          color="danger"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Popup open={isOpen} anchorEl={anchorEl?.current} disablePortal placement="right">
        {isOpen && (
          <SelectPolicy
            selectSingle
            enablePolicyNumberInput={false}
            onPolicySelectChanged={onPolicyChanged}
            isAddress={!currentCase?.building?.buildingId}
            address={
              currentCase?.building?.buildingId
                ? currentCase.building.address
                : currentCase?.address
            }
            buildingId={currentCase?.building?.buildingId}
          />
        )}
      </Popup>
    </>
  );
};

LinkPolicyButton.propTypes = propTypes;

export default LinkPolicyButton;
