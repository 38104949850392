import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WrappedText = ({ children }) => <span className="break-all">{children}</span>;

WrappedText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default WrappedText;
