import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import { useDeleteCaseFiles, useUpdateFile, useUpdateFilesType } from '@/mutations';
import API from './shared/api/ApiService';
import { AppContext } from './shared/context/context';
import { objectToFormData } from './shared/utils/helpers';
import signalRMessages from './redux/actions/signalRMessages';

export type FileContextFile = {
  contentType: string;
  fileName: string;
  fileUri: string;
  fromParent?: boolean
  id: string;
  tasks?: Nullable<string[]>;
  type: string;
};

export enum FileContextParentType {
  Case = 'Case',
  CaseTemplate = 'CaseTemplate',
  Contact = 'Contact',
  Policy = 'Policy',
  Spreadsheet = 'Spreadsheet',
}

type FileContextValue = {
  createFiles: (newFiles: File[], additionalData?: Record<string, any>) => Promise<any> | null;
  currentTeamCanDeleteOverride: boolean;
  deleteFile: (fileId: string) => Promise<boolean>;
  deleteFiles: (fileIds: string[], mutationOptions?: Record<string, any>) => boolean;
  files: FileContextFile[];
  isLoadingFiles: boolean;
  isMutating: boolean;
  parentType: FileContextParentType | null;
  parentTypeId: string | null;
  setCurrentTeamCanDeleteOverride: Dispatch<SetStateAction<boolean>>;
  setFiles: Dispatch<SetStateAction<FileContextFile[]>>;
  setParentTypeId: Dispatch<SetStateAction<string | null>>;
  setSeen: (id: string, seen: boolean) => void;
  updateFile: (fileData: FileContextFile, mutationOptions?: Record<string, any>) => void;
  updateFilesType: (
    data: { fileIds: string[]; newType: string },
    mutationOptions?: Record<string, any>,
  ) => void;
};

type FileContextProviderProps = {
  type: FileContextParentType;
  children: ReactNode;
};

export const FileContext = createContext<FileContextValue>({
  createFiles: () => null,
  currentTeamCanDeleteOverride: false,
  deleteFile: () => Promise.resolve(false),
  deleteFiles: () => false,
  files: [],
  isLoadingFiles: true,
  isMutating: false,
  parentType: null,
  parentTypeId: null,
  setCurrentTeamCanDeleteOverride: () => {},
  setFiles: () => {},
  setParentTypeId: () => {},
  setSeen: () => {},
  updateFile: () => {},
  updateFilesType: () => {},
});

export const FileContextProvider: React.FC<FileContextProviderProps> = ({ type, children }) => {
  const [currentTeamCanDeleteOverride, setCurrentTeamCanDeleteOverride] = useState(false);
  const [files, setFiles] = useState<FileContextFile[]>([]);
  const [isLoadingFiles, setIsloadingFiles] = useState(true);
  const [parentTypeId, setParentTypeId] = useState<string | null>(null);

  const { socket } = useContext(AppContext);
  const { deleteCaseFiles, isDeletingCaseFiles } = useDeleteCaseFiles();
  const { updateFile: updateFileMutation, isUpdatingFile } = useUpdateFile();
  const { updateFilesType: updateFilesTypeMutation, isUpdatingFilesType } = useUpdateFilesType();

  const reloadFiles = useCallback(async () => {
    setIsloadingFiles(true);

    if (!parentTypeId) {
      setIsloadingFiles(false);

      return;
    }

    let apiPromise: (data: string) => Promise<any>;
    switch (type) {
      case FileContextParentType.Case:
        apiPromise = API.fetchGetCaseFiles;
        break;
      case FileContextParentType.CaseTemplate:
        apiPromise = API.fetchGetCaseTemplateFiles;
        break;
      case FileContextParentType.Contact:
        apiPromise = API.fetchGetContactFiles;
        break;
      case FileContextParentType.Policy:
        apiPromise = API.fetchGetPolicyFiles;
        break;
      case FileContextParentType.Spreadsheet:
        apiPromise = API.fetchGetSpreadsheetFiles;
        break;
      default:
        return;
    }

    const response = await apiPromise(parentTypeId);

    setIsloadingFiles(false);

    if (response.serviceError != null || response.status !== 200) {
      setFiles([]);
      return;
    }

    setFiles(response.data);
  }, [parentTypeId, type]);

  const onDocumentAccessibilityUpdated = useCallback(
    (data: any) => {
      if (!parentTypeId) {
        return;
      }
      const { linkedDocuments } = data;
      const linkedEntities = linkedDocuments
        .filter((u: any) => u.entityTypeAsString === type)
        .map((u: any) => u.entityId);
      if (linkedEntities.includes(parentTypeId)) {
        reloadFiles();
      }
    },
    [parentTypeId, reloadFiles, type],
  );

  const onDocumentDataUpdated = useCallback(
    (data: any) => {
      if (!parentTypeId) {
        return;
      }
      const { fileId, linkedDocuments, fileName, categoryDescription } = data;
      const entitiesForWhichToUpdate = linkedDocuments
        .filter((u: any) => u.entityTypeAsString === type)
        .map((u: any) => u.entityId);
      if (entitiesForWhichToUpdate.includes(parentTypeId)) {
        setFiles((prev) =>
          prev.map((file) => {
            if (file.id !== fileId) {
              return file;
            }
            return {
              ...file,
              categoryDescription,
              fileName,
            };
          }),
        );
      }
    },
    [parentTypeId, type],
  );

  const onDocumentLinksAdded = useCallback(
    (data: any) => {
      if (!parentTypeId) {
        return;
      }
      const { addedLinks } = data;
      const entitiesForWhichToAdd = addedLinks
        .filter((u: any) => u.entityTypeAsString === type)
        .map((u: any) => u.entityId);
      if (entitiesForWhichToAdd.includes(parentTypeId)) {
        reloadFiles();
      }
    },
    [parentTypeId, reloadFiles, type],
  );

  const onDocumentLinksRemoved = useCallback(
    (data: any) => {
      if (!parentTypeId) {
        return;
      }
      const { fileId, unlinkedDocuments } = data;
      const entitiesFromWhichToRemove = unlinkedDocuments
        .filter((u: any) => u.entityTypeAsString === type)
        .map((u: any) => u.entityId);
      if (entitiesFromWhichToRemove.includes(parentTypeId)) {
        setFiles((prev) => prev.filter((file) => file.id !== fileId));
      }
    },
    [parentTypeId, type],
  );

  const onDocumentUploaded = useCallback(
    (data: any) => {
      if (!parentTypeId) {
        return;
      }
      const { linkedDocuments } = data;
      const linkedEntities = linkedDocuments
        .filter((u: any) => u.entityTypeAsString === type)
        .map((u: any) => u.entityId.toLowerCase());
      if (linkedEntities.includes(parentTypeId.toLowerCase())) {
        reloadFiles();
      }
    },
    [parentTypeId, reloadFiles, type],
  );

  useEffect(() => {
    socket?.on(signalRMessages.DocumentAccessibilityUpdated, onDocumentAccessibilityUpdated);
    socket?.on(signalRMessages.DocumentDataUpdated, onDocumentDataUpdated);
    socket?.on(signalRMessages.DocumentLinksAdded, onDocumentLinksAdded);
    socket?.on(signalRMessages.DocumentLinksRemoved, onDocumentLinksRemoved);
    socket?.on(signalRMessages.DocumentUploaded, onDocumentUploaded);

    return () => {
      socket?.off(signalRMessages.DocumentAccessibilityUpdated);
      socket?.off(signalRMessages.DocumentDataUpdated);
      socket?.off(signalRMessages.DocumentLinksAdded);
      socket?.off(signalRMessages.DocumentLinksRemoved);
      socket?.off(signalRMessages.DocumentUploaded);
    };
  }, [
    socket,
    onDocumentAccessibilityUpdated,
    onDocumentDataUpdated,
    onDocumentLinksAdded,
    onDocumentLinksRemoved,
    onDocumentUploaded,
  ]);

  useEffect(() => {
    if (parentTypeId == null) {
      return;
    }

    reloadFiles();
  }, [parentTypeId, reloadFiles, type]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'errors']);

  const setSeen = useCallback(
    async (id: string, seen: boolean) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const response = await API.putUpdateFileSeenByTeam(id, seen);
      setIsSubmitting(false);

      if (response.serviceError != null || response.status !== 200) {
        enqueueSnackbar(t('errors:failedToUpdateType', { type: t('file_one') }), {
          variant: 'error',
        });

        return;
      }

      setFiles((prev) => prev.map((f) => (f.id === id ? { ...f, seen } : f)));
    },
    [enqueueSnackbar, isSubmitting, t],
  );

  const updateFile = useCallback(
    async (fileData: FileContextFile, mutationOptions: Record<string, any> = {}) => {
      const { id, fileName, type: fileType } = fileData;
      updateFileMutation(
        { data: { fileName, type: fileType }, fileId: id },
        {
          ...mutationOptions,
          onSuccess: () => {
            setFiles((prev) => prev.map((f) => (f.id === id ? { ...f, ...fileData } : f)));
            if (mutationOptions.onSuccess) {
              mutationOptions.onSuccess();
            }
          },
        },
      );
    },
    [updateFileMutation],
  );

  const updateFilesType = useCallback(
    async (
      { fileIds, newType }: { fileIds: string[]; newType: string },
      mutationOptions: Record<string, any> = {},
    ) => {
      updateFilesTypeMutation(
        { fileIds, type: newType },
        {
          ...mutationOptions,
          onSuccess: () => {
            setFiles((prev) =>
              prev.map((f) => (fileIds.includes(f.id) ? { ...f, type: newType } : f)),
            );
            if (mutationOptions.onSuccess) {
              mutationOptions.onSuccess();
            }
          },
        },
      );
    },
    [updateFilesTypeMutation],
  );

  const createFiles = useCallback(
    async (newFiles: FileContextFile[], additionalData: Record<string, any> = {}) => {
      let apiPromise: (data: any) => Promise<any>;
      let data: any = null;
      switch (type) {
        case FileContextParentType.Case:
          apiPromise = API.postAddFileToCase;
          data = objectToFormData({
            caseId: parentTypeId,
            files: newFiles,
            ...additionalData,
          });
          break;
        case FileContextParentType.CaseTemplate:
          apiPromise = API.postAddFilesToCaseTemplate;
          data = objectToFormData({
            caseTemplateId: parentTypeId,
            files: newFiles,
          });
          break;
        case FileContextParentType.Contact:
          apiPromise = API.postAddFilesToContact;
          data = objectToFormData({
            contactId: parentTypeId,
            files: newFiles,
          });
          break;
        case FileContextParentType.Policy:
          apiPromise = API.postAddFilesToPolicy;
          data = objectToFormData({
            files: newFiles,
            policyId: parentTypeId,
          });
          break;
        case FileContextParentType.Spreadsheet:
          apiPromise = API.postAddFilesToSpreadsheet;
          data = objectToFormData({
            files: newFiles,
            spreadsheetId: parentTypeId,
          });
          break;
        default:
          return null;
      }

      const response = await apiPromise(data);

      if (response.serviceError != null || response.status !== 201) {
        enqueueSnackbar(
          t('errors:failedToUploadType', {
            type: t('file', { count: files.length }),
          }),
          { variant: 'error' },
        );

        return null;
      }

      enqueueSnackbar(
        t('typeSuccessfullyAdded', {
          type: t('file', { count: files.length }),
        }),
        { variant: 'success' },
      );

      reloadFiles();

      return response;
    },
    [enqueueSnackbar, files.length, parentTypeId, reloadFiles, t, type],
  );

  const deleteFiles = useCallback(
    (fileIds: string[], mutationOptions: Record<string, any> = {}) => {
      let mutationFn: any = null;
      let data: any = {};

      switch (type) {
        case FileContextParentType.Case:
          mutationFn = deleteCaseFiles;
          data = { caseFileIds: fileIds, caseId: parentTypeId };
          break;
        default:
          return false;
      }

      mutationFn(data, {
        ...mutationOptions,
        onSuccess: () => {
          setFiles((prev) => prev.filter((f) => !fileIds.includes(f.id)));
          if (mutationOptions.onSuccess) {
            mutationOptions.onSuccess();
          }
        },
      });

      return true;
    },
    [parentTypeId, type, deleteCaseFiles],
  );

  const deleteFile = useCallback(
    async (fileId: string) => {
      if (!parentTypeId) {
        return false;
      }

      let apiPromise: (id: string, fileIds: string[]) => Promise<any>;
      switch (type) {
        case FileContextParentType.Case:
          apiPromise = API.deleteFileFromCase;
          break;
        case FileContextParentType.CaseTemplate:
          apiPromise = API.deleteFileFromCaseTemplate;
          break;
        case FileContextParentType.Contact:
          apiPromise = API.deleteFilesFromContact;
          break;
        case FileContextParentType.Policy:
          apiPromise = API.deleteFileFromPolicy;
          break;
        case FileContextParentType.Spreadsheet:
          apiPromise = API.deleteFileFromSpreadsheet;
          break;
        default:
          return false;
      }

      const response = await apiPromise(parentTypeId, [fileId]);

      if (response.serviceError != null || response.status !== 204) {
        enqueueSnackbar(t('errors:failedToDeleteType', { type: t('file_one') }), {
          variant: 'error',
        });

        return false;
      }

      setFiles((prev) => prev.filter((f) => f.id !== fileId));
      enqueueSnackbar(t('typeSuccessfullyRemoved', { type: t('file_one') }), {
        variant: 'success',
      });
      return true;
    },
    [enqueueSnackbar, parentTypeId, t, type],
  );

  // add rest of loading states of mutations
  const isMutating = isDeletingCaseFiles || isUpdatingFile || isUpdatingFilesType;

  const value = useMemo(
    () => ({
      createFiles,
      currentTeamCanDeleteOverride,
      deleteFile,
      deleteFiles,
      files,
      isLoadingFiles,
      isMutating,
      parentType: type,
      parentTypeId,
      setCurrentTeamCanDeleteOverride,
      setFiles,
      setParentTypeId,
      setSeen,
      updateFile,
      updateFilesType,
    }),
    [
      files,
      setSeen,
      createFiles,
      parentTypeId,
      type,
      deleteFile,
      deleteFiles,
      updateFile,
      currentTeamCanDeleteOverride,
      isLoadingFiles,
      isMutating,
      updateFilesType,
    ],
  );

  return (
    // the Provider gives access to the context to its children
    <FileContext.Provider value={value}>{children}</FileContext.Provider>
  );
};
