import { AutocompleteListbox, AutocompleteOption, ListItemButton } from '@mui/joy';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = React.ComponentProps<typeof AutocompleteListbox> &
  React.AllHTMLAttributes<HTMLUListElement> & {
    handleSelectAll: () => void;
  };

const AutocompleteListboxWithSelectAll = forwardRef<HTMLUListElement, Props>(
  ({ children, handleSelectAll, open, ...rest }, ref) => {
    const { t } = useTranslation();
    const button = (
      <AutocompleteOption key="__handleSelectAll">
        <ListItemButton onClick={handleSelectAll} className="w-full">
          {t('selectAll')}
        </ListItemButton>
      </AutocompleteOption>
    );
    const mappedChildren = children as React.ReactElement[];
    const allChildren = [button, ...mappedChildren];
    return (
      <div className={open ? '' : 'hidden'}>
        <AutocompleteListbox ref={ref} {...rest}>
          {allChildren}
        </AutocompleteListbox>
      </div>
    );
  },
);

export default memo(AutocompleteListboxWithSelectAll);
