enum DeBicValue {
  MARKDEF1 = 'MARKDEF1',
  PBNKDEFF = 'PBNKDEFF',
  QNTODEB2 = 'QNTODEB2',
  REVODEB2 = 'REVODEB2',
  TRZODEB2 = 'TRZODEB2',
  KLRNDEBE = 'KLRNDEBE',
  AARBDE5W = 'AARBDE5W',
  AFOPDEB2 = 'AFOPDEB2',
  FPEGDEB2 = 'FPEGDEB2',
  NTSBDEB1 = 'NTSBDEB1',
  TRBKDEBB = 'TRBKDEBB',
  SWNBDEBB = 'SWNBDEBB',
  HOLVDEB1 = 'HOLVDEB1',
  FNOMDEB2 = 'FNOMDEB2',
  ADYBDEB2 = 'ADYBDEB2',
  BHFBDEFF = 'BHFBDEFF',
  BFSWDE33 = 'BFSWDE33',
  HYVEDEMM = 'HYVEDEMM',
  BHYPDEB2 = 'BHYPDEB2',
  ABKBDEB1 = 'ABKBDEB1',
  LOEBDEBB = 'LOEBDEBB',
  GENODEF1 = 'GENODEF1',
  DLGHDEB1 = 'DLGHDEB1',
  SCFBDE33 = 'SCFBDE33',
  COBADEBB = 'COBADEBB',
  COBADEFF = 'COBADEFF',
  BELADEBE = 'BELADEBE',
  SKPADEB1 = 'SKPADEB1',
  LBSODEB1 = 'LBSODEB1',
  DGZFDEFF = 'DGZFDEFF',
  GENODED1 = 'GENODED1',
  DEUTDEBB = 'DEUTDEBB',
  DEUTDEDB = 'DEUTDEDB',
  NORSDE51 = 'NORSDE51',
  DRESDEFF = 'DRESDEFF',
  BEVODEBB = 'BEVODEBB',
  MACODEB1 = 'MACODEB1',
  IBBBDEBB = 'IBBBDEBB',
  QUBKDEBB = 'QUBKDEBB',
  WELADED1 = 'WELADED1',
  ISBKDEFX = 'ISBKDEFX',
  BIWBDE33 = 'BIWBDE33',
  BOFSDEB1 = 'BOFSDEB1',
  SYBKDE22 = 'SYBKDE22',
  TRDADEB1 = 'TRDADEB1',
  TRDADEBB = 'TRDADEBB',
  MPAYDEB2 = 'MPAYDEB2',
  SOBKDEBB = 'SOBKDEBB',
  SOBKDEB2 = 'SOBKDEB2',
  PNTADEBB = 'PNTADEBB',
  KFWIDEFF = 'KFWIDEFF',
  BYLADEM1 = 'BYLADEM1',
  MEFIDEMM = 'MEFIDEMM',
  NOLADE21 = 'NOLADE21',
  GENODEFF = 'GENODEFF',
  DEUTDEFF = 'DEUTDEFF',
  DEUTDEBR = 'DEUTDEBR',
  ILBXDE8X = 'ILBXDE8X',
  ESSEDEFF = 'ESSEDEFF',
  JYBADEHH = 'JYBADEHH',
  SIBSDEHH = 'SIBSDEHH',
  VGAGDEHH = 'VGAGDEHH',
  CHDBDEHH = 'CHDBDEHH',
  MCRDDEHH = 'MCRDDEHH',
  COBADEHH = 'COBADEHH',
  COBADEHD = 'COBADEHD',
  HSHNDEHH = 'HSHNDEHH',
  HASPDEHH = 'HASPDEHH',
  DEUTDEHH = 'DEUTDEHH',
  DGHYDEH1 = 'DGHYDEH1',
  EDEKDEHH = 'EDEKDEHH',
  BOTKDEH1 = 'BOTKDEH1',
  BKCHDEFF = 'BKCHDEFF',
  BEGODEHH = 'BEGODEHH',
  WBWCDEHH = 'WBWCDEHH',
  DRBKDEH1 = 'DRBKDEH1',
  GOGODEH1 = 'GOGODEH1',
  HSTBDEHH = 'HSTBDEHH',
  GREBDEH1 = 'GREBDEH1',
  BARCDEHA = 'BARCDEHA',
  MELIDEHH = 'MELIDEHH',
  SIHRDEH1 = 'SIHRDEH1',
  DNBADEHX = 'DNBADEHX',
  SXPYDEHH = 'SXPYDEHH',
  OSCBDEH1 = 'OSCBDEH1',
  MHSBDEHB = 'MHSBDEHB',
  EIHBDEHH = 'EIHBDEHH',
  UNBNDE21 = 'UNBNDE21',
  DEUTDE2H = 'DEUTDE2H',
  BRLADE21 = 'BRLADE21',
  DEHYDE2H = 'DEHYDE2H',
  CKVHDE21 = 'CKVHDE21',
  NOLADE2H = 'NOLADE2H',
  SPKHDE2H = 'SPKHDE2H',
  HALLDE2H = 'HALLDE2H',
  VOHADE2H = 'VOHADE2H',
  BHWBDE2H = 'BHWBDE2H',
  OLBODEH2 = 'OLBODEH2',
  NOLADE22 = 'NOLADE22',
  DEUTDE3B = 'DEUTDE3B',
  VOWADE2B = 'VOWADE2B',
  AUDFDE21 = 'AUDFDE21',
  SKODDE21 = 'SKODDE21',
  ECBKDE21 = 'ECBKDE21',
  SEATDE21 = 'SEATDE21',
  BCLSDE21 = 'BCLSDE21',
  FORTDEH4 = 'FORTDEH4',
  SLZODE22 = 'SLZODE22',
  DEUTDEHB = 'DEUTDEHB',
  NEELDE22 = 'NEELDE22',
  NFHBDE21 = 'NFHBDE21',
  PLUMDE29 = 'PLUMDE29',
  BRLADE22 = 'BRLADE22',
  SBREDE22 = 'SBREDE22',
  IKBDDEDD = 'IKBDDEDD',
  BOTKDEDX = 'BOTKDEDX',
  VPAYDE32 = 'VPAYDE32',
  MHCBDEDD = 'MHCBDEDD',
  CMCIDEDD = 'CMCIDEDD',
  NRWBDEDM = 'NRWBDEDM',
  PULSDEDD = 'PULSDEDD',
  CUABDED1 = 'CUABDED1',
  ETRIDE31 = 'ETRIDE31',
  TUBDDEDD = 'TUBDDEDD',
  COBADEDD = 'COBADEDD',
  WELADEDD = 'WELADEDD',
  DUSSDEDD = 'DUSSDEDD',
  GENODEDD = 'GENODEDD',
  DAAEDEDD = 'DAAEDEDD',
  DEUTDEDD = 'DEUTDEDD',
  SMBCDEDD = 'SMBCDEDD',
  DHBNDEDD = 'DHBNDEDD',
  UGBIDEDD = 'UGBIDEDD',
  RCIDDE3N = 'RCIDDE3N',
  KREDDEDD = 'KREDDEDD',
  WERHDED1 = 'WERHDED1',
  WEFZDED1 = 'WEFZDED1',
  WELADEDN = 'WELADEDN',
  MGLSDE33 = 'MGLSDE33',
  SPKRDE33 = 'SPKRDE33',
  AKFBDE31 = 'AKFBDE31',
  GGABDE31 = 'GGABDE31',
  COBADEDH = 'COBADEDH',
  WUPSDE33 = 'WUPSDE33',
  DEUTDEDW = 'DEUTDEDW',
  WELADEDR = 'WELADEDR',
  VBRSDE33 = 'VBRSDE33',
  SOLSDE33 = 'SOLSDE33',
  DUISDE33 = 'DUISDE33',
  DEUTDEDE = 'DEUTDEDE',
  NBAGDE3E = 'NBAGDE3E',
  SPESDE3E = 'SPESDE3E',
  GENODEM1 = 'GENODEM1',
  SPMHDE3E = 'SPMHDE3E',
  BNPADEFF = 'BNPADEFF',
  BUNQDE82 = 'BUNQDE82',
  AXABDE31 = 'AXABDE31',
  TOBADE33 = 'TOBADE33',
  FDBADE3K = 'FDBADE3K',
  FDBADE8F = 'FDBADE8F',
  LRFSDE31 = 'LRFSDE31',
  WWBADE3A = 'WWBADE3A',
  COLSDE33 = 'COLSDE33',
  COKSDE33 = 'COKSDE33',
  DEUTDEDK = 'DEUTDEDK',
  WELADEDL = 'WELADEDL',
  DTABDED1 = 'DTABDED1',
  VZVDDED1 = 'VZVDDED1',
  AABSDE31 = 'AABSDE31',
  AACSDE33 = 'AACSDE33',
  SDUEDE33 = 'SDUEDE33',
  MLBKDEH1 = 'MLBKDEH1',
  WELADE3M = 'WELADE3M',
  LBSWDE31 = 'LBSWDE31',
  GENODEMS = 'GENODEMS',
  WELADE3W = 'WELADE3W',
  WELADE3D = 'WELADE3D',
  DORTDE33 = 'DORTDE33',
  WELADE3H = 'WELADE3H',
  SPSHDE31 = 'SPSHDE31',
  DGPBDE3M = 'DGPBDE3M',
  WELADE3L = 'WELADE3L',
  HAUKDEFF = 'HAUKDEFF',
  SPBIDE3B = 'SPBIDE3B',
  WLAHDE44 = 'WLAHDE44',
  AKBKDEFF = 'AKBKDEFF',
  FCBKDEFF = 'FCBKDEFF',
  INGDDEFF = 'INGDDEFF',
  DEGUDEFF = 'DEGUDEFF',
  BOFADEFX = 'BOFADEFX',
  ALTEDEFA = 'ALTEDEFA',
  JTBPDEFF = 'JTBPDEFF',
  WUIDDEF1 = 'WUIDDEF1',
  LAREDEFF = 'LAREDEFF',
  FBHLDEFF = 'FBHLDEFF',
  BCITDEFF = 'BCITDEFF',
  INGBDEFF = 'INGBDEFF',
  FFBKDEFF = 'FFBKDEFF',
  HCSEDEF1 = 'HCSEDEF1',
  DEFFDEFF = 'DEFFDEFF',
  GMGGDE51 = 'GMGGDE51',
  CARDDEFF = 'CARDDEFF',
  PSADDEF1 = 'PSADDEF1',
  BPNDDE52 = 'BPNDDE52',
  PARBDEFF = 'PARBDEFF',
  DWPBDEFF = 'DWPBDEFF',
  ESBKDEFF = 'ESBKDEFF',
  TRODDEF1 = 'TRODDEF1',
  OPENDEFF = 'OPENDEFF',
  ABOCDEFF = 'ABOCDEFF',
  COBADEF1 = 'COBADEF1',
  HELADEFF = 'HELADEFF',
  HELADEF1 = 'HELADEF1',
  GENODE55 = 'GENODE55',
  GENODE51 = 'GENODE51',
  DEUTDE5X = 'DEUTDE5X',
  ICBKDEFF = 'ICBKDEFF',
  AUSKDEFF = 'AUSKDEFF',
  NATXDEFF = 'NATXDEFF',
  SCBLDEFX = 'SCBLDEFX',
  FBGADEF1 = 'FBGADEF1',
  CHASDEFX = 'CHASDEFX',
  JPMGDEFF = 'JPMGDEFF',
  MNBIDEF1 = 'MNBIDEF1',
  MAIFDEFF = 'MAIFDEFF',
  ICICDEFF = 'ICICDEFF',
  DELBDE33 = 'DELBDE33',
  CRESDE55 = 'CRESDE55',
  COMMDEFF = 'COMMDEFF',
  BMPBDEF2 = 'BMPBDEF2',
  TVBADEFF = 'TVBADEFF',
  BPKODEFF = 'BPKODEFF',
  PAYODEF2 = 'PAYODEF2',
  NBPADEFF = 'NBPADEFF',
  UBSWDEFF = 'UBSWDEFF',
  ICBVDEFF = 'ICBVDEFF',
  FFVBDEFF = 'FFVBDEFF',
  RABODEFF = 'RABODEFF',
  PRCBDEFF = 'PRCBDEFF',
  CITIDEFF = 'CITIDEFF',
  SMHBDEFF = 'SMHBDEFF',
  INVODEF2 = 'INVODEF2',
  HVBKDEFF = 'HVBKDEFF',
  BOFSDEF1 = 'BOFSDEF1',
  CAIXDEFF = 'CAIXDEFF',
  ABCADEFF = 'ABCADEFF',
  METZDEFF = 'METZDEFF',
  PLFGDE5A = 'PLFGDE5A',
  KTAGDEFF = 'KTAGDEFF',
  DLFGDE51 = 'DLFGDE51',
  BARCDEFF = 'BARCDEFF',
  PCBCDEFF = 'PCBCDEFF',
  OWHBDEFF = 'OWHBDEFF',
  BSCHDEFF = 'BSCHDEFF',
  BCMADEFF = 'BCMADEFF',
  PICTDEFF = 'PICTDEFF',
  FTSBDEFA = 'FTSBDEFA',
  SBINDEFF = 'SBINDEFF',
  MHBFDEFF = 'MHBFDEFF',
  RAISDEFF = 'RAISDEFF',
  IRVTDEFX = 'IRVTDEFX',
  BCDMDEF1 = 'BCDMDEF1',
  SEPBDEFF = 'SEPBDEFF',
  BNYMDEF1 = 'BNYMDEF1',
  PANXDEF2 = 'PANXDEF2',
  MARKDEFF = 'MARKDEFF',
  SMBCDEFF = 'SMBCDEFF',
  FDDODEMM = 'FDDODEMM',
  RBMFDEF1 = 'RBMFDEF1',
  DZBMDEF1 = 'DZBMDEF1',
  BBSPDE6K = 'BBSPDE6K',
  MKGMDE51 = 'MKGMDE51',
  NASSDE55 = 'NASSDE55',
  PULSDE5W = 'PULSDE5W',
  WIBADE5W = 'WIBADE5W',
  VRBUDE51 = 'VRBUDE51',
  NZFMDEF1 = 'NZFMDEF1',
  SOGEDEFF = 'SOGEDEFF',
  SGSSDEFF = 'SGSSDEFF',
  NATXDEFP = 'NATXDEFP',
  TCZBDEFF = 'TCZBDEFF',
  BRASDEFF = 'BRASDEFF',
  MSFFDEFX = 'MSFFDEFX',
  ARABDEFF = 'ARABDEFF',
  SKGIDE5F = 'SKGIDE5F',
  VBMHDE5F = 'VBMHDE5F',
  BOFADEFF = 'BOFADEFF',
  BOURDEFF = 'BOURDEFF',
  MIBEDEFF = 'MIBEDEFF',
  BAERDEF1 = 'BAERDEF1',
  GOLDDEFF = 'GOLDDEFF',
  GOLDDEFB = 'GOLDDEFB',
  SABCDEFF = 'SABCDEFF',
  RBAGDEF1 = 'RBAGDEF1',
  KOEXDEFA = 'KOEXDEFA',
  BSUIDEFF = 'BSUIDEFF',
  SHBKDEFF = 'SHBKDEFF',
  ABGRDEFF = 'ABGRDEFF',
  SECGDEFF = 'SECGDEFF',
  CMCIDEF1 = 'CMCIDEF1',
  SCRUDE51 = 'SCRUDE51',
  MALADE51 = 'MALADE51',
  GENODE61 = 'GENODE61',
  DEUTDESM = 'DEUTDESM',
  LUHSDE6A = 'LUHSDE6A',
  SOLADES1 = 'SOLADES1',
  ISBRDE55 = 'ISBRDE55',
  BKMZDE51 = 'BKMZDE51',
  IMMODE5M = 'IMMODE5M',
  SUFGDE51 = 'SUFGDE51',
  CPLADE55 = 'CPLADE55',
  SOLADEST = 'SOLADEST',
  DEUTDE5M = 'DEUTDE5M',
  MVBMDE55 = 'MVBMDE55',
  BILADE55 = 'BILADE55',
  MKBKDE51 = 'MKBKDE51',
  OYAKDE5K = 'OYAKDE5K',
  DEBKDE51 = 'DEBKDE51',
  TRISDE55 = 'TRISDE55',
  SIKBDE55 = 'SIKBDE55',
  MEGHDE81 = 'MEGHDE81',
  SALADE55 = 'SALADE55',
  SAKSDE55 = 'SAKSDE55',
  SALADE51 = 'SALADE51',
  SABADE5S = 'SABADE5S',
  KRSADE55 = 'KRSADE55',
  MERZDE55 = 'MERZDE55',
  LKBWDE6K = 'LKBWDE6K',
  SCHWDESS = 'SCHWDESS',
  MEBEDE6S = 'MEBEDE6S',
  BHBADES1 = 'BHBADES1',
  ELGEDES1 = 'ELGEDES1',
  CPLUDES1 = 'CPLUDES1',
  AKBADES1 = 'AKBADES1',
  TRUFDE66 = 'TRUFDE66',
  BSWLDE61 = 'BSWLDE61',
  IBKBDES1 = 'IBKBDES1',
  GENODESG = 'GENODESG',
  GENODEST = 'GENODEST',
  GENODES1 = 'GENODES1',
  DEUTDESS = 'DEUTDESS',
  VOBADESS = 'VOBADESS',
  SWBSDESS = 'SWBSDESS',
  BBKRDE6B = 'BBKRDE6B',
  WBAGDE61 = 'WBAGDE61',
  WBAGDEA1 = 'WBAGDEA1',
  SABUDES1 = 'SABUDES1',
  MARBDE6G = 'MARBDE6G',
  GOPSDE6G = 'GOPSDE6G',
  ESSLDE66 = 'ESSLDE66',
  OASPDE6A = 'OASPDE6A',
  HOEBDE61 = 'HOEBDE61',
  HEISDE66 = 'HEISDE66',
  BSHHDE61 = 'BSHHDE61',
  ULMVDE66 = 'ULMVDE66',
  VBRTDE6R = 'VBRTDE6R',
  IBBFDE81 = 'IBBFDE81',
  SBCRDE66 = 'SBCRDE66',
  KARSDE66 = 'KARSDE66',
  GENODE6K = 'GENODE6K',
  VBRADE6K = 'VBRADE6K',
  BRUSDE66 = 'BRUSDE66',
  FAITDE66 = 'FAITDE66',
  DEUTDE6F = 'DEUTDE6F',
  PZHSDE66 = 'PZHSDE66',
  VBPFDE66 = 'VBPFDE66',
  MANSDE66 = 'MANSDE66',
  MLPBDE61 = 'MLPBDE61',
  BKMADE61 = 'BKMADE61',
  FRSPDE66 = 'FRSPDE66',
  SKLODE66 = 'SKLODE66',
  VOLODE66 = 'VOLODE66',
  SKHRDE6W = 'SKHRDE6W',
  REBMDEMM = 'REBMDEMM',
  REBMDE7C = 'REBMDE7C',
  KHMIDEMM = 'KHMIDEMM',
  VONTDEM1 = 'VONTDEM1',
  BFWODE71 = 'BFWODE71',
  SIBADEMM = 'SIBADEMM',
  WEGBDE77 = 'WEGBDE77',
  ICRDDE71 = 'ICRDDE71',
  CLABDEM1 = 'CLABDEM1',
  VBANDEMM = 'VBANDEMM',
  FLGMDE77 = 'FLGMDE77',
  SUSKDEM1 = 'SUSKDEM1',
  DEPDDEMM = 'DEPDDEMM',
  FXBBDEM2 = 'FXBBDEM2',
  EBSGDEMX = 'EBSGDEMX',
  EFSGDEM1 = 'EFSGDEM1',
  CSHHDE71 = 'CSHHDE71',
  HERZDEM1 = 'HERZDEM1',
  TEZGDEB1 = 'TEZGDEB1',
  PAGMDEM1 = 'PAGMDEM1',
  WKVBDEM1 = 'WKVBDEM1',
  FUBKDE71 = 'FUBKDE71',
  BHLSDEM1 = 'BHLSDEM1',
  GAKDDEM1 = 'GAKDDEM1',
  BDWBDEMM = 'BDWBDEMM',
  BYLADEMM = 'BYLADEMM',
  DEUTDEMM = 'DEUTDEMM',
  MHYPDEMM = 'MHYPDEMM',
  SBOSDEMX = 'SBOSDEMX',
  DABBDEMM = 'DABBDEMM',
  FMBKDEMM = 'FMBKDEMM',
  AGBMDEMM = 'AGBMDEMM',
  OBKLDEMX = 'OBKLDEMX',
  SSKMDEMM = 'SSKMDEMM',
  LFFBDEMM = 'LFFBDEMM',
  BMWBDEMU = 'BMWBDEMU',
  BTVADE61 = 'BTVADE61',
  ANHODE77 = 'ANHODE77',
  ANHODE7A = 'ANHODE7A',
  AUGSDE77 = 'AUGSDE77',
  GABLDE71 = 'GABLDE71',
  RZOODE77 = 'RZOODE77',
  TEKRDE71 = 'TEKRDE71',
  CBSRDE71 = 'CBSRDE71',
  NORSDE71 = 'NORSDE71',
  CSDBDE71 = 'CSDBDE71',
  TEAMDE71 = 'TEAMDE71',
  UMWEDE7N = 'UMWEDE7N',
  SSKNDE77 = 'SSKNDE77',
  FODBDE77 = 'FODBDE77',
  FUCEDE77 = 'FUCEDE77',
  BSHADE71 = 'BSHADE71',
  FLESDEMM = 'FLESDEMM',
  DEUTDE8M = 'DEUTDE8M',
  ERFBDE8E = 'ERFBDE8E',
  DEUTDE8E = 'DEUTDE8E',
  SABDDE81 = 'SABDDE81',
  OSDDDE81 = 'OSDDDE81',
  WELADE8L = 'WELADE8L',
  DEUTDE8L = 'DEUTDE8L',
  CHEKDE81 = 'CHEKDE81',
  DEUTDE8C = 'DEUTDE8C',
}

export default DeBicValue;
