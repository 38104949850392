import { Field, FieldProps, useFormikContext } from 'formik';

import SelectCRMJoy, { SelectCRMJoyProps } from '../select/crm/SelectCRMJoy';
import { CrmAutoCompleteOption } from '~/common/types';

type Props<Multiple extends boolean = false> = Omit<
  SelectCRMJoyProps,
  'value' | 'onChange' | 'error'
> & {
  name: string;
  multiple?: boolean;
  callback?: Multiple extends true
    ? (value: CrmAutoCompleteOption[] | null) => void
    : (value: CrmAutoCompleteOption | null) => void;
};

const FormikCrmSelectorJoy = <Multiple extends boolean = false>({
  name,
  multiple = false,
  callback,
  ...rest
}: Props<Multiple>) => {
  const { errors, values, touched, setFieldValue } = useFormikContext<{
    [name: string]: CrmAutoCompleteOption | CrmAutoCompleteOption[] | null;
  }>();

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <SelectCRMJoy
          name={name}
          error={touched[name] && errors[name] ? errors[name] : ''}
          onBlur={field.onBlur}
          multiple={multiple}
          value={
            multiple
              ? (values[name] as CrmAutoCompleteOption[])
              : (values[name] as CrmAutoCompleteOption | null)
          }
          onChange={(option: CrmAutoCompleteOption[] | CrmAutoCompleteOption | null) => {
            setFieldValue(name, option);
            if (callback) {
              if (multiple) {
                (callback as (options: CrmAutoCompleteOption[] | null) => void)(
                  option as CrmAutoCompleteOption[] | null,
                );
              } else {
                (callback as (options: CrmAutoCompleteOption | null) => void)(
                  option as CrmAutoCompleteOption | null,
                );
              }
            }
          }}
          {...rest}
        />
      )}
    </Field>
  );
};

export default FormikCrmSelectorJoy;
