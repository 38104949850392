import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

type Options = {
  callback?: (res: any) => void;
};

const useCreateCase = (options: Options) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation({
    mutationFn: caseApi.createCase,
    onError: sendDefaultError,
    onSuccess: (res) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASES]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyAdded', { type: t('_case.title') }),
        variant: 'success',
      });

      if (options.callback) {
        options.callback(res);
      }
    },
  });
  return {
    createCase: mutate,
    isCreatingCase: isLoading,
  };
};

export default useCreateCase;
