import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '../../2.0/Autocomplete';
import { FileContext } from '@/FileContext';
import Row from '../../2.0/layout/Row';

type Props = {
  changeFilter: (data: {
    name: string;
    type: string;
    value: { label: string; value: string };
  }) => void;
  filters: {
    autocomplete: {
      type: { label: string; value: string }[];
    };
  };
};

const DocumentTypeFilter: React.FC<Props> = ({ changeFilter, filters }) => {
  const { t } = useTranslation();
  const { files } = useContext(FileContext);

  const options = useMemo(
    () =>
      [
        ...new Set(
          (files as { type: string }[]).filter((file) => file.type).map((file) => file.type),
        ),
      ].map((type) => ({
        label: type,
        value: type,
      })),
    [files],
  );

  return (
    <Row>
      <Autocomplete
        multiple
        label={t('_tag.filters.type.label')}
        name="type"
        onChange={changeFilter}
        options={options}
        // @ts-ignore
        placeholder={t('_tag.filters.type.placeholder')}
        // @ts-ignore
        value={filters.autocomplete.type}
      />
    </Row>
  );
};

export default DocumentTypeFilter;
