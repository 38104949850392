import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const KpcTooltip = ({ children, ...rest }) => (
  <Tooltip arrow placement="top" {...rest}>
    <div className="d-inline">{children}</div>
  </Tooltip>
);

export default KpcTooltip;

KpcTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};
