import { getIn, useFormikContext } from 'formik';

import { Nullable, TAutocompleteOption } from '~/common/types';
import Autocomplete from '../autocomplete/AutoComplete';

type Props = {
  freeSolo?: boolean;
  isRequired?: boolean;
  multiple?: boolean;
  name: string;
  label: string;
  onChangeCallback?: (value: any) => void;
  options: TAutocompleteOption<any>[];
  disabled?: boolean;
  [key: string]: unknown;
};

const FormikAutocomplete: React.FC<Props> = ({
  freeSolo = false,
  isRequired = false,
  label,
  multiple = false,
  name,
  onChangeCallback = () => {},
  options,
  disabled = false,
  ...rest
}) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();

  const onChange = ({
    value,
  }: {
    value: Nullable<TAutocompleteOption<unknown>> | TAutocompleteOption<unknown>[];
  }) => {
    if (freeSolo) {
      setFieldValue(name, value);
      onChangeCallback(value);
      return;
    }

    if (multiple) {
      const vals = (value as TAutocompleteOption<unknown>[]).map((v) => v.value);
      setFieldValue(name, vals);
      onChangeCallback(vals);
      return;
    }
    const val = (value as Nullable<TAutocompleteOption<unknown>>)?.value;
    setFieldValue(name, val);
    onChangeCallback(val);
  };

  const getValue = () => {
    const currentValue = getIn(values, name);

    if (multiple && currentValue) {
      return options.filter((o) => currentValue.includes(o.value));
    }

    if (freeSolo) {
      return getIn(values, name);
    }

    return options.find(
      (o) => o.value === currentValue || (o.value?.key && o.value.key === currentValue?.key),
    );
  };

  const getError: (errorObj: string | object | null | undefined) => string | undefined = (
    errorObj,
  ) => {
    if (!errorObj) {
      return undefined;
    }
    if (typeof errorObj === 'string' || errorObj instanceof String) {
      return errorObj as string;
    }
    if (Object.values(errorObj).length) {
      return getError(Object.values(errorObj)[0]);
    }
    return undefined;
  };

  return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      name={name}
      label={label}
      multiple={multiple}
      isRequired={isRequired}
      freeSolo={freeSolo}
      onBlur={() => setFieldTouched(name, true)}
      options={options}
      value={getValue() ?? null}
      error={getIn(touched, name) && !!getIn(errors, name)}
      onChange={onChange}
      helperText={getIn(touched, name) ? getError(getIn(errors, name)) : undefined}
    />
  );
};

export default FormikAutocomplete;
