import { useQuery } from '@tanstack/react-query';

import contactApi from '@/shared/api/contact/contact';
import { formatContactDisplayName } from '~/common/utils/utils';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetContacts = (options = {}) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: options.enabled ?? true,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await contactApi.getContacts();

      const formatted = result.map((contact) => ({
        label: formatContactDisplayName(contact),
        value: contact.id,
      }));

      return { formatted, result };
    },
    queryKey: [queryIds.contacts.GET_CONTACTS],
  });

  return {
    contacts: data?.result || [],
    contactsAutocomplete: data?.formatted || [],
    contactsIsLoading: isLoading,
  };
};

export default useGetContacts;
