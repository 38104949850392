import { createContext } from 'react';

import {
  BuildingTagForBuilding,
  FormattedBuildingUnit,
  SimpleBuilding,
  SimpleBuildingRelation,
} from '~/common/types/building';
import { components } from '~/common/types/kpc.d';

type BuildingPortalPushNotificationSettings =
  components['schemas']['KeypointConnect.Messaging.API.Settings.V1.PushNotifications.DTOs.Responses.Abstract.IBuildingPortalPushNotificationSettingsResponse'];

export type SimpleHomeownerAssociationResponse =
  components['schemas']['KeypointConnect.Api.Apis.V1.DTOs.HomeownerAssociations.Responses.SimpleHomeownerAssociationResponse'];

type BuildingContext = {
  buildingSummary?: SimpleBuilding;
  buildingSummaryIsLoading: boolean;
  homeownerAssociation?: SimpleHomeownerAssociationResponse;
  invalidateRelations: () => void;
  invalidateUnits: () => void;
  pushNotificationSettings?: BuildingPortalPushNotificationSettings;
  relations: SimpleBuildingRelation[];
  relationsAreLoading: boolean;
  tags: BuildingTagForBuilding[];
  tagsAreLoading: boolean;
  units: FormattedBuildingUnit[];
  unitsAreLoading: boolean;
};

const BuildingContext = createContext<BuildingContext>({
  buildingSummaryIsLoading: true,
  invalidateRelations: () => {},
  invalidateUnits: () => {},
  relations: [],
  relationsAreLoading: false,
  tags: [],
  tagsAreLoading: true,
  units: [],
  unitsAreLoading: true,
});

export default BuildingContext;
