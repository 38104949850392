import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TagType from '../../../enums/TagType';

const TagTypeFilter = ({ callback, name, defaultValue }) => {
  const { t } = useTranslation('common');
  const allPosibleTagTypes = [TagType.Building, TagType.Company, TagType.Contact];

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={allPosibleTagTypes}
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(option) => t(`common:${option?.toLowerCase()}`)}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={t('common:type')} />
      )}
    />
  );
};

TagTypeFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
};

TagTypeFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
};

export default TagTypeFilter;
