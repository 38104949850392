import config from '@/config/config';
import createRestClient from '../restClient';

const client = createRestClient(config.baseURL);

const deleteExportTemplate = (id) => client.delete(`/export/template/${id}`);

const restClient = createRestClient();

const createExportTemplate = (data) => restClient.post('exportTemplate', data);

const updateExportTemplate = (data) => restClient.put('exportTemplate', data);

const getExportTemplate = (id) => restClient.get(`exportTemplate/${id}`);

const getExportTemplates = () => restClient.get('exportTemplate/list.all');

export default {
  createExportTemplate,
  deleteExportTemplate,
  getExportTemplate,
  getExportTemplates,
  updateExportTemplate,
};
