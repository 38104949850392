import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { isNullOrEmpty } from '../../../utils/helpers';
import GenericEditor from './GenericEditor';

const innerComponentRender = ({
  updateValue,
  isAddingNew,
  disableAutoFocus,
  value,
  validationMessage,
  required,
  label,
  multiple,
  freeSolo,
  getValue,
  t,
  rest,
}) => (
  <Autocomplete
    {...rest}
    noOptionsText={t('common:noOptions')}
    multiple={multiple}
    onChange={(e, selected) => {
      if (selected === value || freeSolo) return;
      if (Array.isArray(selected)) {
        updateValue(selected.map((val) => getValue(val)));
        return;
      }
      updateValue(getValue(selected));
    }}
    onInputChange={(_, data) => {
      if (data === value) return;
      if (!freeSolo) {
        return;
      }
      updateValue(data);
    }}
    value={value}
    renderInput={(params) => (
      <TextField
        autoFocus={!isAddingNew && !disableAutoFocus}
        label={label ?? t('common:search.label')}
        variant="standard"
        error={!isNullOrEmpty(validationMessage)}
        helperText={validationMessage ?? ''}
        required={required}
        {...params}
      />
    )}
  />
);

const AutocompleteEditor = (props) => {
  const {
    column,
    rowKeyValue,
    dispatch,
    value,
    validationMessage,
    label,
    freeSolo,
    multiple,
    getValue,
    hideSaveButtons,
    disableAutoFocus,
    required,
    ...rest
  } = props;

  const { t } = useTranslation('common');

  return (
    <GenericEditor
      column={column}
      rowKeyValue={rowKeyValue}
      dispatch={dispatch}
      innerComponentRender={({ updateValue, isAddingNew }) =>
        innerComponentRender({
          updateValue,
          isAddingNew,
          disableAutoFocus,
          value,
          validationMessage,
          required,
          label: column.title,
          multiple,
          freeSolo,
          getValue,
          t,
          rest,
        })
      }
      hideSaveButtons={hideSaveButtons}
    />
  );
};

export default AutocompleteEditor;

AutocompleteEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rowKeyValue: PropTypes.any.isRequired,
  value: PropTypes.string,
  validationMessage: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  hideSaveButtons: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  required: PropTypes.bool,
};

AutocompleteEditor.defaultProps = {
  value: null,
  validationMessage: null,
  label: null,
  freeSolo: false,
  multiple: false,
  hideSaveButtons: false,
  disableAutoFocus: false,
  required: false,
};
