import { IconButton, Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { checkFileCanBePreviewed, getFileTypeIconForContentType } from '@/shared/utils/helpers';
import { LockIcon } from '@/shared/icons/Icons';

type Props = {
  rowData: {
    id: string;
    contentType: string;
    readOnly: boolean;
  };
  value: string;
  openDocumentPreview: (rowId: string) => void;
};

const ContentTypeCustomCell: React.FC<Props> = ({ rowData, value, openDocumentPreview }) => {
  const { t } = useTranslation();
  const icon = getFileTypeIconForContentType(value);
  const isFilePreviewable = checkFileCanBePreviewed(rowData.contentType);

  return (
    <div className="flex items-center justify-end space-x-2">
      {rowData.readOnly && (
        <Tooltip title={t('readOnly')}>
          <div>
            <LockIcon />
          </div>
        </Tooltip>
      )}
      {isFilePreviewable ? (
        <IconButton size="md" onClick={() => openDocumentPreview(rowData.id)} variant="plain">
          <FontAwesomeIcon icon={['fad', icon as IconName]} />
        </IconButton>
      ) : (
        <div className="flex h-10 w-10 items-center justify-center">
          <Tooltip title={t('unsupportedFileType')}>
            <FontAwesomeIcon icon={['fad', icon as IconName]} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default memo(ContentTypeCustomCell);
