import PropTypes from 'prop-types';

import EnumSelector from '../../select/EnumSelector';

const propTypes = {
  callback: PropTypes.func,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      teamName: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
};

const CustomEnumSelectFilter = ({
  callback = () => {},
  name = '',
  defaultValue = [],
  label = '',
  options,
}) => (
  <EnumSelector
    defaultValue={defaultValue}
    enumValues={options}
    label={label}
    multiple
    onChange={(values) => callback(name, values)}
  />
);

CustomEnumSelectFilter.propTypes = propTypes;

export default CustomEnumSelectFilter;
