import PropTypes from 'prop-types';
import Language from '../enums/Language';
import StatusType from '../enums/StatusType';
import CaseTypePropTypes from './CaseTypePropTypes';

const CaseStatusPropTypes = PropTypes.shape({
  id: PropTypes.string,
  isSystemDefault: PropTypes.bool,
  active: PropTypes.bool,
  value: PropTypes.string,
  caseType: CaseTypePropTypes,
  statusType: PropTypes.oneOf([
    StatusType.Cancelled,
    StatusType.Closed,
    StatusType.Open,
    StatusType.Pending,
  ]),
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.oneOf([Language.Dutch, Language.English, Language.French]),
      description: PropTypes.string,
    }),
  ),
});

export default CaseStatusPropTypes;
