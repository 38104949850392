import { Button, Checkbox } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CancelIcon, InlineSpinner, SaveIcon } from '@/shared/icons/Icons';

import { sentencize, setOpenCaseInNewTabValue } from '@/shared/utils/helpers';
import { ToggleProps } from '@/types';

type Props = {
  toggles: {
    modal: ToggleProps;
    openCaseInNewTab: ToggleProps;
    redirect: ToggleProps;
    pushNotification?: ToggleProps;
    showOnPortal?: ToggleProps;
    task: ToggleProps;
  };
  isMutating: boolean;
};

const AddCaseActions: React.FC<Props> = ({ toggles, isMutating }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between pt-4">
      <div className="flex flex-wrap gap-4">
        <Checkbox
          label={sentencize(t('addType', { type: t('task') }))}
          checked={toggles.task.value}
          onChange={toggles.task.inverse}
        />
        <Checkbox
          label={t('openCaseAfterSaving')}
          checked={toggles.redirect.value}
          onChange={(e) => {
            if (e.target.checked) {
              toggles.openCaseInNewTab.hide();
              setOpenCaseInNewTabValue(!e.target.checked);
            }
            toggles.redirect.inverse();
          }}
        />
        <Checkbox
          label={t('openCaseInNewTabAfterSaving')}
          checked={toggles.openCaseInNewTab.value}
          onChange={(e) => {
            if (e.target.checked) {
              toggles.redirect.hide();
            }
            toggles.openCaseInNewTab.inverse();
            setOpenCaseInNewTabValue(e.target.checked);
          }}
        />

        {!!toggles.showOnPortal && (
          <Checkbox
            label={t('showOnPortal')}
            checked={toggles.showOnPortal.value}
            onChange={(e) => {
              if (!e.target.checked) {
                toggles.pushNotification?.hide();
              }
              toggles.showOnPortal?.inverse();
            }}
          />
        )}

        {!!toggles.pushNotification && (
          <Checkbox
            label={t('sendPushNotification')}
            checked={toggles.pushNotification.value}
            onChange={toggles.pushNotification.inverse}
          />
        )}
      </div>
      <div className="flex space-x-4">
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<CancelIcon />}
          onClick={toggles.modal.hide}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          startDecorator={isMutating ? <InlineSpinner /> : <SaveIcon />}
          disabled={isMutating}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

export default AddCaseActions;
