import { Field } from 'formik';
import PropTypes from 'prop-types';

import SelectEmailContact from '../select/SelectEmailContact';

const FormikEmailContactSelector = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue, handleBlur }, meta: { error, touched } }) => (
      <SelectEmailContact
        name={name}
        value={value}
        onChange={(val) => {
          setFieldValue(name, val);
        }}
        onBlur={handleBlur}
        error={!!(touched && error)}
        helperText={touched && error ? error : ''}
        {...rest}
      />
    )}
  </Field>
);

FormikEmailContactSelector.propTypes = {
  addressOnly: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FormikEmailContactSelector;
