import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CancelIcon, SaveIcon } from '../../../../../shared/icons/Icons';
import KpcModal from '../../../../../shared/components/2.0/layout/KpcModal';
import MandateFormBody from '../../../Repair/MandateFormBody';
import TogglePropTypes from '../../../../../shared/prop-types/TogglePropTypes';
import { useGetMandate } from '../../../../../queries';
import { useUpdateMandate } from '../../../../../mutations';
import VatCode from '../../../../../shared/enums/VatCode';

const propTypes = {
  caseId: PropTypes.string.isRequired,
  toggle: TogglePropTypes,
};

const EditMandateModal = ({ caseId, toggle }) => {
  const { t } = useTranslation('common');

  const { repairMandate } = useGetMandate(caseId);
  const { isSubmittingMandate, updateMandate } = useUpdateMandate({ callback: toggle.hide });

  const onSubmit = (formData) => {
    if (isSubmittingMandate) return;

    const { percentageVatRecovery, ...rest } = formData;
    updateMandate({ caseId, vatRecoveryPercentage: percentageVatRecovery, ...rest });
  };

  return (
    <KpcModal disablePortal toggle={toggle} title={t('editType', { type: t('mandate') })}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          buildingOlderThan10Years: yup.bool(),
          percentageVatRecovery: yup
            .number()
            .nullable()
            .when('vatDeductible', {
              is: true,
              then: yup
                .number()
                .nullable()
                .required(t('errors:fieldIsRequired'))
                .min(1, t('errors:minValueX', { val: 1 }))
                .max(100, t('errors:maxValueX', { val: 100 })),
            }),
          vatDeductible: yup.bool(),
          vatNumber: yup
            .string()
            .nullable()
            .when('vatDeductible', {
              is: true,
              then: yup
                .string()
                .nullable()
                .required(t('errors:xIsRequired', { field: t('common:validVatNumber') })),
            }),
        })}
        initialValues={{
          buildingOlderThan10Years: repairMandate?.buildingOlderThan10Years ?? false,
          corporateForm: repairMandate?.corporateForm,
          percentageVatRecovery: repairMandate?.vatRecoveryPercentage ?? 100,
          vatDeductible: repairMandate?.vatDeductible ?? false,
          vatNumber: repairMandate?.vatNumber ?? '',
          vatPercentage: repairMandate?.vatPercentage ?? VatCode.InlandExclusive21,
        }}
      >
        <Form>
          <MandateFormBody />
          <div className="flex justify-end space-x-4 pt-4">
            <Button
              startDecorator={<CancelIcon />}
              size="sm"
              variant="soft"
              color="neutral"
              onClick={toggle.hide}
            >
              {t('buttons.cancel')}
            </Button>
            <Button startDecorator={<SaveIcon />} size="sm" variant="solid" type="submit">
              {t('buttons.save')}
            </Button>
          </div>
        </Form>
      </Formik>
    </KpcModal>
  );
};

EditMandateModal.propTypes = propTypes;

export default EditMandateModal;
