enum DamageType {
  AccidentAtWork = 'AccidentAtWork',
  Electricity = 'Electricity',
  FireOrSmoke = 'FireOrSmoke',
  Glass = 'Glass',
  Others = 'Others',
  StormWeatherNaturalDisasters = 'StormWeatherNaturalDisasters',
  Theft = 'Theft',
  Vandalism = 'Vandalism',
  Water = 'Water',
}

export default DamageType;
