import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types/index';

type Props = {
  npsScore: Nullable<number>;
};

const NpsScore: React.FC<Props> = ({ npsScore }) => {
  const { t } = useTranslation();

  return (
    <Typography>
      {t('npsScore')} {npsScore}/10
    </Typography>
  );
};

export default NpsScore;
