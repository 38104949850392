import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  ChipDelete,
  ListItemContent,
  ListItemDecorator,
  Tooltip,
} from '@mui/joy';
import classNames from 'classnames';

import { UserOption } from '@/types/trpc/team';

import { trpc } from '@/config/trpc';
import { useAutocompleteOptions } from '@/shared/hooks/autocomplete';

import { CancelIcon, InlineSpinner } from '@/shared/icons/Icons';
import AvatarWithColor from '../AvatarWithColor';

type BaseProps = {
  className?: string;
  limitTags?: number;
  onBlur?: (e: React.FocusEvent<HTMLDivElement, Element>) => void;
};

type SingleProps = BaseProps & {
  multiple?: false;
  onChange: (value: UserOption | null) => void;
  selected?: string | null;
};

type MultiProps = BaseProps & {
  multiple: true;
  onChange: (value: UserOption[]) => void;
  selected: string[];
};

type Props = SingleProps | MultiProps;

const TeamUsersAutocomplete: React.FC<Props> = ({
  className,
  limitTags = 6,
  multiple,
  onBlur,
  onChange,
  selected,
}) => {
  const usersRes = trpc.team.user.autocomplete.useQuery();

  const dropOption = (selectedOptions: UserOption[], id: string) => {
    const newOptions = selectedOptions.filter((option) => option.value !== id);

    if (multiple) {
      onChange(newOptions);
    } else {
      onChange(null);
    }
  };

  const handleCallback = (value: UserOption[] | UserOption | null) => {
    const multipleOptions = Array.isArray(value);

    if (multiple && multipleOptions) {
      onChange(value);
    } else if (!multiple && !multipleOptions) {
      onChange(value);
    }
  };

  const userAutocompleteProps = useAutocompleteOptions({
    ...usersRes,
    callback: handleCallback,
    multiple,
    selected,
  });

  return (
    <Autocomplete
      {...userAutocompleteProps}
      className={classNames({ 'min-h-10': multiple }, className)}
      limitTags={limitTags}
      size="md"
      startDecorator={userAutocompleteProps.loading && <InlineSpinner />}
      onBlur={onBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={option.value} arrow placement="top-start" title={option.label}>
            <Chip
              {...getTagProps({ index })}
              className="rounded-full p-1"
              color="neutral"
              endDecorator={
                <ChipDelete
                  className="mr-0.5"
                  color="neutral"
                  onClick={() => dropOption(value, option.value)}
                >
                  <CancelIcon className="h-[1.125rem]" />
                </ChipDelete>
              }
              variant="soft"
            >
              <AvatarWithColor size="sm" className="mr-1" name={option.label} />
            </Chip>
          </Tooltip>
        ))
      }
      renderOption={(props, option) => (
        <AutocompleteOption key={option.value} {...props} className="py-2">
          <ListItemDecorator>
            <AvatarWithColor name={option.label} size="sm" />
          </ListItemDecorator>
          <ListItemContent>{option.label}</ListItemContent>
        </AutocompleteOption>
      )}
    />
  );
};

export default TeamUsersAutocomplete;
