import 'ka-table/style.css';
import { ActionType, SortingMode } from 'ka-table/enums';
import {
  closeRowEditors,
  hideLoading,
  hideNewRow,
  saveNewRow,
  selectSingleRow,
  showLoading,
  showNewRow,
  updateData,
} from 'ka-table/actionCreators';
import { kaReducer, Table } from 'ka-table';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kaPropsUtils } from 'ka-table/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { arraysAreEqual, isIterable, isNullOrEmpty } from '../../utils/helpers';
import { CancelIcon, SaveIcon } from '../../icons/Icons';
import IconButton from '../buttons/IconButton';
import TableActionBar from './TableActionBar';

const NEW_ROW_KEY = '-1';

const initDynamicRowsOptions = () => {
  const renderedRowSizes = {};
  const estimatedItemSize = 54;

  return ({ rowKeyField }) => {
    const addRowHeight = (rowData, height) => {
      if (height) {
        renderedRowSizes[rowData[rowKeyField]] = height;
      }
    };
    return {
      addRowHeight,
      itemHeight: (rowData) => renderedRowSizes[rowData[rowKeyField]] || estimatedItemSize,
    };
  };
};
const dynamicRowsOptions = initDynamicRowsOptions();

const KeypointTable = ({
  defaultOptions,
  formattedData,
  childComponents,
  filters,
  localStorageKey,
  onAdd,
  onAddNewRow,
  onUpdateRow,
  height,
  hideDisplayMenu,
  hideSearchField,
  hideActionbar,
  fullHeight,
  bordered,
  topBarButtons,
  className,
  disableVirtualScrolling,
  loading,
  delay,
  onSingleRowSelect,
  isAddInIdColumn,
  onAddCustomRender,
  loadMoreData,
}) => {
  const [predicates, updatePredicates] = useState([]);
  const [cachedFilterValues, setCachedFilterValues] = useState([]);
  const [initialCacheRead, setInitialCacheRead] = useState(false);
  const [filtersInited, setFiltersInited] = useState(false);
  const [tableHeight, setTableHeight] = useState(height);

  useEffect(() => {
    if (!filtersInited && filters != null && filters.length > 0) {
      updatePredicates(filters.map((f) => ({ ...f, value: f.defaultValue })));
      setFiltersInited(true);
    }
  }, [filters, filtersInited]);

  const cachedFiltersInitializedCallback = React.useCallback((cachedValues) => {
    setCachedFilterValues(cachedValues);
  }, []);

  React.useEffect(() => {
    if (!filtersInited || initialCacheRead) {
      return;
    }
    setInitialCacheRead(true);
    updatePredicates((currentPredicates) => [
      ...currentPredicates.map((predicateObject) => {
        const cached = cachedFilterValues[predicateObject.name];
        if (cached == null) {
          return predicateObject;
        }
        const { predicate, defaultValue, visibilityDependentOnOtherFilter } = predicateObject;
        return {
          defaultValue,
          name: predicateObject.name,
          predicate,
          value: cached,
          visibilityDependentOnOtherFilter,
        };
      }),
    ]);
  }, [filtersInited, cachedFilterValues, initialCacheRead]);

  const updateFilter = React.useCallback(
    (name, value) => {
      if (filters == null || filters.length === 0) {
        return;
      }
      const filter = filters.filter((f) => f.name === name);
      if (filter != null && filter.length > 0) {
        const currentFilter = filter[0];
        const { predicate, defaultValue, visibilityDependentOnOtherFilter } = currentFilter;

        const currentPredicate = predicates.find((p) => p.name === name);

        // Only update when the predicate value has actually been updated
        if (currentPredicate != null && currentPredicate.value === value) {
          return;
        }
        updatePredicates((prev) => [
          ...prev
            .map((p) => {
              if (p.name !== name) {
                return p;
              }
              return null;
            })
            .filter((p) => p != null),
          {
            defaultValue,
            name,
            predicate,
            value,
            visibilityDependentOnOtherFilter,
          },
        ]);
      }
    },
    [filters, predicates],
  );

  const evaluatePredicates = React.useCallback(
    (data) => {
      if (predicates == null || predicates.length === 0) {
        return data;
      }
      return data.filter((rowData) => {
        let isValid = true;
        predicates.forEach((p) => {
          const { predicate, value, visibilityDependentOnOtherFilter } = p;
          if (
            visibilityDependentOnOtherFilter != null &&
            typeof visibilityDependentOnOtherFilter === 'function'
          ) {
            const isVisible = visibilityDependentOnOtherFilter(predicates);
            if (!isVisible) {
              return;
            }
          }
          if (!predicate(rowData, value)) {
            isValid = false;
          }
        });
        return isValid;
      });
    },
    [predicates],
  );

  const { t } = useTranslation('common');

  const predefinedOptions = {
    columnReordering: true,
    columnResizing: false,
    sortingMode: SortingMode.Single,
  };

  const getCombinedColumns = React.useMemo(() => {
    const { columns } = defaultOptions;
    const ls = JSON.parse(localStorage.getItem(localStorageKey));
    if (ls == null) {
      return columns;
    }

    const { columns: lsColumns } = ls;
    if (lsColumns == null || !isIterable(lsColumns)) {
      return columns;
    }

    // Fetch columns stored in LS and append with predefined data
    const columnsFromLs = lsColumns
      .filter((lsCol) => columns.some((col) => col.key === lsCol.key))
      .map((option) => {
        const { key } = option;
        const def = columns.find((col) => col.key === key);
        if (def == null) {
          // Column does not exist anymore, skip
          return null;
        }
        return option;
      });

    // Add possible new columns that are not saved in LS yet
    const newColumns = columns
      .filter((col) => !lsColumns.some((lsCol) => lsCol.key === col.key))
      .map((option) => {
        const { key } = option;
        const fromLs = lsColumns.find((cache) => cache.key === key);
        if (fromLs == null) {
          return option;
        }
        return Object.assign(option, fromLs);
      });
    return [...columnsFromLs, ...newColumns];
  }, [defaultOptions, localStorageKey]);
  const [tableProps, changeTableProps] = useState({
    ...predefinedOptions,
    ...defaultOptions,
    columns: getCombinedColumns,
  });

  const { itemHeight, addRowHeight } = dynamicRowsOptions(tableProps);

  const setBorderedClasses = React.useCallback(
    (sortedTableProps) => {
      let data = sortedTableProps;
      if (bordered && data != null && data.length > 0) {
        data = sortedTableProps.map((val, i) => {
          if (i % 2 === 0) {
            return { ...val, className: 'even' };
          }
          return { ...val, className: 'odd' };
        });
      }
      return data;
    },
    [bordered],
  );

  const tableDataRows = React.useMemo(() => {
    if (bordered && formattedData != null && formattedData.length > 0) {
      return setBorderedClasses(formattedData);
    }
    return formattedData;
  }, [bordered, formattedData, setBorderedClasses]);

  const dispatch = React.useCallback(
    (action) => {
      changeTableProps((prevState) => {
        let newState = kaReducer(prevState, action);
        const { type } = action;
        const { columns } = newState;
        switch (type) {
          case ActionType.OpenEditor: {
            // Only show editor if columnKey is editable!
            if (!columns.some((col) => col.key === action.columnKey && col.editable === true)) {
              return prevState;
            }
            break;
          }
          case ActionType.UpdateSortDirection: {
            if (!bordered) {
              break;
            }
            const sortedData = kaPropsUtils.getData(newState);
            newState = { ...newState, data: setBorderedClasses(sortedData) };
            break;
          }
          case ActionType.ReorderColumns:
          case ActionType.HideColumn:
          case ActionType.ShowColumn: {
            localStorage.setItem(localStorageKey, JSON.stringify({ columns }));
            break;
          }
          case ActionType.ShowNewRow: {
            // When adding a new row, we want to have all columns visible, even though if they have been deselected
            const allColumnsVisible = [];
            columns.forEach((col) => {
              const newCol = col;
              newCol.visible = true;
              allColumnsVisible.push(newCol);
            });
            break;
          }
          case ActionType.HideNewRow: {
            // We want to restore the user defined column settings
            newState = { ...newState, columns: getCombinedColumns };
            break;
          }
          case ActionType.SaveNewRow: {
            // Save the new row
            dispatch(showLoading());
            const newRow = newState.data.find((row) => row[newState.rowKeyField] === NEW_ROW_KEY);
            if (newRow != null) {
              // Validate newRow again
              const { editableCells } = prevState;
              const updatedEditableCells = editableCells.map((cell) => {
                const column = columns.find((c) => c.key === cell.columnKey);
                const validationMessage =
                  defaultOptions.validation &&
                  defaultOptions.validation({
                    column,
                    rowData: newRow,
                    value: cell.editorValue,
                  });
                return { ...cell, validationMessage };
              });
              if (updatedEditableCells.some((cell) => !isNullOrEmpty(cell.validationMessage))) {
                // not valid
                return { ...prevState, editableCells: updatedEditableCells };
              }
              onAddNewRow(newRow);
              dispatch(hideNewRow());
            }
            dispatch(hideLoading());
            break;
          }
          case ActionType.SaveRowEditors: {
            // Update the new row
            const { editableCells } = newState;
            const editedCell = editableCells.find(
              (cell) => cell.rowKeyValue === action.rowKeyValue,
            );
            if (
              editedCell != null &&
              editedCell.validationMessage != null &&
              editedCell.validationMessage.length > 0
            ) {
              // Not valid!
              break;
            }
            dispatch(showLoading());
            const originalRow = prevState.data.find(
              (row) => row[prevState.rowKeyField] === action.rowKeyValue,
            );
            const updatedRow = newState.data.find(
              (row) => row[newState.rowKeyField] === action.rowKeyValue,
            );
            if (updatedRow != null) {
              onUpdateRow(originalRow, updatedRow);
              dispatch(closeRowEditors(action.rowKeyValue));
            }
            dispatch(hideLoading());
            break;
          }
          default:
            return newState;
        }

        return newState;
      });
      if (!loading && loadMoreData != null && action.type === 'LOAD_MORE_DATA') {
        loadMoreData();
      }
    },
    [
      bordered,
      defaultOptions,
      getCombinedColumns,
      loadMoreData,
      loading,
      localStorageKey,
      onAddNewRow,
      onUpdateRow,
      setBorderedClasses,
    ],
  );

  const getDraggableHeader = ({ column }) => {
    if (column.key === 'id' && isAddInIdColumn) {
      return (
        <IconButton
          icon="plus"
          iconStyle="fas"
          tooltip={t('common:add')}
          onClick={onAddNewRow != null ? () => dispatch(showNewRow()) : onAdd}
        />
      );
    }
    if (column.title == null) return null;
    return (
      <>
        <FontAwesomeIcon
          icon={['fad', 'grip-dots-vertical']}
          style={{ cursor: 'move', marginRight: '5px', position: 'relative' }}
        />
        <span>{column.title}</span>
      </>
    );
  };

  React.useEffect(() => {
    dispatch(loading ? showLoading() : hideLoading());
  }, [dispatch, loading]);

  const saveNewRowData = React.useCallback(() => {
    dispatch(
      saveNewRow(NEW_ROW_KEY, {
        validate: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(updateData(tableDataRows));
  }, [dispatch, tableDataRows]);

  const getNewRowSaveButton = React.useMemo(
    () => (
      <div style={{ display: 'flex' }}>
        <IconButton
          iconComponent={<SaveIcon />}
          color="keypoint"
          tooltip={t('common:save')}
          onClick={() => saveNewRowData()}
        />
        <IconButton
          iconComponent={<CancelIcon />}
          color="red"
          tooltip={t('common:cancel')}
          onClick={() => dispatch(hideNewRow())}
        />
      </div>
    ),
    [dispatch, saveNewRowData, t],
  );

  const getChildComponents = React.useMemo(() => {
    let { detailsRow, dataRow } = childComponents;
    let elementAttributes = null;
    if (dataRow) {
      elementAttributes = dataRow.elementAttributes;
    }

    dataRow = {
      ...dataRow,
      elementAttributes: (data) => ({
        onClick: onSingleRowSelect
          ? (event, extendedEvent) => {
              extendedEvent.dispatch(selectSingleRow(extendedEvent.childProps.rowKeyValue));
              onSingleRowSelect(extendedEvent.childProps.rowData);
            }
          : null,
        ref: (ref) => addRowHeight(data.rowData, ref?.offsetHeight),
        ...(elementAttributes ? elementAttributes(data) : null),
      }),
    };

    if (bordered) {
      detailsRow = {
        ...detailsRow,
        elementAttributes: ({ rowData }) => ({
          className: rowData.className,
        }),
      };
      dataRow = {
        ...dataRow,
        elementAttributes: (data) => ({
          className: data.rowData.className,
          onClick: onSingleRowSelect
            ? (event, extendedEvent) => {
                extendedEvent.dispatch(selectSingleRow(extendedEvent.childProps.rowKeyValue));
                onSingleRowSelect(extendedEvent.childProps.rowData);
              }
            : null,
          ref: (ref) => addRowHeight(data.rowData, ref?.offsetHeight),
          ...(elementAttributes ? elementAttributes(data) : null),
        }),
      };
    }
    let components = {
      ...childComponents,
      dataRow: { ...dataRow },
      detailsRow: { ...detailsRow },
      headCellContent: {
        content: getDraggableHeader,
      },
      noDataRow: {
        content: () => t('common:noDataToDisplay'),
      },
    };
    if (bordered === true) {
      components.table = {
        elementAttributes: () => ({
          className: 'bordered',
        }),
      };
    }
    if (onAddNewRow != null) {
      const { cellEditor } = components;
      const newCellEditor = {
        content: (data) => {
          const { column, rowKeyField } = data;
          if (column.key === rowKeyField) {
            return getNewRowSaveButton;
          }
          return cellEditor != null ? cellEditor.content(data) : null;
        },
      };
      components = { ...components, cellEditor: newCellEditor };
    }
    return components;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bordered,
    childComponents,
    onAddNewRow,
    addRowHeight,
    getDraggableHeader,
    t,
    getNewRowSaveButton,
  ]);

  const tableRef = React.useRef();

  const updateMaxHeight = React.useCallback(() => {
    const rect = tableRef.current.getBoundingClientRect();
    let maxHeight = window.innerHeight - rect.top - 25;

    if (maxHeight <= 0) {
      maxHeight = window.innerHeight - 155;
    }
    const maxHeightPx = `${maxHeight}px`;
    if (tableHeight !== maxHeightPx) {
      setTableHeight(`${maxHeight}px`);
      changeTableProps((current) => ({
        ...current,
        virtualScrolling: { ...current.virtualScrolling, tbodyHeight: maxHeight },
      }));
    }
  }, [tableHeight]);

  React.useEffect(() => {
    if (!fullHeight) {
      // Return something to have consistent return types
      // Return undefined to specify that we don't need a cleanup
      return undefined;
    }

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.attributeName === 'class' && mutation.target.classList.contains('nav-link')) {
          updateMaxHeight();
        }
      });
    });
    const node = document.querySelector('.nav-tabs');
    if (node != null) {
      observer.observe(document.querySelector('.nav-tabs'), {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, [updateMaxHeight, fullHeight]);

  React.useEffect(() => {
    if (tableRef && tableRef.current && fullHeight === true) {
      updateMaxHeight();

      window.addEventListener('resize', updateMaxHeight);

      return () => {
        window.removeEventListener('resize', updateMaxHeight);
      };
    }
    if (height != null) {
      setTableHeight(`${height}${typeof height === 'number' ? 'px' : ''}`);
      if (typeof height === 'number') {
        changeTableProps((current) => ({
          ...current,
          virtualScrolling: { ...current.virtualScrolling, tbodyHeight: height },
        }));
      }
    }
    return () => {};
  }, [height, fullHeight, updateMaxHeight]);

  const filterLocalStorageKey = React.useMemo(
    () =>
      localStorageKey != null && localStorageKey.length > 0
        ? `${localStorageKey}-filter`
        : 'unnamed-table-filter',
    [localStorageKey],
  );

  const clearFilterCallback = React.useCallback(() => {
    setFiltersInited(false);
    localStorage.removeItem(filterLocalStorageKey);
  }, [filterLocalStorageKey]);

  const getCustomProps = () => {
    if (disableVirtualScrolling) {
      return null;
    }
    return {
      ...tableProps.virtualScrolling,
      enabled: !disableVirtualScrolling,
      itemHeight,
    };
  };

  const addFunction = onAddNewRow != null ? () => dispatch(showNewRow()) : onAdd;

  return (
    <div className={classNames('flex flex-col space-y-2', className)}>
      {!hideActionbar && (
        <TableActionBar
          columns={tableProps.columns}
          dispatch={dispatch}
          showFilter={filters != null && filters.length > 0}
          isFilterActive={
            predicates != null &&
            predicates.some((p) =>
              isIterable(p.defaultValue) &&
              typeof p.defaultValue !== 'string' &&
              isIterable(p.value)
                ? !arraysAreEqual(p.defaultValue, p.value)
                : p.defaultValue !== p.value,
            )
          }
          clearFilterCallback={clearFilterCallback}
          callback={updateFilter}
          onAdd={isAddInIdColumn ? null : addFunction}
          hideDisplayMenu={hideDisplayMenu}
          hideSearchField={hideSearchField}
          buttons={topBarButtons}
          predicates={predicates}
          filters={filters}
          cachedFiltersInitializedCallback={cachedFiltersInitializedCallback}
          filterLocalStorageKey={filterLocalStorageKey}
          delay={delay}
          onAddCustomRender={onAddCustomRender}
        />
      )}
      <div ref={tableRef} style={{ maxHeight: tableHeight }}>
        <Table
          {...tableProps}
          virtualScrolling={getCustomProps()}
          dispatch={dispatch}
          extendedFilter={evaluatePredicates}
          childComponents={getChildComponents}
          height={tableHeight}
        />
      </div>
    </div>
  );
};

KeypointTable.propTypes = {
  bordered: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  childComponents: PropTypes.object.isRequired,
  className: PropTypes.string,
  defaultOptions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    columns: PropTypes.array,
    validation: PropTypes.func,
  }).isRequired,
  delay: PropTypes.number,
  disableVirtualScrolling: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape()),
  // eslint-disable-next-line react/forbid-prop-types
  formattedData: PropTypes.array,
  fullHeight: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideActionbar: PropTypes.bool,
  hideDisplayMenu: PropTypes.bool,
  hideSearchField: PropTypes.bool,
  isAddInIdColumn: PropTypes.bool,
  loading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  localStorageKey: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onAddCustomRender: PropTypes.func,
  onAddNewRow: PropTypes.func,
  onSingleRowSelect: PropTypes.func,
  onUpdateRow: PropTypes.func,
  topBarButtons: PropTypes.arrayOf(PropTypes.node),
};

KeypointTable.defaultProps = {
  bordered: false,
  className: null,
  delay: 150,
  disableVirtualScrolling: false,
  filters: null,
  formattedData: [],
  fullHeight: false,
  height: null,
  hideActionbar: false,
  hideDisplayMenu: false,
  hideSearchField: false,
  isAddInIdColumn: false,
  loading: false,
  loadMoreData: null,
  onAdd: null,
  onAddCustomRender: null,
  onAddNewRow: null,
  onSingleRowSelect: null,
  onUpdateRow: null,
  topBarButtons: [],
};

export default React.memo(KeypointTable);
