import {
  Badge,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Tooltip,
} from '@mui/joy';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { AppContext } from '@/shared/context/context';
import { Nullable } from '~/common/types';

type SideBarItemProps = {
  className?: string;
  title: string;
  toolTip?: string;
  icon?: Nullable<React.ReactNode>;
  badgeContent?: Nullable<string | number>;
  badgeTooltip?: Nullable<string>;
  route: string;
  isChild?: boolean;
  endAction?: React.ReactElement;
};

const SidebarItem: React.FC<SideBarItemProps> = ({
  className,
  title = '',
  toolTip = '',
  icon = null,
  badgeContent = null,
  badgeTooltip = '',
  route = '',
  isChild = false,
  endAction = null,
}) => {
  const location = useLocation();
  const { ui } = useContext(AppContext);

  const isSelected = location.pathname.toLowerCase() === route.toLowerCase();

  const selectedClass = classNames({
    'text-primary': isSelected,
  });

  const sidebarIcon = icon && (
    <ListItemDecorator className={`${selectedClass} mr-[-10px] text-xs`}>{icon}</ListItemDecorator>
  );

  return (
    <ListItem endAction={endAction} className="ml-2">
      <ListItemButton className={isChild ? 'py-0 pl-0' : 'py-1.5 pl-3'}>
        <Link to={route} className={classNames(className, 'flex grow items-center text-inherit')}>
          {!ui.toggles.mainMenu ? (
            <Badge
              badgeContent={
                badgeContent && (
                  <Tooltip title={badgeTooltip}>
                    <span>{badgeContent}</span>
                  </Tooltip>
                )
              }
              size="sm"
              invisible={!badgeContent}
              badgeInset="20%"
            >
              <span>{sidebarIcon}</span>
            </Badge>
          ) : (
            <>
              {sidebarIcon}
              <Badge
                badgeContent={
                  badgeContent && (
                    <Tooltip title={badgeTooltip}>
                      <span>{badgeContent}</span>
                    </Tooltip>
                  )
                }
                size="sm"
                invisible={!badgeContent}
                badgeInset="-10%"
              >
                <ListItemContent>
                  <Tooltip title={toolTip} followCursor>
                    <span className={selectedClass}>{title}</span>
                  </Tooltip>
                </ListItemContent>
              </Badge>
            </>
          )}
        </Link>
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
