import PropTypes from 'prop-types';

import EnumSelector from '../../select/EnumSelector';
import GenericEditor from './GenericEditor';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  disableAutoFocus: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  enumValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  hideSaveButtons: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rowKeyValue: PropTypes.any.isRequired,
  translationToUse: PropTypes.string,
  validationMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const innerComponentRender = ({
  disableAutoFocus,
  enumValues,
  isAddingNew,
  label,
  multiple,
  onChangeCallback,
  required,
  rest,
  translationToUse,
  updateValue,
  validationMessage,
  value,
}) => (
  <EnumSelector
    {...rest}
    multiple={multiple}
    autoFocus={!isAddingNew && !disableAutoFocus}
    onChange={(values) => {
      let val = null;
      if (values != null) {
        if (multiple) {
          val = values;
        } else {
          const idx = 0;
          val = values[idx];
        }
      }

      updateValue(val);
      onChangeCallback(val);
    }}
    defaultValue={value}
    enumValues={enumValues}
    errorText={validationMessage}
    translationToUse={translationToUse}
    label={label}
    required={required}
  />
);

const EnumEditor = (props) => {
  const {
    column,
    disableAutoFocus = false,
    dispatch,
    enumValues,
    hideSaveButtons = false,
    multiple = false,
    onChange = () => {},
    required = false,
    rowKeyValue,
    translationToUse = 'common',
    validationMessage = null,
    value = null,
    ...rest
  } = props;

  return (
    <GenericEditor
      column={column}
      rowKeyValue={rowKeyValue}
      dispatch={dispatch}
      innerComponentRender={({ updateValue, isAddingNew }) =>
        innerComponentRender({
          disableAutoFocus,
          enumValues,
          isAddingNew,
          label: column.title,
          multiple,
          onChangeCallback: onChange,
          required,
          rest,
          translationToUse,
          updateValue,
          validationMessage,
          value,
        })
      }
      hideSaveButtons={hideSaveButtons}
    />
  );
};

export default EnumEditor;

EnumEditor.propTypes = propTypes;
