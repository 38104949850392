enum McBicValue {
  CMCIMCM1 = 'CMCIMCM1',
  CMMDMCM1 = 'CMMDMCM1',
  BPPBMCMC = 'BPPBMCMC',
  BERLMCMC = 'BERLMCMC',
  UBSWMCMX = 'UBSWMCMX',
  SDBMMCM2 = 'SDBMMCM2',
  BARCMCMX = 'BARCMCMX',
  CFMOMCMX = 'CFMOMCMX',
  KBLXMCMC = 'KBLXMCMC',
  SGBTMCMC = 'SGBTMCMC',
  MAEAMCM1 = 'MAEAMCM1',
  BAERMCMC = 'BAERMCMC',
  CCBPMCM1 = 'CCBPMCM1',
  POSOMCM1 = 'POSOMCM1',
  BACAMCMC = 'BACAMCMC',
  HAVLMCMX = 'HAVLMCMX',
  UBPGMCMX = 'UBPGMCMX',
  PICTMCMC = 'PICTMCMC',
  CMBMMCMX = 'CMBMMCMX',
  CEPAMCM1 = 'CEPAMCM1',
  EFGBMCMC = 'EFGBMCMC',
  AGRIMCM1 = 'AGRIMCM1',
  BJSBMCMX = 'BJSBMCMX',
  CRLYMCM1 = 'CRLYMCM1',
  SOGEMCM1 = 'SOGEMCM1',
  BNPAMCM1 = 'BNPAMCM1',
}

export default McBicValue;
