import { User } from 'oidc-client-ts';

import config from '../../config/config';

// eslint-disable-next-line import/prefer-default-export
export const getAccessToken = () => {
  const oidcStorage = localStorage.getItem(`oidc.user:${config.authority}:${config.cliendId}`);

  if (!oidcStorage) {
    return undefined;
  }

  const user = User.fromStorageString(oidcStorage);

  return user.access_token;
};
