import { FormControl, FormLabel } from '@mui/joy';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import FormHelperText from '../components/2.0/forms/FormHelperText';
import SelectContractorSkillDefinition from '../components/select/SelectContractorSkillDefinition';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const FormikContractorSkillDefinitionSelector = ({
  required = false,
  name,
  label = null,
  ...rest
}) => {
  const { values, setValues, errors, touched, setTouched, setFieldValue } = useFormikContext();

  return (
    <FormControl className="flex-1">
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <SelectContractorSkillDefinition
        onChangeCallback={(skill) => {
          if (skill == null) {
            setValues({ ...values, [name]: null });
            return;
          }

          setFieldValue(name, skill);
          setFieldValue('contractorSkillDefinitionId', skill.id);
        }}
        initialSkillId={values[name]?.id ?? values[name]}
        onBlur={() => setTouched({ ...touched, [name]: true })}
        {...rest}
      />

      <FormHelperText error={errors[name]} touched={touched[name]} />
    </FormControl>
  );
};

FormikContractorSkillDefinitionSelector.propTypes = propTypes;

export default FormikContractorSkillDefinitionSelector;
