import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
  AutocompleteOption,
  AutocompleteOptionsArgs,
  UseAutocompleteOptionsResult,
} from './autocomplete.types';
import { BookYearOption } from '@/types/trpc/hoa';
import { BookyearStatus } from '~/common/enums';

import { AppContext } from '@/shared/context/context';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

type BookYearOptionExtension = Pick<BookYearOption, 'data'>;

type Options = {
  activeOnly?: boolean;
  homeownerAssociationId: string | null | undefined;
  useGlobalAsDefault?: boolean;
} & AutocompleteOptionsArgs<string, BookYearOptionExtension>;

function useBookYearAutocomplete(
  options: Options & { disableClearable?: boolean | undefined; multiple: true },
): UseAutocompleteOptionsResult<string, true, BookYearOptionExtension>;

function useBookYearAutocomplete(
  options: Options & { disableClearable: true; multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, BookYearOptionExtension>;

function useBookYearAutocomplete(
  options: Options & { disableClearable?: false; multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, BookYearOptionExtension>;

function useBookYearAutocomplete({
  activeOnly = false,
  callback,
  disableClearable,
  homeownerAssociationId,
  multiple,
  selected,
  useGlobalAsDefault = false,
  ...args
}: Options): UseAutocompleteOptionsResult<string, typeof multiple, BookYearOptionExtension> {
  const { data: options, isLoading } = trpc.hoa.bookYear.autocomplete.useQuery(
    {
      bookYearStatus: activeOnly ? [BookyearStatus.Open] : undefined,
      homeownerAssociationId: homeownerAssociationId as string,
    },
    { enabled: !!homeownerAssociationId },
  );
  const { ui } = useContext(AppContext);

  const handleCallback = useCallback(
    (
      newValue:
        | AutocompleteOption<string, BookYearOptionExtension>[]
        | AutocompleteOption<string, BookYearOptionExtension>
        | null,
    ) => {
      const multipleOptions = Array.isArray(newValue);

      if (multiple && multipleOptions) {
        callback(newValue);
      } else if (!multiple && !multipleOptions) {
        if (disableClearable && newValue) {
          callback(newValue);
        } else if (!disableClearable) {
          callback(newValue);
        }
      }
    },
    [callback, disableClearable, multiple],
  );

  const autocompleteProps = useAutocompleteOptions({
    callback: handleCallback,
    data: options,
    disableClearable,
    isLoading,
    multiple,
    selected,
    ...args,
  });

  const fallbackOption = useMemo(() => {
    if (!options) return null;

    let matchingOption;

    if (useGlobalAsDefault && ui.currentBookYearId) {
      matchingOption = options.find((option) => option.value === ui.currentBookYearId);
    } else {
      const currentYear = new Date().getFullYear();

      matchingOption = options.find((option) => option.data.year === currentYear);
    }

    return matchingOption || null;
  }, [options, ui.currentBookYearId, useGlobalAsDefault]);

  useEffect(() => {
    const hasValue = Array.isArray(autocompleteProps.value)
      ? !!autocompleteProps.value.length
      : !!autocompleteProps.value;

    if (!fallbackOption || hasValue) return;

    if (multiple) {
      callback([fallbackOption]);
    } else {
      callback(fallbackOption);
    }
  }, [autocompleteProps.value, callback, fallbackOption, multiple]);

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
}

export default useBookYearAutocomplete;
