import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import GenericEditor from './GenericEditor';
import { isNullOrEmpty } from '../../../utils/helpers';

const innerComponentRender = ({
  updateValue,
  isAddingNew,
  disableAutoFocus,
  value,
  validationMessage,
  required,
  label,
  disabled,
}) => (
  <TextField
    autoFocus={!isAddingNew && !disableAutoFocus}
    fullWidth
    defaultValue={value}
    onChange={({ target }) => updateValue(target.value)}
    error={!isNullOrEmpty(validationMessage)}
    helperText={validationMessage}
    label={label}
    variant="standard"
    disabled={disabled}
    required={required}
  />
);

const StringEditor = (props) => {
  const {
    column,
    rowKeyValue,
    dispatch,
    value,
    validationMessage,
    disabled,
    hideSaveButtons,
    disableAutoFocus,
    required,
  } = props;

  return (
    <GenericEditor
      column={column}
      rowKeyValue={rowKeyValue}
      dispatch={dispatch}
      hideSaveButtons={hideSaveButtons}
      innerComponentRender={({ updateValue, isAddingNew }) =>
        innerComponentRender({
          disableAutoFocus,
          disabled,
          isAddingNew,
          label: column.title,
          required,
          updateValue,
          validationMessage,
          value,
        })
      }
      valueTransformer={(val) => val.trim()}
    />
  );
};

export default StringEditor;

StringEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  disableAutoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  hideSaveButtons: PropTypes.bool,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rowKeyValue: PropTypes.any.isRequired,
  validationMessage: PropTypes.string,
  value: PropTypes.string,
};

StringEditor.defaultProps = {
  disableAutoFocus: false,
  disabled: false,
  hideSaveButtons: false,
  required: false,
  validationMessage: null,
  value: null,
};
