import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconButton from '../buttons/IconButton';
import { CancelIcon, EditIcon, SaveIcon } from '../../icons/Icons';

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

const InlineEditInput = ({ initialValue, label, onSave }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [value, setValue] = React.useState(initialValue ?? '');
  const { t } = useTranslation('common');

  const handleSave = () => {
    setIsEditing(false);
    onSave(value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(initialValue);
  };

  return !isEditing ? (
    <div>
      {label}: {initialValue}
      <IconButton
        iconComponent={<EditIcon />}
        tooltip={t('common:edit')}
        onClick={() => setIsEditing(true)}
        color="keypoint"
      />
    </div>
  ) : (
    <ActionButtonsWrapper>
      <TextField
        label={label}
        value={value}
        onChange={({ target }) => setValue(target.value ?? '')}
        variant="standard"
        autoFocus
      />
      <IconButton
        iconComponent={<SaveIcon />}
        color="keypoint"
        submit
        tooltip={t('common:save')}
        hidden={value === initialValue}
        onClick={handleSave}
        style={{ marginTop: 'auto' }}
      />
      <IconButton
        iconComponent={<CancelIcon />}
        color="danger"
        tooltip={t('common:cancel')}
        onClick={handleCancel}
        style={{ marginTop: 'auto' }}
      />
    </ActionButtonsWrapper>
  );
};

export default InlineEditInput;

InlineEditInput.propTypes = {
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

InlineEditInput.defaultProps = {
  initialValue: '',
};
