import createRestClient from '../restClient';

const restClient = createRestClient();

const getContractorSkillDefinitions = () => restClient.get('contractorskill/list.all');

const getContractorSkillDefinition = (id) => restClient.get('contractorskill', id);

const createContractorSkillDefinition = (data) => restClient.post('contractorskill', data);

const updateContractorSkillDefinition = (data) => restClient.put('contractorskill', data);

const deactivateContractorSkillDefinition = (id) =>
  restClient.put(`contractorskill/deactivate?contractorSkillDefinitionId=${id}`);

const activateContractorSkillDefinition = (id) =>
  restClient.put(`contractorskill/activate?contractorSkillDefinitionId=${id}`);

export default {
  activateContractorSkillDefinition,
  createContractorSkillDefinition,
  deactivateContractorSkillDefinition,
  getContractorSkillDefinition,
  getContractorSkillDefinitions,
  updateContractorSkillDefinition,
};
