import { IconButton, Tooltip } from '@mui/joy';
import React, {
  createElement,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import classnames from 'classnames';
import { Group } from 'ka-table/models';
import i18next from 'i18next';
import { Table } from 'ka-table';
import { useTranslation } from 'react-i18next';

import { getStoredColumns, saveColumns } from '@/shared/utils/helpers';
import { useFilters } from '@/shared/hooks/UseFilters';

import { AddIcon, CancelIcon, DeleteIcon, EditIcon } from '@/shared/icons/Icons';
import Cell from '@/shared/components/2.0/customTableCells/DefaultTableCell';
import DataTableFilter from '@/shared/components/2.0/DataTableFilter';
import DataTableSearch from '@/shared/components/2.0/DataTableSearch';
import DefaultHeaderCell from '@/shared/components/2.0/customTableCells/DefaultHeaderCell';
import MultiSelectColumns from '@/shared/components/2.0/MultiSelectColumns';

type DataTableProps<TData> = {
  callback?: (id: string) => void;
  children?: React.ReactNode;
  childComponents?: Record<string, unknown>;
  className?: string;
  config: {
    columns: {
      list: Record<string, unknown>[];
      localStorageKey?: string;
    };
    filter?: {
      defaultFilters?: Record<string, unknown>;
      localStorageKey?: string;
    };
    search?: ({
      searchText,
      rowData,
      column,
    }: {
      searchText: string;
      rowData: TData;
      column: { key: string };
    }) => boolean;
    type?: string;
  };
  customActions?: React.ReactNode;
  data: TData[] | undefined;
  disableActions?: boolean;
  height?: number;
  defaultActions?: {
    create: boolean;
    delete: boolean;
    columnsSelection: boolean;
  };
  loading?: boolean;
  permissions?: Record<string, unknown>;
  rowId: string;
  selectedItems?: TData[];
  setSelectedId?: Dispatch<SetStateAction<string | null>>;
  setCanEditRow?: (data: TData) => boolean;
  setSelectedItems?: Dispatch<SetStateAction<TData[]>>;
  subtitleContent?: React.ReactNode;
  title?: string;
  toggles?: Record<string, unknown>;
  searchFixed?: boolean;
  groups?: Group[];
  // All other props
  [key: string]: any;
};

const DataTable = <TData extends Record<string, unknown>>({
  callback,
  children,
  childComponents,
  className = '',
  config,
  customActions = null,
  defaultActions = { columnsSelection: true, create: true, delete: true },
  data,
  disableActions = false,
  height,
  loading,
  permissions,
  rowId,
  selectedItems,
  setSelectedId,
  setCanEditRow,
  setSelectedItems,
  subtitleContent,
  title = '',
  searchFixed,
  groups,
  toggles = {},
  ...rest
}: DataTableProps<TData>) => {
  const [tableProps, setTableProps] = useState({
    ...config,
    columns: getStoredColumns(config.columns.list ?? [], config.columns.localStorageKey),
  });
  const [searchText, setSearchText] = useState();

  const { t } = useTranslation();
  const { language } = i18next;
  const { filters, changeFilter } = useFilters({
    defaultFilters: config.filter?.defaultFilters || null,
    localStorageKey: config.filter?.localStorageKey,
  });

  const dataWithRowId = useMemo(
    () => data?.map((item) => ({ ...item, rowId: item[rowId] })) || [],
    [data, rowId],
  );

  const dispatch = useCallback(
    (action: unknown) => {
      setTableProps((prevState) =>
        saveColumns({ action, localStorageType: config.columns.localStorageKey, prevState }),
      );
    },
    [config.columns.localStorageKey],
  );

  return (
    <div className={classnames('rounded-xl bg-white p-4 pb-2', className)}>
      <div className="flex items-end space-x-2 pb-4">
        <div className="grow">
          <h4>{title}</h4>
          {subtitleContent}
        </div>

        <div className="flex space-x-2">
          {config.search && <DataTableSearch setSearchText={setSearchText} fixed={searchFixed} />}

          {filters && <DataTableFilter toggle={toggles.filter} />}
          {defaultActions.columnsSelection && (
            <MultiSelectColumns
              columns={tableProps.columns}
              onSelectFilter={dispatch}
              type={config.type || ''}
            />
          )}

          {customActions}

          {defaultActions.delete && selectedItems && !!selectedItems.length && (
            <Tooltip title={t('delete')}>
              <IconButton
                onClick={toggles.delete.show}
                disabled={disableActions}
                className="align-self-start"
                color="danger"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}

          {!!toggles.edit && (!!dataWithRowId.length || children) && (
            <Tooltip title={toggles.edit.value ? t('buttons.close') : t('edit')}>
              <IconButton
                color={toggles.edit.value ? 'neutral' : 'primary'}
                variant="outlined"
                size="sm"
                disabled={disableActions}
                className="align-self-start"
                onClick={() => {
                  toggles.edit.inverse();
                  if (setSelectedItems) {
                    setSelectedItems([]);
                  }
                }}
              >
                {toggles.edit.value ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
          )}
          {defaultActions.create && (
            <Tooltip title={t('buttons.create')}>
              <IconButton
                disabled={disableActions}
                variant="outlined"
                className="align-self-start"
                onClick={toggles.create?.show}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {toggles.filter ? (
        <div ref={toggles.filter.divRef}>
          {toggles.filter?.value &&
            config.filter &&
            createElement(config.filter.component, { changeFilter, filters })}
        </div>
      ) : null}
      {children}
      <div className="rounded-2xl border border-gray-200">
        <Table
          {...tableProps}
          groups={groups}
          height={height}
          dispatch={dispatch}
          searchText={searchText}
          loading={{ enabled: loading }}
          data={dataWithRowId}
          extendedFilter={(tableData) => {
            if (config.filter?.handler) {
              return config.filter.handler({ data: tableData, filters });
            }

            return tableData;
          }}
          childComponents={{
            cell: {
              content: (props) =>
                Cell.DefaultTableCell({
                  t,
                  ...props,
                  callback,
                  language,
                  permissions,
                  rowId,
                  setCanEditRow,
                  setSelectedId,
                  setSelectedItems,
                  toggles,
                  ...rest,
                }),
            },
            headCellContent: {
              content: (props) =>
                DefaultHeaderCell.DefaultHeadCell({
                  t,
                  type: config.type || null,
                  ...props,
                }),
            },
            ...childComponents,
            noDataRow: { content: () => t('noDataToDisplay') },
          }}
        />
      </div>
    </div>
  );
};

export default DataTable;
