import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import caseApi from '../../shared/api/cases/case';
import { queryIds } from '../../shared/utils/constants';

const useGetCaseTemplates = () => {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    [queryIds.cases.GET_CASE_TEMPLATES],
    async ({ pageParam = 0 }) => caseApi.getCaseTemplates(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 100) return undefined;
        return allPages.length * 100;
      },
    },
  );

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage({ cancelRefetch: false });
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  return {
    caseTemplates: data?.pages?.flat() || [],
    caseTemplatesLoading: isLoading,
  };
};

export default useGetCaseTemplates;
