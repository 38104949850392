import { Autocomplete, Badge } from '@mui/joy';
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';
import { clearRecentCases } from '@/shared/utils/helpers';
import ROUTES from '@/containers/App/Router/Routes';
import useAcceptTeamInvitation from '@/mutations/team/useAcceptTeamInvitation';

import { InlineSpinner, TeamIcon } from '@/shared/icons/Icons';
import { trpc } from '@/config/trpc';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

const TopbarTeam = () => {
  const { currentTeam, setUi } = useContext(AppContext);

  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const utils = trpc.useUtils();

  const { data: teamsAndInvitations, isLoading } = trpc.team.teamsAndInvitations.useQuery();
  const { acceptTeamInvitationAsync } = useAcceptTeamInvitation();

  const badgeCount = useMemo(() => {
    const invitations = teamsAndInvitations?.filter((team) => team.invitationId);
    return invitations?.length || null;
  }, [teamsAndInvitations]);

  useEffect(() => {
    if (currentTeam) {
      setSelectedTeamId(currentTeam.id);
    }
  }, [teamsAndInvitations, currentTeam]);

  return (
    <div>
      <Badge badgeContent={badgeCount} badgeInset="0">
        <Autocomplete
          value={
            teamsAndInvitations?.find((option) => option.value === selectedTeamId) || {
              label: t('loading'),
              value: '',
            }
          }
          startDecorator={isLoading ? <InlineSpinner /> : <TeamIcon />}
          className="w-72"
          disableClearable
          disabled={isLoading}
          getOptionKey={(option) => option.id}
          groupBy={(option) => option.type}
          options={teamsAndInvitations || []}
          onChange={async (_e, option) => {
            if (option.invitationId) {
              await acceptTeamInvitationAsync({
                teamId: option.value as string,
                teamInvitationId: option.invitationId,
              });
            }

            setSelectedTeamId(option.value as string);
            utils.invalidate();
            navigate(ROUTES.HOME);

            setUi({
              payload: option.value,
              type: 'SAVE_CURRENT_TEAM_ID',
            });

            clearRecentCases();
            enqueueSnackbar(t('successfullyChangedToTheTeam', { name: option.label }));
          }}
          slots={{ listbox: VirtualListBoxAdapter }}
          renderOption={(props, option) => [props, option] as React.ReactNode}
          renderGroup={(params) => params as unknown as React.ReactNode}
        />
      </Badge>
    </div>
  );
};

export default TopbarTeam;
