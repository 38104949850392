import { FormControl, FormHelperText, FormLabel } from '@mui/joy';
import classNames from 'classnames';

export type FieldProps = {
  error?: string;
  horizontal?: boolean;
  label?: string;
  required?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
};

const FormField: React.FC<React.PropsWithChildren<FieldProps>> = ({
  children,
  className,
  error,
  horizontal,
  label,
  required = false,
  ...formControlProps
}) => (
  <FormControl
    className={classNames(className, { 'gap-1': horizontal })}
    error={!!error}
    orientation={horizontal ? 'horizontal' : 'vertical'}
    {...formControlProps}
  >
    {!!label && <FormLabel required={required}>{label}</FormLabel>}
    {children}
    {!!error && (
      <FormHelperText className={classNames('px-1 text-xs', { 'my-0': horizontal })}>
        {error}
      </FormHelperText>
    )}
  </FormControl>
);

export default FormField;
