import { Checkbox, Dropdown, IconButton, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { useAuth } from 'react-oidc-context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';

import { AccessibilityIcon, ColumnGridIcon } from '@/shared/icons/Icons';

const DashboardTitle = () => {
  const auth = useAuth();
  const profile = auth.user?.profile;

  const {
    ui: {
      dashboard: { toggles, columnCount },
    },
    setUi,
  } = useContext(AppContext);

  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <Typography
        level="h1"
        className="mb-4 text-2xl font-medium"
        startDecorator={
          <Typography level="h1" className="text-2xl">
            👋
          </Typography>
        }
      >
        {t('dashboard.title', { name: profile?.name })}
      </Typography>

      <div className="space-x-4">
        <Dropdown>
          <MenuButton slots={{ root: IconButton }}>
            <ColumnGridIcon />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem>
              <Checkbox
                checked={columnCount === 1}
                onChange={() =>
                  setUi({
                    payload: 1,
                    type: actions.DASHBOARD_SET_COLUMN_COUNT,
                  })
                }
                label="1x"
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                checked={columnCount === 2}
                onChange={() =>
                  setUi({
                    payload: 2,
                    type: actions.DASHBOARD_SET_COLUMN_COUNT,
                  })
                }
                label="2x"
              />
            </MenuItem>
          </Menu>
        </Dropdown>

        <Dropdown>
          <MenuButton slots={{ root: IconButton }}>
            <AccessibilityIcon />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem>
              <Checkbox
                onChange={() => setUi({ type: actions.DASHBOARD_TOGGLE_HEADER })}
                checked={toggles.header}
                label={t('dashboard.widgets.statsHeader')}
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                onChange={() => setUi({ type: actions.DASHBOARD_TOGGLE_OPEN_TASKS_CHART })}
                checked={toggles.openTasksByTypeChart}
                label={t('dashboard.widgets.openTasksByTypeChart')}
              />
            </MenuItem>
            <MenuItem>
              <Checkbox
                onChange={() => setUi({ type: actions.DASHBOARD_TOGGLE_OPEN_TASKS_TABLE })}
                checked={toggles.openTasksTable}
                label={t('dashboard.widgets.openTasksTable')}
              />
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DashboardTitle;
