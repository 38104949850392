import PropTypes from 'prop-types';
import { Stack } from '@mui/joy';

const propTypes = {
  casesIsLoading: PropTypes.bool.isRequired,
  hasMoreCases: PropTypes.bool.isRequired,
  sortedRelatedCases: PropTypes.arrayOf().isRequired,
};

const CaseRelatedCases = ({ casesIsLoading, hasMoreCases, sortedRelatedCases }) => {
  if (casesIsLoading || hasMoreCases) {
    return (
      <div className="animate-pulse flex-col space-y-2 p-2">
        <div className="h-8 rounded-xl bg-gray-200" />
        <div className="h-8 rounded-xl bg-gray-200" />
        <div className="h-8 rounded-xl bg-gray-200" />
      </div>
    );
  }

  if (sortedRelatedCases && sortedRelatedCases.length > 0) {
    return (
      <Stack
        direction="column"
        spacing={1}
        sx={{
          marginTop: '12px',
          maxHeight: '12vh',
          minHeight: '120px',
          overflow: 'auto',
        }}
      >
        {sortedRelatedCases}
      </Stack>
    );
  }

  return null;
};

CaseRelatedCases.propTypes = propTypes;

export default CaseRelatedCases;
