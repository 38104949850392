import { forwardRef } from 'react';

import NumericFormatAdapter, { NumericFormatAdapterProps } from './NumericFormatAdapter';

const IntegerFormatAdapter = forwardRef<HTMLInputElement, NumericFormatAdapterProps>(
  (props, ref) => (
    <NumericFormatAdapter {...props} ref={ref} decimalScale={0} thousandSeparator={false} />
  ),
);

export default IntegerFormatAdapter;
