import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '@/shared/api/cases/case';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetCaseTemplate = (id?: string) => {
  const { sendDefaultError } = useNotification();
  const {
    i18n: { language },
  } = useTranslation();

  const { data, isLoading, isFetching } = useQuery({
    enabled: !!id,
    onError: sendDefaultError,
    queryFn: () => caseApi.getCaseTemplate(id as string, { language }),
    queryKey: [queryIds.cases.GET_CASE_TEMPLATE, id],
  });

  return {
    currentCaseTemplate: data,
    currentCaseTemplateIsFetching: isFetching,
    currentCaseTemplateIsLoading: isLoading,
  };
};

export default useGetCaseTemplate;
