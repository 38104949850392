import { Button } from '@mui/joy';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { useState } from 'react';

import {
  colorAccent,
  colorAdditional,
  colorBubble,
  colorBubbleActive,
  colorDanger,
  colorText,
} from '../../utils/palette';
import { formatUtcDate, getMomentDescription } from '../../utils/helpers';
import { MessageProps } from '../../prop-types/ChatProps';
import useToggle from '@/shared/hooks/UseToggle';

import { DeleteIcon, DeleteIconAlt, EditIcon } from '../../icons/Icons';
import AvatarWithColor from '../2.0/AvatarWithColor';
import ChatActionButton from './ChatActionButton';
import ConfirmationModal from '../ConfirmationModal';
import CustomTextFieldAdornment from '../CustomTextFieldAdorment';
import KpcTooltip from '../Tooltips/KpcTooltip';

const ChatDeleteButton = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  height: 24px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;

  svg {
    height: 12px;
    width: 12px;
    color: ${colorDanger};
    transition: 0.3s;
  }

  &:hover {
    svg {
      color: ${colorDanger};
    }
  }
`;

const ChatBubbleWrap = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;

  ${(props) =>
    props.active &&
    `
    .chat__bubble-message-wrap {
      min-width: 0;
      background-color: ${colorBubbleActive};
      border-radius: 16px 0 16px 16px;
      padding-right: 16px;
      padding-left: 16px;
    }

    .chat__bubble-avatar {
      margin-left: 10px;
      margin-right: 0;
    }

    flex-direction: row-reverse;
  `}
`;

const ChatActionButtons = styled.div`
  margin-top: 10px;
`;

const ChatBubbleAvatar = styled.div`
  width: 40px;
  min-width: 40px;
  height: 100%;
  overflow: hidden;
  display: none;
  margin-right: 10px;

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

const ChatBubbleMessageWrap = styled.div`
  max-width: 245px;
  border-radius: 0 16px 16px 16px;
  position: relative;
  padding: 8px;
  background-color: ${colorBubble};

  img {
    max-width: 215px;
  }
`;

const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorAccent};
  padding-right: 50px;
`;

const ChatBubbleDate = styled.p`
  color: ${colorAdditional};
  font-size: 10px;
  margin: 0;
  text-align: end;
  right: 36px;

  @media screen and (min-width: 1366px) {
    right: 0;
  }
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: ${colorText};
`;

const propTypes = {
  message: MessageProps.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const ChatBubble = ({
  message = {
    customUpdateModal: () => {},
  },
  onUpdate = () => {},
  onDelete = () => {},
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateValue, setUpdateValue] = useState(message.message);

  const deleteConfirmationToggle = useToggle();

  const customModal = message.customUpdateModal
    ? message.customUpdateModal({ ...message.customUpdateModalProps })
    : null;

  const handleUpdateButtonClick = () => {
    if (customModal) {
      customModal.toggle.show();
      return;
    }
    if (!message.editable) {
      return;
    }
    setUpdateValue(message.message);
    setIsUpdating((prev) => !prev);
  };

  const handleCancelUpdate = () => {
    setIsUpdating(false);
    setUpdateValue(message.message);
  };

  const handleSaveUpdate = () => {
    if (!updateValue.length) {
      return;
    }
    setIsUpdating(false);
    onUpdate(message.id, updateValue);
  };

  return (
    <ChatBubbleWrap active={message.active}>
      <ChatBubbleAvatar className="chat__bubble-avatar">
        <AvatarWithColor name={message.username} />
        <ChatActionButtons>
          {message.editable && (
            <ChatActionButton onClick={() => handleUpdateButtonClick()}>
              <EditIcon />
            </ChatActionButton>
          )}
          {message.buttons}
        </ChatActionButtons>
      </ChatBubbleAvatar>
      <ChatBubbleMessageWrap className="chat__bubble-message-wrap">
        {message.deletable && (
          <>
            <ChatDeleteButton onClick={() => deleteConfirmationToggle.show()}>
              <DeleteIcon />
            </ChatDeleteButton>
            <ConfirmationModal
              onConfirm={() => onDelete(message.id)}
              icon={<DeleteIconAlt />}
              color="danger"
              toggle={deleteConfirmationToggle}
            />
          </>
        )}
        <ChatBubbleContactName>{message.displayName}</ChatBubbleContactName>
        {!isUpdating && !customModal && (
          <ChatBubbleMessage className="break-words">{message.message}</ChatBubbleMessage>
        )}
        {customModal && (
          <Button
            variant="link"
            size="sm"
            className="no-hover color-keypoint cursor-pointer p-0 text-xs font-normal underline"
            onClick={() => handleUpdateButtonClick()}
          >
            {message.message}
          </Button>
        )}
        {isUpdating && (
          <TextField
            multiline
            variant="standard"
            value={updateValue}
            onChange={({ target }) => setUpdateValue(target.value)}
            InputProps={{
              endAdornment: (
                <CustomTextFieldAdornment
                  isDirty={updateValue !== message.message && updateValue.length}
                  onCancel={() => handleCancelUpdate()}
                  onSave={() => handleSaveUpdate()}
                />
              ),
            }}
          />
        )}
        {customModal && customModal.modal}
        <KpcTooltip
          placement="top-end"
          title={formatUtcDate(message.date, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ss')}
        >
          <ChatBubbleDate>
            {getMomentDescription(moment, message.date, 'YYYY-MM-DDTHH:mm:ss', true, false)}
          </ChatBubbleDate>
        </KpcTooltip>
      </ChatBubbleMessageWrap>
    </ChatBubbleWrap>
  );
};

ChatBubble.propTypes = propTypes;

export default ChatBubble;
