import { useCallback, useMemo } from 'react';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteOption,
  AutocompleteOptionsArgs,
  UseAutocompleteOptionsResult,
} from './autocomplete.types';
import useAutocompleteOptions from './useAutocompleteOptions';

type Enum<TValue extends string | number> = {
  [key: string]: TValue;
};

type BaseOptions<TValue extends string | number> = {
  includeValue?: (value: TValue) => boolean;
  srcEnum: Enum<TValue>;
  translationKey?: string;
};

type Options<TValue extends string | number> = BaseOptions<TValue> &
  AutocompleteOptionsArgs<TValue>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable?: boolean; multiple: true },
): UseAutocompleteOptionsResult<TValue, true>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable: true; multiple?: false },
): UseAutocompleteOptionsResult<TValue, false | undefined>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable?: false; multiple?: false },
): UseAutocompleteOptionsResult<TValue, false | undefined>;

function useAutocompleteEnum<TValue extends string | number>({
  callback,
  disableClearable,
  includeValue,
  multiple,
  selected,
  srcEnum,
  translationKey,
  ...args
}: Options<TValue>): UseAutocompleteOptionsResult<TValue, typeof multiple> {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Object.values(srcEnum)
        .filter((value) => value !== 'Unspecified' && (!includeValue || includeValue(value)))
        .map((value) => {
          const subKey = lowerFirst(value.toString());

          return {
            label: translationKey ? t(`${translationKey}.${subKey}`) : t(subKey),
            value,
          };
        }),
    [includeValue, srcEnum, t, translationKey],
  );

  const handleCallback = useCallback(
    (newValue: AutocompleteOption<TValue>[] | AutocompleteOption<TValue> | null) => {
      const multipleOptions = Array.isArray(newValue);

      if (multiple && multipleOptions) {
        callback(newValue);
      } else if (!multiple && !multipleOptions) {
        if (disableClearable && newValue) {
          callback(newValue);
        } else if (!disableClearable) {
          callback(newValue);
        }
      }
    },
    [callback, disableClearable, multiple],
  );

  const autocompleteProps = useAutocompleteOptions({
    callback: handleCallback,
    data: options,
    disableClearable,
    multiple,
    selected,
    ...args,
  });

  return autocompleteProps;
}

export default useAutocompleteEnum;
