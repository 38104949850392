enum ChBicValue {
  SNBZCHZZ = 'SNBZCHZZ',
  SECGDEFF = 'SECGDEFF',
  UBSWCHZH = 'UBSWCHZH',
  UBSBCHZZ = 'UBSBCHZZ',
  ZKBKCHZZ = 'ZKBKCHZZ',
  KBAGCH22 = 'KBAGCH22',
  AIKACH22 = 'AIKACH22',
  BSCTCH22 = 'BSCTCH22',
  BCVSCH2L = 'BCVSCH2L',
  BCNNCH22 = 'BCNNCH22',
  BCVLCH2L = 'BCVLCH2L',
  BEFRCH22 = 'BEFRCH22',
  BLKBCH22 = 'BLKBCH22',
  BKBBCHBB = 'BKBBCHBB',
  GLKBCH22 = 'GLKBCH22',
  GRKBCH22 = 'GRKBCH22',
  KBSZCH22 = 'KBSZCH22',
  LUKBCH22 = 'LUKBCH22',
  NIKACH22 = 'NIKACH22',
  OBWKCH22 = 'OBWKCH22',
  KBSGCH22 = 'KBSGCH22',
  SHKBCH2S = 'SHKBCH2S',
  KBTGCH22 = 'KBTGCH22',
  URKNCH22 = 'URKNCH22',
  KBZGCH22 = 'KBZGCH22',
  BCGECHGG = 'BCGECHGG',
  BCJUCH22 = 'BCJUCH22',
  KBBECH22 = 'KBBECH22',
  CRESCHZZ = 'CRESCHZZ',
  CRESCHZH = 'CRESCHZH',
  RBABCH22 = 'RBABCH22',
  VABECH22 = 'VABECH22',
  BSZHCHZZ = 'BSZHCHZZ',
  ACRGCH22 = 'ACRGCH22',
  ARBHCH22 = 'ARBHCH22',
  BSCHCHGG = 'BSCHCHGG',
  FSUICHGG = 'FSUICHGG',
  IVESCHZZ = 'IVESCHZZ',
  AGRICHGX = 'AGRICHGX',
  ANPRCH22 = 'ANPRCH22',
  BQBHCHGG = 'BQBHCHGG',
  FVLBCHZZ = 'FVLBCHZZ',
  POSOCH22 = 'POSOCH22',
  BILSCHZZ = 'BILSCHZZ',
  BBVACHZZ = 'BBVACHZZ',
  GOLDCHZZ = 'GOLDCHZZ',
  FBGSCHZZ = 'FBGSCHZZ',
  BHFBCHZZ = 'BHFBCHZZ',
  CMBNCHZH = 'CMBNCHZH',
  SLWYCH21 = 'SLWYCH21',
  HYPLCH22 = 'HYPLCH22',
  SYCOCHGG = 'SYCOCHGG',
  CAGYCH21 = 'CAGYCH21',
  ERSCCH21 = 'ERSCCH21',
  KBSOCH22 = 'KBSOCH22',
  BLFLCHBB = 'BLFLCHBB',
  AEXTCHZZ = 'AEXTCHZZ',
  CDEMCH21 = 'CDEMCH21',
  CDDVCH21 = 'CDDVCH21',
  SEPOCH22 = 'SEPOCH22',
  VIMMCHZZ = 'VIMMCHZZ',
  PCBCCHZZ = 'PCBCCHZZ',
  ISAECH2N = 'ISAECH2N',
  VSFACHZZ = 'VSFACHZZ',
  NEPICHZZ = 'NEPICHZZ',
  QNBACHGG = 'QNBACHGG',
  MGTCCHGG = 'MGTCCHGG',
  REYLCHGG = 'REYLCHGG',
  NBOKCHGG = 'NBOKCHGG',
  BBRUCHGT = 'BBRUCHGT',
  BABRCHGG = 'BABRCHGG',
  BZSDCH22 = 'BZSDCH22',
  ABSOCH22 = 'ABSOCH22',
  WIRBCHBB = 'WIRBCHBB',
  FRGGCHB1 = 'FRGGCHB1',
  EKIICH22 = 'EKIICH22',
  EEKBCH22 = 'EEKBCH22',
  SLBUCH22 = 'SLBUCH22',
  SLGUCH2M = 'SLGUCH2M',
  VZDBCHZZ = 'VZDBCHZZ',
  MIGRCHZZ = 'MIGRCHZZ',
  BCLRCHBB = 'BCLRCHBB',
  CBLUCH22 = 'CBLUCH22',
  BCRECH22 = 'BCRECH22',
  VRBPCH22 = 'VRBPCH22',
  SAXOCHZH = 'SAXOCHZH',
  UNCECH22 = 'UNCECH22',
  NBPSCHGG = 'NBPSCHGG',
  BAMRCHZZ = 'BAMRCHZZ',
  BAERCHZZ = 'BAERCHZZ',
  BGAGCH22 = 'BGAGCH22',
  EKRUCH21 = 'EKRUCH21',
  BBOBCH22 = 'BBOBCH22',
  SBTICH22 = 'SBTICH22',
  BTVACH22 = 'BTVACH22',
  IHZUCHZZ = 'IHZUCHZZ',
  SELVCHGG = 'SELVCHGG',
  VPBVCHZH = 'VPBVCHZH',
  BICFCHGG = 'BICFCHGG',
  BEGOCHZZ = 'BEGOCHZZ',
  BLOMCHGG = 'BLOMCHGG',
  SCOPCHBB = 'SCOPCHBB',
  CCIECHGG = 'CCIECHGG',
  BZBKCH2W = 'BZBKCH2W',
  GRAFCH22 = 'GRAFCH22',
  DREYCHBB = 'DREYCHBB',
  BONHCH22 = 'BONHCH22',
  PRIBCHGG = 'PRIBCHGG',
  TRAPCHBB = 'TRAPCHBB',
  MAEBCHZZ = 'MAEBCHZZ',
  MEDSCHGG = 'MEDSCHGG',
  RUEGCHZZ = 'RUEGCHZZ',
  BACECH22 = 'BACECH22',
  EUBACH22 = 'EUBACH22',
  GENOCHZZ = 'GENOCHZZ',
  EFGBCHGG = 'EFGBCHGG',
  NBSZCHZZ = 'NBSZCHZZ',
  BPGECHGG = 'BPGECHGG',
  AUDSCHGG = 'AUDSCHGG',
  BPCPCHGG = 'BPCPCHGG',
  BJHSCHZZ = 'BJHSCHZZ',
  CHASCHGX = 'CHASCHGX',
  CBSWCHZZ = 'CBSWCHZZ',
  UBPGCHGG = 'UBPGCHGG',
  DEUTCHGG = 'DEUTCHGG',
  RKBZCHZZ = 'RKBZCHZZ',
  ROTACHZZ = 'ROTACHZZ',
  PKBSCH22 = 'PKBSCH22',
  BASECH22 = 'BASECH22',
  EFGBCHZZ = 'EFGBCHZZ',
  BPPBCHGG = 'BPPBCHGG',
  BLICCHGG = 'BLICCHGG',
  CITICHZZ = 'CITICHZZ',
  HSBCCHZZ = 'HSBCCHZZ',
  EFGFCHZZ = 'EFGFCHZZ',
  BZPLCHZZ = 'BZPLCHZZ',
  AEKTCH22 = 'AEKTCH22',
  SGABCHZZ = 'SGABCHZZ',
  CIALCHBB = 'CIALCHBB',
  ARBSCHZZ = 'ARBSCHZZ',
  GONECHGG = 'GONECHGG',
  BOFACH2X = 'BOFACH2X',
  CRAMCHGG = 'CRAMCHGG',
  LINSCH23 = 'LINSCH23',
  BKTHCH22 = 'BKTHCH22',
  BARCCHGG = 'BARCCHGG',
  THALCHGG = 'THALCHGG',
  AGRICHGG = 'AGRICHGG',
  SARACHBB = 'SARACHBB',
  PICTCHGG = 'PICTCHGG',
  BAUMCHBB = 'BAUMCHBB',
  VONTCHZZ = 'VONTCHZZ',
  LOCYCHGG = 'LOCYCHGG',
  REICCH22 = 'REICCH22',
  BSSACHGG = 'BSSACHGG',
  BORDCHGG = 'BORDCHGG',
  MIRACHGG = 'MIRACHGG',
  GUTZCHBB = 'GUTZCHBB',
  PIGUCH22 = 'PIGUCH22',
  RAHNCHZZ = 'RAHNCHZZ',
  DEUTCHZZ = 'DEUTCHZZ',
  SWQBCHZZ = 'SWQBCHZZ',
  SLFFCH22 = 'SLFFCH22',
  RSOSCH22 = 'RSOSCH22',
  HFTCCHGG = 'HFTCCHGG',
  HBZUCHZZ = 'HBZUCHZZ',
  PARBCHZZ = 'PARBCHZZ',
  TMBECH22 = 'TMBECH22',
  INCOCHZZ = 'INCOCHZZ',
  LILALI2X = 'LILALI2X',
  NBANLI22 = 'NBANLI22',
  HYIBLI22 = 'HYIBLI22',
  VPBVLI2X = 'VPBVLI2X',
  SERBLI22 = 'SERBLI22',
  BLFLLI2X = 'BLFLLI2X',
  BFRILI22 = 'BFRILI22',
  VOAGLI22 = 'VOAGLI22',
  SFBALI22 = 'SFBALI22',
  DOMICHZZ = 'DOMICHZZ',
  CIMMCHGG = 'CIMMCHGG',
  TXBZCHZZ = 'TXBZCHZZ',
  ABSGCHGG = 'ABSGCHGG',
  CAGLCHGG = 'CAGLCHGG',
  COBACHZH = 'COBACHZH',
  BLEMCHGG = 'BLEMCHGG',
  QVCHCHZH = 'QVCHCHZH',
  ITAUCHZZ = 'ITAUCHZZ',
  GLBNCHZZ = 'GLBNCHZZ',
  DUBACHGG = 'DUBACHGG',
  SFBFCH22 = 'SFBFCH22',
  AQULCHZZ = 'AQULCHZZ',
  IGBKCHGG = 'IGBKCHGG',
  FAVSCHGG = 'FAVSCHGG',
  SUAACH22 = 'SUAACH22',
  SLAMCHZZ = 'SLAMCHZZ',
  SWRECHZH = 'SWRECHZH',
  AXIPCHZZ = 'AXIPCHZZ',
  ZURICHZF = 'ZURICHZF',
  BAAMCHBB = 'BAAMCHBB',
  FETACHZZ = 'FETACHZZ',
  RMCOCHBB = 'RMCOCHBB',
  LFFSCHGG = 'LFFSCHGG',
  SSBECHZZ = 'SSBECHZZ',
  DELECHG2 = 'DELECHG2',
  ZAPRCH22 = 'ZAPRCH22',
  INSECHZZ = 'INSECHZZ',
  EUXCDEFZ = 'EUXCDEFZ',
  SKBPCH22 = 'SKBPCH22',
  BISBCHBB = 'BISBCHBB',
  CLSBUS33 = 'CLSBUS33',
  POFICHBE = 'POFICHBE',
  IWSSCHGG = 'IWSSCHGG',
  AMNSLI22 = 'AMNSLI22',
  VRLLCHZZ = 'VRLLCHZZ',
  SYGNCHZZ = 'SYGNCHZZ',
  SCRYCH22 = 'SCRYCH22',
  ICBKCHZZ = 'ICBKCHZZ',
  JLSECHGG = 'JLSECHGG',
  FIDHCHGG = 'FIDHCHGG',
  PRBECHZZ = 'PRBECHZZ',
  CITIGB2L = 'CITIGB2L',
  RAIFCH22 = 'RAIFCH22',
  RDCTCHZH = 'RDCTCHZH',
  KLARCH22 = 'KLARCH22',
  DBSALULL = 'DBSALULL',
  MIRALULL = 'MIRALULL',
  MONZGB2L = 'MONZGB2L',
  SRLGGB3L = 'SRLGGB3L',
  HAVLLULL = 'HAVLLULL',
  HAVLMCMX = 'HAVLMCMX',
  CLRXCHZZ = 'CLRXCHZZ',
  HELNCH22 = 'HELNCH22',
  BLFLATWW = 'BLFLATWW',
  JHOTCHZZ = 'JHOTCHZZ',
  MBMECHZZ = 'MBMECHZZ',
  SDXXCHZH = 'SDXXCHZH',
  YAPECHZ2 = 'YAPECHZ2',
  CITECHGG = 'CITECHGG',
  PLESCHZZ = 'PLESCHZZ',
  FLOKCHGG = 'FLOKCHGG',
  SWIPCHZH = 'SWIPCHZH',
  TARSCHGG = 'TARSCHGG',
  APNACHGG = 'APNACHGG',
  KSEHCHGG = 'KSEHCHGG',
  PICLCHZ2 = 'PICLCHZ2',
  BKCHCHGE = 'BKCHCHGE',
  BGPBCH22 = 'BGPBCH22',
  SAHHCHZ2 = 'SAHHCHZ2',
  METZDEFF = 'METZDEFF',
  COBADEFF = 'COBADEFF',
  DEUTDEFF = 'DEUTDEFF',
  GENODEFF = 'GENODEFF',
  HASPDEHH = 'HASPDEHH',
  HELADEFF = 'HELADEFF',
  MEFIDEMM = 'MEFIDEMM',
  MSFFDEFX = 'MSFFDEFX',
  SOLADEST = 'SOLADEST',
  TUBDDEDD = 'TUBDDEDD',
  GOLDDEFA = 'GOLDDEFA',
  RBOSGB2R = 'RBOSGB2R',
  DOSPAT2D = 'DOSPAT2D',
  ESSESESS = 'ESSESESS',
  GENOLULL = 'GENOLULL',
  VOLODE66 = 'VOLODE66',
  ASPKAT2L = 'ASPKAT2L',
  GIBAATWG = 'GIBAATWG',
  RABONL2U = 'RABONL2U',
  RZOOAT2L = 'RZOOAT2L',
  CEDELULL = 'CEDELULL',
  HYPVAT2B = 'HYPVAT2B',
  BCITITMM = 'BCITITMM',
  BTVAAT22 = 'BTVAAT22',
  BILLLULL = 'BILLLULL',
  RZBAATWW = 'RZBAATWW',
  BEILLULL = 'BEILLULL',
  INGBNL2A = 'INGBNL2A',
  SPFKAT2B = 'SPFKAT2B',
  ROYCGB22 = 'ROYCGB22',
  DABADKKK = 'DABADKKK',
  RVVGAT2B = 'RVVGAT2B',
  FMBKDEMM = 'FMBKDEMM',
  GSILGB2X = 'GSILGB2X',
  HYPNATWW = 'HYPNATWW',
  IKBDDEDD = 'IKBDDEDD',
  ANTSGB2L = 'ANTSGB2L',
  NDEAFIHH = 'NDEAFIHH',
  GENODE61 = 'GENODE61',
  SKHRDE6W = 'SKHRDE6W',
  EMCFNL2A = 'EMCFNL2A',
  OEKOATWW = 'OEKOATWW',
  BLUXLULL = 'BLUXLULL',
  SKLODE66 = 'SKLODE66',
  LCHLGB2L = 'LCHLGB2L',
  ABNCNL2A = 'ABNCNL2A',
}

export default ChBicValue;
