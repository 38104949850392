import { IconButton, Input, InputProps } from '@mui/joy';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { v4 as uuidv4 } from 'uuid';

import useDayjs from '@/shared/hooks/useDayjs';

import FormField, { FieldProps } from './input/FormField';
import { ClearIcon } from '@/shared/icons/Icons';
import useToggle from '@/shared/hooks/UseToggle';

type CallbackProps =
  | {
      clearable: true;
      onChange: (date: Date | null, inputValue: string | null, cleared: boolean) => void;
    }
  | {
      clearable?: false;
      onChange: (date: Date, inputValue: string, cleared: boolean) => void;
    };

type BaseProps = {
  disabled?: boolean;
  error?: string;
  inputCallbacks?: {
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  };
  options?: {
    disablePast?: boolean;
    disableFuture?: boolean;
    // "format" prop for when the timezone doesn't get saved by the BE (date only / always returns T00:00:00).
    // Expected behavior otherwise: 27th selected (midnight utc+2) => output: 26th (22:00 utc)
    format?: string;
    minDate?: Date;
    maxDate?: Date;
  };
  readOnly?: boolean;
  value: Dayjs | Date | string | null;
} & CallbackProps;

export type DatePickerProps = BaseProps & Omit<FieldProps, 'horizontal' | 'error' | 'onChange'>;

export const BaseDatePicker: React.FC<DatePickerProps> = ({
  clearable,
  disabled = false,
  inputCallbacks = {},
  onChange,
  options = {},
  readOnly = false,
  value,
}) => {
  const [key, setKey] = useState(uuidv4());

  const { dayjs } = useDayjs();

  const calendarToggle = useToggle();

  const currentValue = useMemo(() => {
    if (!value || !dayjs(value).isValid()) return null;

    return options.format ? dayjs(value).format(options.format) : dayjs.utc(value).local();
  }, [dayjs, options.format, value]);

  const handleChange = (newValue: Date | null, cleared = false) => {
    if (clearable && (!newValue || !dayjs(newValue).isValid())) {
      onChange(null, null, cleared);

      return;
    }

    const date = dayjs(newValue).utc(true);

    if (date.isValid()) {
      const dateString = options.format ? date.format(options.format) : date.utc().toISOString();

      onChange(date.toDate(), dateString, cleared);
    }
  };

  // force remount to make sure internal input state is cleared
  const handleClear = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    calendarToggle.hide();
    handleChange(null, true);
    setKey(uuidv4());
  };

  return (
    <MuiDatePicker
      key={key}
      autoFocus={false}
      open={calendarToggle.value}
      onOpen={calendarToggle.show}
      onClose={calendarToggle.hide}
      PopperProps={{ className: 'mt-2', placement: 'top-start' }}
      InputProps={{
        className: 'h-8 text-sm rounded-md bg-white',
      }}
      disabled={disabled}
      value={currentValue}
      onChange={(newValue) => handleChange(newValue)}
      readOnly={readOnly}
      renderInput={(params) => (
        <Input
          {...(params.inputProps as InputProps)}
          ref={params.inputRef}
          fullWidth
          placeholder={dayjs().localeData().longDateFormat('L').toLocaleLowerCase()}
          onBlur={(e) => {
            params.inputProps?.onBlur?.(e);
            inputCallbacks.onBlur?.(e);
          }}
          onChange={(e) => {
            params.inputProps?.onChange?.(e);
            inputCallbacks.onChange?.(e);
          }}
          onClick={() => {
            if (!readOnly) calendarToggle.inverse();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Tab') calendarToggle.hide();
          }}
          endDecorator={
            !!clearable &&
            !readOnly &&
            !!value &&
            dayjs(value).isValid() && (
              <IconButton
                variant="plain"
                color="neutral"
                className="rounded-full"
                sx={{
                  '--IconButton-size': '24px',
                }}
                onClick={handleClear}
              >
                <ClearIcon className="text-sm" />
              </IconButton>
            )
          }
        />
      )}
      {...options}
    />
  );
};

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  error,
  label,
  required,
  ...datePickerProps
}) => (
  <FormField
    className={classNames('flex flex-grow', className)}
    error={error}
    label={label}
    required={required}
  >
    <BaseDatePicker {...datePickerProps} />
  </FormField>
);

export default DatePicker;
