import _ from 'lodash';

// Get the greatest common divisor of two numbers
export const gcd = (a: number, b: number): number => {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
};

// Get the least common multiple of two numbers
export const lcm = (a: number, b: number): number => (a * b) / gcd(a, b);

// Convert percentage to fraction
export const percentageToFraction = (percentage: number): [number, number] => {
  const precision = 10000; // Conversion with 4 decimal precision
  const numerator = Math.round(percentage * precision);
  const denominator = precision * 100;
  const divisor = gcd(numerator, denominator);
  return [numerator / divisor, denominator / divisor];
};

// Function to get the common denominator
export const percentagesToCommonDenominator = (percentages: number[]): number => {
  const fractions = _.map(percentages, percentageToFraction);
  const denominators = _.map(fractions, ([, denominator]) => denominator);
  const commonDenominator = _.reduce(denominators, (acc, denominator) => lcm(acc, denominator), 1);

  return commonDenominator;
};

// Convert a single percentage to a fraction with the common denominator
export const convertPercentageToCommonFraction = (
  percentage: number,
  commonDenominator: number,
): [number, number] => {
  const [numerator, denominator] = percentageToFraction(percentage);
  const factor = commonDenominator / denominator;
  return [numerator * factor, commonDenominator];
};
