enum FrBicValue {
  BREDFRPP = 'BREDFRPP',
  BCITFRPP = 'BCITFRPP',
  CCMRFR21 = 'CCMRFR21',
  BCHAFR21 = 'BCHAFR21',
  AGRIFRCC = 'AGRIFRCC',
  CCBPFRPP = 'CCBPFRPP',
  RABOFRPP = 'RABOFRPP',
  CMCIFRCP = 'CMCIFRCP',
  BSAVFR2C = 'BSAVFR2C',
  CHDUFRPP = 'CHDUFRPP',
  RCINFRPP = 'RCINFRPP',
  SWILFRPP = 'SWILFRPP',
  CRRHFRPA = 'CRRHFRPA',
  HRFRFRP2 = 'HRFRFRP2',
  BDEIFRPP = 'BDEIFRPP',
  BERLMCMC = 'BERLMCMC',
  CITIFRPP = 'CITIFRPP',
  CMCIFRPA = 'CMCIFRPA',
  ICBKFRPP = 'ICBKFRPP',
  CMCIFRP1 = 'CMCIFRP1',
  POUYFR21 = 'POUYFR21',
  UBSWMCMX = 'UBSWMCMX',
  BIARFRPP = 'BIARFRPP',
  AGFBFRCC = 'AGFBFRCC',
  BMCEFRPP = 'BMCEFRPP',
  SORMFR2N = 'SORMFR2N',
  BAECMCMX = 'BAECMCMX',
  BCGEFR21 = 'BCGEFR21',
  AXABFRPP = 'AXABFRPP',
  BOFAFRP3 = 'BOFAFRP3',
  CGDIFRPP = 'CGDIFRPP',
  CFMOMCMX = 'CFMOMCMX',
  BACCFR22 = 'BACCFR22',
  DELUFR22 = 'DELUFR22',
  EDELFRP1 = 'EDELFRP1',
  PSABFRPP = 'PSABFRPP',
  KBLXMCMC = 'KBLXMCMC',
  SGBTMCMC = 'SGBTMCMC',
  BMMMFRCP = 'BMMMFRCP',
  UNCRFRPP = 'UNCRFRPP',
  AUXPFR21 = 'AUXPFR21',
  BSAOFR21 = 'BSAOFR21',
  ESFTFR21 = 'ESFTFR21',
  AGRIGPGX = 'AGRIGPGX',
  LEGRFRP1 = 'LEGRFRP1',
  DXMAFRPP = 'DXMAFRPP',
  BAERMCMC = 'BAERMCMC',
  GRCSFRP1 = 'GRCSFRP1',
  WUIGFRP1 = 'WUIGFRP1',
  MONNFR21 = 'MONNFR21',
  BKIDFRPP = 'BKIDFRPP',
  POSOMCM1 = 'POSOMCM1',
  CFFIFR2L = 'CFFIFR2L',
  KOEXFRPP = 'KOEXFRPP',
  PICTFRPP = 'PICTFRPP',
  VOWAFR21 = 'VOWAFR21',
  CRMPFRP1 = 'CRMPFRP1',
  SMBCFRPP = 'SMBCFRPP',
  CNIFFR2M = 'CNIFFR2M',
  CMCIFR2A = 'CMCIFR2A',
  CMBRFR2B = 'CMBRFR2B',
  BACAMCMC = 'BACAMCMC',
  BNPAFRPL = 'BNPAFRPL',
  BIKCFRP1 = 'BIKCFRP1',
  GSCFFR22 = 'GSCFFR22',
  HAVLMCMX = 'HAVLMCMX',
  ECOCFRPP = 'ECOCFRPP',
  BGFIFRPP = 'BGFIFRPP',
  AFRIFRPP = 'AFRIFRPP',
  CMUBFR21 = 'CMUBFR21',
  BPCEFRPP = 'BPCEFRPP',
  BFOBFRP1 = 'BFOBFRP1',
  GSFHFRPP = 'GSFHFRPP',
  AXABFR21 = 'AXABFR21',
  YOUIFRP1 = 'YOUIFRP1',
  CCMOFR21 = 'CCMOFR21',
  SFILFRPP = 'SFILFRPP',
  UBPGMCMX = 'UBPGMCMX',
  ANZBFRPP = 'ANZBFRPP',
  AGFOFR21 = 'AGFOFR21',
  AGRIFRP1 = 'AGRIFRP1',
  CAVNFR21 = 'CAVNFR21',
  BPLCFRPP = 'BPLCFRPP',
  CCUTFR21 = 'CCUTFR21',
  MEMOFRP2 = 'MEMOFRP2',
  SODIRER1 = 'SODIRER1',
  BACPFRPP = 'BACPFRPP',
  CMBMMCMX = 'CMBMMCMX',
  BLOMFRPP = 'BLOMFRPP',
  BEMOFRPP = 'BEMOFRPP',
  CCMNFR21 = 'CCMNFR21',
  COBAFRPX = 'COBAFRPX',
  SEBXFRP1 = 'SEBXFRP1',
  SBAAFRPP = 'SBAAFRPP',
  DEUTFRPP = 'DEUTFRPP',
  SEPBFRPP = 'SEPBFRPP',
  GMACFR22 = 'GMACFR22',
  QNBAFRPP = 'QNBAFRPP',
  BAMYFR22 = 'BAMYFR22',
  ARABFRPP = 'ARABFRPP',
  CCMLFR21 = 'CCMLFR21',
  MIDLFRPX = 'MIDLFRPX',
  SOGEGPGP = 'SOGEGPGP',
  AUDIFRPP = 'AUDIFRPP',
  ISAEFRPP = 'ISAEFRPP',
  CRESFRPP = 'CRESFRPP',
  CGCPFRPL = 'CGCPFRPL',
  CPMEFRPP = 'CPMEFRPP',
  GPBAFRPP = 'GPBAFRPP',
  CMROFR21 = 'CMROFR21',
  CCMTFR21 = 'CCMTFR21',
  MHCBFRPP = 'MHCBFRPP',
  BMISFRPP = 'BMISFRPP',
  FRAFFRPP = 'FRAFFRPP',
  BFCORERX = 'BFCORERX',
  EFGBMCMC = 'EFGBMCMC',
  BKCHFRPP = 'BKCHFRPP',
  ICBCFRPP = 'ICBCFRPP',
  FEMUFR21 = 'FEMUFR21',
  CSCAFR21 = 'CSCAFR21',
  ABCOFRPP = 'ABCOFRPP',
  IVSTFRP1 = 'IVSTFRP1',
  KBLXFRPP = 'KBLXFRPP',
  BSABFRPP = 'BSABFRPP',
  SFAXFRPP = 'SFAXFRPP',
  AGRIFRPI = 'AGRIFRPI',
  PROCFRPP = 'PROCFRPP',
  UFIFFRP1 = 'UFIFFRP1',
  NOMAFRPP = 'NOMAFRPP',
  AGRIMQMX = 'AGRIMQMX',
  SOAPFR22 = 'SOAPFR22',
  AGRIRERX = 'AGRIRERX',
  PSSTFRPP = 'PSSTFRPP',
  STFEFR21 = 'STFEFR21',
  CPBIFRPP = 'CPBIFRPP',
  BYBBFRPP = 'BYBBFRPP',
  BCMAFRPP = 'BCMAFRPP',
  UBSWFRPP = 'UBSWFRPP',
  BJSBMCMX = 'BJSBMCMX',
  PRIVFRPP = 'PRIVFRPP',
  BCIBFRP1 = 'BCIBFRP1',
  NSMBFRPP = 'NSMBFRPP',
  KREDFRPP = 'KREDFRPP',
  CMDIFR21 = 'CMDIFR21',
  CRLYFRPP = 'CRLYFRPP',
  SOGEFRPP = 'SOGEFRPP',
  BNPAFRPP = 'BNPAFRPP',
  AGRIFRPP = 'AGRIFRPP',
  NATXFRPP = 'NATXFRPP',
  CFFOFRCC = 'CFFOFRCC',
  CCFRFRPP = 'CCFRFRPP',
  CMCIFRPP = 'CMCIFRPP',
  BATIFRP1 = 'BATIFRP1',
  INGBFRPP = 'INGBFRPP',
  MONTFRPP = 'MONTFRPP',
  BARCFRPP = 'BARCFRPP',
  CHASFRPP = 'CHASFRPP',
  LAZPFRPP = 'LAZPFRPP',
  BSUIFRPP = 'BSUIFRPP',
  CDCGFRPP = 'CDCGFRPP',
  BICFFRPP = 'BICFFRPP',
  BOUSFRPP = 'BOUSFRPP',
  BSPFFRPP = 'BSPFFRPP',
  BBVAFRPP = 'BBVAFRPP',
  BOFAFRPP = 'BOFAFRPP',
  BOTKFRPX = 'BOTKFRPX',
  BCDMFRPP = 'BCDMFRPP',
  NBOKFRPP = 'NBOKFRPP',
  LICOFRPP = 'LICOFRPP',
  BNPARERX = 'BNPARERX',
  COFIFRCP = 'COFIFRCP',
  MOEYFRPP = 'MOEYFRPP',
  CFFRFRPP = 'CFFRFRPP',
  SIFBFRP1 = 'SIFBFRP1',
  SIBLFRPP = 'SIBLFRPP',
  UBAFFRPP = 'UBAFFRPP',
  ESCBFRPP = 'ESCBFRPP',
  BSCHFRPP = 'BSCHFRPP',
  ODDOFRCP = 'ODDOFRCP',
}

export default FrBicValue;
