import { ActionType, EditingMode } from 'ka-table/enums';
import { Autocomplete, Popper, TextField } from '@mui/material';
import { Button, Tooltip } from '@mui/joy';
import {
  closeRowEditors,
  hideLoading,
  hideNewRow,
  openRowEditors,
  saveNewRow,
  saveRowEditors,
  showLoading,
  showNewRow,
  updateData,
  updateEditorValue,
} from 'ka-table/actionCreators';
import { Col, Row } from 'react-bootstrap';
import { kaReducer, Table } from 'ka-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaseInGroupRelation } from '@/types/cases';
import { Nullable } from '~/common/types';
import { VatCode } from '~/common/enums';

import {
  arraysAreEqual,
  decapitalize,
  distinct,
  formatAsCurrency,
  getEnumValues,
  getVatPercentage,
  getVatPercentageDisplay,
  isEmptyObject,
  isNullOrEmpty,
  isObject,
  isString,
  makeId,
  roundTo,
} from '@/shared/utils/helpers';
import { colorRed, colorText } from '@/shared/utils/palette';
import useToggle from '@/shared/hooks/UseToggle';

import {
  AddIcon,
  CancelIcon,
  EditIcon,
  InfoIcon,
  RefreshIcon,
  SaveIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import AutocompleteEditor from '@/shared/components/table/editors/AutocompleteEditor';
import AvatarWithColor from '@/shared/components/2.0/AvatarWithColor';
import ChangeLineItemGroupModal from './ChangeLineItemGroupModal';
import DeleteAction from '@/shared/components/DeleteAction';
import EnumEditor from '@/shared/components/table/editors/EnumEditor';
import IconButton from '@/shared/components/buttons/IconButton';
import KeypointTableDetailsButton from '@/shared/components/table/components/KeypointTableDetailsButton';
import KpcCard from '@/shared/components/layout/KpcCard';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import NumberEditor from '@/shared/components/table/editors/NumberEditor';
import StringEditor from '@/shared/components/table/editors/StringEditor';

type TotalAmountColProps = {
  children: React.ReactNode;
  error?: boolean;
  md: number;
};

const TotalAmountCol: React.FC<TotalAmountColProps> = ({ children, error = false, md }) => {
  const color = error ? colorRed : colorText;
  return (
    <Col md={md} className={`text-end text-base font-bold text-[${color}]`}>
      {children}
    </Col>
  );
};

type CustomButton = {
  action: () => void;
  disabled: boolean;
  iconComponent: React.ReactNode;
  tooltip: string;
};

export type LineItem = {
  description: Nullable<string>;
  groupName: Nullable<string>;
  id: string;
  isDelete: boolean;
  isFee: boolean;
  quantity: Nullable<number>;
  receiver: Nullable<CaseInGroupRelation>;
  rowOrder: number;
  subText: Nullable<string>;
  totalAmount: Nullable<number>;
  unit: Nullable<string>;
  unitPrice: Nullable<number>;
  vat: Nullable<VatCode>;
  vatAmount: Nullable<number>;
};

type FormattedLineItem = {
  invalid: boolean;
  isDirty: boolean;
  isNew: boolean;
} & LineItem;

type Totals = {
  grossAmount: Nullable<number>;
  grossError: boolean;
  isDirty: boolean;
  netAmount: Nullable<number>;
  netError: boolean;
  vatAmount: Nullable<number>;
  vatError: boolean;
};

type Group = {
  groupName: Nullable<string>;
  id: Nullable<string>;
};

type LineItemsProps = {
  autoSave?: boolean;
  defaultReceiverCompanyId?: Nullable<string>;
  grossAmount?: Nullable<number>;
  isEditable?: boolean;
  lineItems: LineItem[];
  netAmount?: Nullable<number>;
  onSave: (lineItems: LineItem[], isDirty: boolean) => void;
  possibleRelations?: CaseInGroupRelation[];
  showReceiverColumn?: boolean;
  title?: string;
  vatAmount?: Nullable<number>;
  customActionButtons?: CustomButton[];
};

const LineItems: React.FC<LineItemsProps> = ({
  autoSave = false,
  customActionButtons = [],
  defaultReceiverCompanyId = null,
  grossAmount = null,
  isEditable = false,
  lineItems,
  netAmount = null,
  onSave,
  possibleRelations = [],
  title = null,
  vatAmount = null,
}) => {
  const [formattedData, setFormattedData] = useState<FormattedLineItem[]>([]);
  const [rowToEdit, setRowToEdit] = useState<Nullable<FormattedLineItem>>(null);
  const [editRowGroupData, setEditRowGroupData] = useState<Group>({
    groupName: null,
    id: null,
  });
  const [totals, setTotals] = useState<Totals>({
    grossAmount,
    grossError: false,
    isDirty: false,
    netAmount,
    netError: false,
    vatAmount,
    vatError: false,
  });

  const rowToEditRef = useRef();

  const { t } = useTranslation(['common', 'errors', 'finance']);

  const groupToggle = useToggle();

  const defaultReceiverRelationId = useMemo(() => {
    if (defaultReceiverCompanyId == null || !possibleRelations?.length) {
      return null;
    }
    const defaultReceiverRelation = possibleRelations.find(
      (rel) => rel.companyId === defaultReceiverCompanyId,
    );
    const defaultRequestor = possibleRelations.find((rel) => rel.isRequestor === true);
    return defaultReceiverRelation ?? defaultRequestor ?? possibleRelations[0];
  }, [defaultReceiverCompanyId, possibleRelations]);

  const calculateTotals = useCallback((lineItemsToCalculate: FormattedLineItem[]) => {
    const calculatedTotals = {
      grossAmount: 0,
      grossError: false,
      isDirty: true,
      netAmount: 0,
      netError: false,
      vatAmount: 0,
      vatError: false,
    };
    lineItemsToCalculate
      .filter((li) => !li.isDelete)
      .forEach((li) => {
        calculatedTotals.netAmount += li.totalAmount ?? 0;
        calculatedTotals.vatAmount += li.vatAmount ?? 0;
      });

    calculatedTotals.grossAmount = calculatedTotals.netAmount + calculatedTotals.vatAmount;

    return calculatedTotals;
  }, []);

  const updateLineItems = useCallback(
    (formatted: FormattedLineItem[]) => {
      if (arraysAreEqual(formatted, formattedData)) {
        return;
      }
      setFormattedData(formatted);
      if (autoSave) {
        const currentTotals = calculateTotals(formatted);
        setTotals(currentTotals);
        onSave(formatted, currentTotals.isDirty);
      }
      // Dependency on 'formattedData' is not necessary here because it is only used inside an if condition
      // Since it is not used or referenced elsewhere, it is not necessary to add it as a dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [autoSave, calculateTotals, onSave],
  );

  const defaultOptions = {
    columns: [
      {
        isEditable: false,
        isSortable: false,
        key: 'invalid',
        style: { padding: 0 },
        width: 14,
      },
      {
        isEditable,
        isSortable: false,
        key: 'description',
        title: t('description'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'quantity',
        style: { textAlign: 'right' },
        title: t('finance:quantity'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'unitPrice',
        style: { textAlign: 'right' },
        title: t('finance:unitPrice'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'unit',
        title: t('finance:unit'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'vat',
        title: t('vatPercentage'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'vatAmount',
        style: { textAlign: 'right' },
        title: t('finance:vatAmount'),
      },
      {
        isEditable,
        isSortable: false,
        key: 'totalAmount',
        style: { textAlign: 'right' },
        title: t('finance:totalAmount'),
      },
      {
        isEditable: false,
        isSortable: false,
        key: 'groupName',
      },
      {
        isSortable: false,
        key: 'receiver',
        title: t('receiver'),
        visible: possibleRelations?.length > 0,
      },
      {
        key: 'id',
        width: isEditable ? 141 : 44,
      },
    ],
    editingMode: autoSave ? EditingMode.None : EditingMode.Cell,
    groups: [{ columnKey: 'groupName' }],
    rowKeyField: 'id',
    rowReordering: isEditable,
    /* @ts-ignore */
    validation: ({ column, value }) => {
      switch (column.key) {
        case 'description':
          return value && value.length ? '' : t('errors:fieldIsRequired');
        case 'quantity':
          if (autoSave && value == null) {
            return t('errors:fieldIsRequired');
          }
          return value == null || value >= 0 ? '' : t('errors:valueMustBePositive');
        case 'unitPrice':
        case 'unit':
        case 'vat':
        case 'vatAmount':
        case 'totalAmount':
          if (autoSave) {
            return value != null ? '' : t('errors:fieldIsRequired');
          }
          return null;
        case 'receiver':
          if (possibleRelations?.length > 0 && value?.id == null) {
            return t('errors:fieldIsRequired');
          }
          return null;
        default:
          return null;
      }
    },
  };

  const checkLineTotalsAreInvalid = (lineItem: LineItem) => {
    const { quantity, unitPrice, totalAmount, vatAmount: liVatAmount, vat } = lineItem;
    if (
      quantity == null ||
      unitPrice == null ||
      totalAmount == null ||
      liVatAmount == null ||
      vat == null
    )
      return true;
    const newTotal = roundTo(+(quantity * unitPrice), 2);

    return (
      newTotal !== totalAmount ||
      roundTo(+(newTotal * (getVatPercentage(vat!)! / 100)), 2) !== liVatAmount
    );
  };

  const updateLineItemProp = useCallback(
    (id: string, prop: string, value: unknown) => {
      const lineItem = formattedData.find((li) => li.id === id);
      const newValues = {
        ...lineItem,
        isDirty: true,
        [prop]: value,
      };
      newValues.invalid = checkLineTotalsAreInvalid(newValues as LineItem);

      updateLineItems(
        formattedData.map((li) => (li.id === id ? (newValues as FormattedLineItem) : li)),
      );
    },
    [updateLineItems, formattedData],
  );

  useEffect(() => {
    const lineItemTotals = { netAmount: 0, vatAmount: 0 };

    formattedData
      .filter((li) => !li.isDelete)
      .forEach((li) => {
        lineItemTotals.netAmount += li.totalAmount ?? 0;
        lineItemTotals.vatAmount += li.vatAmount ?? 0;
      });

    lineItemTotals.netAmount = +roundTo(lineItemTotals.netAmount, 2);
    lineItemTotals.vatAmount = +roundTo(lineItemTotals.vatAmount, 2);

    if (formattedData.length > 0 && formattedData.every((li) => li.isNew || li.isDelete)) {
      setTotals(calculateTotals(formattedData));
      return;
    }

    setTotals((prev) => ({
      ...prev,
      grossError: lineItemTotals.netAmount + lineItemTotals.vatAmount !== prev.grossAmount,
      netError: lineItemTotals.netAmount !== prev.netAmount,
      vatError: lineItemTotals.vatAmount !== prev.vatAmount,
    }));
  }, [calculateTotals, formattedData]);

  /* @ts-ignore */
  const getDetailRow = useCallback(
    (data) => {
      const { rowData } = data;

      if (!isEditable) return rowData.subtext;

      return (
        <TextField
          fullWidth
          multiline
          onChange={({ target }) => updateLineItemProp(rowData.id, 'subtext', target.value)}
          /* @ts-ignore */
          placeholder={t('details')}
          value={rowData.subtext ?? ''}
          variant="standard"
        />
      );
    },
    [isEditable, t, updateLineItemProp],
  );

  useEffect(() => {
    const format: (lineItem: LineItem) => FormattedLineItem = (lineItem) => {
      const { groupName, receiver, isFee } = lineItem;

      const formattedTotal = autoSave
        ? roundTo(+((lineItem.quantity || 0) * (lineItem.unitPrice || 0)), 2)
        : lineItem.totalAmount;

      const formattedVat = autoSave
        ? roundTo(+((formattedTotal || 0) * ((getVatPercentage(lineItem.vat) || 0) / 100)), 2)
        : lineItem.vatAmount;

      const formattedLineItem = {
        ...lineItem,
        groupName: groupName ?? '',
        isDelete: false,
        isDirty: false,
        isNew: false,
        receiver: isFee ? receiver : receiver ?? defaultReceiverRelationId,
        totalAmount: formattedTotal,
        vatAmount: formattedVat,
      };

      return {
        ...formattedLineItem,
        invalid: checkLineTotalsAreInvalid(formattedLineItem),
      };
    };

    updateLineItems(lineItems.map(format));
  }, [updateLineItems, lineItems, autoSave, defaultReceiverRelationId]);

  /* @ts-ignore */
  const getCustomCells = useCallback(
    (data) => {
      function handleDeleteItem(lineItem: FormattedLineItem) {
        if (lineItem.isNew) {
          updateLineItems(formattedData.filter((li) => li.id !== lineItem.id));
          return;
        }

        updateLineItemProp(lineItem.id, 'isDelete', true);
      }

      const { column, value, rowData, dispatch } = data;

      if (value == null) {
        return '-';
      }

      switch (column.key) {
        case 'invalid':
          return value ? (
            <KpcTooltip title={t('errors:lineIsInvalid')} style={{ zIndex: '90' }}>
              <WarningIcon color="danger" />
            </KpcTooltip>
          ) : (
            <div />
          );
        case 'quantity':
          return roundTo(value, 2);
        case 'vatAmount':
        case 'unitPrice':
        case 'totalAmount':
          return formatAsCurrency(value, true);
        case 'vat':
          return (
            <KpcTooltip title={t(`${decapitalize(value)}`)} disableInteractive>
              {getVatPercentageDisplay(value)}
            </KpcTooltip>
          );
        case 'receiver': {
          if (value?.displayName == null) return '-';
          return (
            <Tooltip
              style={{ width: 'fit-content' }}
              arrow
              placement="top-start"
              title={value.displayName}
            >
              <div>
                <AvatarWithColor size="sm" name={value.displayName} />
              </div>
            </Tooltip>
          );
        }
        case 'id':
          return (
            <div className="d-flex justify-content-end">
              <KpcTooltip title={t('details')} disableInteractive>
                <KeypointTableDetailsButton
                  color="keypoint"
                  customIconComponent={
                    <InfoIcon iconStyle={isNullOrEmpty(rowData.subtext) ? 'fad' : 'fa'} />
                  }
                  {...data}
                />
              </KpcTooltip>
              {isEditable && (
                <>
                  {!rowData.isFee && (
                    /* @ts-ignore */
                    <IconButton
                      iconComponent={<EditIcon />}
                      onClick={() => {
                        dispatch(openRowEditors(value));
                        setRowToEdit(rowData);
                      }}
                      color="keypoint"
                      tooltip={t('edit')}
                    />
                  )}
                  {/* @ts-ignore */}
                  <IconButton
                    icon="text"
                    onClick={() => {
                      setEditRowGroupData({ groupName: rowData.groupName, id: value });
                      groupToggle.show();
                    }}
                    color="keypoint"
                    tooltip={t('changeGroup')}
                  />
                  {!rowData.isFee && <DeleteAction onDelete={() => handleDeleteItem(rowData)} />}
                </>
              )}
            </div>
          );
        default:
          return value;
      }
    },
    [updateLineItems, formattedData, isEditable, t, updateLineItemProp],
  );

  const onChangeGroupName = (groupName: Nullable<string>) => {
    const matchedGroupName = formattedData
      .filter((li) => !li.isDelete)
      .find((li) => li.groupName?.toLowerCase() === groupName?.toLowerCase())?.groupName;

    if (editRowGroupData?.id) {
      updateLineItemProp(editRowGroupData.id, 'groupName', matchedGroupName ?? groupName ?? '');
    }
    setEditRowGroupData({
      groupName: null,
      id: null,
    });
    groupToggle.hide();
  };

  const predefinedDescriptions = ['/u', '/m²', 'sog', 'lm', '/st'].sort((a, b) =>
    a.localeCompare(b),
  );

  const NEW_ROW_KEY = '-1';
  const [tableProps, changeTableProps] = useState(defaultOptions);

  /* @ts-ignore */
  const dispatch = useCallback(
    (action) => {
      changeTableProps((prevState) => {
        if (
          isString(action.rowKeyValue) &&
          action.rowKeyValue !== NEW_ROW_KEY &&
          /* @ts-ignore */
          !prevState.data.map((s) => s.id).includes(action.rowKeyValue)
        ) {
          return prevState;
        }

        const newState = kaReducer(prevState, action);

        const { type } = action;
        const { columns } = newState;
        switch (type) {
          case ActionType.OpenEditor: {
            /* @ts-ignore */
            if (!columns.some((col) => col.key === action.columnKey && col.isEditable === true)) {
              return prevState;
            }
            break;
          }
          case ActionType.ShowNewRow:
            /* @ts-ignore */
            if (rowToEdit?.id != null) {
              /* @ts-ignore */
              dispatch(closeRowEditors(rowToEdit.id));
            }
            setRowToEdit({
              description: null,
              groupName: null,
              id: NEW_ROW_KEY,
              invalid: false,
              isDelete: false,
              isDirty: false,
              isFee: false,
              isNew: false,
              quantity: 1,
              receiver: null,
              rowOrder: formattedData.length,
              subText: null,
              totalAmount: 0,
              unit: null,
              unitPrice: 0,
              vat: VatCode.InlandExclusive21,
              vatAmount: 0,
            });
            break;
          case ActionType.SaveNewRow: {
            dispatch(showLoading());
            /* @ts-ignore */
            const newRow = newState.data.find((row) => row[newState.rowKeyField] === NEW_ROW_KEY);
            const activeData = formattedData.filter((li) => !li.isDelete);
            const filteredData = activeData.filter((li) => li.groupName === '');

            const maxRowOrder =
              filteredData.length === 0
                ? /* @ts-ignore */
                  Math.max(...activeData.map((li) => li.rowOrder))
                : /* @ts-ignore */
                  Math.max(...filteredData.map((li) => li.rowOrder));
            const newRowOrder = activeData.length === 0 ? 0 : maxRowOrder;
            if (newRow != null) {
              const id = makeId(5);
              updateLineItems([
                ...formattedData,
                {
                  ...newRow,
                  groupName: '',
                  id,
                  invalid: checkLineTotalsAreInvalid(newRow),
                  isNew: true,
                  rowOrder: newRowOrder + 1,
                },
              ]);

              formattedData
                /* @ts-ignore */
                .filter((li) => li.rowOrder > newRowOrder)
                /* @ts-ignore */
                .forEach((li) => updateLineItemProp(li.id, 'rowOrder', li.rowOrder + 1));

              setRowToEdit(null);
              dispatch(hideNewRow());
            }

            dispatch(hideLoading());
            break;
          }
          case ActionType.SaveRowEditors: {
            const { editableCells } = newState;
            const editedCells = editableCells.filter(
              /* @ts-ignore */
              (cell) => cell.rowKeyValue === action.rowKeyValue,
            );
            /* @ts-ignore */
            if (
              editedCells.length > 0 &&
              editedCells.every(
                (c) => c.validationMessage == null || c.validationMessage.length > 0,
              )
            ) {
              return newState;
            }
            dispatch(showLoading());
            /* @ts-ignore */
            const originalRow = prevState.data.find(
              (row) => row[prevState.rowKeyField] === action.rowKeyValue,
            );
            /* @ts-ignore */
            const updatedRow = newState.data.find(
              (row) => row[newState.rowKeyField] === action.rowKeyValue,
            );
            if (updatedRow != null) {
              if (originalRow !== updatedRow) {
                updateLineItems(
                  formattedData.map((li) =>
                    li.id === originalRow.id
                      ? {
                          ...updatedRow,
                          invalid: checkLineTotalsAreInvalid(updatedRow),
                          isDirty: true,
                        }
                      : li,
                  ),
                );
              }

              if (rowToEdit?.id === originalRow.id) {
                setRowToEdit(null);
              }

              dispatch(closeRowEditors(action.rowKeyValue));
            }
            dispatch(hideLoading());
            break;
          }
          case ActionType.UpdateEditorValue: {
            /* @ts-ignore */
            setRowToEdit((prev) => ({ ...prev, [action.columnKey]: action.value }));
            break;
          }
          case ActionType.ReorderRows: {
            const { rowKeyValue, targetRowKeyValue } = action;

            const row = formattedData.find((d) => d.id === rowKeyValue);
            const target = formattedData.find((d) => d.id === targetRowKeyValue);

            const { data } = newState;
            /* @ts-ignore */
            data.forEach((li, index) => {
              const lineItem = formattedData.find((i) => i.id === li.id);
              if (lineItem == null) return;
              lineItem.rowOrder = index;
              lineItem.isDirty = true;
            });
            /* @ts-ignore */
            row.groupName = target.groupName;

            break;
          }
          default:
            return newState;
        }

        return newState;
      });
    },
    [updateLineItems, formattedData, rowToEdit?.id, updateLineItemProp],
  );

  /* @ts-ignore */
  const updateVatAmount = useCallback(
    (rowKeyValue, totalAmount, vatEnum) => {
      if (vatEnum == null) return;

      dispatch(
        updateEditorValue(
          rowKeyValue,
          'vatAmount',
          roundTo(+(totalAmount * (getVatPercentage(vatEnum)! / 100)), 2),
        ),
      );
    },
    [dispatch],
  );

  /* @ts-ignore */
  const onQuantityChanged = useCallback(
    (rowKeyValue, isNew, newValue) => {
      /* @ts-ignore */
      if ((!autoSave && !isNew && isNew != null) || rowToEdit?.unitPrice == null) return;
      /* @ts-ignore */
      const newTotal = roundTo(+(newValue * rowToEdit.unitPrice), 2);
      dispatch(updateEditorValue(rowKeyValue, 'totalAmount', newTotal));
      /* @ts-ignore */
      updateVatAmount(rowKeyValue, newTotal, rowToEdit.vat);
    },
    [autoSave, dispatch, rowToEdit, updateVatAmount],
  );

  /* @ts-ignore */
  const onUnitPriceChanged = useCallback(
    (rowKeyValue, isNew, newValue) => {
      if ((!autoSave && !isNew && isNew != null) || rowToEdit?.quantity == null) return;

      const newTotal = roundTo(+(rowToEdit.quantity * newValue), 2);
      dispatch(updateEditorValue(rowKeyValue, 'totalAmount', newTotal));
      updateVatAmount(rowKeyValue, newTotal, rowToEdit.vat);
    },
    [autoSave, dispatch, rowToEdit, updateVatAmount],
  );

  /* @ts-ignore */
  const onTotalAmountChanged = useCallback(
    (rowKeyValue, isNew, newValue: number) => {
      if (
        (!autoSave && !isNew && isNew != null) ||
        (rowToEdit?.quantity == null && rowToEdit?.unitPrice == null)
      )
        return;

      updateVatAmount(rowKeyValue, newValue, rowToEdit.vat);

      if (rowToEdit?.quantity == null) {
        dispatch(
          updateEditorValue(
            rowKeyValue,
            'quantity',
            roundTo(+(newValue / rowToEdit.unitPrice!), 2),
          ),
        );
        return;
      }

      dispatch(
        updateEditorValue(rowKeyValue, 'unitPrice', roundTo(+(newValue / rowToEdit.quantity!), 2)),
      );
    },
    [dispatch, autoSave, rowToEdit, updateVatAmount],
  );

  /* @ts-ignore */
  const onVatChanged = useCallback(
    (rowKeyValue, isNew, newValue) => {
      if ((!autoSave && !isNew && isNew != null) || rowToEdit?.totalAmount == null) return;

      updateVatAmount(rowKeyValue, rowToEdit.totalAmount, newValue);
    },
    [autoSave, rowToEdit, updateVatAmount],
  );

  /* @ts-ignore */
  const onReceiverChanged = useCallback(
    (rowKeyValue, isNew, newValue) => {
      if (!autoSave && !isNew && isNew != null) return;

      dispatch(updateEditorValue(rowKeyValue, 'receiver', newValue));
    },
    [dispatch, autoSave],
  );

  useEffect(() => {
    if (
      rowToEdit != null &&
      rowToEditRef.current !== rowToEdit?.id &&
      rowToEditRef.current !== null
    ) {
      if (rowToEditRef.current === NEW_ROW_KEY) dispatch(hideNewRow());
      if (rowToEditRef.current !== NEW_ROW_KEY) dispatch(closeRowEditors(rowToEditRef.current));
    }
    /* @ts-ignore */
    rowToEditRef.current = rowToEdit?.id;
  }, [dispatch, rowToEdit]);

  useEffect(() => {
    dispatch(
      updateData(
        formattedData.sort((a, b) => a.rowOrder - b.rowOrder).filter((li) => !li.isDelete),
      ),
    );
  }, [formattedData, dispatch]);

  const newRowSaveButton = useMemo(
    () => (
      <div style={{ display: 'flex' }}>
        {/* @ts-ignore */}
        <IconButton
          iconComponent={<SaveIcon />}
          color="keypoint"
          tooltip={t('save')}
          onClick={() => dispatch(saveNewRow(NEW_ROW_KEY, { validate: true }))}
        />
        {/* @ts-ignore */}
        <IconButton
          iconComponent={<CancelIcon />}
          color="red"
          tooltip={t('cancel')}
          onClick={() => dispatch(hideNewRow())}
        />
      </div>
    ),
    [dispatch, t],
  );

  /* @ts-ignore */
  const getEditRowSaveButton = useCallback(
    (key) => (
      <div style={{ display: 'flex' }}>
        {/* @ts-ignore */}
        <IconButton
          iconComponent={<SaveIcon />}
          color="keypoint"
          tooltip={t('save')}
          onClick={() => dispatch(saveRowEditors(key, { validate: true }))}
        />
        {/* @ts-ignore */}
        <IconButton
          iconComponent={<CancelIcon />}
          color="red"
          tooltip={t('cancel')}
          onClick={() => {
            dispatch(closeRowEditors(key));
            setRowToEdit(null);
          }}
        />
      </div>
    ),
    [dispatch, t],
  );

  const getEditableCells = useCallback(
    /* @ts-ignore */
    (data) => {
      const { column, rowKeyValue, rowData, value, validationMessage } = data;
      /* @ts-ignore */
      const popperComp = (props) => (
        <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />
      );
      const isEditingRow = rowKeyValue === rowToEdit?.id;

      switch (column.key) {
        case 'description':
          return (
            <StringEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
            />
          );
        case 'quantity':
          return (
            <NumberEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
              onChange={(newValue) => onQuantityChanged(rowKeyValue, rowData.isNew, newValue)}
            />
          );
        case 'unitPrice':
          return (
            <NumberEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
              onChange={(newValue) => onUnitPriceChanged(rowKeyValue, rowData.isNew, newValue)}
            />
          );
        case 'vatAmount':
          return (
            <NumberEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
            />
          );
        case 'totalAmount':
          return (
            <NumberEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
              onChange={(newValue) => onTotalAmountChanged(rowKeyValue, rowData.isNew, newValue)}
            />
          );
        case 'unit': {
          return (
            <AutocompleteEditor
              /* @ts-ignore */
              options={predefinedDescriptions}
              label={t('finance:unit')}
              getValue={(val) => val}
              isOptionEqualToValue={() => true}
              freeSolo
              fullWidth
              hideSaveButtons={isEditingRow}
              column={column}
              rowKeyValue={rowKeyValue}
              value={value}
              validationMessage={validationMessage}
              dispatch={dispatch}
              disableAutoFocus={isEditingRow}
            />
          );
        }
        case 'vat':
          return (
            <EnumEditor
              hideSaveButtons={isEditingRow}
              enumValues={getEnumValues(VatCode)}
              onChange={(newValue) => onVatChanged(rowKeyValue, rowData.isNew, newValue)}
              /* @ts-ignore */
              PopperComponent={popperComp}
              column={column}
              rowKeyValue={rowKeyValue}
              value={value}
              validationMessage={validationMessage}
              dispatch={dispatch}
              disableAutoFocus={isEditingRow}
            />
          );
        case 'receiver':
          return (
            <Autocomplete
              noOptionsText={t('noOptions')}
              onChange={(_, newValue) => onReceiverChanged(rowKeyValue, rowData.isNew, newValue)}
              options={possibleRelations}
              value={value}
              getOptionLabel={(opt) => {
                if (opt.displayName == null) {
                  return possibleRelations?.find((rel) => rel.id === opt)?.displayName ?? '';
                }
                return opt.displayName;
              }}
              renderInput={(params) => (
                <TextField
                  required
                  label={t('receiver')}
                  variant="standard"
                  autoFocus
                  error={validationMessage != null}
                  helperText={validationMessage}
                  {...params}
                />
              )}
              isOptionEqualToValue={(opt, val) => opt === val || opt.id === val}
            />
          );
        default:
          return (
            <StringEditor
              {...data}
              hideSaveButtons={isEditingRow}
              disableAutoFocus={isEditingRow}
            />
          );
      }
    },
    [
      possibleRelations,
      dispatch,
      onQuantityChanged,
      onTotalAmountChanged,
      onUnitPriceChanged,
      onVatChanged,
      onReceiverChanged,
      predefinedDescriptions,
      rowToEdit?.id,
      t,
    ],
  );

  const getChildComponents = useCallback(() => {
    let components = {
      cellEditor: {
        content: getEditableCells,
      },
      cellText: {
        content: getCustomCells,
      },
      detailsRow: {
        content: getDetailRow,
      },
      noDataRow: {
        content: () => t('noDataToDisplay'),
      },
    };

    const { cellEditor } = components;
    const newCellEditor = {
      /* @ts-ignore */
      content: (data) => {
        const { column, rowKeyField, rowKeyValue } = data;
        if (column.key === rowKeyField) {
          if (isObject(rowKeyValue) && isEmptyObject(rowKeyValue)) {
            return newRowSaveButton;
          }
          return getEditRowSaveButton(rowKeyValue);
        }
        return cellEditor != null ? cellEditor.content(data) : null;
      },
    };
    /* @ts-ignore */
    components = { ...components, cellEditor: newCellEditor };
    return components;
  }, [getCustomCells, getDetailRow, getEditRowSaveButton, getEditableCells, newRowSaveButton, t]);

  const cardActionButtons = useMemo(() => {
    const buttons: CustomButton[] = [
      {
        action: () => dispatch(showNewRow()),
        disabled: !isEditable,
        iconComponent: <AddIcon />,
        tooltip: t('add'),
      },
      ...customActionButtons,
    ];
    if (autoSave) {
      return buttons;
    }
    return [
      ...buttons,
      {
        action: () => setTotals(calculateTotals(formattedData)),
        disabled: !isEditable,
        iconComponent: <RefreshIcon />,
        tooltip: t('finance:calculateTotals'),
      },
    ];
  }, [autoSave, calculateTotals, customActionButtons, dispatch, formattedData, isEditable, t]);

  return (
    <KpcCard title={title ?? t('lineItems')} buttons={cardActionButtons} buttonsToShowInline={3}>
      {/* @ts-ignore */}
      <Table {...tableProps} childComponents={getChildComponents()} dispatch={dispatch} />
      <div style={{ paddingRight: '161px' }}>
        <Row>
          <Col />
          <Col md={2}>{t('finance:netTotal')}</Col>
          <TotalAmountCol md={2} error={totals.netError}>
            {formatAsCurrency(totals.netAmount, true)}
          </TotalAmountCol>
        </Row>
        <Row>
          <Col />
          <Col md={2}>{t('finance:vatTotal')}</Col>
          <TotalAmountCol md={2} error={totals.vatError}>
            {formatAsCurrency(totals.vatAmount, true)}
          </TotalAmountCol>
        </Row>
        <Row>
          <Col />
          <Col md={2}>{t('finance:grossTotal')}</Col>
          <TotalAmountCol md={2} error={totals.grossError}>
            {formatAsCurrency(totals.grossAmount, true)}
          </TotalAmountCol>
        </Row>
      </div>
      {!autoSave && (
        <Button
          startDecorator={<SaveIcon />}
          onClick={() => onSave(formattedData, totals.isDirty)}
          disabled={!formattedData.some((li) => li.isNew || li.isDirty) && !totals.isDirty}
        >
          {t('save')}
        </Button>
      )}
      <ChangeLineItemGroupModal
        toggle={groupToggle}
        onCancel={() =>
          setEditRowGroupData({
            groupName: null,
            id: null,
          })
        }
        groupOptions={
          formattedData
            .filter((d) => d.groupName !== '')
            .map((d) => d.groupName)
            .filter(distinct) as string[]
        }
        currentValue={editRowGroupData.groupName}
        onSubmit={onChangeGroupName}
      />
    </KpcCard>
  );
};

export default LineItems;
