import { getIn, useFormikContext } from 'formik';

import SelectCountry from '../select/SelectCountry';

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  callback?: (value: string | null) => void;
  className?: string;
  disabled?: boolean;
};

const FormikCountrySelector: React.FC<Props> = ({
  disabled = false,
  name,
  callback,
  className,
  ...rest
}) => {
  const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext<{
    [name: string]: string | null;
  }>();

  return (
    <SelectCountry
      disabled={disabled}
      value={getIn(values, name)}
      onChange={(value) => {
        setFieldValue(name, value);

        callback?.(value);
      }}
      onBlur={() => setFieldTouched(name)}
      error={getIn(touched, name) && getIn(errors, name) ? getIn(errors, name) : undefined}
      className={className}
      {...rest}
    />
  );
};

export default FormikCountrySelector;
