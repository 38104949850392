import { Autocomplete, AutocompleteOption, FormControl, FormHelperText, FormLabel } from '@mui/joy';
import { useMemo } from 'react';

import { Nullable } from '~/common/types';

import { useGetEnums } from '@/queries';

import Flag from '../Flag';
import { GlobeIcon } from '@/shared/icons/Icons';

export type CountryOption = { code: string; name: string; value: number };

type Props = {
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
  label?: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
  onBlur?: () => void;
  fullNames?: boolean;
  className?: string;
  [key: string]: unknown;
};

const SelectCountry: React.FC<Props> = ({
  value,
  onChange,
  label,
  error,
  required,
  fullNames = true,
  ...rest
}) => {
  const {
    enums: { countries },
  } = useGetEnums();

  const options = useMemo(
    () =>
      (countries as CountryOption[]).map(({ name, code }) => ({
        label: fullNames ? name : code,
        value: code,
      })),
    [countries, fullNames],
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value) || null,
    [options, value],
  );

  return (
    <FormControl error={!!error}>
      {!!label && <FormLabel required={required}>{label}</FormLabel>}
      <Autocomplete
        value={selectedOption}
        onChange={(_, option) => onChange(option?.value || null)}
        options={options}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} className="flex items-center space-x-4">
            <Flag countryCode={option.value} />
            <span>{fullNames ? `${option.label} (${option.value})` : option.value}</span>
          </AutocompleteOption>
        )}
        startDecorator={value ? <Flag countryCode={value} /> : <GlobeIcon />}
        {...rest}
      />
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectCountry;
