import config from '@/config/config';
import createRestClient from '../restClient';
import { PaginatedAutocomplete } from '~/common/types';

const client = createRestClient(config.baseURL);

const getTeamUsersAutocomplete = (
  params: Record<string, unknown>,
): Promise<PaginatedAutocomplete> => client.get('/team/autocomplete', { params });

const restClient = createRestClient();

const getAutomaticMessageTemplates = () => restClient.get('team/automatic-message-templates');

const getMessageTemplates = () => restClient.get('team/message-templates');

export default {
  getAutomaticMessageTemplates,
  getMessageTemplates,
  getTeamUsersAutocomplete,
};
