import PropTypes from 'prop-types';

const TaskPropTypes = PropTypes.shape({
  buildingId: PropTypes.string,
  caseId: PropTypes.string,
  completedBy: PropTypes.string,
  completedDate: PropTypes.string,
  created: PropTypes.string,
  description: PropTypes.string,
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  id: PropTypes.string,
  priority: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  systemTaskType: PropTypes.string,
  taskUsers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      id: PropTypes.string,
      isAssigned: PropTypes.bool,
      isOwner: PropTypes.bool,
      taskId: PropTypes.string,
      teamUserId: PropTypes.string,
    }),
  ),
  teamTaskTypeId: PropTypes.string,
});

export default TaskPropTypes;
