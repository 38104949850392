import { Autocomplete, AutocompleteOption, ListItemContent, ListItemDecorator } from '@mui/joy';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import formatContractorSkillDefinition from '../../utils/components/select/ContractorSkillDefinition/util';
import { useGetContractorSkillDefinitions } from '../../../queries';

const propTypes = {
  disabled: PropTypes.bool,
  initialSkillId: PropTypes.string,
  loading: PropTypes.bool,
  onChangeCallback: PropTypes.func,
};

const SelectContractorSkillDefinition = ({
  disabled = false,
  initialSkillId = null,
  onChangeCallback = () => {},
  loading = false,
}) => {
  const { contractorSkillDefinitions } = useGetContractorSkillDefinitions();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const sortedOptions = useMemo(
    () =>
      contractorSkillDefinitions
        ?.map((s) => formatContractorSkillDefinition(s.id, contractorSkillDefinitions, language))
        ?.sort((a, b) => a.sortableDescription.localeCompare(b.sortableDescription)),
    [contractorSkillDefinitions, language],
  );

  const initialSkill = useMemo(() => {
    if (sortedOptions.length) {
      return sortedOptions.find((option) => option.id === initialSkillId) ?? null;
    }
    return undefined;
    // Not including initialSkillId in deps because it's causing irrelevant console error in MUI autocomplete when
    // we change defaultValue after mounting.
    // Since we are using uncontrolled Autocomplete it does not matter that initialSkill does not get updated on value
    // change because the state is handled by Autocomplete internally.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sortedOptions]);

  const handleChange = async (e, val) => {
    onChangeCallback(val);
  };

  const handleInputChange = (e, value) => {
    if (!value) {
      onChangeCallback(null);
    }
  };
  return sortedOptions && initialSkill !== undefined ? (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      loading={loading}
      disabled={disabled}
      id="contractor-skill-definition-select"
      options={sortedOptions}
      autoHighlight
      defaultValue={initialSkill}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={(opt) => opt?.chosenDescription ?? opt}
      getOptionDisabled={(opt) => !opt.active}
      renderOption={(props, opt) => (
        <AutocompleteOption {...props}>
          {opt.depth > 1 && (
            <ListItemDecorator style={{ marginLeft: `${opt.depth * 5}px` }}>
              <FontAwesomeIcon icon={['far', 'arrow-turn-down-right']} />
            </ListItemDecorator>
          )}
          <ListItemContent className={classnames(opt.depth === 1 && 'font-medium')}>
            {opt.description}
          </ListItemContent>
        </AutocompleteOption>
      )}
    />
  ) : (
    <div className="h-8 w-full animate-pulse rounded-lg bg-gray-200" />
  );
};

SelectContractorSkillDefinition.propTypes = propTypes;

export default SelectContractorSkillDefinition;
