import actionTypes from '../actions/actionTypes';

const initialState = {
  policies: [],
  loading: false,
  error: false,
};

const policiesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_TEAM:
      return initialState;
    case actionTypes.GET_POLICY_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        policies: action.policies,
      };
    case actionTypes.REMOVE_POLICY:
      return {
        ...state,
        policies: state.policies.filter((policy) => policy.id !== action.id),
      };
    case actionTypes.GET_POLICIES_FAILED:
      return { ...state, loading: false, error: true };
    case actionTypes.ADD_OR_UPDATE_POLICY:
      if (state.policies.some((policy) => policy.id === action.policy.id)) {
        // Update
        return {
          ...state,
          policies: state.policies.map((obj) => {
            if (obj.id === action.policy.id) {
              return { ...action.policy };
            }
            return obj;
          }),
        };
      }
      // Add
      return { ...state, policies: [...state.policies, action.policy] };
    case actionTypes.GET_OR_UPDATE_POLICY_RELATION_SUCCESS: {
      const policyToUpdate = action.policyId;
      return {
        ...state,
        policies: state.policies.map((p) => {
          if (p.id !== policyToUpdate) {
            return p;
          }
          const { relations } = p;
          if (relations.some((rel) => rel.id === action.relation.id)) {
            // Update
            const updatedRelations = relations.map((rel) => {
              if (rel.id !== action.relation.id) {
                return rel;
              }
              return action.relation;
            });
            return { ...p, relations: [...updatedRelations] };
          }
          return { ...p, relations: [...relations, action.relation] };
        }),
      };
    }
    case actionTypes.REMOVE_POLICY_RELATION: {
      const policyToUpdate = action.policyId;
      return {
        ...state,
        policies: state.policies.map((p) => {
          if (p.id !== policyToUpdate) {
            return p;
          }
          const { relations } = p;
          const updatedRelations = relations.filter((rel) => rel.id !== action.relationId);
          return { ...p, relations: updatedRelations };
        }),
      };
    }
    default:
      return state;
  }
};

export default policiesReducer;
