import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import GenericEditor from './GenericEditor';
import { isNullOrEmpty } from '../../../utils/helpers';

const innerComponentRender = ({
  updateValue,
  isAddingNew,
  onChange,
  disableAutoFocus,
  value,
  validationMessage,
  required,
  label,
}) => (
  <TextField
    fullWidth
    autoFocus={!isAddingNew && !disableAutoFocus}
    type="number"
    value={value ?? ''}
    onChange={({ target }) => {
      let val = null;
      if (!isNullOrEmpty(target.value)) val = Number(target.value);
      updateValue(val);
      onChange(val);
    }}
    error={!isNullOrEmpty(validationMessage)}
    helperText={validationMessage}
    label={label}
    variant="standard"
    required={required}
  />
);

const NumberEditor = (props) => {
  const {
    column,
    rowKeyValue,
    dispatch,
    value,
    validationMessage,
    hideSaveButtons,
    onChange,
    disableAutoFocus,
    required,
  } = props;

  return (
    <GenericEditor
      column={column}
      rowKeyValue={rowKeyValue}
      dispatch={dispatch}
      innerComponentRender={({ updateValue, isAddingNew }) =>
        innerComponentRender({
          disableAutoFocus,
          isAddingNew,
          label: column.title,
          onChange,
          required,
          updateValue,
          validationMessage,
          value,
        })
      }
      valueTransformer={(val) => +val}
      hideSaveButtons={hideSaveButtons}
    />
  );
};

export default NumberEditor;

NumberEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  disableAutoFocus: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  hideSaveButtons: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rowKeyValue: PropTypes.any.isRequired,
  validationMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NumberEditor.defaultProps = {
  disableAutoFocus: false,
  hideSaveButtons: false,
  onChange: () => {},
  required: false,
  validationMessage: null,
  value: null,
};
