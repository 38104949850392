import {
  Alert,
  Autocomplete,
  Checkbox,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Step,
  Stepper,
  Tooltip,
} from '@mui/joy';
import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { lowerFirst } from 'lodash';
import { MoreVert } from '@mui/icons-material';
import { Table } from 'ka-table';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useCreateCompany, useCreateContact } from '@/mutations';
import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import CaseRole from '~/common/enums/caseRole';
import { CrmOptionType } from '~/common/enums';
import { customCellWidth } from '@/shared/utils/constants';
import { FormikValues } from './AddRepairCaseStepper';
import InvolvementType from '~/common/enums/involvementType';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import {
  AddIcon,
  CompanyIcon,
  ContactIcon,
  DeleteIcon,
  InfoIcon,
  InlineSpinner,
  InsuredCaseIcon,
  InsurerIcon,
  TeamIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import AddEditCompanyForm from '@/containers/Companies/AddEditCompany/AddEditCompanyForm';
import AddEditContactForm from '@/containers/Contacts/AddEditContact/AddEditContactForm';
import HeaderStep from './HeaderStep';
import InfoModal from './InfoModal';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import StepIndicator from './StepIndicator';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

import { companyApi } from '@/shared/api';

type Party = {
  value?: string | null;
  label: string;
  type: CrmOptionType;
  entityId?: string | null;
  isTeam?: boolean;
};

const InvolvedPartiesStep = () => {
  const { ui, setUi, currentTeam } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [selectedParty, setSelectedParty] = useState<Party | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const companyToggle = useToggle();
  const contactToggle = useToggle();

  const {
    data: crmOptions,
    isLoading: crmIsLoading,
    refetch: refetchCrmOptions,
  } = trpc.contact.involvedParties.useQuery();

  const { data: contact, error: contactError } = trpc.contact.byId.useQuery(
    selectedParty?.entityId as string,
    {
      enabled: !!(selectedParty?.type === CrmOptionType.Contact),
    },
  );

  const { data: company, error: companyError } = trpc.company.byId.useQuery(
    selectedParty?.entityId as string,
    { enabled: !!(selectedParty?.type === CrmOptionType.Company) },
  );

  const { mutateAsync: createCompanyAsync } = useCreateCompany();
  const { mutateAsync: createContactAsync } = useCreateContact();

  const involvedParties = useMemo(() => {
    if (!crmOptions) return [];

    if (values.relations.length > 0) {
      return crmOptions.filter(
        (option) => !values.relations.some((relation) => relation.id === option.value),
      );
    }
    return crmOptions;
  }, [values.relations, crmOptions]);

  const caseRoles = Object.values(CaseRole).map((role) => ({
    label: t(lowerFirst(role)),
    value: role,
  }));

  const involvementTypes = Object.values(InvolvementType).map((involvementType) => ({
    label: t(lowerFirst(involvementType)),
    value: involvementType,
  }));

  const hasRequestor = values.relations.some((relation) => relation.isRequestor);
  const hasClient = values.relations.some((relation) => relation.isClient);
  const hasInsurer = values.relations.some((relation) => relation.role === CaseRole.Insurer);
  const hasIntermediary = values.relations.some(
    (relation) => relation.role === CaseRole.InsuranceIntermediary,
  );

  const isValidInvolvedParties = currentTeam?.allowRepairManagement
    ? hasRequestor && hasClient && hasInsurer && hasIntermediary
    : hasRequestor && hasClient && hasIntermediary;

  const defaultValues = {
    involvementType: null,
    isClient: false,
    isRequestor: false,
    relationReference: '',
    role: contact?.defaultCaseRole || company?.defaultCaseRole || null,
  };

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.involvedParties.title')}
        description={t('repairCaseForm.involvedParties.description')}
        tooltipMessage={t('repairCaseForm.involvedParties.tooltip')}
      />

      {(contactError || companyError) && (
        <Alert color="danger" startDecorator={<WarningIcon />}>
          {t('repairCaseForm.involvedParties.error')}
        </Alert>
      )}

      <div
        className={classNames('p-4', 'rounded-xl border', {
          'bg-gray-50': !isValidInvolvedParties,
          'bg-green-100': isValidInvolvedParties,
        })}
      >
        <Stepper>
          <Step indicator={<StepIndicator permission={hasRequestor} required />}>
            {t('repairCaseForm.involvedParties.validation.requestor')}
          </Step>
          <Step indicator={<StepIndicator permission={hasClient} required />}>
            {t('repairCaseForm.involvedParties.validation.client')}
          </Step>
          <Step indicator={<StepIndicator permission={hasInsurer} />}>
            {t('repairCaseForm.involvedParties.validation.insurer')}
          </Step>
          <Step indicator={<StepIndicator permission={hasIntermediary} />}>
            {t('repairCaseForm.involvedParties.validation.intermediary')}
          </Step>
        </Stepper>
      </div>
      <FormControl>
        <FormLabel>{t('involvedParty')}</FormLabel>
        <div className="flex space-x-4">
          <Autocomplete
            value={selectedParty}
            placeholder={t('typeHere')}
            disabled={crmIsLoading}
            startDecorator={crmIsLoading && <InlineSpinner />}
            className="grow"
            options={involvedParties || []}
            slots={{ listbox: VirtualListBoxAdapter }}
            groupBy={(option) => option.type}
            renderOption={(props, option) => [props, option] as React.ReactNode}
            renderGroup={(params) => params as unknown as React.ReactNode}
            onChange={(_e, party) => {
              if (party) {
                setSelectedParty(party);
              }
            }}
          />
          <IconButton
            disabled={!selectedParty}
            onClick={() => {
              if (!selectedParty) return;

              if (contact && selectedParty.type === CrmOptionType.Contact) {
                setFieldValue('relations', [
                  ...values.relations,
                  {
                    ...defaultValues,
                    ...contact,
                    ...selectedParty,
                    companyId: null,
                    contactId: selectedParty.value,
                    emailAddresses: contact.emailAddresses.map((e) => e.email),
                    language: contact.language,
                    teamId: null,
                  },
                ]);
              }
              if (company && selectedParty.type === CrmOptionType.Company) {
                setFieldValue('relations', [
                  ...values.relations,
                  {
                    ...defaultValues,
                    ...company,
                    ...selectedParty,
                    companyId: selectedParty.value,
                    contactId: null,
                    emailAddresses: company.emailAddresses.map((e) => e.email),
                    language: company.language,
                    teamId: null,
                  },
                ]);
              }
              if (company && selectedParty.type === CrmOptionType.Company && selectedParty.isTeam) {
                setFieldValue('relations', [
                  ...values.relations,
                  {
                    ...company,
                    ...selectedParty,
                    ...defaultValues,
                    companyId: null,
                    contactId: null,
                    emailAddresses: company.emailAddresses.map((e) => e.email),
                    id: selectedParty.value,
                    language: company.language,
                    teamId: selectedParty.value,
                  },
                ]);
              }

              setSelectedParty(null);
            }}
          >
            <AddIcon />
          </IconButton>
          <Dropdown>
            <MenuButton slots={{ root: IconButton }}>
              <MoreVert />
            </MenuButton>
            <Menu placement="bottom-end" className="z-popperModal">
              <MenuItem onClick={() => contactToggle.show()}>
                <ListItemDecorator>
                  <ContactIcon />
                </ListItemDecorator>
                {t('addContact')}
              </MenuItem>
              <MenuItem onClick={() => companyToggle.show()}>
                <ListItemDecorator>
                  <CompanyIcon />
                </ListItemDecorator>
                {t('addCompany')}
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </FormControl>

      <div className="rounded-xl border">
        <Table
          rowKeyField="id"
          columns={[
            {
              key: 'type',
              title: '',
              width: 50,
            },
            {
              key: 'label',
              title: t('name'),
            },
            {
              key: 'isRequestor',
              title: t('requestor'),
              width: customCellWidth.S,
            },
            {
              key: 'isClient',
              title: t('client'),
              width: customCellWidth.S,
            },
            {
              key: 'role',
              title: t('role'),
            },
            {
              key: 'involvementType',
              title: t('involvementType'),
            },
            {
              key: 'relationReference',
              title: t('reference'),
            },
            {
              key: 'id',
              title: t('actions'),
              width: customCellWidth.SM,
            },
          ]}
          data={values.relations}
          childComponents={{
            cellText: {
              // eslint-disable-next-line react/no-unstable-nested-components
              content: (props) => {
                switch (props.column.key) {
                  case 'type': {
                    if (props.rowData.role === CaseRole.Insurer) {
                      return (
                        <Tooltip title={t('insurer')}>
                          <span>
                            <InsurerIcon />
                          </span>
                        </Tooltip>
                      );
                    }
                    if (props.rowData.role === CaseRole.InsuranceIntermediary) {
                      return (
                        <Tooltip title={t('intermediary')}>
                          <span>
                            <InsuredCaseIcon />
                          </span>
                        </Tooltip>
                      );
                    }
                    if (props.rowData.type === CrmOptionType.Contact) {
                      return (
                        <Tooltip title={t('contact')}>
                          <span>
                            <ContactIcon />
                          </span>
                        </Tooltip>
                      );
                    }
                    if (props.rowData.type === CrmOptionType.Team) {
                      return (
                        <Tooltip title={t('team')}>
                          <span>
                            <TeamIcon />
                          </span>
                        </Tooltip>
                      );
                    }
                    return (
                      <Tooltip title={t('company')}>
                        <span>
                          <CompanyIcon />
                        </span>
                      </Tooltip>
                    );
                  }
                  case 'isRequestor':
                    return (
                      <Checkbox
                        checked={props.rowData.isRequestor}
                        onChange={(e) => {
                          setFieldValue(
                            'relations',
                            values.relations.map((relation) => {
                              if (relation.id === props.rowData.id) {
                                return { ...relation, isRequestor: e.target.checked };
                              }
                              return { ...relation, isRequestor: false };
                            }),
                          );
                        }}
                      />
                    );
                  case 'isClient':
                    return (
                      <Checkbox
                        checked={props.rowData.isClient}
                        onChange={(e) => {
                          setFieldValue(
                            'relations',
                            values.relations.map((relation) => {
                              if (relation.id === props.rowData.id) {
                                return { ...relation, isClient: e.target.checked };
                              }
                              return relation;
                            }),
                          );
                        }}
                      />
                    );
                  case 'role':
                    return (
                      <Autocomplete
                        placeholder={t('typeHere')}
                        value={caseRoles.find((role) => role.value === props.value) || null}
                        options={caseRoles}
                        onChange={(_e, role) => {
                          setFieldValue(
                            'relations',
                            values.relations.map((relation) => {
                              if (relation.id === props.rowData.id) {
                                return { ...relation, role: role?.value };
                              }
                              return relation;
                            }),
                          );
                        }}
                      />
                    );

                  case 'involvementType':
                    return (
                      <Autocomplete
                        placeholder={t('typeHere')}
                        value={
                          involvementTypes.find(
                            (involvementType) => involvementType.value === props.value,
                          ) || null
                        }
                        options={involvementTypes}
                        onChange={(e, involvementType) => {
                          setFieldValue(
                            'relations',
                            values.relations.map((relation) => {
                              if (relation.id === props.rowData.id) {
                                return { ...relation, involvementType: involvementType?.value };
                              }
                              return relation;
                            }),
                          );
                        }}
                      />
                    );
                  case 'relationReference':
                    return (
                      <Input
                        placeholder={t('typeHere')}
                        value={props.value}
                        onChange={(e) => {
                          setFieldValue(
                            'relations',
                            values.relations.map((relation) => {
                              if (relation.id === props.rowData.id) {
                                return { ...relation, relationReference: e.target.value };
                              }
                              return relation;
                            }),
                          );
                        }}
                      />
                    );

                  case 'id':
                    return (
                      <div className="flex space-x-2">
                        <IconButton
                          onClick={() => {
                            setUi({
                              payload: props.rowData.id,
                              type: actions.CASE_TOGGLE_INVOLVED_PARTY_INFO,
                            });
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          color="danger"
                          onClick={() => {
                            setFieldValue(
                              'relations',
                              values.relations.filter(
                                (relation) => relation.id !== props.rowData.id,
                              ),
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );

                  default:
                    return props.value;
                }
              },
            },

            noDataRow: { content: () => t('noDataToDisplay') },
          }}
        />
      </div>

      {ui.case.toggles.involvedPartyInfo && <InfoModal />}

      {companyToggle.value && (
        <KpcModal title={t('company')} toggle={companyToggle}>
          <AddEditCompanyForm
            onCancel={companyToggle.hide}
            onSubmit={async (data) => {
              try {
                // @ts-ignore
                const createdCompany = await createCompanyAsync({
                  ...data,
                  companyTags: data.companyTags.map((tag) => tag.value),
                });
                const companyTeamsResponse = await companyApi.getCompanyTeams();
                // @ts-ignore
                const companyTeamsList = companyTeamsResponse.flatMap(({ teams, id: companyId }) =>
                  teams.map((team) => ({
                    companyId,
                    isTeam: true,
                    teamId: team.teamId,
                  })),
                );

                const foundCompanyTeam = companyTeamsList.find(
                  (ct) => ct.companyId === createdCompany.id,
                );

                refetchCrmOptions();
                companyToggle.hide();

                setFieldValue('relations', [
                  ...values.relations,
                  {
                    ...defaultValues,
                    ...createdCompany,
                    companyId: foundCompanyTeam ? null : createdCompany.id,
                    contactId: null,
                    emailAddresses: createdCompany.emailAddresses.map((e) => e.email),
                    entityId: createdCompany.id,
                    isTeam: !!foundCompanyTeam,
                    label: createdCompany.displayName,
                    language: createdCompany.language,
                    role: createdCompany.defaultCaseRole || null,
                    teamId: foundCompanyTeam ? foundCompanyTeam.teamId : null,
                    type: CrmOptionType.Company,
                    value: createdCompany.id,
                  },
                ]);
              } catch (error) {
                enqueueSnackbar(t('errorCreatingCompany', { error: error.message }), {
                  variant: 'error',
                });
              }
            }}
          />
        </KpcModal>
      )}

      {contactToggle.value && (
        <KpcModal title={t('contact')} toggle={contactToggle}>
          <AddEditContactForm
            onCancel={contactToggle.hide}
            onSubmit={async (con) => {
              const { addressLine, postalCode, city, country, ...data } = con;

              const contactResponse = await createContactAsync({
                ...data,
                address: {
                  addressLine,
                  city,
                  country,
                  postalCode,
                },
              });
              refetchCrmOptions();
              contactToggle.hide();

              setFieldValue('relations', [
                ...values.relations,
                {
                  ...defaultValues,
                  ...contactResponse,
                  companyId: null,
                  contactId: contactResponse.id,
                  emailAddresses: contactResponse.emailAddresses.map((e) => e.email),
                  entityId: contactResponse.id,
                  label: `${contactResponse.firstName} ${contactResponse.lastName}` || '',
                  language: contactResponse.language,
                  role: contactResponse.defaultCaseRole || null,
                  teamId: null,
                  type: CrmOptionType.Contact,
                  value: contactResponse.id,
                },
              ]);
            }}
          />
        </KpcModal>
      )}
    </>
  );
};

export default InvolvedPartiesStep;
