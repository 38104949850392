import { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { clamp } from '../utils/helpers';
import { layout } from '../utils/constants';
import useViewport from './useViewport';

const DEFAULT_OPTIONS = {
  localStorageKey: null,
  maxHeight: null,
  minHeight: null,
  offsetY: layout.OFFSET_Y,
};

const useToggleHeight = (options = {}) => {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options };
  /* @ts-ignore */
  const saveFilters = useSelector((state) => state.user?.settings?.saveFilters);

  const [height, setHeight] = useState<number>(0);
  const [value, setValue] = useState<boolean>(
    mergedOptions.localStorageKey
      ? JSON.parse(localStorage.getItem(mergedOptions.localStorageKey) ?? 'false') || false
      : false,
  );

  const divRef = useRef<HTMLDivElement>(null);
  const viewport = useViewport();

  useLayoutEffect(() => {
    const newHeight = viewport.height - (divRef.current?.offsetHeight ?? 0) - mergedOptions.offsetY;
    setHeight(clamp(newHeight, mergedOptions.minHeight, mergedOptions.maxHeight));
  }, [
    value,
    viewport.height,
    mergedOptions.offsetY,
    mergedOptions.minHeight,
    mergedOptions.maxHeight,
  ]);

  const inverse = () => {
    setValue(!value);
    if (saveFilters && mergedOptions.localStorageKey) {
      localStorage.setItem(mergedOptions.localStorageKey, JSON.stringify(!value));
    }
  };

  return {
    divRef,
    height,
    inverse,
    value,
  };
};

export default useToggleHeight;
