import { memo } from 'react';
import PropTypes from 'prop-types';

import FiltersToggleButton from './buttons/FiltersToggleButton';

const propTypes = {
  toggle: PropTypes.shape({
    show: PropTypes.func,
    value: PropTypes.bool,
  }),
};

const DataTableFilter = ({ toggle }) => <FiltersToggleButton toggle={toggle} />;

DataTableFilter.propTypes = propTypes;

export default memo(DataTableFilter);
