import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../shared/api/ApiService';
import actionTypes from '../../actions/actionTypes';

function* handleGetPolicies() {
  const response = yield call(API.fetchPolicyOverview);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_POLICIES_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.GET_POLICIES_SUCCESS,
    policies: response.data,
  });
}

function* handleGetSinglePolicy(action) {
  const response = yield call(API.fetchPolicyById, action.id);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_POLICIES_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.ADD_OR_UPDATE_POLICY,
    policy: response.data,
  });
}

function* handleRelationAdded(action) {
  const response = yield call(API.fetchPolicyRelationById, action.policyId, action.relationId);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_OR_UPDATE_POLICY_RELATION_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    type: actionTypes.GET_OR_UPDATE_POLICY_RELATION_SUCCESS,
    relation: response.data,
    policyId: action.policyId,
  });
}

function* watcherPoliciesSaga() {
  yield takeLatest(actionTypes.GET_POLICY_REQUESTED, handleGetPolicies);
  yield takeLatest(actionTypes.GET_SINGLE_POLICY_REQUESTED, handleGetSinglePolicy);
  yield takeLatest(actionTypes.GET_OR_UPDATE_POLICY_RELATION_REQUESTED, handleRelationAdded);
}

export default watcherPoliciesSaga;
