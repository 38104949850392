import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormLabel,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useGetCountries } from '../../../../queries';

const CountriesAutocomplete = () => {
  const { t } = useTranslation();
  const { countriesAutocomplete, countriesIsLoading } = useGetCountries();
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (e, val) => {
    e?.preventDefault();
    setFieldValue('country', val.value);
  };

  return (
    <FormControl>
      <FormLabel>{t('country.label')}</FormLabel>
      <Autocomplete
        defaultValue={
          countriesAutocomplete.find((country) => country.value === values.country) || {
            label: 'Belgium',
            value: 'BE',
          }
        }
        onChange={(e, val) => handleChange(e, val)}
        size="sm"
        loading={countriesIsLoading}
        placeholder={t('country.placeholder')}
        slotProps={{ input: { autoComplete: 'new-password' } }}
        options={countriesAutocomplete}
        renderOption={(props, option) => (
          <AutocompleteOption {...props}>
            <ListItemDecorator>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                alt={option.value.toLowerCase()}
              />
            </ListItemDecorator>
            <ListItemContent className="pl-2">{option.label}</ListItemContent>
          </AutocompleteOption>
        )}
      />
    </FormControl>
  );
};

export default CountriesAutocomplete;
