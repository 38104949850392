enum CaseChannel {
  OnSite = 'OnSite',
  Phone = 'Phone',
  Email = 'Email',
  Letter = 'Letter',
  Meeting = 'Meeting',
  Other = 'Other',
  Case = 'Case',
  QRScan = 'QRScan',
  Assistance = 'Assistance',
  Template = 'Template',
  Application = 'Application',
}

export default CaseChannel;
