import { FormControl, FormHelperText, FormLabel, Textarea } from '@mui/joy';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ReferencePropType from '../../../prop-types/ReferencePropType';

const propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  textfieldRef: ReferencePropType,
};

const TextArea = ({
  className,
  error = false,
  helperText,
  inputClassName,
  isRequired,
  label,
  size = 'sm',
  textfieldRef,
  ...rest
}) => (
  <FormControl className={`${className} w-full flex-1`} size={size} error={error}>
    <FormLabel component="div" className={classNames('truncate', error ? 'invalid' : '')}>
      {label}
      {isRequired && ' *'}
    </FormLabel>
    <Textarea className={inputClassName} ref={textfieldRef} {...rest} />
    <FormHelperText className={error ? 'validation-message' : ''}>{helperText}</FormHelperText>
  </FormControl>
);

TextArea.propTypes = propTypes;

export default TextArea;
