import { Document, Page, pdfjs } from 'react-pdf';
import classNames from 'classnames';
import { useState } from 'react';

import { Nullable } from '~/common/types';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// for testing purposes: if you want to view the PDF in localhost
// you have to enable the CORS on the blob storage to allow * (didn't get it to work with localhost)
// or we have to implement a pdfjs worker ourselves which doesn't use CORS

type Props = {
  className?: string;
  uri: string;
  onError?: () => void;
  onLoad?: () => void;
};

const PDFRenderer: React.FC<Props> = ({ uri, onLoad, onError, className }) => {
  const [nrPages, setNrPages] = useState<Nullable<number>>(null);

  return (
    <div className={classNames('pdf-renderer', className)}>
      <Document
        file={{ url: uri }}
        onLoadSuccess={({ numPages }: { numPages: number }) => {
          setNrPages(numPages);
          onLoad?.();
        }}
        onError={onError}
      >
        {Array.from(new Array(nrPages), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

export default PDFRenderer;
