import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  checkFileCanBePreviewed,
  decapitalize,
  formatUtcDate,
  getFileTypeIconForContentType,
  isString,
  sentencize,
} from '@/shared/utils/helpers';
import { colorDanger, colorPrimary } from '@/shared/utils/palette';
import { AppContext } from '@/shared/context/context';
import downloadFile from '@/shared/utils/fileHelpers';
import SpreadsheetPropTypes from '@/shared/prop-types/SpreadsheetPropTypes';
import SpreadsheetStatus from '@/shared/enums/SpreadsheetStatus';
import useToggle from '@/shared/hooks/UseToggle';
import { useUpdateSpreadsheetRemark } from '@/mutations';

import { CommentIcon, FileIcon } from '@/shared/icons/Icons';
import DocumentPreviewModal from '@/shared/components/documents/Documents/DocumentPreviewModal';
import { formatContactDisplayName } from '~/common/utils/utils';
import IconButton from '@/shared/components/buttons/IconButton';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import RemarkModal from '@/shared/components/2.0/RemarkModal';

const Container = styled.div`
  width: 45%;
  padding: 5px;
  border: 2px solid ${(props) => props.color};
  border-radius: 5px;
  display: flex;
  margin-bottom: 5px;
  cursor: ${(props) =>
    props.showCursor &&
    `
      pointer
    `};
`;

const IconContainer = styled.div`
  padding-left: 5px;
  padding-right: 10px;
  flex-grow: 0;
`;

const InfoContainer = styled.div`
  align-content: flex-start;
  flex-grow: 1;
`;

const RejectedInfo = styled.p`
  p {
    color: ${colorDanger} !important;
  }
`;

const SpreadsheetDisplay = ({ spreadsheet }) => {
  const [fileToPreview, setFileToPreview] = useState();

  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();
  const { updateSpreadsheetRemark } = useUpdateSpreadsheetRemark(spreadsheet.caseGroupId);

  const navigate = useNavigate();

  const documentPreviewToggle = useToggle();
  const spreadSheetToggle = useToggle();

  const currentTeamCanOpen = () => {
    if (currentTeam == null) {
      return false;
    }
    return (
      currentTeam.id === spreadsheet.managerTeamId ||
      currentTeam.id === spreadsheet.sender?.teamId ||
      currentTeam.id === spreadsheet.receiver?.teamId
    );
  };
  const handleClick = () => {
    if (currentTeamCanOpen()) {
      navigate(`/spreadsheet/${spreadsheet.id}`);
    }
  };

  const handleOpenDocumentPreview = () => {
    setFileToPreview(spreadsheet.mainFile);
    documentPreviewToggle.show();
  };

  const getSenderDisplayName = () => {
    const { sender } = spreadsheet;
    if (sender == null) {
      return null;
    }
    if (isString(sender)) {
      return sender;
    }
    const { companyName, firstName, lastName } = sender;
    if (companyName == null && firstName == null && lastName == null) {
      return null;
    }
    return companyName ?? formatContactDisplayName({ firstName, lastName });
  };

  const getBorderColor = () => {
    switch (spreadsheet.status) {
      case SpreadsheetStatus.Accepted:
        return 'rgba(0, 255, 40, 0.4)';
      case SpreadsheetStatus.Rejected:
        return 'rgba(255, 0, 0, 0.4)';
      default:
        return colorPrimary;
    }
  };

  const fileCanBePreviewed = checkFileCanBePreviewed(spreadsheet.mainFile?.contentType);
  const getContentTypeIcon = (contentType) => {
    const icon = getFileTypeIconForContentType(contentType);
    if (fileCanBePreviewed) {
      return (
        <IconButton
          style={{ fontSize: '1.25rem' }}
          icon={icon}
          onClick={handleOpenDocumentPreview}
        />
      );
    }
    return (
      <KpcTooltip title={t('common:unsupportedFileType')}>
        <div style={{ fontSize: '1.25rem', paddingLeft: '6px' }}>
          <FontAwesomeIcon icon={['fad', icon]} />
        </div>
      </KpcTooltip>
    );
  };

  return (
    <Container className="me-2" showCursor={currentTeamCanOpen()} color={getBorderColor()}>
      <IconContainer onClick={handleClick}>
        <FileIcon size="5x" />
      </IconContainer>
      <InfoContainer onClick={handleClick}>
        <h4>{getSenderDisplayName()}</h4>
        <p>
          {t('deliveredOn')} {formatUtcDate(spreadsheet.deliveryDate)}
        </p>
        {spreadsheet.status === SpreadsheetStatus.Rejected && (
          <RejectedInfo>
            <p>
              {t('status')}: {t(`${decapitalize(spreadsheet.status)}`).toLowerCase()}
            </p>
            <p>
              {t('rejectedBy')}: {spreadsheet.validatedBy ?? '-'}
            </p>
            <p>
              {t('rejectedOn', {
                date: formatUtcDate(spreadsheet.validationDate, 'DD/MM/YYYY HH:mm'),
              })}
            </p>
            <p>
              {t('reason')}: {t(`${decapitalize(spreadsheet.rejectionReason)}`).toLowerCase()}
            </p>
            {spreadsheet.validationRemark && (
              <p>
                {t('remark')}: {t(`${decapitalize(spreadsheet.validationRemark)}`).toLowerCase()}
              </p>
            )}
            <p>{spreadsheet.rejectionDescription}</p>
          </RejectedInfo>
        )}
        {spreadsheet.status === SpreadsheetStatus.Accepted && (
          <div>
            <p>
              {t('status')}: {t(`${decapitalize(spreadsheet.status)}`)}
            </p>
            <p>
              {t('acceptedBy')}: {spreadsheet.validatedBy ?? '-'}
            </p>
            <p>
              {t('acceptedOn', {
                date: formatUtcDate(spreadsheet.validationDate, 'DD/MM/YYYY HH:mm'),
              })}
            </p>
            {spreadsheet.validationRemark && (
              <p>
                {t('remark')}: {t(`${decapitalize(spreadsheet.validationRemark)}`).toLowerCase()}
              </p>
            )}
          </div>
        )}
        {spreadsheet.status !== SpreadsheetStatus.Accepted &&
          spreadsheet.status !== SpreadsheetStatus.Rejected && (
            <div>
              <p>
                {t('status')}: {t(`${decapitalize(spreadsheet.status)}`)}
              </p>
            </div>
          )}
      </InfoContainer>
      <IconContainer>
        <KpcTooltip title={spreadsheet.remark || sentencize(t('addType', { type: t('remark') }))}>
          <IconButton
            className="pr-2 text-xl"
            iconComponent={<CommentIcon iconStyle={spreadsheet.remark ? 'fad' : 'far'} />}
            onClick={spreadSheetToggle.show}
          />
        </KpcTooltip>
        {spreadsheet.mainFile && (
          <>
            <KpcTooltip title={t('common:download')}>
              <IconButton
                className="pr-2 text-xl"
                icon="download"
                onClick={() => downloadFile(spreadsheet.mainFile)}
              />
            </KpcTooltip>
            {getContentTypeIcon(spreadsheet.mainFile?.contentType)}
            {fileToPreview && (
              <DocumentPreviewModal toggle={documentPreviewToggle} file={fileToPreview} />
            )}
          </>
        )}
      </IconContainer>

      <RemarkModal
        isOpen={spreadSheetToggle.value}
        setIsOpen={spreadSheetToggle.setValue}
        submit={(id, newRemark, setSubmitting) => {
          const data = {
            id: spreadsheet.id,
            remark: newRemark,
          };

          updateSpreadsheetRemark(data, {
            onSettled: () => setSubmitting(false),
            onSuccess: () => {
              spreadSheetToggle.hide();

              // eslint-disable-next-line no-param-reassign
              spreadsheet.remark = newRemark;
            },
          });
        }}
        remark={spreadsheet.remark}
      />
    </Container>
  );
};

SpreadsheetDisplay.propTypes = {
  spreadsheet: SpreadsheetPropTypes.isRequired,
};

export default SpreadsheetDisplay;
