import config from '@/config/config';
import { Contact } from '~/common/types/contact/contact';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';
import { RelationBuildingInfo } from '~/common/types/building';
import { SendMessageRequest } from '~/common/types/email/emailMessage';

const client = createRestClient(config.baseURL);

// @ts-ignore
const getContactsAutocomplete = (params) => client.get('/contact/autocomplete', { params });

const restClient = createRestClient();

const deleteContact = (id: string) => restClient.delete(`contact/${id}`);

const getContact = (id: string): Promise<Contact> => restClient.get(`contact/${id}`);

const getContactBuildingInfo = (id: string): Promise<RelationBuildingInfo[]> =>
  client.get(`/contact/${id}/building-info`);

const getContacts = () => restClient.get('contact');

const getContactTags = () => restClient.get('contact/tags');

// @ts-ignore
const mergeContacts = (data) => restClient.post('contact/merge', data);

// @ts-ignore
const updateContact = (contact) => restClient.put('contact', contact);

// @ts-ignore
const createTag = (tag) => restClient.post('contact/tag', tag);

// @ts-ignore
const updateTag = (tag) => restClient.put('contact/tag', tag);

// @ts-ignore
const linkTag = (tag) => restClient.put('contact/tag.link', tag);

// @ts-ignore
const unLinkTag = (tag) => restClient.put('contact/tag.unlink', tag);

const deleteTag = (id: string) => restClient.delete('contact/tag', { data: { tagId: id } });

const postSendEmailMessage = ({
  data,
  contactId,
}: {
  data: SendMessageRequest;
  contactId: string;
}) =>
  restClient.post(`contact/${contactId}/emails`, objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export default {
  createTag,
  deleteContact,
  deleteTag,
  getContact,
  getContactBuildingInfo,
  getContacts,
  getContactsAutocomplete,
  getContactTags,
  linkTag,
  mergeContacts,
  postSendEmailMessage,
  unLinkTag,
  updateContact,
  updateTag,
};
