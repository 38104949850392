import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import ROUTES from '../../App/Router/Routes';
import Search from '../../../shared/components/2.0/Search';

import useSearchCases from '../../../queries/cases/useSearchCases';

const propTypes = {
  className: PropTypes.string,
};

const TopbarSearch = ({ className }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const navigate = useNavigate();
  const { data: foundCases, isFetching, refetch } = useSearchCases(searchValue);

  const search = useCallback(() => {
    if (searchValue !== '') {
      setShouldNavigate(true);
      refetch();
    }
  }, [refetch, searchValue]);

  useEffect(() => {
    if (shouldNavigate) {
      if (foundCases) {
        if (foundCases.length === 1) {
          navigate(`${ROUTES.CASE_DETAIL_NAVIGATE}/${foundCases[0]}`);
        } else {
          navigate(`${ROUTES.CASE_OVERVIEW_SEARCH}${searchValue}`);
        }

        setShouldNavigate(false);
      }
    }
  }, [foundCases, shouldNavigate, navigate, searchValue]);

  return (
    <div className={classNames(className)}>
      <Search
        placeholder={t('topbar.search')}
        onChange={setSearchValue}
        onEnter={search}
        isFetching={isFetching}
      />
    </div>
  );
};

TopbarSearch.propTypes = propTypes;

export default TopbarSearch;
