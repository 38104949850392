import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';

export type RootState = {
  fsmaNumbers: any;
  integrations: any;
  layout: any;
  policies: any;
  router: any;
  sidebar: any;
  tags: any;
  teams: any;
  user: any;
};

const createdSagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const middleware = [createdSagaMiddleware, routerMiddleware];
const rootReducer = createRootReducer(routerReducer);

export const store = createStore(rootReducer, applyMiddleware(...middleware));

export const history = createReduxHistory(store);

export const sagaMiddleware = createdSagaMiddleware;
