import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import KpcTooltip from '../Tooltips/KpcTooltip';

const Container = styled.div`
  p:first-child,
  div:first-child,
  span:first-child,
  button:first-child,
  a:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => (props.color ? props.color : null)};
  }
`;

const EllipsisContainer = ({ children, title, ellipsisColor }) => {
  const [overflowActive, setOverflowActive] = useState(false);
  const overflowRef = useRef();

  const handleOverflow = () => {
    const ref = overflowRef?.current?.children[0];

    if (!ref) return;

    const overflow = ref.clientWidth < ref.scrollWidth || ref.clientHeight < ref.scrollHeight;
    setOverflowActive(overflow);
  };

  useEffect(() => {
    handleOverflow();
    window.addEventListener('resize', handleOverflow);
    overflowRef.current.children[0].addEventListener('change', handleOverflow);
    const observer = new ResizeObserver((entries) => (entries ? handleOverflow() : null));
    const container = document.querySelector('.ellipsis-container');

    if (container != null) {
      observer.observe(container);
    }
    return () => {
      window.removeEventListener('resize', handleOverflow);
      observer.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <KpcTooltip
        disableInteractive
        placement="top-start"
        title={title}
        disableHoverListener={!overflowActive}
      >
        <Container color={ellipsisColor} ref={overflowRef} className="ellipsis-container">
          {children}
        </Container>
      </KpcTooltip>
    </div>
  );
};

EllipsisContainer.propTypes = {
  children: PropTypes.node.isRequired,
  ellipsisColor: PropTypes.string,
  title: PropTypes.string.isRequired,
};

EllipsisContainer.defaultProps = {
  ellipsisColor: '',
};

export default EllipsisContainer;
