import { DialogContent, DialogTitle, Divider, Drawer } from '@mui/joy';
import classNames from 'classnames';

import { UseToggle } from '@/shared/hooks/UseToggle';

const sizes = {
  lg: 'w-1/2',
  md: 'w-1/3',
  sm: 'w-1/4',
  xl: 'w-3/5',
};

type Props<T> = {
  backdropBehavior?: 'closeOnSingleClick' | 'closeOnDoubleClick' | 'noCloseOnClick';
  className?: string;
  description?: string;
  disableEnforceFocus?: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  size?: keyof typeof sizes;
  title: string;
  toggle: UseToggle<T>;
};

const SidebarModal = <T,>({
  backdropBehavior = 'closeOnDoubleClick',
  children,
  className,
  description = '',
  disableEnforceFocus = false,
  icon,
  onClose,
  size = 'sm',
  title = 'Form Modal title',
  toggle,
}: React.PropsWithChildren<Props<T>>) => {
  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const onClickBackdrop = () => {
    if (backdropBehavior === 'closeOnSingleClick') {
      handleClose();
    }
  };

  const onDoubleClickBackdrop = () => {
    if (backdropBehavior === 'closeOnDoubleClick') {
      handleClose();
    }
  };

  return (
    <Drawer
      open={toggle.value}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return;

        handleClose();
      }}
      slotProps={{
        backdrop: {
          className: 'bg-black backdrop-blur-none bg-opacity-60',
          onClick: onClickBackdrop,
          onDoubleClick: onDoubleClickBackdrop,
        },
        content: {
          className: classNames(className, sizes[size]),
        },
      }}
      anchor="right"
      disableEnforceFocus={disableEnforceFocus}
    >
      <DialogContent className="z-modal p-4">
        <DialogTitle className="pb-4">
          {icon} {title}
        </DialogTitle>
        <Divider className="bg-gray-400" />
        {description && <p className="py-4">{description}</p>}
        {children}
      </DialogContent>
    </Drawer>
  );
};

export default SidebarModal;
