import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../../../shared/api/ApiService';
import { objectToFormData } from '@/shared/utils/helpers';
import useNotification from '../../../../../shared/hooks/UseNotification';

import FileInput from '../../../../../shared/components/inputs/FileInput';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

const RequestFileUploadForm = ({ taskId, onCancel, caseId }) => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();
  const [files, setFiles] = useState([]);
  const { VITE_MAX_FILE_SIZE } = import.meta.env;

  const onSave = async () => {
    const data = objectToFormData({
      caseId,
      files,
      taskId,
    });

    const response = await API.postAddFileToCase(data);

    if (response.serviceError != null || response.status !== 201) {
      sendDefaultError(response);
      return;
    }

    setFiles([...files, ...response.data]);
    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyAdded', {
        type: t('file', { count: files.length }),
      }),
      variant: 'success',
    });
    onCancel();
  };

  return (
    <>
      <FileInput
        showIcon
        showSelected
        maxFileSize={parseInt(VITE_MAX_FILE_SIZE, 10)}
        callback={setFiles}
      />
      <ModalFooter onCancel={onCancel} onSubmit={onSave} />
    </>
  );
};

RequestFileUploadForm.propTypes = {
  caseId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default RequestFileUploadForm;
