enum MessageTemplateContext {
  Case = 'Case',
  CaseComplaint = 'CaseComplaint',
  CaseRepair = 'CaseRepair',
  CaseInsurance = 'CaseInsurance',
  Building = 'Building',
  Finance = 'Finance',
  Meeting = 'Meeting',
  Debtors = 'Debtors',
  Provision = 'Provision',
  HomeownerAssociation = 'HomeownerAssociation',
  Settlement = 'Settlement',
  CombinedCommunication = 'CombinedCommunication',
}

export default MessageTemplateContext;
