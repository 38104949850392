import { List, ListItem } from '@mui/joy';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Nullable } from '~/common/types';
import useToggle from '@/shared/hooks/UseToggle';

import { AppContext } from '@/shared/context/context';
import IconButton from '@/shared/components/buttons/IconButton';
import SidebarItem from './SidebarItem';

type CollapsibleSidebarItemProps = {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  badgeContent?: Nullable<string | number>;
  badgeTooltip?: string;
  hideCollapse?: boolean;
  route: string;
};

const CollapsibleSidebarItem: React.FC<CollapsibleSidebarItemProps> = ({
  title = '',
  icon = null,
  children = [],
  badgeContent = null,
  badgeTooltip = '',
  hideCollapse = false,
  route = '',
}) => {
  const { ui } = useContext(AppContext);
  const collapseToggle = useToggle();

  return (
    <ListItem
      nested
      endAction={
        ui.toggles.mainMenu && !hideCollapse ? (
          <IconButton
            iconComponent={
              <FontAwesomeIcon
                icon={['fad', 'angle-up']}
                className={classNames(
                  'mr-2 transition-all duration-150',
                  !collapseToggle.value && 'rotate-180',
                )}
              />
            }
            className="ml-2 flex items-center"
            onClick={() => collapseToggle.inverse()}
          />
        ) : null
      }
    >
      <List size="sm">
        <SidebarItem
          title={title}
          icon={icon}
          route={route}
          badgeContent={badgeContent}
          badgeTooltip={badgeTooltip}
        />

        <Collapse in={ui.toggles.mainMenu && collapseToggle.value} timeout="auto" unmountOnExit>
          <List size="sm" className="ml-6">
            {children}
          </List>
        </Collapse>
      </List>
    </ListItem>
  );
};

export default CollapsibleSidebarItem;
