import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CreatedByField, DescriptionField, InfoSection, TypeName } from '../ItemStyles/Item-Styles';
import NoteItemPropTypes from '@/shared/prop-types/NoteItemPropTypes';
import { transformHtml } from '@/shared/utils/helpers';
import useToggle from '@/shared/hooks/UseToggle';

import AddEditCaseNoteModal from '../../../../Notes/AddEditCaseNoteModal';
import WrappedText from '@/shared/components/WrappedText';

const propTypes = {
  note: NoteItemPropTypes.isRequired,
};

const NoteItem = ({ note }) => {
  const { t } = useTranslation();
  const detailedNoteToggle = useToggle();

  return (
    <>
      <TypeName>{t('note')}</TypeName>
      <CreatedByField>
        {note.createdOnClientPage ? t('clientPage') : note.createdByUser}
      </CreatedByField>

      <br />

      <InfoSection>
        {note.isDetailedNote ? (
          <div className="scrollbar-mailbox flex overflow-auto">
            <div className="email-body">{transformHtml(note.note)}</div>
          </div>
        ) : (
          <>
            <DescriptionField>{t('description')}: </DescriptionField>
            <WrappedText>{note.note}</WrappedText>
          </>
        )}
      </InfoSection>
      {note.isDetailedNote && (
        <div className="float-right">
          <Button variant="plain" onClick={detailedNoteToggle.show}>
            {t('viewDetails')}
          </Button>
          <AddEditCaseNoteModal note={note} toggle={detailedNoteToggle} />
        </div>
      )}
    </>
  );
};

export default NoteItem;

NoteItem.propTypes = propTypes;
