import AtBicValue from './atBicValue';
import BeBicValue from './beBicValue';
import ChBicValue from './chBicValue';
import DeBicValue from './deBicValue';
import EsBicValue from './esBicValue';
import FrBicValue from './frBicValue';
import LiBicValue from './liBicValue';
import LuBicValue from './luBicValue';
import McBicValue from './mcBicValue';
import NlBicValue from './nlBicValue';


export type BicValueKey =
  keyof typeof AtBicValue |
  keyof typeof BeBicValue |
  keyof typeof ChBicValue |
  keyof typeof DeBicValue |
  keyof typeof EsBicValue |
  keyof typeof FrBicValue |
  keyof typeof LiBicValue |
  keyof typeof LuBicValue |
  keyof typeof McBicValue |
  keyof typeof NlBicValue;

export const bicValueKeys = Object.keys(AtBicValue)
  .concat(Object.keys(BeBicValue))
  .concat(Object.keys(ChBicValue))
  .concat(Object.keys(DeBicValue))
  .concat(Object.keys(EsBicValue))
  .concat(Object.keys(FrBicValue))
  .concat(Object.keys(LiBicValue))
  .concat(Object.keys(LuBicValue))
  .concat(Object.keys(McBicValue))
  .concat(Object.keys(NlBicValue)) as BicValueKey[];

export {
  AtBicValue,
  BeBicValue,
  ChBicValue,
  DeBicValue,
  EsBicValue,
  FrBicValue,
  LiBicValue,
  LuBicValue,
  McBicValue,
  NlBicValue,
};
