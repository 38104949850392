import { Alert, Chip } from '@mui/joy';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { decapitalize, getLanguageFromCode } from '../../shared/utils/helpers';
import CaseStatusType from '../../shared/enums/CaseStatusType';
import Language from '../../shared/enums/Language';
import { OpenIcon } from '@/shared/icons/Icons';

const badgeColorMap = {
  [CaseStatusType.Open]: 'primary',
  [CaseStatusType.Pending]: 'warning',
  [CaseStatusType.Closed]: 'danger',
  [CaseStatusType.Cancelled]: 'danger',
};

const propTypes = {
  caseEntity: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    status: PropTypes.string,
    statusTranslations: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        language: PropTypes.oneOf([Language.Dutch, Language.English, Language.French]),
      }),
    ),
  }).isRequired,
};

const CaseChipDisplay = ({ caseEntity }) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const statusDescription = useMemo(
    () =>
      caseEntity.statusTranslations?.find((tr) => tr.language === getLanguageFromCode(language))
        ?.description ?? t(`common:${decapitalize(caseEntity.status)}`),
    [caseEntity, language, t],
  );

  return (
    <Alert
      startDecorator={<Chip color={badgeColorMap[caseEntity.status]}>{statusDescription}</Chip>}
      key={caseEntity.id}
      color={badgeColorMap[caseEntity.status]}
      size="sm"
    >
      <span className="grow truncate">
        {caseEntity.reference} - {caseEntity.description}
      </span>
      <Link to={`/case/${caseEntity.id}`} target="_blank">
        <OpenIcon />
      </Link>
    </Alert>
  );
};

CaseChipDisplay.propTypes = propTypes;

export default CaseChipDisplay;
