import { Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';

import { ComposeEmailFormikValues, SenderOrReceiver } from '@/types/email/EmailFormikValues';
import { FileManagerFile, File as UploadedFile } from '@/types/documents';
import { CaseAttachment } from '../AttachmentsUploadView';
import ComposeEmailBody from './ComposeEmailBody';
import FormikCaseEntityOptions from '@/shared/components/2.0/formik/FormikCaseEntityOptions';
import { useComposeEmailConfig } from './useComposeEmail';

type Props = {
  bcc?: SenderOrReceiver[];
  to?: SenderOrReceiver[];
  subject?: string;
  body?: string;
  caseFiles?: CaseAttachment[];
  contactSuggestions?: SenderOrReceiver[];
  initialFiles?: (UploadedFile | FileManagerFile)[];
  showCaseOptions?: boolean;
  onSubmit: (
    values: ComposeEmailFormikValues,
    formikHelpers: FormikHelpers<ComposeEmailFormikValues>,
  ) => void;
  onDelete?: () => void;
};

const ComposeEmail = forwardRef<FormikProps<ComposeEmailFormikValues> | null, Props>(
  (
    {
      onSubmit,
      subject,
      body,
      initialFiles = [],
      onDelete,
      caseFiles = [],
      contactSuggestions = [],
      showCaseOptions = false,
      to = [],
      bcc = [],
    },
    ref,
  ) => {
    const { initialValues, validationSchema, bodyIsLoading } = useComposeEmailConfig({
      attachments: initialFiles,
      bcc,
      body,
      subject,
      to,
    });

    return (
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        // @ts-ignore
        initialValues={initialValues}
        enableReinitialize
        validateOnMount={false}
        innerRef={ref}
      >
        <div className="h-100 flex flex-col overflow-auto px-1">
          <ComposeEmailBody
            onDelete={onDelete}
            caseFiles={caseFiles}
            contactSuggestions={contactSuggestions}
            bodyIsLoading={bodyIsLoading}
          />
          <div className="ml-4">{showCaseOptions && <FormikCaseEntityOptions allowRemark />}</div>
        </div>
      </Formik>
    );
  },
);

export default ComposeEmail;
