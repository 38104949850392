import { createFilterOptions } from '@mui/joy';
import { FilterOptionsState } from '@mui/base';
import { TFunction } from 'i18next';

import { CrmAutoCompleteOption } from '~/common/types';
import { CrmOptionType } from '~/common/enums';
import { UseToggle } from '@/shared/hooks/UseToggle';

export type ModalTypes = 'company' | 'contact' | 'centralCompany';

export const groupPrecedence: Record<CrmOptionType | 'default', number> = {
  [CrmOptionType.BuildingSuggestion]: 1,
  [CrmOptionType.Company]: 2,
  [CrmOptionType.Team]: 2,
  [CrmOptionType.Contact]: 2,
  [CrmOptionType.CentralCompany]: 2,
  default: 3,
  [CrmOptionType.Info]: 4,
  [CrmOptionType.ActionAdd]: 5,
};

export const filter = createFilterOptions<CrmAutoCompleteOption>({ limit: 20 });

export const sortOptions = (
  options: CrmAutoCompleteOption[] | undefined,
  excludeIds: string[] | undefined,
) =>
  options
    ?.filter((option) => !excludeIds?.includes(option.value as string))
    ?.sort((a, b) => {
      if (
        groupPrecedence[a.type as CrmOptionType] - groupPrecedence[b.type as CrmOptionType] ===
        0
      ) {
        return a.label.localeCompare(b.label);
      }
      return groupPrecedence[a.type as CrmOptionType] - groupPrecedence[b.type as CrmOptionType];
    }) ?? [];

export const optionsGroupBy = (type: CrmOptionType, t: TFunction) => {
  switch (type) {
    case CrmOptionType.BuildingSuggestion:
      return t('crmSelector.groups.buildingSuggestions');
    case CrmOptionType.Contact:
    case CrmOptionType.Company:
    case CrmOptionType.Team:
      return t('crmSelector.groups.contactsAndCompanies');
    case CrmOptionType.Info:
      return t('info');
    case CrmOptionType.ActionAdd:
      return t('actions');
    case CrmOptionType.CentralCompany:
      return t('centralCompanies');
    default:
      throw new Error(`Unexpected CRM option type: ${type}`);
  }
};

export const filterOptions = (
  options: CrmAutoCompleteOption[],
  sortedOptions: CrmAutoCompleteOption[],
  modalToggle: UseToggle<ModalTypes>,
  params: FilterOptionsState<CrmAutoCompleteOption>,
  query: string,
  areCrmOptionsLoading: boolean,
  showAddCentralCompany: boolean,
  showAddCompany: boolean,
  showAddContact: boolean,
  value: CrmAutoCompleteOption[] | CrmAutoCompleteOption | null | undefined,
  t: TFunction,
) => {
  const filtered = filter(options, params);

  if (areCrmOptionsLoading) {
    filtered.push({
      label: t('app.status.isLoading'),
      type: CrmOptionType.Info,
      value: '',
    });
  }

  if (sortedOptions.length && !areCrmOptionsLoading) {
    filtered.push({
      label: t('asyncAutocomplete.result', {
        count: filtered.length,
        search: query,
        selected: Array.isArray(value) ? value.length : value ? 1 : 0,
        total: sortedOptions.length,
      }),
      type: CrmOptionType.Info,
      value: '',
    });

    if (showAddCentralCompany) {
      filtered.push({
        action: () => modalToggle.show('centralCompany'),
        label: t('addType', { type: t('centralCompany').toLowerCase() }),
        type: CrmOptionType.ActionAdd,
        value: '',
      });
    }

    if (showAddCompany) {
      filtered.push({
        action: () => modalToggle.show('company'),
        label: t('addType', { type: t('company').toLowerCase() }),
        type: CrmOptionType.ActionAdd,
        value: '',
      });
    }

    if (showAddContact) {
      filtered.push({
        action: () => modalToggle.show('contact'),
        label: t('addType', { type: t('contact').toLowerCase() }),
        type: CrmOptionType.ActionAdd,
        value: '',
      });
    }
  }
  return filtered;
};
