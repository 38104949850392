import useViewport from '@/shared/hooks/useViewport';

const OverViewSkeleton = () => {
  const viewport = useViewport();

  return (
    <div
      style={{ height: viewport.mainContainerHeight }}
      className="animate-pulse flex-col space-y-4 p-4"
    >
      <div className="flex space-x-4">
        <div className="h-8 w-1/2 rounded-xl bg-gray-200" />
        <div className="h-8 w-1/2 rounded-xl bg-gray-200" />
      </div>
      <div className="flex space-x-4">
        <div className="h-8 w-10/12 rounded-xl bg-gray-200" />
        <div className="h-8 grow rounded-xl bg-gray-200" />
        <div className="h-8 grow rounded-xl bg-gray-200" />
        <div className="h-8 grow rounded-xl bg-gray-200" />
      </div>
      <div className="h-8 rounded-xl bg-gray-200" />
      <div className="h-48 rounded-xl bg-gray-200" />
      <div className="h-48 rounded-xl bg-gray-200" />
      <div className="h-48 rounded-xl bg-gray-200" />
      <div className="h-48 rounded-xl bg-gray-200" />
      <div className="h-48 rounded-xl bg-gray-200" />
    </div>
  );
};
export default OverViewSkeleton;
