import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import actionTypes from '../../actions/actionTypes';
import API from '../../../shared/api/ApiService';

function* handleGetTeams(msg, fetch) {
  const { serviceError, status, getNext, data } = yield call(fetch ?? API.fetchGetTeams);

  if (serviceError != null || status !== 200) {
    // eslint-disable-next-line no-console
    console.error(serviceError?.message ?? data.title);

    yield put({ type: actionTypes.GET_TEAMS_FAILED });
    return;
  }

  yield put({
    loading: getNext != null,
    teams: data,
    type: fetch == null ? actionTypes.GET_TEAMS_SUCCESS : actionTypes.TEAMS_APPEND,
  });

  if (getNext != null) {
    yield call(handleGetTeams, msg, getNext);
  }
}

function* handleGetCurrentTeam() {
  const response = yield call(API.fetchGetCurrentTeam);

  if (response.status === 403) {
    // eslint-disable-next-line no-console
    console.warn('No team has been selected');
    return;
  }

  if (response.serviceError != null || response.status !== 200) {
    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    currentTeam: response.data,
    type: actionTypes.GET_CURRENT_TEAM_SUCCESS,
  });
}

function* handleCurrentTeamUpdate() {
  yield put(push('/'));
  yield call(handleGetCurrentTeam);
}

function* watcherTeamSaga() {
  yield takeLatest(actionTypes.GET_TEAMS_REQUESTED, handleGetTeams);
  yield takeLatest(actionTypes.GET_TEAMS_REQUESTED, handleGetCurrentTeam);
  yield takeLatest(actionTypes.UPDATE_CURRENT_TEAM, handleCurrentTeamUpdate);
}

export default watcherTeamSaga;
