import config from '@/config/config';
import createRestClient from '../restClient';

import { AutocompleteOption } from '~/common/types';
import { CreateAxaInvoicingReportRequest } from '~/common/types/export/exportRequests';
import { ExportFilters } from '~/common/types/export/filter';
import { ExportItem } from '@/types/exports';

const client = createRestClient(config.baseURL);

const getCaseTypesAutocomplete = (params: Record<string, unknown>): Promise<AutocompleteOption[]> =>
  client.get('/export/case-type/autocomplete', { params });

const createExport = (data: Record<string, unknown>) => client.post('/export', data);

const getExportTemplatesAutocomplete = (params: Record<string, unknown>) =>
  client.get('/export/template/autocomplete', { params });

const getExportTemplate = ({ id, language }: { id: string; language: string }) =>
  client.get(`/export/template/${id}`, { params: { language } });

const createAxaInvoicingReport = (
  data: CreateAxaInvoicingReportRequest,
): Promise<undefined | ArrayBuffer> =>
  client.post('/export/axa-invoicing', data, { responseType: 'arraybuffer' });

const createExportTemplate = (data: Record<string, unknown>) =>
  client.post('/export/template', data);

const updateExportTemplate = (data: Record<string, unknown>) =>
  client.put('/export/template', data);

const getExportFiltersBySegment = (segment: string): Promise<ExportFilters> =>
  client.get(`/export/filter/multiselect/${segment}`);

const restClient = createRestClient();

const deleteExport = (id: string) => restClient.delete(`export/${id}`);

const getExport = (id: string) => restClient.get(`export/${id}`);

const getExports = (): Promise<ExportItem[]> => restClient.get('export/list.all');

const triggerExport = (data: Record<string, unknown>) => restClient.put('export/trigger', data);

export default {
  createAxaInvoicingReport,
  createExport,
  createExportTemplate,
  deleteExport,
  getCaseTypesAutocomplete,
  getExport,
  getExportFiltersBySegment,
  getExports,
  getExportTemplate,
  getExportTemplatesAutocomplete,
  triggerExport,
  updateExportTemplate,
};
