enum CrmOptionType {
  ActionAdd = 'ActionAdd',
  Info = 'Info',
  Contact = 'Contact',
  Company = 'Company',
  CentralCompany = 'CentralCompany',
  Team = 'Team',
  BuildingSuggestion = 'BuildingSuggestion',
}

export default CrmOptionType;
