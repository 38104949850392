import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import AvatarWithColor from '../AvatarWithColor';
import { EmailAddressRequest } from '~/common/types/meeting/meetingCommunications';
import { isValidEmailAddress } from '../../../utils/helpers';
import RelationType from '../../../enums/RelationType';
import { search } from '../../../utils/constants';
import { trpc } from '@/config/trpc';
import useDebounce from '../../../hooks/UseDebounce';

const SUGGESTION_TYPE = 'Suggestion';

type EmailSuggestion = EmailAddressRequest & {
  id: string;
};

type Props = {
  suggestions?: EmailSuggestion[];
  value: EmailAddressRequest[];
  onChange: (value: EmailAddressRequest[]) => void;
  helperText?: string;
  multiple?: boolean;
  error?: boolean;
  [key: string]: unknown;
};

const EmailContactSelector: React.FC<Props> = ({
  suggestions = [],
  value,
  onChange,
  helperText,
  error = false,
  multiple = true,
  ...rest
}) => {
  const { t } = useTranslation('common');

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const { data: emailAddresses, isLoading: isSearchLoading } = trpc.mailbox.search.useQuery(
    debouncedQuery,
    {
      enabled: debouncedQuery.length >= search.START_SEARCH_QUERY_LENGTH,
    },
  );

  const emailSuggestions = useMemo(() => {
    if (emailAddresses == null) return [];

    return emailAddresses
      .filter((x) => x.emailAddresses != null && x.emailAddresses.length > 0)
      .flatMap((contact) =>
        contact.emailAddresses.map((email) => ({
          email,
          id: contact.id,
          name: contact.name,
          type: contact.type,
        })),
      );
  }, [emailAddresses]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();

      if (isValidEmailAddress(query)) {
        onChange([
          ...value.filter((contact) => contact.email !== query),
          { email: query, name: query.split('@')[0] },
        ]);
      }
    }
  };

  const filteredSuggestions = suggestions
    .filter((suggestion) => suggestion.name && suggestion.name.toLowerCase().includes(query))
    .map((suggestion) => ({ ...suggestion, type: SUGGESTION_TYPE }));

  const isOpened =
    query.length >= search.START_SEARCH_QUERY_LENGTH || filteredSuggestions.length > 0;
  const autocompleteOptions = [...filteredSuggestions, ...emailSuggestions];

  return (
    <FormControl error={error}>
      <Autocomplete
        size="sm"
        forcePopupIcon={false}
        loading={isOpened && isSearchLoading}
        options={autocompleteOptions}
        onInputChange={(_, val) => setQuery(val.toLowerCase())}
        onChange={(_, val) => onChange(val)}
        groupBy={(option) => {
          switch (option.type) {
            case RelationType.Contact:
              return t('contacts');
            case RelationType.Company:
              return t('companies');
            case SUGGESTION_TYPE:
              return t('suggestions');
            default:
              return '';
          }
        }}
        autoComplete={false}
        inputValue={query}
        onKeyDownCapture={handleKeyDown}
        value={value}
        disableClearable
        filterOptions={(options, params) => {
          const { inputValue } = params;

          const isExisting = options.some((option) => option.email === inputValue);
          if (inputValue !== '' && !isExisting && isValidEmailAddress(inputValue)) {
            options.push({
              customIcon: 'plus',
              customOptionLabel: t('useThisAddress', { address: inputValue }),
              email: inputValue,
              name: query.split('@')[0],
            });
          }

          if (inputValue.length < search.START_SEARCH_QUERY_LENGTH) {
            options.push({
              customIcon: 'pen-line',
              customOptionLabel: t('startTypingToSearchNetwork'),
              disabled: true,
              email: '',
            });
          }

          return options;
        }}
        multiple={multiple}
        // freeSolo so we dont show No options text initially
        freeSolo={query.length < search.START_SEARCH_QUERY_LENGTH}
        clearOnBlur
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={`option-${option.email}-${option.name}-${option.id}`}>
            <ListItemDecorator>
              {option.customIcon ? (
                <FontAwesomeIcon icon={['fas', option.customIcon]} />
              ) : (
                <AvatarWithColor name={option.name} size="sm" />
              )}
            </ListItemDecorator>
            <ListItemContent className="flex items-baseline text-sm">
              {option.customOptionLabel ? (
                <span>{option.customOptionLabel}</span>
              ) : (
                <>
                  <span>{option.name}</span> <span className="ml-1 text-xs">[{option.email}]</span>
                </>
              )}
            </ListItemContent>
          </AutocompleteOption>
        )}
        getOptionDisabled={(option) => option.disabled}
        getOptionLabel={(option) => option.email}
        noOptionsText={t('noOptions')}
        limitTags={2}
        {...rest}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default EmailContactSelector;
