import { Button, ButtonGroup, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';
import useToggle from '@/shared/hooks/UseToggle';

import {
  DropDownIcon,
  InlineSpinner,
  NextIcon,
  PreviousIcon,
  SaveIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

const AddInsuranceCaseModalFooter = ({ data, setData, isLastStep, isSubmitting }) => {
  const [modalTitle, setModalTitle] = useState('');

  const { t } = useTranslation();
  const {
    currentTeam: { repairInKindPossible },
  } = useContext(AppContext);
  const { values, errors, submitForm, setFieldValue } = useFormikContext();

  const skipStepToggle = useToggle();

  const policies = !values.policySelector ? null : values.policySelector.policies;

  const disableNextButton = () => {
    if (data?.step === 0 && values.buildingSelector == null) {
      return true;
    }

    if (data?.step === 2 && (errors.admins || errors.reference || errors.priority)) {
      return true;
    }

    if (data?.step === 3 && (errors.damageType || errors.description || errors.incidentDate)) {
      return true;
    }

    if (data?.step === 4 && errors.caseRelations) {
      return true;
    }

    return false;
  };

  const disableCreateInsuranceCaseButton = () => {
    if (values.selfManagement === null) {
      return true;
    }

    if (values.selfManagement === true && repairInKindPossible && values.repairByKeypoint == null) {
      return true;
    }

    if (values.selfManagement === false && errors.ownerTeamId) {
      return true;
    }

    if (repairInKindPossible && values.repairByKeypoint === null) {
      return true;
    }

    return false;
  };

  const showModal = (action) => {
    switch (action) {
      case 'skip':
        if (data.step === 1) {
          skipStepToggle.show();
          setModalTitle(
            policies && policies.length > 0
              ? t('theSelectedPolicyWillBeRemoved')
              : t('areYouSureYouWantToProceedWithoutSelectingAPolicy'),
          );
          return;
        }
        setData({ ...data, step: data.step + 1 });
        break;
      case 'next':
        if (
          data.step === 1 &&
          (!policies || policies.length === 0) &&
          data.suggestedPolicies &&
          data.suggestedPolicies.length > 0
        ) {
          skipStepToggle.show();
          setModalTitle(t('areYouSureYouWantToProceedWithoutSelectingAPolicy'));
          return;
        }
        setData({ ...data, step: data.step + 1 });
        break;
      default:
        break;
    }
  };

  const saveAndOpen = () => {
    setFieldValue('openUponSubmit', true);
    submitForm();
  };

  return (
    <div className="mt-8">
      {!data?.isAddingInvolvedParty && (
        <div className="flex justify-center space-x-4">
          {data.step > 0 && (
            <Button
              startDecorator={<PreviousIcon />}
              onClick={() => setData({ ...data, step: data.step - 1 })}
              disabled={isSubmitting}
            >
              {t('previous')}
            </Button>
          )}
          {!isLastStep ? (
            <Button
              endDecorator={<NextIcon />}
              disabled={disableNextButton()}
              onClick={() => showModal('next')}
            >
              {t('next')}
            </Button>
          ) : (
            <ButtonGroup>
              <Button
                startDecorator={isSubmitting ? <InlineSpinner /> : <SaveIcon />}
                disabled={disableCreateInsuranceCaseButton() || isSubmitting}
                variant="solid"
                color="primary"
                onClick={saveAndOpen}
              >
                {t('saveAndOpen')}
              </Button>
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{ root: { color: 'primary', variant: 'outlined' } }}
                >
                  <DropDownIcon />
                </MenuButton>
                <Menu className="z-popperModal">
                  <MenuItem
                    disabled={disableCreateInsuranceCaseButton() || isSubmitting}
                    onClick={submitForm}
                  >
                    {t('saveAndClose')}
                  </MenuItem>
                </Menu>
              </Dropdown>
            </ButtonGroup>
          )}
          {data.step === 1 && (
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => {
                showModal('skip');
              }}
            >
              {t('skipStep')}
            </Button>
          )}
        </div>
      )}
      <KpcModal
        toggle={skipStepToggle}
        title={t('areYouSure')}
        icon={<WarningIcon size="xl" style={{ color: '#FFCC00' }} />}
      >
        <>
          <div className="text-center">{modalTitle}</div>
          <div className="flex justify-center space-x-4">
            <Button variant="outlined" color="neutral" onClick={() => skipStepToggle.hide()}>
              {t('cancel')}
            </Button>
            <Button
              onClick={() => {
                setData({ ...data, step: data.step + 1 });
                skipStepToggle.hide();
                setFieldValue('policySelector', null);
              }}
            >
              {t('yes')}
            </Button>
          </div>
        </>
      </KpcModal>
    </div>
  );
};

AddInsuranceCaseModalFooter.propTypes = {
  data: PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.shape({
        addressLine: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        postalCode: PropTypes.string,
      }),
    }),
    caseInfo: PropTypes.shape({
      admins: PropTypes.arrayOf(PropTypes.string),
    }),
    isAddingInvolvedParty: PropTypes.bool,
    policies: PropTypes.arrayOf(PropTypes.shape({})),
    step: PropTypes.number.isRequired,
    suggestedPolicies: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isLastStep: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
};

export default AddInsuranceCaseModalFooter;
