import { Tooltip as JoyToolTip } from '@mui/joy';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

const Tooltip = ({ children, placement = 'top', ...rest }) => (
  <JoyToolTip arrow placement={placement} size="sm" {...rest}>
    <div className="inline">{children}</div>
  </JoyToolTip>
);

Tooltip.propTypes = propTypes;

export default Tooltip;
