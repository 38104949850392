import { getLanguageFromCode } from '../../shared/utils/helpers';

interface Translation {
  language: string;
  description: string;
}

const formatContractorSkillDefinition = (translations: Translation[], language: string): string => {
  if (!translations) return '-';

  const lang = getLanguageFromCode(language.slice(0, 2));
  const description = translations.find((tr) => tr.language === lang)?.description.trim();
  return description ?? '-';
};

export default formatContractorSkillDefinition;
