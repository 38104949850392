import { Col, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikTimePicker from '../../../../../../shared/formik/FormikTimePicker';
import FormikYesNo from '../../../../../../shared/formik/FormikYesNo';

const AppointmentFormBody = ({ allowExecutionOnTimeAndExpenseBasis, isCaseOwnerOrContractor }) => {
  const { t } = useTranslation('common');
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.appointmentTime != null) {
      setFieldValue('appointmentAmPm', null);
    }
  }, [setFieldValue, values.appointmentTime]);

  return (
    <>
      <FormGroup className="mb-3">
        <Row>
          <Col xs={5}>
            <FormikDatePicker
              name="appointmentDate"
              disabled={!isCaseOwnerOrContractor}
              label={t('date')}
              options={{
                format: 'YYYY-MM-DD',
              }}
              clearable
            />
          </Col>
          <Col xs={4}>
            <FormikTimePicker
              id="appointmentTime"
              name="appointmentTime"
              label={t('common:time')}
              disabled={!isCaseOwnerOrContractor}
            />
          </Col>
          {values.appointmentTime == null && (
            <Col xs={3}>
              <FormikYesNo
                id="appointmentAmPm"
                name="appointmentAmPm"
                noText={t('common:morning')}
                yesText={t('common:afternoon')}
                disabled={!isCaseOwnerOrContractor}
                isDefaultChecked={false}
              />
            </Col>
          )}
        </Row>
      </FormGroup>

      <FormGroup className="mb-3">
        <FormikYesNo
          id="appointmentForSpecifications"
          name="appointmentForSpecifications"
          yesText={t('common:appointmentForSpecifications')}
          noText={t('common:appointmentForSecurisationOrFirstIntervention')}
          isDefaultChecked={false}
          disabled={!isCaseOwnerOrContractor}
        />
      </FormGroup>

      {allowExecutionOnTimeAndExpenseBasis && (
        <FormGroup className="mb-3">
          <FormikYesNo
            id="executeOnTimeAndExpenseBasis"
            name="executeOnTimeAndExpenseBasis"
            yesText={t('common:onTimeAndExpenseBasis')}
            noText={t('common:notOnTimeAndExpenseBasis')}
            isDefaultChecked={false}
            disabled={!isCaseOwnerOrContractor}
          />
        </FormGroup>
      )}
    </>
  );
};

export default AppointmentFormBody;

AppointmentFormBody.propTypes = {
  allowExecutionOnTimeAndExpenseBasis: PropTypes.bool.isRequired,
  isCaseOwnerOrContractor: PropTypes.bool.isRequired,
};
