import { useContext, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AddressIconPretty, BuildingIconPretty } from '@/shared/icons/Icons';
import { decapitalize, getAddressString } from '@/shared/utils/helpers';
import { useGetBuilding, useGetCases } from '@/queries';
import { AppContext } from '@/shared/context/context';
import CaseChipDisplay from '../../../CaseChipDisplay';
import FormikBuildingSelector from '@/shared/formik/FormikBuildingSelector';
import ReferencePropType from '@/shared/prop-types/ReferencePropType';
import useGetLinkedPolicies from '@/queries/policies/useGetLinkedPolicies';

const BuildingSelectorStep = ({ popperContainer }) => {
  const { currentTeam, currentTeamUser } = useContext(AppContext);

  const { t } = useTranslation(['common', 'insurancePolicy']);
  const [relatedPolicies, setRelatedPolicies] = useState([]);
  const { values, setFieldValue, touched, initialValues } = useFormikContext();

  const { buildingId, address, buildingSelector } = values;

  const { building, buildingIsLoading } = useGetBuilding({ buildingId });

  const autocomplete = {
    buildings: buildingId ? [{ value: [buildingId] }] : [],
  };

  const input = {
    addressLine: address && !buildingId ? address.addressLine : null,
    city: address && !buildingId ? address.city : null,
  };

  const { cases, casesIsLoading, loadMoreCases, hasMoreCases } = useGetCases({
    enabled: !!(buildingId || address),
    formatOptions: { currentTeam, currentTeamUser },
    params: buildingId ? { autocomplete } : { input },
  });

  useEffect(() => {
    if (!casesIsLoading && hasMoreCases) {
      loadMoreCases();
    }
  }, [hasMoreCases, loadMoreCases, casesIsLoading]);

  const relatedCases = useMemo(() => {
    if (casesIsLoading || !cases.length) {
      return [];
    }
    return cases
      .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified))
      .map((c) => <CaseChipDisplay caseEntity={c} />);
  }, [buildingId, cases, casesIsLoading]);

  const { linkedPolicies, linkedPoliciesAreLoading } = useGetLinkedPolicies({
    ...address,
    buildingId: buildingSelector?.isAddress ? null : buildingId,
  });

  useEffect(() => {
    if (linkedPoliciesAreLoading) {
      return;
    }

    setRelatedPolicies(linkedPolicies.map((p) => p.insurancePolicyType));
  }, [linkedPoliciesAreLoading, linkedPolicies]);

  const handleBuildingChanged = (buildingData) => {
    setFieldValue('policySelector', null, false);

    if (buildingData) {
      setFieldValue('buildingId', buildingData.isAddress ? null : buildingData.id);
      setFieldValue('address', buildingData.address);
      return;
    }

    setFieldValue('buildingId', null);
    setFieldValue('address', null);
  };

  useEffect(() => {
    if (buildingIsLoading || !touched.buildingSelector) {
      return;
    }

    if (building.administrators?.length) {
      setFieldValue('admins', building.administrators);

      return;
    }
    setFieldValue('admins', initialValues.admins);
  }, [buildingIsLoading, building, initialValues.admins, setFieldValue, touched.buildingSelector]);

  return (
    <div className="flex flex-col space-y-4">
      <h3 className="font-medium">{t('common:buildingDetails')}</h3>
      <p className="text-sm">{t('common:forWhichBuildingDoYouWantToEnterADamage')}</p>
      <FormikBuildingSelector
        selectSingle
        id="buildingSelector"
        name="buildingSelector"
        label={t('common:building')}
        isRequired
        popperContainer={popperContainer}
        disablePortal={false}
        callback={handleBuildingChanged}
      />
      <div>
        {!!address && (
          <>
            <div className="flex">
              <div className="p-4">
                {buildingId ? <BuildingIconPretty /> : <AddressIconPretty />}
              </div>
              <div className="grow">
                {building && (
                  <div className="flex">
                    <div className="w-1/3">{t('common:vatNumber')}</div>
                    <div>{building.vatNumber ?? '-'}</div>
                  </div>
                )}
                {address && (
                  <div className="flex">
                    <div className="w-1/3">{t('common:_address.label')}</div>
                    <div>
                      {getAddressString(
                        address.addressLine,
                        address.city,
                        address.postalCode,
                        address.country,
                      )}
                    </div>
                  </div>
                )}
                <div className="flex">
                  <div className="w-1/3">{t('common:linkedPolicies')}</div>
                  <div>{relatedPolicies.length}</div>
                </div>
                {relatedPolicies && relatedPolicies.length > 0 && (
                  <div className="flex">
                    <div className="w-1/3">{t('common:linkedPolicyTypes')}</div>
                    <div>
                      {relatedPolicies.map((rp, index) => {
                        let policyTypes = t(`insurancePolicy:${decapitalize(rp)}`);
                        if (index !== relatedPolicies.length - 1) {
                          policyTypes = policyTypes.concat(', ');
                        }
                        return policyTypes;
                      })}
                    </div>
                  </div>
                )}
                <div className="flex">
                  <div className="w-1/3">{t('common:otherCases')}</div>
                  <div>{relatedCases.length}</div>
                </div>
              </div>
            </div>
            <div>
              {relatedCases.length > 0 && (
                <Stack
                  className="flex max-h-48 flex-col space-y-2 overflow-y-auto"
                  direction="column"
                >
                  {relatedCases}
                </Stack>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

BuildingSelectorStep.propTypes = {
  popperContainer: ReferencePropType,
};

BuildingSelectorStep.defaultProps = {
  popperContainer: null,
};

export default BuildingSelectorStep;
