const VatCode = {
  CoContractorPurchase21: 'CoContractorPurchase21',
  CoContractorPurchase12: 'CoContractorPurchase12',
  CoContractorPurchase6: 'CoContractorPurchase6',
  InlandExclusive6: 'InlandExclusive6',
  InlandExclusive12: 'InlandExclusive12',
  InlandExclusive21: 'InlandExclusive21',
};

export default VatCode;
