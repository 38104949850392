import { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import List from '@mui/joy/List';
import { ListDivider } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { debounce } from 'lodash';
import {
  AdminIcon,
  BuildingIcon,
  CalendarIcon,
  CompanyIcon,
  ContactIcon,
  FinanceIcon,
  HandshakeIcon,
  HomeIcon,
  InsurancePolicyIcon,
  MailboxIcon,
  ReportIcon,
  SupportIcon,
  TaskIcon,
  UploadIcon,
} from '@/shared/icons/Icons';
import { AppContext } from '@/shared/context/context';
import { useUserRole } from '@/queries';

import CaseSidebarContent from './CaseSidebarContent';
import CollapsibleSidebarItem from './CollapsibleSidebarItem';
import ROUTES from '../../App/Router/Routes';
import SidebarItem from './SidebarItem';
import { trpc } from '@/config/trpc';
import signalRMessages from '@/redux/actions/signalRMessages';

const CustomListDivider = () => <ListDivider className="my-3 bg-slate-100" />;

const Sidebar = () => {
  const { t } = useTranslation();
  const { isAdmin, isSupervisor } = useUserRole();
  const { currentTeam, ui, socket } = useContext(AppContext);
  const DEBOUNCE_TIME = 2000;

  const allowRepairManagement = currentTeam ? currentTeam.allowRepairManagement : false;
  const allowSyndicManagement = currentTeam ? currentTeam.allowSyndicManagement : false;

  const { data: numberOfIncompleteBatches, refetch } =
    trpc.mail.batch.incompleteBatchCount.useQuery();

  useEffect(() => {
    const debounceFunc = debounce(refetch, DEBOUNCE_TIME);
    socket?.on(signalRMessages.BatchStatusUpdated, debounceFunc);
    socket?.on(signalRMessages.BatchDeleted, debounceFunc);
    socket?.on(signalRMessages.BatchCreated, debounceFunc);

    return () => {
      socket?.off(signalRMessages.BatchStatusUpdated, debounceFunc);
      socket?.off(signalRMessages.BatchDeleted, debounceFunc);
      socket?.off(signalRMessages.BatchCreated, debounceFunc);
    };
  }, [socket, refetch]);

  const sidebarStyles = useMemo(
    () =>
      classNames(
        'fixed left-0 top-0 h-screen overflow-y-auto overflow-x-hidden bg-white pt-[90px] z-sidebar',
        {
          'w-[180px]': ui.toggles.mainMenu,
          'w-[60px]': !ui.toggles.mainMenu,
        },
      ),
    [ui.toggles.mainMenu],
  );

  return (
    <List size="sm" className={sidebarStyles}>
      <SidebarItem icon={<HomeIcon />} route={ROUTES.HOME} title={t('dashboard.label')} />
      <CustomListDivider />
      <SidebarItem icon={<MailboxIcon />} route={ROUTES.MAILBOX} title={t('mailbox')} />
      <SidebarItem icon={<CalendarIcon />} route={ROUTES.CALENDAR} title={t('calendar')} />
      <SidebarItem title={t('tasks')} icon={<TaskIcon />} route={ROUTES.TASKS_OVERVIEW} />
      <CustomListDivider />
      <SidebarItem title={t('contacts')} icon={<ContactIcon />} route={ROUTES.CONTACT_DASHBOARD} />
      <SidebarItem
        title={t('companies')}
        icon={<CompanyIcon />}
        route={ROUTES.COMPANIES_OVERVIEW}
      />
      <SidebarItem
        title={t('buildings')}
        icon={<BuildingIcon />}
        route={ROUTES.BUILDINGS_OVERVIEW}
      />
      <SidebarItem
        title={t('policies')}
        icon={<InsurancePolicyIcon />}
        route={ROUTES.POLICY_OVERVIEW}
      />
      <CustomListDivider />
      <CaseSidebarContent />
      <CustomListDivider />
      {allowSyndicManagement && (
        <>
          <SidebarItem
            title={t('meetings')}
            icon={<HandshakeIcon />}
            route={ROUTES.MEETINGS_OVERVIEW}
          />
          <CustomListDivider />
        </>
      )}
      <SidebarItem title={t('insights.title')} icon={<ReportIcon />} route={ROUTES.INSIGHTS} />
      <CustomListDivider />
      {(allowRepairManagement || allowSyndicManagement) && (
        <>
          <SidebarItem
            title={t('mailings')}
            icon={<UploadIcon />}
            route={ROUTES.BATCH_COMMUNICATION_OVERVIEW}
            badgeContent={numberOfIncompleteBatches}
            badgeTooltip={t('common:communicationSidebarTooltip', {
              batchesCount: numberOfIncompleteBatches,
            })}
          />
          <CustomListDivider />
        </>
      )}
      {(allowSyndicManagement || allowRepairManagement) && (
        <>
          <CollapsibleSidebarItem title={t('finance')} icon={<FinanceIcon />} route="#">
            <SidebarItem title={t('invoices')} route={ROUTES.INVOICE_MANAGEMENT} isChild />
            {allowSyndicManagement && (
              <SidebarItem title={t('payments')} route={ROUTES.ACCOUNTING_ENVELOPES} isChild />
            )}
            {(isSupervisor || isAdmin) && allowRepairManagement && (
              <SidebarItem isChild title={t('debtors')} route={ROUTES.REPAIR_DEBTOR_MANAGEMENT} />
            )}
            {allowSyndicManagement && (
              <>
                <SidebarItem isChild title={t('debtors')} route={ROUTES.HOA_DEBTOR_MANAGEMENT} />
                <SidebarItem title={t('statements')} route={ROUTES.ACCOUNTING_STATEMENTS} isChild />
              </>
            )}
          </CollapsibleSidebarItem>
          <CustomListDivider />
        </>
      )}
      <li className="mb-2 lg:mt-auto">
        <List size="sm">
          {isAdmin && (
            <SidebarItem
              title={t('administration')}
              icon={<AdminIcon />}
              route={ROUTES.ADMINISTRATION}
            />
          )}
          <SidebarItem
            title={t('support')}
            icon={<SupportIcon />}
            route={ROUTES.SUPPORT_OVERVIEW}
          />
        </List>
      </li>
    </List>
  );
};

export default Sidebar;
