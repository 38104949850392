import qs from 'qs';

import { Batch } from '~/common/types/batchCommunication/batch';
import { BatchMessage } from '~/common/types/batchCommunication/batchMessage';
import { components } from '~/common/types/kpc.d';
import config from '../../../config/config';
import createRestClient from '../restClient';
import { ProcessBatchOrMessagesRequest } from '~/common/types/batchCommunication/processBatchOrMessagesRequest';
import { UpdateBatchMessageStatusRequest } from '~/common/types/batchCommunication/updateBatchMessageStatusRequest';

export type DownloadBatchAttachmentsRequest =
  components['schemas']['KeypointConnect.Messaging.Backend.Rest.Batches.V1.DTOs.Requests.DownloadBatchMessageAttachmentRequest'];

const restClient = createRestClient(config.baseURL);
const client = createRestClient(null, true);

const getBatches: () => Promise<Batch[]> = () => restClient.get('/batchCommunication');

const cancelBatch = (batchId: string) => restClient.post(`/batchCommunication/${batchId}/cancel`);

const processBatchOrMessages = (data: ProcessBatchOrMessagesRequest) =>
  restClient.post('/batchCommunication/process', data);

const updateBatchMessageStatus = (data: UpdateBatchMessageStatusRequest) =>
  restClient.put('/batchCommunication/message/status', data);

const getBatchMessages: (batchId: string) => Promise<BatchMessage[]> = (batchId) =>
  restClient.get(`/batchCommunication/${batchId}/messages`);

const getBatchMessageFiles: (batchMessageId: string) => Promise<Document[]> = (batchMessageId) =>
  restClient.get(`/batchCommunication/message/${batchMessageId}/files`);

const downloadBatchMessageAttachments = ({
  request,
}: {
  request: { requests: DownloadBatchAttachmentsRequest[]; shouldMerge: boolean };
}) =>
  client.get('/communication/batch-messages/download-attachments', {
    params: request,
    paramsSerializer: (p) => qs.stringify(p, { allowDots: true, encode: false, skipNulls: true }),
    responseType: 'blob',
  });

export default {
  cancelBatch,
  downloadBatchMessageAttachments,
  getBatches,
  getBatchMessageFiles,
  getBatchMessages,
  processBatchOrMessages,
  updateBatchMessageStatus,
};
