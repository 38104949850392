import { IconButton, Tooltip, Typography } from '@mui/joy';
import React, { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'ka-table';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';
import { customCellWidth } from '@/shared/utils/constants';
import { mergePaginatedResults } from '@/shared/utils/helpers';
import Routes from '@/containers/App/Router/Routes';
import { trpc } from '@/config/trpc';

import { InlineSpinner, NewTabIcon, TaskIcon } from '@/shared/icons/Icons';
import DueDateCell from '@/shared/components/2.0/customTableCells/DueDateCell';
import LinkCustomCell from '@/containers/Tasks/CustomTableCells/LinkCustomCell';
import PriorityCustomCell from '@/containers/Cases/CustomTableCells/PriorityCustomCell';
import useNotification from '@/shared/hooks/UseNotification';

type FinishTaskCustomCellProps = {
  value: string;
  callback: () => void;
};

const FinishTaskCustomCell: React.FC<FinishTaskCustomCellProps> = ({ value, callback }) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();

  const { mutateAsync: finishTask, error, isLoading } = trpc.task.finishTask.useMutation();

  return (
    <Tooltip title="Finish task">
      <IconButton
        onClick={async () => {
          await finishTask(value);
          sendNotification({ message: t('_task.status.finish') });

          if (callback) {
            callback();
          }
          if (error) {
            sendDefaultError();
          }
        }}
      >
        {isLoading ? <InlineSpinner /> : <TaskIcon />}
      </IconButton>
    </Tooltip>
  );
};

const OpenTaskTable = () => {
  const { ui } = useContext(AppContext);
  const { t } = useTranslation();

  const { data, fetchNextPage, isLoading, refetch, isFetchingNextPage, isFetching } =
    trpc.task.infiniteTasks.useInfiniteQuery(
      { params: { isAssignedToMyTeam: true, isCompleted: false } },
      {
        enabled: ui.dashboard.toggles.openTasksTable,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        initialCursor: 1,
      },
    );

  const mergedInfiniteTasks = useMemo(() => mergePaginatedResults(data), [data]);
  const refetchTasks = useCallback(() => refetch(), [refetch]);

  return (
    <div className="space-y-4 rounded-xl bg-white p-4">
      <div className="flex space-x-4">
        <Typography className="grow font-medium">{t('openTasks')}</Typography>
        <Link to={Routes.TASKS_OVERVIEW}>
          <IconButton>
            <NewTabIcon />
          </IconButton>
        </Link>
      </div>

      <div className="rounded-xl border">
        <Table
          loading={{ enabled: isLoading || isFetchingNextPage || isFetching }}
          height={420}
          columns={[
            { key: 'priority', title: t('_task.columns.priority'), width: customCellWidth.S },
            { key: 'link', title: t('_task.columns.link') },
            { key: 'dueDate', title: t('_task.columns.dueDate') },
            { key: 'description', title: t('_task.columns.description') },
            { key: 'id', title: t('_task.columns.id'), width: customCellWidth.S },
          ]}
          data={mergedInfiniteTasks || []}
          rowKeyField="id"
          childComponents={{
            cellText: {
              // eslint-disable-next-line react/no-unstable-nested-components
              content: (props) => {
                switch (props.column.key) {
                  case 'link':
                    return <LinkCustomCell {...props} />;
                  case 'dueDate':
                    return <DueDateCell {...props} />;
                  case 'priority':
                    return <PriorityCustomCell {...props} />;
                  case 'id':
                    return <FinishTaskCustomCell {...props} callback={refetchTasks} />;
                  default:
                    return (
                      <Typography level="body-sm" className="text-xxs">
                        {props.value}
                      </Typography>
                    );
                }
              },
            },
            tableWrapper: {
              elementAttributes: () => ({
                onScroll: (event, { baseFunc }) => {
                  baseFunc(event);
                  const scrollContainer = event.currentTarget;
                  const canLoadMore =
                    scrollContainer.offsetHeight + scrollContainer.scrollTop >=
                    scrollContainer.scrollHeight - 20;
                  if (canLoadMore) {
                    fetchNextPage();
                  }
                },
              }),
            },
          }}
        />
      </div>
    </div>
  );
};

export default OpenTaskTable;
