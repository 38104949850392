import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import emailApi from '@/shared/api/mailbox/email';
import useNotification from '@/shared/hooks/UseNotification';

const useSendEmailMessage = () => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation({
    mutationFn: emailApi.sendEmailMessage,
    onError: sendDefaultError,
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullySent', { type: t('email_one') }),
        variant: 'success',
      });
    },
  });

  return {
    isSendingMessage: isLoading,
    sendMessage: mutate,
  };
};

export default useSendEmailMessage;
