const DocumentRelation = {
  Building: 'Building',
  Case: 'Case',
  CaseTemplate: 'CaseTemplate',
  Contact: 'Contact',
  Meeting: 'Meeting',
  MeetingType: 'MeetingType',
  Policy: 'policy',
  Spreadsheet: 'Spreadsheet',
  Unit: 'unit',
};

export default DocumentRelation;
