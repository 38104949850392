import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '../../shared/api/cases/case';
import { decapitalize } from '@/shared/utils/helpers';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetSelectorCases = ({ inactive, select = (data) => data }) => {
  const { sendDefaultError } = useNotification();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    enabled: !inactive,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await caseApi.getSelectorCases();

      const formatted = result.map((c) => ({
        label: `${c.reference}${
          c.description && c.description !== '-'
            ? ` | ${c.description.length > 50 ? `${c.description.slice(0, 50)}...` : c.description}`
            : ''
        }${c.requestors && c.requestors.length ? ` | ${c.requestors.join(', ')}` : ''} | ${t(
          decapitalize(c.status),
        )}`,
        type: c.caseType,
        value: c.id,
      }));

      return { formatted, result };
    },
    queryKey: [queryIds.cases.GET_SELECTOR_CASES],
    select,
  });

  return {
    selectorCases: data?.result || [],
    selectorCasesFormatted: data?.formatted || [],
    selectorCasesIsLoading: isLoading,
  };
};

export default useGetSelectorCases;
