import { Case } from '@/types/cases';
import { CaseType } from '../enums';

import { ExportTemplate, Filter } from '~/common/types/export/template';

export const sortArrayOfObjects = <T extends { [key: string]: unknown }>(
  arr: T[],
  prop: keyof T,
) => {
  arr.sort((a, b) => {
    if (a[prop] < b[prop]) return -1;
    if (a[prop] > b[prop]) return 1;
    return 0;
  });

  return arr;
};

export const getActiveFiltersFromCurrentTemplate = (currentExportTemplate: ExportTemplate) => {
  const exportTemplateActiveFilters: { [key: string]: boolean } = {};
  const unpaginatedFilters = ['CreatedOn', 'DueDate', 'Priority', 'Case', 'TaskType'];

  if (currentExportTemplate.filters) {
    const { filters } = currentExportTemplate;
    const sortedFilters = sortArrayOfObjects(filters, 'type');
    sortedFilters.forEach((obj: Filter) => {
      if (unpaginatedFilters.includes(obj.type) || !!obj.autocompleteValues.length) {
        exportTemplateActiveFilters[obj.type] = true;
      }
    });
  }

  return exportTemplateActiveFilters;
};

export const getCaseCaseType = (
  currentCase: Pick<Case, 'isRepairCase' | 'isComplaintCase' | 'isInsuranceClaimCase'>,
) => {
  const { isRepairCase, isComplaintCase, isInsuranceClaimCase } = currentCase;
  if (isRepairCase) return CaseType.Repair;
  if (isComplaintCase) return CaseType.Complaint;
  if (isInsuranceClaimCase) return CaseType.InsuranceClaim;
  return CaseType.Case;
};

type ToFormatContact = {
  firstName?: string | null;
  lastName?: string | null;
};

export const formatContactDisplayName = (contact: ToFormatContact | null) => {
  if (!contact || (!contact.firstName && !contact.lastName)) {
    return '-';
  }

  const formattedFirstName = contact.firstName?.trim() || '';
  const formattedLastName = contact.lastName?.trim() || '';
  const seperator = formattedLastName && formattedFirstName ? ' ' : '';

  return `${formattedFirstName}${seperator}${formattedLastName}`;
};

type ReplaceMap = Record<string, string>;

export const mappedReplace = (value: string, replaceMap: ReplaceMap) => {
  const regExp = new RegExp(Object.keys(replaceMap).join('|'), 'g');

  return value.replace(regExp, (match) => replaceMap[match]);
};

export const replaceUrlParams = (route: string, params: ReplaceMap) => {
  const replaceMap: ReplaceMap = {
    ...params,
    // remove delimiter for all react route params
    ':': '',
  };

  return mappedReplace(route, replaceMap);
};

export const toPercentage = (value: number) => Number((value * 100).toFixed(2));

export const containsOnlyDigits = (value: string) => /^\d+$/.test(value);

export const removeSubstrings = (value: string, substrings: string[]) => {
  const substringRegex = new RegExp(substrings.join('|'), 'gi');
  const multiSpaceRegex = /\s+/g;

  const result = value.replace(substringRegex, '');

  return result.replace(multiSpaceRegex, ' ').trim();
};

export const roundToNearest = (factor: number, num: number | string) => {
  if (typeof num !== 'number') {
    return 0;
  }

  const remainder = num % factor;
  const halfFactor = factor / 2;
  return remainder >= halfFactor
    ? Math.ceil(num / factor) * factor
    : Math.floor(num / factor) * factor;
};

export const getEnumWithNumberValues = (
  enumObject: Record<string, string | number>,
): { name: string; value: number }[] => {
  const array = Object.entries(enumObject);
  return array
    .filter(([_, value]) => typeof value === 'number')
    .map(([key, value]) => ({ name: key, value: value as number }));
};
