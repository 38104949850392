import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { BilledStatus } from '~/common/enums';
import { Performance } from '@/types/trpc/hoa';
import { UseToggle } from '@/shared/hooks/UseToggle';

import usePerformanceFormikConfig, {
  IndividualDivision,
  PerformanceFormFields,
} from './Forms/usePerformanceFormikConfig';
import { trpc } from '@/config/trpc';

import AddEditPerformanceFormBody from './Forms/AddEditPerformanceFormBody';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props<T> = {
  initialPerformance?: Performance | null;
  onClose?: () => void;
  onSuccess?: () => void;
  toggle: UseToggle<T>;
};

const messageKeys = {
  error: {
    create: 'failedToCreateType',
    update: 'failedToUpdateType',
  },
  success: {
    create: 'typeSuccessfullyAdded',
    update: 'typeSuccessfullyUpdated',
  },
} as const;

type SuccessKeys = keyof typeof messageKeys.success;

const AddEditPerformanceModal = <T,>({
  initialPerformance,
  onClose,
  onSuccess,
  toggle,
}: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const formikProps = usePerformanceFormikConfig(initialPerformance);

  const { mutateAsync: createPerformanceAsync, isLoading: isCreatingPerformance } =
    trpc.hoa.performance.create.useMutation();
  const { mutateAsync: updatePerformanceAsync, isLoading: isUpdatingPerformance } =
    trpc.hoa.performance.update.useMutation();

  const onSuccessHandler = (key: SuccessKeys) => {
    const messageKey = messageKeys.success[key];

    enqueueSnackbar(t(messageKey, { type: t('performance') }), { variant: 'success' });

    onSuccess?.();
    onClose?.();
    toggle.hide();
  };

  const handleSubmit = async ({
    contractId,
    contractPerformanceId,
    description,
    homeownerAssociationId,
    individualDivisions,
    shares,
    unitPrice,
    status,
    ...restValues
  }: PerformanceFormFields) => {
    if (!contractId || !contractPerformanceId || !homeownerAssociationId) return;

    const mapIndividualDivision = (item: IndividualDivision) => ({
      buildingRelationId: item.buildingRelationId || null,
      share: item.share,
      unitId: item.unitRelationShare.unitId,
    });

    const mappedShares = individualDivisions?.map(mapIndividualDivision) || [];

    const request = {
      homeownerAssociationId,
      request: {
        contractId,
        contractPerformanceId,
        description: description || '',
        shares: shares || mappedShares,
        status: status || BilledStatus.NotBilled,
        unitPrice: unitPrice || 0,
        ...restValues,
      },
    };

    let key: SuccessKeys = 'create';

    try {
      if (initialPerformance) {
        key = 'update';

        await updatePerformanceAsync({
          ...request,
          id: initialPerformance.id,
        });

        onSuccessHandler(key);
      } else {
        await createPerformanceAsync(request);

        onSuccessHandler(key);
      }
    } catch (error) {
      const messageKey = messageKeys.error[key];

      enqueueSnackbar(t(messageKey, { type: t('performance') }), { variant: 'error' });
    }
  };

  return (
    <KpcModal
      className="w-4/5"
      title={t('chargeAdditionalPerformance')}
      toggle={toggle}
      onClose={onClose}
    >
      <Formik onSubmit={handleSubmit} {...formikProps} enableReinitialize>
        <Form>
          <AddEditPerformanceFormBody
            isEdit={!!initialPerformance}
            isMutating={isCreatingPerformance || isUpdatingPerformance}
            toggle={toggle}
          />
        </Form>
      </Formik>
    </KpcModal>
  );
};

export default AddEditPerformanceModal;
