import actionTypes from '../../redux/actions/actionTypes';
import { getAccessToken } from '../utils/authUtils';
import { pagination } from '~/common/constants';
import { store } from '../../redux';

const setIsBusy = (isBusy = true) => {
  if (isBusy) {
    store.dispatch({
      type: actionTypes.SET_IS_BUSY,
    });
  } else {
    store.dispatch({
      type: actionTypes.SET_IS_NOT_BUSY,
    });
  }
};

const methods = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
};

const getAuthorizationHeader = () => `Bearer ${getAccessToken()}`;

const getBasicHeaders = (teamIdOverride) => ({
  Authorization: getAuthorizationHeader(),
  Team: teamIdOverride ?? localStorage.getItem('teamId'),
});

const getPostHeaders = (isFormData) => {
  const headers = getBasicHeaders();

  if (isFormData) {
    return headers;
  }
  return {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  };
};

class ApiService {
  constructor(baseUri) {
    this.api = baseUri;
    this.fetchPublicDataAborter = null;
  }

  /* Teams */

  fetchGetTeams = async () => this.#defaultGetData('team/list.all');

  fetchGetTeamUsers = async () => this.#defaultGetData('team/users');

  fetchGetCurrentTeamUser = () => this.#defaultGetData('team/current-user');

  fetchGetCurrentTeam = async () => this.#defaultGetData('team/current-team');

  putUpdateTeam = async (team) => this.#defaultSendData(methods.PUT, 'team', team);

  putUpdateTeamSubscription = async (subscription) =>
    this.#defaultSendData(methods.PUT, 'team/subscription', subscription);

  automaticMessageTemplatesWithMailBoxIdExist = async (mailBoxId) =>
    this.#defaultGetData(`team/automatic-message-templates/mailbox?mailBoxId=${mailBoxId}`);

  putAutomaticMessageTemplatesMailBox = async (data) =>
    this.#defaultSendData(methods.PUT, 'team/automatic-message-templates/mailbox', data);

  putUpdateTeamWhitelabel = async (data) =>
    this.#defaultSendData(methods.PUT, 'team/whitelabel', data, true);

  deleteRemoveTeamWhitelabel = async () => this.#defaultSendData(methods.DELETE, 'team/whitelabel');

  /* Team Integrations */

  putUpdateIntegratorsWithAccess = async (data) =>
    this.#defaultSendData(methods.PUT, 'team/integrators', data);

  fetchGetTeamIntegrations = async () => this.#defaultGetData('integration');

  postAddTeamIntegrations = async (data) =>
    this.#defaultSendData(methods.POST, 'integration', data);

  putUpdateIntegrationMetadata = async (data) =>
    this.#defaultSendData(methods.PUT, 'integration/metadata', data);

  deleteTeamIntegration = async (integrationId) =>
    this.#defaultSendData(methods.DELETE, 'integration', { integrationId });

  /* Users */

  postSyncUser = async () => this.#defaultSendData(methods.POST, 'user/sync');

  fetchGetUserTeamSettingsForTeam = async () => this.#defaultGetData('user/settings');

  fetchGetUserTeamInvitations = async () => this.#defaultGetData('user/invitations');

  fetchGetUserTeamSettings = async () => this.#defaultGetData('user/settings.list');

  fetchGetUserTeamSettingsUnsafe = async () => this.#defaultGetDataUnsafe('user/settings.list');

  putUpdateUserTeamSettings = async (settings) =>
    this.#defaultSendData(methods.PUT, 'user/settings', { ...settings });

  postAddUserSettingsForTeam = async (teamId) =>
    this.#defaultSendData(methods.POST, `user/settings/${teamId}`, null);

  deleteUserSettings = async (settingsId) =>
    this.#defaultSendData(methods.DELETE, `user/settings/${settingsId}`);

  /* Contacts */

  fetchGetContactTags = async () => this.#defaultGetData('contact/tags');

  fetchGetSingleContactTag = async (tagId) => this.#defaultGetData(`contact/tag/${tagId}`);

  putUnlinkContactTagFromContact = async (data) =>
    this.#defaultSendData(methods.PUT, 'contact/tag.unlink', data);

  putLinkContactTagToContact = async (data) =>
    this.#defaultSendData(methods.PUT, 'contact/tag.link', data);

  postAddTagToContact = async (contactTag) =>
    this.#defaultSendData(methods.POST, 'contact/tag', contactTag);

  postAddContact = async (contact) => this.#defaultSendData(methods.POST, 'contact', contact);

  putUpdateContact = async (contact) =>
    this.#defaultSendData(methods.PUT, 'contact', { ...contact, contactId: contact.id });

  fetchGetContactFiles = async (contactId) => this.#defaultGetData(`contact/${contactId}/file`);

  postAddFilesToContact = async (data) =>
    this.#defaultSendData(methods.POST, 'contact/file', data, true);

  deleteFilesFromContact = async (contactId, fileIds) =>
    this.#defaultSendData(methods.DELETE, `contact/${contactId}/file`, fileIds);

  /* Companies */

  fetchGetCompanyTags = async () => this.#defaultGetData('company/tags');

  fetchGetSingleCompanyTag = async (tagId) => this.#defaultGetData(`company/tag/${tagId}`);

  fetchGetCompaniesWithTeams = async () => this.#defaultGetData('company/teams');

  postAddTagToCompany = async (companyTag) =>
    this.#defaultSendData(methods.POST, 'company/tag', companyTag);

  putUnlinkCompanyTagFromCompany = async (data) =>
    this.#defaultSendData(methods.PUT, 'company/tag.unlink', data);

  putLinkCompanyTagToCompany = async (data) =>
    this.#defaultSendData(methods.PUT, 'company/tag.link', data);

  postAddCompany = async (company) => this.#defaultSendData(methods.POST, 'company', company);

  fetchGetContractorRemarksForCase = async (contractorId, caseId) =>
    this.#defaultGetData(`contractor/remarks?contractorId=${contractorId}&caseId=${caseId}`);

  putUpdateContractorRemarksForCase = async (data) =>
    this.#defaultSendData(methods.PUT, 'contractor/remarks', data);

  /* Contractors */

  putUpdateContractorsCapacity = async (data) =>
    this.#defaultSendData(methods.PUT, 'contractor/capacity', data);

  /* Contracts */

  postCreateContract = async (data) => this.#defaultSendData(methods.POST, 'contract', data, true);

  getContract = async (id) => this.#defaultGetData(`contract/${id}`);

  deleteRemoveWhitelabelFromContract = async (id) =>
    this.#defaultSendData(methods.DELETE, `contract/${id}/whiteLabel`);

  putUpdateContractWhitelabel = async (data) =>
    this.#defaultSendData(methods.PUT, 'contract/whitelabel', data, true);

  putActivateContract = async (contractId) =>
    this.#defaultSendData(methods.PUT, `contract/activate?contractId=${contractId}`);

  putDeactivateContract = async (contractId) =>
    this.#defaultSendData(methods.PUT, `contract/deactivate?contractId=${contractId}`);

  putUpdateContractDescription = async (data) =>
    this.#defaultSendData(methods.PUT, 'contract/description', data);

  putUpdateContractFeeDescription = async (data) =>
    this.#defaultSendData(methods.PUT, 'contract/fee-description', data);

  putUpdateContractFeeSettings = async (data) =>
    this.#defaultSendData(methods.PUT, 'contract/fee-settings', data);

  putUpdateContractFeeTiers = async (data) =>
    this.#defaultSendData(methods.PUT, 'contract/fee-tiers', data);

  /* Central Companies */

  postAddCentralCompany = async (centralCompany) =>
    this.#defaultSendData(methods.POST, 'centralcompany', centralCompany);

  /* Mailboxes */

  fetchGetAvailableMailboxes = async () => this.#defaultGetData('mailbox/accounts');

  fetchGetMessagesForEmailAccount = async (
    emailAccountLinkId,
    folderId,
    orderAsc,
    offset,
    limit = pagination.queryParam.PAGE_SIZE,
  ) => {
    if (folderId) {
      return this.#defaultGetPagedDataUnsafe(
        `mailbox/messages?emailAccountLinkId=${emailAccountLinkId}&folderId=${folderId}`,
        orderAsc,
        offset,
        limit,
      );
    }
    return this.#defaultGetPagedDataUnsafe(
      `mailbox/messages?emailAccountLinkId=${emailAccountLinkId}`,
      orderAsc,
      offset,
    );
  };

  fetchGetMessagesSearchResult = async (emailAccountLinkId, searchQuery, offset) =>
    this.#defaultGetPagedDataUnsafe(
      `mailbox/messages/search-subject?emailAccountLinkId=${emailAccountLinkId}&subject=${encodeURIComponent(
        searchQuery,
      )}`,
      false,
      offset,
    );

  fetchGetFoldersForEmailAccount = async (emailAccountLinkId, offset) =>
    this.#defaultGetPagedDataUnsafe(
      `mailbox/folders?emailAccountLinkId=${emailAccountLinkId}`,
      false,
      offset,
    );

  /* Sms */

  fetchGetSmsBalance = async () => this.#defaultGetData('sms/balance');

  postSendSms = async (smsData) => this.#defaultSendData(methods.POST, 'sms', smsData);

  /* GlobalSettings */

  fetchGlobalSettingByType = async (type) => this.#defaultGetData(`globalsetting?type=${type}`);

  postCreateOrUpdateGlobalSetting = async (data) =>
    this.#defaultSendData(methods.POST, 'globalsetting', data);

  /* Buildings */

  fetchGetBuildingTags = async () => this.#defaultGetData('building/tags');

  fetchGetSingleBuildingTag = async (tagId) => this.#defaultGetData(`building/tag/${tagId}`);

  postAddUnitToBuilding = async (unit) =>
    this.#defaultSendData(methods.POST, 'building/unit', unit);

  deleteRemoveBuildingUnit = async (data) =>
    this.#defaultSendData(methods.DELETE, 'building/unit', data);

  postAddTagToBuilding = async (buildingTag) =>
    this.#defaultSendData(methods.POST, 'building/tag', buildingTag);

  putUnlinkBuildingTagFromBuilding = async (data) =>
    this.#defaultSendData(methods.PUT, 'building/tag.unlink', data);

  putLinkBuildingTagToBuilding = async (data) =>
    this.#defaultSendData(methods.PUT, 'building/tag.link', data);

  /* Closed periods */
  fetchGetClosedPeriods = async () => this.#defaultGetData('team/closedPeriod.list');

  postAddClosedPeriod = async (closedPeriod) =>
    this.#defaultSendData(methods.POST, 'team/closedPeriod', closedPeriod);

  deleteRemoveClosedPeriod = async (closedPeriodId) =>
    this.#defaultSendData(methods.DELETE, `team/closedPeriod/${closedPeriodId}`);

  /* Case */
  postAddFilesToNewInsuranceClaim = async (data) =>
    this.#defaultSendData(methods.POST, 'insuranceClaim/file', data, true);

  putUpdateCase = async (data) => this.#defaultSendData(methods.PUT, 'case', data);

  putUpdateCaseAddress = async (data) => this.#defaultSendData(methods.PUT, 'case/address', data);

  putUpdateCasePolicyData = async (data) =>
    this.#defaultSendData(methods.PUT, 'case/policyData', data);

  putAcceptCase = async (data) => this.#defaultSendData(methods.PUT, 'case/accept', data);

  putRejectCase = async (data) => this.#defaultSendData(methods.PUT, 'case/reject', data);

  putCancelCase = async (data) => this.#defaultSendData(methods.PUT, 'case/cancel', data);

  postAddRelationToCase = async (data) =>
    this.#defaultSendData(methods.POST, 'case/relation', data);

  postSimpleCaseRelation = async (data) =>
    this.#defaultSendData(methods.POST, 'case/simple-relation', data);

  putUpdateCaseRelation = async (data) => this.#defaultSendData(methods.PUT, 'case/relation', data);

  putUpdateCaseRelationDetails = async (data) =>
    this.#defaultSendData(methods.PUT, 'case/relation/details', data);

  deleteRemoveCaseRelation = async (caseId, relationId) =>
    this.#defaultSendData(methods.DELETE, 'case/relation', { caseId, relationId });

  deleteRemoveCaseRelationCrmLinkForTeam = async (caseId, relationId) =>
    this.#defaultSendData(methods.DELETE, `case/${caseId}/relation/${relationId}/crm-link`);

  postAddCaseRelationCrmLinkForTeam = async (caseId, relationId, data) =>
    this.#defaultSendData(methods.POST, `case/${caseId}/relation/${relationId}/crm-link`, data);

  postRequestDocumentFromCaseRelation = async (data) =>
    this.#defaultSendData(methods.POST, 'case/relation/request-task', data);

  fetchGetTeamUsersWithAccessToCase = async (caseId) =>
    this.#defaultGetData(`case/${caseId}/team.users`);

  postAddFileToCase = async (data) => this.#defaultSendData(methods.POST, 'case/file', data, true);

  deleteFileFromCase = async (caseId, fileIds) =>
    this.#defaultSendData(methods.DELETE, `case/${caseId}/files`, fileIds);

  fetchGetCaseFiles = async (caseId) => this.#defaultGetData(`case/${caseId}/file.list`);

  deleteRemoveCaseEntity = async (caseId, entityId) =>
    this.#defaultSendData(methods.DELETE, `case/${caseId}/entity/${entityId}`);

  putUpdatePhoneCall = async (data) => this.#defaultSendData(methods.PUT, 'case/phone-call', data);

  putUpdateCaseNote = async (data) => this.#defaultSendData(methods.PUT, 'case/note', data);

  deleteCaseAppointment = async (data) =>
    this.#defaultSendData(methods.DELETE, `case/appointment`, data);

  getCaseEntityAccess = async (caseId, entityId) =>
    this.#defaultGetData(`case/${caseId}/entity/${entityId}/access`);

  putUpdateCaseEntityAccess = async (data) =>
    this.#defaultSendData(methods.PUT, 'case/entity/access', data);

  fetchGetCasePhoneNumbers = async (caseId) => this.#defaultGetData(`case/phoneNumbers/${caseId}`);

  postAddCasePayment = async (data) => this.#defaultSendData(methods.POST, 'case/payment', data);

  putUpdateCasePayment = async (data) => this.#defaultSendData(methods.PUT, 'case/payment', data);

  deleteRemovePaymentFromCase = async (data) =>
    this.#defaultSendData(methods.DELETE, 'case/payment', data);

  putUpdateCasePaymentRemark = async (data) =>
    this.#defaultSendData(methods.PUT, 'case/payment/remark', data);

  /* Case Templates */
  postAddCaseTemplate = async (data) => this.#defaultSendData(methods.POST, 'casetemplate', data);

  putUpdateCaseTemplate = async (data) => this.#defaultSendData(methods.PUT, 'casetemplate', data);

  deleteCaseTemplate = async (data) => this.#defaultSendData(methods.DELETE, 'casetemplate', data);

  fetchGetCaseTemplateFiles = async (caseTemplateId) =>
    this.#defaultGetData(`casetemplate/${caseTemplateId}/file.list`);

  postAddFilesToCaseTemplate = async (data) =>
    this.#defaultSendData(methods.POST, 'casetemplate/file', data, true);

  deleteFileFromCaseTemplate = async (caseTemplateId, fileId) =>
    this.#defaultSendData(methods.DELETE, `casetemplate/${caseTemplateId}/file`, fileId);

  /* Files Common */
  putUpdateFileAccessibility = async (fileId, data) =>
    this.#defaultSendData(methods.PUT, `file/${fileId}/accessibility`, data);

  putUpdateFileSeenByTeam = async (fileId, seen) =>
    this.#defaultSendData(methods.PUT, `file/${fileId}/seen`, seen);

  /* Repair Case */

  putIntakeCall = async (data) => this.#defaultSendData(methods.PUT, 'repair/intake-call', data);

  putUpdateFirstAppointment = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/first-appointment', data);

  putUpdateCaseRemarksForCase = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/case-remarks', data);

  putUpdateRepairStart = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/repair-start', data);

  putUpdateWorkSiteRegistrationNumber = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/worksite-registration-number', data);

  putUpdateRequireEstimatePhotos = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/require-estimate-photos', data);

  putUpdateRequireInvoicePhotos = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/require-invoice-photos', data);

  putUpdateRepairEnd = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/repair-end', data);

  putUpdateMaterialsOrderDeliveryDateInfo = async (data) =>
    this.#defaultSendData(methods.PUT, 'repair/material-order', data);

  fetchGetRepairCaseFinanceSummary = async (caseId, documentId) =>
    this.#defaultGetData(
      `repair/finance?caseId=${caseId}${documentId == null ? '' : `&documentId=${documentId}`}`,
    );

  /* Case classifications */

  fetchGetTeamCaseClassifications = async (caseType) =>
    this.#defaultGetData(`caseclassification/list.all${caseType ? `?caseType=${caseType}` : ''}`);

  deleteRemoveCaseClassification = async (caseClassificationId) =>
    this.#defaultSendData(methods.DELETE, `caseclassification/${caseClassificationId}`);

  postAddCaseClassification = async (caseClassification) =>
    this.#defaultSendData(methods.POST, 'caseclassification', caseClassification);

  putUpdateCaseClassification = async (caseClassification) =>
    this.#defaultSendData(methods.PUT, 'caseclassification', { ...caseClassification });

  /* Case status */

  getCaseStatuses = async () => this.#defaultGetData('casestatus/list.all');

  /* Blog */

  postCreateBlog = async (blog) => this.#defaultSendData(methods.POST, 'blog', { ...blog });

  putUpdateBlog = async (blog) => this.#defaultSendData(methods.PUT, 'blog', { ...blog });

  deleteRemoveBlog = async (id) => this.#defaultSendData(methods.DELETE, `blog/${id}`);

  /* Finance */

  fetchGetSpreadsheet = async (spreadsheetId) => this.#defaultGetData(`finance/${spreadsheetId}`);

  postCreateSpreadsheet = (data) => this.#defaultSendData(methods.POST, 'finance', data, true);

  fetchGetSpreadsheetsByCase = async (caseId) =>
    this.#defaultGetPagedData(`finance/list?caseId=${caseId}`);

  fetchGetSpreadsheetFiles = async (spreadsheetId) =>
    this.#defaultGetData(`finance/${spreadsheetId}/file.list`);

  postAddFilesToSpreadsheet = async (data) =>
    this.#defaultSendData(methods.POST, 'finance/file', data, true);

  deleteFileFromSpreadsheet = async (spreadsheetId, fileIds) =>
    this.#defaultSendData(methods.DELETE, `finance/${spreadsheetId}/files`, fileIds);

  /* Policies Enum values */

  fetchDomainEnumValues = async () => this.#defaultGetData('policy/insuranceDomain');

  fetchPolicyTypeEnumValues = async (domainId) =>
    this.#defaultGetData(
      domainId ? `policy/insurancePolicyType/${domainId}` : 'policy/insurancePolicyType',
    );

  fetchRiskObjectEnumValues = async (domainId) =>
    this.#defaultGetData(`policy/insuranceRiskObject/${domainId}`);

  fetchInsuranceGuaranteeEnumValues = async (domainId) =>
    this.#defaultGetData(`policy/insuranceGuarantee/${domainId}`);

  /* Policies */

  postAddPolicy = async (policy) => this.#defaultSendData(methods.POST, 'policy', { ...policy });

  fetchPolicyById = async (id) => this.#defaultGetData(`policy/${id}`);

  fetchPolicyOverview = async () => this.#defaultGetData('policy/list');

  fetchPolicyRelationById = async (policyId, relationId) =>
    this.#defaultGetData(`policy/${policyId}/relation/${relationId}`);

  deleteRemovePolicyRelationCrmLinkForTeam = async (policyId, relationId) =>
    this.#defaultSendData(methods.DELETE, `policy/${policyId}/relation/${relationId}/crm-link`);

  postAddPolicyRelationCrmLinkForTeam = async (policyId, relationId, data) =>
    this.#defaultSendData(methods.POST, `policy/${policyId}/relation/${relationId}/crm-link`, data);

  fetchGetPolicyFiles = async (policyId) => this.#defaultGetData(`policy/${policyId}/file.list`);

  deleteFileFromPolicy = async (policyId, fileIds) =>
    this.#defaultSendData(methods.DELETE, `policy/${policyId}/files`, fileIds);

  postAddFilesToPolicy = async (data) =>
    this.#defaultSendData(methods.POST, 'policy/file', data, true);

  fetchGetRiskObjects = async (policyId) =>
    this.#defaultGetData(`policy/${policyId}/risk-object.list`);

  postAddRiskObject = async (data) =>
    this.#defaultSendData(methods.POST, 'policy/risk-object', data);

  putEditRiskObject = async (data) =>
    this.#defaultSendData(methods.PUT, 'policy/risk-object', data);

  deleteRemoveRiskObject = async (policyId, riskObjectId) =>
    this.#defaultSendData(methods.DELETE, `policy/${policyId}/risk-object/${riskObjectId}`);

  postAddRiskObjectGuarantee = async (data) =>
    this.#defaultSendData(methods.POST, 'policy/risk-object/guarantee', data);

  putAddRiskObjectGuarantee = async (data) =>
    this.#defaultSendData(methods.PUT, 'policy/risk-object/guarantee', data);

  deleteRemoveRiskObjectGuarantee = async (policyId, riskObjectId, guaranteeId) =>
    this.#defaultSendData(
      methods.DELETE,
      `policy/${policyId}/risk-object/${riskObjectId}/guarantee/${guaranteeId}`,
    );

  putEditRiskObjectExcess = async (data) =>
    this.#defaultSendData(methods.PUT, 'policy/risk-object/excess', data);

  postAddPolicyRiskObjectBuildingLink = async (policyId, data) =>
    this.#defaultSendData(methods.POST, `policy/${policyId}/risk-object/building-link`, data);

  deleteRemovePolicyRiskObjectBuildingLink = async (policyId, riskObjectId) =>
    this.#defaultSendData(
      methods.DELETE,
      `policy/${policyId}/risk-object/${riskObjectId}/building-link`,
    );

  getPoliciesLinkedToAddressOrBuilding = async (address) =>
    this.#defaultGetData(`policy/linked-policies?${new URLSearchParams(address).toString()}`);

  removeDeleteRiskObjectExcess = async (policyId, riskObjectId, guaranteeId) =>
    this.#defaultSendData(
      methods.DELETE,
      `policy/${policyId}/risk-object/${riskObjectId}/guarantee/${guaranteeId}/excess`,
    );

  putUpdatePolicyStatus = async (data) => this.#defaultSendData(methods.PUT, 'policy/status', data);

  /* Fsma numbers */
  fetchGetFsmaNumberList = async () => this.#defaultGetData('fsmanumber/list');

  fetchGetFsmaNumberById = async (fsmaNbrId) => this.#defaultGetData(`fsmanumber/${fsmaNbrId}`);

  postAddFsmaNumber = async (fsmaNumber) =>
    this.#defaultSendData(methods.POST, 'fsmanumber', fsmaNumber);

  putUpdateFsmaNumber = async (fsmaNumber) =>
    this.#defaultSendData(methods.PUT, 'fsmanumber', fsmaNumber);

  deleteRemoveFsmaNumber = async (fsmaNbrId) =>
    this.#defaultSendData(methods.DELETE, `fsmanumber/${fsmaNbrId}`);

  /* CompanyWeb */
  validateCompanyWebCredentials = async (username, password) =>
    this.#defaultSendData(methods.POST, 'companyweb/validate', { password, username });

  /* Lucene */

  fetchSearchPostalCodeCity = async (country, term) =>
    this.#defaultGetData(`lucene/${country}/cityindex.search?term=${term}`);

  /* Private Functions / Helpers */

  #defaultSendData = async (method, endpoint, body, isFormData) => {
    try {
      setIsBusy(true);
      if (method !== methods.POST && method !== methods.PUT && method !== methods.DELETE) {
        throw new Error('Unsupported method');
      }

      const response = await fetch(`${this.api}/${endpoint}`, {
        body: isFormData ? body : JSON.stringify(body),
        headers: getPostHeaders(isFormData),
        method,
      });
      setIsBusy(false);

      const contentType = response.headers.get('content-type');
      if (
        contentType &&
        (contentType.includes('application/json') ||
          contentType.includes('application/problem+json'))
      ) {
        const data = await response.json();
        return {
          data,
          status: response.status,
        };
      }

      return {
        status: response.status,
      };
    } catch (error) {
      setIsBusy(false);

      return {
        serviceError: error,
      };
    }
  };

  #defaultGetData = async (endpoint, signal) => {
    try {
      setIsBusy(true);
      const response = await fetch(`${this.api}/${endpoint}`, {
        headers: getBasicHeaders(),
        method: methods.GET,
        signal,
      });
      setIsBusy(false);

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        return {
          data,
          status: response.status,
        };
      }

      return {
        status: response.status,
      };
    } catch (error) {
      setIsBusy(false);

      return {
        serviceError: error,
      };
    }
  };

  #defaultGetTextData = async (endpoint, signal) => {
    try {
      setIsBusy(true);
      const response = await fetch(`${this.api}/${endpoint}`, {
        headers: getBasicHeaders(),
        method: methods.GET,
        signal,
      });
      setIsBusy(false);

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/plain')) {
        const data = await response.text();

        return {
          data,
          status: response.status,
        };
      }

      return {
        status: response.status,
      };
    } catch (error) {
      setIsBusy(false);

      return {
        serviceError: error,
      };
    }
  };

  #defaultGetDataUnsafe = async (endpoint, signal) => {
    try {
      setIsBusy(true);
      const response = await fetch(`${this.api}/${endpoint}`, {
        headers: getBasicHeaders(),
        method: methods.GET,
        signal,
      });
      setIsBusy(false);

      if (!response.ok) {
        throw new Error(`Error ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        return {
          data,
          status: response.status,
        };
      }

      return {
        status: response.status,
      };
    } catch (error) {
      setIsBusy(false);

      throw error;
    }
  };

  #defaultGetPagedDataUnsafe = async (
    endpoint,
    orderAsc,
    offset = 0,
    limit = pagination.queryParam.PAGE_SIZE,
  ) => {
    setIsBusy(true);
    const response = await this.#defaultGetDataUnsafe(
      `${endpoint}${
        endpoint.includes('?') ? '&' : '?'
      }orderAsc=${orderAsc}&offset=${offset}&limit=${limit}`,
    );
    setIsBusy(false);

    return {
      ...response,
      getNext:
        response.data.length === pagination.queryParam.PAGE_SIZE
          ? () => this.#defaultGetDataUnsafe(endpoint, offset + pagination.queryParam.PAGE_SIZE)
          : null,
    };
  };

  #defaultGetPagedData = async (endpoint, offset = 0, limit = pagination.queryParam.PAGE_SIZE) => {
    try {
      setIsBusy(true);
      const response = await this.#defaultGetData(
        `${endpoint}${endpoint.includes('?') ? '&' : '?'}offset=${offset}&limit=${limit}`,
      );
      setIsBusy(false);

      return {
        ...response,
        getNext:
          response.data.length === pagination.queryParam.PAGE_SIZE
            ? () => this.#defaultGetPagedData(endpoint, offset + pagination.queryParam.PAGE_SIZE)
            : null,
      };
    } catch (error) {
      return { serviceError: error };
    }
  };
}

const { VITE_KEYPOINT_API_URI } = import.meta.env;

const API = new ApiService(VITE_KEYPOINT_API_URI);

export default API;
