import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '../../../utils/helpers';

const propTypes = {
  callback: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const CaseStatusFilter = ({ callback = () => {}, name = '', defaultValue = [], options }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Autocomplete
        noOptionsText={t('common:noOptions')}
        multiple
        options={options}
        getOptionLabel={(opt) => t(`common:${decapitalize(opt)}`)}
        fullWidth
        value={defaultValue}
        onChange={(e, values) => callback(name, values)}
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t('common:status')} />
        )}
      />
    </div>
  );
};

CaseStatusFilter.propTypes = propTypes;

export default CaseStatusFilter;
