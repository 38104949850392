import { MobileDateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

import { ClearIcon } from '../icons/Icons';
import { getMomentUtcObject } from '../utils/helpers';
import IconButton from '../components/buttons/IconButton';

const FormikDateTimePicker = (props) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const { name, required, ...rest } = props;

  const [currentValue, setCurrentValue] = React.useState(values[name]);

  React.useEffect(() => {
    setCurrentValue(values[name]);
  }, [values, name]);

  return (
    <MobileDateTimePicker
      {...rest}
      ampm={false}
      onChange={(val) => {
        if (val) {
          setCurrentValue(val.utc().format('YYYY-MM-DDTHH:mm:ss'));
        }
      }}
      onAccept={(val) => setFieldValue(name, val.utc().format('YYYY-MM-DDTHH:mm:ss'))}
      onClose={() => setCurrentValue(values[name])}
      value={getMomentUtcObject(currentValue).utc(true)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          required={required}
          error={!!errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          fullWidth
          onBlur={() => setFieldTouched(name, true)}
          InputProps={
            values[name] && {
              endAdornment: (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setFieldValue(name, null);
                  }}
                  iconComponent={<ClearIcon />}
                />
              ),
            }
          }
        />
      )}
    />
  );
};

FormikDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormikDateTimePicker.defaultProps = {
  required: false,
};

export default FormikDateTimePicker;
