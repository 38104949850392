import PropTypes from 'prop-types';

const DocumentPropTypes = PropTypes.shape({
  buildings: PropTypes.arrayOf(PropTypes.string),
  cases: PropTypes.arrayOf(PropTypes.string),
  categoryDescription: PropTypes.string,
  contentType: PropTypes.string,
  extension: PropTypes.string,
  fileName: PropTypes.string,
  fileUri: PropTypes.string,
  id: PropTypes.string,
  isPublic: PropTypes.bool,
  ownerTeamId: PropTypes.string,
  policies: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  seen: PropTypes.bool,
  spreadsheets: PropTypes.arrayOf(PropTypes.string),
  tasks: PropTypes.arrayOf(PropTypes.string),
  teamsWithAccess: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  uploadDate: PropTypes.string,
  uploadedBy: PropTypes.string,
});

export default DocumentPropTypes;
