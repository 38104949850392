enum NlBicValue {
  ABNANL2A = 'ABNANL2A',
  ABNCNL2A = 'ABNCNL2A',
  ADYBNL2A = 'ADYBNL2A',
  AEGONL2U = 'AEGONL2U',
  AINHNL22 = 'AINHNL22',
  ANDLNL2A = 'ANDLNL2A',
  ARBNNL22 = 'ARBNNL22',
  ARSNNL21 = 'ARSNNL21',
  ASNBNL21 = 'ASNBNL21',
  BARCNL22 = 'BARCNL22',
  BCDMNL22 = 'BCDMNL22',
  BCITNL2A = 'BCITNL2A',
  BICKNL2A = 'BICKNL2A',
  BINKNL21 = 'BINKNL21',
  BITSNL2A = 'BITSNL2A',
  BKCHNL2R = 'BKCHNL2R',
  BKMGNL2A = 'BKMGNL2A',
  BLGWNL21 = 'BLGWNL21',
  BNDANL2A = 'BNDANL2A',
  BNGHNL2G = 'BNGHNL2G',
  BNPANL2A = 'BNPANL2A',
  BOFANLNX = 'BOFANLNX',
  BOFSNL21 = 'BOFSNL21',
  BOTKNL2X = 'BOTKNL2X',
  BUNQNL2A = 'BUNQNL2A',
  CCBVNL2A = 'CCBVNL2A',
  CEPANL2A = 'CEPANL2A',
  CHASNL2X = 'CHASNL2X',
  CITCNL2A = 'CITCNL2A',
  CITINL2X = 'CITINL2X',
  COBANL2X = 'COBANL2X',
  DELENL22 = 'DELENL22',
  DEUTNL2A = 'DEUTNL2A',
  DHBNNL2R = 'DHBNNL2R',
  DNIBNL2G = 'DNIBNL2G',
  EBPBNL22 = 'EBPBNL22',
  EBURNL21 = 'EBURNL21',
  FBHLNL2A = 'FBHLNL2A',
  FLORNL2A = 'FLORNL2A',
  FNOMNL22 = 'FNOMNL22',
  FRNXNL2A = 'FRNXNL2A',
  FROMNL2A = 'FROMNL2A',
  FVLBNL22 = 'FVLBNL22',
  FXBBNL22 = 'FXBBNL22',
  GILLNL2A = 'GILLNL2A',
  HANDNL2A = 'HANDNL2A',
  HIFXNL2A = 'HIFXNL2A',
  HSBCNL2A = 'HSBCNL2A',
  HUSHNL2A = 'HUSHNL2A',
  ICBCNL2A = 'ICBCNL2A',
  ICBKNL2A = 'ICBKNL2A',
  ICEPNL21 = 'ICEPNL21',
  INGBNL2A = 'INGBNL2A',
  ISAENL2A = 'ISAENL2A',
  ISBKNL2A = 'ISBKNL2A',
  KABANL2A = 'KABANL2A',
  KNABNL2H = 'KNABNL2H',
  KOEXNL2A = 'KOEXNL2A',
  KREDNL2X = 'KREDNL2X',
  LOYDNL2A = 'LOYDNL2A',
  LPLNNL2F = 'LPLNNL2F',
  MHCBNL2A = 'MHCBNL2A',
  MODRNL22 = 'MODRNL22',
  NNBANL2G = 'NNBANL2G',
  NWABNL2G = 'NWABNL2G',
  PANXNL22 = 'PANXNL22',
  PCBCNL2A = 'PCBCNL2A',
  PNOWNL2A = 'PNOWNL2A',
  RABONL2U = 'RABONL2U',
  RBRBNL21 = 'RBRBNL21',
  REVONL22 = 'REVONL22',
  SBOSNL2A = 'SBOSNL2A',
  SNSBNL2A = 'SNSBNL2A',
  SOGENL2A = 'SOGENL2A',
  SWNBNL22 = 'SWNBNL22',
  TRIONL2U = 'TRIONL2U',
  UGBINL2A = 'UGBINL2A',
  VOWANL21 = 'VOWANL21',
  VPAYNL22 = 'VPAYNL22',
  VTPSNL2R = 'VTPSNL2R',
  YOURNL2A = 'YOURNL2A',
  ZWLBNL21 = 'ZWLBNL21',
}

export default NlBicValue;
