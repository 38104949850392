import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '../../shared/utils/helpers';
import { policyApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetPolicies = (options = { addCalculatedColumns: false }) => {
  const { sendDefaultError } = useNotification();

  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    enabled: options.enabled ?? true,
    onError: (error) => {
      sendDefaultError(error);
    },
    onSuccess: (res) => {
      if (options?.callback) {
        options.callback(res);
      }
    },
    queryFn: async () => {
      const result = await policyApi.getPolicies();

      if (!options.addCalculatedColumns) {
        return { result };
      }

      const withCalculatedColumns = result.map((policy) => {
        const policyManager = policy.relations.filter((rel) => rel.isPolicyManager);
        const insurers = policy.relations.filter((rel) => rel.isInsurer);
        const insured = policy.relations.filter((rel) => rel.isPolicyHolder);
        const insuranceIntermediary = policy.relations.filter((rel) => rel.isInsuranceIntermediary);
        const parties = policy.relations.filter(
          (rel) =>
            !rel.isPolicyManager &&
            !rel.isInsurer &&
            !rel.isPolicyHolder &&
            !rel.isInsuranceIntermediary,
        );

        return {
          ...policy,
          insuranceIntermediary,
          insured,
          insurers,
          parties,
          policyManager,
        };
      });

      return { result: withCalculatedColumns };
    },
    queryKey: [queryIds.policies.GET_POLICIES],
  });

  return {
    policies: data?.result || [],
    policiesIsLoading: isLoading,
  };
};

export default useGetPolicies;
