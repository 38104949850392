import { isNullOrEmpty, makeId } from '@/shared/utils/helpers';
import AddressFunction from '@/shared/enums/AddressFunction';
import CaseRelationRole from '@/shared/enums/CaseRelationRole';
import EmailContext from '~/common/enums/emailContext';
import { formatContactDisplayName } from '~/common/utils/utils';

export const tryGetAddressHqOrDefault = (allAddresses) => {
  if (allAddresses == null || allAddresses.length === 0) {
    return {};
  }
  const { address } =
    allAddresses.find((a) => a.function === AddressFunction.HQ) ?? allAddresses[0];
  const { addressLine, city, postalCode, country } = address;
  return {
    relationAddressAddressLine: addressLine,
    relationAddressCity: city,
    relationAddressCountry: country,
    relationAddressPostalCode: postalCode,
  };
};

export const formatData = (relation) => {
  const {
    role,
    subordinate,
    displayName,
    companyName,
    firstName,
    lastName,
    relationId,
    relationAddressAddressLine,
    relationAddressCity,
    relationAddressCountry,
    relationAddressPostalCode,
    ...relationData
  } = relation;
  const identifier = relationId ?? makeId(7);
  return {
    address: {
      relationAddressAddressLine,
      relationAddressCity,
      relationAddressCountry,
      relationAddressPostalCode,
    },
    administrators: [],
    firstName,
    id: identifier,
    identifier,
    lastName,
    name: displayName ?? companyName ?? formatContactDisplayName({ firstName, lastName }),
    parentIdentifier: subordinate,
    subordinate,
    role,
    type: role,
    ...relationData,
  };
};

export const prefillRelations = (currentTeam, currentTeamCompany, policySelector, policies) => {
  const allRelations = [];
  const { companyAddresses, ...currentTeamRelationData } = currentTeamCompany ?? {};

  const currentTeamRelation = formatData({
    displayName: currentTeam.name,
    teamId: currentTeam.id,
    ...currentTeamRelationData,
    emailAddresses: currentTeamRelationData.emailAddresses?.filter((e) => e.context === EmailContext.All)
      .map((e) => e.email),
    ...tryGetAddressHqOrDefault(companyAddresses),
  });

  if (policySelector == null || policySelector.policies == null) {
    return [currentTeamRelation];
  }

  const policyRelations = policies
    .filter((policy) =>
      policySelector.policies.some((selectedPolicy) => selectedPolicy.id === policy.id),
    )
    .map((policy) =>
      policy.relations.map((relation) => {
        const {
          addressLine,
          city,
          country,
          postalCode,
          isInsurer,
          isPolicyHolder,
          isInsuranceIntermediary,
          id,
          ...otherRelationData
        } = relation;
        let role = null;
        if (isInsurer) {
          role = CaseRelationRole.Insurer;
        } else if (isInsuranceIntermediary) {
          role = CaseRelationRole.InsuranceIntermediary;
        }
        return formatData({
          isClient: isPolicyHolder,
          isRequestor: isPolicyHolder,
          policyRelationId: id,
          relationAddressAddressLine: addressLine,
          relationAddressCity: city,
          relationAddressCountry: country,
          relationAddressPostalCode: postalCode,
          role,
          ...otherRelationData,
        });
      }),
    )
    .flat(1);

  if (policyRelations.length > 0) {
    const distinctPolicyRelations = policyRelations.reduce((accumulator, relation) => {
      const { contactId, companyId, teamId } = relation;
      if (
        accumulator.some(
          (otherRelation) =>
            (!isNullOrEmpty(companyId) && otherRelation.companyId === companyId) ||
            (!isNullOrEmpty(teamId) && otherRelation.teamId === teamId) ||
            (!isNullOrEmpty(contactId) && otherRelation.contactId === contactId),
        )
      ) {
        return accumulator;
      }
      accumulator.push(relation);
      return accumulator;
    }, []);

    allRelations.push(...distinctPolicyRelations);
  }

  if (!allRelations.some((rel) => rel.teamId === currentTeamRelation.teamId)) {
    allRelations.push(currentTeamRelation);
  }

  return allRelations;
};
