import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import centralCompanyApi from '../../shared/api/centralCompanies/centralCompany';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetCentralCompanies = (enabled = true) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled,
    onError: (error) => sendDefaultError(error),
    queryFn: centralCompanyApi.getCentralCompanies,
    queryKey: [queryIds.centralCompanies.GET_CENTRAL_COMPANY],
  });

  const centralCompanies = useMemo(() => data ?? [], [data]);

  return {
    centralCompanies,
    centralCompaniesLoading: isLoading,
  };
};

export default useGetCentralCompanies;
