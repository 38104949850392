import config from '@/config/config';
import createRestClient from '../restClient';
import { Document } from '~/common/types/document/document';
import { TemporaryFile } from '@/containers/Cases/Repair/Timeline/Steps/SubmitOfferOrInvoice/UploadFileModal';

const restClient = createRestClient();
const client = createRestClient(config.baseURL);

const putUpdateFile = ({
  fileId,
  data,
}: {
  fileId: string;
  data: { fileName: string; type: string };
}) => restClient.put(`file/${fileId}`, data);

const putUpdateFilesType = (data: { fileIds: string[]; type: string }) =>
  restClient.put('file/type', data);

const downloadFile = (fileId: string) => restClient.get(`file/${fileId}/download`);

const getFileInfo = (fileId: string): Promise<Document> => client.get(`file/${fileId}/info`);

const putBulkUpdateFileAccessibility = (data: {
  caseId?: string;
  fileIds: string[];
  isPublic: boolean;
  teamsWithAccess: string[];
}) => restClient.put('file/accessibility', data);

const getUriForFile: (id: string) => Promise<string> = (id) => client.get(`file/${id}/fileUri`);

const uploadTemporaryFiles = ({
  data,
  onProgress,
}: {
  data: FormData;
  onProgress: (progress: number) => void;
}) =>
  restClient.post<TemporaryFile[]>('/temporaryfiles/multiple', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

export default {
  downloadFile,
  getFileInfo,
  getUriForFile,
  putBulkUpdateFileAccessibility,
  putUpdateFile,
  putUpdateFilesType,
  uploadTemporaryFiles,
};
