import { SendMessageRequest } from '~/common/types/email/emailMessage';

import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';

const restClient = createRestClient();

const sendEmailMessage = (data: SendMessageRequest) =>
  restClient.post(
    'mailbox/message',
    // we must use custom function so attachments have key `attachments` and not `attachments[]` as
    // is produced by axios automatic generating of form data
    objectToFormData(data),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

export default {
  sendEmailMessage,
};
