import { Chip, Divider } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@/shared/components/2.0/autocomplete/AutoComplete';
import FileInput from '@/shared/components/inputs/FileInput';

import { AutocompleteOption } from '~/common/types';
import { DocumentManager } from '../hooks/useManageDocuments';

type Props = {
  active: boolean;
  documentManager: DocumentManager;
};

const DocumentStep: React.FC<Props> = ({ active, documentManager }) => {
  const { VITE_MAX_FILE_SIZE } = import.meta.env;
  const { t } = useTranslation();

  if (!active) {
    return null;
  }

  return (
    <div>
      {documentManager.get.caseFileAutocomplete.length > 0 && (
        <>
          <Autocomplete
            label=""
            multiple
            limitTags={2}
            name="caseFiles"
            onChange={({ value }) => documentManager.set.caseFiles(value as AutocompleteOption[])}
            options={documentManager.get.caseFileAutocomplete}
            placeholder={t('selectCaseDocumentsAsAttachments')}
            value={documentManager.get.caseFiles}
          />
          <Divider>
            <Chip variant="soft" size="sm" className="mb-4 mt-4">
              {t('or')}
            </Chip>
          </Divider>
        </>
      )}
      <FileInput
        showIcon
        maxFileSize={parseInt(VITE_MAX_FILE_SIZE, 10)}
        callback={documentManager.set.uploadedFiles}
        showSelected
      />
    </div>
  );
};

export default DocumentStep;
