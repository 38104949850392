/* eslint-disable sort-keys */
export default {
  /* Teams */
  GET_TEAMS_REQUESTED: 'GET_TEAMS_REQUESTED',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  TEAMS_APPEND: 'TEAMS_APPEND',
  GET_TEAMS_FAILED: 'GET_TEAMS_FAILED',
  GET_CURRENT_TEAM_SUCCESS: 'GET_CURRENT_TEAM_SUCCESS',
  GET_CURRENT_TEAM_FAILED: 'GET_CURRENT_TEAM_FAILED',
  UPDATE_CURRENT_TEAM: 'UPDATE_CURRENT_TEAM',
  UPDATE_CURRENT_TEAM_DATA: 'UPDATE_CURRENT_TEAM_DATA',
  ADD_OR_UPDATE_TEAM: 'ADD_OR_UPDATE_TEAM',
  REMOVE_TEAM: 'REMOVE_TEAM',
  GET_INTEGRATIONS_REQUESTED: 'GET_INTEGRATIONS_REQUESTED',
  GET_INTEGRATIONS_SUCCESS: 'GET_INTEGRATIONS_SUCCESS',
  GET_INTEGRATIONS_FAILED: 'GET_INTEGRATIONS_FAILED',
  GET_CURRENT_CONTRACTOR_SKILLS_DEFINITIONS_SUCCESS:
    'GET_CURRENT_CONTRACTOR_SKILLS_DEFINITIONS_SUCCESS',
  UPDATE_CONTRACTOR_SKILL_DEFINITIONS_REQUESTED: ' UPDATE_CONTRACTOR_SKILL_DEFINITIONS_REQUESTED',
  UPDATE_CONTRACTOR_SKILL_DEFINITIONS_SUCCESS: 'UPDATE_CONTRACTOR_SKILL_DEFINITIONS_SUCCESS',
  /* Users */
  GET_USER_TEAM_INVITATIONS_REQUESTED: 'GET_USER_TEAM_INVITATIONS_REQUESTED',
  GET_USER_TEAM_INVITATIONS_SUCCESS: 'GET_USER_TEAM_INVITATIONS_SUCCESS',
  GET_USER_TEAM_INVITATIONS_FAILED: 'GET_USER_TEAM_INVITATIONS_FAILED',
  GET_USER_TEAM_SETTINGS_REQUESTED: 'GET_USER_TEAM_SETTINGS_REQUESTED',
  GET_USER_TEAM_SETTINGS_SUCCESS: 'GET_USER_TEAM_SETTINGS_SUCCESS',
  GET_USER_TEAM_SETTINGS_FAILED: 'GET_USER_TEAM_SETTINGS_FAILED',
  GET_CURRENT_TEAM_USER_REQUESTED: 'GET_CURRENT_TEAM_USER_REQUESTED',
  GET_CURRENT_TEAM_USER_SUCCESS: 'GET_CURRENT_TEAM_USER_SUCCESS',
  GET_CURRENT_TEAM_USER_FAILED: 'GET_CURRENT_TEAM_USER_FAILED',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  /* Layout Settings */
  SET_IS_BUSY: 'SET_IS_BUSY',
  SET_IS_NOT_BUSY: 'SET_IS_NOT_BUSY',
  /* SignalR */
  INIT_OR_UPDATE_SIGNALR_CONNECTION: 'INIT_OR_UPDATE_SIGNALR_CONNECTION',
  SEND_MESSAGE: 'SEND_MESSAGE',
  REGISTER_HANDLER: 'REGISTER_HANDLER',
  UNREGISTER_HANDLER: 'UNREGISTER_HANDLER',
  /* Tags */
  GET_TAGS_REQUESTED: 'GET_TAGS_REQUESTED',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
  GET_TAGS_FAILED: 'GET_TAGS_FAILED',
  ADD_OR_UPDATE_TAG: 'ADD_OR_UPDATE_TAGS',
  GET_SINGLE_TAG_REQUESTED: 'GET_SINGLE_TAG_REQUESTED',
  REMOVE_TAG: 'REMOVE_TAG',
  /* Emailaccounts */
  GET_EMAILACCOUNTS_REQUESTED: 'GET_EMAILACCOUNTS_REQUESTED',
  GET_EMAILACCOUNTS_SUCCESS: 'GET_EMAILACCOUNTS_SUCCESS',
  GET_EMAILACCOUNTS_FAILED: 'GET_EMAILACCOUNTS_FAILED',
  /* Calendars */
  GET_LINKEDCALENDARS_REQUESTED: 'GET_LINKEDCALENDARS_REQUESTED',
  GET_LINKEDCALENDARS_SUCCESS: 'GET_LINKEDCALENDARS_SUCCESS',
  GET_LINKEDCALENDARS_FAILED: 'GET_LINKEDCALENDARS_FAILED',

  /* Policies */
  GET_POLICY_REQUESTED: 'GET_POLICY_REQUESTED',
  GET_POLICIES_SUCCESS: 'GET_POLICIES_SUCCESS',
  GET_POLICIES_FAILED: 'GET_POLICIES_FAILED',
  ADD_OR_UPDATE_POLICY: 'ADD_OR_UPDATE_POLICY',
  GET_SINGLE_POLICY_REQUESTED: 'GET_SINGLE_POLICY_REQUESTED',
  REMOVE_POLICY: 'REMOVE_POLICY',
  GET_OR_UPDATE_POLICY_RELATION_REQUESTED: 'GET_OR_UPDATE_POLICY_RELATION_REQUESTED',
  GET_OR_UPDATE_POLICY_RELATION_SUCCESS: 'GET_OR_UPDATE_POLICY_RELATION_SUCCESS',
  GET_OR_UPDATE_POLICY_RELATION_FAILED: 'GET_OR_UPDATE_POLICY_RELATION_FAILED',
  REMOVE_POLICY_RELATION: 'REMOVE_POLICY_RELATION',
  /* Fsma numbers */
  GET_FSMANUMBER_REQUESTED: 'GET_FSMANUMBER_REQUESTED',
  GET_FSMANUMBER_SUCCESS: 'GET_FSMANUMBER_SUCCESS',
  GET_FSMANUMBER_FAILED: 'GET_FSMANUMBER_FAILED',
  ADD_OR_UPDATE_FSMANUMBER: 'ADD_OR_UPDATE_FSMANUMBER',
  GET_SINGLE_FSMANUMBER_REQUESTED: 'GET_SINGLE_FSMANUMBER_REQUESTED',
  REMOVE_FSMANUMBER: 'REMOVE_FSMANUMBER',
  /* Sidebar */
  CHANGE_SIDEBAR_VISIBILITY: 'CHANGE_SIDEBAR_VISIBILITY',
  CHANGE_MOBILE_SIDEBAR_VISIBILITY: 'CHANGE_MOBILE_SIDEBAR_VISIBILITY',
  COLLAPSE_SIDEBAR: 'COLLAPSE_SIDEBAR',
  /* Tables */
  CHANGE_COLUMN_VISIBILITY: 'CHANGE_COLUMN_VISIBILITY',
};
