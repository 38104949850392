import styled from 'styled-components';

import { colorBorder, colorTextAdditional } from '../../../../../../shared/utils/palette';

const SideContainer = styled.div`
  border-top: 3px solid ${colorBorder};
  padding-top: 15px;

  width: 50%;
  padding-bottom: 30px;
`;

export const InboundSide = styled(SideContainer)`
  border-right: 1.5px solid ${colorBorder};
  padding-right: 15px;
`;

export const OutboundSide = styled(SideContainer)`
  border-left: 1.5px solid ${colorBorder};
  padding-left: 15px;
`;

export const OpenTitleAmount = styled.span`
  font-size: 0.75rem;
  color: ${colorTextAdditional};
`;

export const BalancesWrapper = styled.div`
  width: 50%;
  margin: auto;
  padding-top: 20px;
`;

export const BalancesInnerWrapper = styled.div`
  width: 400px;
  margin: auto;
`;

export const InvoiceContainer = styled.div`
  position: relative;
  border: 2px solid ${colorBorder};
  border-radius: 15px;
  padding: 8px;
  height: 100%;
  width: 80%;
`;

export const ActionContainer = styled.div`
  float: right;
  display: inline;
  right: 15px;
`;

export const TotalsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;
