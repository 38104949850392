import * as yup from 'yup';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormikAutocomplete from '../../../../../shared/formik/FormikAutocomplete';
import FormikCrmSelector from '../../../../../shared/formik/FormikCrmSelector';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

import SystemTaskType from '../../../../../shared/enums/SystemTaskType';
import { useGetTaskTypes } from '../../../../../queries';

const RequestFileForm = ({ onCancel, onSubmit, caseId, selectedTeamId }) => {
  const { t } = useTranslation('common');

  const { taskTypes, taskTypesIsLoading } = useGetTaskTypes();
  const today = new Date();
  today.setTime(0, 0, 0, 0);

  const predefinedDescription = React.useMemo(() => {
    const options = [
      t('common:paymentRequest'),
      t('common:estimate'),
      t('common:contract'),
      t('common:expertReport'),
      t('common:invoice'),
      t('common:image'),
      t('common:leakDetectionReport'),
    ];
    return options.sort((a, b) => a.localeCompare(b));
  }, [t]);

  return taskTypesIsLoading ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        description: null,
        dueDate: moment().add(
          taskTypes.find((tt) => tt.systemTaskType === SystemTaskType.RequestFile).dueDays,
          'days',
        ),
        crmValues: selectedTeamId ? { team: { id: selectedTeamId } } : null,
      }}
      validationSchema={yup.object().shape({
        description: yup
          .string()
          .max(250, t('errors:fieldMaxLengthIsX', { max: 250 }))
          .nullable()
          .required(t('errors:fieldIsRequired')),
        dueDate: yup
          .date()
          .min(moment().startOf('day'), t('errors:dateCannotBeInThePast'))
          .nullable()
          .required(t('errors:fieldIsRequired')),
        crmValues: yup
          .object()
          .shape({
            team: yup
              .object()
              .shape({
                id: yup.string().nullable(),
                display: yup.string().nullable(),
              })
              .nullable(),
          })
          .nullable()
          .required(t('errors:fieldIsRequired')),
      })}
    >
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup className="mb-3">
              <FormikDatePicker
                name="dueDate"
                label={t('dueDate')}
                options={{
                  disablePast: true,
                  format: 'YYYY-MM-DD',
                }}
                clearable
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormikAutocomplete
              freeSolo
              id="description"
              name="description"
              options={predefinedDescription}
              label={t('common:description')}
              getValue={(val) => val}
              isOptionEqualToValue={() => true}
            />
          </Col>
          <Col md={12}>
            <FormikCrmSelector
              isRequired
              selectSingle
              fullWidth
              id="crmValues"
              name="crmValues"
              label={t('common:assignTo')}
              showTeams
              caseId={caseId}
              teamIdsToSelect={[selectedTeamId]}
            />
          </Col>
        </Row>
        <ModalFooter onCancel={onCancel} />
      </Form>
    </Formik>
  );
};

RequestFileForm.propTypes = {
  caseId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedTeamId: PropTypes.string.isRequired,
};

export default RequestFileForm;
