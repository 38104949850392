import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import HTMLReactParser from 'html-react-parser';
import { kaReducer } from 'ka-table';
import moment from 'moment';

import { localStorageKeys, queryIds, recentCasesCount } from './constants';
import CaseType from '../enums/CaseType';
import DamageType from '../enums/DamageType';
import { decimalRegEx } from './regexes';
import Keyboard from '../enums/Keyboard';
import Language from '../enums/Language';
import LanguageCode from '../enums/LanguageCode';
import { languageMap } from '~/common/constants';
import { Month } from '~/common/enums';
import Priority from '../enums/Priority';
import SystemTaskType from '../enums/SystemTaskType';
import tableReducer from '../../redux/reducers/tableReducer';
import VatCode from '../enums/VatCode';

export const hasEqualType = (item1, item2) => {
  if (typeof item1 !== typeof item2) {
    return false;
  }
  return true;
};

export const isNullOrEmpty = (item) => item == null || !item;

export const isEmptyObject = (obj) => Object.getOwnPropertyNames(obj).length === 0;

export const isIterable = (obj) => {
  if (!obj) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

export const isObject = (obj) => typeof obj === 'object' && !isIterable(obj) && obj != null;

export const getEqualPropertiesInObjects = (obj1, obj2) => {
  if (!obj1 || !obj2) {
    return null;
  }

  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    return null;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  const sameValues = {};

  obj1Keys.forEach((key) => {
    if (!obj2Keys.includes(key)) {
      return;
    }

    const item1 = obj1[key];
    const item2 = obj2[key];

    if (!hasEqualType(item1, item2)) {
      return;
    }

    if (item1 instanceof Object && item2 instanceof Object) {
      sameValues[key] = getEqualPropertiesInObjects(item1, item2);
    } else if (item1 === item2) {
      sameValues[key] = item1;
    }
  });

  return sameValues;
};

export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      flattened[key] = obj[key].join(', ');
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const replaceEmptyStringsWithNulls = (obj) => {
  const copy = obj;
  Object.keys(obj)
    /* eslint-disable-next-line no-return-assign */
    .forEach((k) => (copy[k] = obj[k] === '' ? null : obj[k]));
  return copy;
};

export const getSum = (arr) => arr.reduce((a, b) => a + b, 0);

export const getIssueFilterIconDetails = (key) => {
  switch (key) {
    case 'showLogs':
      return {
        color: '',
        icon: 'history',
      };
    case 'showStatuses':
      return {
        color: '#04CBC0',
        icon: 'heart-rate',
      };
    case 'showNotes':
      return {
        color: '#E3B622',
        icon: 'sticky-note',
      };
    case 'showPhoneCalls':
      return {
        color: '#11ba55',
        icon: 'phone-volume',
      };
    case 'showDocuments':
      return {
        color: '#B922E3',
        icon: 'file',
      };
    case 'showSms':
      return {
        color: '#3402C9',
        icon: 'sms',
      };
    case 'showEmails':
      return {
        color: '#0267E3',
        icon: 'at',
      };
    default:
      throw new Error(`Unsupported key '${key}'`);
  }
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export const isValidEmailAddress = (emailAddress) =>
  String(emailAddress).toLowerCase().match(emailRegex);

export const isBoolean = (val) => typeof val === 'boolean';

export const isString = (val) => typeof val === 'string';

export const isNumber = (val) => typeof val === 'number' || Number.isFinite(val);

export const isDecimalNumber = (val, maxDigits) => {
  const regex = decimalRegEx(maxDigits);
  return val.match(regex);
};

export const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const convertDateToUTCDate = (date) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - timezoneOffset);
};

export const removeTimeFromDate = (date) => moment(date).format('yyyy-MM-DD');

export const distinct = (value, index, self) => self.indexOf(value) === index;

export const distinctObject = (value, index, self, key) =>
  self.findIndex((s) => s[key] === value[key]) === index;

export const decapitalize = (str) => {
  if (typeof str !== 'string') {
    return '';
  }

  const firstCodeUnit = str[0];
  if (firstCodeUnit < '\uD800' || firstCodeUnit > '\uDFFF') {
    return str[0].toLowerCase() + str.slice(1);
  }

  return str.slice(0, 2).toLowerCase() + str.slice(2);
};

const shiftSubsetUp = (array, sortedIndices) => {
  if (sortedIndices[0] === 0) {
    return array; // Cannot shift subset up further, return
  }

  const newArray = array.slice();
  for (let i = 0; i < sortedIndices.length; i += 1) {
    const currentIndex = sortedIndices[i];
    const newIndex = currentIndex - 1;

    [newArray[currentIndex], newArray[newIndex]] = [newArray[newIndex], newArray[currentIndex]];
  }

  return newArray;
};

const shiftSubsetDown = (array, sortedIndices) => {
  if (sortedIndices[sortedIndices.length - 1] === array.length - 1) {
    return array; // Cannot shift subset down further, return
  }

  const newArray = array.slice();
  for (let i = sortedIndices.length - 1; i >= 0; i -= 1) {
    const currentIndex = sortedIndices[i];
    const newIndex = currentIndex + 1;

    [newArray[currentIndex], newArray[newIndex]] = [newArray[newIndex], newArray[currentIndex]];
  }

  return newArray;
};

export const shiftSubset = (array, subset, direction) => {
  const subsetIndices = subset.map((item) => array.findIndex((elem) => elem === item));

  if (subsetIndices.some((index) => index === -1)) {
    return array;
  }

  const sortedIndices = subsetIndices.sort((a, b) => a - b);

  if (direction === 'up') {
    return shiftSubsetUp(array, sortedIndices);
  }

  if (direction === 'down') {
    return shiftSubsetDown(array, sortedIndices);
  }

  return array;
};

export const trimString = (str, len) => {
  if (str && str.length > len) {
    return `${str.slice(0, len)}...`;
  }

  return str;
};

export const capitalize = (str) => {
  const firstCodeUnit = str[0];
  if (firstCodeUnit < '\uD800' || firstCodeUnit > '\uDFFF') {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str.slice(0, 2).toUpperCase() + str.slice(2);
};

export const sentencize = (str) => capitalize(str.toLowerCase());

export const removeWhitespaces = (str) => str.replace(/\s+/g, '');

export const getCaseTypeTranslation = (t, caseType) => {
  switch (caseType) {
    case CaseType.InsuranceClaim:
      return t('common:insuranceClaim');
    case CaseType.Repair:
      return t('common:repairCase');
    case CaseType.Complaint:
      return t('common:complaint');
    default:
      return t('common:_case.title');
  }
};

export const getSystemTaskTypeTranslation = (t, systemTaskType) => {
  if (Object.keys(SystemTaskType).some((key) => key === systemTaskType)) {
    return t(`common:${decapitalize(systemTaskType)}`);
  }
  return systemTaskType;
};

export const getDamageTypeTranslation = (t, damageType) => {
  if (!damageType) {
    return null;
  }

  if (
    damageType === DamageType.Water ||
    damageType === DamageType.Electricity ||
    damageType === DamageType.FireOrSmoke ||
    damageType === DamageType.Glass
  ) {
    return t(`common:${decapitalize(damageType.concat('Damage'))}`);
  }
  if (damageType === DamageType.StormWeatherNaturalDisasters) {
    return t('common:stormDamage');
  }

  return t(`common:${decapitalize(damageType.toString())}`);
};

export const scrollTo = (id, topOffset) => {
  const element = document.getElementById(id);
  if (element != null) {
    const elementTop = element.getBoundingClientRect().top;
    const viewportHeight = window.innerHeight;
    const scrollTop = window.pageYOffset + elementTop - (topOffset ?? viewportHeight / 2);
    window.scrollTo({ behavior: 'smooth', top: scrollTop });
  }
};

export const scrollToOverflowContainer = (id) => {
  const targetElement = document.querySelector(`#${id}`);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export const scrollToOverflowContainerWithOffset = (id, topOffset, containerId) => {
  const targetElement = document.querySelector(`#${id}`);
  const scrollableContainer = document.querySelector(`#${containerId}`);

  if (targetElement && scrollableContainer) {
    const elementTop = targetElement.getBoundingClientRect().top;    
    const containerTop = scrollableContainer.getBoundingClientRect().top;
    const containerScrollTop = scrollableContainer.scrollTop;

    const scrollPosition = containerScrollTop + elementTop - containerTop - topOffset;
    scrollableContainer.scrollTo({
      behavior: 'smooth',
      top: scrollPosition,
    
    });
  }
};

export const getTaskDescription = (t, task) => {
  const tasktype = task.systemTaskType ?? task.teamTaskType;
  if (task.description === tasktype || task.description == null) {
    return getSystemTaskTypeTranslation(t, tasktype);
  }
  if (tasktype === SystemTaskType.CreateAutomaticMessageTemplate) {
    return sentencize(
      t('common:createAutomaticMessageTemplate', {
        trigger: t(`common:${decapitalize(task.description)}`),
      }),
    );
  }
  return `${tasktype ? `${getSystemTaskTypeTranslation(t, tasktype)}: ` : ''}${task.description}`;
};

export const getCurrentTranslation = (translations, languageCode) =>
  translations?.find((translation) => translation.language === languageMap[languageCode])
    ?.description;

export const getConditionsTranslated = (t, i18n, conditions) =>
  conditions.map((p) => {
    const translatedValues = p.predicateValues
      .map((v) => {
        if (v.translations != null && v.translations.length > 0) {
          return getCurrentTranslation(v.translations, i18n.language);
        }
        return (
          i18n.exists(`common:${decapitalize(v.description ?? v)}`)
            ? t(`common:${decapitalize(v.description ?? v)}`)
            : v.description ?? v
        ).toLowerCase();
      })
      .join(` ${t('common:or')} `);
    const translationValues =
      !!p.lowerLimit && !!p.upperLimit
        ? t('common:inBetweenXAndY', { x: p.lowerLimit, y: p.upperLimit })
        : `${t('common:isEqualTo')} ${translatedValues}`;
    return {
      key: p.predicateIdentifier,
      text: `${t(`common:${decapitalize(p.predicateIdentifier)}`)} ${translationValues}`,
    };
  });

export const getAddressString = (addressLine, city, postalCode, country) => {
  const concatted = `${addressLine || ''} ${postalCode || ''} ${city || ''}`;
  const singleSpaces = concatted.replace(/  +/g, ' ');
  if (country == null || country.length === 0) {
    return singleSpaces.trim() != null && singleSpaces.trim().length > 0 ? singleSpaces : '';
  }
  return `${singleSpaces} (${country})`;
};

export const getAddressStringFromAddressObject = (addressObject) => {
  if (addressObject == null) {
    return null;
  }
  const { addressLine, street, city, postalCode, country } = addressObject;
  return getAddressString(addressLine ?? street, city, postalCode, country);
};

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1 == null && arr2 == null) {
    return true;
  }
  // Check if the arrays have the same length
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Iterate through each element in the arrays
  for (let i = 0; i < arr1.length; i += 1) {
    const value1 = arr1[i];
    const value2 = arr2[i];

    // Check if the values are arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      // Recursively check if the nested arrays are equal
      if (!arraysAreEqual(value1, value2)) {
        return false;
      }
    } else if (typeof value1 === 'object' && typeof value2 === 'object') {
      // Check if the values are objects
      // eslint-disable-next-line no-use-before-define
      if (!objectsAreEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  // The arrays are equal
  return true;
};

export const objectsAreEqual = (obj1, obj2) => {
  if (obj1 == null && obj2 == null) {
    return true;
  }

  if (obj1 == null || obj2 == null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!objectsAreEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

export const getMomentDescription = (
  momentFunc,
  dateString,
  format = 'YYYY-MM-DDTHH:mm:ss',
  convertToLocal = false,
  withFullDate = false,
) => {
  let dateObject = momentFunc.utc(dateString, format);
  if (convertToLocal) {
    dateObject = dateObject.local();
  }
  if (!withFullDate) {
    return dateObject.fromNow();
  }
  return `${dateObject.fromNow()} (${dateObject.format('DD/MM/YYYY')})`;
};

export const getMomentObject = (date, format = 'YYYY-MM-DDTHH:mm:ss') => moment(date, format);

export const getMomentUtcObject = (date, format = 'YYYY-MM-DDTHH:mm:ss') =>
  moment.utc(date, format);

export const getMomentUtcToLocalObject = (date, format = 'YYYY-MM-DDTHH:mm:ss') =>
  moment.utc(date, format).local();

export const formatDate = (
  date,
  outputFormat = 'DD/MM/YYYY',
  inputFormat = 'YYYY-MM-DDTHH:mm:ss',
) => getMomentObject(date, inputFormat).format(outputFormat);

export const formatUtcDate = (
  date,
  outputFormat = 'DD/MM/YYYY',
  inputFormat = 'YYYY-MM-DDTHH:mm:ss',
) => getMomentUtcObject(date, inputFormat).local().format(outputFormat);

export const isTodayBetween = (from, until, format = 'YYYY-MM-DDTHH:mm:ss') => {
  const fromDate = moment(from, format);
  const untilDate = moment(until, format);
  const today = moment();
  return fromDate < today && today < untilDate;
};

export const isInTheFuture = (date, format = 'YYYY-MM-DDTHH:mm:ss') => {
  const today = moment();
  if (moment.isMoment(date)) {
    return date > today;
  }
  const fromDate = moment(date, format);
  return fromDate > today;
};

export const roundTo = (n, digits) => {
  const digitsToUse = digits ?? 0;
  const multiplicator = 10 ** digitsToUse;
  const multiplicated = parseFloat((n * multiplicator).toFixed(11));

  // JavaScripts' Math.round has the unusual behavior of rounding halfway cases towards positive infinity
  // For positive numbers, we can just use Math.round (as it will round towards positive infinity: 0.5 -> 1)
  // For negative numbers, we need to round the inverted number and then invert it again (otherwise -0.5 will round to 0 instead of to -1)
  return multiplicated < 0
    ? -Math.round(-multiplicated) / multiplicator
    : Math.round(multiplicated) / multiplicator;
};

export const convertToUtc = (date) => {
  let time = null;
  if (date) {
    time = moment.parseZone(date).utc(true).format();
  }

  return time;
};

export const formatAsCurrency = (number, includeCurrency = false, digits = 2) => {
  const style = includeCurrency
    ? {
        currency: 'EUR',
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
        style: 'currency',
      }
    : {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      };
  return new Intl.NumberFormat('nl-BE', style).format(number);
};

export const getToday = () => moment();

export const getTodayUtc = () => moment().utc();

export const getCreatedBy = (createdByUser, createdByTeam) => {
  if (createdByUser == null || createdByUser.length === 0) {
    return createdByTeam;
  }
  if (createdByTeam == null || createdByTeam.length === 0) {
    return createdByUser;
  }
  return `${createdByUser} (${createdByTeam})`;
};

export const addLeadingZeros = (value, amountOfDigits) => {
  const val = isNumber(value) ? value.toString() : value;
  if (val.length < amountOfDigits) {
    // call recursively in case we need to add more than 1 leadingZero
    return addLeadingZeros(`0${val}`, amountOfDigits);
  }
  return val;
};

export const formatUsernameAndTeamName = (username, teamName) => {
  if (teamName == null || !teamName.length) {
    return username ?? '';
  }

  if (username == null || !username.length) {
    return teamName ?? '';
  }

  return `${username} (${teamName})`;
};

export const addToRecentCases = (id, reference, description) => {
  let recentCases;
  if (localStorage.getItem('recentCases')) {
    recentCases = JSON.parse(localStorage.getItem('recentCases') || '[]');
  } else recentCases = [];

  recentCases = recentCases.filter((x) => x.id !== id);

  recentCases.push({
    description,
    id,
    reference,
  });

  if (recentCases.length > recentCasesCount) {
    recentCases.shift();
  }
  localStorage.setItem('recentCases', JSON.stringify(recentCases));
  const event = new Event('casesChange');
  document.dispatchEvent(event);
};

export const getRecentCases = () => {
  const cases = JSON.parse(localStorage.getItem('recentCases') || '[]');
  return cases.reverse();
};

export const removeFromRecentCases = (id) => {
  let recentCases;
  if (localStorage.getItem('recentCases')) {
    recentCases = JSON.parse(localStorage.getItem('recentCases') || '[]');
    recentCases = recentCases.filter((x) => x.id !== id);
  }
  localStorage.setItem('recentCases', JSON.stringify(recentCases));
  const event = new Event('casesChange');
  document.dispatchEvent(event);
};

export const clearRecentCases = () => {
  localStorage.removeItem('recentCases');
  const event = new Event('casesChange');
  document.dispatchEvent(event);
};

export const invalidateAllQueriesExceptEnums = (queryClient) => {
  queryClient.removeQueries({
    predicate: (query) => {
      // remove everything related to all but the enum queries
      const isEnumQuery = Object.values(queryIds.enums).includes(query.queryKey[0]);
      return !isEnumQuery;
    },
  });
};

export const getAllowedUploadTypes = (allowedDocumentTypes) => {
  if (
    allowedDocumentTypes == null ||
    !Array.isArray(allowedDocumentTypes) ||
    allowedDocumentTypes.length === 0
  )
    return {};
  const obj = {};

  allowedDocumentTypes
    .filter((at) => at.includes('/'))
    .forEach((at) => {
      obj[at] = [];
    });
  return obj;
};

export const imageFileTypes = [
  'image/png',
  'jpg',
  'jpeg',
  'image/jpg',
  'image/jpeg',
  'bmp',
  'image/bmp',
  'png',
  'image/png',
];

export const msDocFileTypesMap = {
  exel: [
    'xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'xls',
    'application/vnd.ms-excel',
  ],
  powerPoint: [
    'pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'ppt',
    'application/vnd.ms-powerpoint',
  ],
  word: [
    'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'doc',
    'application/msword',
  ],
};

export const msDocFileTypes = [
  ...msDocFileTypesMap.exel,
  ...msDocFileTypesMap.powerPoint,
  ...msDocFileTypesMap.word,
];

export const pdfFileTypes = ['pdf', 'application/pdf'];

export const txtFileTypes = ['text', 'text/plain'];

export const getFileTypeIconForContentType = (contentType) => {
  let icon = 'file';
  if (imageFileTypes.includes(contentType)) icon = 'file-image';
  else if (msDocFileTypesMap.word.includes(contentType)) icon = 'file-word';
  else if (msDocFileTypesMap.exel.includes(contentType)) icon = 'file-excel';
  else if (msDocFileTypesMap.powerPoint.includes(contentType)) icon = 'file-powerpoint';
  else if (pdfFileTypes.includes(contentType)) icon = 'file-pdf';
  else if (txtFileTypes.includes(contentType)) icon = 'file-lines';
  return icon;
};

export const checkFileCanBePreviewed = (contentType) =>
  [...pdfFileTypes, ...msDocFileTypes, ...imageFileTypes, ...txtFileTypes].includes(contentType);

export const getLanguageFromCode = (code) => {
  switch (code) {
    case LanguageCode.en:
      return Language.English;
    case LanguageCode.nl:
      return Language.Dutch;
    case LanguageCode.fr:
      return Language.French;
    default:
      return Language.English;
  }
};

export const getMaxDaysInMonth = (month) => {
  const daysInMonth = {
    [Month.January]: 31,
    [Month.February]: 28,
    [Month.March]: 31,
    [Month.April]: 30,
    [Month.May]: 31,
    [Month.June]: 30,
    [Month.July]: 31,
    [Month.August]: 31,
    [Month.September]: 30,
    [Month.October]: 31,
    [Month.November]: 30,
    [Month.December]: 31,
  };

  return daysInMonth[month];
};

export const getEnumValues = (enumObj) =>
  Object.entries(enumObj).map(([key, value]) => ({ name: key, value }));

export const checkSubordinates = (parent, subordinates) => {
  const parentSubordinates = subordinates.filter(
    (subordinate) =>
      (subordinate.parentRelationId === parent.id || subordinate.subordinate === parent.id) &&
      subordinates.some(
        (otherSubordinate) =>
          otherSubordinate.parentRelationId === subordinate.id ||
          otherSubordinate.subordinate === subordinate.id,
      ),
  );

  if (parentSubordinates == null || parentSubordinates.length === 0) {
    // No further parent subordinates, return the regular subordinates
    return (
      subordinates.filter(
        (subordinate) =>
          subordinate.parentRelationId === parent.id || subordinate.subordinate === parent.id,
      ) ?? []
    );
  }
  // Else, get the other parent subordinates first
  const otherParents = [];
  parentSubordinates.forEach((parentSubordinate) => {
    otherParents.push(parentSubordinate);
    const others = checkSubordinates(parentSubordinate, subordinates) ?? [];
    otherParents.push(...others);
  });
  return otherParents;
};

export const getRelationsWithSubordinates = (allRelations) => {
  const parents = allRelations.filter(
    (rel) =>
      rel.parentRelationId == null &&
      rel.subordinate == null &&
      allRelations
        .filter((otherRel) => otherRel.id !== rel.id)
        .some(
          (otherRel) => otherRel.parentRelationId === rel.id || otherRel.subordinate === rel.id,
        ),
  );

  const all = [];
  parents.forEach((parent) => {
    /* Add the parent to the list */
    all.push(parent);
    /* Check if there are any subordinates for this parent that are a parent as well */
    const subordinates = checkSubordinates(parent, allRelations) ?? [];
    all.push(...subordinates);
  });

  // Append all that are not a parent nor a subordinate
  const others = allRelations
    .filter((rel) => !all.some((a) => a.id === rel.id))
    .map((rel) => ({
      ...rel,
      parentRelationId: null,
      subordinate: null,
    }));
  all.push(...others);
  return all;
};

export const sortTasksByPriority = (a, b) => {
  if (
    a.priority === Priority.High ||
    (a.priority === Priority.Normal && b.priority === Priority.Low)
  ) {
    return -1;
  }

  return 1;
};

export const getVatPercentage = (vatEnum) => {
  switch (vatEnum) {
    case VatCode.CoContractorPurchase6:
    case VatCode.CoContractorPurchase12:
    case VatCode.CoContractorPurchase21:
      return 0;
    case VatCode.InlandExclusive6:
      return 6;
    case VatCode.InlandExclusive12:
      return 12;
    case VatCode.InlandExclusive21:
      return 21;
    default:
      return null;
  }
};

export const getVatPercentageDisplay = (vatEnum) => {
  switch (vatEnum) {
    case VatCode.CoContractorPurchase6:
      return '6% MC';
    case VatCode.CoContractorPurchase12:
      return '12% MC';
    case VatCode.CoContractorPurchase21:
      return '21% MC';
    case VatCode.InlandExclusive6:
      return '6%';
    case VatCode.InlandExclusive12:
      return '12%';
    case VatCode.InlandExclusive21:
      return '21%';
    default:
      return null;
  }
};

export const getAllPagedData = async (fetch, options = []) => {
  const response = await fetch();
  const { serviceError, status, data, getNext } = response;

  if (serviceError != null || status !== 200) {
    return {
      data,
      serviceError,
      status,
    };
  }

  const allData = options.concat(data);
  if (getNext != null) {
    return getAllPagedData(getNext, allData);
  }

  return {
    data: allData,
    serviceError,
    status,
  };
};

export const getEventListenerEffect = (eventName, handler) => {
  window.addEventListener(eventName, handler);
  return () => {
    window.removeEventListener(eventName, handler);
  };
};

export const addEscEnterKeyEffect = (escKeyHandler, enterKeyHandler) => {
  const handleKeyboard = (event) => {
    if (event.keyCode === Keyboard.Esc) {
      escKeyHandler();
    }
    if (event.keyCode === Keyboard.Enter) {
      enterKeyHandler();
    }
  };

  return getEventListenerEffect('keyup', handleKeyboard);
};

export const mergePaginatedResults = (data) => {
  if (!data || !Array.isArray(data.pages)) return undefined;
  if (!data.pages.length > 0) return undefined;

  return data.pages.map(({ items }) => items).flat();
};

export const formatAddress = (item) => {
  if (item == null) {
    return '-';
  }
  const { building } = item;
  const { addressLine, postalCode, city, name } = building || item;
  const caseAddress =
    `${name ?? ''} ${addressLine ?? ''} ${postalCode ?? ''} ${city ?? ''}`.trim() || '-';

  return caseAddress;
};

export const formatPhoneNumber = (item) => {
  if (!item || (isNullOrEmpty(item.number) && isNullOrEmpty(item.countryCode))) {
    return '-';
  }
  return `${item.countryCode ? `+${item.countryCode}` : ''}${item.number ?? '-'}` || '-';
};

export const formatDisplayName = (client) => {
  if (!client) {
    return '-';
  }
  if (client.companyName) {
    return {
      type: 'company',
      value: client.companyName.trim(),
    };
  }
  if (client.firstName && !client.lastName) {
    return {
      type: 'person',
      value: client.firstName.trim(),
    };
  }
  if (!client.firstName && client.lastName) {
    return {
      type: 'person',
      value: client.lastName.trim(),
    };
  }
  if (client.firstName && client.lastName) {
    return {
      type: 'person',
      value: `${client.firstName.trim()} ${client.lastName.trim()}`,
    };
  }

  return '-';
};

export const formatDataForAutoComplete = ({ data, value, label }) =>
  data
    .map((obj) => ({
      label: obj[label] || '-',
      value: obj[value],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const formatTranslatedDataForAutoComplete = ({
  data,
  valueKey,
  labelKey,
  translationCallback,
}) =>
  data.map((obj) => ({
    label: obj[labelKey] ? translationCallback(obj[labelKey]) : '-',
    value: obj[valueKey],
  }));

export const formatCaseClassificationForAutoComplete = (caseClassifications, languageCode) =>
  caseClassifications.map((caseClassification) => ({
    caseType: caseClassification.caseType,
    label: getCurrentTranslation(caseClassification.translations, languageCode),
    value: caseClassification.id,
  }));

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getMaxDecimalPrecisionValue = (precision = 18, scale = 2) => {
  const str = '9'.repeat(precision);
  return +`${str.slice(0, precision - scale)}.${str.slice(precision - scale)}`;
};

export const workdayStrToArr = (workdaysStr) => {
  if (!workdaysStr) {
    return [];
  }
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const strArr = workdaysStr.replaceAll(' ', '').split(',');

  return strArr.map((d) => weekdays.indexOf(d));
};

export const filterBookYear = ({ data, year, key }) => {
  if (!year) {
    return data;
  }

  return data.filter((obj) => {
    const objYear = dayjs(obj[key]).year();
    return objYear.toString() === year.value;
  });
};

export const filterStringWithAutoCompleteFilters = ({ data, filters, key }) => {
  if (!filters?.length) {
    return data;
  }

  return data.filter((obj) => filters.some(({ value }) => value === obj[key]));
};

export const filterListWithAutoCompleteFilters = ({ data, filters, key }) => {
  if (!filters?.length) {
    return data;
  }

  return data.filter((obj) => filters.some(({ value }) => obj[key].some((v) => v === value)));
};

export const filterDataWithAutoCompleteFilters = ({ data, filters, keyToFilter, filterKey }) => {
  if (!filters?.length) {
    return data;
  }

  const filterIds = new Set(filters.map(({ value }) => value));

  const filteredData = data.filter((dataObject) => {
    const dataToFilter = dataObject[keyToFilter];

    if (!Array.isArray(dataToFilter)) {
      throw new Error(`keyToFilter ${keyToFilter} is not an array`);
    }

    return dataToFilter.some((filterObject) => {
      const filterObjectHasKey = Object.prototype.hasOwnProperty.call(filterObject, filterKey);
      if (!filterObjectHasKey) {
        throw new Error(`filterKey ${filterKey} not found on filterObject`);
      }

      return filterIds.has(filterObject[filterKey]);
    });
  });

  return filteredData;
};

export const filterDateRange = ({ data, dateRange, key }) => {
  const { startDate, endDate } = dateRange;
  if (startDate == null || endDate == null) {
    return data;
  }

  const filteredData = data.filter((item) => {
    const isBetween = dayjs(item[key]).isBetween(startDate, endDate, 'day', '[]');
    return isBetween;
  });

  return filteredData;
};

export const getStoredColumns = (defaultColumns, localStorageKey) => {
  if (!localStorageKey) {
    return defaultColumns;
  }

  const localStorageColumns = JSON.parse(localStorage.getItem(localStorageKey));
  let hasLocalStorageColumns = localStorageColumns && Array.isArray(localStorageColumns);
  if (
    hasLocalStorageColumns && // reset localStorage when column was added / name changed
    !arraysAreEqual(
      localStorageColumns.map((c) => c.key).sort(),
      defaultColumns.map((c) => c.key).sort(),
    )
  ) {
    hasLocalStorageColumns = false;
  }
  if (!hasLocalStorageColumns) {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(
        defaultColumns.map((column) => ({
          allowDeselect: column.allowDeselect,
          isSortable: column.isSortable,
          key: column.key,
          sortDirection: column.sortDirection,
          title: column.title,
          visible: column.visible,
          width: column.width,
        })),
      ),
    );

    return defaultColumns;
  }

  const newColumns = localStorageColumns.map((column) => {
    const matchingColumn = defaultColumns.find(({ key }) => key === column.key);

    let newColumn = { ...column };

    if (matchingColumn?.customCellComponent) {
      newColumn = { ...newColumn, customCellComponent: matchingColumn.customCellComponent };
    }

    if (matchingColumn?.customHeaderComponent) {
      newColumn = { ...newColumn, customHeaderComponent: matchingColumn.customHeaderComponent };
    }

    return newColumn;
  });

  return newColumns;
};

export const saveColumns = ({ action, prevState, localStorageType }) => {
  const reducers = [kaReducer, tableReducer];
  const finalState = reducers.reduce(
    (state, reducer) =>
      reducer(state, { ...action, payload: { ...action.payload, localStorageType } }),
    prevState,
  );

  return finalState;
};

export const transformHtml = (body) => {
  if (isNullOrEmpty(body)) {
    return null;
  }
  const cleanHtml = DOMPurify.sanitize(body);
  const parser = new DOMParser();
  const content = parser.parseFromString(cleanHtml, 'text/html');
  const anchors = content.getElementsByTagName('a');
  Array.from(anchors).forEach((a) => {
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noopener');
  });
  const hrs = content.getElementsByTagName('hr');
  Array.from(hrs).forEach((hr) => {
    hr.classList.add('opacity-100');
  });
  return HTMLReactParser(content.body.innerHTML);
};

export const mapEmailEntityFieldsToMailboxFields = (entity, body) => ({
  ...entity,
  body: body ?? '',
  dateEpoch: getMomentUtcObject(entity.emailDate).unix(),
  files: entity.attachments.map((attachment) => ({
    contentType: attachment.contentType,
    fileName: attachment.fileName,
    id: attachment.fileId,
    isAttachment: true,
    url: attachment.url,
  })),
  id: entity.emailId,
  snippet: entity.preheader,
});

export const clamp = (value, min, max) => {
  if (min && value < min) {
    return min;
  }
  if (max && value > max) {
    return max;
  }
  return value;
};

export const isDueDate = (date) => {
  if (!dayjs(date).isValid()) return false;
  const today = dayjs();
  const dueDate = dayjs(date);

  return dueDate.isBefore(today, 'day');
};

export const getAvatarInitials = (name) => {
  if (!name) return '';

  const splitName = name?.trim().split(' ');

  if (splitName.length > 1) {
    return (splitName[0][0] + splitName[splitName.length - 1][0]).toUpperCase();
  }
  return splitName[0][0].toUpperCase();
};

export const sortArrayByKey = (array, key) => {
  const hasTypeProperty = array.some((item) => typeof item === 'object' && key in item);

  if (hasTypeProperty && key) {
    return array.sort((a, b) => {
      if (a[key] && b[key]) {
        return a[key].localeCompare(b[key]);
      }
      if (!a[key]) {
        return 1;
      }
      if (!b[key]) {
        return -1;
      }
      return 0;
    });
  }

  return array;
};

export const formatCaseStatus = (status, language, t) => {
  let description;

  if (status.translations == null) {
    description = t(`common:${decapitalize(status.value)}`);
  } else {
    switch (language) {
      case LanguageCode.en:
        description = status.translations.find(
          (tr) => tr.language === Language.English,
        ).description;
        break;
      case LanguageCode.fr:
        description = status.translations.find((tr) => tr.language === Language.French).description;
        break;
      case LanguageCode.nl:
        description = status.translations.find((tr) => tr.language === Language.Dutch).description;
        break;
      default:
        description = status.translations.find(
          (tr) => tr.language === Language.English,
        ).description;
        break;
    }
  }
  return {
    caseType: status.caseType,
    id: status.id,
    label: description,
    statusType: status.statusType,
    value: status.isSystemDefault ? status.value : description,
  };
};

export const objectToFormData = (obj, namespace = null, prevData = null) => {
  let initial = false;
  let data = prevData;

  if (prevData === null) {
    initial = true;
    data = new FormData();
  }
  if (!data) {
    return null;
  }
  if (
    (typeof obj === 'string' || obj instanceof File || obj instanceof Blob) &&
    data &&
    namespace
  ) {
    data.append(namespace, obj);
    return data;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && data) {
      const formKey = namespace
        ? `${namespace}${initial ? '' : '.'}${key}`
        : `${initial ? '' : '.'}${key}`;
      if (obj[key] instanceof Date) {
        data.append(formKey, obj[key].toISOString());
      } else if (obj[key] instanceof Array) {
        obj[key].forEach((element, index) => {
          let tempFormKey = `${formKey}[${index}]`;
          // For files, we push all files in an array to the same namespace
          if (element instanceof File) {
            tempFormKey = formKey;
          }
          objectToFormData(element, tempFormKey, data);
        });
      } else if (
        typeof obj[key] === 'object' &&
        !(obj[key] instanceof File) &&
        !(obj[key] instanceof Blob)
      ) {
        objectToFormData(obj[key], formKey, data);
      } else {
        data.append(formKey, obj[key]);
      }
    }
  });
  return data;
};

export const setOpenCaseInNewTabValue = (openCaseInNewTab) => {
  localStorage.setItem(localStorageKeys.openCaseInNewTab, JSON.stringify(openCaseInNewTab));
};

export const getOpenCaseInNewTabValue = () => {
  const value = localStorage.getItem(localStorageKeys.openCaseInNewTab);
  return value === 'true';
};
