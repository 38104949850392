import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CaseRelationRole from '@/shared/enums/CaseRelationRole';

import { AcceptIcon, CancelIcon, WarningIcon } from '@/shared/icons/Icons';

type LabelProps = {
  labelNo: string;
  labelYes: string;
};

type ComplianceVisualizerProps = {
  isCompliant: boolean;
  showWarningInsteadOfError?: boolean;
  labels: LabelProps;
};

type RelationProps = {
  type: string;
  isClient: boolean;
  isRequestor: boolean;
};

type Props = {
  relations?: RelationProps[];
  createCaseAskForClient: boolean;
};

const ComplianceVisualizer = ({
  isCompliant,
  showWarningInsteadOfError = false,
  labels,
}: ComplianceVisualizerProps) => {
  if (isCompliant) {
    return (
      <div>
        <span className="mr-2 text-green-500">
          <AcceptIcon size="xl" />
        </span>
        {labels.labelYes}
      </div>
    );
  }

  if (showWarningInsteadOfError) {
    return (
      <div>
        <span className="mr-2 text-orange-500">
          <WarningIcon size="xl" />
        </span>
        {labels.labelNo}
      </div>
    );
  }

  return (
    <div>
      <span className="mr-2 text-red-500">
        <CancelIcon size="xl" />
      </span>
      {labels.labelNo}
    </div>
  );
};

const InvolvedPartiesComplianceVisualizer = ({ relations, createCaseAskForClient }: Props) => {
  const { t } = useTranslation();

  const hasInsurer = useMemo(() => {
    if (!relations?.length) {
      return false;
    }
    return relations.some((cr) => cr.type === CaseRelationRole.Insurer);
  }, [relations]);

  const hasClient = useMemo(() => {
    if (!relations?.length) {
      return false;
    }
    return relations.some((cr) => cr.isClient);
  }, [relations]);

  const hasRequestor = useMemo(() => {
    if (!relations?.length) {
      return false;
    }
    return relations.some((cr) => cr.isRequestor);
  }, [relations]);

  const hasBroker = useMemo(() => {
    if (!relations?.length) {
      return false;
    }
    return relations.some((cr) => cr.type === CaseRelationRole.InsuranceIntermediary);
  }, [relations]);

  return (
    <div className="m-2 grid grid-cols-2 gap-2">
      {createCaseAskForClient && (
        <ComplianceVisualizer
          isCompliant={hasClient}
          labels={{
            labelNo: t('common:clientSelectedNo'),
            labelYes: t('common:clientSelectedYes'),
          }}
        />
      )}

      <ComplianceVisualizer
        isCompliant={hasRequestor}
        labels={{
          labelNo: t('common:requestorSelectedNo'),
          labelYes: t('common:requestorSelectedYes'),
        }}
      />

      <ComplianceVisualizer
        isCompliant={hasInsurer}
        showWarningInsteadOfError
        labels={{
          labelNo: t('common:insurerSelectedNo'),
          labelYes: t('common:insurerSelectedYes'),
        }}
      />

      <ComplianceVisualizer
        isCompliant={hasBroker}
        showWarningInsteadOfError
        labels={{
          labelNo: t('common:insuranceIntermediarySelectedNo'),
          labelYes: t('common:insuranceIntermediarySelectedYes'),
        }}
      />
    </div>
  );
};

export default InvolvedPartiesComplianceVisualizer;
