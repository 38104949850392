import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '../../shared/api/cases/case';
import { SendEmailFromCaseRequest } from '~/common/types/email/emailMessage';
import useNotification from '../../shared/hooks/UseNotification';

const useSendEmailFromCase = () => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation(
    (data: SendEmailFromCaseRequest) => caseApi.postSendEmailMessage(data),
    {
      onError: sendDefaultError,
      onSuccess: () => {
        sendNotification({
          header: t('success'),
          message: t('typeSuccessfullySent', { type: t('email_one') }),
          variant: 'success',
        });
      },
    },
  );

  return {
    isSendingMessage: isLoading,
    sendMessage: mutate,
  };
};

export default useSendEmailFromCase;
