import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Timeline from '@mui/lab/Timeline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CaseEntityPropTypes from '@/shared/prop-types/CaseEntityPropTypes';
import CaseEntityType from '@/shared/enums/CaseEntityType';

import { getIssueFilterIconDetails } from '@/shared/utils/helpers';
import IconCheckbox from '@/shared/components/IconCheckbox';
import KpcCard from '@/shared/components/2.0/layout/KpcCard';
import TimelineSection from './TimelineSection';

const ITEMS_PER_PAGE = 10;

const propTypes = {
  caseEntities: PropTypes.arrayOf(CaseEntityPropTypes).isRequired,
};

const TimelineCard = ({ caseEntities }) => {
  const { settings } = useSelector((state) => state.user);

  const { t } = useTranslation('common');

  const getFilterType = (key) => {
    switch (key) {
      case 'showLogs':
        return CaseEntityType.Log;
      case 'showStatuses':
        return CaseEntityType.Status;
      case 'showNotes':
        return CaseEntityType.Note;
      case 'showPhoneCalls':
        return CaseEntityType.PhoneCall;
      case 'showDocuments':
        return CaseEntityType.File;
      case 'showSms':
        return CaseEntityType.TextMessage;
      case 'showEmails':
        return CaseEntityType.Email;
      default:
        return null;
    }
  };

  const [appliedFilters, setAppliedFilters] = useState([]);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  const allFilteredItems = useMemo(() => {
    if (appliedFilters?.length) {
      return caseEntities.filter((entity) => appliedFilters?.includes(entity.type));
    }
    return caseEntities;
  }, [appliedFilters, caseEntities]);

  const loadMoreItems = useCallback(() => {
    setPage((prev) => (allFilteredItems.length <= prev * ITEMS_PER_PAGE ? prev : prev + 1));
  }, [allFilteredItems.length]);

  useEffect(
    () => setItems(allFilteredItems.slice(0, page * ITEMS_PER_PAGE)),
    [allFilteredItems, page],
  );

  useEffect(() => {
    setAppliedFilters(
      Object.entries(settings?.caseActivityLogFilter ?? {})
        .map(([key, val]) => {
          if (val) {
            return key;
          }
          return null;
        })
        .map((e) => getFilterType(e))
        .filter((e) => e),
    );
  }, [settings]);

  return (
    <KpcCard id="caseTimeLine" title={t('timeLine')}>
      {caseEntities.length !== 0 && (
        <div className="flex w-full justify-end px-5">
          {Object.entries(settings?.caseActivityLogFilter ?? {}).map(([key]) => {
            if (['showLogs', 'showStatuses'].includes(key)) return null;

            const details = getIssueFilterIconDetails(key);
            const currentFilterType = getFilterType(key);

            return (
              <IconCheckbox
                key={key}
                id={`${key}`}
                isChecked={appliedFilters?.includes(currentFilterType)}
                tooltipText={t(key)}
                checkedIcon={
                  <FontAwesomeIcon icon={['far', details.icon]} style={{ color: details.color }} />
                }
                uncheckedIcon={
                  <FontAwesomeIcon icon={['far', details.icon]} className="issue-filter-disabled" />
                }
                onClick={() =>
                  setAppliedFilters((prevState) => {
                    if (prevState.includes(currentFilterType)) {
                      return prevState.filter((f) => f !== currentFilterType);
                    }
                    return [...prevState, currentFilterType];
                  })
                }
              />
            );
          })}
        </div>
      )}
      <Timeline sx={{ width: '100%' }} position="right">
        <TimelineSection items={items} loadMoreItems={loadMoreItems} />
      </Timeline>
    </KpcCard>
  );
};

export default TimelineCard;

TimelineCard.propTypes = propTypes;
