import { ReactNode, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import BuildingContext from './BuildingContext';
import { RouteParams } from '@/types';
import { trpc } from '@/config/trpc';

type BuildingContextProps = {
  children: ReactNode;
};

const queryOptions = {
  trpc: {
    context: {
      skipBatch: true,
    },
  },
};

const BuildingContextProvider: React.FC<BuildingContextProps> = ({ children }) => {
  const { buildingId } = useParams() as RouteParams;

  const utils = trpc.useUtils();

  const { data: buildingSummary, isLoading: buildingSummaryIsLoading } =
    trpc.building.summaryById.useQuery(buildingId, queryOptions);

  const { data: relations, isLoading: relationsAreLoading } = trpc.building.relations.useQuery(
    { buildingId },
    queryOptions,
  );

  const { data: units, isLoading: unitsAreLoading } = trpc.building.unit.list.useQuery(
    { buildingId },
    queryOptions,
  );

  const { data: tags, isLoading: tagsAreLoading } = trpc.building.tagsForBuilding.useQuery(
    { buildingId },
    queryOptions,
  );

  const { data: pushNotificationSettings } =
    trpc.messaging.pushNotificationSettings.getBuildingPortalPushNotificationSettings.useQuery(
      undefined,
      queryOptions,
    );

  const { data: homeownerAssociation } = trpc.hoa.summary.useQuery(
    { id: buildingSummary?.homeownerAssociationId as string },
    { ...queryOptions, enabled: !!buildingSummary?.homeownerAssociationId },
  );

  const invalidateUnits = useCallback(() => {
    utils.building.unit.list.invalidate({ buildingId });
  }, [buildingId]);

  const invalidateRelations = useCallback(() => {
    utils.building.relations.invalidate({ buildingId });
  }, [buildingId]);

  const value = useMemo(
    () => ({
      buildingSummary,
      buildingSummaryIsLoading,
      homeownerAssociation,
      invalidateRelations,
      invalidateUnits,
      pushNotificationSettings,
      relations: relations ?? [],
      relationsAreLoading,
      tags: tags ?? [],
      tagsAreLoading,
      units: units ?? [],
      unitsAreLoading,
    }),
    [
      buildingSummary,
      buildingSummaryIsLoading,
      homeownerAssociation,
      invalidateRelations,
      invalidateUnits,
      pushNotificationSettings,
      relations,
      relationsAreLoading,
      tags,
      tagsAreLoading,
      units,
      unitsAreLoading,
    ],
  );

  return <BuildingContext.Provider value={value}>{children}</BuildingContext.Provider>;
};

export default BuildingContextProvider;
