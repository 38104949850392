import actionTypes from './actionTypes';

export const getTeams = () => ({
  type: actionTypes.GET_TEAMS_REQUESTED,
});

export const getUserTeamInvitations = () => ({
  type: actionTypes.GET_USER_TEAM_INVITATIONS_REQUESTED,
});

export const updateCurrentTeam = (teamId) => ({
  teamId,
  type: actionTypes.UPDATE_CURRENT_TEAM,
});

export const updateCurrentTeamData = (updatedCurrentTeam) => ({
  type: actionTypes.UPDATE_CURRENT_TEAM_DATA,
  updatedCurrentTeam,
});

export const getTags = () => ({
  type: actionTypes.GET_TAGS_REQUESTED,
});

export const getUpdatedTag = (tagId, tagType) => ({
  id: tagId,
  tagType,
  type: actionTypes.GET_SINGLE_TAG_REQUESTED,
});

export const removeTag = (tagId, tagType) => ({
  id: tagId,
  tagType,
  type: actionTypes.REMOVE_TAG,
});

export const getUserTeamSettings = () => ({
  type: actionTypes.GET_USER_TEAM_SETTINGS_REQUESTED,
});

export const getCurrentTeamUser = () => ({
  type: actionTypes.GET_CURRENT_TEAM_USER_REQUESTED,
});

export const removeTeam = (teamId) => ({
  teamId,
  type: actionTypes.REMOVE_TEAM,
});

export const setIsBusy = (isBusy = true) =>
  isBusy
    ? {
        type: actionTypes.SET_IS_BUSY,
      }
    : {
        type: actionTypes.SET_IS_NOT_BUSY,
      };

export const updateCurrentUser = (userId) => ({
  type: actionTypes.UPDATE_CURRENT_USER,
  userId,
});

export const updateContractorSkillDefinition = (contractorSkillDefinitionId) => ({
  contractorSkillDefinitionId,
  type: actionTypes.UPDATE_CONTRACTOR_SKILL_DEFINITIONS_REQUESTED,
});

export const getPolicies = () => ({
  type: actionTypes.GET_POLICY_REQUESTED,
});

export const getUpdatedPolicy = (policyId) => ({
  id: policyId,
  type: actionTypes.GET_SINGLE_POLICY_REQUESTED,
});

export const removePolicy = (policyId) => ({
  id: policyId,
  type: actionTypes.REMOVE_POLICY,
});

export const getPolicyRelation = (policyId, relationId) => ({
  policyId,
  relationId,
  type: actionTypes.GET_OR_UPDATE_POLICY_RELATION_REQUESTED,
});

export const removePolicyRelation = (policyId, relationId) => ({
  policyId,
  relationId,
  type: actionTypes.REMOVE_POLICY_RELATION,
});

export const getFsmaNumbers = () => ({
  type: actionTypes.GET_FSMANUMBER_REQUESTED,
});

export const getUpdatedFsmaNumber = (fsmaNbrId) => ({
  id: fsmaNbrId,
  type: actionTypes.GET_SINGLE_FSMANUMBER_REQUESTED,
});

export const removeFsmaNumber = (fsmaNbrId) => ({
  id: fsmaNbrId,
  type: actionTypes.REMOVE_FSMANUMBER,
});

export const getTeamIntegrations = () => ({
  type: actionTypes.GET_INTEGRATIONS_REQUESTED,
});

export const changeSidebarVisibility = () => ({
  type: actionTypes.CHANGE_SIDEBAR_VISIBILITY,
});

export const changeMobileSidebarVisibility = () => ({
  type: actionTypes.CHANGE_MOBILE_SIDEBAR_VISIBILITY,
});

export const collapseSidebar = () => ({
  type: actionTypes.COLLAPSE_SIDEBAR,
});

/* Batches */

export const getBatches = () => ({
  type: actionTypes.GET_BATCHES_REQUESTED,
});

export const updateBatchData = (batchId, newData) => ({
  batchId,
  newData,
  type: actionTypes.UPDATE_BATCH_DATA,
});
