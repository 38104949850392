import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { useContext, useMemo } from 'react';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import useToggle from '@/shared/hooks/UseToggle';

import AddContractorForm from '../../../AddContractorForm';
import { AssignIcon } from '../../../../../../shared/icons/Icons';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

const AssignContractor = ({ nextStep }) => {
  const { currentCase, ownRelation } = useContext(CaseContext);
  const { t } = useTranslation();

  const assignContractorToggle = useToggle();

  const isCaseOwner = ownRelation.isOwner;

  const contractor = useMemo(
    () => (currentCase ? currentCase.relations.find((relation) => relation.isContractor) : null),
    [currentCase],
  );

  const handleSubmit = () => {
    assignContractorToggle.hide();
    nextStep();
  };

  return (
    <div>
      <Timeline position="right">
        {!!currentCase && !!contractor && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: '4px' }} />
              <TimelineDot sx={{ height: '45px', width: '45px' }} />
              <TimelineConnector sx={{ height: '4px' }} />
            </TimelineSeparator>
            <div className="ms-3" style={{ lineHeight: '16px', margin: 'auto 0px', width: '100%' }}>
              {contractor?.displayName}
              <p className="mt-1">
                {currentCase.assignmentDescription}
                <br />
                {currentCase.assignmentAdditionalInfo}
              </p>
            </div>
          </TimelineItem>
        )}
        {!isCaseOwner && contractor == null && (
          <span className="color-keypoint">{t('noDataToDisplay')}</span>
        )}
        {isCaseOwner && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: '15px' }} />
              <TimelineDot sx={{ height: '45px', width: '45px' }} />
              <TimelineConnector sx={{ height: '15px' }} />
            </TimelineSeparator>
            <div className="ms-3" style={{ margin: 'auto 0px', width: '100%' }}>
              <Button startDecorator={<AssignIcon />} onClick={assignContractorToggle.show}>
                {contractor ? t('assignAnotherContractor') : t('assignContractor')}
              </Button>
            </div>
          </TimelineItem>
        )}
      </Timeline>
      <KpcModal title={t('assignContractor')} toggle={assignContractorToggle}>
        <AddContractorForm onCancel={assignContractorToggle.hide} onSave={handleSubmit} />
      </KpcModal>
    </div>
  );
};

export default AssignContractor;

AssignContractor.propTypes = {
  nextStep: PropTypes.func.isRequired,
};
