import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '../../../shared/api/cases/case';
import { queryIds } from '../../../shared/utils/constants';
import useNotification from '../../../shared/hooks/UseNotification';

const useUpdateRepairPolicy = (callback = () => {}) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(async (data) => caseApi.putRepairPolicy(data), {
    onError: (response) => sendDefaultError(response),
    onSuccess: async (_, { caseId }) => {
      await queryClient.invalidateQueries([queryIds.cases.GET_CASE, caseId]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('case_one') }),
        variant: 'success',
      });
      callback();
    },
  });

  return {
    isUpdatingRepairPolicy: isLoading,
    updateRepairPolicy: mutate,
  };
};

export default useUpdateRepairPolicy;
