import config from '~/frontend/config/config';
import createRestClient from '../restClient';
import { FsmaCompany } from '~/common/types/fsma';

const client = createRestClient(config.baseURL);
const restClient = createRestClient();

const getFsmaCompanies = (): Promise<FsmaCompany[]> => client.get('/fsma/companies');

const getFsmaNumbers = () => restClient.get('fsmanumber/list');

export default {
  getFsmaCompanies,
  getFsmaNumbers,
};
