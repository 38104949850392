import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CasePoliciesCardDetails from './CasePoliciesCardDetails';
import CasePoliciesCardEditForm from './CasePoliciesCardEditForm';
import useNotification from '../../../../shared/hooks/UseNotification';

const propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func.isRequired,
};

const CasePoliciesCard = ({ isEditing = false, setIsEditing }) => {
  const { sendNotification } = useNotification();
  const { t } = useTranslation('common');

  const onCancel = () => {
    setIsEditing(false);
  };

  const onSubmitPolicyData = () => {
    setIsEditing(false);
    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:_case.title') }),
      variant: 'success',
    });
  };

  return isEditing ? (
    <CasePoliciesCardEditForm onCancel={onCancel} onSubmitPolicyData={onSubmitPolicyData} />
  ) : (
    <CasePoliciesCardDetails />
  );
};

export default CasePoliciesCard;

CasePoliciesCard.propTypes = propTypes;
