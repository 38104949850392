import { Chip, Tooltip } from '@mui/joy';
import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';
import { RelationType } from '~/common/enums';
import useCompanyForRelationByCentralCompany from '@/shared/hooks/useCompanyForRelationByCentralCompany';

import { RelationBuildingUnit } from '~/common/types/building/building';
import { TeamIcon } from '@/shared/icons/Icons';

const RelationTypeTeam = RelationType.Team;

type Props = {
  rowData: {
    companyId: Nullable<string>;
    contactId: Nullable<string>;
    displayName: string;
    isClient: boolean;
    isContractor: boolean;
    isOwner: boolean;
    isRequestor: boolean;
    relationReference: Nullable<string>;
    relationType: Nullable<RelationType>;
    teamName: Nullable<string>;
    unitLinks: Nullable<RelationBuildingUnit[]>;
  };
};

const RelationNameCustomCell: React.FC<Props> = ({ rowData }) => {
  const { t } = useTranslation();
  const {
    companyId,
    contactId,
    displayName,
    isClient,
    isContractor,
    isOwner,
    isRequestor,
    relationReference,
    relationType,
    teamName,
    unitLinks,
  } = rowData;

  const linkedUnits = useMemo(() => {
    if (unitLinks && unitLinks.length > 0) {
      return unitLinks.map((item) => item.registrationNumber).join(', ');
    }
    return '';
  }, [unitLinks]);

  const fullDisplayName = useMemo(() => {
    if (relationReference?.length) {
      return `${displayName} ${linkedUnits ? `- ${linkedUnits} ` : ''}(${relationReference})`;
    }
    return `${displayName} ${linkedUnits ? `- ${linkedUnits}` : ''}`;
  }, [displayName, linkedUnits, relationReference]);

  const { existingCrmCompany } = useCompanyForRelationByCentralCompany(
    rowData,
    relationType === RelationTypeTeam && !companyId,
  );

  const shouldDisplayChips = isOwner || isRequestor || isClient || isContractor;

  const renderDisplayName = () => {
    if (contactId) {
      return <Link to={`/contact/${contactId}`}>{fullDisplayName}</Link>;
    }
    if (companyId) {
      return <Link to={`/company/${companyId}`}>{fullDisplayName}</Link>;
    }
    if (relationType === RelationTypeTeam && existingCrmCompany) {
      return <Link to={`/company/${existingCrmCompany.id}`}>{fullDisplayName}</Link>;
    }
    return fullDisplayName;
  };

  return (
    <>
      {shouldDisplayChips && (
        <>
          {isOwner && (
            <Chip className="mr-1" size="sm" variant="soft">
              {t('owner')}
            </Chip>
          )}
          {isRequestor && (
            <Chip className="mr-1" size="sm" variant="soft">
              {t('requestor')}
            </Chip>
          )}
          {isClient && (
            <Chip className="mr-1" size="sm" variant="soft">
              {t('client')}
            </Chip>
          )}
          {isContractor && (
            <Chip className="mr-1" size="sm" variant="soft">
              {t('contractor')}
            </Chip>
          )}
          <br />
        </>
      )}
      {teamName != null && (
        <Tooltip title={teamName}>
          <span>
            <TeamIcon /> &nbsp;
          </span>
        </Tooltip>
      )}
      {renderDisplayName()}
    </>
  );
};

export default memo(RelationNameCustomCell);
