import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import TextCheckbox from '../components/checkboxes/TextCheckbox';

const FormikYesNo = (props) => {
  const { values, setValues, validateField } = useFormikContext();
  const { name, onChangeCallback, ...rest } = props;

  return (
    <TextCheckbox
      {...rest}
      callback={(checked) => {
        setValues({ ...values, [name]: checked });
        validateField(name);
        onChangeCallback(checked);
      }}
      isChecked={values[name]}
    />
  );
};

FormikYesNo.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
};

FormikYesNo.defaultProps = {
  onChangeCallback: () => {},
};

export default FormikYesNo;
