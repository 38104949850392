import { memo } from 'react';
import PropTypes from 'prop-types';

import { isDueDate } from '@/shared/utils/helpers';
import useDayjs from '@/shared/hooks/useDayjs';

import { WarningIcon } from '@/shared/icons/Icons';

const propTypes = {
  toggles: PropTypes.shape({
    tasksToggle: PropTypes.shape({
      value: PropTypes.bool,
    }),
  }),
  value: PropTypes.string,
};

const DueDateCell = ({ value: date, toggles }) => {
  const { formatDate } = useDayjs();
  const isDue = isDueDate(date);
  const isDate = !!date;
  const closedTasksTabActive = toggles && toggles.tasksToggle ? toggles.tasksToggle.value : false;

  return (
    <div className="truncate">
      {isDue && !closedTasksTabActive && <WarningIcon className="pr-2 text-red-500" />}
      {isDate ? formatDate(date) : '-'}
    </div>
  );
};

DueDateCell.propTypes = propTypes;

export default memo(DueDateCell);
