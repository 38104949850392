import { useEffect, useState } from 'react';

const elementRolesToExclude = ['option', 'presentation'];

const useOutsideAlerter = (ref) => {
  const [isActive, setIsActive] = useState(false);
  const [isInside, setIsInside] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!isActive) {
        return;
      }
      if (
        event.target.attributes &&
        'role' in event.target.attributes &&
        elementRolesToExclude.some((role) => role === event.target.attributes.role.value)
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        setIsInside(false);
        setIsActive(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isActive]);

  const setOutsideAlerterActive = (active) => {
    setIsActive(active);
    if (active) {
      setIsInside(true);
    }
  };

  return { isInside, setOutsideAlerterActive };
};

export default useOutsideAlerter;
