import React, { memo } from 'react';

import useDayjs from '@/shared/hooks/useDayjs';

type Props = {
  value: string;
};

const DateCell: React.FC<Props> = ({ value: date }) => {
  const { formatDate } = useDayjs();

  return <div className="truncate">{date ? formatDate(date) : '-'}</div>;
};

export default memo(DateCell);
