import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import SelectBuilding from '../components/select/SelectBuilding';

const FormikBuildingSelector = ({
  name,
  label,
  selectSingle = false,
  callback = () => {},
  buildingsToExclude = [],
  ...rest
}) => {
  const { values, setFieldValue, errors, touched, setTouched } = useFormikContext();

  const [defaultBuildings, setDefaultBuildings] = useState([]);

  useEffect(() => {
    if (!values[name]?.id) {
      return;
    }
    if (selectSingle) {
      setDefaultBuildings([values[name].id ?? null]);
      return;
    }
    setDefaultBuildings(values[name].map((b) => b.id) ?? []);
  }, [values, name, selectSingle]);

  const updateAndCallback = (updated) => {
    setFieldValue(name, updated, true);
    callback(updated);
  };

  const updateBuildings = (buildings) => {
    if (selectSingle) {
      const updated = buildings.length > 0 ? { ...buildings[0] } : null;
      updateAndCallback(updated);
      return;
    }
    updateAndCallback([...buildings]);
  };

  return (
    <SelectBuilding
      addressToSelect={values[name]?.id ? null : values[name]?.addressToSelect}
      buildingsToExclude={buildingsToExclude}
      buildingIdsToSelect={defaultBuildings}
      errorText={touched[name] ? errors[name] : null}
      label={label}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      onBuildingSelectChanged={updateBuildings}
      selectSingle={selectSingle}
      {...rest}
    />
  );
};

FormikBuildingSelector.propTypes = {
  buildingsToExclude: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectSingle: PropTypes.bool,
};

export default FormikBuildingSelector;
