import React from 'react';
import PropTypes from 'prop-types';
import TextCheckbox from '../../checkboxes/TextCheckbox';

const BooleanFilter = ({ callback, yesText, noText, name, defaultValue }) => (
  <div>
    <TextCheckbox
      yesText={yesText}
      noText={noText}
      callback={(checked) => callback(name, checked)}
      isChecked={defaultValue}
    />
  </div>
);

BooleanFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  defaultValue: PropTypes.bool,
};

BooleanFilter.defaultProps = {
  callback: () => {},
  defaultValue: false,
  name: '',
  yesText: '',
  noText: '',
};

export default BooleanFilter;
