import {
  DocumentTemplate,
  LoadWordResponse,
  WordFileSaveRequest,
} from '~/common/types/documentTemplate/documentTemplate';
import config from '@/config/config';
import createRestClient from '../restClient';

const client = createRestClient(config.baseURL);

const postImportWord = (data: { formFile: File }) =>
  client.postForm('documentTemplate/import-word', data);

const getDocumentTemplates = (): Promise<DocumentTemplate[]> => client.get('documentTemplate');

const deleteDocumentTemplates = (data: string[]) => client.delete('documentTemplate', { data });

const loadWordDocument = (id: string): Promise<LoadWordResponse> =>
  client.get(`documentTemplate/load-word/${id}`);

const saveWordDocument = (data: WordFileSaveRequest): Promise<string> =>
  client.post('documentTemplate/save', data);

export default {
  deleteDocumentTemplates,
  getDocumentTemplates,
  loadWordDocument,
  postImportWord,
  saveWordDocument,
};
