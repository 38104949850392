import { Popover, Typography } from '@mui/material';
import { Container } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';

import { stripHtml } from '../utils/helpers';

const LargeTextHoverDisplay = ({ text, displayLength }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  if (!text) return '-';
  const parsedText = stripHtml(text);
  const displayedText = `${parsedText.substring(
    0,
    parsedText.length < displayLength ? parsedText.length : displayLength,
  )}${parsedText.length > displayLength ? '...' : ''}`;

  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        component="div"
        sx={{ fontSize: 'inherit' }}
      >
        {displayedText}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          marginTop: -1,
          pointerEvents: 'none',
        }}
        open={open && parsedText.length > displayLength}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        PaperProps={{
          style: { maxWidth: '40%' },
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <div>
          <Container
            className="large-text-hover-display-popover"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          />
        </div>
      </Popover>
    </>
  );
};

LargeTextHoverDisplay.propTypes = {
  displayLength: PropTypes.number.isRequired,
  text: PropTypes.string,
};

LargeTextHoverDisplay.defaultProps = {
  text: '',
};

export default LargeTextHoverDisplay;
