import {
  Alert,
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';

import fileApi from '@/shared/api/file/file';

import {
  AcceptIcon,
  CancelIcon,
  DownloadIcon,
  InlineSpinner,
  UploadIcon,
  WarningIcon,
} from '@/shared/icons/Icons';

export type TemporaryFile = {
  uri: string;
  name: string;
  blobContainerName: string;
};

const UploadFileModal = () => {
  const { ui, setUi } = useContext(AppContext);
  const [progress, setProgress] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (formData: FormData) => {
      const files = await fileApi.uploadTemporaryFiles({
        data: formData,
        onProgress: setProgress,
      });

      return files;
    },
  });

  const formik = useFormik({
    initialValues: {
      files: {
        main: null,
        temporary: [],
      },
    },
    onSubmit: async (values) => {
      try {
        const formikFiles = values.files.temporary.map(({ file }) => file);

        const formData = new FormData();
        formikFiles.forEach((file) => {
          formData.append('files', file);
        });

        const temporaryFiles = await mutateAsync(formData);

        if (ui.case.toggles.uploadFile.multiple && Array.isArray(temporaryFiles)) {
          setUi({
            payload: [...ui.files.temporary, ...temporaryFiles],
            type: actions.FILES_SET_TEMPORARY_FILES,
          });
        }
        if (!ui.case.toggles.uploadFile.multiple && Array.isArray(temporaryFiles)) {
          setUi({
            payload: temporaryFiles,
            type: actions.FILES_SET_MAIN_FILE,
          });
        }

        enqueueSnackbar(t('uploadSucceeded'));

        setUi({ type: actions.CASE_TOGGLE_UPLOAD_FILE });
      } catch (error) {
        enqueueSnackbar(t('somethingWentWrongPleaseTryAgainLater'), { variant: 'error' });
      }
    },
  });

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    maxFiles: ui.case.toggles.uploadFile.multiple ? 10 : 1,
    noClick: true,
    noKeyboard: true,
    onDrop: async (inputFiles) => {
      const fileWithOptions = inputFiles.map((file) => ({
        file,
        label: file.name,
        value: file.name,
      }));

      formik.setFieldValue('files.temporary', fileWithOptions);
    },
  });

  const { values, setFieldValue } = formik;

  return (
    <Modal
      open={ui.case.toggles.uploadFile.modal}
      onClose={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_FILE })}
    >
      <ModalDialog
        className={classNames('w-2/3', {
          'bg-green-50': isDragAccept,
          'bg-red-50': isDragReject,
        })}
      >
        <ModalClose />
        <Typography startDecorator={<UploadIcon />} className="font-medium">
          {t('uploadFiles')}
        </Typography>
        <Divider />

        <div
          {...getRootProps({
            className: classNames('mt-2 border border-gray-300 border-dashed p-4 rounded-lg'),
          })}
        >
          <div className="pb-4 text-center">
            <input {...getInputProps()} />
            <Tooltip title="Open file dialog">
              <IconButton variant="plain" className="p-2" onClick={open}>
                {!isDragReject && !isDragAccept && <DownloadIcon className="fa-2xl" />}
                {isDragReject && <CancelIcon className="fa-2xl text-red-600" />}
                {isDragAccept && <AcceptIcon className="fa-2xl text-green-600" />}
              </IconButton>
            </Tooltip>
            {!isDragReject && (
              <p className="justify-center pt-2">{t('_import.types.policy.helperText')}</p>
            )}
            {isDragReject && (
              <p className={classNames('text-bold justify-center pt-2 text-red-600')}>
                {t('uploadLimit', {
                  count: ui.case.toggles.uploadFile.multiple ? 10 : 1,
                })}
              </p>
            )}
          </div>

          {!values.files.temporary?.length && (
            <Alert
              className="mb-4 text-xs"
              color="warning"
              size="sm"
              startDecorator={<WarningIcon />}
              variant="soft"
            >
              {t('uploadLimit', { count: ui.case.toggles.uploadFile.multiple ? 10 : 1 })}
            </Alert>
          )}

          <p className="text-xs">{t('uploadModalDescription')}</p>

          <form onSubmit={formik.handleSubmit}>
            <div className="mt-4 space-y-4">
              <FormControl>
                <FormLabel required>{t('file_one')}</FormLabel>
                <Autocomplete
                  multiple
                  placeholder={t('file_other')}
                  disabled={!values.files.temporary?.length}
                  options={values.files.temporary || []}
                  value={values.files.temporary || []}
                  onChange={(_e, updatedFileOptions) => {
                    setFieldValue('files', updatedFileOptions);
                  }}
                />
              </FormControl>

              {isLoading && (
                <div>
                  <LinearProgress size="sm" determinate value={progress} />
                  <span className="text-xxs"> {`${t('app.status.isLoading')} ${progress}%`}</span>
                </div>
              )}

              <div className="flex justify-end space-x-2">
                <Button
                  color="neutral"
                  variant="outlined"
                  onClick={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_FILE })}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  disabled={isLoading}
                  startDecorator={isLoading && <InlineSpinner />}
                  type="submit"
                >
                  {t('buttons.submit')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ModalDialog>
    </Modal>
  );
};
export default UploadFileModal;
