import { Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DamageType } from '~/common/enums';
import { RequestRepairInKindRequest } from '~/common/types/case/caseRequests';

import { useDocumentManager } from './hooks/useManageDocuments';
import { useGeneralInformationManager } from './hooks/useManageGeneralInformation';
import useRequestRepairInKind from '@/mutations/case/useRequestRepairInKind';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  InlineSpinner,
  NextIcon,
  PreviousIcon,
  RepairCaseIcon,
  SaveIcon,
} from '@/shared/icons/Icons';
import DocumentStep from './components/DocumentStep';
import GeneralInformationStep from './components/GeneralInformationStep';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

enum RequestRikStep {
  GeneralInformation = 0,
  Documents = 1,
}

type Props = {
  toggle: UseToggle<string>;
};

const RequestRIKModal: React.FC<Props> = ({ toggle }) => {
  const [activeStep, setActiveStep] = useState<RequestRikStep>(RequestRikStep.GeneralInformation);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { requestRepairInKind, repairInKindCaseId, isRequestingRepairInKind } =
    useRequestRepairInKind();

  const generalInformationManager = useGeneralInformationManager(toggle.state);
  const documentManager = useDocumentManager(toggle.state);

  const validateGeneralInformationAndMoveNext = () => {
    setActiveStep(RequestRikStep.Documents);
    generalInformationManager.validate();
  };

  const moveToGeneralInformationStep = () => {
    setActiveStep(RequestRikStep.GeneralInformation);
  };

  useEffect(() => {
    if (!repairInKindCaseId) {
      return;
    }
    navigate(`/case/${repairInKindCaseId}`);
    toggle.hide();
  }, [repairInKindCaseId]);

  const handleSave = () => {
    if (isRequestingRepairInKind) {
      return;
    }

    if (generalInformationManager.isValid === null) {
      const isValid = generalInformationManager.validate();
      if (!isValid) {
        return;
      }
    }
    const data: RequestRepairInKindRequest = {
      caseFileIds: documentManager.get.caseFiles.map((file) => file.value),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      caseId: toggle.state!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      damageType: generalInformationManager.get.damageType!.value as DamageType,
      description: generalInformationManager.get.description,
      files: documentManager.get.uploadedFiles,
      insurerCentralCompanyId: generalInformationManager.get.insurerCentralCompany?.value || null,
      remark: generalInformationManager.get.remark,
    };
    requestRepairInKind(data);
  };

  return (
    <KpcModal toggle={toggle} title={t('requestRepairInKind.title')} icon={<RepairCaseIcon />}>
      <Stepper nonLinear alternativeLabel activeStep={activeStep} className="mb-4">
        <Step completed={!!generalInformationManager.isValid}>
          <StepLabel error={generalInformationManager.isValid === false}>
            {t('generalInformation')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('documents')}</StepLabel>
        </Step>
      </Stepper>
      <div>
        <GeneralInformationStep
          active={activeStep === RequestRikStep.GeneralInformation}
          generalInformationManager={generalInformationManager}
        />
        <DocumentStep
          active={activeStep === RequestRikStep.Documents}
          documentManager={documentManager}
        />
      </div>
      <div className="flex items-center justify-center">
        {activeStep === RequestRikStep.GeneralInformation && (
          <Button
            startDecorator={
              <i>
                <NextIcon />
              </i>
            }
            onClick={validateGeneralInformationAndMoveNext}
          >
            {t('next')}
          </Button>
        )}
        {activeStep === RequestRikStep.Documents && (
          <>
            <Button
              startDecorator={
                <i>
                  <PreviousIcon />
                </i>
              }
              onClick={moveToGeneralInformationStep}
            >
              {t('previous')}
            </Button>
            <Button
              className="ml-2"
              disabled={generalInformationManager.isValid === false || isRequestingRepairInKind}
              startDecorator={<i>{isRequestingRepairInKind ? <InlineSpinner /> : <SaveIcon />}</i>}
              onClick={handleSave}
            >
              {t('save')}
            </Button>
          </>
        )}
      </div>
    </KpcModal>
  );
};

export default RequestRIKModal;
