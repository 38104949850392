import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CancelIcon, LoadingIcon, SaveIcon } from '@/shared/icons/Icons';

type ModalFooterProps = {
  disableSubmit?: boolean;
  isSubmitting?: boolean;
  labels?: {
    cancel?: string;
    submit?: string;
  };
  onCancel?: () => void;
  onSubmit?: () => void;
  submitButtonType?: 'button' | 'submit';
};

const ModalFooter: React.FC<ModalFooterProps> = ({
  disableSubmit,
  isSubmitting,
  labels,
  onCancel,
  onSubmit,
  submitButtonType = 'submit',
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 flex justify-end gap-4">
      <Button startDecorator={<CancelIcon />} color="neutral" variant="outlined" onClick={onCancel}>
        {labels?.cancel || t('cancel')}
      </Button>
      <Button
        disabled={isSubmitting || disableSubmit}
        startDecorator={isSubmitting ? <LoadingIcon /> : <SaveIcon />}
        type={submitButtonType}
        onClick={onSubmit}
      >
        {labels?.submit || t('save')}
      </Button>
    </div>
  );
};

export default ModalFooter;
