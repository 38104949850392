import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CancelIcon, InlineSpinner, SaveIcon } from '@/shared/icons/Icons';
import AddEditCaseNoteFormBody from './AddEditCaseNoteFormBody';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import Row from '@/shared/components/2.0/layout/Row';

import { useAddNote, useUpdateNote } from '@/mutations';
import { CaseContext } from '../CaseContext';
import FormikCaseEntityOptions from '@/shared/components/2.0/formik/FormikCaseEntityOptions';
import NoteItemPropTypes from '@/shared/prop-types/NoteItemPropTypes';
import TogglePropTypes from '@/shared/prop-types/TogglePropTypes';

const propTypes = {
  note: NoteItemPropTypes,
  toggle: TogglePropTypes.isRequired,
};

const AddEditCaseNoteModal = ({ note = null, toggle }) => {
  const { currentCase, canEditCase } = useContext(CaseContext);
  const canEdit = (canEditCase && note?.createdByCurrentTeam) || true;
  const { t } = useTranslation();

  const schema = yup.object().shape({
    message: yup.string().nullable().required(t('errors:fieldIsRequired')),
    tasks: yup
      .array()
      .nullable()
      .test('taskDataShouldBeValid', (value) => (value && value[0] ? value[0].isValid : true)),
  });

  const initialValues = {
    message: note?.note || null,
    tasks: null,
  };

  const { addNote, isAddingNote } = useAddNote(currentCase.id);
  const { updateNote, isUpdatingNote } = useUpdateNote(currentCase.id);

  const onSubmit = (data) => {
    if (!canEdit) {
      return;
    }
    if (isAddingNote || isUpdatingNote) {
      return;
    }
    const addUpdateNote = !note ? addNote : updateNote;
    addUpdateNote(
      {
        ...data,
        isDetailed: true,
        noteId: note?.id,
      },
      {
        onSuccess: () => toggle.hide(),
      },
    );
  };

  return (
    <KpcModal
      className="w-1/2"
      disableEnforceFocus
      title={!note ? t('addDetailedNote') : t('updateDetailedNote')}
      toggle={toggle}
    >
      <Formik onSubmit={onSubmit} validationSchema={schema} initialValues={initialValues}>
        <Form className="flex min-h-0 flex-col overflow-auto">
          <div className="overflow-auto">
            <Row className="flex h-full w-full flex-col px-4 py-2">
              <AddEditCaseNoteFormBody disabled={!canEdit} />
            </Row>
            {canEdit && (
              <Row className="flex w-full flex-col px-4">
                <FormikCaseEntityOptions entity={note} />
              </Row>
            )}
          </div>
          {canEdit && (
            <Row className="justify-end pt-2">
              <Button
                startDecorator={<CancelIcon className="pr-2" />}
                size="sm"
                color="neutral"
                variant="outlined"
                onClick={toggle.hide}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                startDecorator={isAddingNote || isUpdatingNote ? <InlineSpinner /> : <SaveIcon />}
                disabled={isAddingNote || isUpdatingNote}
              >
                {t('buttons.save')}
              </Button>
            </Row>
          )}
        </Form>
      </Formik>
    </KpcModal>
  );
};

export default AddEditCaseNoteModal;

AddEditCaseNoteModal.propTypes = propTypes;
