import { Box, Chip, TextField } from '@mui/material';
import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InsurancePolicyIcon } from '../../icons/Icons';
import PolicySelectorList from './Components/PolicySelectorList';
import useOutsideAlerter from '../../hooks/UseOutsideAlerter';

import { decapitalize } from '../../utils/helpers';
import PolicyStatus from '../../enums/PolicyStatus';
import ReferencePropType from '../../prop-types/ReferencePropType';
import useDayjs from '@/shared/hooks/useDayjs';
import useGetLinkedPolicies from '@/queries/policies/useGetLinkedPolicies';
import { useGetPolicies } from '@/queries';

const SelectPolicy = ({
  address,
  alwaysOpen,
  buildingId,
  disablePortal,
  defaultPolicyIdsToSelect,
  enablePolicyNumberInput,
  isAddress,
  label,
  onBlur,
  onPolicySelectChanged,
  onSuggestedPoliciesFound,
  policyIdsToSelect,
  policyNumbersToPrefill,
  popperContainer,
  selectSingle,
}) => {
  const [selectList, setSelectList] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [query, setQuery] = useState('');
  const anchorRef = useRef();
  const { t } = useTranslation(['common', 'insurancePolicy']);
  const { dayjs } = useDayjs();
  const selectorMenuRef = React.useRef();
  const { isInside, setOutsideAlerterActive } = useOutsideAlerter(selectorMenuRef);
  const [isAddingPolicyNumber, setIsAddingPolicyNumber] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [suggestedPolicies, setSuggestedPolicies] = useState([]);

  const { policies: unfilteredPolicies, policiesIsLoading } = useGetPolicies();
  const policies = useMemo(
    () =>
      unfilteredPolicies.filter((policy) => {
        const { startDate, endDate } = policy;
        return !startDate || !endDate || dayjs().isBetween(startDate, endDate);
      }),
    [dayjs, unfilteredPolicies],
  );

  const isActivePolicy = (policy) =>
    policy.status !== PolicyStatus.terminated &&
    policy.status !== PolicyStatus.cancelled &&
    policy.status !== PolicyStatus.suspended;
  const collapseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
    setQuery('');

    if (isInside) {
      onBlur();
    }
  }, [isInside, onBlur, setOutsideAlerterActive]);

  useEffect(() => {
    onSuggestedPoliciesFound(suggestedPolicies);
  }, [suggestedPolicies, onSuggestedPoliciesFound]);

  useEffect(() => {
    if (policiesIsLoading) {
      return;
    }
    setSelectList(
      policies
        .filter((p) => isActivePolicy(p))
        .map(({ id, policyNumber, insurancePolicyType }) => ({
          id,
          insurancePolicyType,
          isPolicyNumber: false,
          policyNumber,
        })),
    );
  }, [policies, policiesIsLoading]);

  const { linkedPolicies, linkedPoliciesAreLoading } = useGetLinkedPolicies({
    ...address,
    ...(isAddress ? {} : { buildingId }),
  });

  useEffect(() => {
    if (linkedPoliciesAreLoading) {
      return;
    }
    setSuggestedPolicies(
      linkedPolicies
        .filter((p) => isActivePolicy(p))
        .map(({ id, policyNumber, insurancePolicyType }) => ({
          id,
          insurancePolicyType,
          policyNumber,
        })),
    );
  }, [linkedPolicies]);

  const filteredPolicies = useMemo(() => {
    if (!query || query.length === 0) {
      return selectList.filter((p) => !suggestedPolicies.some((sp) => p.id === sp.id));
    }
    return selectList
      .filter((p) => !suggestedPolicies.some((sp) => p.id === sp.id))
      .filter((p) => p.policyNumber.toLowerCase().includes(query));
  }, [selectList, query, suggestedPolicies]);

  const filteredSuggestedPolicies = useMemo(() => {
    if (!query || query.length === 0) {
      return selectList.filter((p) => suggestedPolicies.some((sp) => p.id === sp.id));
    }
    return selectList
      .filter((p) => suggestedPolicies.some((sp) => p.id === sp.id))
      .filter((p) => p.policyNumber.toLowerCase().includes(query));
  }, [selectList, query, suggestedPolicies]);

  const handleSelectOrDeselect = useCallback(
    (policy) => {
      setQuery('');
      let currentSelectList = selectList;
      let currentSelectedItems = selectedPolicies;
      const currentIsAlreadySelected = currentSelectedItems.some((i) => i.id === policy.id);

      if (currentIsAlreadySelected) {
        if (!selectSingle) {
          currentSelectedItems = currentSelectedItems.filter((i) => i.id !== policy.id);
          currentSelectList = [...currentSelectList, policy];
        } else {
          currentSelectedItems = [];
          currentSelectList = [...currentSelectList, policy];
        }
        setSelectedPolicies(
          currentSelectedItems.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
        setSelectList(
          currentSelectList.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
        onPolicySelectChanged(
          currentSelectedItems.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
      } else {
        if (!selectSingle) {
          currentSelectedItems = [...currentSelectedItems, policy];
          currentSelectList = currentSelectList.filter((i) => i.id !== policy.id);
        } else {
          if (currentSelectedItems.length > 0) {
            const [currentlySelected] = currentSelectedItems;
            currentSelectList = [...currentSelectList, currentlySelected];
          }
          currentSelectedItems = [policy];
          currentSelectList = currentSelectList.filter((i) => i.id !== policy.id);
          collapseSelect();
        }
        setSelectedPolicies(
          currentSelectedItems.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
        setSelectList(
          currentSelectList.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
        onPolicySelectChanged(
          currentSelectedItems.sort((a, b) => a.policyNumber.localeCompare(b.policyNumber)),
        );
      }
    },
    [collapseSelect, selectList, onPolicySelectChanged, selectSingle, selectedPolicies],
  );

  const [policyPreSelectInited, setPolicyPreSelectInited] = useState(false);
  useEffect(() => {
    if (!policyPreSelectInited && defaultPolicyIdsToSelect.length > 0 && selectList.length > 0) {
      if (selectedPolicies === null || selectedPolicies.length === 0) {
        setSelectedPolicies([...selectList.filter((s) => defaultPolicyIdsToSelect.includes(s.id))]);
      } else {
        setSelectedPolicies(selectedPolicies);
      }
      setSelectList((prev) => prev.filter((p) => !defaultPolicyIdsToSelect.includes(p.id)));
      setPolicyPreSelectInited(true);
    } else if (policyIdsToSelect && policyIdsToSelect !== selectedPolicies) {
      setSelectedPolicies(policyIdsToSelect);
    }
  }, [
    defaultPolicyIdsToSelect,
    policyPreSelectInited,
    selectList,
    handleSelectOrDeselect,
    selectedPolicies,
    policyIdsToSelect,
  ]);

  const [policyPreSelectPolicyNumbersInited, setPolicyPreSelectPolicyNumbersInited] =
    useState(false);

  useEffect(() => {
    if (!policyPreSelectPolicyNumbersInited && policyNumbersToPrefill.length > 0) {
      if (selectedPolicies === null || selectedPolicies.length === 0) {
        setSelectedPolicies([...policyNumbersToPrefill]);
      } else {
        setSelectedPolicies(selectedPolicies);
      }
      setPolicyPreSelectPolicyNumbersInited(true);
    }
  }, [
    policyNumbersToPrefill,
    policyPreSelectPolicyNumbersInited,
    handleSelectOrDeselect,
    selectedPolicies,
  ]);

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      collapseSelect();
    }
  };

  const handleBlur = () => {
    if (isInside) {
      return;
    }
    onBlur();
    setOutsideAlerterActive(false);
  };

  const getSelectedChips = useMemo(() => {
    let policyChips = [];
    if (selectedPolicies && selectedPolicies.length) {
      policyChips = selectedPolicies.map((policy) => (
        <Chip
          icon={<InsurancePolicyIcon />}
          key={policy.id}
          label={`${policy.policyNumber}${
            policy.insurancePolicyType
              ? ` - ${t(`insurancePolicy:${decapitalize(policy.insurancePolicyType)}`)}`
              : ''
          } `}
          onDelete={() => handleSelectOrDeselect(policy)}
        />
      ));
    }

    return policyChips && policyChips.length > 0 && [policyChips];
  }, [selectedPolicies, t, handleSelectOrDeselect]);

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });

  return (
    <ClickAwayListener onClickAway={() => collapseSelect()}>
      <div>
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <TextField
            className={textFieldClasses}
            size="small"
            id="input-with-sx"
            fullWidth
            autoComplete="off"
            variant="standard"
            label={`${label || t('common:search.label')}`}
            ref={anchorRef}
            onChange={({ target }) => setQuery(target.value.toLowerCase())}
            value={query}
            InputProps={{
              startAdornment: getSelectedChips,
            }}
            onBlur={() => handleBlur()}
            onKeyDown={handleKeyDown}
            onFocus={() => {
              setIsSelectOpen(true);
              setOutsideAlerterActive(true);
            }}
          />
        </Box>
        {alwaysOpen ? (
          <PolicySelectorList
            filteredPolicies={filteredPolicies}
            suggestedPolicies={filteredSuggestedPolicies}
            handleSelectOrDeselect={handleSelectOrDeselect}
            selectorMenuRef={selectorMenuRef}
            isAddingPolicyNumber={isAddingPolicyNumber}
            setIsAddingPolicyNumber={setIsAddingPolicyNumber}
            enablePolicyNumberInput={enablePolicyNumberInput}
          />
        ) : (
          <PopperUnstyled
            disablePortal={disablePortal}
            container={popperContainer?.current}
            open={isSelectOpen}
            anchorEl={anchorRef?.current}
            className="custom__selector-wrap"
            placement="bottom-start"
          >
            <PolicySelectorList
              filteredPolicies={filteredPolicies}
              suggestedPolicies={filteredSuggestedPolicies}
              handleSelectOrDeselect={handleSelectOrDeselect}
              selectorMenuRef={selectorMenuRef}
              isAddingPolicyNumber={isAddingPolicyNumber}
              setIsAddingPolicyNumber={setIsAddingPolicyNumber}
              enablePolicyNumberInput={enablePolicyNumberInput}
            />
          </PopperUnstyled>
        )}
      </div>
    </ClickAwayListener>
  );
};

SelectPolicy.propTypes = {
  address: PropTypes.shape({
    addressLine: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  alwaysOpen: PropTypes.bool,
  buildingId: PropTypes.string,
  defaultPolicyIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  disablePortal: PropTypes.bool,
  enablePolicyNumberInput: PropTypes.bool,
  isAddress: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onPolicySelectChanged: PropTypes.func.isRequired,
  onSuggestedPoliciesFound: PropTypes.func,
  policyIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  policyNumbersToPrefill: PropTypes.arrayOf(PropTypes.shape({})),
  popperContainer: ReferencePropType,
  selectSingle: PropTypes.bool,
};

SelectPolicy.defaultProps = {
  address: null,
  alwaysOpen: false,
  buildingId: null,
  defaultPolicyIdsToSelect: [],
  disablePortal: true,
  enablePolicyNumberInput: true,
  isAddress: false,
  label: '',
  onBlur: () => {},
  onSuggestedPoliciesFound: () => {},
  policyIdsToSelect: null,
  policyNumbersToPrefill: [],
  popperContainer: null,
  selectSingle: false,
};

export default SelectPolicy;
