import {
  CreateUserResponsibilityRequest,
  UpdateUserResponsibilityRequest,
} from '~/common/types/userResponsibility/userResponsibilityRequests';

import { UserResponsibility } from '@/types/userResponsibilities';

import config from '../../../config/config';
import createRestClient from '../restClient';

const restClient = createRestClient(config.baseURL);

const getUserResponsibilities: () => Promise<UserResponsibility[]> = () =>
  restClient.get('/userresponsibility');

const createUserResponsibility = (data: CreateUserResponsibilityRequest) =>
  restClient.post('/userresponsibility', data);

const updateUserResponsibility = (data: UpdateUserResponsibilityRequest) =>
  restClient.put('/userresponsibility', data);

const deleteUserResponsibility = (id: string) => restClient.delete(`userResponsibility/${id}`);

export default {
  createUserResponsibility,
  deleteUserResponsibility,
  getUserResponsibilities,
  updateUserResponsibility,
};
