import { Form, Formik, FormikValues } from 'formik';
import { AnyObjectSchema } from 'yup';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import { CancelIcon, InlineSpinner, ValidIcon } from '@/shared/icons/Icons';
import Row from '@/shared/components/2.0/layout/Row';
import SidebarModal from '@/shared/components/2.0/layout/SidebarModal';

type Props<V extends FormikValues, T> = {
  allowEnterSave?: boolean;
  children?: React.ReactNode;
  className?: string;
  description?: string;
  disableActions?: boolean;
  disableEnforceFocus?: boolean;
  enableReinitialize?: boolean;
  handleSubmit: (values: V) => void;
  icon?: React.ReactNode;
  initialValues?: V;
  isDelete?: boolean;
  isMutating?: boolean;
  isUnlink?: boolean;
  onClose?: () => void;
  size?: 'lg' | 'md' | 'sm' | 'xl';
  title: string;
  extraInfo?: string;
  toggle: UseToggle<T>;
  validationSchema?: AnyObjectSchema;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  disableSubmitWhenInvalid?: boolean;
};

const FormSidebarModal = <V extends FormikValues, T>({
  allowEnterSave = true,
  children,
  className,
  description = '',
  disableActions = false,
  disableEnforceFocus = false,
  disableSubmitWhenInvalid = false,
  enableReinitialize = false,
  handleSubmit,
  icon,
  initialValues = {} as V,
  isDelete = false,
  isMutating = false,
  isUnlink = false,
  onClose,
  size = 'sm',
  title = 'Form Modal title',
  extraInfo,
  toggle,
  validationSchema = undefined,
  validateOnChange = false,
  validateOnMount = false,
}: Props<V, T>) => {
  const { t } = useTranslation();

  const handleClose = () => {
    toggle.hide();

    if (onClose) {
      onClose();
    }
  };

  return (
    <SidebarModal
      className={className}
      description={description}
      disableEnforceFocus={disableEnforceFocus}
      icon={icon}
      size={size}
      title={title}
      toggle={toggle}
      onClose={handleClose}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={validateOnChange}
        validateOnMount={validateOnMount}
      >
        {({ isValid }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !allowEnterSave) {
                e.preventDefault();
              }
            }}
            className="flex flex-col gap-2"
          >
            {children}
            {!disableActions && (
              <Row className="flex items-center justify-end pt-2">
                <p className="pr-2 text-sm">{extraInfo}</p>
                <Button size="sm" color="neutral" variant="outlined" onClick={handleClose}>
                  <CancelIcon className="pr-2" /> {t('buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  startDecorator={isMutating ? <InlineSpinner /> : <ValidIcon />}
                  disabled={isMutating || (!isValid && disableSubmitWhenInvalid)}
                  color={isDelete || isUnlink ? 'danger' : 'primary'}
                >
                  {isDelete && t('buttons.delete')}
                  {isUnlink && t('buttons.unlink')}
                  {!isDelete && !isUnlink && t('buttons.save')}
                </Button>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </SidebarModal>
  );
};

export default FormSidebarModal;
