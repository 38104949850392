import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../shared/api/ApiService';
import actionTypes from '../../actions/actionTypes';

function* handleGetUserTeamSettings() {
  const response = yield call(API.fetchGetUserTeamSettingsForTeam);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_USER_TEAM_SETTINGS_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data?.title);
    return;
  }

  yield put({
    type: actionTypes.GET_USER_TEAM_SETTINGS_SUCCESS,
    settings: response.data,
  });
}

function* handleGetUserTeamInvitations() {
  const response = yield call(API.fetchGetUserTeamInvitations);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_USER_TEAM_INVITATIONS_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data?.title);
    return;
  }

  yield put({
    type: actionTypes.GET_USER_TEAM_INVITATIONS_SUCCESS,
    invitations: response.data,
  });
}

function* handleGetCurrentTeamUser() {
  const currentTeamUserResponse = yield call(API.fetchGetCurrentTeamUser);
  const settingsResponse = yield call(API.fetchGetUserTeamSettingsForTeam);

  if (currentTeamUserResponse.serviceError != null || settingsResponse.serviceError != null) {
    yield put({ type: actionTypes.GET_CURRENT_TEAM_USER_FAILED });

    // eslint-disable-next-line no-console
    console.error(
      currentTeamUserResponse.serviceError?.message ?? settingsResponse.serviceError.message,
    );
    return;
  }

  if (currentTeamUserResponse.status !== 200 || settingsResponse.status !== 200) {
    yield put({ type: actionTypes.GET_CURRENT_TEAM_USER_FAILED });

    // eslint-disable-next-line no-console
    console.error(currentTeamUserResponse.data?.title ?? settingsResponse.data?.title);
    return;
  }

  yield put({
    type: actionTypes.GET_CURRENT_TEAM_USER_SUCCESS,
    currentTeamUser: currentTeamUserResponse.data,
    settings: settingsResponse.data,
  });
}

function* watcherUserSaga() {
  yield takeLatest(actionTypes.GET_USER_TEAM_SETTINGS_REQUESTED, handleGetUserTeamSettings);
  yield takeLatest(actionTypes.GET_USER_TEAM_INVITATIONS_REQUESTED, handleGetUserTeamInvitations);
  yield takeLatest(actionTypes.GET_CURRENT_TEAM_USER_REQUESTED, handleGetCurrentTeamUser);
}

export default watcherUserSaga;
