import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TagType from '../../../enums/TagType';

import BooleanFilter from './BooleanFilter';
import BuildingFilter from './BuildingFilter';
import CaseFilter from './CaseFilter';
import CaseStatusFilter from './CaseStatusFilter';
import CaseTypeFilter from './CaseTypeFilter';
import ClassificationFilter from './ClassificationFilter';
import CompanyFilter from './CompanyFilter';
import ContactFilter from './ContactFilter';
import CustomEnumSelectFilter from './CustomEnumSelectFilter';
import FilterType from './FilterType';
import IconButton from '../../buttons/IconButton';
import InsurancePolicyTpeFilter from './InsurancePolicyTypeFilter';
import StringFilterOld from './StringFilterOld';
import StringOptionsFilter from './StringOptionsFilter';
import TagFilter from './TagFilter';
import TagTypeFilter from './TagTypeFilter';
import TeamFilter from './TeamFilter';
import TeamUserFilter from './TeamUserFilter';

const FilterWrapper = ({
  callback,
  cachedFiltersInitializedCallback,
  filters,
  hideFilter,
  filterLocalStorageKey,
  predicates,
}) => {
  const saveFilters = useSelector((state) => state.user?.settings?.saveFilters) ?? false;

  const getCurrentLsValues = useCallback(
    () => JSON.parse(localStorage.getItem(filterLocalStorageKey)) ?? {},
    [filterLocalStorageKey],
  );

  const [cachedValuesRendered, setCachedValuesRenderd] = useState(false);
  useEffect(() => {
    if (!saveFilters || getCurrentLsValues() == null) {
      return;
    }
    cachedFiltersInitializedCallback(getCurrentLsValues());
    setCachedValuesRenderd(true);
  }, [saveFilters, getCurrentLsValues, cachedFiltersInitializedCallback]);

  const callbackHandler = useCallback(
    (name, values) => {
      if (saveFilters) {
        // Write to LS
        const newValues = { ...getCurrentLsValues(), [name]: values };
        localStorage.setItem(filterLocalStorageKey, JSON.stringify(newValues));
      }
      // Callback
      callback(name, values);
    },
    [callback, filterLocalStorageKey, getCurrentLsValues, saveFilters],
  );

  const tryGetCurrentValue = useCallback(
    (name) => {
      if (!saveFilters) {
        return null;
      }
      const value = getCurrentLsValues()[name];
      if (value == null) {
        return null;
      }
      return value;
    },
    [getCurrentLsValues, saveFilters],
  );

  const getFilter = useCallback(
    (filter) => {
      const { name, type, label, visibilityDependentOnOtherFilter, valuesToSelect } = filter;
      if (
        visibilityDependentOnOtherFilter != null &&
        typeof visibilityDependentOnOtherFilter === 'function'
      ) {
        const isVisible = visibilityDependentOnOtherFilter(predicates);
        if (!isVisible) {
          return null;
        }
      }
      let currentValue = null;
      if (saveFilters && !cachedValuesRendered) {
        currentValue = tryGetCurrentValue(name);
      }
      if (currentValue == null && predicates.some((pr) => pr.name === name)) {
        const currentPredicate = predicates.find((pr) => pr.name === name);
        currentValue = currentPredicate.value;
      }
      switch (type) {
        case FilterType.String:
          return (
            <StringFilterOld
              key={name}
              callback={callbackHandler}
              name={name}
              label={label}
              defaultValue={currentValue ?? ''}
            />
          );
        case FilterType.Bool: {
          const { yesText, noText, defaultValue } = filter;
          return (
            <BooleanFilter
              key={name}
              name={name}
              callback={callbackHandler}
              yesText={yesText}
              noText={noText}
              defaultValue={currentValue ?? defaultValue}
            />
          );
        }
        case FilterType.CaseStatus: {
          const { options } = filter;
          return (
            <CaseStatusFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? []}
              options={options}
            />
          );
        }
        case FilterType.CaseType: {
          return (
            <CaseTypeFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? []}
            />
          );
        }
        case FilterType.Classification: {
          return (
            <ClassificationFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.InsurancePolicyType: {
          return (
            <InsurancePolicyTpeFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? []}
              valuesToSelect={valuesToSelect}
            />
          );
        }
        case FilterType.Contact: {
          return (
            <ContactFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.Company: {
          return (
            <CompanyFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.Team: {
          return (
            <TeamFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.Building: {
          return (
            <BuildingFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.Tag: {
          const { tagType } = filter;
          return (
            <TagFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? []}
              tagType={tagType}
            />
          );
        }
        case FilterType.TeamUser: {
          return (
            <TeamUserFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.CustomEnumSelect: {
          const { options } = filter;
          return (
            <CustomEnumSelectFilter
              key={name}
              name={name}
              label={label}
              callback={callbackHandler}
              defaultValue={currentValue ?? []}
              options={options}
            />
          );
        }
        case FilterType.StringOptions: {
          const { options, defaultValue, multiple } = filter;
          return (
            <StringOptionsFilter
              key={name}
              name={name}
              options={options}
              label={label}
              callback={callbackHandler}
              defaultValue={currentValue ?? defaultValue ?? []}
              multiple={multiple}
            />
          );
        }
        case FilterType.Case: {
          return (
            <CaseFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        case FilterType.TagType: {
          return (
            <TagTypeFilter
              key={name}
              name={name}
              callback={callbackHandler}
              defaultValue={currentValue ?? filter.defaultValue}
              label={label}
            />
          );
        }
        default:
          return null;
      }
    },
    [callbackHandler, saveFilters, tryGetCurrentValue, predicates, cachedValuesRendered],
  );

  return (
    <div className="w-100">
      <Box
        sx={{
          position: 'absolute',
          right: 10,
          zIndex: 99,
        }}
      >
        <IconButton icon="chevron-up" size="2x" onClick={hideFilter} />
      </Box>
      <Row>
        {filters &&
          filters.map((filter) => (
            <Col key={filter.name} md={filter.colSize ?? 12} className="mb-3">
              {getFilter(filter)}
            </Col>
          ))}
      </Row>
    </div>
  );
};

FilterWrapper.propTypes = {
  cachedFiltersInitializedCallback: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  filterLocalStorageKey: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      colSize: PropTypes.number,
      defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string]),
      label: PropTypes.string,
      name: PropTypes.string,
      noText: PropTypes.string,
      predicate: PropTypes.func,
      tagType: PropTypes.oneOf([TagType.Building, TagType.Company, TagType.Contact]),
      type: PropTypes.oneOf([
        FilterType.Bool,
        FilterType.Case,
        FilterType.CaseStatus,
        FilterType.CaseType,
        FilterType.Contact,
        FilterType.Company,
        FilterType.Building,
        FilterType.CustomEnumSelect,
        FilterType.InsurancePolicyType,
        FilterType.String,
        FilterType.StringOptions,
        FilterType.Tag,
        FilterType.Team,
        FilterType.TeamUser,
      ]),
      yesText: PropTypes.string,
    }),
  ).isRequired,
  hideFilter: PropTypes.func.isRequired,
  predicates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      predicate: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
    }),
  ).isRequired,
};

export default FilterWrapper;
