import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const { VITE_APPLICATION_INSIGHTS_CONNECTION_STRING } = import.meta.env;

const reactPlugin = new ReactPlugin();
const appInsights = VITE_APPLICATION_INSIGHTS_CONNECTION_STRING
  ? new ApplicationInsights({
      config: {
        connectionString: VITE_APPLICATION_INSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    })
  : null;

if (appInsights) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { appInsights, reactPlugin };
