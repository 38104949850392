import commonEN from './en/common.json';
import commonFR from './fr/common.json';
import commonNL from './nl/common.json';
import errorsEN from './en/errors.json';
import errorsFR from './fr/errors.json';
import errorsNL from './nl/errors.json';
import financeEN from './en/finance.json';
import financeFR from './fr/finance.json';
import financeNL from './nl/finance.json';
import generalLedgerAccountEN from './en/generalLedgerAccount.json';
import generalLedgerAccountFR from './fr/generalLedgerAccount.json';
import generalLedgerAccountNL from './nl/generalLedgerAccount.json';
import insurancePolicyEN from './en/insurancePolicy.json';
import insurancePolicyFR from './fr/insurancePolicy.json';
import insurancePolicyNL from './nl/insurancePolicy.json';
import tagsEN from './en/tags.json';
import tagsFR from './fr/tags.json';
import tagsNL from './nl/tags.json';
import teamSettingsEN from './en/teamSettings.json';
import teamSettingsFR from './fr/teamSettings.json';
import teamSettingsNL from './nl/teamSettings.json';

const resources = {
  en: {
    common: commonEN,
    errors: errorsEN,
    finance: financeEN,
    generalLedgerAccount: generalLedgerAccountEN,
    insurancePolicy: insurancePolicyEN,
    tags: tagsEN,
    teamSettings: teamSettingsEN,
  },
  fr: {
    common: commonFR,
    errors: errorsFR,
    finance: financeFR,
    generalLedgerAccount: generalLedgerAccountFR,
    insurancePolicy: insurancePolicyFR,
    tags: tagsFR,
    teamSettings: teamSettingsFR,
  },
  nl: {
    common: commonNL,
    errors: errorsNL,
    finance: financeNL,
    generalLedgerAccount: generalLedgerAccountNL,
    insurancePolicy: insurancePolicyNL,
    tags: tagsNL,
    teamSettings: teamSettingsNL,
  },
};

export const i18nInitFormatters = (i18n) => {
  i18n.services.formatter.add('lowercase', (value) => value.toLowerCase());
  i18n.services.formatter.add('uppercase', (value) => value.toUpperCase());
};

export const i18nConfig = {
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  ns: 'common',
  resources,
  returnNull: false,
};

export { resources };
