import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { GetLinkedPoliciesRequest, Policy } from '@/types/policies';
import { Nullable } from '~/common/types';
import { policyApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetLinkedPolicies = (request: Nullable<GetLinkedPoliciesRequest>) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery<Policy[], AxiosError>({
    enabled:
      !!request?.addressLine || !!request?.city || !!request?.postalCode || !!request?.buildingId,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: () => policyApi.getPoliciesLinkedToAddressOrBuilding(request!),
    queryKey: [
      queryIds.policies.GET_POLICIES_LINKED_TO_ADDRESS_OR_BUILDING,
      request!.addressLine,
      request!.city,
      request!.postalCode,
      request!.buildingId,
    ],
  });

  return {
    linkedPolicies: data || [],
    linkedPoliciesAreLoading: isLoading,
  };
};

export default useGetLinkedPolicies;
