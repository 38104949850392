import * as yup from 'yup';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CancelIcon, SaveIcon } from '../../../shared/icons/Icons';
import AddPolicyRelationFormBody from './AddPolicyRelationFormBody';
import FormikEnumSelector from '../../../shared/formik/FormikEnumSelector';
import FormikTextField from '../../../shared/formik/FormikTextField';
import useNotification from '../../../shared/hooks/UseNotification';

import API from '../../../shared/api/ApiService';
import { decapitalize } from '../../../shared/utils/helpers';
import useGetEnums from '../../../queries/enums/useGetEnums';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const AddPolicyForm = ({ onSubmit, onCancel }) => {
  const formRef = useRef();
  const popperContainer = useRef();
  const { t } = useTranslation();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [insuranceDomainOptions, setInsuranceDomainOptions] = useState([]);
  const [insurancePolicyTypeOptions, setInsurancePolicyTypeOptions] = useState([]);

  const {
    enums: { policyStatus },
  } = useGetEnums();
  const { sendDefaultError } = useNotification();

  useEffect(() => {
    async function getData() {
      const response = await API.fetchDomainEnumValues();

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
        return;
      }

      setInsuranceDomainOptions(response.data);
      const firstDomain = response.data[0].name;
      formRef.current.setFieldValue('insuranceDomain', firstDomain);
      setSelectedDomain(firstDomain);
    }

    getData();
  }, [sendDefaultError]);

  useEffect(() => {
    async function getData() {
      const response = await API.fetchPolicyTypeEnumValues(selectedDomain);

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
        return;
      }

      setInsurancePolicyTypeOptions(response.data);
    }

    formRef.current.setFieldValue('insurancePolicyType', null);
    if (selectedDomain == null) {
      setInsurancePolicyTypeOptions([]);
      return;
    }

    getData();
  }, [selectedDomain, sendDefaultError]);

  const saveAndClose = () => {
    formRef.current.setFieldValue('open', false);
    formRef.current.submitForm();
  };
  const saveAndOpen = () => {
    formRef.current.setFieldValue('open', true);
    formRef.current.submitForm();
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          insuranceDomain: null,
          insurancePolicyType: null,
          policyNumber: '',
          status: null,
        }}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          insuranceDomain: yup
            .string()
            .nullable()
            .required(
              t('errors:fieldXisInvalid', { field: decapitalize(t('common:insuranceDomain')) }),
            ),
          insurancePolicyType: yup.string().nullable().required(t('errors:fieldIsRequired')),
          policyNumber: yup
            .string()
            .nullable()
            .max(250, t('errors:fieldMaxLengthIsX', { max: 250 }))
            .required(t('errors:fieldIsRequired')),
          status: yup.string().nullable(),
        })}
      >
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-3">
                <FormikTextField
                  id="policyNumber"
                  name="policyNumber"
                  label={t('common:policyNumber')}
                  fullWidth
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-3">
                <FormikEnumSelector
                  id="status"
                  name="status"
                  label={t('common:status')}
                  enumValues={policyStatus}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-3">
                <FormikEnumSelector
                  id="insuranceDomain"
                  name="insuranceDomain"
                  label={t('common:insuranceDomain')}
                  enumValues={insuranceDomainOptions}
                  translationToUse="insurancePolicy"
                  onChange={(value) => {
                    setSelectedDomain(value);
                  }}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-3">
                <FormikEnumSelector
                  id="insurancePolicyType"
                  name="insurancePolicyType"
                  label={t('common:insurancePolicyType')}
                  enumValues={insurancePolicyTypeOptions}
                  translationToUse="insurancePolicy"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <AddPolicyRelationFormBody popperContainer={popperContainer} />
          <div className="flex justify-end space-x-4">
            <Button
              startDecorator={<CancelIcon />}
              color="neutral"
              variant="outlined"
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
            <Button startDecorator={<SaveIcon />} onClick={saveAndOpen}>
              {t('common:saveAndOpen')}
            </Button>
            <Button startDecorator={<SaveIcon />} onClick={saveAndClose}>
              {t('common:saveAndClose')}
            </Button>
          </div>
        </Form>
      </Formik>
      <div ref={popperContainer} />
    </>
  );
};

AddPolicyForm.propTypes = propTypes;

export default AddPolicyForm;
