import PropTypes from 'prop-types';

import CasePaymentType from '../enums/CasePaymentType';

const CasePaymentPropTypes = PropTypes.shape({
  amount: PropTypes.number,
  caseId: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  linkedPaymentId: PropTypes.string,
  relationId: PropTypes.string,
  type: PropTypes.oneOf([CasePaymentType.DamageToThirdParties, CasePaymentType.OwnDamage]),
});

export default CasePaymentPropTypes;
