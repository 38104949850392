import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import ROUTES from '../../App/Router/Routes';

import { getRecentCases, removeFromRecentCases } from '@/shared/utils/helpers';

import { CaseIcon } from '@/shared/icons/Icons';

import { CaseStatusType } from '~/common/enums';
import { useGetCasesCount } from '@/queries';

import CollapsibleSidebarItem from './CollapsibleSidebarItem';
import IconButton from '@/shared/components/buttons/IconButton';
import SidebarItem from './SidebarItem';

type RecentCase = {
  id: string;
  description: string;
  reference: string;
};

const CaseSidebarContent = () => {
  const [recentCases, setRecentCases] = useState<RecentCase[]>([]);
  const { totalCases: pendingCasesCount } = useGetCasesCount(CaseStatusType.Pending);
  const { t } = useTranslation();

  useEffect(() => {
    setRecentCases(getRecentCases());

    const handleCasesChange = () => {
      setRecentCases(getRecentCases());
    };

    document.addEventListener('casesChange', handleCasesChange);

    return () => {
      document.removeEventListener('casesChange', handleCasesChange);
    };
  }, []);

  const removeFromCases = (id: string) => {
    removeFromRecentCases(id);
  };

  return (
    <CollapsibleSidebarItem
      title={t('case_other')}
      icon={<CaseIcon />}
      badgeContent={pendingCasesCount}
      route={ROUTES.CASE_OVERVIEW}
      hideCollapse={recentCases.length === 0}
    >
      {recentCases.length > 0 &&
        recentCases.map((recentCase) => (
          <SidebarItem
            className="text-xs"
            key={recentCase.id}
            route={`/case/${recentCase.id}`}
            title={recentCase.reference}
            isChild
            toolTip={recentCase.description}
            endAction={
              <IconButton
                color="neutral"
                iconComponent={<CloseIcon className="mr-2 w-4" />}
                onClick={() => removeFromCases(recentCase.id)}
              />
            }
          />
        ))}
    </CollapsibleSidebarItem>
  );
};

export default CaseSidebarContent;
