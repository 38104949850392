import { useMemo } from 'react';

import { useGetCompanyTeams } from '../../queries';

const useCompanyForRelationByCentralCompany = (relation, enabled) => {
  // TODO: this can be later optimized by getting a companies by central company id after we have endpoint for it
  const { companyTeams, companyTeamsLoading } = useGetCompanyTeams({ enabled });

  const existingCrmCompany = useMemo(
    () =>
      companyTeams.find((company) => relation.teamCentralCompanyId === company.centralCompanyId),
    [companyTeams, relation],
  );

  return {
    existingCrmCompany,
    isLoading: companyTeamsLoading,
  };
};

export default useCompanyForRelationByCentralCompany;
