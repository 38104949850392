import { IconButton, Tooltip } from '@mui/joy';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import KeypointTableDetailsButton, {
  KeypointTableDetailsButtonProps,
} from '@/shared/components/table/components/KeypointTableDetailsButton';
import DeleteAction from '@/shared/components/DeleteAction';
import { EditIcon } from '@/shared/icons/Icons';

import { AppContext } from '@/shared/context/context';
import { Nullable } from '~/common/types';
import { sentencize } from '@/shared/utils/helpers';

import API from '@/shared/api/ApiService';
import { Toggles } from '@/types';
import useNotification from '@/shared/hooks/UseNotification';

type Props = {
  rowData: {
    canEdit: boolean;
    caseId: string;
    id: string;
    isClient: boolean;
    isContractor: boolean;
    isOwner: boolean;
    numberOfClientRelations: number;
    teamId: Nullable<string>;
  };
  toggles: Toggles;
} & KeypointTableDetailsButtonProps;

const RelationCustomActionCell: React.FC<Props> = ({ rowData, toggles, ...rest }) => {
  const {
    ui: { currentTeamId },
  } = useContext(AppContext);

  const { t } = useTranslation();

  const { canEdit, caseId, id, isClient, isContractor, isOwner, numberOfClientRelations, teamId } =
    rowData;

  const isDeletable = canEdit && (!isClient || numberOfClientRelations > 1);

  const { sendDefaultError, sendNotification } = useNotification();

  const onDelete = useMemo(
    () => async () => {
      const response = await API.deleteRemoveCaseRelation(caseId, id);
      if (response.serviceError != null || response.status !== 204) {
        sendDefaultError(response);
        return;
      }

      sendNotification({
        header: t('success'),
        message: sentencize(t('typeSuccessfullyRemoved', { type: t('involvedParty') })),
        variant: 'success',
      });
    },
    [caseId, id, sendDefaultError, sendNotification, t],
  );

  return (
    <div className="d-flex">
      <KeypointTableDetailsButton {...rest} />
      <Tooltip title={t('edit')}>
        <IconButton disabled={!canEdit} onClick={() => toggles.customEdit.show(id)} variant="plain">
          <EditIcon />
        </IconButton>
      </Tooltip>
      {currentTeamId !== teamId && !isContractor && !isOwner && (
        <DeleteAction
          onDelete={onDelete}
          disabled={!isDeletable}
          tooltip={
            isDeletable
              ? t('remove')
              : sentencize(
                  t('cannotDeleteBecause', {
                    reason: t('errors:thereShouldBeAtLeastOneClient'),
                    what: t('involvedParty'),
                  }),
                )
          }
        />
      )}
    </div>
  );
};

export default memo(RelationCustomActionCell);
