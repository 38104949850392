import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  Button,
  FormHelperText,
  IconButton,
  ListItemContent,
  ListItemDecorator,
  Tooltip,
} from '@mui/joy';
import React, { SyntheticEvent, useLayoutEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  AsyncAutocompleteFilter,
  AutocompleteOption as AutocompleteOptionType,
  PaginatedAutocomplete,
} from '~/common/types';
import { CaseType } from '~/common/enums';
import ExportSegment from '@/shared/enums/ExportSegment';
import { pagination } from '~/common/constants';
import { sortArrayByKey } from '@/shared/utils/helpers';
import useNotification from '@/shared/hooks/UseNotification';
import { useSearchFilter } from '@/shared/hooks/UseFilters';
import useToggle from '@/shared/hooks/UseToggle';

import {
  AcceptIcon,
  BuildingIcon,
  CancelIcon,
  CaseIcon,
  CircleIcon,
  ClassificationIcon,
  CompanyIcon,
  ComplaintCaseIcon,
  ContactIcon,
  DeleteIcon,
  FileIcon,
  InfoIcon,
  InlineSpinner,
  InsurancePolicyIcon,
  InsuredCaseIcon,
  RefreshIcon,
  RepairCaseIcon,
  TaskIcon,
  UserIcon,
} from '@/shared/icons/Icons';
import DeleteModal from '../DeleteModal';

const typeToIcon: Record<string, React.ReactNode> = {
  Building: <BuildingIcon />,
  CaseClassification: <ClassificationIcon />,
  Company: <CompanyIcon />,
  Contact: <ContactIcon />,
  Contract: <FileIcon />,
  default: <CircleIcon />,
  info: <InfoIcon />,
  Policy: <InsurancePolicyIcon />,
  selected: <AcceptIcon />,
  User: <UserIcon />,
  [CaseType.InsuranceClaim]: <InsuredCaseIcon />,
  [CaseType.Complaint]: <ComplaintCaseIcon />,
  [CaseType.Repair]: <RepairCaseIcon />,
  [CaseType.Case]: <CaseIcon />,
  [ExportSegment.Case]: <CaseIcon />,
  [ExportSegment.Task]: <TaskIcon />,
};

type AsyncAutocompleteProps = {
  customClear?: boolean;
  customDelete?: boolean;
  className?: string;
  defaultValue?: AutocompleteOptionType | AutocompleteOptionType[] | null;
  isFetchingExternal?: boolean;
  multiple?: boolean;
  onChange: (
    e: SyntheticEvent<Element, Event>,
    value: AutocompleteOptionType[] | AutocompleteOptionType,
  ) => void;
  onClearCallback?: () => void;
  onDeleteCallback?: () => void;
  onSuccess?: () => void;
  queryId: string;
  queryFn: (params: {
    search: string;
    page: number;
    language?: string;
    filter: AsyncAutocompleteFilter | undefined;
  }) => Promise<PaginatedAutocomplete>;
  groupBy?: (option: AutocompleteOptionType) => string | undefined;
  filter?: AsyncAutocompleteFilter;
  showInfo?: boolean;
  customActions?: AutocompleteOptionType[];
  placeholder?: string;
  children?: React.ReactNode;
  endDecorator?: React.ReactNode;
};

const AsyncAutocomplete: React.FC<AsyncAutocompleteProps> = ({
  endDecorator,
  customClear = false,
  customDelete = false,
  className,
  defaultValue = null,
  filter,
  isFetchingExternal = false,
  multiple = false,
  showInfo = true,
  customActions = [],
  onClearCallback,
  onDeleteCallback,
  onChange,
  onSuccess,
  queryId,
  queryFn,
  groupBy,
  children,
  ...rest
}) => {
  const [value, setValue] = React.useState<
    AutocompleteOptionType | AutocompleteOptionType[] | null
  >(defaultValue);
  const [inputValue, setInputValue] = useState('');

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const toggle = useToggle();
  const deleteToggle = useToggle();
  const { debounceSearch, search, setSearch } = useSearchFilter({ defaultSearch: '' });
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOptionType[]>([]);
  const { sendDefaultError } = useNotification();

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isFetching: isSearching,
  } = useInfiniteQuery({
    enabled: toggle.value,
    getNextPageParam: (lastPage: PaginatedAutocomplete) =>
      lastPage.pagination.hasNextPage
        ? lastPage.pagination.page + 1
        : lastPage.pagination.hasNextPage,
    onError: (err) => sendDefaultError(err),
    onSuccess,
    queryFn: ({ pageParam = pagination.queryParam.FIRST_PAGE }) =>
      queryFn({ filter, language, page: pageParam, search }),
    queryKey: [queryId, search, filter],
    staleTime: 0,
  });

  const flattenedOptions = useMemo(
    () => (data ? data.pages.map(({ items }) => items).flat() : []),
    [data],
  );

  const uniqueOptions = useMemo(() => {
    const mergedSelectedOptions = flattenedOptions
      ? [...selectedOptions, ...flattenedOptions]
      : selectedOptions;

    const filteredOptions = mergedSelectedOptions.filter(
      (item, index, self) => index === self.findIndex((option) => option.value === item.value),
    );

    const groupedByType = sortArrayByKey(filteredOptions, 'type') as AutocompleteOptionType[];

    return groupedByType;
  }, [selectedOptions, flattenedOptions]);

  const reset = () => {
    setValue(null);
    setInputValue('');
    setSelectedOptions([]);
    setSearch('');

    if (onClearCallback) {
      onClearCallback();
    }
  };

  useLayoutEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="flex space-x-3">
      <Autocomplete
        endDecorator={endDecorator}
        className={classNames('w-full', className)}
        value={value}
        inputValue={inputValue}
        open={toggle.value}
        onOpen={toggle.show}
        onClose={toggle.hide}
        disableCloseOnSelect={multiple}
        groupBy={(option) => {
          if (option.type === 'info') return t('info');
          if (option.type === 'selected') return t('selected');
          if (option.type === 'action') return t('actions');
          if (groupBy) return groupBy(option) || 'options';

          return 'Options';
        }}
        isOptionEqualToValue={(option, newValue) => {
          if (option.type === 'selected' || newValue.type === 'selected') {
            return true;
          }
          return option.value === newValue.value;
        }}
        multiple={multiple}
        onInputChange={(_e, val) => {
          setInputValue(val);
          debounceSearch(val);
        }}
        onChange={(e, val) => {
          if (onChange && val) {
            onChange(e, val);
            setValue({
              ...val,
            });
          }
          if (!val) {
            reset();
          }
          if (multiple && Array.isArray(val)) {
            const mappedOptions = val.map((option: AutocompleteOptionType) => ({
              ...option,
              type: 'selected',
            }));
            setSelectedOptions(mappedOptions);
            setValue(mappedOptions);
          }
          if (!multiple && val) {
            const autocompleteValue = val as AutocompleteOptionType;
            setSelectedOptions([{ ...autocompleteValue, type: 'selected' }]);
            setValue(autocompleteValue);
            setInputValue(autocompleteValue.label);
          }
          if (!val) {
            setSelectedOptions([]);
          }
        }}
        options={uniqueOptions}
        filterOptions={(options) => {
          if (showInfo) {
            options.push({
              label: t('asyncAutocomplete.result', {
                count: options.length - selectedOptions.length || 0,
                search,
                selected: selectedOptions.length,
                total: data?.pages[0].pagination.totalItems || 0,
              }),
              type: 'info',
              value: 'info',
            });
          }

          options.push(
            ...customActions.map((option) => ({
              ...option,
              type: 'action',
            })),
          );

          return options;
        }}
        renderOption={(props, option) => {
          const icon = option.icon ?? typeToIcon[option.type || 'default'];
          const optionIsInfo = option.type === 'info';
          const optionIsAction = option.type === 'action';
          const optionIsDefault = !optionIsInfo && !optionIsAction;

          return (
            <div>
              {optionIsDefault && (
                <AutocompleteOption {...props} key={option.value}>
                  <ListItemDecorator>{icon}</ListItemDecorator>
                  <ListItemContent>
                    {option.label}

                    {option.data && option.data.description && (
                      <FormHelperText>{option.data.description}</FormHelperText>
                    )}
                  </ListItemContent>
                </AutocompleteOption>
              )}
              {optionIsAction && (
                <Button
                  variant="plain"
                  startDecorator={icon}
                  className="w-full justify-start rounded-none"
                  onClick={() => option.action && option.action(inputValue)}
                >
                  {option.label}
                </Button>
              )}
              {showInfo && optionIsInfo && (
                <div className="flex flex-col items-center space-y-2 p-2">
                  {(hasNextPage || isLoading) && (
                    <Button
                      className="w-full"
                      onClick={() => fetchNextPage()}
                      startDecorator={
                        isFetchingNextPage || isSearching ? <InlineSpinner /> : <RefreshIcon />
                      }
                      variant="outlined"
                    >
                      {isFetchingNextPage || isSearching
                        ? t('app.status.isLoading')
                        : t('app.status.loadMore')}
                    </Button>
                  )}
                  <Alert
                    className="w-full"
                    color="warning"
                    key={option.value}
                    size="sm"
                    startDecorator={<InfoIcon />}
                  >
                    <p className="text-center italic">{option.label}</p>
                  </Alert>
                </div>
              )}
            </div>
          );
        }}
        {...rest}
      />
      {customClear && (
        <Tooltip title={t('buttons.reset')}>
          <IconButton disabled={isFetchingExternal || !value} onClick={reset}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
      {!multiple && customDelete && (
        <Tooltip title={t('buttons.delete')}>
          <IconButton
            color="danger"
            disabled={isFetchingExternal || !value}
            onClick={() => deleteToggle.show()}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      {children}

      <DeleteModal
        toggle={deleteToggle}
        onDelete={() => {
          reset();
          if (onDeleteCallback) {
            onDeleteCallback();
          }
        }}
      />
    </div>
  );
};

export default AsyncAutocomplete;
