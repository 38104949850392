import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryIds } from '@/shared/utils/constants';
import { teamApi } from '@/shared/api';

import useNotification from '@/shared/hooks/UseNotification';

type Data = {
  teamId: string;
  teamInvitationId: string;
};

const useAcceptTeamInvitation = () => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { isLoading, mutate, mutateAsync } = useMutation({
    mutationFn: (data: Data) => teamApi.acceptTeamInvitation(data),
    onError: sendDefaultError,
    onSuccess: () => {
      queryClient.invalidateQueries([queryIds.team.GET_TEAMS_AND_INVITATIONS]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyAdded', { type: t('invitation_one') }),
        variant: 'success',
      });
    },
  });
  return {
    acceptTeamInvitation: mutate,
    acceptTeamInvitationAsync: mutateAsync,
    isAcceptingTeamInvitation: isLoading,
  };
};

export default useAcceptTeamInvitation;
