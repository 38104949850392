import { AutocompleteOption } from '~/common/types';

import config from '@/config/config';
import createRestClient from '../restClient';

const restClient = createRestClient(config.baseURL);

const getEmailAddressesAutocomplete = (
  params: Record<string, unknown>,
): Promise<AutocompleteOption[]> => restClient.get(`mailbox/search/autocomplete`, { params });

export default {
  getEmailAddressesAutocomplete,
};
