import { lowerFirst, upperFirst } from 'lodash';
import i18next from 'i18next';

import { HomeownerAssociationAccount } from '@/types/trpc/hoa';

type DefaultAccount = HomeownerAssociationAccount['defaultAccount'];

type ToTranslateAccount = {
  defaultAccount: DefaultAccount | null;
  descriptionEn: string;
  descriptionFr: string;
  descriptionNl: string;
  number: number;
};

export const getDefaultAccountTranslation = (
  description: string,
  number: number,
  defaultAccount: ToTranslateAccount['defaultAccount'],
) => {
  let translation = description;

  if (!defaultAccount || description !== number.toString()) return translation;

  const defaultAccountTranslation = i18next.t(`generalLedgerAccount:${lowerFirst(defaultAccount)}`);

  translation += `: ${defaultAccountTranslation}`;

  return translation;
};

export const getAccountTranslation = (account: ToTranslateAccount, language: string) => {
  const { defaultAccount, descriptionEn, descriptionFr, descriptionNl, number } = account;

  let translation = number.toString();

  const translationKey = `description${upperFirst(language)}` as
    | 'descriptionNl'
    | 'descriptionEn'
    | 'descriptionFr';

  const description = account[translationKey] ?? descriptionNl ?? descriptionFr ?? descriptionEn;

  if (description) translation += `: ${description}`;

  return getDefaultAccountTranslation(translation, number, defaultAccount);
};
