import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { companyApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetCompanyTeams = (options) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: companyApi.getCompanyTeams,
    queryKey: [queryIds.companies.GET_COMPANY_TEAMS],
    ...options,
  });

  const companyTeams = useMemo(() => data ?? [], [data]);

  return {
    companyTeams,
    companyTeamsLoading: isLoading,
  };
};

export default useGetCompanyTeams;
