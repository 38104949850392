import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { AppContext } from '@/shared/context/context';
import { queryIds } from '../../shared/utils/constants';
import Roles from '../../shared/enums/Roles';
import useNotification from '../../shared/hooks/UseNotification';
import userApi from '../../shared/api/user/user';

const useUserRole = () => {
  const { currentTeam } = useContext(AppContext);
  const { sendDefaultError } = useNotification();

  const { data: role, isSuccess } = useQuery({
    enabled: !!currentTeam,
    onError: (err) => {
      sendDefaultError(err);
    },
    queryFn: userApi.getUserTeamRole,
    queryKey: [queryIds.user.GET_USER_ROLE, currentTeam?.id],
  });

  const isAdmin = role === Roles.Administrator;
  const isSupervisor = isAdmin || role === Roles.Supervisor;

  return {
    isAdmin,
    isSupervisor,
    role,
    rolesLoaded: isSuccess,
  };
};

export default useUserRole;
