import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import FormikVatInput from '../../shared/formik/FormikVatInput';

const AddCentralCompanyFormBody = ({ setCanSubmit }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <FormGroup className="mb-3">
      <FormikVatInput
        name="vatNumber"
        callback={({ companyName }) => {
          setFieldValue('name', companyName ?? '', false);
          setCanSubmit(!!companyName);
        }}
      />
    </FormGroup>
  );
};

AddCentralCompanyFormBody.propTypes = {
  setCanSubmit: PropTypes.func.isRequired,
};

export default AddCentralCompanyFormBody;
