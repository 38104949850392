import { getContrastRatio } from '@mui/material';

export const BLACK = '#000';
export const WHITE = '#fff';

export const getContrastingTextColor = (hexBg: string) => {
  const contrastBlack = getContrastRatio(BLACK, hexBg) || 0;
  const contrastWhite = getContrastRatio(WHITE, hexBg) || 0;

  if (contrastBlack > contrastWhite) {
    return BLACK;
  }

  return WHITE;
};

export const stringToUniqueColor = (value: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const hex = (hash >> (i * 8)) & 0xff;

    color += `00${hex.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
