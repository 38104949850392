import { getIn, useFormikContext } from 'formik';

import VatNumberInput, { VatPayload } from '../components/inputs/vatNumberInput/VatNumberInput';

type Props = {
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  callback?: (payload: VatPayload) => void;
};

const FormikVatInput: React.FC<Props> = ({
  name,
  defaultValue,
  required = false,
  callback,
  disabled,
}) => {
  const { values, setFieldValue, errors, setFieldTouched, setFieldError } = useFormikContext();

  const value = getIn(values, name);
  const error = getIn(errors, name);

  const onChange = (payload: VatPayload) => {
    if (payload.value !== value) {
      setFieldValue(name, payload.value);
      setFieldTouched(name, true);
    }

    if (payload.error && payload.error !== error) {
      setFieldError(name, payload.error);
    } else if (error && payload.valid) {
      setFieldError(name, undefined);
    }

    callback?.(payload);
  };

  return (
    <VatNumberInput
      disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      required={required}
      error={error}
      onChange={onChange}
      onBlur={() => setFieldTouched(name, true)}
    />
  );
};

export default FormikVatInput;
