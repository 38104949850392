import { Chip } from '@mui/joy';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FileContext } from '@/FileContext';

type Props = {
  rowData: {
    id: string;
  };
  value: boolean;
};

const DocumentStatusCustomCell: React.FC<Props> = ({ rowData, value }) => {
  const { t } = useTranslation();
  const { setSeen } = useContext(FileContext);

  return (
    <Chip
      variant="soft"
      size="sm"
      color={value ? 'success' : 'warning'}
      onClick={() => setSeen(rowData.id, !value)}
    >
      {t(value ? 'seen' : 'new')}
    </Chip>
  );
};

export default DocumentStatusCustomCell;
