import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  AutocompleteOption as JoyAutocompleteOption,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { createElement, FocusEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CrmOptionType, CrmType } from '~/common/enums';
import {
  filterOptions,
  ModalTypes,
  optionsGroupBy,
  sortOptions,
} from '@/shared/components/2.0/select/crm/utils';
import { useCreateCompany, useCreateContact } from '@/mutations';
import { Contact } from '~/common/types/contact/contact';
import { CrmAutoCompleteOption } from '~/common/types';
import { sentencize } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import { useGetCrmOptions } from '@/queries';
import useToggle from '@/shared/hooks/UseToggle';

import AddCentralCompanyForm, {
  CreateCentralCompanyFormikValues,
} from '@/containers/CentralCompanies/AddCentralCompanyForm';
import AddEditCompanyForm from '@/containers/Companies/AddEditCompany/AddEditCompanyForm';
import AddEditContactForm from '@/containers/Contacts/AddEditContact/AddEditContactForm';
import CrmTypeIcon from '@/shared/components/2.0/select/crm/CrmTypeIcon';
import { EmailAddressFormikValue } from '~/common/types/email/emailFormikValues';
import { InlineSpinner } from '@/shared/icons/Icons';
import KpcModal from '../../layout/KpcModal';

export type SelectCRMJoyProps<Multiple extends boolean | undefined = undefined> = {
  multiple?: Multiple;
  value?: Multiple extends true ? CrmAutoCompleteOption[] : CrmAutoCompleteOption | null;
  onChange: Multiple extends true
    ? (options: CrmAutoCompleteOption[]) => void
    : (option: CrmAutoCompleteOption | null) => void;
  label?: string;
  buildingId?: string;
  hoaId?: string;
  error?: string;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  name?: string;
  disabled?: boolean;
  isRequired?: boolean;
  excludeIds?: string[];
  includeTeams?: boolean;
  includeContacts?: boolean;
  includeCompanies?: boolean;
  includeCentralCompanies?: boolean;
  placeholder?: string;
  showAddCompany?: boolean;
  showAddCentralCompany?: boolean;
  showAddContact?: boolean;
  [key: string]: unknown;
};

const SelectCRMJoy = <Multiple extends boolean | undefined = undefined>({
  buildingId,
  disabled = false,
  error,
  excludeIds,
  hoaId,
  includeCentralCompanies = false,
  includeCompanies = true,
  includeContacts = true,
  includeTeams = false,
  isRequired,
  label,
  multiple = false,
  name,
  onBlur,
  onChange,
  showAddCentralCompany = false,
  showAddCompany = true,
  showAddContact = true,
  ...rest
}: SelectCRMJoyProps<Multiple>) => {
  const { t } = useTranslation();

  const { crmOptions, areCrmOptionsLoading } = useGetCrmOptions({
    buildingId,
    hoaId,
    includeCentralCompanies,
    includeCompanies,
    includeContacts,
    includeTeams,
  });
  const { mutate: createContact } = useCreateContact();
  const { mutate: createCompany } = useCreateCompany();
  const { mutateAsync: createCentralCompany } = trpc.company.centralCompany.add.useMutation();

  const [query, setQuery] = useState('');
  const modalToggle = useToggle<ModalTypes>();

  const sortedOptions = useMemo(
    () => sortOptions(crmOptions, excludeIds),
    [crmOptions, excludeIds],
  );

  const addCreatedOptionToValue = (newOption: CrmAutoCompleteOption) => {
    if (multiple) {
      const { value } = rest;
      (onChange as (options: CrmAutoCompleteOption[]) => void)([
        ...(value as CrmAutoCompleteOption[]),
        newOption,
      ]);
    } else {
      (onChange as (option: CrmAutoCompleteOption | null) => void)(
        newOption as CrmAutoCompleteOption,
      );
    }
  };

  const handleCreateContact = (
    contact: Omit<Contact, 'address' | 'contactTags' | 'id'> & {
      addressLine: string;
      postalCode: string;
      city: string;
      country: string;
    },
  ) => {
    const { addressLine, postalCode, city, country, emailAddresses, ...formBody } = contact;
    createContact(
      // @ts-ignore
      {
        ...formBody,
        address: {
          addressLine,
          city,
          country,
          postalCode,
        },
        emailAddresses: emailAddresses.filter((emailAddress) => !!emailAddress.email),
      },
      {
        onSuccess: (data) => {
          const newOption = {
            crmType: CrmType.Contact,
            label: `${contact.firstName || ''} ${contact.lastName}`.trim(),
            type: CrmOptionType.Contact,
            value: data.id as string,
          };
          addCreatedOptionToValue(newOption);
          modalToggle.hide();
        },
      },
    );
  };

  // TODO type company later together with AddEditCompanyForm
  // @ts-ignore
  const handleCreateCompany = (company) => {
    const { emailAddresses, ...formBody } = company;

    createCompany(
      {
        ...formBody,
        companyTags: company.companyTags.map((tag) => tag.value),
        emailAddresses: emailAddresses.filter(
          (emailAddress: EmailAddressFormikValue) => !!emailAddress.email,
        ),
      },
      {
        onSuccess: (data) => {
          const newOption = {
            crmType: CrmType.Company,
            label: company.name,
            type: CrmOptionType.Company,
            value: data.id as string,
          };

          addCreatedOptionToValue(newOption);
          modalToggle.hide();
        },
      },
    );
  };

  const handleCreateCentralCompany = async (centralCompany: CreateCentralCompanyFormikValues) => {
    const data = await createCentralCompany({ vatNumber: centralCompany.vatNumber });

    addCreatedOptionToValue({
      crmType: CrmType.CentralCompany,
      label: `${centralCompany.name} (${centralCompany.vatNumber})`,
      type: CrmOptionType.CentralCompany,
      value: data.id,
    });
  };

  const modalTitles: Record<ModalTypes, string> = {
    centralCompany: sentencize(t('addType', { type: t('centralCompany') })),
    company: sentencize(t('addType', { type: t('_company.title') })),
    contact: sentencize(t('addType', { type: t('_contact.title') })),
  };

  return (
    <FormControl error={!!error} className="flex-1">
      {label && <FormLabel required={isRequired}>{label}</FormLabel>}
      <Autocomplete
        name={name}
        disabled={disabled}
        disableCloseOnSelect={multiple}
        options={sortedOptions ?? []}
        loading={areCrmOptionsLoading}
        onInputChange={(_, val) => setQuery(val)}
        placeholder={rest.placeholder}
        onChange={(_, val) => {
          if (multiple) {
            (onChange as (options: CrmAutoCompleteOption[]) => void)(
              val as CrmAutoCompleteOption[],
            );
            return;
          }
          (onChange as (option: CrmAutoCompleteOption | null) => void)(
            val as CrmAutoCompleteOption | null,
          );
        }}
        inputValue={query}
        groupBy={({ type }) => optionsGroupBy(type, t)}
        filterOptions={(options, params) =>
          filterOptions(
            options,
            sortedOptions,
            modalToggle,
            params,
            query,
            areCrmOptionsLoading,
            showAddCentralCompany,
            showAddCompany,
            showAddContact,
            rest.value,
            t,
          )
        }
        endDecorator={areCrmOptionsLoading && <InlineSpinner />}
        isOptionEqualToValue={(option, other) => option.value === other.value}
        renderOption={(props, option) => {
          const icon = createElement(CrmTypeIcon, { type: option.type ?? 'default' });
          const optionIsInfo = option.type === CrmOptionType.Info;
          const optionIsAction = option.type === CrmOptionType.ActionAdd;

          if (optionIsInfo) {
            return (
              <Alert
                className="mx-2 mb-2"
                color="warning"
                size="sm"
                key={option.value}
                startDecorator={icon}
              >
                <p className="italic">{option.label}</p>
              </Alert>
            );
          }

          if (optionIsAction) {
            return (
              <Button
                key={option.label}
                variant="plain"
                startDecorator={icon}
                className="justify-start rounded-none"
                onClick={() => option.action && option.action()}
              >
                {option.label}
              </Button>
            );
          }

          return (
            <JoyAutocompleteOption {...props} key={`${option.value}-${option.label}`}>
              <ListItemDecorator>{icon}</ListItemDecorator>
              <ListItemContent className="flex flex-wrap items-center gap-2">
                {option.label}
                {!!option.data?.address?.addressLine && (
                  <Typography>({option.data.address.addressLine})</Typography>
                )}
              </ListItemContent>
            </JoyAutocompleteOption>
          );
        }}
        limitTags={1}
        onBlur={onBlur}
        multiple={multiple}
        {...rest}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
      <KpcModal
        title={modalToggle.state ? modalTitles[modalToggle.state] : undefined}
        toggle={modalToggle}
      >
        {modalToggle.state === 'centralCompany' && (
          <AddCentralCompanyForm
            onCancel={modalToggle.hide}
            onSubmit={handleCreateCentralCompany}
          />
        )}
        {modalToggle.state === 'company' && (
          <AddEditCompanyForm onCancel={modalToggle.hide} onSubmit={handleCreateCompany} />
        )}
        {modalToggle.state === 'contact' && (
          <AddEditContactForm onCancel={modalToggle.hide} onSubmit={handleCreateContact} />
        )}
      </KpcModal>
    </FormControl>
  );
};

export default SelectCRMJoy;
