// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import DocumentRelation from '../../../enums/DocumentRelation';

import { imageFileTypes } from '@/shared/utils/helpers';
import useToggle from '@/shared/hooks/UseToggle';

import DocumentPreviewModal from '../Documents/DocumentPreviewModal';
import { FileContext } from '@/FileContext';
import ImageDetails from './ImageDetails';

const GalleryTab = ({ documentRelation }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiper, setSwiper] = useState();
  const [thumbnailSwiper, setThumbnailSwiper] = useState();

  const { files, updateFile } = useContext(FileContext);

  const documentPreviewToggle = useToggle();

  const images = useMemo(
    () =>
      files
        .filter((f) => imageFileTypes.includes(f.contentType))
        .sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate)),
    [files],
  );

  const imageToPreview = useMemo(() => {
    const image = images[currentIndex];

    return {
      ...image,
      parentId: image.id,
    };
  }, [currentIndex, images]);

  return (
    <div>
      <Swiper
        preloadImages={false}
        lazy
        navigation
        loop
        centeredSlides
        className="galary-swiper"
        slidesPerView={images.length > 1 ? 2 : 1}
        modules={[Navigation]}
        onSlideChange={({ realIndex }) => {
          setCurrentIndex(realIndex);
          if (thumbnailSwiper && thumbnailSwiper.realIndex !== realIndex) {
            thumbnailSwiper.slideToLoop(realIndex, 0, false);
          }
        }}
        slideToClickedSlide
        onSwiper={setSwiper}
      >
        {images.map((img) => (
          <SwiperSlide key={img.id}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
            <img
              src={img.fileUri}
              alt={img.fileName}
              onClick={documentPreviewToggle.show}
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        preloadImages={false}
        lazy
        loop
        centeredSlides
        slideToClickedSlide
        watchSlidesProgress
        navigation
        className="thumbnail-swiper"
        spaceBetween={12}
        slidesPerView={images.length < 15 ? images.length : 15}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
        onSlideChange={({ realIndex }) => {
          setCurrentIndex(realIndex);
          if (swiper && swiper.realIndex !== realIndex) {
            swiper.slideToLoop(realIndex, 0, false);
          }
        }}
        onSwiper={setThumbnailSwiper}
      >
        {images.map((img) => (
          <SwiperSlide key={img.id}>
            <img src={img.fileUri} alt={img.fileName} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>

      {!!images[currentIndex] && (
        <ImageDetails
          file={images[currentIndex]}
          updateFile={updateFile}
          documentRelation={documentRelation}
        />
      )}
      {!!imageToPreview && (
        <DocumentPreviewModal
          toggle={documentPreviewToggle}
          file={imageToPreview}
          swiper={swiper}
        />
      )}
    </div>
  );
};

GalleryTab.propTypes = {
  documentRelation: PropTypes.oneOf([
    DocumentRelation.Case,
    DocumentRelation.Building,
    DocumentRelation.Unit,
    DocumentRelation.Policy,
    DocumentRelation.Spreadsheet,
    DocumentRelation.Meeting,
  ]).isRequired,
};

export default GalleryTab;
