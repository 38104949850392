import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ReferencePropType from '@/shared/prop-types/ReferencePropType';
import useGetEnums from '@/queries/enums/useGetEnums';

import FormikCaseRelationSelector from '@/shared/components/2.0/formik/FormikCaseRelationSelector';
import FormikDateTimePicker from '@/shared/components/2.0/formik/FormikDateTimePicker';
import FormikEnumAutocomplete from '@/shared/components/2.0/formik/FormikEnumAutocomplete';
import FormikTextArea from '@/shared/components/2.0/formik/FormikTextArea';

const propTypes = {
  caseId: PropTypes.string.isRequired,
  isOutboundCall: PropTypes.bool,
  popoverAnchorRef: ReferencePropType,
};

const PhoneCallFormBody = ({ caseId, isOutboundCall = false, popoverAnchorRef = null }) => {
  const { t } = useTranslation(['common', 'errors']);
  const {
    enums: { phoneCallOutcomes },
  } = useGetEnums();

  return (
    <>
      <div className="mb-3">
        <FormikCaseRelationSelector
          isRequired
          id="calleeRelationId"
          name="calleeRelationId"
          label={t('_contact.title')}
          selectSingle
          caseId={caseId}
          popperContainer={popoverAnchorRef}
          disablePortal={false}
          showPhoneNumbers
        />
      </div>
      <div className="mb-3">
        <FormikDateTimePicker
          name="callDate"
          id="callDate"
          label={t('common:callDate')}
          inputFormat="DD-MM-YYYY HH:mm"
          disableFuture
        />
      </div>
      <div className="mb-3">
        <FormikTextArea id="note" name="note" label={t('common:note')} />
      </div>
      {phoneCallOutcomes && isOutboundCall && (
        <FormikEnumAutocomplete
          id="phoneCallOutcome"
          name="phoneCallOutcome"
          label={t('common:phoneCallOutcome')}
          filterSelectedOptions
          options={phoneCallOutcomes}
          isRequired
        />
      )}
    </>
  );
};

PhoneCallFormBody.propTypes = propTypes;

export default PhoneCallFormBody;
