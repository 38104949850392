const CaseRelationRole = {
  Contractor: 'Contractor',
  CourtExpert: 'CourtExpert',
  ExpertOppositeParty: 'ExpertOppositeParty',
  HomeOwnerAssociation: 'HomeOwnerAssociation',
  InsuranceEngineer: 'InsuranceEngineer',
  InsuranceIntermediary: 'InsuranceIntermediary',
  Insurer: 'Insurer',
  InsurerOppositeParty: 'InsurerOppositeParty',
  Lawyer: 'Lawyer',
  LawyerOppositeParty: 'LawyerOppositeParty',
  LeakSearchCompany: 'LeakSearchCompany',
  OppositeParty: 'OppositeParty',
  Other: 'Other',
  OwnCounterExpert: 'OwnCounterExpert',
  OwnerLessor: 'OwnerLessor',
  OwnerResident: 'OwnerResident',
  OwnerSecond: 'OwnerSecond',
  PropertyManager: 'PropertyManager',
  Tenant: 'Tenant',
};

export default CaseRelationRole;
