const SpreadsheetStatus = {
  Accepted: 'Accepted',
  Draft: 'Draft',
  DraftInScan: 'DraftInScan',
  Failed: 'Failed',
  ReadyForValidation: 'ReadyForValidation',
  Rejected: 'Rejected',
};

export default SpreadsheetStatus;
