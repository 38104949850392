import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface RowProps {
  children: ReactNode;
  className?: string;
}

const Row: FC<RowProps> = ({ className, children }) => (
  <div className={classNames(className, 'flex space-x-4 pb-4')}>{children}</div>
);

export default Row;
