import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useUpdateAppointmentMutation = (options = {}) => {
  const { t } = useTranslation('common');
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => caseApi.putAppointment(data),
    onError: (err) => {
      sendDefaultError(err);
    },
    onSuccess: (_, { appointmentId, caseId }) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASE_ENTITIES, caseId]);
      queryClient.invalidateQueries([queryIds.cases.GET_CASE_ENTITY_ACCESS, caseId, appointmentId]);
      sendNotification({
        header: t('common:success'),
        message: t('common:typeSuccessfullyUpdated', { type: t('appointment') }),
        variant: 'success',
      });

      options.callback();
    },
  });

  return {
    isUpdatingAppointment: isLoading,
    updateAppointment: (appointment) => mutate(appointment),
  };
};

export default useUpdateAppointmentMutation;
