import { useQuery } from '@tanstack/react-query';

import { caseApi } from '@/shared/api';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';

const useGetCaseRelation = ({
  caseId,
  relationId,
}: {
  caseId: Nullable<string>;
  relationId: Nullable<string>;
}) => {
  const { data, isLoading } = useQuery({
    enabled: !!caseId && !!relationId,
    queryFn: () => caseApi.getCaseRelation({ caseId: caseId!, relationId: relationId! }),
    queryKey: [queryIds.cases.GET_CASE_RELATION, caseId, relationId],
  });

  return {
    caseRelation: data || null,
    caseRelationLoading: isLoading,
  };
};

export default useGetCaseRelation;
