import { memo, useMemo } from 'react';

import AccidentAtWork from './DamageTypeIcons/Ico-arbeidsongeval.svg';
import Electricity from './DamageTypeIcons/Ico-elektriciteit.svg';
import FireOrSmoke from './DamageTypeIcons/Ico-brand-rook.svg';
import GlassDamage from './DamageTypeIcons/Ico-glasschade.svg';
import Others from './DamageTypeIcons/Ico-andere.svg';
import StormWeatherNaturalDisasters from './DamageTypeIcons/Ico-natuurrampen.svg';
import Theft from './DamageTypeIcons/Ico-diefstal.svg';
import Vandalism from './DamageTypeIcons/Ico-vandalisme.svg';
import WaterDamage from './DamageTypeIcons/Ico-waterschade.svg';

import { DamageType } from '~/common/enums';

type DamageTypeIconProps = {
  damageType: DamageType;
  width?: string;
  height?: string;
};

const DamageTypeIcon: React.FC<DamageTypeIconProps> = ({ damageType, width, height }) => {
  const icon = useMemo(() => {
    switch (damageType) {
      case DamageType.Water:
        return WaterDamage;
      case DamageType.Vandalism:
        return Vandalism;
      case DamageType.Glass:
        return GlassDamage;
      case DamageType.StormWeatherNaturalDisasters:
        return StormWeatherNaturalDisasters;
      case DamageType.Electricity:
        return Electricity;
      case DamageType.Theft:
        return Theft;
      case DamageType.FireOrSmoke:
        return FireOrSmoke;
      case DamageType.AccidentAtWork:
        return AccidentAtWork;
      case DamageType.Others:
        return Others;
      default:
        return null;
    }
  }, [damageType]);

  if (!icon) return null;

  return <img alt="damageType" src={icon} width={width || 20} height={height || 20} />;
};

export default memo(DamageTypeIcon);
