import { FormControl, FormHelperText, FormLabel, Textarea } from '@mui/joy';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const FormikTextArea = ({
  name,
  label,
  required,
  type,
  getMaskedValue,
  setMaskedValue,
  onChangeCallback,
  ...rest
}) => {
  const { errors, touched, values, setFieldValue, setTouched } = useFormikContext();
  const textFieldRef = React.useRef();

  const getValue = useCallback(() => {
    if (!name.includes('.')) {
      return getMaskedValue ? getMaskedValue(values[name]) : values[name];
    }
    const split = name.split('.');
    if (name.includes('[')) {
      const parts = name.split('.');
      const arrayName = parts[0].split('[')[0];
      const index = parts[0].split('[')[1].substring(0, 1);
      const property = parts[1];
      const value = values[arrayName][index][property];
      return getMaskedValue ? getMaskedValue(value) : value;
    }
    return values[split[0]][split[1]];
  }, [getMaskedValue, name, values]);

  const getTouched = () => {
    if (!name.includes('.')) {
      return touched[name];
    }
    const split = name.split('.');
    return touched[split[0]] ? touched[split[0]][split[1]] : false;
  };

  const getErrors = () => {
    if (!name.includes('.')) {
      return errors[name];
    }
    const split = name.split('.');
    return errors[split[0]] ? errors[split[0]][split[1]] : null;
  };

  const onBlur = () => {
    if (!name.includes('.')) {
      setTouched({ ...touched, [name]: true });
      return;
    }
    const split = name.split('.');
    const prev = touched[split[0]];
    const updated = { ...prev, [split[1]]: true };
    setTouched({ ...touched, [split[0]]: { ...updated } });
  };

  React.useEffect(() => {
    if (getValue() === '') {
      setFieldValue(name, null);
    }
  }, [getValue, name, setFieldValue, values]);

  React.useEffect(() => {
    const textFiled = textFieldRef.current;
    const handleWheel = (e) => e.preventDefault();
    textFiled.addEventListener('wheel', handleWheel);

    return () => textFiled.removeEventListener('wheel', handleWheel);
  }, []);

  return (
    <FormControl className="flex-1" error={!!(getTouched() && getErrors() !== undefined)}>
      {label && (
        <FormLabel>
          {label}
          {required && ' *'}
        </FormLabel>
      )}
      <Textarea
        size="sm"
        ref={textFieldRef}
        name={name}
        value={getValue() ?? ''}
        type={type}
        onChange={({ target }) => {
          let { value } = target;
          if (setMaskedValue) value = setMaskedValue(target.value);
          setFieldValue(name, value);
          onChangeCallback(value);
        }}
        onBlur={onBlur}
        {...rest}
      />
      {getTouched() && getErrors() && (
        <FormHelperText className="text-xs">{getErrors()}</FormHelperText>
      )}
    </FormControl>
  );
};

FormikTextArea.propTypes = {
  getMaskedValue: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
  setMaskedValue: PropTypes.func,
  type: PropTypes.string,
};

FormikTextArea.defaultProps = {
  getMaskedValue: null,
  label: '',
  onChangeCallback: () => {},
  required: false,
  setMaskedValue: null,
  type: 'text',
};

export default FormikTextArea;
