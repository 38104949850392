import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import caseApi from '@/shared/api/cases/case';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetMandate = (caseId: Nullable<string>) => {
  const { sendDefaultError } = useNotification();
  const { data, isLoading } = useQuery({
    enabled: !!caseId,
    onError: (error: AxiosError) => sendDefaultError(error),
    queryFn: () => caseApi.getRepairMandate(caseId!),
    queryKey: [queryIds.cases.repair.GET_REPAIR_MANDATE, caseId],
  });

  return {
    repairMandate: data || null,
    repairMandateLoading: isLoading,
  };
};

export default useGetMandate;
