import PropTypes from 'prop-types';

const { string, shape } = PropTypes;

const AddressPropTypes = shape({
  postalCode: string,
  city: string,
  addressLine: string,
  country: string,
});

export default AddressPropTypes;
