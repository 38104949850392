import AddressCell from './AddressCell';

type Props = {
  value: {
    addressLine: string;
    city: string;
    name: string;
    postalCode: string;
  };
};

const AddressCustomCell: React.FC<Props> = ({ value }) => {
  const { addressLine, postalCode, city, name } = value;

  return <AddressCell addressLine={addressLine} city={city} name={name} postalCode={postalCode} />;
};

export default AddressCustomCell;
