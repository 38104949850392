import { useMutation } from '@tanstack/react-query';

import { caseApi } from '@/shared/api';
import useNotification from '@/shared/hooks/UseNotification';

const useCreateInsuranceClaim = () => {
  const { sendDefaultError } = useNotification();

  const { mutate, mutateAsync } = useMutation({
    mutationFn: (data: any) => caseApi.createInsuranceClaim(data),
    onError: sendDefaultError,
  });

  return {
    createInsuranceClaim: mutate,
    createInsuranceClaimAsync: mutateAsync,
  };
};

export default useCreateInsuranceClaim;
