import { Button, IconButton, Tooltip } from '@mui/joy';
import { useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DescriptionField, InfoSection, TypeName } from '../ItemStyles/Item-Styles';
import { CaseContext } from '@/containers/Cases/CaseContext';
import CaseViewEmailModal from '../../../../Email/CaseViewEmailModal';
import EmailStatus from '../../../../../../shared/enums/EmailStatus';
import { mapEmailEntityFieldsToMailboxFields } from '../../../../../../shared/utils/helpers';
import SendCaseEmail from '@/containers/Cases/Email/SendCaseEmail';
import { trpc } from '@/config/trpc';
import { useEmailBodyByBodyUrl } from '../../../../../../queries';
import { useMailboxContext } from '../../../../../Mailbox/store';
import useToggle from '../../../../../../shared/hooks/UseToggle';
import { useUpdateEntityTeamInfo } from '@/mutations';
import { WarningIcon } from '../../../../../../shared/icons/Icons';
import WrappedText from '../../../../../../shared/components/WrappedText';

const propTypes = {
  email: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        contentType: PropTypes.string,
        fileId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    bcc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    bodyUrl: PropTypes.string,
    caseId: PropTypes.string,
    cc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    created: PropTypes.string,
    createdByCurrentTeam: PropTypes.bool,
    createdByUser: PropTypes.string,
    emailDate: PropTypes.string,
    emailId: PropTypes.string,
    from: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
    id: PropTypes.string,
    inbound: PropTypes.bool,
    isPinned: PropTypes.bool,
    isSeen: PropTypes.bool,
    preheader: PropTypes.string,
    showOnClientPage: PropTypes.bool,
    status: PropTypes.string,
    subject: PropTypes.string,
    to: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
  }),
};

const EMAIL_CONTACTS_TO_DISPLAY_NUM = 7;

const formatEmailContact = (contact) =>
  contact.name ? `${contact.name} <${contact.email}>` : contact.email;

const EmailItem = ({ email }) => {
  const { canEditCase } = useContext(CaseContext);

  const { t } = useTranslation();
  const { updateState } = useMailboxContext();
  const { data: emailAccounts } = trpc.mailbox.accounts.get.useQuery();
  const emailPreviewToggle = useToggle();
  const sendEmailToggle = useToggle();
  const { data: body } = useEmailBodyByBodyUrl(email.bodyUrl, {
    enabled: sendEmailToggle.value,
    onSuccess: (data) => {
      updateState({
        replyEmail: mapEmailEntityFieldsToMailboxFields(email, data),
      });
    },
  });
  const { updateEntityTeamInfo } = useUpdateEntityTeamInfo();

  const toContacts = useMemo(() => {
    const formatted = email.to.map((to) => formatEmailContact(to));
    if (formatted.length <= EMAIL_CONTACTS_TO_DISPLAY_NUM) {
      return <span>{formatted.join(', ')}</span>;
    }

    return (
      <Tooltip title={formatted.join(', ')}>
        <span>{formatted.splice(0, EMAIL_CONTACTS_TO_DISPLAY_NUM).join(', ')}, ...</span>
      </Tooltip>
    );
  }, [email]);

  const reply = () => {
    updateState({
      isForward: false,
      isReplyAll: false,
      replyEmail: mapEmailEntityFieldsToMailboxFields(email, body),
    });
    sendEmailToggle.show();
  };

  const replyAll = () => {
    updateState({
      isForward: false,
      isReplyAll: true,
      replyEmail: mapEmailEntityFieldsToMailboxFields(email, body),
    });
    sendEmailToggle.show();
  };

  const forward = () => {
    updateState({
      isForward: true,
      isReplyAll: false,
      replyEmail: mapEmailEntityFieldsToMailboxFields(email, body),
    });
    sendEmailToggle.show();
  };

  const retry = () => {
    updateState({
      isForward: false,
      isReplyAll: false,
      isRetry: true,
      replyEmail: mapEmailEntityFieldsToMailboxFields(email, body),
    });
    sendEmailToggle.show();
  };

  return (
    <>
      <TypeName className="flex items-center space-x-3">
        {!email.isSeen && <FontAwesomeIcon icon={['fad', 'circle']} size="xs" opacity="1" />}
        <span>{t('email_one')}</span>
        {email.inbound ? (
          <FontAwesomeIcon icon={['fad', 'inbox-in']} />
        ) : (
          <FontAwesomeIcon icon={['fad', 'inbox-out']} />
        )}
        {email.attachments.length > 0 && <FontAwesomeIcon icon={['fad', 'paperclip']} />}
        {email.status === EmailStatus.Failed && (
          <Tooltip title={t('emailFailed')}>
            <span>
              <WarningIcon color="danger" />
            </span>
          </Tooltip>
        )}
      </TypeName>
      <div className="text-sm font-medium">
        {t('from')}: {email.from.map((from) => formatEmailContact(from)).join(', ')}
      </div>
      <div className="text-sm font-medium">
        {t('to')}: {toContacts}
      </div>
      <br />
      <InfoSection>
        <DescriptionField>{t('common:subject')}: </DescriptionField>
        <WrappedText>{email.subject}</WrappedText>
      </InfoSection>
      <InfoSection>
        <DescriptionField>{t('common:description')}: </DescriptionField>
        <WrappedText>{email.preheader}</WrappedText>
      </InfoSection>
      <br />
      {canEditCase && !!emailAccounts?.length && (
        <>
          <Tooltip title={t('common:reply')}>
            <IconButton variant="plain" onClick={reply}>
              <FontAwesomeIcon icon={['fas', 'reply']} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common:replyAll')}>
            <IconButton variant="plain" onClick={replyAll}>
              <FontAwesomeIcon icon={['fas', 'reply-all']} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common:forward')}>
            <IconButton variant="plain" onClick={forward}>
              <FontAwesomeIcon icon={['fas', 'share']} />
            </IconButton>
          </Tooltip>
          {email.status === EmailStatus.Failed && (
            <Tooltip title={t('common:retry')}>
              <IconButton variant="plain" onClick={retry}>
                <FontAwesomeIcon icon={['fas', 'rotate']} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <div className="float-right">
        <Button
          variant="plain"
          onClick={() => {
            emailPreviewToggle.show();
            if (email.isSeen) return;
            updateEntityTeamInfo({ caseId: email.caseId, entityId: email.id, isSeen: true });
          }}
        >
          {t('common:viewDetails')}
        </Button>
      </div>
      <CaseViewEmailModal
        toggle={emailPreviewToggle}
        openCompose={sendEmailToggle.show}
        email={email}
      />
      {canEditCase && <SendCaseEmail toggle={sendEmailToggle} />}
    </>
  );
};

EmailItem.propTypes = propTypes;

export default EmailItem;
