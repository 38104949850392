import { all } from 'redux-saga/effects';
import watcherFsmaNumberSaga from './handlers/fetchFsmaNumbers';
import watcherIntegrationsSaga from './handlers/fetchIntegrations';
import watcherPoliciesSaga from './handlers/fetchPolicies';
import watcherTagsSaga from './handlers/fetchTags';
import watcherTeamSaga from './handlers/fetchTeams';
import watcherUserSaga from './handlers/fetchUsers';

export default function* rootSaga() {
  yield all([
    watcherTeamSaga(),
    watcherUserSaga(),
    watcherTagsSaga(),
    watcherPoliciesSaga(),
    watcherFsmaNumberSaga(),
    watcherIntegrationsSaga(),
  ]);
}
