import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import SelectCRM from '../components/select/SelectCRM';

const FormikCrmSelector = ({
  name,
  label,
  selectSingle,
  callback,
  contactsToExclude,
  teamsToExclude,
  companiesToExclude,
  teamIdsToSelect,
  ...rest
}) => {
  const { values, setFieldValue, errors, touched, setTouched } = useFormikContext();

  const updateAndCallback = (updated) => {
    callback(updated);
    setFieldValue(name, { ...updated });
  };

  const [defaultTeamValues, setDefaultTeamValues] = useState(teamIdsToSelect ?? []);

  useEffect(() => {
    if (selectSingle && !values.ownerTeamId?.team) {
      return;
    }

    if (!selectSingle && (!values.ownerTeamId?.teams || values.ownerTeamId.teams.length === 0)) {
      return;
    }

    if (selectSingle) {
      setDefaultTeamValues([values.ownerTeamId.team.id]);
    } else {
      setDefaultTeamValues(values.ownerTeamId.teams.map(({ id }) => id));
    }
  }, [values.ownerTeamId, selectSingle]);

  const updateContacts = (contacts) => {
    if (selectSingle) {
      const updated = {
        centralCompany: null,
        company: null,
        companyWithTeam: null,
        contact: contacts.length > 0 ? contacts[0] : null,
        team: null,
      };
      updateAndCallback(updated);
      return;
    }
    updateAndCallback({ ...values[name], contacts });
  };

  const updateCompanies = (companies) => {
    if (selectSingle) {
      const updated = {
        centralCompany: null,
        company: companies.length > 0 ? companies[0] : null,
        companyWithTeam: null,
        contact: null,
        team: null,
      };
      updateAndCallback(updated);
      return;
    }
    updateAndCallback({ ...values[name], companies });
  };

  const updateTeams = (teams) => {
    if (selectSingle) {
      const updated = {
        centralCompany: null,
        company: null,
        companyWithTeam: null,
        contact: null,
        team: teams.length > 0 ? teams[0] : null,
      };
      updateAndCallback(updated);
      return;
    }
    updateAndCallback({ ...values[name], teams });
  };

  const updateCentralCompanies = (centralCompanies) => {
    if (selectSingle) {
      const updated = {
        centralCompany: centralCompanies.length > 0 ? centralCompanies[0] : null,
        company: null,
        companyWithTeam: null,
        contact: null,
        team: null,
      };
      updateAndCallback(updated);
      return;
    }
    updateAndCallback({ ...values[name], centralCompanies });
  };

  const updateCompaniesWithTeam = (companiesWithTeam) => {
    if (selectSingle) {
      const updated = {
        centralCompany: null,
        company: null,
        companyWithTeam: companiesWithTeam.length > 0 ? companiesWithTeam[0] : null,
        contact: null,
        team: null,
      };
      updateAndCallback(updated);
      return;
    }

    updateAndCallback({ ...values[name], companiesWithTeam });
  };

  return (
    <SelectCRM
      onContactSelectChanged={updateContacts}
      onCompanySelectChanged={updateCompanies}
      onTeamSelectChanged={updateTeams}
      onCentralCompanySelectChanged={updateCentralCompanies}
      onCompanyWithTeamSelectChanged={updateCompaniesWithTeam}
      companiesToExclude={companiesToExclude}
      teamsToExclude={teamsToExclude}
      contactsToExclude={contactsToExclude}
      teamIdsToSelect={defaultTeamValues}
      selectSingle={selectSingle}
      label={label}
      errorText={touched[name] === true ? errors[name] : null}
      isTouched={touched[name] === true}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      {...rest}
    />
  );
};

FormikCrmSelector.propTypes = {
  callback: PropTypes.func,
  companiesToExclude: PropTypes.arrayOf(PropTypes.string),
  contactsToExclude: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectSingle: PropTypes.bool,
  teamIdsToSelect: PropTypes.arrayOf(PropTypes.string),
  teamsToExclude: PropTypes.arrayOf(PropTypes.string),
};

FormikCrmSelector.defaultProps = {
  callback: () => {},
  companiesToExclude: [],
  contactsToExclude: [],
  selectSingle: false,
  teamIdsToSelect: [],
  teamsToExclude: [],
};

export default FormikCrmSelector;
