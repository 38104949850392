import { Autocomplete, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/config/trpc';

const TeamFilter = ({ callback, name, defaultValue, label }) => {
  const { data: teamsAndInvitations } = trpc.team.teamsAndInvitations.useQuery();

  const { t } = useTranslation('common');

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={teamsAndInvitations}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:teams')} />
      )}
    />
  );
};

TeamFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
};

TeamFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
  label: null,
};

export default TeamFilter;
