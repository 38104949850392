import { Check, Error } from '@mui/icons-material';
import React from 'react';
import { StepIndicator as StepIndicatorJoy } from '@mui/joy';

type Props = {
  permission: boolean;
  required?: boolean;
};

const StepIndicator: React.FC<Props> = ({ permission, required }) => (
  <StepIndicatorJoy
    color={permission ? 'success' : required ? 'danger' : 'warning'}
    variant="solid"
  >
    {permission ? <Check /> : <Error />}
  </StepIndicatorJoy>
);

export default StepIndicator;
