import { Button, Modal, ModalClose, ModalDialog } from '@mui/joy';
import { Form, Formik } from 'formik';
import { AnyObjectSchema } from 'yup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ToggleProps } from '@/types';

import { CancelIcon, InlineSpinner, ValidIcon } from '../../../icons/Icons';

const sizes = {
  lg: 'w-3/5',
  md: 'w-2/5',
  sm: 'w-1/5',
  xl: 'w-4/5',
};

const modalStyles =
  'max-h-screen max-w-screen-2xl overflow-y-auto rounded-xl border border-gray-200 bg-white';

type Props = {
  backdropBehavior?: 'closeOnSingleClick' | 'closeOnDoubleClick' | 'noCloseOnClick';
  children?: React.ReactNode;
  className?: string;
  description?: string;
  handleSubmit: (values: Record<string, unknown>) => void;
  headerComponent?: React.ReactNode;
  initialValues: Record<string, unknown>;
  icon?: React.ReactNode;
  isDelete?: boolean;
  isMutating?: boolean;
  onClose?: () => void;
  size?: 'lg' | 'md' | 'sm' | 'xl';
  title: string;
  toggle: ToggleProps;
  validationSchema?: AnyObjectSchema;
};

const FormModal: React.FC<Props> = ({
  backdropBehavior = 'closeOnDoubleClick',
  children,
  className,
  description,
  handleSubmit,
  headerComponent,
  initialValues,
  icon,
  isDelete = false,
  isMutating = false,
  onClose,
  size = 'xl',
  title,
  toggle,
  validationSchema,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const onClickBackdrop = () => {
    if (backdropBehavior === 'closeOnSingleClick') {
      handleClose();
    }
  };

  const onDoubleClickBackdrop = () => {
    if (backdropBehavior === 'closeOnDoubleClick') {
      handleClose();
    }
  };

  return (
    <Modal
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return;
        handleClose();
        toggle.hide();
      }}
      open={toggle.value}
      slotProps={{
        backdrop: {
          onClick: onClickBackdrop,
          onDoubleClick: onDoubleClickBackdrop,
        },
      }}
    >
      <ModalDialog className={classNames(modalStyles, className, sizes[size])}>
        <h4>
          {icon} {title}
        </h4>
        <ModalClose />
        {description && (
          <p className="font-sans text-xs italic leading-5">
            {description && <p>{description}</p>}
          </p>
        )}
        {headerComponent && <div>{headerComponent}</div>}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            {children}
            <div className="flex justify-end space-x-4 pt-4">
              <Button size="sm" color="neutral" variant="outlined" onClick={handleClose}>
                <CancelIcon className="pr-2" /> {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                size="sm"
                startDecorator={isMutating ? <InlineSpinner /> : <ValidIcon />}
                disabled={isMutating}
                color={isDelete ? 'danger' : 'primary'}
              >
                {isDelete ? t('buttons.delete') : t('buttons.save')}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalDialog>
    </Modal>
  );
};

export default FormModal;
