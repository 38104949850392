import config from '@/config/config';
import createRestClient from '../restClient';

import { EnumObject } from '~/common/types/enum/enum';

import { components } from '~/common/types/kpc.d';

export type ProvinceEnumObject =
  components['schemas']['KeypointConnect.Api.Apis.V1.DTOs.Enums.ProvinceEnumObject'];

const client = createRestClient(config.baseURL);

const getPrioritiesAutoComplete = (): Promise<EnumObject[]> =>
  client.get('enum/priorities/autocomplete');

const getPriorities = (): Promise<EnumObject[]> => client.get('enum/priorities');

const getStatusAutocomplete = (params) => client.get('/enum/status/autocomplete', { params });

const restClient = createRestClient();

const getContractorAbsences = () => restClient.get('enum/contractor.absences');

const getContractorFeeTypes = () => restClient.get('enum/contractor.feeTypes');

const getCountries = () => restClient.get('enum/countries');

const getProvinces = (): Promise<ProvinceEnumObject[]> => restClient.get('enum/provinces');

const getLanguages = () => restClient.get('enum/languages');

const getAddressFunctions = () => restClient.get('enum/address.functions');

const getBuildingRelationRoles = (): Promise<EnumObject[]> =>
  restClient.get('enum/building.relationRoles');

const getWorkdays = () => restClient.get('enum/workdays');

const getCaseChannels = () => restClient.get('enum/case.channels');

const getCaseInvolvementTypes = () => restClient.get('enum/case.involvement-types');

const getCaseRoles = () => restClient.get('enum/case.roles');

const getGenders = () => restClient.get('enum/genders');

const getCurrencies = () => restClient.get('enum/currencies');

const getPolicyStatus = () => restClient.get('enum/policy-status');

const getRenewalCollectionModes = () => restClient.get('enum/renewal-collection-mode');

const getDischargeCollectionModes = () => restClient.get('enum/discharge-collection-mode');

const getPaymentFrequencies = () => restClient.get('enum/payment-frequency');

const getPhoneCallOutcomes = () => restClient.get('enum/phone-call-outcomes');

const getUnities = () => restClient.get('enum/unities');

const getVATCodes = () => restClient.get('enum/vat-codes');

const getCorporateForms = () => restClient.get('enum/corporate-forms');

const getExcessTypes = () => restClient.get('enum/excess-type');

const getMessageTemplateContexts = () => restClient.get('enum/message-template/contexts');

const getMessageTemplateTags = (context: string) =>
  restClient.get(`enum/message-template/tags?context=${context}`);

const getRecurrencePatternFrequencyChoices = () =>
  restClient.get('enum/recurrence-pattern-frequency-choices');

const getRecurrencePatternMonthlyChoices = () =>
  restClient.get('enum/recurrence-pattern-monthly-choices');

const getRecurrencePatternYearlyChoices = () =>
  restClient.get('enum/recurrence-pattern-yearly-choices');

const getRecurrenceRangeEndChoices = () => restClient.get('enum/recurrence-range-end-choices');

const getExportTypes = () => restClient.get('enum/exports/types');

const getSegments = () => restClient.get('enum/exports/segments');

const getSegmentTypes = () => restClient.get('enum/exports/segment-types');

// @ts-ignore
const getColumns = (segment) => restClient.get('enum/exports/columns', { params: { segment } });

// @ts-ignore
const getFilters = (segment) => restClient.get('enum/exports/filters', { params: { segment } });

export default {
  getAddressFunctions,
  getBuildingRelationRoles,
  getCaseChannels,
  getCaseInvolvementTypes,
  getCaseRoles,
  getColumns,
  getContractorAbsences,
  getContractorFeeTypes,
  getCorporateForms,
  getCountries,
  getCurrencies,
  getDischargeCollectionModes,
  getExcessTypes,
  getExportTypes,
  getFilters,
  getGenders,
  getLanguages,
  getMessageTemplateContexts,
  getMessageTemplateTags,
  getPaymentFrequencies,
  getPhoneCallOutcomes,
  getPolicyStatus,
  getPriorities,
  getPrioritiesAutoComplete,
  getProvinces,
  getRecurrencePatternFrequencyChoices,
  getRecurrencePatternMonthlyChoices,
  getRecurrencePatternYearlyChoices,
  getRecurrenceRangeEndChoices,
  getRenewalCollectionModes,
  getSegments,
  getSegmentTypes,
  getStatusAutocomplete,
  getUnities,
  getVATCodes,
  getWorkdays,
};
