enum AtBicValue {
  NABAATWW = 'NABAATWW',
  BUNDATWW = 'BUNDATWW',
  OEKOATWW = 'OEKOATWW',
  BKAUATWW = 'BKAUATWW',
  BAWAATWW = 'BAWAATWW',
  OBKLAT2L = 'OBKLAT2L',
  BTVAAT22 = 'BTVAAT22',
  BFKKAT2K = 'BFKKAT2K',
  GEBAATWW = 'GEBAATWW',
  PABXATW1 = 'PABXATW1',
  BWFBATW1 = 'BWFBATW1',
  CITIATWX = 'CITIATWX',
  SGABATWW = 'SGABATWW',
  VBOEATWW = 'VBOEATWW',
  BGENATWW = 'BGENATWW',
  VKBLAT2L = 'VKBLAT2L',
  DEUTATWW = 'DEUTATWW',
  SPBAATWW = 'SPBAATWW',
  GUTBATWW = 'GUTBATWW',
  KTBKATWW = 'KTBKATWW',
  WIPBATWW = 'WIPBATWW',
  PABAAT2L = 'PABAAT2L',
  BSSWATWW = 'BSSWATWW',
  SCHOATWW = 'SCHOATWW',
  WISMATWW = 'WISMATWW',
  DIRAAT2S = 'DIRAAT2S',
  EUAAATWW = 'EUAAATWW',
  INGBATWW = 'INGBATWW',
  DENEATW1 = 'DENEATW1',
  BMASAT21 = 'BMASAT21',
  WELCATW1 = 'WELCATW1',
  RCNOATW1 = 'RCNOATW1',
  COPRATWW = 'COPRATWW',
  BLFLATWW = 'BLFLATWW',
  BIIWATWW = 'BIIWATWW',
  PIAGAT2S = 'PIAGAT2S',
  OPBAATW2 = 'OPBAATW2',
  STSPAT2G = 'STSPAT2G',
  SPAEAT2S = 'SPAEAT2S',
  BKCHATWW = 'BKCHATWW',
  ESBKATWW = 'ESBKATWW',
  INVOATWW = 'INVOATWW',
  COBAATWX = 'COBAATWX',
  TVBAATWW = 'TVBAATWW',
  SANTATWW = 'SANTATWW',
  WUSNATWW = 'WUSNATWW',
  FAZFAT21 = 'FAZFAT21',
  WUIBATWW = 'WUIBATWW',
  ICBKATWW = 'ICBKATWW',
  CENBATWW = 'CENBATWW',
  WUBUAT2S = 'WUBUAT2S',
  BRASATWW = 'BRASATWW',
  GIBAATWG = 'GIBAATWG',
  GIBAATWW = 'GIBAATWW',
  GIBAAT21 = 'GIBAAT21',
  SPAMAT21 = 'SPAMAT21',
  SPBDAT21 = 'SPBDAT21',
  SPHBAT21 = 'SPHBAT21',
  SPHAAT21 = 'SPHAAT21',
  SPHEAT21 = 'SPHEAT21',
  SPHNAT21 = 'SPHNAT21',
  SSKOAT21 = 'SSKOAT21',
  SPKDAT21 = 'SPKDAT21',
  SPLSAT21 = 'SPLSAT21',
  SPNGAT21 = 'SPNGAT21',
  SPPOAT21 = 'SPPOAT21',
  SPPDAT21 = 'SPPDAT21',
  SPSPAT21 = 'SPSPAT21',
  SPSBAT21 = 'SPSBAT21',
  SPZWAT21 = 'SPZWAT21',
  WINSATWN = 'WINSATWN',
  SPFRAT21 = 'SPFRAT21',
  SKBIAT21 = 'SKBIAT21',
  SPLAAT21 = 'SPLAAT21',
  ASPKAT2L = 'ASPKAT2L',
  SPNKAT21 = 'SPNKAT21',
  SPPBAT21 = 'SPPBAT21',
  SPPRAT21 = 'SPPRAT21',
  SPRHAT21 = 'SPRHAT21',
  SMWRAT21 = 'SMWRAT21',
  SPMIAT21 = 'SPMIAT21',
  SBGSAT2S = 'SBGSAT2S',
  SPIMAT21 = 'SPIMAT21',
  SPIHAT22 = 'SPIHAT22',
  SPKIAT2K = 'SPKIAT2K',
  SPKUAT22 = 'SPKUAT22',
  LISPAT21 = 'LISPAT21',
  SPRTAT21 = 'SPRTAT21',
  SPREAT21 = 'SPREAT21',
  SPSCAT22 = 'SPSCAT22',
  SPBRAT2B = 'SPBRAT2B',
  DOSPAT2D = 'DOSPAT2D',
  SPEGAT21 = 'SPEGAT21',
  SPFKAT2B = 'SPFKAT2B',
  SSBLAT21 = 'SSBLAT21',
  SPFNAT21 = 'SPFNAT21',
  KSPKAT2K = 'KSPKAT2K',
  SPMZAT21 = 'SPMZAT21',
  SPPLAT21 = 'SPPLAT21',
  SPVOAT21 = 'SPVOAT21',
  BAOSATWW = 'BAOSATWW',
  RZBAATWW = 'RZBAATWW',
  RBSKATW1 = 'RBSKATW1',
  NTBAATWW = 'NTBAATWW',
  RLNWATWW = 'RLNWATWW',
  RLNWATW1 = 'RLNWATW1',
  RLBBAT2E = 'RLBBAT2E',
  RZOOAT2L = 'RZOOAT2L',
  RVSAAT2S = 'RVSAAT2S',
  RZTIAT22 = 'RZTIAT22',
  RBRTAT22 = 'RBRTAT22',
  ALPEAT22 = 'ALPEAT22',
  RVVGAT2B = 'RVVGAT2B',
  RANJAT2B = 'RANJAT2B',
  RANMAT21 = 'RANMAT21',
  RZSTAT2G = 'RZSTAT2G',
  RZKTAT2K = 'RZKTAT2K',
  VSGKAT2K = 'VSGKAT2K',
  OVLIAT21 = 'OVLIAT21',
  MVOGAT22 = 'MVOGAT22',
  ABVRATW1 = 'ABVRATW1',
  WSPKATW1 = 'WSPKATW1',
  EHBBAT2E = 'EHBBAT2E',
  HAABAT2K = 'HAABAT2K',
  HSEEAT2K = 'HSEEAT2K',
  HYPNATWW = 'HYPNATWW',
  OBLAAT2L = 'OBLAAT2L',
  SLHYAT2S = 'SLHYAT2S',
  HYPTAT22 = 'HYPTAT22',
  HYPVAT2B = 'HYPVAT2B',
  SFIVATW1 = 'SFIVATW1',
  SBOSATWW = 'SBOSATWW',
  PORCAT21 = 'PORCAT21',
  VIAUATW1 = 'VIAUATW1',
  TEAMDE71 = 'TEAMDE71',
}

export default AtBicValue;