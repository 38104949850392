import { Button, IconButton } from '@mui/joy';
import PropTypes from 'prop-types';

import { CancelIcon } from '@/shared/icons/Icons';
import useToggle from '../../hooks/UseToggle';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const CaseEntityOption = ({ title, children }) => {
  const creationOptionToggle = useToggle();

  return (
    <div>
      {!creationOptionToggle.value && (
        <Button variant="plain" onClick={creationOptionToggle.show}>
          {title}
        </Button>
      )}
      {creationOptionToggle.value && (
        <div className="flex w-full space-x-2">
          <div className="grow">{children}</div>
          <div className="mt-[22px]">
            <IconButton onClick={creationOptionToggle.hide} variant="outlined" color="neutral">
              <CancelIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

CaseEntityOption.propTypes = propTypes;

export default CaseEntityOption;
