import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import FormikCurrencyInput from '../../../../shared/formik/FormikCurrencyInput';
import FormikEnumSelector from '../../../../shared/formik/FormikEnumSelector';
import FormikPolicySelector from '../../../../shared/formik/FormikPolicySelector';
import FormikTextField from '../../../../shared/formik/FormikTextField';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';
import useNotification from '../../../../shared/hooks/UseNotification';

import API from '../../../../shared/api/ApiService';
import { CaseContext } from '../../CaseContext';
import ExcessType from '../../../../shared/enums/ExcessType';
import useGetEnums from '../../../../queries/enums/useGetEnums';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitPolicyData: PropTypes.func.isRequired,
};

const CasePoliciesCardEditForm = ({ onCancel, onSubmitPolicyData }) => {
  const { currentCase } = useContext(CaseContext);

  const {
    enums: { excessTypes },
  } = useGetEnums();
  const [suggestedPolicies, setSuggestedPolicies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendDefaultError } = useNotification();
  const [excessType, setExcessType] = useState(currentCase.excessType);

  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }
    const policies = data.policies.policies.map((p) => ({
      policyId: p.isPolicyNumber ? null : p.id,
      policyNumber: p.policyNumber,
    }));
    const requestData = { caseId: currentCase.id, ...data, policies };

    setIsSubmitting(true);
    const response = await API.putUpdateCasePolicyData(requestData);
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    onSubmitPolicyData();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        excessAmount: currentCase.excessAmount,
        excessIncreasedAmount: currentCase.excessIncreasedAmount,
        excessRemark: currentCase.excessRemark,
        excessType: currentCase.excessType,
        policies: { policies: currentCase.policies.map((p) => ({ ...p, id: p.policyId })) },
        policyNumber: currentCase.policies?.length > 0 ? currentCase.policies[0].policyNumber : '',
      }}
    >
      <Form>
        <h3 className="font-weight-bold">{t('common:policy')}</h3>
        <p style={{ marginBottom: '20px', marginTop: '0px' }}>
          {suggestedPolicies !== null && suggestedPolicies.length > 0
            ? t('common:policiesWereFoundInTheDatabaseThatCanBeLinkedToThisBuilding')
            : t('common:noPoliciesWereFoundInTheDatabaseThatCanBeLinkedToThisBuilding')}
        </p>
        <FormikPolicySelector
          label={t('common:policies')}
          id="policies"
          name="policies"
          onSuggestedPoliciesFound={(suggested) => setSuggestedPolicies(suggested)}
          address={
            currentCase?.building?.buildingId ? currentCase.building.address : currentCase?.address
          }
          buildingId={currentCase?.building?.buildingId}
        />
        <br />
        <FormGroup className="mb-3">
          <FormikEnumSelector
            id="excessType"
            name="excessType"
            enumValues={excessTypes}
            label={t('common:excessType')}
            required
            onChange={setExcessType}
          />
        </FormGroup>
        {excessType !== ExcessType.None && (
          <>
            <FormGroup className="mb-3">
              <FormikCurrencyInput
                label={t('common:excess')}
                id="excessAmount"
                name="excessAmount"
                fullWidth
                required={currentCase.excessType != null}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikCurrencyInput
                label={t('common:increasedExcess')}
                id="excessIncreasedAmount"
                name="excessIncreasedAmount"
                fullWidth
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikTextField
                id="excessRemark"
                name="excessRemark"
                label={t('common:excessRemark')}
              />
            </FormGroup>
          </>
        )}
        <ModalFooter onCancel={onCancel} />
      </Form>
    </Formik>
  );
};

export default CasePoliciesCardEditForm;

CasePoliciesCardEditForm.propTypes = propTypes;
