import config from '@/config/config';
import { Contract } from '~/common/types/contract/contract';
import createRestClient from '../restClient';
import { PaginatedAutocomplete } from '~/common/types';

const client = createRestClient(config.baseURL);

const getContractsAutocomplete = (
  params: Record<string, unknown>,
): Promise<PaginatedAutocomplete> =>
  client.get('/contract/autocomplete', { params: { ...params, isActive: true } });

const restClient = createRestClient();

const getContract: (contractId: string) => Promise<Contract> = (contractId) =>
  restClient.get(`contract/${contractId}`);

const getContracts: () => Promise<Contract[]> = () => restClient.get(`contract/list`);

const updateInvoicingFlow = (data: unknown) => restClient.put('contract/invoicing-flow', data);

export default {
  getContract,
  getContracts,
  getContractsAutocomplete,
  updateInvoicingFlow,
};
