import PropTypes from 'prop-types';

const NoteItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  created: PropTypes.string,
  createdByCurrentTeam: PropTypes.bool,
  createdByUser: PropTypes.string,
  isPinned: PropTypes.bool,
  showOnClientPage: PropTypes.bool,
  note: PropTypes.string,
});

export default NoteItemPropTypes;
