import React from 'react';
import { useTranslation } from 'react-i18next';

const CallbackFront = () => {
  const { t } = useTranslation('common');

  return (
    <div className="callback" style={{ height: '100vh', width: '100vw' }}>
      <div className="callback__container">
        <h2 className="callback__title">{t('common:success')}</h2>
        <p className="callback__description">{t('common:youMayNowCloseThisPage')}</p>
      </div>
    </div>
  );
};

export default CallbackFront;
