const signalRMessages = {
  BatchCreated: 'BatchCreated',
  BatchDeleted: 'BatchDeleted',
  BatchMessageCreated: 'BatchMessageCreated',
  BatchMessageEasypostJobStatusUpdated: 'BatchMessageEasypostJobStatusUpdated',
  BatchMessageStatusUpdated: 'BatchMessageStatusUpdated',
  BatchStatusUpdated: 'BatchStatusUpdated',
  BuildingFileReadUpdated: 'BuildingFileReadUpdated',
  BuildingImportCreated: 'BuildingImportCreated',
  BuildingImportDeleted: 'BuildingImportDeleted',
  BuildingImportUpdated: 'BuildingImportUpdated',
  BuildingRelationImportCreated: 'BuildingRelationImportCreated',
  BuildingRelationImportDeleted: 'BuildingRelationImportDeleted',
  BuildingRelationImportUpdated: 'BuildingRelationImportUpdated',
  BuildingTagCreated: 'BuildingTagCreated',
  BuildingTagRemoved: 'BuildingTagRemoved',
  BuildingTagUpdated: 'BuildingTagUpdated',
  CaseEntityAdded: 'CaseEntityAdded',
  CaseEntityRemoved: 'CaseEntityRemoved',
  CaseEntityUpdated: 'CaseEntityUpdated',
  CaseFileReadUpdated: 'CaseFileReadUpdated',
  CasePaymentAdded: 'CasePaymentAdded',
  CasePaymentRemarkUpdated: 'CasePaymentRemarkUpdated',
  CasePaymentRemoved: 'CasePaymentRemoved',
  CasePaymentUpdated: 'CasePaymentUpdated',
  CaseRelationAdded: 'CaseRelationAdded',
  CaseRelationRemoved: 'CaseRelationRemoved',
  CaseRelationUpdated: 'CaseRelationUpdated',
  CaseUpdated: 'CaseUpdated',
  CompanyImportCreated: 'CompanyImportCreated',
  CompanyImportDeleted: 'CompanyImportDeleted',
  CompanyImportUpdated: 'CompanyImportUpdated',
  CompanyTagCreated: 'CompanyTagCreated',
  CompanyTagRemoved: 'CompanyTagRemoved',
  CompanyTagUpdated: 'CompanyTagUpdated',
  ContactTagCreated: 'ContactTagCreated',
  ContactTagRemoved: 'ContactTagRemoved',
  ContactTagUpdated: 'ContactTagUpdated',
  DivisionKeyImportCreated: 'DivisionKeyImportCreated',
  DivisionKeyImportDeleted: 'DivisionKeyImportDeleted',
  DivisionKeyImportUpdated: 'DivisionKeyImportUpdated',
  DocumentAccessibilityUpdated: 'DocumentAccessibilityUpdated',
  DocumentDataUpdated: 'DocumentDataUpdated',
  DocumentLinksAdded: 'DocumentLinksAdded',
  DocumentLinksRemoved: 'DocumentLinksRemoved',
  DocumentUploaded: 'DocumentUploaded',
  EmailMessageCreated: 'EmailMessageCreated',
  EmailMessageReplied: 'EmailMessageReplied',
  EmailMessageUpdated: 'EmailMessageUpdated',
  ExportUpdated: 'ExportUpdated',
  GeneralLedgerAccountImportCreated: 'GeneralLedgerAccountImportCreated',
  GeneralLedgerAccountImportDeleted: 'GeneralLedgerAccountImportDeleted',
  GeneralLedgerAccountImportUpdated: 'GeneralLedgerAccountImportUpdated',
  HomeownerAssociationReportStatusUpdated: 'HomeownerAssociationReportStatusUpdated',
  JoinBuildingGroup: 'JoinBuildingGroup',
  JoinCaseGroup: 'JoinCaseGroup',
  JoinMailboxGroupGroup: 'JoinMailboxGroupGroup',
  LeaveBuildingGroup: 'LeaveBuildingGroup',
  LeaveCaseGroup: 'LeaveCaseGroup',
  LeaveMailboxGroupGroup: 'LeaveMailboxGroupGroup',
  MeetingAgendaPointVotesUpdated: 'MeetingAgendaPointVotesUpdated',
  MeetingAgendaPointVotingStatusChanged: 'MeetingAgendaPointVotingStatusChanged',
  MeetingAttendeeDeparted: 'MeetingAttendeeDeparted',
  MeetingAttendeeDepartureDeleted: 'MeetingAttendeeDepartureDeleted',
  MeetingAttendeeRegistered: 'MeetingAttendeeRegistered',
  MeetingInvitationsSent: 'MeetingInvitationsSent',
  MeetingProcurationCreated: 'MeetingProcurationCreated',
  MeetingProcurationDeleted: 'MeetingProcurationDeleted',
  MeetingProcurationUpdated: 'MeetingProcurationUpdated',
  MeetingReportSent: 'MeetingReportSent',
  MeetingStatusUpdated: 'MeetingStatusUpdated',
  MeetingUpdated: 'MeetingUpdated',
  PaymentAdded: 'PaymentAdded',
  PaymentDeleted: 'PaymentDeleted',
  PerformanceAdded: 'PerformanceAdded',
  PerformanceUpdated: 'PerformanceUpdated',
  PolicyFileReadUpdated: 'PolicyFileReadUpdated',
  ReconciliationRequestCompleted: 'ReconciliationRequestCompleted',
  SpreadsheetAdded: 'SpreadsheetAdded',
  SpreadsheetUpdated: 'SpreadsheetUpdated',
  TaskCreated: 'TaskCreated',
  TaskFinished: 'TaskFinished',
  TaskSnoozed: 'TaskSnoozed',
  TaskUpdated: 'TaskUpdated',
  UnitFileReadUpdated: 'UnitFileReadUpdated',
};

export default signalRMessages;
