import { Alert, Checkbox, IconButton } from '@mui/joy';
import { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentencize, sortTasksByPriority } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '@/containers/Cases/CaseContext';
import useDayjs from '@/shared/hooks/useDayjs';
import useToggle from '@/shared/hooks/UseToggle';

import { AddIcon, InfoIcon } from '@/shared/icons/Icons';
import AddEditTaskModal from '../../../../Tasks/EditTask/AddEditTaskModal';
import TaskItem from './TaskItem';
import Tooltip from '@/shared/components/2.0/Tooltip';

const propTypes = {
  caseTasks: PropTypes.arrayOf(PropTypes.shape({})),
};

const CaseTasks = ({ caseTasks }) => {
  const { currentTeam } = useContext(AppContext);
  const { currentCase, canEditCase, refetchCaseEntities } = useContext(CaseContext);

  const { dayjs } = useDayjs();
  const { t } = useTranslation();
  const addToggle = useToggle();
  const showAllToggle = useToggle(true);

  const assignedTasksByToggle = useMemo(() => {
    const allAssignedTasks = caseTasks
      .filter((task) => task.taskUsers.some((tu) => tu.isAssigned))
      .sort(sortTasksByPriority)
      .sort((a, b) => dayjs(a.dueDate, 'YYYY-MM-DD').diff(dayjs(b.dueDate, 'YYYY-MM-DD')));

    const openTasks = allAssignedTasks.filter((task) => !task.completedDate);

    return showAllToggle.value ? openTasks : allAssignedTasks;
  }, [caseTasks, showAllToggle.value, dayjs]);

  const otherTeamsTasks = useMemo(
    () =>
      assignedTasksByToggle.filter((e) => e.taskUsers.some((tu) => tu.teamId !== currentTeam.id)) ||
      [],
    [assignedTasksByToggle, currentTeam.id],
  );

  const currentTeamTasks = useMemo(
    () =>
      assignedTasksByToggle.filter((e) => e.taskUsers.some((tu) => tu.teamId === currentTeam.id)) ||
      [],
    [assignedTasksByToggle, currentTeam.id],
  );

  const canEditTask = useMemo(
    () => (task) =>
      canEditCase &&
      (task.createdByTeamId === currentTeam.id ||
        task.taskUsers.some((tu) => tu.teamId === currentTeam.id)),
    [canEditCase, currentTeam],
  );

  const checkForPendingTasks = useCallback(
    (taskList) =>
      taskList.map((td) => (
        <TaskItem task={td} key={td.id} canEdit={canEditTask(td)} currentCase={currentCase} />
      )),
    [canEditTask, currentCase],
  );

  return (
    <div role="presentation" className="relative flex min-h-[400px] w-full flex-col">
      <h4 className="pb-4">{t('tasksForMyTeam')}</h4>
      <div className="mb-4 flex justify-between space-x-4 rounded-lg bg-gray-100 p-4">
        <Checkbox
          className="items-center"
          label={t('openTasks')}
          onChange={(e) => showAllToggle.setValue(e.target.checked)}
          checked={showAllToggle.value}
        />

        {canEditCase && (
          <Tooltip title={sentencize(t('addType', { type: t('task') }))}>
            <IconButton variant="outlined" onClick={addToggle.show}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="flex min-h-0 w-full grow-0 flex-col overflow-y-auto overflow-x-hidden overscroll-contain">
        {!showAllToggle.value && (
          <Alert className="mb-4" startDecorator={<InfoIcon />} color="warning">
            {t('finishedAndOpenTasks')}
          </Alert>
        )}

        {currentTeamTasks.length ? (
          checkForPendingTasks(currentTeamTasks)
        ) : (
          <Alert startDecorator={<InfoIcon />} color="success">
            {t('noPendingTasks')}
          </Alert>
        )}
        {otherTeamsTasks.length > 0 && (
          <>
            <h4 className="pb-4">{t('tasksForOtherTeams')}</h4>
            {checkForPendingTasks(otherTeamsTasks)}
          </>
        )}
      </div>
      {canEditCase && (
        <AddEditTaskModal
          refetchCaseEntities={refetchCaseEntities}
          toggle={addToggle}
          currentCase={currentCase}
        />
      )}
    </div>
  );
};

CaseTasks.propTypes = propTypes;

export default CaseTasks;
