import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { contractorSkillDefinitionApi } from '../../shared/api';
import { languageMap } from '~/common/constants';
import { queryIds } from '../../shared/utils/constants';
import { sortArrayOfObjects } from '~/common/utils/utils';
import useNotification from '../../shared/hooks/UseNotification';

const useGetContractorSkillDefinitions = () => {
  const { sendDefaultError } = useNotification();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { data, isLoading } = useQuery({
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await contractorSkillDefinitionApi.getContractorSkillDefinitions();
      const formatted = result.map(({ id, translations, parent }) => {
        const { description } = translations.find((tr) => tr.language === languageMap[language]);
        if (parent) {
          const parentTranslation = parent.translations.find(
            (tr) => tr.language === languageMap[language],
          );
          const { description: parentDescription } = parentTranslation;
          return { label: description, parent: parentDescription, value: id };
        }
        return { label: description, parent: t('uncategorized'), value: id };
      });

      const sorted = sortArrayOfObjects(formatted, 'parent');

      return { result, sorted };
    },
    queryKey: [queryIds.contractorSkillDefinitions.GET_CONTRACTOR_SKILL_DEFINITIONS],
  });

  return {
    contractorSkillDefinitions: data?.result || [],
    contractorSkillDefinitionsAutoComplete: data?.sorted || [],
    contractorSkillIsLoading: isLoading,
  };
};

export default useGetContractorSkillDefinitions;
