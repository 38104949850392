import { getLanguageFromCode, isNullOrEmpty } from '../../../helpers';

const formatContractorSkillDefinition = (id, contractorSkillDefinitions, language) => {
  if (!contractorSkillDefinitions) return {};
  const cs = contractorSkillDefinitions.find((tcs) => tcs.id === id);
  if (!cs) return {};

  const { translations, parent, ...others } = cs;

  const lang = getLanguageFromCode(language.slice(0, 2));
  const description = translations.find((tr) => tr.language === lang)?.description.trim();
  let sortableDescription = description;
  let chosenDescription = description;
  let parents;
  let nextParent = parent;
  let depth = 1;
  while (nextParent) {
    const parentDescription = nextParent.translations
      .find((tr) => tr.language === lang)
      ?.description.trim();
    sortableDescription = `${parentDescription}${sortableDescription}`;
    parents = isNullOrEmpty(parents) ? parentDescription : `${parentDescription} - ${parents}`;
    nextParent = nextParent.parent;
    depth += 1;
  }

  if (parents) {
    chosenDescription = `${chosenDescription} (${parents})`;
  }

  const hasChildren = contractorSkillDefinitions.find((tcs) => tcs.parentId === id);

  return {
    chosenDescription,
    depth,
    description,
    hasChildren,
    id,
    parent,
    sortableDescription,
    ...others,
  };
};

export default formatContractorSkillDefinition;
