const CaseStatus = {
  AwaitingDocumentsAndInformation: 'AwaitingDocumentsAndInformation',
  AwaitingExpertise: 'AwaitingExpertise',
  AwaitingProposalForSettlement: 'AwaitingProposalForSettlement',
  AwaitingRepairInvoices: 'AwaitingRepairInvoices',
  AwaitingSignedCharge: 'AwaitingSignedCharge',
  AwaitingSignedSettlementPowerOfAttorney: 'AwaitingSignedSettlementPowerOfAttorney',
  AwaitingStory: 'AwaitingStory',
  Cancelled: 'Cancelled',
  Closed: 'Closed',
  Declined: 'Declined',
  EightyPercentSettlement: 'EightyPercentSettlement',
  EstimateApproved: 'EstimateApproved',
  EstimateApprovedWorkCanBeScheduled: 'EstimateApprovedWorkCanBeScheduled',
  EstimateCreated: 'EstimateCreated',
  EstimateDeclined: 'EstimateDeclined',
  InstructingEngineer: 'InstructingEngineer',
  OnHold: 'OnHold',
  Open: 'Open',
  OrganizingCounterExpertise: 'OrganizingCounterExpertise',
  OrganizingExpertise: 'OrganizingExpertise',
  Pending: 'Pending',
  WaitingForEstimate: 'WaitingForEstimate',
};

export default CaseStatus;
