import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import moment from 'moment';

import API from '@/shared/api/ApiService';
import { LanguageCode } from '~/common/enums';

import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/nl';

import App from '../App';
import Start from '../Start/Start';

const Auth = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      setHasTriedSignin(true);
      auth.signinRedirect();
    } else if (auth.isAuthenticated && auth.user) {
      const { profile } = auth.user;

      i18next.changeLanguage(profile.locale);
      moment.locale(profile.locale === LanguageCode.EN ? 'en-gb' : profile.locale);
      API.postSyncUser();
    }
  }, [auth, hasTriedSignin]);

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return auth.isAuthenticated && auth.user ? <App /> : <Start />;
};

export default Auth;
