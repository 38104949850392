import { AddEditDefaultAccountFormikValues } from '@/containers/Teams/TeamSettings/Finance/Forms/FormikValues';
import config from '@/config/config';
import createRestClient from '../restClient';
import { DefaultAccount } from '~/common/types/team/defaultAccounts';

const client = createRestClient(config.baseURL);

const getDefaultAccounts = (): Promise<DefaultAccount[]> =>
  client.get('/defaultGeneralLedgerAccount');

const deleteDefaultAccounts = (id: string) => client.delete(`/defaultGeneralLedgerAccount/${id}`);

const createDefaultAccount = (data: AddEditDefaultAccountFormikValues) =>
  client.post('/defaultGeneralLedgerAccount', data);

const updateDefaultAccount = (data: AddEditDefaultAccountFormikValues) =>
  client.put('/defaultGeneralLedgerAccount', data);

export default {
  createDefaultAccount,
  deleteDefaultAccounts,
  getDefaultAccounts,
  updateDefaultAccount,
};
