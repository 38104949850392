import PropTypes from 'prop-types';
import CaseType from '../enums/CaseType';

const CaseTypePropTypes = PropTypes.oneOf([
  CaseType.Case,
  CaseType.Complaint,
  CaseType.InsuranceClaim,
  CaseType.Repair,
]);

export default CaseTypePropTypes;
