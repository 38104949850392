import { Chip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import FormikCheckbox from '../../2.0/formik/FormikCheckbox';
import Row from '../../2.0/layout/Row';

import { AcceptIcon, CancelIcon } from '@/shared/icons/Icons';
import FormikAutocomplete from '../../2.0/formik/FormikAutocomplete';

type Props = {
  files?: {
    fileName: string;
    isPublic: boolean;
    teamsWithAccess: { id: string; name: string }[];
  }[];
  accessOptions: { label: string; value: string }[];
  currentTeamId: string;
};

const DocumentsAccessibilityForm: React.FC<Props> = ({ accessOptions, files, currentTeamId }) => {
  const { t } = useTranslation(['common', 'finance']);
  return (
    <div>
      <div className="space-y-4 py-4">
        {files?.map((file) => (
          <div key={file.fileName} className="grid grid-cols-[min-content_auto] gap-x-4">
            <span className="whitespace-nowrap font-medium">{t('fileName')}</span>
            <span className="truncate">{file.fileName}</span>
            <span className="font-medium">{t('accessibility')}</span>
            <span className="truncate">
              {file.teamsWithAccess.map((team) => team.name).join(', ')}
            </span>
            <span className="font-medium">{t('public')}</span>

            {file.isPublic ? (
              <span className="text-green-500">
                <AcceptIcon />
              </span>
            ) : (
              <span className="text-red-500">
                <CancelIcon />
              </span>
            )}
          </div>
        ))}
      </div>
      <div className="border-t py-4">
        <div className="mb-2 italic text-gray-400">
          {t('_case.forms.documentAccessibility.info')}
        </div>
        <Row>
          <FormikAutocomplete
            name="teamsWithAccess"
            label={t('accessibility')}
            options={accessOptions}
            getOptionDisabled={(option: { label: string; value: string }) =>
              option.value === currentTeamId
            }
            // @ts-ignore
            renderTags={(tags, getTagProps) =>
              // @ts-ignore
              tags.map((item, index) => {
                const { onClick, tagProps } = getTagProps({ index });
                return (
                  <Chip
                    color="neutral"
                    variant="soft"
                    endDecorator={<CancelIcon className="-mr-1 text-base" />}
                    onClick={item.value === currentTeamId ? undefined : onClick}
                    {...tagProps}
                  >
                    {item.label}
                  </Chip>
                );
              })
            }
            multiple
          />
        </Row>
        <Row>
          <FormikCheckbox name="isPublic" label={t('publicCheckboxInfo')} />
        </Row>
      </div>
    </div>
  );
};

export default DocumentsAccessibilityForm;
