import PropTypes from 'prop-types';

const AppointmentPropTypes = PropTypes.shape({
  allDay: PropTypes.bool,
  caseId: PropTypes.string,
  caseRelationId: PropTypes.string,
  created: PropTypes.string,
  createdByCurrentTeam: PropTypes.bool,
  createdByTeam: PropTypes.string,
  createdByUser: PropTypes.string,
  description: PropTypes.string,
  endTime: PropTypes.string,
  id: PropTypes.string,
  isPinned: PropTypes.bool,
  linkedCalendarId: PropTypes.string,
  location: PropTypes.string,
  nylasEventId: PropTypes.string,
  read: PropTypes.bool,
  showOnClientPage: PropTypes.bool,
  startTime: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
});

export default AppointmentPropTypes;
