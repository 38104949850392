import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Attachments from './Attachments';
import AvatarWithColor from '../../AvatarWithColor';
import CollapsibleRecipients from '@/containers/Mailbox/Components/Messages/CollapsibleRecipients';
import { FormattedEmailMessage } from '~/common/types/email/emailMessage';
import useDayjs from '@/shared/hooks/useDayjs';

type Props = {
  email?: FormattedEmailMessage;
  topActions: ReactNode;
  emailActions: ReactNode;
  children: ReactNode;
  areAttachmentsCaseFiles?: boolean;
};

const Email: React.FC<Props> = ({
  email,
  topActions,
  emailActions,
  children,
  areAttachmentsCaseFiles,
}) => {
  const { t } = useTranslation();
  const { formatUtcDate } = useDayjs();

  if (!email) {
    return (
      <div className="inbox__email inbox__empty-placeholder flex h-full flex-col items-center justify-center">
        <FontAwesomeIcon icon={['fad', 'envelopes-bulk']} size="7x" className="text-primary" />
        <h4>{t('selectEmailFromList')}</h4>
        <p>{t('nothingSelected')}</p>
      </div>
    );
  }

  return (
    <div className="inbox__email flex min-h-0 flex-col">
      <div className="border-b border-slate-200 pb-3">
        <div className="flex w-full items-center justify-between space-x-2">
          <h4>{email.subject}</h4>
          <div className="align-self-start flex space-x-1">{topActions}</div>
        </div>
      </div>
      <div>
        <div className="flex justify-between space-x-4 py-3">
          <div className="flex min-w-0">
            {!!email.from?.length && (
              <>
                <div>
                  <AvatarWithColor name={email.from[0].name ?? email.from[0].email} size="lg" />
                </div>
                <div className="flex flex-col overflow-hidden pl-2 pt-1.5">
                  <h5 className="font-medium">
                    {email.from[0].name} &lt;{email.from[0].email}&gt;
                  </h5>
                  <CollapsibleRecipients preText={t('to')} recipients={email.to} />
                  <CollapsibleRecipients preText="Cc" recipients={email.cc} />
                  <CollapsibleRecipients preText="Bcc" recipients={email.bcc} />
                </div>
              </>
            )}
          </div>
          <div className="flex shrink-0 flex-col items-end">
            <div className="flex items-center space-x-1">{emailActions}</div>
            <div className="text-xxs text-gray-400">
              <FontAwesomeIcon icon={['fad', 'calendar-day']} className="mr-1" />
              {formatUtcDate(email.date, 'DD/MM/YYYY HH:mm')}
            </div>
          </div>
        </div>
        {!!email.attachments.length && (
          <Attachments
            attachments={email.attachments}
            areAttachmentsCaseFiles={areAttachmentsCaseFiles}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default Email;
