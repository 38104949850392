enum BeBicValue {
  ABERBE22 = 'ABERBE22',
  ABNABE2A = 'ABNABE2A',
  ADIABE22 = 'ADIABE22',
  ARSPBE22 = 'ARSPBE22',
  AXABBE22 = 'AXABBE22',
  BARCBEBB = 'BARCBEBB',
  BBRUBEBB = 'BBRUBEBB',
  BBVABEBB = 'BBVABEBB',
  BCDMBEBB = 'BCDMBEBB',
  BCMCBEBB = 'BCMCBEBB',
  BIBLBE21 = 'BIBLBE21',
  BKCHBEBB = 'BKCHBEBB',
  BKIDBE22 = 'BKIDBE22',
  BLUXBEBB = 'BLUXBEBB',
  BMEUBEB1 = 'BMEUBEB1',
  BMPBBEBB = 'BMPBBEBB',
  BNAGBEBB = 'BNAGBEBB',
  BOFABE3X = 'BOFABE3X',
  BOTKBEBX = 'BOTKBEBX',
  BSCHBEBB = 'BSCHBEBB',
  BYBBBEBB = 'BYBBBEBB',
  CBPXBE99 = 'CBPXBE99',
  CEKVBE88 = 'CEKVBE88',
  CEPABEB2 = 'CEPABEB2',
  CFFRBEB1 = 'CFFRBEB1',
  CHASBEBX = 'CHASBEBX',
  CITIBEBX = 'CITIBEBX',
  CLIQBEB1 = 'CLIQBEB1',
  CMCIBEB1 = 'CMCIBEB1',
  CPHBBE75 = 'CPHBBE75',
  CREGBEBB = 'CREGBEBB',
  CRLYBEBB = 'CRLYBEBB',
  CTBKBEBX = 'CTBKBEBX',
  CVMCBEBB = 'CVMCBEBB',
  DEGRBEBB = 'DEGRBEBB',
  DELEBE22 = 'DELEBE22',
  DEUTBEBE = 'DEUTBEBE',
  DHBNBEBB = 'DHBNBEBB',
  DIERBE21 = 'DIERBE21',
  DIGEBEB2 = 'DIGEBEB2',
  DNIBBE21 = 'DNIBBE21',
  EBPBBEB1 = 'EBPBBEB1',
  ENIBBEBB = 'ENIBBEBB',
  EPBFBEBB = 'EPBFBEBB',
  EURBBE99 = 'EURBBE99',
  FMMSBEB1 = 'FMMSBEB1',
  FPEBBEB2 = 'FPEBBEB2',
  FVLBBE22 = 'FVLBBE22',
  FXBBBEBB = 'FXBBBEBB',
  GEBABEBB = 'GEBABEBB',
  GKCCBEBB = 'GKCCBEBB',
  GOCFBEB1 = 'GOCFBEB1',
  HABBBEBB = 'HABBBEBB',
  HOMNBEB1 = 'HOMNBEB1',
  HSBCBEBB = 'HSBCBEBB',
  ICBKBEBB = 'ICBKBEBB',
  IRVTBEBB = 'IRVTBEBB',
  ISAEBEBB = 'ISAEBEBB',
  JPMGBEBB = 'JPMGBEBB',
  JVBABE22 = 'JVBABE22',
  KEYTBEBB = 'KEYTBEBB',
  KREDBEBB = 'KREDBEBB',
  LOCYBEBB = 'LOCYBEBB',
  MBWMBEBB = 'MBWMBEBB',
  MGTCBEBE = 'MGTCBEBE',
  MHCBBEBB = 'MHCBBEBB',
  MTPSBEBB = 'MTPSBEBB',
  NBBEBEBB = 'NBBEBEBB',
  NICABEBB = 'NICABEBB',
  OONXBEBB = 'OONXBEBB',
  PANXBEB1 = 'PANXBEB1',
  PARBBEBZ = 'PARBBEBZ',
  PAYVBEB2 = 'PAYVBEB2',
  PCHQBEBB = 'PCHQBEBB',
  PESOBEB1 = 'PESOBEB1',
  PRIBBEBB = 'PRIBBEBB',
  PUILBEBB = 'PUILBEBB',
  RCBPBEBB = 'RCBPBEBB',
  REVOBEB2 = 'REVOBEB2',
  SBINBE2X = 'SBINBE2X',
  SGABBEB2 = 'SGABBEB2',
  SMBCBEBB = 'SMBCBEBB',
  TRIOBEBB = 'TRIOBEBB',
  TRWIBEB1 = 'TRWIBEB1',
  TUNZBEB1 = 'TUNZBEB1',
  UTWBBEBB = 'UTWBBEBB',
  VAPEBE22 = 'VAPEBE22',
  VDSPBE91 = 'VDSPBE91',
  VPAYBEB1 = 'VPAYBEB1',
  WAFABEBB = 'WAFABEBB',
}

export default BeBicValue;
