import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router';

import HomeownerAssociationContext from '@/containers/HomeownerAssociation/context/HomeownerAssociationContext';
import { trpc } from '@/config/trpc';

type HomeownerAssociationContextProps = {
  children: ReactNode;
};

const queryOptions = {
  trpc: {
    context: {
      skipBatch: true,
    },
  },
};

const HomeownerAssociationContextProvider: React.FC<HomeownerAssociationContextProps> = ({
  children,
}) => {
  const trpcUtils = trpc.useUtils();

  const { buildingId, homeownerAssociationId } = useParams();

  const { data: homeownerAssociation, isLoading: homeownerAssociationIsLoading } =
    trpc.hoa.summary.useQuery(
      { id: homeownerAssociationId as string },
      { ...queryOptions, enabled: !!homeownerAssociationId },
    );

  const { data: navigatedFromBuilding, isLoading: navigatedFromBuildingIsLoading } =
    trpc.building.summaryById.useQuery(buildingId as string, {
      ...queryOptions,
      enabled: !!buildingId,
    });

  const { data: buildings, isLoading: buildingsAreLoading } = trpc.building.summariesByIds.useQuery(
    homeownerAssociation?.buildings as string[],
    { ...queryOptions, enabled: !!homeownerAssociation?.buildings?.length },
  );

  const { data: units, isLoading: unitsAreLoading } = trpc.building.unit.listForBuildings.useQuery(
    homeownerAssociation?.buildings as string[],
    { ...queryOptions, enabled: !!homeownerAssociation?.buildings?.length },
  );

  const { data: relations, isLoading: relationsAreLoading } =
    trpc.building.relationsForBuildings.useQuery(homeownerAssociation?.buildings as string[], {
      ...queryOptions,
      enabled: !!homeownerAssociation?.buildings?.length,
    });

  const { data: glas, isLoading: glasAreLoading } = trpc.hoa.account.list.useQuery(
    { homeownerAssociationId: homeownerAssociationId as string },
    { ...queryOptions, enabled: !!homeownerAssociationId },
  );

  const value = useMemo(
    () => ({
      buildings: buildings || [],
      buildingsAreLoading,
      glas: glas ?? [],
      glasAreLoading,
      homeownerAssociation,
      homeownerAssociationId: homeownerAssociationId ?? null,
      homeownerAssociationIsLoading,
      invalidateGlas: () =>
        trpcUtils.hoa.account.list.invalidate({
          homeownerAssociationId: homeownerAssociationId as string,
        }),
      navigatedFromBuilding,
      navigatedFromBuildingIsLoading,
      relations: relations ?? [],
      relationsAreLoading,
      units: units ?? [],
      unitsAreLoading,
    }),
    [
      buildings,
      buildingsAreLoading,
      glas,
      glasAreLoading,
      homeownerAssociation,
      homeownerAssociationId,
      homeownerAssociationIsLoading,
      navigatedFromBuilding,
      navigatedFromBuildingIsLoading,
      relations,
      relationsAreLoading,
      units,
      unitsAreLoading,
    ],
  );

  return (
    <HomeownerAssociationContext.Provider value={value}>
      {children}
    </HomeownerAssociationContext.Provider>
  );
};

export default HomeownerAssociationContextProvider;
