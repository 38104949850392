import * as yup from 'yup';
import { Button, Chip, ChipDelete } from '@mui/joy';
import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import { Form, Formik } from 'formik';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from 'react-bootstrap';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../api/ApiService';
import { AppContext } from '@/shared/context/context';
import CaseRelationRole from '../../../enums/CaseRelationRole';
import ReferencePropType from '../../../prop-types/ReferencePropType';
import { sentencize } from '../../../utils/helpers';
import useNotification from '../../../hooks/UseNotification';
import useOutsideAlerter from '../../../hooks/UseOutsideAlerter';

import {
  AddIcon,
  HomeOwnerAssociationIcon,
  InsuranceEngineerIcon,
  InsuranceIntermediaryIcon,
  InsurerIcon,
  PropertyManagerIcon,
} from '../../../icons/Icons';
import AddCaseRelationFormBody from '../../../../containers/Cases/CaseLayout/BodySections/Forms/AddCaseRelationFormBody';
import ModalFooter from '../forms/ModalFooter';
import TextField from '../input/TextField';
import { useGetCase } from '../../../../queries';

const propTypes = {
  callback: PropTypes.func,
  caseId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  errorText: PropTypes.string,
  initialValues: PropTypes.arrayOf(PropTypes.string),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  popperContainer: ReferencePropType,
  selectOnly: PropTypes.bool,
  selectSingle: PropTypes.bool,
  showPhoneNumbers: PropTypes.bool,
};

const SelectCaseRelation = ({
  caseId,
  isRequired = false,
  label = '',
  errorText = '',
  defaultValue = null,
  selectSingle = false,
  disablePortal = true,
  popperContainer = null,
  callback = () => {},
  selectOnly = false,
  initialValues = null,
  disabled = false,
  onBlur = () => {},
  showPhoneNumbers = false,
}) => {
  const {
    currentTeam: { createCaseAskForClient },
  } = useContext(AppContext);
  const { currentCase } = useGetCase(caseId);

  const { t } = useTranslation('common');
  const { sendNotification, sendDefaultError } = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addingRelation, setAddingRelation] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [query, setQuery] = useState('');

  const [selectedRelations, updateSelectedRelations] = useState([]);

  const selectorMenuRef = useRef();
  const { isInside, setOutsideAlerterActive } = useOutsideAlerter(selectorMenuRef);

  const collapseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
    setAddingRelation(false);
    setQuery('');

    if (isInside) {
      onBlur();
    }
  }, [isInside, onBlur, setOutsideAlerterActive]);

  const handleSelectOrDeselect = useCallback(
    (relationId) => {
      if (selectSingle) {
        updateSelectedRelations((prev) => {
          if (prev.includes(relationId)) {
            return [];
          }
          collapseSelect();
          return [relationId];
        });
        return;
      }
      updateSelectedRelations((prev) => {
        if (!prev.includes(relationId)) {
          return [...prev, relationId];
        }
        return [...prev.filter((id) => id !== relationId)];
      });
    },
    [collapseSelect, selectSingle],
  );

  useEffect(() => {
    callback([...selectedRelations]);
  }, [callback, selectedRelations]);

  useEffect(() => {
    if (defaultValue) {
      if (selectSingle) {
        updateSelectedRelations([defaultValue]);
      }
    }
  }, [defaultValue, selectSingle]);

  const getLabel = useMemo(() => label || t('common:search.label'), [label, t]);
  const helperText = useMemo(() => {
    if (errorText != null && errorText.length > 0) {
      return errorText;
    }
    if (isRequired && selectedRelations.length === 0) {
      return t('errors:fieldIsRequired');
    }
    return null;
  }, [selectedRelations, errorText, isRequired, t]);

  const anchorRef = useRef();

  const getCaseRelationRoleIcon = useCallback((role) => {
    switch (role) {
      case CaseRelationRole.Insurer:
        return <InsurerIcon />;
      case CaseRelationRole.InsuranceEngineer:
        return <InsuranceEngineerIcon />;
      case CaseRelationRole.InsuranceIntermediary:
        return <InsuranceIntermediaryIcon />;
      case CaseRelationRole.HomeOwnerAssociation:
        return <HomeOwnerAssociationIcon />;
      case CaseRelationRole.PropertyManager:
        return <PropertyManagerIcon />;
      default:
        return null;
    }
  }, []);

  const getPhoneNumbersLabel = (phoneNumberLabel, mobilePhoneNumberLabel) => {
    if (phoneNumberLabel && mobilePhoneNumberLabel) {
      return ` - ${phoneNumberLabel}, ${mobilePhoneNumberLabel}`;
    }
    if (phoneNumberLabel && !mobilePhoneNumberLabel) {
      return ` - ${phoneNumberLabel}`;
    }
    if (!phoneNumberLabel && mobilePhoneNumberLabel) {
      return ` - ${mobilePhoneNumberLabel}`;
    }
    return '';
  };

  const getRelationLabel = useCallback(
    (relation) => {
      const {
        displayName,
        phoneNumber,
        mobilePhoneNumber,
        isClient,
        isRequestor,
        isOwner,
        isContractor,
      } = relation;

      const mobilePhoneNumberLabel = mobilePhoneNumber
        ? `${mobilePhoneNumber.countryCode ?? ''}${mobilePhoneNumber.number ?? ''}`
        : '';
      const phoneNumberLabel = phoneNumber
        ? `${phoneNumber.countryCode ?? ''}${phoneNumber.number ?? ''}`
        : '';

      const badges = [];
      if (isOwner) {
        badges.push(<Chip key="owner">{t('common:owner')}</Chip>);
      }
      if (isRequestor) {
        badges.push(<Chip key="requestor">{t('common:requestor')}</Chip>);
      }
      if (isClient) {
        badges.push(<Chip key="client">{t('common:client')}</Chip>);
      }
      if (isContractor) {
        badges.push(<Chip key="contractor">{t('common:contractor')}</Chip>);
      }

      return (
        <div className="flex space-x-2">
          <div>{badges}</div>
          <div>
            {displayName}{' '}
            {(mobilePhoneNumberLabel || phoneNumberLabel) && showPhoneNumbers
              ? getPhoneNumbersLabel(phoneNumberLabel, mobilePhoneNumberLabel)
              : ''}
          </div>
        </div>
      );
    },
    [showPhoneNumbers, t],
  );

  const selectedChips = useMemo(() => {
    if (selectedRelations.length === 0) {
      return null;
    }
    return selectedRelations.map((relationId) => {
      const relation = currentCase.relations.find((rel) => rel.id === relationId);
      if (relation == null) {
        return null;
      }
      return (
        <Chip
          key={relationId}
          variant="outlined"
          startDecorator={getCaseRelationRoleIcon(relation.relationType)}
          endDecorator={<ChipDelete onClick={() => handleSelectOrDeselect(relationId)} />}
          disabled={disabled}
        >
          {getRelationLabel(relation)}
        </Chip>
      );
    });
  }, [
    currentCase,
    disabled,
    getCaseRelationRoleIcon,
    getRelationLabel,
    handleSelectOrDeselect,
    selectedRelations,
  ]);

  const handleCreateRelation = useCallback(
    async (relationData) => {
      if (isSubmitting) {
        return;
      }

      const { isClient, isRequestor } = relationData;

      setIsSubmitting(true);
      const response = await API.postSimpleCaseRelation({
        ...relationData,
        isClient: createCaseAskForClient ? isClient : isRequestor,
      });
      setIsSubmitting(false);

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
        return;
      }

      setAddingRelation(false);
      sendNotification({
        header: t('common:success'),
        message: t('common:typeSuccessfullyAdded', { type: t('common:involvedParty') }),
        variant: 'success',
      });
    },
    [createCaseAskForClient, isSubmitting, sendDefaultError, sendNotification, t],
  );

  const preselected = useRef(false);
  useEffect(() => {
    if (
      preselected.current ||
      initialValues == null ||
      initialValues.length === 0 ||
      currentCase.relations == null ||
      currentCase.relations.length === 0
    ) {
      return;
    }
    preselected.current = true;

    currentCase.relations
      .filter((cr) => initialValues.includes(cr.id))
      .forEach((cr) => handleSelectOrDeselect(cr.id));
  }, [currentCase, handleSelectOrDeselect, initialValues]);

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      collapseSelect();
    }
  };

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });

  return (
    <ClickAwayListener onClickAway={collapseSelect}>
      <div className="custom__selector">
        <div className="flex items-end">
          <TextField
            label={isRequired ? `${getLabel} *` : getLabel}
            isRequired={isRequired}
            inputClassName={textFieldClasses}
            onChange={({ target }) => setQuery(target.value.toLowerCase())}
            id="input-with-sx"
            onFocus={() => setIsSelectOpen(true)}
            autoComplete="off"
            startDecorator={selectedChips}
            textfieldRef={anchorRef}
            value={query}
            onKeyDown={handleKeyDown}
            error={
              (isRequired && selectedRelations.length === 0) ||
              (helperText != null && helperText.length > 0)
            }
            helperText={helperText}
          />
        </div>
        <PopperUnstyled
          disablePortal={disablePortal}
          container={popperContainer?.current}
          open={isSelectOpen}
          anchorEl={anchorRef?.current}
          className="custom__selector-wrap"
          placement="bottom-start"
        >
          <div className="rounded-xl bg-white p-2 shadow-xl" ref={selectorMenuRef}>
            {!addingRelation ? (
              <div className="flex flex-col">
                {currentCase?.relations
                  .filter((rel) => !selectedRelations.includes(rel.id))
                  .filter(
                    (rel) =>
                      query == null ||
                      query.length === 0 ||
                      rel.displayName?.toLowerCase()?.includes(query),
                  )
                  .map((rel) => (
                    <Button
                      className="w-full justify-start"
                      variant="plain"
                      color="neutral"
                      key={rel.id}
                      startDecorator={getCaseRelationRoleIcon(rel.relationType)}
                      onClick={() => handleSelectOrDeselect(rel.id)}
                    >
                      {getRelationLabel(rel)}
                    </Button>
                  ))}
                {!selectOnly && (
                  <>
                    <hr />
                    <Button
                      className="w-full justify-start"
                      variant="plain"
                      color="neutral"
                      startDecorator={<AddIcon />}
                      onClick={() => setAddingRelation(true)}
                    >
                      {sentencize(t('common:addType', { type: t('common:involvedParty') }))}
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <Formik
                onSubmit={handleCreateRelation}
                className="p-3"
                initialValues={{
                  caseId,
                  crmSelector: null,
                  isClient: false,
                  isRequestor: false,
                }}
                validationSchema={yup.object().shape({
                  createCaseAskForClient: yup.bool().nullable(),
                  crmSelector: yup
                    .object()
                    .shape({
                      company: yup
                        .object()
                        .shape({
                          display: yup.string().nullable(),
                          id: yup.string().nullable(),
                        })
                        .nullable(),
                      contact: yup
                        .object()
                        .shape({
                          display: yup.string().nullable(),
                          id: yup.string().nullable(),
                        })
                        .nullable(),
                      team: yup
                        .object()
                        .shape({
                          display: yup.string().nullable(),
                          id: yup.string().nullable(),
                        })
                        .nullable(),
                    })
                    .test('clientIsNotNull', t('errors:fieldIsRequired'), (value) => {
                      const { company, contact, team } = value;
                      return company != null || contact != null || team != null;
                    }),
                  isClient: yup.bool().nullable(),
                  isRequestor: yup.bool().nullable(),
                })}
              >
                <Form>
                  <AddCaseRelationFormBody
                    minified
                    caseId={caseId}
                    createCaseAskForClient={createCaseAskForClient}
                    popperContainer={popperContainer}
                    currentCaseRelations={currentCase.relations}
                  />
                  <ModalFooter onCancel={() => setAddingRelation(false)} />
                </Form>
              </Formik>
            )}
          </div>
        </PopperUnstyled>
      </div>
    </ClickAwayListener>
  );
};

SelectCaseRelation.propTypes = propTypes;

export default SelectCaseRelation;
