import { getIn, useFormikContext } from 'formik';

import { AutocompleteOption, Nullable } from '~/common/types';
import SelectHomeownerAssociation, {
  SelectHomeownerAssociationProps,
} from '../select/SelectHomeownerAssociation';

type Props<Multiple extends boolean | undefined = undefined> = Omit<
  SelectHomeownerAssociationProps,
  'value' | 'onChange' | 'error'
> & {
  name: string;
  multiple?: Multiple;
  callback?: Multiple extends true
    ? (options: AutocompleteOption[]) => void
    : (option: Nullable<AutocompleteOption>) => void;
};

const FormikHomeownerAssociationSelector = <Multiple extends boolean | undefined = undefined>({
  name,
  callback,
  multiple = false,
  ...rest
}: Props<Multiple>) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && !!error;

  return (
    <SelectHomeownerAssociation
      error={hasError ? error : undefined}
      onChange={(selected: Nullable<AutocompleteOption> | AutocompleteOption[]) => {
        setFieldTouched(name, true);

        if (multiple) {
          const multiSelected = (selected ?? []) as AutocompleteOption[];
          setFieldValue(
            name,
            multiSelected.map((option) => option.value),
          );

          if (callback) {
            (callback as (option: AutocompleteOption[]) => void)(multiSelected);
          }
          return;
        }

        const singleSelected = selected as Nullable<AutocompleteOption>;
        setFieldValue(name, singleSelected?.value ?? null);

        if (callback) {
          (callback as (option: Nullable<AutocompleteOption>) => void)(singleSelected);
        }
      }}
      value={getIn(values, name) || null}
      onBlur={() => setFieldTouched(name, true)}
      multiple={multiple}
      {...rest}
    />
  );
};

export default FormikHomeownerAssociationSelector;
