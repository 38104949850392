import { ActionType } from 'ka-table';
import actionTypes from '../actions/actionTypes';

const initialState = {};

const tableReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.CHANGE_COLUMN_VISIBILITY: {
      const newColumns = state.columns.map((column) => {
        if (column.key === payload.columnKey) {
          return { ...column, visible: payload.checked };
        }
        return column;
      });

      localStorage.setItem(payload.localStorageType, JSON.stringify(newColumns));

      return {
        ...state,
        columns: newColumns,
      };
    }
    case ActionType.ReorderColumns: {
      localStorage.setItem(payload.localStorageType, JSON.stringify(state.columns));

      return {
        ...state,
      };
    }
    case ActionType.ResizeColumn: {
      localStorage.setItem(payload.localStorageType, JSON.stringify(state.columns));

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default tableReducer;
