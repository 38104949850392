const CaseEntityType = {
  Appointment: 'Appointment',
  Email: 'EmailMessage',
  File: 'File',
  InternalMessage: 'InternalMessage',
  Log: 'Log',
  Note: 'Note',
  PhoneCall: 'PhoneCall',
  Status: 'Status',
  TextMessage: 'TextMessage',
};

export default CaseEntityType;
