import { Autocomplete, FormControl, FormLabel } from '@mui/joy';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { UseMutateFunction } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityType, MessageTemplateContext } from '~/common/enums';
import { File, FileManagerFile } from '@/types/documents';
import {
  SenderOrReceiver,
  SendMessageFormikValues,
} from '../../../../types/email/EmailFormikValues';
import { Nullable } from '~/common/types';
import { SendMessageRequest } from '~/common/types/email/emailMessage';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  MessageTemplateFilter,
  MessageTemplateFormatted,
} from '@/queries/team/useMessageTemplates';
import { formatEmailFormData } from '@/shared/utils/Email/emailHelpers';
import { trpc } from '@/config/trpc';
import { useMailboxContext } from '@/containers/Mailbox/store';
import { useMessageTemplates } from '@/queries';
import useSendEmailMessage from '@/mutations/email/useSendEmailMessage';

import ComposeEmail from '@/containers/Mailbox/Components/ComposeEmail/ComposeEmail';
import EmailAccountsAutocomplete from '@/containers/Mailbox/Components/EmailAccountsAutocomplete';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props = {
  onClose?: () => void;
  onSubmit?: (
    formData: SendMessageFormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) => void;
  onSuccessCallback?: () => void;
  messageTemplateFilter?: Nullable<MessageTemplateFilter>;
  mutation?: UseMutateFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    SendMessageRequest,
    unknown
  >;
  entityType?: Nullable<EntityType>;
  entityId?: string;
  context?: MessageTemplateContext;
  toggle: UseToggle<unknown>;
  selectors?: Nullable<JSX.Element>;
  to?: SenderOrReceiver[];
  bcc?: SenderOrReceiver[];
  formRef?: React.ForwardedRef<FormikProps<SendMessageFormikValues>>;
  initialSubject?: Nullable<string>;
  initialFiles?: File[] | FileManagerFile[];
  caseFiles?: File[];
  suggestions?: SenderOrReceiver[];
  showCaseOptions?: boolean;
};

const SendEmailModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  onSuccessCallback,
  toggle,
  selectors,
  messageTemplateFilter,
  to,
  formRef,
  initialSubject,
  bcc,
  entityType,
  mutation,
  entityId,
  initialFiles,
  caseFiles,
  context,
  showCaseOptions,
  suggestions,
}) => {
  const { t } = useTranslation();
  const {
    clearCompose,
    state: { emailAccount, replyEmail },
  } = useMailboxContext();
  const [template, setSelectedTemplate] = useState<Nullable<MessageTemplateFormatted>>(null);
  const { areMessageTemplatesLoading, messageTemplatesContentsAutocomplete } = useMessageTemplates(
    context ? (mt) => mt.context === context : messageTemplateFilter,
  );

  const { data: resolvedTemplate } = trpc.messaging.resolveTemplate.useQuery(
    {
      entityId: entityId as string,
      entityType: entityType as string,
      templateId: template?.value as string,
    },
    { enabled: !!entityId && !!template },
  );
  const { sendMessage } = useSendEmailMessage();

  const mutationFunction = mutation ?? sendMessage;

  const sendBasicMessage = (
    values: SendMessageFormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) => {
    const request = formatEmailFormData(values, emailAccount, replyEmail);
    mutationFunction(request, {
      onSettled: () => {
        setSubmitting(false);
      },
      onSuccess: () => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        clearCompose();
        toggle.hide();
      },
    });
  };

  const submit = onSubmit ?? sendBasicMessage;
  const showTemplateSelector = !!context || !!messageTemplateFilter;

  const onCloseWindow = () => {
    clearCompose();
    if (onClose) {
      onClose();
    }
  };

  return (
    <KpcModal
      className="h-full w-2/3"
      disableEnforceFocus
      title={t('sendEmail')}
      toggle={toggle}
      onClose={onCloseWindow}
    >
      <div className="flex min-h-0 w-full grow flex-col">
        <div className="flex flex-col space-y-2 px-4">
          {selectors}
          <div className="flex space-x-4">
            {showTemplateSelector && (
              <FormControl className="w-full min-w-0 flex-1">
                <FormLabel>{t('selectType', { type: t('template').toLowerCase() })}</FormLabel>
                <Autocomplete
                  className="w-full"
                  name="template"
                  disabled={areMessageTemplatesLoading}
                  onChange={(_, option) => setSelectedTemplate(option)}
                  options={messageTemplatesContentsAutocomplete.sort(
                    (a, b) => Number(a.isAutomaticTemplate) - Number(b.isAutomaticTemplate),
                  )}
                  placeholder={t('selectType', { type: t('template').toLowerCase() })}
                  value={template}
                  groupBy={(option: MessageTemplateFormatted) => {
                    if (option.isAutomaticTemplate) return t('automaticEmailTemplates');
                    return t('manualEmailTemplates');
                  }}
                />
              </FormControl>
            )}
            <EmailAccountsAutocomplete
              className={showTemplateSelector ? '' : 'w-full'}
              clearMailboxState={false}
              showLabel
            />
          </div>
        </div>
        <ComposeEmail
          subject={resolvedTemplate?.resolvedSubject ?? initialSubject}
          body={resolvedTemplate?.resolvedBody ?? ''}
          initialFiles={initialFiles}
          onSubmit={submit}
          contactSuggestions={suggestions}
          caseFiles={caseFiles}
          showCaseOptions={showCaseOptions}
          ref={formRef}
          to={to}
          bcc={bcc}
        />
      </div>
    </KpcModal>
  );
};

export default SendEmailModal;
