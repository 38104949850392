import { useContext, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  decapitalize,
  formatDisplayName,
  mergePaginatedResults,
} from '~/frontend/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import caseApi from '~/frontend/shared/api/cases/case';
import CaseStatusType from '~/common/enums/caseStatusType';
import { formatCases } from '~/frontend/shared/utils/cases/cases';
import { getNonPendingStatusFilters } from '@/containers/Cases/filters';
import { pagination } from '~/common/constants';
import { queryIds } from '~/frontend/shared/utils/constants';
import signalRMessages from '@/redux/actions/signalRMessages';
import useNotification from '~/frontend/shared/hooks/UseNotification';

const enrichQueryParams = ({ queryParams, updatedAutocomplete }) => {
  if (!updatedAutocomplete) return;

  Object.entries(updatedAutocomplete).forEach(([key, autoCompleteParams]) => {
    if (!autoCompleteParams) return;
    if (!Array.isArray(autoCompleteParams)) {
      queryParams.append(key, [autoCompleteParams.value]);
    } else {
      autoCompleteParams.forEach((param) => {
        queryParams.append(key, param.value);
      });
    }
  });
};

const useGetCases = ({ params, formatOptions, enabled = true }) => {
  const { socket } = useContext(AppContext);
  const { sendDefaultError } = useNotification();
  const { t } = useTranslation();
  const { autocomplete, ...rest } = params;
  const filterParams = Object.assign({}, ...Object.values(rest));
  const queryParams = new URLSearchParams({ ...filterParams });
  const updatedAutocomplete = {
    ...autocomplete,
    statuses:
      autocomplete?.statuses?.length && autocomplete?.statuses?.length
        ? autocomplete?.statuses?.filter((s) => Object.values(CaseStatusType).includes(s.value))
        : getNonPendingStatusFilters(t),
  };

  enrichQueryParams({ queryParams, updatedAutocomplete });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching, refetch } =
    useInfiniteQuery({
      enabled,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      onError: (error) => {
        sendDefaultError(error);
      },
      queryFn: async ({ pageParam = pagination.queryParam.FIRST_PAGE }) => {
        const { items, nextPage } = await caseApi.getCases({
          page: pageParam,
          params: queryParams.toString(),
        });
        const { currentTeam, currentTeamUser } = formatOptions;
        const formatted = formatCases({ cases: items, currentTeam, currentTeamUser });
        return {
          items: formatted,
          nextPage,
        };
      },
      queryKey: [queryIds.cases.GET_CASES, queryParams.toString()],
    });

  const handleScrollMore = (e, { baseFunc }) => {
    baseFunc(e);
    const tableWrapper = e.currentTarget;
    const scrollMore =
      tableWrapper.scrollTop + 1 > tableWrapper.scrollHeight - tableWrapper.offsetHeight;
    if (!isLoading && !isFetchingNextPage && hasNextPage && scrollMore) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    socket.on(signalRMessages.CaseUpdated, () => refetch());
    socket.on(signalRMessages.CaseRelationAdded, () => refetch());
    socket.on(signalRMessages.CaseRelationUpdated, () => refetch());
    socket.on(signalRMessages.CaseRelationRemoved, () => refetch());

    return () => {
      socket.off(signalRMessages.CaseUpdated);
      socket.off(signalRMessages.CaseRelationAdded);
      socket.off(signalRMessages.CaseRelationUpdated);
      socket.off(signalRMessages.CaseRelationRemoved);
    };
  }, [socket, refetch]);

  const cases = mergePaginatedResults(data);
  const casesAutocomplete = cases?.map((c) => ({
    label: `${c.reference}${
      c.description && c.description !== '-'
        ? ` | ${c.description.length > 50 ? `${c.description.slice(0, 50)}...` : c.description}`
        : ''
    }${
      c.relations?.filter((r) => r.isRequestor)
        ? ` | ${c.relations
            .filter((r) => r.isRequestor)
            .map((r) => formatDisplayName(r).value)
            .join(', ')}`
        : ''
    } | ${t(decapitalize(c.status))}`,
    type: c.caseType,
    value: c.id,
  }));

  return {
    cases: cases || [],
    casesAutocomplete: casesAutocomplete || [],
    casesIsFetching: isFetching,
    casesIsLoading: isLoading || isFetchingNextPage,
    hasMoreCases: hasNextPage,
    loadMoreCases: fetchNextPage,
    scrollMore: (e, opts) => handleScrollMore(e, opts),
  };
};

export default useGetCases;
