import { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormControl, FormLabel } from '@mui/joy';

import { AppContext } from '@/shared/context/context';
import { CaseContext } from '@/containers/Cases/CaseContext';
import CaseEntityOption from './CaseEntityOption';
import { sentencize } from '../../utils/helpers';
import TextCheckbox from '../checkboxes/TextCheckbox';
import { useGetCaseEntityAccess } from '../../../queries';

const propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string,
    showOnClientPage: PropTypes.bool,
  }),
  handleUpdateShowOnClientPage: PropTypes.func.isRequired,
  handleUpdateTeamAccessibility: PropTypes.func.isRequired,
  selectedCase: PropTypes.shape({
    id: PropTypes.string,
  }),

  shouldNotBeShownOnPublicPage: PropTypes.bool,
};

const ChangeAccessibilityOption = ({
  selectedCase,
  entity,
  handleUpdateTeamAccessibility,
  handleUpdateShowOnClientPage,
  shouldNotBeShownOnPublicPage,
}) => {
  const { currentTeam } = useContext(AppContext);
  const { currentCase: caseFromContext } = useContext(CaseContext);
  const { t } = useTranslation();
  const [teamsAccessibility, setTeamsAccessibility] = useState([]);

  const currentCase = Object.keys(caseFromContext).length > 0 ? caseFromContext : selectedCase;

  const { teamsWithAccessToEntity, areTeamsWithAccessLoading } = useGetCaseEntityAccess(
    currentCase.id,
    entity?.id,
  );

  const allTeamsWithAccessToCase = useMemo(
    () =>
      currentCase.relations
        ?.filter((rel) => rel.hasAccess && rel.teamId != null)
        .map((rel) => {
          const { teamId, displayName } = rel;
          return {
            displayName,
            teamId,
          };
        }) ?? [],
    [currentCase],
  );

  useEffect(() => {
    if (!allTeamsWithAccessToCase) {
      return;
    }
    const teamsWithAccess = allTeamsWithAccessToCase.map((tm) => {
      const hasAccessToEntity =
        !teamsWithAccessToEntity?.length ||
        teamsWithAccessToEntity?.some((team) => team === tm.teamId);
      return { hasAccess: hasAccessToEntity, teamId: tm.teamId };
    });
    setTeamsAccessibility(teamsWithAccess);
    handleUpdateTeamAccessibility(teamsWithAccess);
    handleUpdateShowOnClientPage(entity?.showOnClientPage);
  }, [
    allTeamsWithAccessToCase,
    entity?.showOnClientPage,
    handleUpdateShowOnClientPage,
    handleUpdateTeamAccessibility,
    teamsWithAccessToEntity,
  ]);

  const updateAccessibility = async (teamId, hasAccess) => {
    const updatedAccessibility = teamsAccessibility.map((tm) =>
      tm.teamId === teamId ? { hasAccess, teamId } : tm,
    );
    setTeamsAccessibility(updatedAccessibility);
    handleUpdateTeamAccessibility(updatedAccessibility);
  };

  return (
    <CaseEntityOption title={sentencize(t('common:editType', { type: t('common:accessibility') }))}>
      <FormControl className="mb-4">
        <FormLabel>{t('common:accessibility')}</FormLabel>
        {(entity && areTeamsWithAccessLoading) || currentTeam?.id == null ? (
          <Skeleton height={120} />
        ) : (
          <>
            {allTeamsWithAccessToCase.map((teamWithAccessToCase) => {
              const hasAccessToEntity =
                !teamsWithAccessToEntity?.length ||
                teamsWithAccessToEntity?.some((tm) => tm === teamWithAccessToCase.teamId);
              const isCurrentTeam = currentTeam.id === teamWithAccessToCase.teamId;
              return (
                <div key={teamWithAccessToCase.teamId}>
                  <TextCheckbox
                    isDefaultChecked={hasAccessToEntity}
                    yesText={t('teamXHasAccess', { team: teamWithAccessToCase.displayName })}
                    noText={t('teamXHasNoAccess', { team: teamWithAccessToCase.displayName })}
                    callback={(hasAccess) =>
                      updateAccessibility(teamWithAccessToCase.teamId, hasAccess)
                    }
                    disabled={isCurrentTeam}
                    disabledInfo={t('cannotChangeTheAccessibilityOfYourOwnTeam')}
                  />
                </div>
              );
            })}
            {!shouldNotBeShownOnPublicPage &&
              allTeamsWithAccessToCase.length ===
                teamsAccessibility.filter((tm) => tm.hasAccess).length && (
                <div className="mt-2 border-t">
                  <TextCheckbox
                    isDefaultChecked={entity?.showOnClientPage}
                    yesText={t('showOnClientPage')}
                    noText={t('doNotShowOnClientPage')}
                    callback={(showOnClientPage) => handleUpdateShowOnClientPage(showOnClientPage)}
                  />
                </div>
              )}
          </>
        )}
      </FormControl>
    </CaseEntityOption>
  );
};

ChangeAccessibilityOption.propTypes = propTypes;

export default ChangeAccessibilityOption;
