import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { Tooltip } from '@mui/joy';

import { Nullable } from '~/common/types';

type Props = {
  rowData: {
    caseRelation: Nullable<{
      displayName: string;
    }>;
  };
  value: string;
};

const DocumentFilenameCustomCell: React.FC<Props> = ({ rowData, value }) => {
  if (rowData.caseRelation) {
    return (
      <span>
        {value}{' '}
        <Tooltip title={rowData.caseRelation.displayName}>
          <span>
            <FontAwesomeIcon icon={['fad', 'link']} />
          </span>
        </Tooltip>
      </span>
    );
  }
  return value;
};

export default memo(DocumentFilenameCustomCell);
