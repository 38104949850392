import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { decapitalize, sentencize } from '../../../shared/utils/helpers';
import DamageType from '../../../shared/enums/DamageType';

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const FinishedStep = styled.div`
  margin-bottom: 5px;
`;

const propTypes = {
  data: PropTypes.shape({
    building: PropTypes.shape({
      display: PropTypes.string,
    }),
    policies: PropTypes.arrayOf(PropTypes.shape({})),
    step: PropTypes.number,
  }).isRequired,
};

const FinishedSteps = ({ data }) => {
  const { values } = useFormikContext();
  const {
    buildingSelector,
    policySelector,
    admins,
    reference,
    automaticReference,
    files,
    damageType,
    damageToCommonUnits,
    damageToPrivateUnits,
    thirdPartyInvolved,
    verbalProcess,
  } = values;

  const policies = policySelector?.policies ?? null;
  const { t } = useTranslation('common');
  const images = files.filter((f) => f.file.type.includes('image'));
  const documents = files.filter((f) => !f.file.type.includes('image'));

  const getDamageTypeTranslation = () => {
    if (!damageType) {
      return null;
    }
    let type;

    if (
      damageType === DamageType.Water ||
      damageType === DamageType.Electricity ||
      damageType === DamageType.FireOrSmoke ||
      damageType === DamageType.Glass
    ) {
      type = decapitalize(t(`common:${decapitalize(damageType.concat('Damage'))}`));
    } else if (damageType === DamageType.StormWeatherNaturalDisasters) {
      type = decapitalize(t('common:stormDamage'));
    } else {
      type = decapitalize(t(`common:${decapitalize(damageType)}`));
    }

    let conjunction = '';

    if (damageToCommonUnits || damageToPrivateUnits) {
      conjunction = t('common:to');
    } else if (thirdPartyInvolved || verbalProcess) {
      conjunction = t('common:and');
    }
    return sentencize(`${t('common:thereIsDamageTo', { type: t(type) })} 
      ${conjunction}`);
  };

  const getBooleansDescription = () => {
    const buildDescription = [];

    if (damageToCommonUnits) {
      buildDescription.push(decapitalize(t('common:commonUnits')));
    }

    if (damageToPrivateUnits) {
      buildDescription.push(decapitalize(t('common:privateUnits')));
    }

    if (thirdPartyInvolved) {
      buildDescription.push(decapitalize(t('common:thereAreThirdPartyInvolved')));
    }

    if (verbalProcess) {
      buildDescription.push(decapitalize(t('common:thereIsAnPoliceReport')));
    }

    return buildDescription.length > 0 ? buildDescription.join(` ${t('common:and')} `) : '';
  };

  return (
    <Wrapper>
      <FinishedStep>
        {data.step > 0 && buildingSelector && (
          <Alert variant="standard" severity="info" icon={false}>
            {t('common:damageFor')}: {buildingSelector.display}
          </Alert>
        )}
      </FinishedStep>
      <FinishedStep>
        {data.step > 1 && policies && (
          <Alert variant="standard" severity="info" icon={false}>
            {t('common:linkedPolicies')}: {policies.map((p) => p.policyNumber).join(' , ')}
          </Alert>
        )}
      </FinishedStep>
      <FinishedStep>
        {data.step > 2 && (
          <Alert variant="standard" severity="info" icon={false}>
            {automaticReference ? (
              <span>{t('common:automaticReferenceWillBeAssigned')} + </span>
            ) : (
              <span>
                {t('common:manualReference')}: {reference} +{' '}
              </span>
            )}
            <span>
              {admins.length} {decapitalize(t('common:administratorsAdded'))}
            </span>
          </Alert>
        )}
      </FinishedStep>
      <FinishedStep>
        {data.step > 3 && damageType && (
          <Alert variant="standard" severity="info" icon={false}>
            <span>{getDamageTypeTranslation()} </span>
            <span>{getBooleansDescription()}</span>
          </Alert>
        )}
      </FinishedStep>
      <FinishedStep>
        {data.step > 5 && files && files.length > 0 && (
          <Alert variant="standard" severity="info" icon={false}>
            {images.length && (
              <span>
                {images.length} {decapitalize(t('common:photosAdded'))}{' '}
              </span>
            )}
            {documents.length && (
              <>
                {images.length > 0 && <span> {t('common:and').toLowerCase()} </span>}
                <span>
                  {documents.length} {decapitalize(t('common:documentsAdded'))}
                </span>
              </>
            )}
          </Alert>
        )}
      </FinishedStep>
    </Wrapper>
  );
};

FinishedSteps.propTypes = propTypes;

export default FinishedSteps;
