import classNames from 'classnames';

import { Breadcrumb } from '@/types';

import PageTitle from '@/containers/Layout/PageTitle';
import Row from '@/shared/components/2.0/layout/Row';

type Props = {
  breadCrumb?: Breadcrumb[];
  children?: React.ReactNode;
  className?: string;
  subTitle?: string;
  title?: string;
};

const KpcFullPage: React.FC<Props> = ({
  breadCrumb = [],
  children = [],
  className = '',
  subTitle = '',
  title = '',
}) => (
  <div className={classNames(`m-4 flex flex-col rounded-xl`, className)}>
    {title && breadCrumb && (
      <Row className="flex space-x-4">
        <PageTitle
          className="flex-grow"
          subTitle={subTitle}
          title={title}
          breadCrumb={breadCrumb}
        />
      </Row>
    )}
    {children}
  </div>
);

export default KpcFullPage;
