import { useQuery } from '@tanstack/react-query';

import { homeownerAssociationApi } from '@/shared/api';

import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetHomeownerAssociationsAutocomplete = () => {
  const { sendDefaultError } = useNotification();
  const { data, isLoading } = useQuery({
    onError: sendDefaultError,
    queryFn: () => homeownerAssociationApi.getHomeownerAssociationsAutocomplete(),
    queryKey: [queryIds.homeownerAssociation.GET_HOMEOWNER_ASSOCIATIONS_AUTOCOMPLETE],
  });

  return {
    homeownerAssociationsAutocomplete: data || [],
    isHomeownerAssociationAutocompleteLoading: isLoading,
  };
};

export default useGetHomeownerAssociationsAutocomplete;
