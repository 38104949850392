import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatUtcDate, sentencize } from '@/shared/utils/helpers';
import API from '@/shared/api/ApiService';
import AppointmentPropTypes from '@/shared/prop-types/AppointmentPropTypes';
import CasePropTypes from '@/shared/prop-types/CasePropTypes';
import { colorText } from '@/shared/utils/palette';
import { trpc } from '@/config/trpc';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import {
  AccessibilityIcon,
  CalendarIcon,
  ClockIcon,
  DeleteIcon,
  DeleteIconAlt,
  EditIcon,
} from '@/shared/icons/Icons';
import CaseEntityAccessibility from '../Components/CaseEntityAccessibility';
import ConfirmationModal from '@/shared/components/ConfirmationModal';
import IconButton from '@/shared/components/buttons/IconButton';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import LargeTextHoverDisplay from '@/shared/components/LargeTextHoverDisplay';

const AppointmentItem = ({ appointment, currentCase, onEdit, canEdit }) => {
  const { t } = useTranslation(['common']);

  const { sendDefaultError, sendNotification } = useNotification();

  const { data: linkedCalendars, isLoading: linkedCalendarsAreLoading } =
    trpc.calendar.getLinked.useQuery();

  const deleteConfirmationToggle = useToggle();

  const canEditAppointment = useMemo(() => {
    if (!canEdit || linkedCalendarsAreLoading) return false;
    if (!appointment.linkedCalendarId) return appointment.createdByCurrentTeam;
    return linkedCalendars.find((c) => c.id === appointment.linkedCalendarId) != null;
  }, [
    appointment.createdByCurrentTeam,
    appointment.linkedCalendarId,
    canEdit,
    linkedCalendars,
    linkedCalendarsAreLoading,
  ]);

  const deleteAppointment = async () => {
    const response = await API.deleteCaseAppointment({
      appointmentId: appointment.id,
      caseId: currentCase.id,
      linkedCalendarId: appointment.linkedCalendarId,
      nylasEventId: appointment.nylasEventId,
    });

    if (response.serviceError != null || response.status !== 204) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyCancelled', { type: t('common:appointment') }),
      variant: 'success',
    });
  };

  const TitleSection = styled.div`
    font-size: 0.8rem !important;
    color: ${colorText};
    overflow: hidden;
    width: 70%;
    font-weight: bold;
  `;

  const DescriptionSection = styled.div`
    font-size: 0.7rem !important;
    left: 10px;
    color: ${colorText};
    padding-top: 0;
    overflow: hidden;
    width: 70%;
  `;

  const DateSection = styled.div`
    font-size: 0.7rem !important;
    color: ${colorText};
    padding-top: 0;
    float: right;
  `;

  return (
    <div className="appointment-item" key={appointment.id}>
      <TitleSection className="truncate">{appointment.title}</TitleSection>
      <DescriptionSection>
        <LargeTextHoverDisplay
          text={DOMPurify.sanitize(appointment.description)}
          displayLength={30}
        />
      </DescriptionSection>

      <div className="appointment-item-date">
        <DateSection>
          <KpcTooltip
            title={
              linkedCalendars?.find((c) => c.id === appointment.linkedCalendarId)?.calendarName
            }
          >
            <CalendarIcon />{' '}
          </KpcTooltip>
          <span style={{ marginRight: '5px' }}>{formatUtcDate(appointment.startTime)}</span>
          <KpcTooltip title={t('common:timestamp')}>
            <ClockIcon />{' '}
          </KpcTooltip>
          {appointment.allDay ? (
            t('common:allDay')
          ) : (
            <>
              {formatUtcDate(appointment.startTime, 'HH:mm')}-
              {formatUtcDate(appointment.endTime, 'HH:mm')}
            </>
          )}
        </DateSection>
      </div>
      <div className="appointment-item-btns">
        {canEditAppointment && (
          <>
            <IconButton
              iconComponent={<EditIcon />}
              color="keypoint"
              style={{ paddingRight: '6px' }}
              onClick={onEdit}
              tooltip={t('common:edit')}
            />
            <IconButton
              iconComponent={<DeleteIcon />}
              color="danger"
              onClick={() => deleteConfirmationToggle.show()}
              tooltip={sentencize(t('common:removeType', { type: t('common:appointment') }))}
            />
          </>
        )}
        {appointment.createdByCurrentTeam && (
          <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <CaseEntityAccessibility
              relations={currentCase?.relations}
              entity={appointment}
              customButton={<AccessibilityIcon />}
              shouldNotBeShownOnPublicPage
            />
          </div>
        )}
      </div>
      {canEditAppointment && (
        <ConfirmationModal
          onConfirm={() => deleteAppointment()}
          icon={<DeleteIconAlt />}
          color="danger"
          toggle={deleteConfirmationToggle}
        />
      )}
    </div>
  );
};

AppointmentItem.propTypes = {
  appointment: AppointmentPropTypes.isRequired,
  canEdit: PropTypes.bool.isRequired,
  currentCase: CasePropTypes.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AppointmentItem;
