import { Autocomplete, FormControl, FormLabel } from '@mui/joy';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption, Nullable } from '~/common/types';
import { BuildingRelationRole, CaseType, EntityType, MessageTemplateContext } from '~/common/enums';
import { CaseEmailFormikValues, SenderOrReceiver } from '@/types/email/EmailFormikValues';
import { File } from '@/types/documents';
import { MessageTemplate } from '~/common/types/team/messageTemplates';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { formatEmailFormData, mapRelation } from '@/shared/utils/Email/emailHelpers';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../CaseContext';
import { decapitalize } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import { useGetCaseFiles } from '@/queries';
import { useMailboxContext } from '@/containers/Mailbox/store';
import useSendEmailFromCase from '@/mutations/email/useSendEmailFromCase';

import { MessageTemplateFilter } from '@/queries/team/useMessageTemplates';
import SendEmailModal from '../../../shared/components/2.0/modals/SendEmailModal';

const allContactsAndCompaniesDescription = 'allContactsAndCompanies';
const boardOfCoOwnershipDescription = 'boardOfCoOwnership';

const syndicRoleOptions = [
  allContactsAndCompaniesDescription,
  BuildingRelationRole.Owner,
  BuildingRelationRole.Tenant,
  boardOfCoOwnershipDescription,
];
const roleOptions = [allContactsAndCompaniesDescription, ...Object.values(BuildingRelationRole)];

type Props = {
  buildingId?: string;
  initialFiles?: File[];
  initialSubject: Nullable<string>;
  toggle: UseToggle<unknown>;
};

const SendCaseEmail: React.FC<Props> = ({
  buildingId,
  initialFiles = [],
  initialSubject,
  toggle,
}) => {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<CaseEmailFormikValues>>(null);
  const {
    clearCompose,
    state: { emailAccount, replyEmail },
  } = useMailboxContext();

  const { currentCase } = useContext(CaseContext);
  const { caseFiles } = useGetCaseFiles(currentCase?.id);
  const { data: building } = trpc.building.byId.useQuery(buildingId as string, {
    enabled: !!buildingId,
  });
  const { data: boardMembers } = trpc.hoa.boardMembers.boardMemberEmailContacts.useQuery(
    building?.homeownerAssociationId as string,
    { enabled: !!building?.homeownerAssociationId },
  );
  const { data: buildingContacts } = trpc.building.emailContacts.useQuery(building?.id as string, {
    enabled: !!building?.id,
  });

  const { currentTeam } = useContext(AppContext);
  const { sendMessage } = useSendEmailFromCase();

  const [selectedBuildingRoles, setSelectedBuildingRoles] = useState<AutocompleteOption[]>([]);

  const caseRelations = useMemo(
    () => currentCase.relations.flatMap(mapRelation),
    [currentCase.relations],
  );

  const allContactsAndCompaniesOption = useMemo(
    () => ({
      label: t(allContactsAndCompaniesDescription),
      value: allContactsAndCompaniesDescription,
    }),
    [t],
  );

  const submit = (
    values: CaseEmailFormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) => {
    const request = formatEmailFormData(values, emailAccount, replyEmail);
    sendMessage(
      {
        ...request,
        caseId: currentCase.id,
        emailAccountLinkId: emailAccount?.linkId,
      },
      {
        onSettled: () => setSubmitting(false),
        onSuccess: () => {
          clearCompose();
          toggle.hide();
        },
      },
    );
  };

  const buildingRoleOptions = useMemo(
    () =>
      (currentTeam?.allowSyndicManagement ? syndicRoleOptions : roleOptions)
        .map((r) => ({
          label: t(decapitalize(r)),
          value: r,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [currentTeam?.allowSyndicManagement, t],
  );

  const changeSelectedRoles = (roles: AutocompleteOption[]) => {
    if (roles.some((r) => r.value === allContactsAndCompaniesDescription)) {
      setSelectedBuildingRoles([allContactsAndCompaniesOption]);
    } else {
      setSelectedBuildingRoles(roles);
    }
  };

  const filterRepairCases = (mt: MessageTemplate) => {
    if (mt.predicates && !!mt.predicates.length) {
      const hadContractPredicate = mt.predicates.some(
        (predicate) =>
          predicate.predicateIdentifier === 'Contract' &&
          predicate.predicateValues.includes(currentCase?.contractDescription as string),
      );
      return (
        mt.context === MessageTemplateContext.CaseRepair &&
        (mt.predicates.length === 0 || hadContractPredicate)
      );
    }

    return mt.context === MessageTemplateContext.CaseRepair;
  };

  const templateFilter = (mt: MessageTemplate) => {
    let filter = null;
    switch (currentCase?.caseType) {
      case CaseType.Case:
        filter = mt.context === MessageTemplateContext.Case;
        break;
      case CaseType.Repair:
        filter = filterRepairCases(mt);
        break;
      case CaseType.Complaint:
        filter = mt.context === MessageTemplateContext.CaseComplaint;
        break;
      case CaseType.InsuranceClaim:
        filter = mt.context === MessageTemplateContext.CaseInsurance;
        break;
      default:
        return null;
    }

    return filter;
  };

  useEffect(() => {
    if (!selectedBuildingRoles?.length || !buildingContacts?.length) {
      formRef.current?.setFieldValue('bcc', []);
      return;
    }

    const filterOnRole = (relation: { roles: string[] }) =>
      relation.roles.some((role) => selectedBuildingRoles.some(({ value }) => role === value));

    let bccList: SenderOrReceiver[] = [];
    if (selectedBuildingRoles.some((role) => role.value === allContactsAndCompaniesDescription)) {
      bccList = buildingContacts.map(({ roles, ...rest }) => rest);
    } else bccList = buildingContacts?.filter(filterOnRole).map(({ roles, ...rest }) => rest);

    if (
      selectedBuildingRoles.some((br) => br.value === boardOfCoOwnershipDescription) &&
      boardMembers?.length
    ) {
      bccList = [...bccList, ...boardMembers].filter(
        (relation, index, self) =>
          index ===
          self.findIndex(
            (r) => r.contactId === relation.contactId && r.companyId === relation.companyId,
          ),
      );
    }
    formRef.current?.setFieldValue('bcc', bccList);
  }, [selectedBuildingRoles, t, allContactsAndCompaniesOption, boardMembers, buildingContacts]);
  const selectors = buildingId ? (
    <FormControl>
      <FormLabel>{t('buildingContactRoles')}</FormLabel>
      <Autocomplete
        name="buildingRole"
        size="sm"
        onChange={(_, option) => changeSelectedRoles(option)}
        options={buildingRoleOptions}
        placeholder={t('buildingContactRoles')}
        value={selectedBuildingRoles}
        multiple
      />
    </FormControl>
  ) : null;

  return (
    <SendEmailModal
      initialSubject={initialSubject}
      selectors={selectors}
      entityType={EntityType.Case}
      entityId={currentCase.id}
      messageTemplateFilter={templateFilter as MessageTemplateFilter}
      onSubmit={submit}
      initialFiles={initialFiles}
      suggestions={caseRelations}
      showCaseOptions
      toggle={toggle}
      caseFiles={caseFiles}
      formRef={formRef}
      bcc={(replyEmail?.bcc as SenderOrReceiver[]) ?? undefined}
    />
  );
};

export default SendCaseEmail;
