import { useTranslation } from 'react-i18next';

import FormikAutocomplete from '../../2.0/formik/FormikAutocomplete';
import useDocumentTypeOptions from '@/shared/hooks/useDocumentTypeOptions';

const DocumentsTypeForm = () => {
  const { t } = useTranslation();
  const documentTypeSuggestions = useDocumentTypeOptions();

  return (
    <div className="py-4">
      <FormikAutocomplete
        freeSolo
        name="type"
        options={documentTypeSuggestions}
        label={t('type')}
      />
    </div>
  );
};

export default DocumentsTypeForm;
