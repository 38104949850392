import { useLayoutEffect, useRef, useState } from 'react';

const useTruncated = () => {
  const [isTruncated, setIsTruncated] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (containerRef.current && childRef.current) {
      setIsTruncated(childRef.current.offsetWidth > containerRef.current.offsetWidth);
    }
  }, [containerRef.current?.offsetWidth]);

  return {
    childRef,
    containerRef,
    isTruncated,
  };
};

export default useTruncated;
