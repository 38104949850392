enum InboundHoaInvoiceVatCode {
  Unspecified = 'Unspecified',
  PurchaseServices0 = 'PurchaseServices0',
  PurchaseServices6 = 'PurchaseServices6',
  PurchaseServices12 = 'PurchaseServices12',
  PurchaseServices21 = 'PurchaseServices21',
  PurchaseServicesVatExempt = 'PurchaseServicesVatExempt',
}

export default InboundHoaInvoiceVatCode;
