/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';
import { HubConnection } from '@microsoft/signalr';

import { CurrentTeam, CurrentTeamUser } from '@/types/trpc/team';
import { State } from '@/reducers/ui';

type AppContextType = {
  ui: State;
  setUi: React.Dispatch<{ type: string; payload?: any }>;
  currentTeam?: CurrentTeam;
  currentTeamId?: string;
  currentTeamUser?: CurrentTeamUser;
  refetchCurrentTeam: () => void;
  socket: HubConnection | null;
};

export const AppContext = createContext({} as AppContextType);
