import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { CaseType } from '~/common/enums';

import addressIcon from '../img/address/undrraw_address_blue.svg';
import buildingIcon from '../img/buildings/undraw_building_blue.svg';
import policyIcon from '../img/policy/undraw_policy_blue.svg';

type IconProps = {
  [key: string]: unknown;
};

type StyledIconProps = IconProps & {
  iconStyle?: IconPrefix;
};

export const AdminIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'screwdriver-wrench']} {...props} />
);

export const AdministratorIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'user-crown']} {...props} />
);

export const HomeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'house']} {...props} />
);

export const AutomaticIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'gears']} {...props} />
);

export const SettingsIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'gear']} {...props} />
);

export const ManualIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'hand']} {...props} />
);

export const MailboxIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'inbox-full']} {...props} />
);

export const CalendarIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'calendar-days']} {...props} />
);

export const CalendarDayIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'calendar-day']} {...rest} />
);

export const DueDateIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'calendar-circle-exclamation']} {...props} />
);

export const ReminderDateIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'calendar-clock']} {...props} />
);

export const CircleExclamation: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'circle-exclamation']} {...props} />
);
export const PlayIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'play']} {...props} />
);

export const PauseIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'pause']} {...props} />
);

export const ClockIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'clock']} {...props} />
);

export const LoaderIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'loader']} {...props} />
);

export const ContactIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'address-book']} {...props} />
);

export const CompanyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'industry-windows']} {...props} />
);

export const ValidCompanyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'shield-check']} {...props} />
);

export const InsurancePolicyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-contract']} {...props} />
);

export const BuildingIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'house-building']} {...props} />
);

export const FilterIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'filter']} {...props} />
);

export const ClearFilterIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'filter-circle-xmark']} {...props} />
);

export const CaseIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'box-archive']} {...props} />
);

export const InsuredCaseIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'umbrella']} {...props} />
);

export const RepairCaseIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'screwdriver-wrench']} {...props} />
);

export const ComplaintCaseIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'message-exclamation']} {...props} />
);

export const MarketplaceIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'shopping-cart']} {...props} />
);

export const IntegrationIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'link-simple']} {...props} />
);

export const ReportIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'chart-line']} {...props} />
);

export const SearchIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'magnifying-glass']} {...props} />
);

export const TeamIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'people-group']} {...props} />
);

export const DeleteIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'trash']} {...props} />
);

export const DeleteIconAlt: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'trash-alt']} {...props} />
);

export const EditIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'pencil']} {...props} />
);

export const SaveIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'save']} {...props} />
);

export const UndoIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'undo']} {...props} />
);

export const RedoIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'redo']} {...props} />
);

export const AcceptIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'check-circle']} {...props} />
);

export const CancelIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'circle-xmark']} {...props} />
);

export const CircleIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['far', 'circle']} {...props} />
);

export const FullCircleIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'circle']} {...props} />
);

export const TimerIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['far', 'timer']} {...props} />
);

export const PreviewIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-magnifying-glass']} {...props} />
);

export const OpenIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-right-to-arc']} {...props} />
);

export const NewTabIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-up-right-from-square']} {...props} />
);

export const UpdateIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-right-arrow-left']} {...props} />
);

export const AddIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'plus']} {...rest} />
);

export const KeyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'key']} {...props} />
);

export const LogoutIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-right-from-bracket']} {...props} />
);
export const DivisionKeyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'split']} {...props} />
);

export const RepeatIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'repeat']} {...props} />
);

export const ExcelIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-excel']} {...props} />
);

export const CsvIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-csv']} {...props} />
);

export const UserIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'user']} {...props} />
);

export const DropDownIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'chevron-down']} {...props} />
);

export const EuroIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'euro-sign']} {...props} />
);

export const EuroCircleIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'circle-euro']} {...props} />
);

export const DropDownIconUp: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'chevron-up']} {...props} />
);

export const TaskIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'flag-pennant']} {...props} />
);

export const SnoozeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'alarm-snooze']} {...props} />
);

export const PriorityIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrows-from-dotted-line']} {...props} />
);

export const PriorityLowIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'down-from-dotted-line']} {...props} />
);

export const PriorityNormalIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'horizontal-rule']} {...props} />
);

export const PriorityHighIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'up-from-dotted-line']} {...props} />
);

export const BlogIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'rss']} {...props} />
);

export const AddressIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'location-dot']} {...props} />
);

export const DownloadIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'download']} {...props} />
);

export const UploadIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'upload']} {...props} />
);

export const ValidIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'circle-check']} {...props} />
);

export const ValidIconSolid: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'circle-check']} {...props} />
);

export const InvalidIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'circle-xmark']} {...props} />
);

export const NeutralIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'circle-minus']} {...props} />
);

export const ImportIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'cloud-arrow-up']} {...props} />
);

export const EmailIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'envelope']} {...props} />
);

export const LetterIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'mailbox']} {...props} />
);

export const SmsIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'message']} {...props} />
);

export const PhoneIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'phone']} {...props} />
);

export const InboundPhoneCall: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'phone-arrow-down-left']} {...props} />
);

export const OutBoundPhoneCall: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'phone-arrow-up-right']} {...props} />
);

export const DebtorsIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'sack-dollar']} {...props} />
);

export const MobilePhoneIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'mobile']} {...props} />
);

export const InboundIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'inbox-in']} {...props} />
);

export const OutboundIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'inbox-out']} {...props} />
);

export const InsuranceIntermediaryIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'umbrella']} {...props} />
);

export const InsurerIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'fire-extinguisher']} {...props} />
);

export const InsuranceEngineerIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'briefcase']} {...props} />
);

export const SpreadsheetIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-spreadsheet']} {...props} />
);

export const PropertyManagerIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'gavel']} {...props} />
);

export const HomeOwnerAssociationIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'building-user']} {...props} />
);

export const PermissionsIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'shield-check']} {...props} />
);

export const InfoIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'circle-info']} {...rest} />
);

export const FinanceIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'coins']} {...props} />
);

export const CompletedIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'check']} {...props} />
);

export const LinkIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'link']} {...props} />
);

export const UnlinkIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'link-slash']} {...props} />
);

export const ChartPieIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'chart-pie']} {...props} />
);

export const FemaleIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'venus']} {...props} />
);
export const MaleIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'mars']} {...props} />
);
export const UnknownGenderIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'mars-and-venus']} {...props} />
);

export const AssignIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'person-arrow-down-to-line']} {...props} />
);

export const RiskObjectGuaranteeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'badge-check']} {...props} />
);

export const SkipIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-right-long-to-line']} {...props} />
);

export const ClearIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'xmark']} {...props} />
);

export const LoadingIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'spinner-third']} spin {...props} />
);

export const PreviousIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'arrow-left']} {...props} />
);

export const NextIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'arrow-right']} {...props} />
);

export const RefreshIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrows-rotate']} {...props} />
);

export const SyncIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrows-rotate']} {...props} />
);

export const SyncingIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrows-rotate']} spin {...props} />
);

export const IndentationArrowIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrow-turn-down-right']} {...props} />
);

export const NoteIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'sticky-note']} {...rest} />
);

export const WarningIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'triangle-exclamation']} {...props} />
);

export const SendIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'paper-plane']} {...props} />
);

export const SendArrowIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'paper-plane-top']} {...props} />
);

export const SwapIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'rotate-reverse']} {...props} />
);

export const AngleUpIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'angle-up']} {...props} />
);

export const AngleDownIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'angle-down']} {...props} />
);

export const AngleRightIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'angle-right']} {...props} />
);
export const AngleLeftIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'angle-left']} {...props} />
);

export const AccessibilityIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'eye']} {...rest} />
);

export const CommentIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'comment']} {...rest} />
);

export const BuildingIconPretty = () => (
  <img className="block h-16 w-16" src={buildingIcon} alt="building-icon" />
);

export const AddressIconPretty = () => (
  <img className="block h-16 w-16" src={addressIcon} alt="address-icon" />
);

export const InsurancePolicyIconPretty = () => (
  <img className="block h-16 w-16" src={policyIcon} alt="policy-icon" />
);

export const BanIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'ban']} {...props} />
);

export const GlobeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'globe']} {...props} />
);

export const PinIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon="thumbtack" {...props} />
);

export const ThumbsUpIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'thumbs-up']} {...props} />
);

export const ThumbsDownIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'thumbs-down']} {...props} />
);

export const FileSignatureIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-signature']} {...props} />
);

export const FileIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'file']} {...rest} />
);

export const EllipsisIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon="ellipsis" {...props} />
);

export const EllipsisIconVertical: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon="ellipsis-vertical" {...props} />
);

export const FolderTreeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'folder-tree']} {...props} />
);

export const FoldersIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'folders']} {...rest} />
);

export const FolderIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'folder']} {...rest} />
);

export const ListIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'list']} {...props} />
);

export const LockIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'lock']} {...props} />
);

export const ClassificationIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'tags']} {...props} />
);

export const ResponsibilityIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'user-police-tie']} {...props} />
);

export const ColumnsIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'eye']} {...props} />
);

export const ColumnsIconSlash: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'eye-slash']} {...props} />
);

export const HandshakeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'handshake']} {...props} />
);

export const SignatureIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'signature']} {...props} />
);

export const PiggyBankIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'piggy-bank']} {...props} />
);
export const MergeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'layer-group']} {...props} />
);

export const MoreFiltersIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'diagram-next']} {...props} />
);

export const HideFiltersIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'diagram-previous']} {...props} />
);

export const InlineSpinner: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'circle-notch']} {...props} spin />
);

export const LanguageIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'language']} {...props} />
);

export const IbanBicIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'credit-card-front']} {...props} />
);

export const PaymentPeriodIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'money-check-dollar-pen']} {...props} />
);

export const MeetingIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'calendar-lines-pen']} {...props} />
);

export const ReminderIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'bell']} {...props} />
);

export const SupportIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'user-headset']} {...props} />
);

export const FsmaIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'input-numeric']} {...props} />
);

export const QrIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'qrcode']} {...props} />
);

export const CopyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'copy']} {...props} />
);

export const MenuIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'bars']} {...props} />
);

export const ProcurationIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'book-section']} {...props} />
);

export const ImageIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'image']} {...props} />
);

export const ReceiptIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'receipt']} {...props} />
);

export const InvoiceIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'file-invoice']} {...props} />
);

export const AbstainIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'xmark-to-slot']} {...props} />
);

export const IndividualDivisionIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'people']} {...props} />
);

export const DividerIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'pipe']} {...props} />
);

export const AttachmentsIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'paperclip']} {...rest} />
);

export const ChevronsDownIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'chevrons-down']} {...props} />
);

export const WelcomeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'hand-wave']} {...props} />
);

export const ColumnGridIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'columns-3']} {...props} />
);

export const CashRegisterIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'cash-register']} {...props} />
);

export const MaximizeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'expand']} {...props} />
);

export const MinimizeIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'window-minimize']} {...props} />
);

export const GripIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'grip-lines']} {...props} />
);

export const ChevronLeftIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'chevron-left']} {...props} />
);

export const ChevronRightIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'chevron-right']} {...props} />
);

export const ZoomInIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'magnifying-glass-plus']} {...props} />
);

export const ZoomOutIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'magnifying-glass-minus']} {...props} />
);

export const CenterIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'arrows-to-circle']} {...props} />
);

export const OrderIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'arrow-down-arrow-up']} {...rest} />
);

export const BarFilterIcon: React.FC<StyledIconProps> = ({ iconStyle, ...rest }) => (
  <FontAwesomeIcon icon={[iconStyle ?? 'fad', 'bars-filter']} {...rest} />
);

export const CheckboxIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'square-check']} {...props} />
);

export const CurrencyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'square-dollar']} {...props} />
);

export const EmailIconAlt: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'at']} {...props} />
);

export const CalculatorIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'input-numeric']} {...props} />
);

export const TextIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fad', 'text']} {...props} />
);

export const UploadIconAlt: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'arrow-up-from-bracket']} {...props} />
);

export const EmptyIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'empty-set']} {...props} />
);

export const CheckIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'check']} {...props} />
);

export const RoundingIcon: React.FC<IconProps> = (props) => (
  <FontAwesomeIcon icon={['fas', 'arrow-down-up-across-line']} {...props} />
);

type CaseTypeIconProps = IconProps & {
  caseType: CaseType;
};

export const CaseTypeIcon: React.FC<CaseTypeIconProps> = ({ caseType, ...props }) => {
  const caseTypeIconMap = {
    [CaseType.Case]: <CaseIcon {...props} />,
    [CaseType.Complaint]: <ComplaintCaseIcon {...props} />,
    [CaseType.InsuranceClaim]: <InsuredCaseIcon {...props} />,
    [CaseType.Repair]: <RepairCaseIcon {...props} />,
  };

  return caseTypeIconMap[caseType];
};
