import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Priority from '../../../shared/enums/Priority';

import { PriorityHighIcon, PriorityLowIcon, PriorityNormalIcon } from '../../../shared/icons/Icons';
import Tooltip from '../../../shared/components/2.0/Tooltip';

const icons = {
  [Priority.High]: <PriorityHighIcon />,
  [Priority.Low]: <PriorityLowIcon />,
  [Priority.Normal]: <PriorityNormalIcon />,
};

const propTypes = {
  value: PropTypes.string.isRequired,
};

const PriorityCustomCell = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={`${t('common:priority')}: ${value.toLowerCase()}`}>{icons[value]}</Tooltip>
  );
};

PriorityCustomCell.propTypes = propTypes;

export default memo(PriorityCustomCell);
