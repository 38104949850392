import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Col, FormGroup, Row } from 'react-bootstrap';
import FormikCrmSelector from '../../../shared/formik/FormikCrmSelector';
import ReferencePropType from '../../../shared/prop-types/ReferencePropType';

const AddPolicyRelationFormBody = ({ popperContainer }) => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const insuredCrmSelection = useCallback(
    (crmValues) => {
      const { company, contact, team } = crmValues;
      setFieldValue('insuredCompanyId', company?.id);
      setFieldValue('insuredTeamId', team?.id);
      setFieldValue('insuredContactId', contact?.id);
    },
    [setFieldValue],
  );

  const insurerCrmSelection = useCallback(
    (crmValues) => {
      const { company } = crmValues;
      setFieldValue('insurerCompanyId', company?.id);
    },
    [setFieldValue],
  );

  return (
    <Row>
      <Col md={6}>
        <FormGroup className="mb-3">
          <FormikCrmSelector
            selectSingle
            id="insured"
            name="insured"
            label={t('common:insured')}
            showContacts
            showCompanies
            showTeams
            callback={insuredCrmSelection}
            popperContainer={popperContainer}
            disablePortal={false}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup className="mb-3">
          <FormikCrmSelector
            selectSingle
            id="insurer"
            name="insurer"
            label={t('common:insurer')}
            showCompanies
            callback={insurerCrmSelection}
            popperContainer={popperContainer}
            disablePortal={false}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

AddPolicyRelationFormBody.propTypes = {
  popperContainer: ReferencePropType,
};

AddPolicyRelationFormBody.defaultProps = {
  popperContainer: null,
};

export default AddPolicyRelationFormBody;
