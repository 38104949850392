/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';

import { Case, CaseEntity, CaseRelation } from '@/types/cases';
import { CaseType } from '~/common/enums';

type CaseContextType = {
  canEditCase: boolean;
  caseEntities: CaseEntity[];
  caseType: CaseType;
  currentCase: Case;
  ownRelation: CaseRelation;
  refetchCase: () => void;
  refetchCaseEntities: () => void;
};

export const CaseContext = createContext({
  canEditCase: false,
  caseEntities: [],
  caseType: CaseType.Case,
  currentCase: {} as Case,
  ownRelation: {} as CaseRelation,
  refetchCase: () => {},
  refetchCaseEntities: () => {},
} as CaseContextType);
