import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';

const StringOptionsFilter = (props) => {
  const { t } = useTranslation('common');

  const { callback, name, defaultValue, label, options, multiple, ...rest } = props;

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple={multiple}
      options={options}
      isOptionEqualToValue={(opt, val) => opt === val}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect={multiple}
      filterSelectedOptions
      {...rest}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:contacts')} />
      )}
    />
  );
};

StringOptionsFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  multiple: PropTypes.bool,
};

StringOptionsFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
  label: null,
  multiple: true,
};

export default StringOptionsFilter;
