import { useQuery } from '@tanstack/react-query';

import caseApi from '../../shared/api/cases/case';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useSearchCases = (searchTerm) => {
  const { sendDefaultError } = useNotification();
  const { data, isFetching, refetch } = useQuery({
    enabled: false, // disable this query from automatically running
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: () => caseApi.searchCases(searchTerm),
    queryKey: [queryIds.cases.SEARCH_CASES, searchTerm],
  });

  return { data, isFetching, refetch };
};

export default useSearchCases;
