import { Form, Formik } from 'formik';

import { Building } from '@/types/trpc/building';

import useAddEditBuildingFormConfig, {
  AddEditBuildingFormikValues,
} from './AddEditBuildingFormConfig';
import AddEditBuildingFormBody from './AddEditBuildingFormBody';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

type Props = {
  initialBuilding?: Building;
  minified?: boolean;
  onCancel: () => void;
  onSubmit: (values: AddEditBuildingFormikValues) => void;
  isSubmitting?: boolean;
};

const AddEditBuildingForm: React.FC<Props> = ({
  initialBuilding,
  onCancel,
  minified = false,
  onSubmit,
  isSubmitting,
}) => {
  const { initialValues, validationSchema } = useAddEditBuildingFormConfig(initialBuilding);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      <Form>
        <AddEditBuildingFormBody isEdit={!!initialBuilding} minified={minified} />
        <ModalFooter onCancel={onCancel} isSubmitting={isSubmitting} />
      </Form>
    </Formik>
  );
};

export default AddEditBuildingForm;
