import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentencize } from '@/shared/utils/helpers';

import FormikTextField from './formik/FormikTextField';
import KpcModal from './layout/KpcModal';
import { SaveIcon } from '@/shared/icons/Icons';

const propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  remark: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

const RemarkModal = ({ id = null, isOpen, remark = '', setIsOpen, submit }) => {
  const { t } = useTranslation('common');

  return (
    <KpcModal
      toggle={{
        hide: () => setIsOpen(false),
        value: isOpen,
      }}
      title={sentencize(
        t(remark ? 'common:editType' : 'common:addType', { type: t('common:remark') }),
      )}
    >
      <Formik
        onSubmit={(values, { setSubmitting }) => submit(id, values.remark, setSubmitting)}
        initialValues={{ remark }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField name="remark" placeholder={t('remark')} />
            <div className="flex justify-end space-x-4 pt-4">
              <Button size="sm" variant="soft" color="neutral" onClick={() => setIsOpen(false)}>
                {t('buttons.cancel')}
              </Button>
              <Button
                startDecorator={<SaveIcon />}
                size="sm"
                variant="solid"
                type="submit"
                loading={isSubmitting}
              >
                {t('buttons.save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </KpcModal>
  );
};

RemarkModal.propTypes = propTypes;

export default RemarkModal;
