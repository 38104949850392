import { FormControl, FormHelperText, FormLabel } from '@mui/joy';
import { SyntheticEvent } from 'react';
import { useFormikContext } from 'formik';

import { AsyncAutocompleteFilter, AutocompleteOption, PaginatedAutocomplete } from '~/common/types';

import AsyncAutocomplete from '~/frontend/shared/components/2.0/autocomplete/AsyncAutoComplete';

type Props = {
  callback?: (value: string | null) => void;
  filter?: AsyncAutocompleteFilter;
  label?: string;
  name: string;
  queryFn: (params: {
    search: string;
    page: number;
    filter: AsyncAutocompleteFilter | undefined;
  }) => Promise<PaginatedAutocomplete>;
  queryId: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: AutocompleteOption;
};

const AsyncFormikSelector: React.FC<Props> = ({
  callback = null,
  filter,
  label = null,
  name,
  queryFn,
  queryId,
  required = false,
  placeholder,
  defaultValue,
  ...rest
}) => {
  const { touched, errors, setFieldValue } = useFormikContext<{ [name: string]: string | null }>();

  const handleChange = (_: SyntheticEvent<Element, Event>, val: AutocompleteOption | null) => {
    if (callback && val) {
      callback(val.value);
    }
    if (callback && !val) {
      callback(null);
    }
    if (val) {
      setFieldValue(name, val.value);
    } else {
      setFieldValue(name, null);
    }
  };

  const hasError = !!(touched[name] && errors[name]);

  return (
    <FormControl error={hasError}>
      <FormLabel required={required}>{label}</FormLabel>
      <AsyncAutocomplete
        onClearCallback={() => setFieldValue(name, null)}
        queryFn={queryFn}
        queryId={queryId}
        onChange={(e, val) => handleChange(e, val)}
        filter={filter}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...rest}
      />
      {hasError && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default AsyncFormikSelector;
