import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { forwardRef } from 'react';

const DEFAULT_VALUES = {
  decimalScale: 2,
  decimalSeparator: ',',
  thousandSeparator: '.',
  valueIsNumericString: true,
};

type BaseProps = Omit<
  NumericFormatProps,
  'getInputRef' | 'isAllowed' | 'max' | 'min' | 'onValueChange'
>;

export type NumericFormatAdapterProps = BaseProps & {
  max?: number;
  min?: number;
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
};

const NumericFormatAdapter = forwardRef<
  HTMLInputElement,
  NumericFormatAdapterProps & NumericFormatProps
>(({ max, min, onChange, ...numericFormatProps }, ref) => (
  <NumericFormat
    getInputRef={ref}
    isAllowed={(values) => {
      const { floatValue } = values;

      if (!floatValue) return true;

      const passesMax = !max || floatValue <= max;
      const passesMin = !min || floatValue >= min;

      return passesMax && passesMin;
    }}
    onValueChange={(values) => {
      onChange({
        target: {
          name: numericFormatProps.name,
          value: values.value,
        },
      });
    }}
    {...DEFAULT_VALUES}
    {...numericFormatProps}
  />
));

export default NumericFormatAdapter;
