import { getIn, useFormikContext } from 'formik';

import { RichTextEditorProps } from '../../richTextEditor/types';

import FormField, { FieldProps } from '../input/FormField';
import RichTextEditor from '../../richTextEditor/RichTextEditor';

type ControlProps = Pick<FieldProps, 'label' | 'required'>;

type Props = ControlProps &
  RichTextEditorProps & {
    name: string;
  };

const FormikRichTextEditor: React.FC<Props> = ({ name, label, required, ...editorProps }) => {
  const { touched, errors, values, setFieldValue, setFieldTouched } = useFormikContext();

  const error = getIn(touched, name) ? getIn(errors, name) : undefined;
  const value = getIn(values, name);

  return (
    <FormField className="flex-1" label={label} required={required} error={error}>
      <RichTextEditor
        value={value}
        callback={(content) => setFieldValue(name, content)}
        onBlur={() => setFieldTouched(name, true)}
        height={editorProps.height || 200}
        {...editorProps}
      />
    </FormField>
  );
};

export default FormikRichTextEditor;
