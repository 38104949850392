import { useContext, useMemo } from 'react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { decapitalize } from '../../../../../../shared/utils/helpers';
import PhoneCallItemPropTypes from '../../../../../../shared/prop-types/PhoneCallItemPropTypes';
import useToggle from '../../../../../../shared/hooks/UseToggle';

import { CreatedByField, DescriptionField, InfoSection, TypeName } from '../ItemStyles/Item-Styles';
import { InboundPhoneCall, OutBoundPhoneCall } from '../../../../../../shared/icons/Icons';
import PhoneCallModal from '../../../../../PhoneCalls/PhoneCallModal';
import WrappedText from '../../../../../../shared/components/WrappedText';

const phoneCallIconStyle = { margin: '0px 10px' };

const propTypes = {
  phoneCall: PhoneCallItemPropTypes.isRequired,
};

const PhoneCallItem = ({ phoneCall }) => {
  const { currentCase, canEditCase } = useContext(CaseContext);
  const phoneCallToggle = useToggle();
  const { t } = useTranslation('common');

  const currentCaseRelation = useMemo(
    () => currentCase?.relations?.find((r) => r.id === phoneCall?.calleeRelationId),
    [currentCase, phoneCall],
  );

  return (
    <>
      <TypeName>
        {t('common:phoneCall')}
        {phoneCall.isInbound ? (
          <InboundPhoneCall style={phoneCallIconStyle} />
        ) : (
          <OutBoundPhoneCall style={phoneCallIconStyle} />
        )}
      </TypeName>
      <CreatedByField>{phoneCall.createdByUser}</CreatedByField>

      <br />

      {phoneCall.note && (
        <InfoSection>
          <DescriptionField>{t('common:subject')}: </DescriptionField>
          <WrappedText>{phoneCall.note}</WrappedText>
        </InfoSection>
      )}

      <InfoSection>
        {currentCaseRelation?.phoneNumber &&
          currentCaseRelation?.phoneNumber.countryCode &&
          currentCaseRelation?.phoneNumber.number &&
          !currentCaseRelation?.mobilePhoneNumber.number && (
            <>
              <DescriptionField>{t('common:phoneNumber')}: </DescriptionField>
              {`${currentCaseRelation.phoneNumber.countryCode ?? ''}${
                currentCaseRelation.phoneNumber.number ?? ''
              }`}
            </>
          )}
      </InfoSection>
      <InfoSection>
        {currentCaseRelation?.mobilePhoneNumber &&
          currentCaseRelation?.mobilePhoneNumber.countryCode &&
          currentCaseRelation?.mobilePhoneNumber.number && (
            <>
              <DescriptionField>{t('common:mobilePhoneNumber')}: </DescriptionField>
              {`${currentCaseRelation.mobilePhoneNumber.countryCode ?? ''}${
                currentCaseRelation.mobilePhoneNumber.number ?? ''
              }`}
            </>
          )}
      </InfoSection>

      {!phoneCall.isInbound && (
        <InfoSection>
          <DescriptionField>{t('common:outcome')}: </DescriptionField>
          <span>{t(`common:${decapitalize(phoneCall.outcome)}`)}</span>
        </InfoSection>
      )}

      {phoneCall.callee && (
        <InfoSection>
          <DescriptionField>{t('common:relation')}: </DescriptionField>
          <span>{phoneCall.callee}</span>
        </InfoSection>
      )}

      {canEditCase && (
        <div style={{ float: 'right' }}>
          <Button variant="plain" onClick={phoneCallToggle.show}>
            {t('common:viewDetails')}
          </Button>
          <PhoneCallModal
            phoneCallToUpdate={{
              callDate: phoneCall.callDate,
              calleeRelationId: phoneCall.calleeRelationId,
              id: phoneCall.id,
              isInbound: phoneCall.isInbound,
              note: phoneCall.note,
              phoneCallOutcome: phoneCall.outcome,
              showOnClientPage: phoneCall.showOnClientPage,
            }}
            toggle={phoneCallToggle}
            canEditAccess={phoneCall.createdByCurrentTeam}
          />
        </div>
      )}
    </>
  );
};

export default PhoneCallItem;

PhoneCallItem.propTypes = propTypes;
