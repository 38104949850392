import { useCallback, useMemo } from 'react';

import {
  AutocompleteOption,
  AutocompleteOptionsArgs,
  UseAutocompleteOptionsResult,
} from './autocomplete.types';
import { HomeownerAssociationAccountOption } from '@/types/trpc/hoa';

import { GeneralLedgerAccountType, ProvisionType } from '~/common/enums';
import { getDefaultAccountTranslation } from '@/shared/utils/hoa/hoaUtils';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

const glaTypeMapping = {
  [ProvisionType.FixedWorkCapital]: GeneralLedgerAccountType.FixedWorkCapital,
  [ProvisionType.SpareCapital]: GeneralLedgerAccountType.ReserveCapital,
  [ProvisionType.WorkCapital]: GeneralLedgerAccountType.WorkCapital,
};

type HoaOptionExtension = Pick<HomeownerAssociationAccountOption, 'data'>;

type Options = {
  homeownerAssociationId: string | null | undefined;
  startsWith?: string[];
  provisionType?: ProvisionType;
} & AutocompleteOptionsArgs<string, HoaOptionExtension>;

function useHoaAccountAutocomplete(
  options: Options & { disableClearable?: boolean; multiple: true },
): UseAutocompleteOptionsResult<string, true, HoaOptionExtension>;

function useHoaAccountAutocomplete(
  options: Options & { disableClearable: true; multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, HoaOptionExtension>;

function useHoaAccountAutocomplete(
  options: Options & { disableClearable?: false; multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, HoaOptionExtension>;

function useHoaAccountAutocomplete({
  callback,
  disableClearable,
  homeownerAssociationId,
  multiple,
  selected,
  startsWith = [],
  provisionType,
  ...args
}: Options): UseAutocompleteOptionsResult<string, typeof multiple, HoaOptionExtension> {
  const { data: options, isLoading } = trpc.hoa.account.autocomplete.useQuery(
    {
      homeownerAssociationId: homeownerAssociationId as string,
      startsWith: startsWith || [],
    },
    { enabled: !!homeownerAssociationId },
  );

  const translatedOptions = useMemo(() => {
    if (!options) return [];

    const translateLabel = (option: HomeownerAssociationAccountOption) => {
      const { label } = option;
      const { defaultAccount, number } = option.data;

      return getDefaultAccountTranslation(label, number, defaultAccount);
    };

    return options
      .filter((o) => !provisionType || o.data.type === glaTypeMapping[provisionType])
      .map((option) => ({
        ...option,
        label: translateLabel(option),
      }));
  }, [provisionType, options]);

  const handleCallback = useCallback(
    (
      newValue:
        | AutocompleteOption<string, HoaOptionExtension>[]
        | AutocompleteOption<string, HoaOptionExtension>
        | null,
    ) => {
      const multipleOptions = Array.isArray(newValue);

      if (multiple && multipleOptions) {
        callback(newValue);
      } else if (!multiple && !multipleOptions) {
        if (disableClearable && newValue) {
          callback(newValue);
        } else if (!disableClearable) {
          callback(newValue);
        }
      }
    },
    [callback, disableClearable, multiple],
  );

  const autocompleteProps = useAutocompleteOptions({
    callback: handleCallback,
    data: translatedOptions,
    disableClearable,
    isLoading,
    multiple,
    selected,
    ...args,
  });

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
}

export default useHoaAccountAutocomplete;
