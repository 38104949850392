import createRestClient from '../restClient';

const restClient = createRestClient(undefined, true);

type CityResponse = {
  id: string;
  city: string;
  postalCode: string;
};

const getPostalCodeCity = ({ countryCode, term }: { countryCode: string; term: string }) =>
  restClient.get<CityResponse[]>(`lucene/${countryCode}/cityindex.search?term=${term}`);

export default {
  getPostalCodeCity,
};
