import {
  Alert,
  Autocomplete,
  Card,
  CardContent,
  FormGroup,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Button, Checkbox, Tooltip } from '@mui/joy';
import { Col, Row } from 'react-bootstrap';
import { GoogleMap, InfoWindowF, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  decapitalize,
  formatDate,
  getAddressStringFromAddressObject,
  getCurrentTranslation,
  getMomentObject,
  isInTheFuture,
  isTodayBetween,
  sentencize,
  stripHtml,
} from '@/shared/utils/helpers';
import { useGetContractorsAvailableForRepair, useGetContractorSkillDefinitions } from '@/queries';
import AddressFunction from '@/shared/enums/AddressFunction';
import { CaseContext } from '../CaseContext';
import formatContractorSkillDefinition from '@/shared/utils/components/select/ContractorSkillDefinition/util';
import { queryIds } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import useGetEnums from '@/queries/enums/useGetEnums';

import {
  AssignIcon,
  CancelIcon,
  InfoIcon,
  InlineSpinner,
  SaveIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import EnumSelector from '@/shared/components/select/EnumSelector';
import IconButton from '@/shared/components/buttons/IconButton';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import SelectContractorSkillDefinition from '@/shared/components/select/SelectContractorSkillDefinition';
import TextCheckbox from '@/shared/components/checkboxes/TextCheckbox';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const AddContractorForm = ({ onSave, onCancel }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { currentCase } = useContext(CaseContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { companyTags } = useSelector((state) => state.tags);

  const queryClient = useQueryClient();

  const { contractors, contractorsLoading } = useGetContractorsAvailableForRepair();
  const { contractorSkillDefinitions, contractorSkillIsLoading } =
    useGetContractorSkillDefinitions();
  const { isLoaded: gMapsLoaded, loadError: gMapsLoadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_G_MAPS_API_KEY,
  });
  const {
    enums: { addressFunctions, provinces },
  } = useGetEnums();

  const { mutateAsync: assignContractorAsync, isLoading: isAssigningContractor } =
    trpc.case.repair.contractor.assign.useMutation();

  const [selectedContractor, setSelectedContractor] = useState(null);
  const [teamWithAccess, setTeamWithAccess] = useState(null);
  const contractorTeamWarning = selectedContractor && !teamWithAccess;

  const [executeOnTimeAndExpenseBasis, setExecuteOnTimeAndExpenseBasis] = useState(false);
  const [repairAssignmentDescription, updateRepairAssignmentDescription] = useState();
  const [repairAssignmentAdditionalInfo, updateRepairAssignmentAdditionalInfo] = useState();

  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedAddressFunctions, setSelectedAddressFunctions] = useState([
    AddressFunction.HQ,
    AddressFunction.Office,
    AddressFunction.Shop,
    AddressFunction.Worksite,
  ]);
  const [showAbsentContractors, setShowAbsentContractors] = useState(true);
  const [showContractorsOverCapacity, setShowContractorsOverCapacity] = useState(true);
  const [takeContractorRegionIntoConsideration, setTakeContractorRegionIntoConsideration] =
    useState(true);
  const [takeContractorLanguageIntoConsideration, setTakeContractorLanguageIntoConsideration] =
    useState(
      currentCase.relations
        ?.filter((relation) => relation.isClient)
        .every((v, i, arr) => i === 0 || (v.language && v.language === arr[0].language)),
    );
  const [availableContractors, setAvailableContractors] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);

  const [contractorPins, setContractorPins] = useState([]);
  const [allContractorPins, setAllContractorPins] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 50.8551188,
    lng: 4.340398,
  });

  const [closedInfoWindows, updateClosedInfoWindows] = useState([]);

  const handleWindowClose = (key) => {
    updateClosedInfoWindows((prev) => [...prev.filter((p) => p !== key), key]);
  };

  const handleWindowOpen = (key) => {
    updateClosedInfoWindows((prev) => [...prev].filter((p) => p !== key));
  };

  useEffect(() => {
    if (!isLoading || contractorSkillIsLoading) {
      return;
    }
    setSelectedSkillId(currentCase.contractorSkillDefinition?.id);
    setIsLoading(false);
    setError(null);
  }, [currentCase, isLoading, contractorSkillIsLoading]);

  const flattenCompanyAddresses = (arr) =>
    arr.flatMap(({ addresses, ...rest }) =>
      addresses.map((o) => ({
        ...rest,
        ...o,
      })),
    );

  const contractorSkill = useMemo(() => {
    if (!selectedContractor || !currentCase) {
      return null;
    }
    return selectedContractor.skills.find(
      (s) => s.skillDefinitionId === currentCase.contractorSkillDefinition?.id,
    );
  }, [selectedContractor, currentCase]);

  const nextAbsenceDate = useMemo(() => {
    if (!selectedContractor) {
      return null;
    }
    const absences = selectedContractor.absences
      .filter(
        ({ fromDate, untilDate }) => isInTheFuture(fromDate) || isTodayBetween(fromDate, untilDate),
      )
      .sort((a, b) => getMomentObject(a.fromDate).unix() - getMomentObject(b.fromDate).unix());

    return absences.length > 0
      ? `${formatDate(absences[0].fromDate, 'DD-MM-YYYY')} - ${formatDate(
          absences[0].untilDate,
          'DD-MM-YYYY',
        )}`
      : null;
  }, [selectedContractor]);

  const caseAddressPin = useMemo(
    () => ({
      address: `${currentCase?.address.addressLine}`,
      key: currentCase?.id,
      position: {
        lat: currentCase?.latitude,
        lng: currentCase?.longitude,
      },
    }),
    [currentCase],
  );

  const handleContractorSelected = (selected) => {
    setSelectedContractor(selected);
    setTeamWithAccess(null);

    if (selected && selected.linkedTeams.length === 1) {
      setTeamWithAccess(selected.linkedTeams[0]);
    }
  };

  const handleContractorPinSelected = (id) => {
    const contractorToSelect = id ? contractors.find((c) => c.id === id) : null;
    handleContractorSelected(contractorToSelect);
  };

  const assignedContractorId = currentCase.contractorId || '';
  const colSize = selectedContractor?.fees?.length > 0 ? 3 : 4;

  const checkPostalCodeCoverage = useCallback(
    (regions) => {
      if (!regions) {
        return false;
      }

      const contractorHandlesSpecificPostalCode = regions.some(
        (region) =>
          region.postalCodes.includes(currentCase?.address?.postalCode) &&
          !region.excludePostalCodes,
      );

      if (!contractorHandlesSpecificPostalCode) {
        const currentCaseProvince = provinces.find((province) =>
          (province.postalCodes || []).some(
            (postalCode) =>
              postalCode.postalCode === parseInt(currentCase?.address?.postalCode, 10),
          ),
        );

        return !!(
          currentCaseProvince &&
          regions.some(
            (region) =>
              region.province === currentCaseProvince.name &&
              (region.postalCodes.length === 0 ||
                (region.excludePostalCodes &&
                  !region.postalCodes.find(
                    (postalCode) => postalCode === currentCase?.address?.postalCode,
                  ))),
          )
        );
      }

      return contractorHandlesSpecificPostalCode;
    },
    [currentCase, provinces],
  );

  useEffect(() => {
    if (!gMapsLoaded || isLoading) {
      return;
    }

    const possibleContractors = contractors.filter((c) => c.id !== assignedContractorId);

    const mappedContractors = possibleContractors
      .map(
        ({
          id,
          name,
          skills,
          addresses,
          absences,
          totalOpenCases,
          isOverGlobalCapacity,
          tags: contractorTags,
          regions,
          language: contractorLanguage,
        }) => ({
          addresses: addresses.filter(
            (address) => address.latitude != null && address.longitude != null,
          ),
          contractorTags,
          coversCasePostalCode: !checkPostalCodeCoverage(regions),
          hasSameLanguageAsClients: !currentCase.relations.some(
            (relation) => relation.isClient && relation.language === contractorLanguage,
          ),
          id,
          isAbsent: absences.some(({ fromDate, untilDate }) => isTodayBetween(fromDate, untilDate)),
          isOverCapacity:
            isOverGlobalCapacity ||
            skills.some(
              ({ skillDefinitionId, capacity }) =>
                capacity !== 0 &&
                currentCase &&
                skillDefinitionId === currentCase.contractorSkillDefinition?.id &&
                totalOpenCases >= capacity,
            ),
          name,
          skills: skills.map(({ skillDefinitionId }) => skillDefinitionId),
        }),
      )
      .filter((contractor) => contractor.addresses != null && contractor.addresses.length > 0);

    const flatContractorMap = flattenCompanyAddresses(mappedContractors);
    const pins = flatContractorMap.map(
      ({
        name,
        latitude,
        longitude,
        function: addressFunction,
        id: contractorId,
        skills,
        isAbsent,
        isOverCapacity,
        contractorTags,
        coversCasePostalCode,
        hasSameLanguageAsClients,
      }) => ({
        addressFunction,
        contractorId,
        contractorTags,
        coversCasePostalCode,
        hasSameLanguageAsClients,
        isAbsent,
        isOverCapacity,
        key: contractorId + latitude + longitude,
        name,
        position: {
          lat: latitude,
          lng: longitude,
        },
        skills,
      }),
    );
    setAllContractorPins([...pins]);
  }, [
    gMapsLoaded,
    contractors,
    isLoading,
    currentCase,
    assignedContractorId,
    checkPostalCodeCoverage,
  ]);

  useEffect(() => {
    if (allContractorPins == null || !allContractorPins.length) {
      return;
    }

    const filtered = allContractorPins
      .filter(
        ({ skills }) =>
          selectedSkillId == null || skills.some((skill) => skill === selectedSkillId),
      )
      .filter(({ isAbsent }) => (showAbsentContractors ? !isAbsent : true))
      .filter(({ isOverCapacity }) => (showContractorsOverCapacity ? !isOverCapacity : true))
      .filter(({ coversCasePostalCode }) =>
        takeContractorRegionIntoConsideration ? !coversCasePostalCode : true,
      )
      .filter(({ hasSameLanguageAsClients }) =>
        takeContractorLanguageIntoConsideration ? !hasSameLanguageAsClients : true,
      )
      .filter(
        ({ addressFunction }) =>
          selectedAddressFunctions == null ||
          selectedAddressFunctions.length === 0 ||
          selectedAddressFunctions.some((addrFunc) => addrFunc === addressFunction),
      )
      .filter(
        (c) =>
          selectedTags == null ||
          selectedTags.length === 0 ||
          selectedTags.some((selectedTag) =>
            c.contractorTags?.some((tagId) => selectedTag.id === tagId),
          ),
      );
    setContractorPins([...filtered]);
  }, [
    allContractorPins,
    selectedSkillId,
    showAbsentContractors,
    selectedAddressFunctions,
    showContractorsOverCapacity,
    selectedTags,
    takeContractorLanguageIntoConsideration,
    takeContractorRegionIntoConsideration,
  ]);

  useEffect(() => {
    if (contractorsLoading || !contractors?.length) {
      return;
    }

    function checkCapacityFilter(skills, totalOpenCases, globalCapacity) {
      const skillToCheck = skills.find(
        ({ contractorSkillDefinitionId }) =>
          contractorSkillDefinitionId === currentCase.contractorSkillDefinition?.id,
      );
      if (globalCapacity === 0) {
        return true;
      }

      if (skillToCheck) {
        return skillToCheck.capacity > totalOpenCases;
      }

      return globalCapacity > totalOpenCases;
    }

    function checkLanguageFilter(contractorLanguage) {
      return currentCase.relations.some(
        (relation) => relation.isClient && relation.language === contractorLanguage,
      );
    }

    const filtered = contractors
      .filter(
        (c) =>
          selectedSkillId == null ||
          c.skills.some((skill) => skill.skillDefinitionId === selectedSkillId),
      )
      .filter((c) =>
        showAbsentContractors
          ? !c.absences.some(({ fromDate, untilDate }) => isTodayBetween(fromDate, untilDate))
          : true,
      )
      .filter((c) =>
        showContractorsOverCapacity
          ? checkCapacityFilter(c.skills, c.totalOpenCases, c.capacity)
          : true,
      )
      .filter((c) =>
        takeContractorRegionIntoConsideration ? checkPostalCodeCoverage(c.regions) : true,
      )
      .filter((c) =>
        takeContractorLanguageIntoConsideration ? checkLanguageFilter(c.language) : true,
      )
      .filter(
        (c) =>
          selectedTags == null ||
          selectedTags.length === 0 ||
          selectedTags.some((selectedTag) => c.tags?.some((tagId) => selectedTag.id === tagId)),
      )
      .filter((c) => c.id !== assignedContractorId);

    setAvailableContractors([...filtered]);
  }, [
    contractors,
    selectedSkillId,
    showAbsentContractors,
    currentCase,
    showContractorsOverCapacity,
    assignedContractorId,
    selectedTags,
    contractorsLoading,
    checkPostalCodeCoverage,
    takeContractorLanguageIntoConsideration,
    takeContractorRegionIntoConsideration,
  ]);

  const formatContractorSkillDefinitionCallback = useCallback(
    (id) =>
      formatContractorSkillDefinition(id, contractorSkillDefinitions, language)?.chosenDescription,
    [contractorSkillDefinitions, language],
  );

  const handleSave = async () => {
    if (isAssigningContractor) {
      return;
    }

    try {
      const data = {
        assignmentAdditionalInfo: repairAssignmentAdditionalInfo,
        assignmentDescription: repairAssignmentDescription,
        caseId: currentCase.id,
        contractorId: selectedContractor.id,
        executeOnTimeAndExpenseBasis,
        teamId: teamWithAccess?.id,
      };

      await assignContractorAsync(data);

      enqueueSnackbar(t('typeSuccessfullyAdded', { type: t('contractor') }));
      queryClient.invalidateQueries([queryIds.cases.GET_CASE, data.caseId]);
      onSave();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const isInfoBoxShown = useCallback(
    (key) => {
      const closed = closedInfoWindows.some((windowKey) => windowKey === key);
      return !closed;
    },
    [closedInfoWindows],
  );

  const checkGlobalCapacity = () =>
    selectedContractor.totalOpenCases >= selectedContractor.globalCapacity;

  const checkSkillCapacity = () =>
    contractorSkill?.capacity > 0 && selectedContractor.totalOpenCases >= contractorSkill?.capacity;

  return (
    <div style={{ width: '100%' }}>
      {error != null && error.length ? (
        <p>{error}</p>
      ) : (
        <>
          {isLoading ? (
            <Skeleton variant="rectangular" height={176} />
          ) : (
            <Card>
              <CardContent>
                <Typography className="mb-2">{t('filter')}</Typography>
                <Row className="mb-2">
                  <Col sm={6} md={6} lg={6}>
                    <SelectContractorSkillDefinition
                      initialSkillId={selectedSkillId}
                      label={t('skill')}
                      onChangeCallback={(v) => setSelectedSkillId(v?.id ?? null)}
                    />
                    <Autocomplete
                      noOptionsText={t('noOptions')}
                      loading={isLoading}
                      multiple
                      options={companyTags}
                      value={selectedTags}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(e, selected) => setSelectedTags(selected)}
                      getOptionLabel={(opt) => (opt == null ? '' : opt.description)}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.description}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField label={t('tag')} variant="standard" fullWidth {...params} />
                      )}
                    />
                    <EnumSelector
                      enumValues={addressFunctions}
                      defaultValue={selectedAddressFunctions}
                      multiple
                      label={t('addressFunction')}
                      fullWidth
                      onChange={(selectedValues) => {
                        if (selectedValues == null) {
                          setSelectedAddressFunctions(null);
                          return;
                        }
                        setSelectedAddressFunctions([...selectedValues]);
                      }}
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <Checkbox
                      label={t('hideAbsentContractors')}
                      checked={showAbsentContractors}
                      onChange={() => setShowAbsentContractors(!showAbsentContractors)}
                      className="mb-4"
                    />
                    <Checkbox
                      label={t('hideContractorsOverCapacity')}
                      checked={showContractorsOverCapacity}
                      onChange={() => setShowContractorsOverCapacity(!showContractorsOverCapacity)}
                      className="mb-4"
                    />
                    <Checkbox
                      label={t('takeContractorRegionsIntoConsideration')}
                      checked={takeContractorRegionIntoConsideration}
                      onChange={() =>
                        setTakeContractorRegionIntoConsideration(
                          !takeContractorRegionIntoConsideration,
                        )
                      }
                      className="mb-4"
                    />
                    <Checkbox
                      label={t('checkTheLanguageOfTheContractor')}
                      disabled={
                        !currentCase.relations
                          ?.filter((relation) => relation.isClient)
                          .every(
                            (v, i, arr) =>
                              i === 0 || (v.language && v.language === arr[0].language),
                          )
                      }
                      checked={takeContractorLanguageIntoConsideration}
                      onChange={() =>
                        setTakeContractorLanguageIntoConsideration(
                          !takeContractorLanguageIntoConsideration,
                        )
                      }
                      className="mb-4"
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>
          )}
          <br />
          {isLoading || !gMapsLoaded ? (
            <Skeleton variant="rectangular" height={508} />
          ) : (
            <Card>
              <CardContent>
                <Typography className="mb-2">
                  {t('_address.label')}:{' '}
                  {getAddressStringFromAddressObject(currentCase.address) ?? t('caseHasNoAddress')}
                </Typography>
                {gMapsLoadError == null || !gMapsLoadError.length ? (
                  <GoogleMap
                    zoom={8.5}
                    mapContainerStyle={{ height: '400px', width: '100%' }}
                    center={mapCenter}
                    onTilesLoaded={() => setMapCenter(null)}
                  >
                    {contractorPins != null &&
                      contractorPins.map(
                        ({ name, addressFunction, position, contractorId, key }) => (
                          <MarkerF
                            key={key}
                            position={position}
                            onClick={() => handleWindowOpen(key)}
                          >
                            {isInfoBoxShown(key) && (
                              <InfoWindowF
                                key={`infowindow-${key}`}
                                position={position}
                                onCloseClick={() => handleWindowClose(key)}
                              >
                                <div>
                                  <p>{`${name} (${addressFunction})`}</p>
                                  <IconButton
                                    iconComponent={<AssignIcon />}
                                    tooltip={t('assignContractor')}
                                    onClick={() => handleContractorPinSelected(contractorId)}
                                    color="keypoint"
                                  />
                                </div>
                              </InfoWindowF>
                            )}
                          </MarkerF>
                        ),
                      )}
                    <MarkerF
                      key={caseAddressPin.key}
                      position={caseAddressPin.position}
                      onClick={() => handleWindowOpen(caseAddressPin.key)}
                      icon={{
                        anchor: { x: 11.5, y: 21 },
                        fillColor: 'royalBlue',
                        fillOpacity: 1.0,
                        path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                        scale: 2,
                        strokeWeight: 0,
                      }}
                    />
                  </GoogleMap>
                ) : (
                  <p>{gMapsLoadError}</p>
                )}
                <br />
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <Autocomplete
                      noOptionsText={t('noOptions')}
                      loading={isLoading}
                      options={availableContractors}
                      value={selectedContractor || null}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      onChange={(_, val) => handleContractorSelected(val)}
                      getOptionLabel={(opt) => {
                        if (opt == null) {
                          return '';
                        }
                        return opt.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={t('contractor')}
                          variant="standard"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <TextCheckbox
                      yesText={t('onTimeAndExpenseBasis')}
                      noText={t('notOnTimeAndExpenseBasis')}
                      isDefaultChecked={false}
                      callback={(checked) => setExecuteOnTimeAndExpenseBasis(checked)}
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>
          )}
          <br />
          {!!selectedContractor && (
            <Card style={{ overflow: 'initial' }}>
              <CardContent>
                <div className="mb-2">
                  {t('contractor')}: {selectedContractor.displayName}
                  {!contractorSkill && (
                    <Alert
                      variant="filled"
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                      severity="warning"
                    >
                      {t('thisContractorDoesNotHaveTheSkill')}
                    </Alert>
                  )}
                </div>
                {!!selectedContractor?.linkedTeams.length && (
                  <FormGroup className="mb-3">
                    <Autocomplete
                      noOptionsText={t('noOptions')}
                      loading={isLoading}
                      isRequired
                      options={selectedContractor.linkedTeams}
                      value={teamWithAccess ?? null}
                      isOptionEqualToValue={(option, value) => option?.id === value.id}
                      onChange={(e, selected) => setTeamWithAccess(selected)}
                      getOptionLabel={(opt) => {
                        if (opt == null) {
                          return '';
                        }
                        return opt.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField label={t('team')} variant="standard" fullWidth {...params} />
                      )}
                    />
                  </FormGroup>
                )}
                {selectedContractor.note && (
                  <FormGroup className="mb-3">
                    <TextField
                      disabled
                      value={stripHtml(selectedContractor.note) ?? t('none')}
                      label={t('note')}
                      fullWidth
                      multiline
                      variant="standard"
                      className="mb-2"
                    />
                  </FormGroup>
                )}
                <Row>
                  {checkGlobalCapacity() && (
                    <div>
                      <Alert className="mb-2" variant="filled" severity="warning">
                        {t('contractorsCapacityExceeded')}
                      </Alert>
                    </div>
                  )}
                  {checkSkillCapacity() && (
                    <div>
                      <Alert className="mb-2" variant="filled" severity="warning">
                        {sentencize(
                          t('contractorsSkillCapacityExceeded', {
                            skill: t(
                              `${decapitalize(
                                getCurrentTranslation(contractorSkill.translations, language),
                              )}`,
                            ),
                          }),
                        )}
                      </Alert>
                    </div>
                  )}
                  <Col sm={colSize} md={colSize} lg={colSize}>
                    {selectedContractor.globalCapacity === 0 ? (
                      <div>
                        {t('capacity')}:
                        <span style={{ color: 'green', marginLeft: '5px' }}>{t('noLimit')}</span>
                      </div>
                    ) : (
                      <div>
                        {t('capacity')}:
                        <span
                          style={{
                            color: checkGlobalCapacity() ? 'red' : 'green',
                            fontSize: checkGlobalCapacity() ? '14px' : '12px',
                            marginLeft: '5px',
                          }}
                        >
                          {selectedContractor.totalOpenCases}
                        </span>
                        /
                        <span
                          style={{
                            fontSize: checkGlobalCapacity() ? '12px' : '14px',
                          }}
                        >
                          {selectedContractor.capacity}
                        </span>
                        {checkGlobalCapacity() && (
                          <KpcTooltip
                            style={{
                              marginRight: '15px',
                              marginTop: '10px',
                              verticalAlign: 'bottom',
                            }}
                            title={t('contractorsCapacityExceeded')}
                          >
                            <i>
                              <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                            </i>
                          </KpcTooltip>
                        )}
                      </div>
                    )}
                    {contractorSkill && (
                      <div>
                        {t('capacity')}{' '}
                        {decapitalize(
                          formatContractorSkillDefinitionCallback(
                            contractorSkill.skillDefinitionId,
                          ),
                        )}
                        :
                        {contractorSkill.capacity === 0 ? (
                          <span style={{ color: 'green', marginLeft: '5px' }}>{t('noLimit')}</span>
                        ) : (
                          <span>
                            <span
                              style={{
                                color: checkSkillCapacity() ? 'red' : 'green',
                                fontSize: checkSkillCapacity() ? '14px' : '12px',
                                marginLeft: '5px',
                              }}
                            >
                              {selectedContractor.totalOpenCases}
                            </span>
                            /
                            <span
                              style={{
                                fontSize: checkSkillCapacity() ? '12px' : '14px',
                              }}
                            >
                              {contractorSkill.capacity}
                            </span>
                            {checkSkillCapacity() && (
                              <KpcTooltip
                                style={{
                                  marginRight: '15px',
                                  marginTop: '10px',
                                  verticalAlign: 'bottom',
                                }}
                                title={t('contractorsSkillCapacityExceeded', {
                                  skill: formatContractorSkillDefinitionCallback(
                                    contractorSkill.skillDefinitionId,
                                  ),
                                })}
                              >
                                <i>
                                  <WarningIcon style={{ color: 'red', marginLeft: '5px' }} />
                                </i>
                              </KpcTooltip>
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </Col>
                  {selectedContractor?.skills?.length > 0 && (
                    <Col sm={colSize} md={colSize} lg={colSize}>
                      <ul>
                        {selectedContractor &&
                          selectedContractor.skills.map((s) => (
                            <li key={s.skillId}>{`${getCurrentTranslation(
                              s.translations,
                              language,
                            )}: €${s.price}`}</li>
                          ))}
                      </ul>
                    </Col>
                  )}
                  {selectedContractor?.fees?.length > 0 && (
                    <Col sm={colSize} md={colSize} lg={colSize}>
                      {selectedContractor.fees.map((f) => (
                        <div>
                          {`${t(decapitalize(f.feeType ?? '-'))}: €${f.price}`}
                          {f.remark && (
                            <KpcTooltip style={{ marginLeft: '3px' }} title={f.remark}>
                              <i>
                                <InfoIcon />
                              </i>
                            </KpcTooltip>
                          )}
                        </div>
                      ))}
                    </Col>
                  )}
                  <Col sm={colSize} md={colSize} lg={colSize}>
                    {t('nextAbsence')}:
                    {!nextAbsenceDate ? (
                      ` ${t('none')}`
                    ) : (
                      <div className="whitespace-nowrap">{nextAbsenceDate}</div>
                    )}
                  </Col>
                </Row>
              </CardContent>
            </Card>
          )}
          <br />
          {!!selectedContractor && (
            <Card>
              <CardContent>
                <Typography className="mb-2">{t('assignment')}</Typography>
                <TextField
                  label={t('description')}
                  onChange={({ target }) => updateRepairAssignmentDescription(target?.value ?? '')}
                  value={repairAssignmentDescription ?? currentCase.description ?? ''}
                  fullWidth
                  variant="standard"
                  className="mb-2"
                  multiline
                />
                <TextField
                  label={t('additionalInfo')}
                  onChange={({ target }) =>
                    updateRepairAssignmentAdditionalInfo(target?.value ?? '')
                  }
                  value={repairAssignmentAdditionalInfo ?? ''}
                  fullWidth
                  variant="standard"
                  className="mb-2"
                  multiline
                />
              </CardContent>
            </Card>
          )}
          <br />
          <div>
            <div className="flex justify-end space-x-4">
              <Button
                startDecorator={<CancelIcon />}
                variant="outlined"
                color="neutral"
                onClick={onCancel}
              >
                {t('cancel')}
              </Button>
              <Tooltip arrow title={contractorTeamWarning ? t('noTeamLinkedToContractor') : ''}>
                <Button
                  startDecorator={
                    isAssigningContractor ? (
                      <InlineSpinner />
                    ) : contractorTeamWarning ? (
                      <WarningIcon />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  color={contractorTeamWarning ? 'warning' : undefined}
                  onClick={handleSave}
                  disabled={
                    !selectedContractor ||
                    (!!selectedContractor.linkedTeams.length && !teamWithAccess) ||
                    isAssigningContractor
                  }
                >
                  {t('save')}
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddContractorForm;

AddContractorForm.propTypes = propTypes;
