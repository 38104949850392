import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { Company, CompanySummary } from '~/common/types/company/company';

import useAddEditCompanyFormConfig, {
  AddEditCompanyFormikValues,
} from './useAddEditCompanyFormConfig';

import AddEditCompanyFormBody from './AddEditCompanyFormBody';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

type Props = {
  company?: Company | CompanySummary;
  minified?: boolean;
  onSubmit: (values: AddEditCompanyFormikValues) => void | Promise<void>;
  onCancel: () => void;
  isContractor?: boolean;
  isSubmitting?: boolean;
};

const AddEditCompanyForm: React.FC<Props> = ({
  company,
  onSubmit,
  onCancel,
  isContractor = false,
  minified = false,
  isSubmitting = false,
}) => {
  const [hasManualError, setHasManualError] = useState(false);

  const { baseValidationSchema, expandedValidationSchema, initialValues } =
    useAddEditCompanyFormConfig(company, {
      isContractor,
      minified,
    });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={minified ? baseValidationSchema : expandedValidationSchema}
      onSubmit={(values) => {
        if (hasManualError) {
          return;
        }

        onSubmit(values);
      }}
    >
      <Form>
        <AddEditCompanyFormBody setHasManualError={setHasManualError} minified={minified} />
        <ModalFooter onCancel={onCancel} isSubmitting={isSubmitting} />
      </Form>
    </Formik>
  );
};

export default AddEditCompanyForm;
