enum IntegrationPartner {
  CompanyWeb = 'CompanyWeb',
  ExactOnline = 'ExactOnline',
  Isabel6 = 'Isabel6',
  Metamaze = 'Metamaze',
  Octopus = 'Octopus',
  Organimmo = 'Organimmo',
  Teamleader = 'Teamleader',
}

export default IntegrationPartner;
