import { useMutation } from '@tanstack/react-query';

import caseApi from '@/shared/api/cases/case';

import useNotification from '@/shared/hooks/UseNotification';

const useRequestRepairInKind = () => {
  const { sendDefaultError } = useNotification();

  const { mutate, isLoading, data } = useMutation({
    mutationFn: caseApi.requestRepairInKind,
    onError: sendDefaultError,
  });

  return {
    isRequestingRepairInKind: isLoading,
    repairInKindCaseId: data || null,
    requestRepairInKind: mutate,
  };
};

export default useRequestRepairInKind;
