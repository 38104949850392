import {
  Alert,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from '@mui/joy';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Check } from '@mui/icons-material';
import { toInteger } from 'lodash';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { validate as uuidValidate } from 'uuid';

import { CaseChannel, CaseRole, DamageType, ExcessType, Priority } from '~/common/enums';
import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import useCreateRepairCase from '@/mutations/case/useCreateRepairCase';
import useDayjs from '@/shared/hooks/useDayjs';

import {
  CancelIcon,
  InlineSpinner,
  NextIcon,
  PreviousIcon,
  RepairCaseIcon,
  ValidIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import AddressStep from './AddressStep';
import CaseInfoStep from './CaseInfoStep';
import DamageClaimStep from './DamageClaimStep';
import FilesStep from './FilesStep';
import InvolvedPartiesStep from './InvolvedPartiesStep';
import ManagementStep from './ManagementStep';
import PolicyStep from './PolicyStep';
import SkillsStep from './SkillsStep';
import Summary from './Summary';

type Address = {
  addressLine: string;
  city: string;
  country: string;
  postalCode: string;
};

type CompanyAddress = {
  function: string;
  address: Address;
  description: string;
  longitude: number;
  latitude: number;
};

export type FormikValues = {
  address: Address;
  admins: string[];
  buildingId: string;
  channel: string;
  classificationId: string;
  contractId: string;
  damageType: string;
  description: string;
  excessType: string;
  incidentDate: string;
  notificationDate: string;
  excessAmount: number;
  excessRemark: string;
  excessIncreasedAmount: number;
  policies: {
    policyId: string;
    policyNumber: string;
  }[];
  priority: string;
  remark: string;
  sendTo?: string;
  relations: {
    id: string;
    isRequestor: boolean;
    isClient: boolean;
    role: string;
    label: string;
    type: string;
    language: string;
    gender: string;
    teamId: string | null;
    companyId: string | null;
    contactId: string | null;
    firstName: string;
    lastName: string;
    phoneNumber: {
      countryCode: string;
      number: string;
    };
    mobilePhoneNumber: {
      countryCode: string;
      number: string;
    };
    remark: string;
    emailAddresses: string[];
    address: {
      addressLine: string;
      city: string;
      country: string;
      postalCode: string;
    };
    companyName: string;
    vatNumber: string;
    companyAddresses: CompanyAddress[];
  }[];
};

const useSteps = () => {
  const { currentTeamUser, currentTeam } = useContext(AppContext);

  const { t } = useTranslation();
  const { dayjs } = useDayjs();

  return {
    formik: {
      initialValues: {
        address: {
          addressLine: '',
          city: '',
          country: 'BE',
          postalCode: '',
        },
        admins: [currentTeamUser?.id],
        buildingId: null,
        channel: CaseChannel.Email,
        classificationId: null,
        consentOnCancellationFee: false,
        contractId: null,
        damageType: DamageType.AccidentAtWork,
        description: '',
        excessAmount: 0,
        excessIncreasedAmount: 0,
        excessRemark: '',
        excessType: ExcessType.None,
        incidentDate: dayjs().utc().format(),
        notificationDate: currentTeam?.allowRepairManagement ? dayjs().utc().format() : null,
        policies: [],
        priority: Priority.Normal,
        relations: [],
        remark: '',
        sendTo: null,
      },
    },
    steps: [
      { component: AddressStep, title: t('address') },
      { component: PolicyStep, title: t('excess') },
      { component: CaseInfoStep, title: t('caseInfo') },
      { component: DamageClaimStep, title: t('damageClaims') },
      { component: InvolvedPartiesStep, title: t('involvedParties') },
      { component: FilesStep, title: t('file_other') },
      currentTeam?.allowRepairManagement
        ? { component: SkillsStep, title: t('skills') }
        : { component: ManagementStep, title: t('management') },
    ],
  };
};

const AddRepairCaseStepper = () => {
  const { t } = useTranslation();
  const { ui, setUi, currentTeam } = useContext(AppContext);
  const navigate = useNavigate();

  const currentStep = ui.case.stepper.repairCase.step;

  const [redirect, setRedirect] = useState({
    toCase: true,
    toCaseInNewTab: false,
  });

  const [uiError, setUiError] = useState<string | null>(null);

  const { createRepairCaseAsync, isCreatingRepairCase } = useCreateRepairCase({ redirect: false });

  const {
    steps,
    formik: { initialValues },
  } = useSteps();

  return (
    <Modal
      open={ui.case.toggles.createRepairCase}
      onClose={(_e, reason) => {
        if (reason === 'backdropClick') return;
        setUi({ type: actions.CASE_TOGGLE_CREATE_REPAIR_CASE });
        setUi({ payload: 1, type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP });
        setUi({ payload: [], type: actions.FILES_SET_TEMPORARY_FILES });
      }}
    >
      <ModalOverflow>
        <ModalDialog className="w-5/6">
          <ModalClose />
          <Typography startDecorator={<RepairCaseIcon />} level="title-lg">
            {t('createRepairCase')}
          </Typography>

          <Divider />

          <div className="flex grow flex-col space-y-4">
            <Stepper className="my-8">
              {steps.map((step, index) => {
                const activeStep = index + 1;
                return (
                  <Step
                    key={index}
                    orientation="vertical"
                    indicator={
                      <IconButton
                        variant="plain"
                        disabled={currentStep < activeStep}
                        onClick={() => {
                          setUi({
                            payload: activeStep,
                            type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                          });
                          setUiError(null);
                        }}
                      >
                        <StepIndicator
                          variant={currentStep === activeStep ? 'solid' : 'soft'}
                          color={currentStep <= activeStep ? 'primary' : 'primary'}
                        >
                          {currentStep <= activeStep ? activeStep : <Check />}
                        </StepIndicator>
                      </IconButton>
                    }
                    sx={{
                      '&::after': {
                        ...(currentStep > activeStep && { bgcolor: 'primary.solidBg' }),
                      },
                    }}
                  >
                    {step.title}
                  </Step>
                );
              })}
            </Stepper>

            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                const updatedValues = {
                  ...values,
                  filesToAdd: ui.files.temporary,
                };

                const caseResponse = await createRepairCaseAsync(updatedValues);
                const isValidId = uuidValidate(
                  typeof caseResponse === 'string' ? caseResponse : '',
                );

                if (!isValidId) return;

                if (redirect.toCase) {
                  navigate(`/case/${caseResponse}`);
                }

                if (redirect.toCaseInNewTab) {
                  window.open(`/case/${caseResponse}`, '_blank');
                }

                setUi({ type: actions.CASE_TOGGLE_CREATE_REPAIR_CASE });
                setUi({ payload: 1, type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP });
                setUi({ payload: [], type: actions.FILES_SET_TEMPORARY_FILES });
              }}
            >
              {({ values }) => (
                <Form className="flex flex-col space-y-4">
                  {uiError && (
                    <Alert
                      startDecorator={<WarningIcon />}
                      color="danger"
                      endDecorator={
                        <IconButton variant="plain" color="danger" onClick={() => setUiError(null)}>
                          <CancelIcon />
                        </IconButton>
                      }
                    >
                      {uiError}
                    </Alert>
                  )}

                  <Summary />
                  {steps.map(
                    (step, index) =>
                      currentStep === index + 1 && (
                        <div key={index} className="flex flex-col space-y-4">
                          {step.component ? <step.component /> : <span>{step.title}</span>}
                        </div>
                      ),
                  )}

                  <div className="flex items-center justify-end space-x-4">
                    {currentStep === steps.length && (
                      <>
                        <Checkbox
                          label={t('openCaseAfterSaving')}
                          checked={redirect.toCase}
                          onChange={() =>
                            setRedirect((prev) => ({ ...prev, toCase: !prev.toCase }))
                          }
                        />
                        <Checkbox
                          label={t('openCaseInNewTabAfterSaving')}
                          checked={redirect.toCaseInNewTab}
                          onChange={() =>
                            setRedirect((prev) => ({
                              ...prev,
                              toCaseInNewTab: !prev.toCaseInNewTab,
                            }))
                          }
                        />
                      </>
                    )}

                    <Button
                      variant="outlined"
                      startDecorator={<PreviousIcon />}
                      disabled={currentStep === 1}
                      onClick={() => {
                        setUi({
                          payload: currentStep - 1,
                          type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                        });
                        setUiError(null);
                      }}
                    >
                      {t('previous')}
                    </Button>

                    <Button
                      disabled={currentStep > steps.length - 1}
                      variant="outlined"
                      endDecorator={<NextIcon />}
                      onClick={async () => {
                        switch (currentStep) {
                          case 1: {
                            const hasAddress =
                              values.address.addressLine &&
                              values.address.city &&
                              values.address.postalCode;

                            if (values.buildingId || hasAddress) {
                              setUi({
                                payload: currentStep + 1,
                                type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                              });
                              setUiError(null);
                            } else {
                              setUiError(t('repairCaseForm.address.error'));
                            }

                            break;
                          }

                          case 2:
                            if (currentTeam?.allowRepairManagement && !values.contractId) {
                              setUiError(t('repairCaseForm.excess.contract'));
                              break;
                            }

                            if (values.excessType === ExcessType.None) {
                              setUi({
                                payload: currentStep + 1,
                                type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                              });
                              setUiError(null);
                            }

                            if (
                              values.excessType === ExcessType.LegalExcess ||
                              values.excessType === ExcessType.EnglishExcess
                            ) {
                              if (
                                (toInteger(values.excessAmount) &&
                                  toInteger(values.excessIncreasedAmount) === 0) ||
                                toInteger(values.excessAmount) <
                                  toInteger(values.excessIncreasedAmount)
                              ) {
                                setUi({
                                  payload: currentStep + 1,
                                  type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                                });
                                setUiError(null);
                              } else if (
                                toInteger(values.excessAmount) >=
                                toInteger(values.excessIncreasedAmount)
                              ) {
                                setUiError(t('repairCaseForm.excess.increasedExcessError'));
                              } else {
                                setUiError(t('repairCaseForm.excess.error'));
                              }
                            }
                            break;
                          case 4:
                            {
                              const hasDescription = values.description.length > 3;

                              if (hasDescription) {
                                setUi({
                                  payload: currentStep + 1,
                                  type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                                });
                                setUiError(null);
                              } else {
                                setUiError(t('repairCaseForm.damage.error'));
                              }
                            }
                            break;
                          case 5:
                            {
                              const hasRequestor = values.relations.some(
                                (relation: { isRequestor: boolean }) => relation.isRequestor,
                              );
                              const hasClient = values.relations.some(
                                (relation: { isClient: boolean }) => relation.isClient,
                              );

                              const isValidInvolvedParties = hasRequestor && hasClient;

                              if (isValidInvolvedParties) {
                                setUi({
                                  payload: currentStep + 1,
                                  type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                                });
                                setUiError(null);
                              } else {
                                setUiError(t('repairCaseForm.involvedParties.error'));
                              }
                            }
                            break;
                          default:
                            setUi({
                              payload: currentStep + 1,
                              type: actions.CASE_STEPPER_SET_REPAIR_CASE_STEP,
                            });
                            break;
                        }
                      }}
                    >
                      {t('next')}
                    </Button>

                    <Button
                      disabled={
                        steps.length !== currentStep ||
                        isCreatingRepairCase ||
                        (!currentTeam?.allowRepairManagement && !values.sendTo)
                      }
                      startDecorator={isCreatingRepairCase ? <InlineSpinner /> : <ValidIcon />}
                      type="submit"
                    >
                      {t('createCase')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default AddRepairCaseStepper;
