import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { getIn, useFormikContext } from 'formik';
import classNames from 'classnames';

// Define the base props without the type and onChangeCallback
type BaseProps = {
  label?: string;
  name: string;
  required?: boolean;
  getMaskedValue?: (value: string) => string;
  setMaskedValue?: (value: string) => string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
} & Omit<React.ComponentProps<typeof Input>, 'type' | 'onChange'>;

interface TextOrPasswordProps extends BaseProps {
  type?: 'text' | 'password';
  onChangeCallback?: (value: string) => void;
}

interface NumberProps extends BaseProps {
  type: 'number';
  onChangeCallback?: (value: number) => void;
}

type Props = TextOrPasswordProps | NumberProps;

const FormikTextField = forwardRef<{ focus: () => void }, Props>(
  (
    {
      className,
      disabled,
      getMaskedValue,
      label,
      name,
      onChangeCallback,
      required = false,
      setMaskedValue,
      type = 'text',
      min,
      max,
      ...rest
    },
    ref,
  ) => {
    const { errors, touched, values, setFieldValue, setFieldTouched } = useFormikContext();
    const textFieldRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          (textFieldRef.current?.firstChild as HTMLElement).focus();
        },
      }),
      [],
    );

    const error = getIn(errors, name);
    const hasError = !!(getIn(touched, name) && error);

    return (
      <FormControl className={classNames('min-w-0 flex-1', className)} error={hasError}>
        {label && <FormLabel required={required}>{label}</FormLabel>}
        <Input
          className={classNames({ 'Mui-disabled': disabled })}
          size="sm"
          ref={textFieldRef}
          name={name}
          value={getMaskedValue ? getMaskedValue(getIn(values, name)) : getIn(values, name) ?? ''}
          fullWidth
          type={type}
          onChange={async ({ target }) => {
            let { value } = target;

            setFieldTouched(name, true);

            if (type === 'number') {
              const numberValue = parseFloat(value) ?? 0;

              if (max != null && numberValue > max) {
                await setFieldValue(name, max);
                (onChangeCallback as (value: number) => void | undefined)?.(max);
                return;
              }
              if (min != null && numberValue < min) {
                await setFieldValue(name, min);
                (onChangeCallback as (value: number) => void | undefined)?.(min);
                return;
              }
              await setFieldValue(name, numberValue);
              (onChangeCallback as (value: number) => void | undefined)?.(numberValue);
              return;
            }

            if (setMaskedValue) {
              value = setMaskedValue(value);
            }
            await setFieldValue(name, value);
            (onChangeCallback as (value: string) => void | undefined)?.(value);
          }}
          onBlur={() => setFieldTouched(name, true)}
          slotProps={{
            input: {
              disabled,
              readOnly: disabled,
            },
          }}
          {...rest}
        />
        {hasError && <FormHelperText className="pl-1 text-xs">{error}</FormHelperText>}
      </FormControl>
    );
  },
);

export default FormikTextField;
