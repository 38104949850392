import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { CaseStatusType, CaseType } from '~/common/enums';
import caseStatusApi from '@/shared/api/caseStatus/caseStatus';
import { getCurrentTranslation } from '@/shared/utils/helpers';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';

type Options = {
  caseType?: Nullable<CaseType>;
  enabled?: boolean;
  hideSystemDefaults?: boolean;
  onlyOwnStatuses?: boolean;
  typesToShow?: CaseStatusType[];
};

const useGetCaseStatuses = (
  options: Options = {
    caseType: null,
    enabled: true,
    hideSystemDefaults: false,
    onlyOwnStatuses: true,
    typesToShow: [CaseStatusType.Open, CaseStatusType.Cancelled, CaseStatusType.Closed],
  },
) => {
  const {
    i18n: { language },
  } = useTranslation();

  const caseType = options.caseType || null;
  const hideSystemDefaults = options.hideSystemDefaults || false;
  const onlyOwnStatuses = options.onlyOwnStatuses === undefined ? true : options.onlyOwnStatuses;
  const enabled = options.enabled || true;
  const typesToShow = options.typesToShow || [
    CaseStatusType.Open,
    CaseStatusType.Cancelled,
    CaseStatusType.Closed,
  ];

  const { data, isLoading } = useQuery({
    enabled,
    queryFn: async () => {
      const result = await caseStatusApi.getCaseStatuses(onlyOwnStatuses);

      const filtered = result
        .filter((s) => !hideSystemDefaults || !s.isSystemDefault)
        .filter((s) => !caseType || s.caseType === caseType || !s.caseType)
        .filter((s) => !s.statusType || typesToShow.includes(s.statusType));

      const formatted = result.map(({ id, translations }) => ({
        label: getCurrentTranslation(translations, language),
        value: id,
      }));

      return {
        formatted,
        result: filtered,
      };
    },
    queryKey: [queryIds.caseStatuses.GET_CASE_STATUSES],
    staleTime: 0,
  });

  return {
    caseStatuses: data?.result || [],
    caseStatusesAutocomplete: data?.formatted || [],
    caseStatusesIsLoading: isLoading,
  };
};

export default useGetCaseStatuses;
