import { Avatar, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { Link } from 'react-router-dom';
import { MenuItem as MenuItemBase } from '@mui/base';
import { useAuth } from 'react-oidc-context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoutIcon, SettingsIcon, UserIcon } from '@/shared/icons/Icons';
import { AppContext } from '@/shared/context/context';
import { getAvatarInitials } from '@/shared/utils/helpers';
import ROUTES from '@/containers/App/Router/Routes';
import { useUserRole } from '@/queries';

const TopbarProfile = () => {
  const auth = useAuth();
  const profile = auth.user?.profile;

  const { currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation();
  const { isSupervisor } = useUserRole();

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: Avatar }}
        slotProps={{ root: { color: 'primary', size: 'sm', variant: 'solid' } }}
      >
        {getAvatarInitials(profile?.name)}
      </MenuButton>

      <Menu placement="bottom-end" className="flex w-72 flex-col">
        <div className="flex items-center space-x-4 p-4">
          <Avatar size="lg" color="primary" variant="solid">
            {getAvatarInitials(profile?.name)}
          </Avatar>
          <div className="flex flex-col space-y-2 text-xs">
            <h5 className="font-medium">{profile?.name}</h5>
            {!!currentTeamUser && <p className="pb-2">{currentTeamUser.email}</p>}

            <MenuItemBase>
              <Link to="https://auth.keypoint.be/Manage" target="_blank" rel="noopener noreferrer">
                <UserIcon className="pr-2" />
                {t('profile')}
              </Link>
            </MenuItemBase>
            <MenuItemBase>
              <Link to={`${ROUTES.USER_SETTINGS}/${ROUTES.USER_SETTINGS_GENERAL}`}>
                <SettingsIcon className="pr-2" />
                {t('user')} {t('settings')}
              </Link>
            </MenuItemBase>
            {isSupervisor && (
              <MenuItemBase>
                <Link to={`${ROUTES.TEAM_SETTINGS}/${ROUTES.TEAM_SETTINGS_GENERAL}`}>
                  <SettingsIcon className="pr-2" />
                  {t('teamSettings')}
                </Link>
              </MenuItemBase>
            )}
          </div>
        </div>

        <MenuItem
          className="border-t border-t-gray-200 p-3"
          onClick={() => {
            auth.removeUser();
            auth.signoutRedirect();
          }}
        >
          <i className="pr-4">
            <LogoutIcon />
          </i>
          {t('logOut')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default TopbarProfile;
