import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize, isIterable, makeId } from '../../utils/helpers';

const EnumSelector = ({
  enumValues,
  defaultValue,
  onChange,
  errorText,
  label,
  translationToUse,
  required,
  multiple,
  autoFocus,
  isAutocomplete,
  ...rest
}) => {
  const { t } = useTranslation(translationToUse);

  const [inited, setInited] = React.useState(false);
  const [selected, setSelected] = React.useState(multiple ? [] : null);

  React.useEffect(() => {
    if (enumValues == null || enumValues.length === 0) {
      return;
    }
    if (multiple) {
      if (defaultValue == null || !defaultValue.length) {
        setSelected([]);
        setInited(true);
        return;
      }
      setSelected(enumValues.filter((val) => defaultValue.includes(val.name)) ?? []);
      setInited(true);
      return;
    }
    setSelected(enumValues.find((val) => val.name === defaultValue) ?? null);
    setInited(true);
  }, [defaultValue, enumValues, multiple]);

  const checkOptionValueEquality = useCallback(
    (option, value) => {
      if (multiple && isIterable(value)) {
        return value.some((val) => val.value === option.value);
      }
      return option?.value === value?.value;
    },
    [multiple],
  );

  const setValues = useCallback(
    (values) => {
      if (values == null) {
        onChange(null);
        return;
      }
      if (isIterable(values)) {
        onChange(values.map((val) => val.name));
        return;
      }
      onChange([values.name]);
    },
    [onChange],
  );

  const selectLabelId = useMemo(() => makeId(5), []);

  const getValue = () => {
    if (multiple) {
      if (selected == null) {
        return [];
      }
      return isIterable(selected) ? selected : [selected];
    }
    if (typeof selected === 'string' && selected.length === 0) {
      return null;
    }
    return selected ?? null;
  };

  if (enumValues == null || enumValues.length === 0 || !inited) {
    return <Skeleton variant="rectangular" height={48} />;
  }

  return isAutocomplete ? (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      style={{ flexGrow: '3' }}
      options={enumValues}
      value={getValue()}
      isOptionEqualToValue={checkOptionValueEquality}
      getOptionLabel={(option) => {
        if (option?.name == null) {
          return '';
        }
        return t(`${translationToUse}:${decapitalize(option.name)}`);
      }}
      onChange={(e, values) => setValues(values)}
      renderOption={(props, option) => (
        <li {...props} key={option.value} value={option.value}>
          {t(`${translationToUse}:${decapitalize(option.name)}`)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          label={required ? `${label} *` : label}
          variant="standard"
          fullWidth
          error={errorText != null && errorText.length > 0}
          helperText={errorText}
          {...params}
        />
      )}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      {...rest}
    />
  ) : (
    <FormControl variant="standard" fullWidth error={!!errorText}>
      <InputLabel id={selectLabelId}>{required ? `${label} *` : label}</InputLabel>
      <Select
        labelId={selectLabelId}
        multiple={multiple}
        value={getValue()}
        onChange={(e) => setValues(e.target.value)}
        renderValue={(selectedOpt) => {
          if (multiple) {
            return selectedOpt
              .map((o) => t(`${translationToUse}:${decapitalize(o.name)}`))
              .join(', ');
          }
          return t(`${translationToUse}:${decapitalize(selectedOpt.name)}`);
        }}
        {...rest}
      >
        {enumValues.map((option) => (
          <MenuItem key={`option-${option.value}`} value={option}>
            {option.name ? t(`${translationToUse}:${decapitalize(option.name)}`) : ''}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText>With label + helper text</FormHelperText>}
    </FormControl>
  );
};

EnumSelector.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  enumValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  errorText: PropTypes.string,
  isAutocomplete: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  translationToUse: PropTypes.string,
};

EnumSelector.defaultProps = {
  autoFocus: false,
  defaultValue: null,
  errorText: null,
  isAutocomplete: true,
  multiple: false,
  required: false,
  translationToUse: 'common',
};

export default EnumSelector;
