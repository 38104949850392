import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';
import { UseToggle } from '@/shared/hooks/UseToggle';

import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

type Props<T> = {
  toggle: UseToggle<T>;
  onSubmit: (value: Nullable<string>) => void;
  onCancel?: () => void;
  groupOptions: string[];
  currentValue?: Nullable<string>;
};

const ChangeLineItemGroupModal = <T,>({
  toggle,
  onSubmit,
  onCancel,
  groupOptions,
  currentValue,
}: Props<T>) => {
  const [selectedGroup, setSelectedGroup] = useState(currentValue || null);

  const { t } = useTranslation();

  useEffect(() => setSelectedGroup(currentValue || null), [currentValue]);

  const cancel = () => {
    onCancel?.();
    toggle.hide();
  };

  return (
    <KpcModal title={t('changeGroup')} toggle={toggle}>
      <>
        <FormGroup className="mb-3">
          <Autocomplete
            noOptionsText={t('noOptions')}
            freeSolo
            value={selectedGroup ?? ''}
            options={groupOptions.sort((a, b) => a.localeCompare(b))}
            onChange={(_, val) => setSelectedGroup(val)}
            onInputChange={(_, val) => setSelectedGroup(val)}
            renderInput={(params) => (
              <TextField label={t('groupName')} variant="standard" autoFocus {...params} />
            )}
          />
        </FormGroup>
        <ModalFooter onCancel={cancel} onSubmit={() => onSubmit(selectedGroup)} />
      </>
    </KpcModal>
  );
};

export default ChangeLineItemGroupModal;
