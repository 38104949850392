import PropTypes from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';

import SelectTeamUser from '../components/select/SelectTeamUser';

const FormikTeamUserSelector = ({
  name,
  label,
  callback,
  selectSingle,
  nameToUseForTouch,
  ...rest
}) => {
  const { values, setFieldValue, touched, setFieldTouched } = useFormikContext();

  const nameToUse = nameToUseForTouch == null ? name : nameToUseForTouch;

  const updateAndCallback = React.useCallback(
    (teamUsers) => {
      if (!touched[nameToUse]) {
        setFieldTouched(nameToUse, true);
      }

      setFieldValue(name, teamUsers);
      callback(teamUsers);
    },
    [callback, name, nameToUse, setFieldTouched, setFieldValue, touched],
  );

  return (
    <SelectTeamUser
      label={label}
      selectSingle={selectSingle}
      onBlur={() => setFieldTouched(nameToUse, true)}
      callback={updateAndCallback}
      value={values[name] ?? []}
      isTouched={!!touched[nameToUse]}
      {...rest}
    />
  );
};

FormikTeamUserSelector.propTypes = {
  callback: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameToUseForTouch: PropTypes.string,
  selectSingle: PropTypes.bool,
};

FormikTeamUserSelector.defaultProps = {
  callback: () => {},
  label: null,
  nameToUseForTouch: null,
  selectSingle: false,
};

export default FormikTeamUserSelector;
