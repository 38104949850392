import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import EditSubscriptionFormBody from '../TeamSettings/Common/EditSubscriptionFormBody';
import FormikCrmSelector from '../../../shared/formik/FormikCrmSelector';
import FormikTextField from '../../../shared/formik/FormikTextField';
import SelectWorkdays from '../../../shared/components/select/SelectWorkdays';

import ReferencePropType from '../../../shared/prop-types/ReferencePropType';
import useGetEnums from '../../../queries/enums/useGetEnums';
import { useUserRole } from '../../../queries';

const propTypes = {
  initialWorkDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEdit: PropTypes.bool,
  popperContainer: ReferencePropType,
};

const AddEditTeamFormBody = ({
  isEdit = false,
  popperContainer = null,
  initialWorkDays = 'Monday, Tuesday, Wednesday, Thursday, Friday',
}) => {
  const [selectedWorkdays, setSelectedWorkdays] = useState([]);

  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const { isAdmin } = useUserRole();
  const {
    enums: { workdays },
  } = useGetEnums();

  useEffect(() => {
    if (
      workdays &&
      workdays.length > 0 &&
      initialWorkDays &&
      initialWorkDays.length &&
      typeof initialWorkDays === 'string'
    ) {
      const selectedDays = initialWorkDays.replaceAll(' ', '').split(',');
      setSelectedWorkdays(selectedDays.map((sr) => workdays.find((wd) => wd.name === sr).value));
    }
  }, [initialWorkDays, workdays]);

  useEffect(() => {
    if (selectedWorkdays.length === 0) {
      setFieldValue('workdays', null);
    } else if (selectedWorkdays.length === 1) {
      setFieldValue('workdays', selectedWorkdays[0]);
    } else {
      let result = null;

      selectedWorkdays.forEach((v) => {
        // eslint-disable-next-line no-bitwise
        result |= v;
      });
      setFieldValue('workdays', result);
    }
  }, [setFieldValue, selectedWorkdays]);

  return (
    <>
      <FormGroup className="mb-3">
        <FormikTextField
          fullWidth
          id="name input-with-sx"
          name="name"
          type="text"
          required
          variant="standard"
          label={t('name')}
        />
      </FormGroup>
      {isAdmin && (
        <FormGroup className="mb-3">
          <FormikCrmSelector
            id="centralCompanySelector"
            name="centralCompanySelector"
            selectSingle
            showCentralCompanies
            centralCompanyIdsToSelect={[
              ...(values.centralCompanyId ? [values.centralCompanyId] : []),
            ]}
            label={t('company')}
            isRequired
            callback={({ centralCompany }) => setFieldValue('centralCompanyId', centralCompany?.id)}
            popperContainer={popperContainer}
            disablePortal={false}
          />
        </FormGroup>
      )}
      {!isEdit && (
        <>
          <FormGroup className="mb-3 mt-6">
            <InputLabel>{t('subscription')}</InputLabel>
            <EditSubscriptionFormBody />
          </FormGroup>
          <FormGroup className="mb-3">
            <InputLabel className="mb-2">{t('workdays')}</InputLabel>
            <SelectWorkdays
              selectedWorkdays={selectedWorkdays}
              setSelectedWorkdays={setSelectedWorkdays}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};

AddEditTeamFormBody.propTypes = propTypes;

export default AddEditTeamFormBody;
