import { isPlainObject } from 'lodash';

import { Obj } from '../types';

type SortConfigBase = {
  key?: string;
};

type DefaultSortConfig<T> = SortConfigBase & {
  sortFn: (a: T, b: T) => number;
};

type CustomSortConfig<T> = SortConfigBase & {
  order: T[];
  sortFn: (a: T, b: T, order?: T[]) => number;
};

type SortConfig<T> = DefaultSortConfig<T> | CustomSortConfig<T>;

const isCustomSortConfig = <T>(config: SortConfig<T>): config is CustomSortConfig<T> =>
  !!(config as CustomSortConfig<T>).order;

// sticking with any since the a and b types of sortFn should be independent of one another (unless a better solution is found)
// fe first sortFn could be string, second sortFn number since different keys could be involved
export const createMultiSort =
  (...sortConfigs: SortConfig<any>[]) =>
  <T>(list: T[]): T[] =>
    list.sort((a, b) => {
      let result = 0;

      const hasKey = (item: T, key?: string): key is string =>
        !!key && isPlainObject(item) && Object.hasOwn(item as Obj, key);

      sortConfigs.some((sortConfig) => {
        const { key } = sortConfig;

        // no nested keys like 'item.unitLink.registrationNumber', can be added later if needed
        const aValue = hasKey(a, key) ? a[key as keyof T] : a;
        const bValue = hasKey(b, key) ? b[key as keyof T] : b;

        if (isCustomSortConfig(sortConfig)) {
          result = sortConfig.sortFn(aValue, bValue, sortConfig.order);
        } else {
          result = sortConfig.sortFn(aValue, bValue);
        }

        return result !== 0;
      });

      return result;
    });

const isNumber = (value: string) => !Number.isNaN(Number(value));

export const sortAlphaNum = (a: string, b: string) => {
  const splitAplhaNumRegex = /(\d+|\D+)/g;

  const aParts = a.match(splitAplhaNumRegex) || [];
  const bParts = b.match(splitAplhaNumRegex) || [];

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i += 1) {
    const aPart = aParts[i];
    const bPart = bParts[i];

    if (isNumber(aPart) && isNumber(bPart)) {
      const aNum = parseInt(aPart, 10);
      const bNum = parseInt(bPart, 10);

      if (aNum !== bNum) return aNum - bNum;
    } else if (aPart !== bPart) {
      return aPart.localeCompare(bPart);
    }
  }

  return a.length - b.length;
};

export const sortWithCustomOrder = (a: string, b: string, order: string[] = []) =>
  order.indexOf(a) - order.indexOf(b);

export const sortByAlphaNum = <T extends Obj>(items: T[], key: keyof T): T[] =>
  items.sort((a, b) => {
    const aValue = a[key] || '';
    const bValue = b[key] || '';

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortAlphaNum(aValue, bValue);
    }

    throw new Error(
      `Can't sort objects with the passed property key: '${key.toString()}'. Make sure it points to a string value.`,
    );
  });
