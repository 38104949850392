import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryIds } from '@/shared/utils/constants';
import { taskApi } from '../../shared/api';
import { trpc } from '@/config/trpc';
import useNotification from '../../shared/hooks/UseNotification';

const useUpdateTask = (options = {}) => {
  const utils = trpc.useUtils();
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (id) => taskApi.updateTask(id),
    onError: (err) => sendDefaultError(err),
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('task') }),
        variant: 'success',
      });

      if (options.caseId) {
        utils.task.caseTasks.invalidate({ caseId: options.caseId });
      } else {
        queryClient.invalidateQueries({ queryKey: [queryIds.tasks.GET_TASK_OVERVIEW] });
      }

      if (options.callback) {
        options.callback();
      }
    },
  });

  return {
    isUpdatingTask: isLoading,
    updateTask: (id) => mutate(id),
  };
};

export default useUpdateTask;
