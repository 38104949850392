import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import SelectPolicy from '../components/select/SelectPolicy';

const FormikPolicySelector = ({
  name,
  label,
  selectSingle,
  callback,
  onSuggestedPoliciesFound,
  ...rest
}) => {
  const { values, setFieldValue, touched, setTouched } = useFormikContext();

  const updateAndCallback = (updated) => {
    setFieldValue(name, { ...updated }, true);
    callback(updated);
  };

  const updatePolicies = (policies) => {
    if (selectSingle) {
      const updated = {
        policies: policies.length > 0 ? [policies[0]] : null,
      };
      updateAndCallback(updated);
      return;
    }
    updateAndCallback({ ...values[name], policies });
  };

  return (
    <SelectPolicy
      onPolicySelectChanged={updatePolicies}
      selectSingle={selectSingle}
      label={label}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      onSuggestedPoliciesFound={onSuggestedPoliciesFound}
      defaultPolicyIdsToSelect={
        values[name]?.policies ? values[name].policies.map((p) => p.id) : []
      }
      policyNumbersToPrefill={
        values[name]?.policies
          ? values[name].policies.filter((p) => p.insurancePolicyType !== null)
          : []
      }
      {...rest}
    />
  );
};

FormikPolicySelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectSingle: PropTypes.bool,
  callback: PropTypes.func,
  onSuggestedPoliciesFound: PropTypes.func,
};

FormikPolicySelector.defaultProps = {
  selectSingle: false,
  callback: () => {},
  onSuggestedPoliciesFound: () => {},
};

export default FormikPolicySelector;
