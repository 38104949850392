import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CancelIcon, InlineSpinner, SaveIcon } from '../../shared/icons/Icons';
import AddCentralCompanyFormBody from './AddCentralCompanyFormBody';

export type CreateCentralCompanyFormikValues = {
  name: string;
  vatNumber: string;
};

type Props = {
  canSubmit?: boolean;
  isSubmitting?: boolean;
  onCancel: () => void;
  onSubmit: (values: CreateCentralCompanyFormikValues) => void;
  setCanSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCentralCompanyForm: React.FC<Props> = ({
  canSubmit = true,
  isSubmitting = false,
  onCancel,
  onSubmit,
  setCanSubmit = () => {},
}) => {
  const { t } = useTranslation(['common', 'errors']);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: '',
        vatNumber: '',
      }}
      validationSchema={yup.object().shape({
        name: yup.string().nullable().required(t('errors:fieldIsRequired')),
        vatNumber: yup.string().nullable().required(t('errors:fieldIsRequired')),
      })}
    >
      <Form>
        <AddCentralCompanyFormBody setCanSubmit={setCanSubmit} />
        <div className="flex justify-between space-x-4 pt-2">
          <Button
            variant="soft"
            color="neutral"
            size="sm"
            startDecorator={<CancelIcon />}
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            variant="solid"
            type="submit"
            size="sm"
            disabled={!canSubmit || isSubmitting}
            startDecorator={isSubmitting ? <InlineSpinner /> : <SaveIcon />}
          >
            {t('common:save')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default AddCentralCompanyForm;
