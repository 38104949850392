import { useMemo, useState } from 'react';

import { AutocompleteOption, Nullable } from '~/common/types';
import { formatBytes } from '@/shared/utils/helpers';
import { useGetCaseFiles } from '@/queries';

export type DocumentManager = {
  get: {
    caseFiles: AutocompleteOption[];
    caseFileAutocomplete: AutocompleteOption[];
    uploadedFiles: File[];
  };
  set: {
    caseFiles: (caseFiles: AutocompleteOption[]) => void;
    uploadedFiles: (uploadedFiles: File[]) => void;
  };
};

export const useDocumentManager: (caseId: Nullable<string>) => DocumentManager = (caseId) => {
  const [selectedCaseFiles, setSelectedCaseFiles] = useState<AutocompleteOption[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const { caseFiles } = useGetCaseFiles(caseId);

  const caseFileOptions = useMemo(
    () =>
      caseFiles.map((obj) => ({
        label: obj.fileName ? `${obj.fileName} (${formatBytes(obj.fileSize)})` : '-',
        value: obj.id,
      })),
    [caseFiles],
  );

  return {
    get: {
      caseFileAutocomplete: caseFileOptions,
      caseFiles: selectedCaseFiles,
      uploadedFiles,
    },
    set: {
      caseFiles: (files: AutocompleteOption[]) => setSelectedCaseFiles(files),
      uploadedFiles: (files: File[]) => setUploadedFiles(files),
    },
  } as const;
};
