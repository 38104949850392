import PropTypes from 'prop-types';

const CaseRelationPropTypes = PropTypes.shape({
  id: PropTypes.string,
  caseId: PropTypes.string,
  teamId: PropTypes.string,
  contactId: PropTypes.string,
  companyId: PropTypes.string,
  isOwner: PropTypes.bool,
  isRequestor: PropTypes.bool,
  isClient: PropTypes.bool,
  isContractor: PropTypes.bool,
  hasAccess: PropTypes.bool,
  displayName: PropTypes.string,
  role: PropTypes.string,
  relationReference: PropTypes.string,
});

export default CaseRelationPropTypes;
