import { IconButton } from '@mui/joy';
import { memo } from 'react';
import PropTypes from 'prop-types';

import { EditIcon } from '../../../icons/Icons';

const propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  rowId: PropTypes.string.isRequired,
  setCanEditRow: PropTypes.func,
  setSelectedId: PropTypes.func.isRequired,
  toggles: PropTypes.shape({
    create: PropTypes.shape({
      show: PropTypes.func,
    }),
    edit: PropTypes.shape({
      value: PropTypes.bool,
    }),
  }).isRequired,
};

const EditRowCell = ({ rowData, rowId, setSelectedId, setCanEditRow, toggles }) => {
  const handleEditRow = () => {
    toggles.create.show();
    setSelectedId(rowData[rowId]);
  };

  const editDisabled = () => {
    if (!setCanEditRow) return false;
    return !setCanEditRow(rowData);
  };

  return (
    <div key={rowData[rowId]} className="flex items-center justify-center">
      {!!toggles.edit && toggles.edit.value && (
        <IconButton onClick={handleEditRow} disabled={editDisabled()}>
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};

EditRowCell.propTypes = propTypes;

export default memo(EditRowCell);
