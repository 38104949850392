import axios, { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { queryIds } from '@/shared/utils/constants';

const DEFAULT_REGION = 'BE';
const GOOGLE_GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode';

const client = axios.create({
  baseURL: GOOGLE_GEOCODE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

type Params = {
  address: string;
  component?: string;
  region?: string;
};

const useGoogleGeocoding = (params: Params, options = { enabled: true }) => {
  const { data, isLoading } = useQuery({
    enabled: options.enabled,
    queryFn: (): Promise<AxiosResponse<google.maps.GeocoderResponse>> =>
      client.get('/json', {
        params: {
          ...params,
          key: import.meta.env.VITE_G_MAPS_API_KEY,
          regions: params.region || DEFAULT_REGION,
        },
      }),
    queryKey: [queryIds.geocode, params],
  });

  return {
    geocodeData: data?.data,
    geocodeDataIsLoading: isLoading,
  };
};

export default useGoogleGeocoding;
