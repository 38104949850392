import { useQuery } from '@tanstack/react-query';

import { Nullable } from '~/common/types';

import useNotification from '@/shared/hooks/UseNotification';

import fileManagerApi from '@/shared/api/fileManager/fileManager';
import { queryIds } from '@/shared/utils/constants';

const useGetFileUri = (fileId: Nullable<string>) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!fileId,
    onError: sendDefaultError,
    queryFn: () => fileManagerApi.getUri(fileId!),
    queryKey: [queryIds.fileManager.GET_FILE_URI, fileId],
  });

  return {
    uri: data?.data,
    uriIsLoading: isLoading,
  };
};

export default useGetFileUri;
