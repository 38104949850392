import actionTypes from '../actions/actionTypes';

const initialState = {
  teams: [],
  loading: false,
  error: false,
  currentTeam: null,
  initialLoading: true,
};

const teamsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_TEAMS_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.teams,
        loading: action.loading,
        initialLoading: false,
      };
    case actionTypes.TEAMS_APPEND:
      return {
        ...state,
        teams: state.teams.concat(action.teams),
        loading: action.loading,
        initialLoading: false,
      };
    case actionTypes.GET_CURRENT_TEAM_SUCCESS: {
      const { currentTeam } = action;
      if (state.currentTeam) {
        const { contractorSkillDefinitions } = state.currentTeam;
        currentTeam.contractorSkillDefinitions = contractorSkillDefinitions;
      }
      return {
        ...state,
        currentTeam,
      };
    }
    case actionTypes.GET_TEAMS_FAILED:
      return {
        ...state,
        loading: false,
        initialLoading: false,
        error: true,
      };
    case actionTypes.UPDATE_CURRENT_TEAM:
      localStorage.setItem('teamId', action.teamId);
      return { ...state, currentTeam: state.teams.find((team) => team.id === action.teamId) };
    case actionTypes.UPDATE_CURRENT_TEAM_DATA:
      return { ...state, currentTeam: action.updatedCurrentTeam };
    case actionTypes.REMOVE_TEAM:
      if (state.currentTeam.id === action.teamId) {
        return {
          ...state,
          currentTeam: null,
          teams: state.teams.filter((team) => team.id !== action.teamId),
        };
      }
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== action.teamId),
      };
    case actionTypes.ADD_OR_UPDATE_TEAM:
      if (state.currentTeam.id === action.team.id) {
        // Update teamsReducer & current team
        return {
          ...state,
          teams: state.teams.map((team) => {
            if (team.id === action.team.id) {
              return { ...action.team };
            }
            return { ...team };
          }),
          currentTeam: action.team,
        };
      }
      if (state.teams.some((team) => team.id === action.team.id)) {
        // Update
        return {
          ...state,
          teams: state.teams.map((team) => {
            if (team.id === action.team.id) {
              return { ...action.team };
            }
            return { ...team };
          }),
        };
      }
      // Add
      return { ...state, teams: [...state.teams, action.team] };
    case actionTypes.GET_CURRENT_CONTRACTOR_SKILLS_DEFINITIONS_SUCCESS: {
      const { currentTeam } = state;
      if (currentTeam) {
        currentTeam.contractorSkillDefinitions = action.currentContractorSkillDefinitions;
      }
      return {
        ...state,
        currentTeam,
      };
    }
    case actionTypes.UPDATE_CONTRACTOR_SKILL_DEFINITIONS_SUCCESS: {
      const { currentTeam } = state;
      if (currentTeam) {
        if (
          currentTeam.contractorSkillDefinitions.some(
            (c) => c.id === action.contractorSkillDefinition.id,
          )
        ) {
          // Update
          currentTeam.contractorSkillDefinitions = currentTeam.contractorSkillDefinitions.map(
            (obj) => {
              if (obj.id === action.contractorSkillDefinition.id) {
                return { ...action.contractorSkillDefinition };
              }
              return obj;
            },
          );
        } else {
          // Add
          currentTeam.contractorSkillDefinitions = currentTeam.contractorSkillDefinitions.concat(
            action.contractorSkillDefinition,
          );
        }
      }

      return {
        ...state,
        currentTeam,
      };
    }
    default:
      return state;
  }
};

export default teamsReducer;
