import { ClickAwayListener, Popper as PopperUnstyled } from '@mui/base';
import { IconButton, Tooltip } from '@mui/joy';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddressPropTypes from '../../prop-types/AddressPropTypes';
import { LinkIcon } from '../../icons/Icons';
import SelectBuilding from '../select/SelectBuilding';
import { sentencize } from '../../utils/helpers';

const propTypes = {
  centralCompanyId: PropTypes.string,
  isEditable: PropTypes.bool,
  onBuildingSelection: PropTypes.func.isRequired,
  prefillAddress: AddressPropTypes,
};

const LinkBuildingButton = ({
  onBuildingSelection,
  centralCompanyId = null,
  prefillAddress = null,
  isEditable = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef();

  const onBuildingChanged = (buildings) => {
    if (buildings && buildings.length > 0) {
      setIsOpen(false);
      onBuildingSelection(buildings[0]);
    }
  };

  return (
    <>
      <Tooltip title={sentencize(`${t('common:building')} ${t('common:link')}`)}>
        <IconButton
          variant="plain"
          ref={anchorEl}
          onClick={() => setIsOpen((prev) => !prev)}
          disabled={!isEditable}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>

      <PopperUnstyled
        open={isOpen}
        className="z-popper bg-white"
        anchorEl={anchorEl?.current}
        placement="bottom-start"
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className="rounded-xl border px-2 shadow-lg">
            <SelectBuilding
              {...rest}
              hideAddAddress
              showSuggestions
              alwaysOpen
              selectSingle
              centralCompanyId={centralCompanyId}
              onBuildingSelectChanged={onBuildingChanged}
              prefillAddress={prefillAddress}
            />
          </div>
        </ClickAwayListener>
      </PopperUnstyled>
    </>
  );
};

LinkBuildingButton.propTypes = propTypes;

export default LinkBuildingButton;
