import { memo } from 'react';

import { PriorityHighIcon, PriorityLowIcon, PriorityNormalIcon } from '@/shared/icons/Icons';
import { Priority } from '~/common/enums';

type PriorityIconProps = {
  priority: Priority;
};

const PriorityIcon: React.FC<PriorityIconProps> = ({ priority }) => {
  switch (priority) {
    case Priority.High:
      return <PriorityHighIcon />;
    case Priority.Normal:
      return <PriorityNormalIcon />;
    case Priority.Low:
      return <PriorityLowIcon />;
    default:
      return null;
  }
};

export default memo(PriorityIcon);
