import { Button, ColorPaletteProp } from '@mui/joy';
import { Chip, TextField } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import multiDownload from 'multi-download';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import { arraysAreEqual, sentencize } from '@/shared/utils/helpers';
import API from '@/shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import { FileContext } from '@/FileContext';
import { queryIds } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import { useGetCaseRelation } from '@/queries';
import useNotification from '@/shared/hooks/UseNotification';

import AvatarWithColor from '@/shared/components/2.0/AvatarWithColor';
import CustomTextFieldAdornment from '@/shared/components/CustomTextFieldAdorment';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import RequestFileForm from '@/containers/Cases/CaseLayout/BodySections/Forms/RequestFileForm';
import RequestFileUploadForm from '@/containers/Cases/CaseLayout/BodySections/Forms/RequestFileUploadForm';
import SelectTeamUser from '@/shared/components/select/SelectTeamUser';
import TextCheckbox from '@/shared/components/checkboxes/TextCheckbox';
import useToggle from '@/shared/hooks/UseToggle';

const MAX_REFERENCE_LENGTH = 70;

type RelationDocumentRequest = {
  crmValues: {
    team: {
      id: string;
    };
  };
  description: string;
  dueDate: string;
};

type Props = {
  rowData: {
    canEdit?: boolean;
    canManageOwnTeam?: boolean;
    caseId: string;
    caseReference: string;
    currentTeamIsOwner: boolean;
    isInsuranceClaimCase: boolean;
    currentTeamIsRequestor: boolean;
    displayName: string;
    hasAccess: boolean;
    id: string;
    relationReference: Nullable<string>;
    relationRemark: Nullable<string>;
    shouldRequestDocument?: boolean;
    teamId: Nullable<string>;
  };
};

const RelationDetailRow: React.FC<Props> = ({ rowData }) => {
  const {
    canEdit,
    canManageOwnTeam,
    caseId,
    caseReference,
    currentTeamIsOwner,
    isInsuranceClaimCase,
    currentTeamIsRequestor,
    displayName,
    hasAccess,
    id,
    relationReference,
    relationRemark,
    shouldRequestDocument = true,
    teamId,
  } = rowData;

  const [showReferenceLengthWarning, setShowReferenceLengthWarning] = useState(false);
  const [referenceValue, updateReferenceValue] = useState(
    relationReference == null || relationReference.length === 0 ? caseReference : relationReference,
  );
  const [isReferenceDirty, setIsReferenceDirty] = useState(false);

  const [remarkValue, updateRemarkValue] = useState(relationRemark ?? '');
  const [isRemarkDirty, setIsRemarkDirty] = useState(false);

  const [uploadDocumentTaskId, setUploadDocumentTaskId] = useState<Nullable<string>>(null);

  const {
    ui: { currentTeamId },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const { files } = useContext(FileContext);

  const { caseRelation, caseRelationLoading } = useGetCaseRelation({
    caseId,
    relationId: id,
  });
  const { data: caseTasks, isLoading: caseTasksIsLoading } = trpc.task.caseTasks.useQuery({
    caseId,
  });

  const requestToggle = useToggle();
  const uploadToggle = useToggle();

  const queryClient = useQueryClient();

  const administratorTeamUsers = useMemo(() => {
    if (caseRelationLoading || !caseRelation?.administrators?.length) {
      return [];
    }
    return caseRelation.administrators?.map((admin) => admin.teamUserId);
  }, [caseRelation, caseRelationLoading]);

  const isOwnerOrOwnTeam = useMemo(
    () => currentTeamIsOwner || teamId === currentTeamId,
    [currentTeamIsOwner, teamId, currentTeamId],
  );

  const handleReferenceChange = (e: Event) => {
    if (!isOwnerOrOwnTeam) {
      return;
    }
    const { value } = e.target as HTMLInputElement;
    if (value?.length > MAX_REFERENCE_LENGTH) {
      setShowReferenceLengthWarning(true);
    } else {
      setShowReferenceLengthWarning(false);
    }
    updateReferenceValue(value);
    if (value === relationReference) {
      setIsReferenceDirty(false);
      return;
    }
    setIsReferenceDirty(true);
  };

  const handleCancelReferenceChange = () => {
    updateReferenceValue(relationReference ?? '');
    setIsReferenceDirty(false);
    setShowReferenceLengthWarning(false);
  };

  const handleRemarkChange = (e: Event) => {
    if (!isOwnerOrOwnTeam && !(isInsuranceClaimCase && currentTeamIsRequestor)) {
      return;
    }
    const { value } = e.target as HTMLInputElement;
    updateRemarkValue(value);
    if (value === relationRemark) {
      setIsRemarkDirty(false);
      return;
    }
    setIsRemarkDirty(true);
  };

  const handleCancelRemarkChange = () => {
    updateRemarkValue(relationRemark ?? '');
    setIsRemarkDirty(false);
  };

  const resetDirty = useCallback(() => {
    setIsReferenceDirty(false);
    setIsRemarkDirty(false);
  }, []);

  const handleSaveRelationDetails = async (data: any) => {
    await API.putUpdateCaseRelationDetails({
      caseId,
      relationId: id,
      ...data,
    });

    queryClient.invalidateQueries([queryIds.cases.GET_CASE_RELATION, caseId, id]);
  };

  const handleSave = (access: boolean, newAdmins: Nullable<string[]> = null) => {
    handleSaveRelationDetails({
      administrators: newAdmins ?? administratorTeamUsers,
      hasAccess: access,
      reference: referenceValue,
      remark: remarkValue,
    });
    resetDirty();
  };

  const handleAdminsUpdate = (newAdmins: Nullable<string[]>) => {
    if (!arraysAreEqual(newAdmins ?? [], administratorTeamUsers ?? [])) {
      handleSave(hasAccess, newAdmins);
    }
  };

  const getOtherTeamAdminChips = useMemo(() => {
    if (caseRelationLoading || !caseRelation?.administrators?.length) {
      return [];
    }
    return caseRelation.administrators.map((u) => {
      const { name } = u;
      return (
        <KpcTooltip disableFocusListener title={name}>
          <Chip avatar={<AvatarWithColor name={name} size="lg" />} key={name} className="me-1" />
        </KpcTooltip>
      );
    });
  }, [caseRelation?.administrators, caseRelationLoading]);

  const onRequestRelationDocument = useCallback(
    async (data: RelationDocumentRequest) => {
      if (!shouldRequestDocument) {
        return;
      }
      const { crmValues, description, dueDate } = data;
      const { team } = crmValues;
      const response = await API.postRequestDocumentFromCaseRelation({
        caseId,
        description,
        dueDate,
        relationId: id,
        teamId: team.id,
      });

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
        return;
      }

      requestToggle.hide();
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyRequested', { type: t('document') }),
        variant: 'success',
      });
    },
    [caseId, id, sendDefaultError, sendNotification, t, shouldRequestDocument],
  );

  const showDocumentUploadModalForTask = (taskId: string) => {
    setUploadDocumentTaskId(taskId);
    uploadToggle.show();
  };

  const downloadLinkedFiles = async (taskId: string) => {
    const filesToDownload = files.filter(
      ({ tasks }) => tasks != null && tasks.length > 0 && tasks.some((task) => task === taskId),
    );
    if (filesToDownload == null || filesToDownload.length === 0) {
      return;
    }

    const filesToLoad = filesToDownload.map((f) => f.fileUri);

    multiDownload(filesToLoad, {
      rename: ({ index }: { index: number }) => filesToDownload[index].fileName,
    });
  };

  return (
    <div className="m-2">
      <TextField
        label={t('reference')}
        value={referenceValue}
        variant="standard"
        disabled={!isOwnerOrOwnTeam || !canEdit}
        fullWidth
        className="mb-4"
        error={showReferenceLengthWarning}
        helperText={
          showReferenceLengthWarning
            ? sentencize(
                t('errors:fieldXmaxYcharacters', {
                  field: t('reference'),
                  max: MAX_REFERENCE_LENGTH,
                }),
              )
            : ''
        }
        /* @ts-ignore */
        onChange={handleReferenceChange}
        InputProps={{
          endAdornment: (
            <CustomTextFieldAdornment
              isDirty={isReferenceDirty}
              canSave={!showReferenceLengthWarning}
              onCancel={handleCancelReferenceChange}
              onSave={() => handleSave(hasAccess)}
            />
          ),
        }}
      />
      <TextField
        label={t('remark')}
        variant="standard"
        fullWidth
        className="mb-4"
        value={remarkValue}
        disabled={!canEdit && !(isInsuranceClaimCase && currentTeamIsRequestor)}
        /* @ts-ignore */
        onChange={handleRemarkChange}
        InputProps={{
          endAdornment: (
            <CustomTextFieldAdornment
              isDirty={isRemarkDirty}
              onCancel={handleCancelRemarkChange}
              onSave={() => handleSave(hasAccess)}
            />
          ),
        }}
      />
      {/* @ts-ignore */}
      <TextCheckbox
        id={id}
        yesText={t('hasAccessToThisCase')}
        noText={t('hasNoAccessToThisCase')}
        isChecked={hasAccess}
        hidden={teamId == null}
        customClass="mb-4"
        disabled={
          !currentTeamIsOwner ||
          (currentTeamIsOwner && teamId === currentTeamId && !canManageOwnTeam) ||
          !canEdit
        }
        disabledInfo={
          !currentTeamIsOwner
            ? t('onlyCaseOwnerCanChangeAccessibility')
            : t('cannotChangeTheAccessibilityOfYourOwnTeam')
        }
        paragraphProps={{
          style: {
            margin: '-3px',
          },
        }}
        callback={(newVal: boolean) => handleSave(newVal)}
      />
      {hasAccess && teamId === currentTeamId && (
        <SelectTeamUser
          usersToSelect={administratorTeamUsers}
          label={t('administrators')}
          callback={handleAdminsUpdate}
          /* @ts-ignore */
          className="mb-4"
          disabled={teamId !== currentTeamId || !canEdit}
        />
      )}
      {hasAccess && teamId !== currentTeamId && (
        <TextField
          size="small"
          id="input-with-sx"
          label={t('administrators')}
          variant="standard"
          autoComplete="off"
          fullWidth
          disabled
          InputProps={{
            startAdornment: getOtherTeamAdminChips,
          }}
        />
      )}
      {!caseTasksIsLoading &&
        !!caseTasks &&
        caseTasks
          .filter((ct) => ct.isCaseEntityRequestedTask && ct.caseRelationId === id)
          .sort((a, b) => Number(a.completedDate != null) - Number(b.completedDate != null))
          .map((reqTask) => (
            <Button
              disabled={!canEdit}
              key={reqTask.id}
              type="button"
              className="m-1 w-[250px]"
              onClick={() =>
                reqTask.completedDate == null
                  ? showDocumentUploadModalForTask(reqTask.id)
                  : downloadLinkedFiles(reqTask.id)
              }
              color={
                reqTask.completedDate == null
                  ? ('primary' as ColorPaletteProp)
                  : ('secondary' as ColorPaletteProp)
              }
              startDecorator={
                reqTask.completedDate == null ? (
                  <FontAwesomeIcon icon={['fad', 'upload']} />
                ) : (
                  <FontAwesomeIcon icon={['fad', 'download']} />
                )
              }
            >
              {reqTask.description}
              {reqTask.completedDate == null && (
                <KpcTooltip
                  title={`${t('dueDate')}: ${moment(reqTask.dueDate, 'YYYY-MM-DD').fromNow()}`}
                >
                  <i className="ms-2">
                    <FontAwesomeIcon icon={['fad', 'clock']} />
                  </i>
                </KpcTooltip>
              )}
            </Button>
          ))}
      {shouldRequestDocument && (
        <>
          <br />
          <Button
            onClick={requestToggle.show}
            disabled={!canEdit}
            className="m-1 mt-4 w-[250px]"
            startDecorator={
              <i>
                <FontAwesomeIcon icon={['fad', 'file-circle-info']} />
              </i>
            }
          >
            {t('requestDocument')}
          </Button>
          {currentTeamId && (
            <KpcModal
              title={t('requestDocumentFrom', { name: displayName })}
              toggle={requestToggle}
            >
              <RequestFileForm
                onCancel={requestToggle.hide}
                onSubmit={onRequestRelationDocument}
                caseId={caseId}
                selectedTeamId={currentTeamId}
              />
            </KpcModal>
          )}
        </>
      )}
      {uploadDocumentTaskId && (
        <KpcModal title={t('uploadFiles')} toggle={uploadToggle}>
          <RequestFileUploadForm
            taskId={uploadDocumentTaskId}
            onCancel={uploadToggle.hide}
            caseId={caseId}
          />
        </KpcModal>
      )}
    </div>
  );
};

export default RelationDetailRow;
