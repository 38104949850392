import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
} from '@mui/joy';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { AppContext } from '@/shared/context/context';
import { decapitalize } from '@/shared/utils/helpers';
import ExcessType from '@/shared/enums/ExcessType';
import { Nullable } from '~/common/types';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import { CancelIcon, EuroIcon, FileIcon, SaveIcon } from '@/shared/icons/Icons';
import { CrmOptionType } from '~/common/enums';
import { FormikValues } from './AddRepairCaseStepper';
import HeaderStep from './HeaderStep';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import NumericFormatAdapter from '@/shared/components/2.0/adapters/NumericFormatAdapter';
import { useGetCompanyTeams } from '@/queries';

type Policy = {
  policyId: Nullable<string>;
  policyNumber: string;
};

const excessTypes: string[] = Object.values(ExcessType);

const PolicyStep = () => {
  const [policyNumber, setPolicyNumber] = useState<string>('');
  const [allPolicies, setAllPolicies] = useState<Policy[]>([]);

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { currentTeam } = useContext(AppContext);

  const policyToggle = useToggle();
  const closePolicyToggle = useToggle(true);

  const { data: activeContracts } = trpc.case.contracts.useQuery();

  const { data: policies } = trpc.policy.list.useQuery();

  const currentContract = activeContracts?.find((contract) => contract.id === values.contractId);

  const { data: contractCompany } = trpc.company.byId.useQuery(
    currentContract?.companyId as string,
    {
      enabled: !!currentContract?.companyId,
    },
  );

  const { data: ownTeamCompany } = trpc.company.byId.useQuery(currentTeam?.companyId as string, {
    enabled: !!currentTeam?.companyId,
  });

  useEffect(() => {
    if (currentContract?.admins.length > 0) {
      setFieldValue('admins', currentContract?.admins);
    }
  }, [currentContract, setFieldValue]);

  useEffect(() => {
    if (policies) {
      const mappedPolicies = policies.map((policy) => ({
        policyId: policy.id,
        policyNumber: `${policy.policyNumber}${
          policy.insurancePolicyType
            ? ` - ${t(`insurancePolicy:${decapitalize(policy.insurancePolicyType)}`)}`
            : ''
        }`,
      }));

      if (values.policies.length) {
        setAllPolicies([...mappedPolicies, ...values.policies]);
      } else {
        setAllPolicies(mappedPolicies);
      }
    }
  }, [policies, t, values.policies]);

  const resetAddPolicy = () => {
    policyToggle.hide();
    closePolicyToggle.show();
    setPolicyNumber('');
  };

  const { companyTeams } = useGetCompanyTeams();

  // @ts-ignore
  const companyTeamsList = useMemo(
    () =>
      companyTeams.flatMap(({ teams, id: companyId }) =>
        teams.map((team) => ({
          companyId,
          isTeam: true,
          teamId: team.teamId,
        })),
      ),
    [companyTeams],
  );

  useEffect(() => {
    if (!contractCompany || values.relations.find((r) => r.companyId === contractCompany.id)) {
      return;
    }

    const setCompanyRelation = async () => {
      // @ ts-ignore
      const foundCompanyTeam = companyTeamsList.find((ct) => ct.companyId === contractCompany.id);
      const id = foundCompanyTeam ? foundCompanyTeam.teamId : contractCompany.id;

      if (foundCompanyTeam && values.relations.find((r) => r.teamId === foundCompanyTeam.teamId)) {
        return;
      }

      setFieldValue('relations', [
        ...values.relations,
        {
          ...contractCompany,
          companyId: foundCompanyTeam ? null : contractCompany.id,
          contactId: null,
          emailAddresses: contractCompany.emailAddresses.map((e) => e.email),
          entityId: contractCompany.id,
          id,
          involvementType: null,
          isClient: false,
          isRequestor: false,
          isTeam: !!foundCompanyTeam,
          label: contractCompany.displayName,
          language: contractCompany.language,
          relationReference: '',
          role: contractCompany.defaultCaseRole || null,
          teamId: foundCompanyTeam ? foundCompanyTeam.teamId : null,
          type: CrmOptionType.Company,
          value: id,
        },
      ]);
    };

    setCompanyRelation();
  }, [contractCompany, companyTeamsList, setFieldValue]);

  useEffect(() => {
    if (
      currentTeam?.allowRepairManagement ||
      !ownTeamCompany ||
      values.relations.find((r) => r.teamId === currentTeam?.id)
    )
      return;
    const setCompanyRelation = async () => {
      // @ ts-ignore
      setFieldValue('relations', [
        ...values.relations,
        {
          ...ownTeamCompany,
          companyId: null,
          contactId: null,
          emailAddresses: ownTeamCompany.emailAddresses.map((e) => e.email),
          entityId: ownTeamCompany.id,
          id: currentTeam?.id,
          involvementType: null,
          isClient: false,
          isRequestor: true,
          isTeam: true,
          label: ownTeamCompany.displayName,
          language: ownTeamCompany.language,
          relationReference: '',
          role: ownTeamCompany.defaultCaseRole || null,
          teamId: currentTeam?.id,
          type: CrmOptionType.Company,
          value: currentTeam?.id,
        },
      ]);
    };

    setCompanyRelation();
  }, [companyTeamsList, currentTeam?.id, ownTeamCompany, setFieldValue]);

  return (
    <>
      <HeaderStep
        title={t('excess')}
        description={
          currentTeam?.allowRepairManagement
            ? t('repairCaseForm.excess.descriptionWithContract')
            : t('repairCaseForm.excess.description')
        }
        tooltipMessage={
          currentTeam?.allowRepairManagement
            ? t('repairCaseForm.excess.tooltipWithContract')
            : t('repairCaseForm.excess.tooltip')
        }
      />

      {currentTeam?.allowRepairManagement && (
        <FormControl>
          <FormLabel required>{t('contract')}</FormLabel>
          <Autocomplete
            value={activeContracts?.find((contract) => contract.id === values.contractId) || null}
            placeholder={t('typeHere')}
            startDecorator={<FileIcon />}
            getOptionKey={(option) => option.id || uuidv4()}
            getOptionLabel={(option) => option.description || ''}
            options={activeContracts || []}
            onChange={(_e, option) => {
              if (option) {
                setFieldValue('contractId', option.id);
              }
            }}
          />
        </FormControl>
      )}
      <div className="flex space-x-4">
        <FormControl className="grow">
          <FormLabel required>{t('excessType')}</FormLabel>
          <Select
            placeholder={t('select.placeholder')}
            value={values.excessType}
            onChange={(_e, option) => setFieldValue('excessType', option)}
          >
            {excessTypes.map((excessType) => (
              <Option key={excessType} value={excessType}>
                {t(decapitalize(excessType))}
              </Option>
            ))}
          </Select>
        </FormControl>

        {values.excessType !== 'None' && (
          <>
            <FormControl className="w-1/6">
              <FormLabel>{t('excess')}</FormLabel>
              <Input
                value={values.excessAmount}
                slotProps={{
                  input: { component: NumericFormatAdapter },
                }}
                onChange={(e) => setFieldValue('excessAmount', e.target.value)}
                startDecorator={<EuroIcon />}
              />
            </FormControl>
            <FormControl className="w-1/6">
              <FormLabel>{t('increasedExcess')}</FormLabel>
              <Input
                value={values.excessIncreasedAmount}
                slotProps={{
                  input: { component: NumericFormatAdapter },
                }}
                onChange={(e) => setFieldValue('excessIncreasedAmount', e.target.value)}
                startDecorator={<EuroIcon />}
              />
            </FormControl>
          </>
        )}
      </div>

      <FormControl>
        <FormLabel>{t('excessRemark')}</FormLabel>
        <Input
          value={values.excessRemark}
          placeholder={t('remark')}
          onChange={(e) => setFieldValue('excessRemark', e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t('policies')}</FormLabel>
        <div className="flex items-center space-x-4">
          <Autocomplete
            value={values.policies.map(
              (p) => allPolicies?.find((pol) => !!p.policyId && pol.policyId === p.policyId) ?? p,
            )}
            multiple
            disableCloseOnSelect
            className="grow"
            placeholder={t('typeHere')}
            startDecorator={<FileIcon />}
            getOptionKey={(p) => p?.policyId || uuidv4()}
            getOptionLabel={(p) => p?.policyNumber || ''}
            options={allPolicies || []}
            onChange={(_e, opts) => {
              setFieldValue('policies', opts);
            }}
          />

          <IconButton onClick={() => policyToggle.show()}>
            <FileIcon />
          </IconButton>
        </div>
      </FormControl>

      <KpcModal toggle={policyToggle} title={t('policy')} onClose={resetAddPolicy}>
        <FormControl>
          <FormLabel>{t('policyNumber')}</FormLabel>
          <Input value={policyNumber} onChange={(e) => setPolicyNumber(e.target.value)} />
        </FormControl>
        <div className="flex items-center space-x-4">
          <Checkbox
            checked={closePolicyToggle.value}
            onChange={closePolicyToggle.inverse}
            label={t('saveAndClose')}
            className="grow"
          />
          <div className="flex space-x-4">
            <Button
              onClick={resetAddPolicy}
              startDecorator={<CancelIcon />}
              color="neutral"
              variant="outlined"
            >
              {t('cancel')}
            </Button>
            <Button
              disabled={!policyNumber?.length}
              onClick={() => {
                const newPolicy = { policyId: null, policyNumber };
                setFieldValue('policies', [...values.policies, newPolicy]);
                setAllPolicies([...allPolicies, newPolicy]);
                setPolicyNumber('');

                if (closePolicyToggle.value) {
                  policyToggle.hide();
                }
              }}
              startDecorator={<SaveIcon />}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </KpcModal>
    </>
  );
};

export default PolicyStep;
