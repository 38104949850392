import PropTypes from 'prop-types';

const PhoneCallItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  created: PropTypes.string,
  createdByCurrentTeam: PropTypes.bool,
  createdByUser: PropTypes.string,
  isPinned: PropTypes.bool,
  showOnClientPage: PropTypes.bool,
  note: PropTypes.string,
  isInbound: PropTypes.bool,
  callee: PropTypes.string,
  calleeRelationId: PropTypes.string,
  outcome: PropTypes.string,
  callDate: PropTypes.string,
});

export default PhoneCallItemPropTypes;
