import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddEditAppointmentFormBody from './AddEditAppointmentFormBody';
import { AddEditAppointmentFormikValues } from '../FormikValues';
import { CalendarEvent } from '~/common/types/calendars/events';
import { CaseContext } from '@/containers/Cases/CaseContext';
import FormModal from '@/shared/components/2.0/layout/FormModal';
import { sentencize } from '@/shared/utils/helpers';
import useAddEditAppointmentValidation from './useAddEditAppointmentValidation';
import { UseToggle } from '@/shared/hooks/UseToggle';

type Props = {
  calendarId?: string;
  initialAppointment?: CalendarEvent;
  isMutating: boolean;
  onSubmit: (value: AddEditAppointmentFormikValues) => void;
  showLinkedCase?: boolean;
  toggle: UseToggle<any>;
};

const AddEditAppointmentModal: React.FC<Props> = ({
  calendarId,
  initialAppointment,
  isMutating,
  onSubmit,
  showLinkedCase,
  toggle,
}) => {
  const { t } = useTranslation();
  const { validationSchema } = useAddEditAppointmentValidation();

  const { currentCase } = useContext(CaseContext);

  return (
    <FormModal
      size="lg"
      toggle={toggle}
      // @ts-ignore
      handleSubmit={onSubmit}
      isMutating={isMutating}
      initialValues={{
        allDay: initialAppointment?.allDay,
        caseId: initialAppointment?.caseId || currentCase.id,
        description: initialAppointment?.description,
        end: initialAppointment?.endTime,
        linkedCalendarId: calendarId ?? initialAppointment?.linkedCalendarId,
        start: initialAppointment?.startTime,
        title: initialAppointment?.title,
      }}
      validationSchema={validationSchema}
      title={
        initialAppointment?.id
          ? sentencize(t('editType', { type: t('appointment') }))
          : sentencize(t('addType', { type: t('appointment') }))
      }
    >
      <AddEditAppointmentFormBody
        showLinkedCase={showLinkedCase}
        initialAppointment={initialAppointment}
      />
    </FormModal>
  );
};

export default AddEditAppointmentModal;
