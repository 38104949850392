import config from '../../../config/config';
import createRestClient from '../restClient';
import { PaginatedAutocomplete } from '~/common/types';

const restClient = createRestClient(config.baseURL);

const getRequestorsAutocomplete = (
  params: Record<string, unknown>,
): Promise<PaginatedAutocomplete> => restClient.get('/requestor/autocomplete', { params });

export default {
  getRequestorsAutocomplete,
};
