import { Avatar, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddIcon,
  BuildingIcon,
  CalendarIcon,
  DebtorsIcon,
  QrIcon,
  TaskIcon,
} from '@/shared/icons/Icons';
import TwoSidedLayout from '../TwoSidedLayout';

import logo from '@/shared/img/logo-vera.png';

type Props = {
  teaser: string;
};

const HeroLeft02: React.FC<Props> = ({ teaser }) => {
  const { t } = useTranslation();

  return (
    <TwoSidedLayout teaser={teaser}>
      <Typography
        level="h4"
        startDecorator={<img className="w-14" src={logo} alt="vera-logo" />}
        className="space-y-4 font-medium text-primary"
      >
        {t('app.startPage.hero02.title')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <TaskIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature01')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <CalendarIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature02')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <BuildingIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature03')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <DebtorsIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature04')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <QrIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature05')}
      </Typography>
      <Typography
        component="div"
        className="gap-4"
        startDecorator={
          <Avatar>
            <AddIcon />
          </Avatar>
        }
      >
        {t('app.startPage.hero02.feature06')}
      </Typography>
    </TwoSidedLayout>
  );
};

export default HeroLeft02;
