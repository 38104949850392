import {
  Box,
  Button,
  ColorPaletteProp,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from '@mui/joy';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '../hooks/UseToggle';

import { CircleExclamation, InlineSpinner } from '../icons/Icons';

type ButtonProps = {
  primaryFilled?: boolean;
  confirmAsPrimary?: boolean;
  isProcessing?: boolean;
};

type Props<T> = {
  className?: string;
  color?: ColorPaletteProp;
  icon?: React.ReactNode;
  keepOpenOnConfirm?: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  title?: string;
  toggle: UseToggle<T>;
  buttonProps?: ButtonProps;
};

const ConfirmationModal = <T,>({
  children,
  className = 'w-1/2',
  color = 'primary',
  icon = <CircleExclamation />,
  keepOpenOnConfirm = false,
  onClose,
  onConfirm,
  title,
  toggle,
  buttonProps = { confirmAsPrimary: true, isProcessing: false, primaryFilled: true },
}: React.PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation();
  const { primaryFilled = true, confirmAsPrimary = true, isProcessing = false } = buttonProps;
  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const handleModalConfirm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();

    if (!keepOpenOnConfirm) toggle.hide();

    onConfirm();
  };

  return (
    <Modal open={toggle.value} onClose={handleClose}>
      <ModalDialog className={classNames('max-w-xl', className)}>
        <ModalClose />
        <div className="m-2">
          <div className="mb-4 flex items-center gap-4">
            <Box
              className="flex aspect-square w-10 items-center justify-center rounded-full"
              sx={(theme) => ({
                background: `rgba(${theme.palette[color].mainChannel} / 0.1)`,
                color: theme.palette[color][500],
              })}
            >
              <div className="*:aspect-square *:h-4">{icon}</div>
            </Box>
            <DialogTitle className="text-lg">{title || t('areYouSure')}</DialogTitle>
          </div>
          <DialogContent className="overflow-hidden text-base">
            {children || t('thisActionIsIrreversible')}
          </DialogContent>
        </div>
        <DialogActions className={classNames({ 'flex-row self-end': !confirmAsPrimary })}>
          <Button
            color={confirmAsPrimary ? color : 'neutral'}
            variant={primaryFilled && confirmAsPrimary ? 'solid' : 'outlined'}
            disabled={isProcessing}
            startDecorator={isProcessing ? <InlineSpinner /> : null}
            onClick={handleModalConfirm}
          >
            {t('confirm')}
          </Button>
          <Button
            color={!confirmAsPrimary ? color : 'neutral'}
            variant={!confirmAsPrimary && primaryFilled ? 'solid' : 'outlined'}
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModal;
