import { useQuery } from '@tanstack/react-query';

import { Country } from '.';
import { GeocodeResponse } from '~/backend/services/geocode';

import config from '@/config/config';
import createRestClient from '@/shared/api/restClient';
import { queryIds } from '@/shared/utils/constants';

const client = createRestClient(config.baseURL);

type Params = {
  country?: Country;
  postalCode: string;
  radius: number;
};

const useGetPostalCodesByRadius = (params: Params, options = { enabled: true }) => {
  const { data, isLoading } = useQuery({
    enabled: options.enabled,
    queryFn: (): Promise<GeocodeResponse[]> => client.get('/geocode/radius', { params }),
    queryKey: [queryIds.geocode.GET_POSTAL_CODES_BY_RADIUS, params],
  });

  return {
    postalCodesByRadius: data,
    postalCodesByRadiusAreLoading: isLoading,
  };
};

export default useGetPostalCodesByRadius;
