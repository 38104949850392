import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormLabel,
  Input,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import React, { memo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FormikValues } from './AddRepairCaseStepper';
import { trpc } from '@/config/trpc';

import { InlineSpinner } from '@/shared/icons/Icons';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

type Props = {
  name: string;
  address: {
    addressLine: string;
    postalCode: string;
    city: string;
    country: string;
  };
};

const AddressForm: React.FC<Props> = ({ name, address }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const { t } = useTranslation();

  const { data: countries, isLoading: countriesIsLoading } = trpc.building.address.useQuery();
  const { data: postalCodes, isLoading: postalCodesIsLoading } =
    trpc.building.postalCodes.useQuery();

  return (
    <>
      <div className="flex space-x-4">
        <FormControl className="w-1/2">
          <FormLabel required>{t('addressLine')}</FormLabel>
          <Input
            value={address.addressLine}
            onChange={(e) => setFieldValue(`${name}.addressLine`, e.target.value)}
          />
        </FormControl>
        <FormControl className="w-1/2">
          <FormLabel required>
            {t('postalCode')} / {t('city')}
          </FormLabel>

          {postalCodes && address.country === 'BE' ? (
            <Autocomplete
              disabled={postalCodesIsLoading}
              startDecorator={postalCodesIsLoading && <InlineSpinner />}
              value={postalCodes.find(
                (p) =>
                  p.postalCode.toString() === address.postalCode && p.location === address.city,
              )}
              slots={{ listbox: VirtualListBoxAdapter }}
              options={postalCodes || []}
              getOptionKey={(opt) => opt.id || uuidv4()}
              renderOption={(props, option) => [props, option] as React.ReactNode}
              renderGroup={(params) => params as unknown as React.ReactNode}
              onChange={(_e, option) => {
                if (option) {
                  setFieldValue(`${name}.postalCode`, option.postalCode.toString());
                  setFieldValue(`${name}.city`, option.location);
                }
              }}
            />
          ) : (
            <div className="flex space-x-4">
              <Input
                type="number"
                onChange={(e) => setFieldValue(`${name}.postalCode`, e.target.value)}
              />
              <Input
                className="grow"
                onChange={(e) => setFieldValue(`${name}.city`, e.target.value)}
              />
            </div>
          )}
        </FormControl>
      </div>

      <FormControl>
        <FormLabel required>{t('country.label')}</FormLabel>
        <Autocomplete
          placeholder={t('country.placeholder')}
          startDecorator={countriesIsLoading && <InlineSpinner />}
          value={countries?.find((c) => c.code === address.country) || null}
          options={countries || []}
          getOptionKey={(opt) => opt.code || uuidv4()}
          getOptionLabel={(opt) => opt.name || '-'}
          onChange={(_e, country) => {
            if (country) {
              setFieldValue(`${name}.country`, country.code);
              setFieldValue(`${name}.postalCode`, '');
              setFieldValue(`${name}.city`, '');
            }
          }}
          renderOption={(props, option) => {
            if (option && option.code && option.name) {
              return (
                <AutocompleteOption {...props}>
                  <ListItemDecorator>
                    <img
                      loading="lazy"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt={option.name.toLowerCase()}
                    />
                  </ListItemDecorator>
                  <ListItemContent className="pl-2">{option.name}</ListItemContent>
                </AutocompleteOption>
              );
            }
            return <Alert>{t('noCountriesFound')}</Alert>;
          }}
        />
      </FormControl>
    </>
  );
};

export default memo(AddressForm);
