const {
  VITE_API_URL,
  VITE_TRPC_URL,
  VITE_SYNCFUSION_KEY,
  VITE_KEYPOINT_CLIENT_ID,
  VITE_KEYPOINT_IDP_URL,
  VITE_KEYPOINT_API_URI,
} = import.meta.env;

const config = {
  authority: VITE_KEYPOINT_IDP_URL || 'https://auth.keypoint.be',
  baseURL: VITE_API_URL || 'http://localhost:3000/api',
  cliendId: VITE_KEYPOINT_CLIENT_ID || 'kpcReact',
  keypointApiUrl: VITE_KEYPOINT_API_URI || 'https://dev-api.keypoint.be/api/v1',
  syncFusionKey: VITE_SYNCFUSION_KEY,
  tprcURL: VITE_TRPC_URL || 'http://localhost:3000/trpc',
};

export default config;
