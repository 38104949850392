import { useQuery } from '@tanstack/react-query';

import { buildingApi } from '@/shared/api';

import { formatDataForAutoComplete } from '@/shared/utils/helpers';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetBuildings = (enabled = true, includeInactive = false, vatNumber = '') => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    enabled,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await buildingApi.getBuildings({
        includeInactive,
        vatNumber,
      });

      const autocompleteWithAddress = result.map((building) => {
        const hasAddress =
          !!building.address.addressLine &&
          !!building.address.city &&
          !!building.address.postalCode;
        return {
          label: hasAddress
            ? `${building.name} (${building.address.addressLine}, ${building.address.postalCode} ${building.address.city})`
            : building.name,
          value: building.id,
        };
      });

      const autocomplete = formatDataForAutoComplete({
        data: result,
        label: 'name',
        value: 'id',
      });

      return {
        autocomplete,
        autocompleteWithAddress,
        result,
      };
    },
    queryKey: [queryIds.buildings.GET_BUILDINGS, includeInactive],
  });

  return {
    buildings: data?.result || [],
    buildingsAutocomplete: data?.autocomplete || [],
    buildingsIsFetching: isFetching,
    buildingsIsLoading: isLoading,
    buildingsWithAddressAutocomplete: data?.autocompleteWithAddress || [],
  };
};

export default useGetBuildings;
