import useViewport from '@/shared/hooks/useViewport';

import CaseContentBody from './CaseLayout/CaseContentBody';
import CaseSidebar from './CaseLayout/CaseSidebar';
import TaskAlert from './CaseLayout/Alerts/TaskAlert';
import ServiceNotSyncedAlert from './CaseLayout/Alerts/ServiceNotSyncedAlert';

const CaseDetail = () => {
  const viewport = useViewport();

  return (
    <main className="flex space-x-4 p-4">
      <section
        style={{ height: viewport.contentHeight }}
        className="w-full overflow-y-auto rounded-xl pr-4"
      >
        <TaskAlert />
        <ServiceNotSyncedAlert />
        <CaseContentBody />
      </section>
      <aside style={{ height: viewport.contentHeight }} className="w-96 overflow-y-auto rounded-xl">
        <CaseSidebar />
      </aside>
    </main>
  );
};

export default CaseDetail;
