import { Autocomplete, Chip, ChipDelete, IconButton, Tooltip } from '@mui/joy';
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CreateCompanyRequestFormikValues as CreateCompanyFormikValues } from '~/backend/services/company';
import { CreateContactFormikValues } from '~/common/types/contact/ContactFormikValues';
import { RelationOption } from '@/types/trpc/case';
import { RelationType } from '~/common/enums';

import { createMultiSort, sortWithCustomOrder } from '~/common/utils/sortUtils';
import { useCreateCompany, useCreateContact } from '@/mutations';
import useToggle, { UseToggle } from '@/shared/hooks/UseToggle';
import { trpc } from '@/config/trpc';
import { useAutocompleteOptions } from '@/shared/hooks/autocomplete';

import { ClearIcon, CompanyIcon, ContactIcon, InlineSpinner } from '@/shared/icons/Icons';
import FormField, { FieldProps } from '../input/FormField';
import AddEditCompanyForm from '@/containers/Companies/AddEditCompany/AddEditCompanyForm';
import AddEditContactForm from '@/containers/Contacts/AddEditContact/AddEditContactForm';
import KpcModal from '../layout/KpcModal';
import VirtualListBoxAdapter from '../adapters/VirtualListBoxAdapter';

const OPTIONS_SORT_ORDER = [RelationType.Contact, RelationType.Team, RelationType.Company];

export type CaseRelationOption = RelationOption & {
  isSuggestion: boolean;
};

const sortOptions = createMultiSort(
  {
    key: 'isSuggestion',
    sortFn: (a: boolean, b: boolean) => Number(b) - Number(a),
  },
  {
    key: 'type',
    order: OPTIONS_SORT_ORDER,
    sortFn: sortWithCustomOrder,
  },
  {
    key: 'label',
    sortFn: (a: string, b: string) => a.localeCompare(b),
  },
);

type UseCaseRelationOptionsArgs = {
  callbacks?: {
    onError?: (error: unknown) => void;
    onSuccess?: (option: CaseRelationOption) => void;
  };
  suggestedRelationIds?: string[];
  toggles: {
    company: UseToggle;
    contact: UseToggle;
  };
};

type BaseProps = FieldProps &
  Omit<UseCaseRelationOptionsArgs, 'toggles'> & {
    onBlur?: (e: React.FocusEvent<HTMLDivElement, Element>) => void;
    forceSelected?: string[];
  };

const useCaseRelationOptions = ({
  callbacks,
  suggestedRelationIds,
  toggles,
}: UseCaseRelationOptionsArgs) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'errors']);

  const trpcUtils = trpc.useUtils();

  const { data: options, isLoading } = trpc.case.relationOptions.useQuery();

  const { mutateAsync: createCompanyAsync, isLoading: isCreatingCompany } = useCreateCompany();
  const { mutateAsync: createContactAsync, isLoading: isCreatingContact } = useCreateContact();

  const orderedOptions = useMemo(() => {
    if (!options) return [];

    const groupedOptions = options.map((option) => ({
      ...option,
      isSuggestion: !!suggestedRelationIds?.includes(option.id),
    }));

    const sortedOptions = sortOptions(groupedOptions);

    return sortedOptions;
  }, [options, suggestedRelationIds]);

  const updateQueryCache = useCallback(
    (newOption: CaseRelationOption) => {
      trpcUtils.case.relationOptions.setData(undefined, (queryData) => {
        if (!queryData) return undefined;

        return [...queryData, newOption];
      });
      trpcUtils.case.relationOptions.invalidate();
    },
    [trpcUtils.case.relationOptions],
  );

  const submitCompany = useCallback(
    async (company: CreateCompanyFormikValues) => {
      const type = t('company');

      try {
        const companyRes = await createCompanyAsync(company);

        const { displayName, emailAddresses, id, vatNumber, ...rest } = companyRes;

        const simplifiedEmails = emailAddresses.map((email) => email.email);

        const newCompanyOption = {
          address: {
            country: 'BE' as const,
            postalCode: null,
          },
          displayName: displayName || id,
          emailAddresses: simplifiedEmails,
          id,
          isSuggestion: false,
          label: vatNumber ? `${displayName} (${vatNumber})` : displayName || id,
          type: RelationType.Company as const,
          value: id,
          ...rest,
        };

        updateQueryCache(newCompanyOption);
        enqueueSnackbar(t('typeSuccessfullyAdded', { type }), { variant: 'success' });
        toggles.company.hide();
        callbacks?.onSuccess?.(newCompanyOption);
      } catch (err) {
        enqueueSnackbar(t('errors:failedToCreateType', { type }), { variant: 'error' });
        callbacks?.onError?.(err);
      }
    },
    [callbacks, createCompanyAsync, enqueueSnackbar, t, toggles, updateQueryCache],
  );

  const submitContact = useCallback(
    async (contact: CreateContactFormikValues) => {
      const type = t('contact');

      try {
        const contactRes = await createContactAsync(contact);

        const { firstName, id, lastName, ...rest } = contactRes;

        const label = [firstName, lastName].filter(Boolean).join(' ').trim();

        const newContactOption = {
          id,
          isSuggestion: false,
          label,
          type: RelationType.Contact as const,
          value: id,
          ...rest,
        };

        updateQueryCache(newContactOption);
        enqueueSnackbar(t('typeSuccessfullyAdded', { type }), { variant: 'success' });
        toggles.contact.hide();
        callbacks?.onSuccess?.(newContactOption);
      } catch (err) {
        enqueueSnackbar(t('errors:failedToCreateType', { type }), { variant: 'error' });
        callbacks?.onError?.(err);
      }
    },
    [callbacks, createContactAsync, enqueueSnackbar, t, toggles, updateQueryCache],
  );

  return {
    company: {
      isSubmitting: isCreatingCompany,
      submit: submitCompany,
    },
    contact: {
      isSubmitting: isCreatingContact,
      submit: submitContact,
    },
    isLoading,
    options: orderedOptions,
  };
};

type SingleProps = BaseProps & {
  multiple?: false;
  onChange: (value: CaseRelationOption | null) => void;
  selected: string | null;
};

type MultiProps = BaseProps & {
  multiple: true;
  onChange: (value: CaseRelationOption[]) => void;
  selected: string[];
};

type Props = SingleProps | MultiProps;

const CaseRelationsAutocomplete: React.FC<Props> = ({
  callbacks,
  forceSelected,
  multiple,
  onBlur,
  onChange,
  selected,
  suggestedRelationIds,
  ...formFieldProps
}) => {
  const toggles = {
    company: useToggle(),
    contact: useToggle(),
  };

  const { company, contact, isLoading, options } = useCaseRelationOptions({
    callbacks,
    suggestedRelationIds,
    toggles,
  });
  const { t } = useTranslation();

  const dropOption = (selectedOptions: CaseRelationOption[], id: string) => {
    const newOptions = selectedOptions.filter((option) => option.value !== id);

    if (multiple) {
      onChange(newOptions);
    } else {
      onChange(null);
    }
  };

  const handleCallback = (newOptions: CaseRelationOption[] | CaseRelationOption | null) => {
    const multipleOptions = Array.isArray(newOptions);

    if (multiple && multipleOptions) {
      onChange(newOptions);
    } else if (!multiple && !multipleOptions) {
      onChange(newOptions);
    }
  };

  const autocompleteProps = useAutocompleteOptions({
    callback: handleCallback,
    data: options,
    isLoading,
    multiple,
    selected,
  });

  return (
    <>
      <FormField {...formFieldProps}>
        <div className="flex gap-3">
          <Autocomplete
            {...autocompleteProps}
            className="flex-grow"
            groupBy={(option) => (option.isSuggestion ? t('suggestions') : t('options'))}
            placeholder={t('search.placeholder')}
            slots={{ listbox: VirtualListBoxAdapter }}
            startDecorator={autocompleteProps.loading && <InlineSpinner />}
            getOptionDisabled={(option) => !!forceSelected?.includes(option.value)}
            onBlur={onBlur}
            renderGroup={(params) => params as unknown as React.ReactNode}
            renderOption={(props, option) => [props, option] as React.ReactNode}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  color="neutral"
                  disabled={!!forceSelected?.includes(option.value)}
                  variant="soft"
                  endDecorator={
                    <ChipDelete
                      className="mr-0.5"
                      color="neutral"
                      onClick={() => dropOption(value, option.value)}
                    >
                      <ClearIcon />
                    </ChipDelete>
                  }
                >
                  {option.label}
                </Chip>
              ))
            }
          />
          <Tooltip arrow title={t('_contact.actions.create')}>
            <IconButton onClick={toggles.contact.show}>
              <ContactIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={t('_company.actions.create')}>
            <IconButton onClick={toggles.company.show}>
              <CompanyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </FormField>
      {toggles.company.value && (
        <KpcModal title={t('company')} toggle={toggles.company}>
          <AddEditCompanyForm
            isSubmitting={company.isSubmitting}
            onCancel={toggles.company.hide}
            onSubmit={company.submit}
          />
        </KpcModal>
      )}
      {toggles.contact.value && (
        <KpcModal title={t('contact')} toggle={toggles.contact}>
          <AddEditContactForm
            isSubmitting={contact.isSubmitting}
            onCancel={toggles.contact.hide}
            onSubmit={contact.submit}
          />
        </KpcModal>
      )}
    </>
  );
};

export default CaseRelationsAutocomplete;
