import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { useEffect, useMemo, useReducer } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import classNames from 'classnames';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { library } from '@fortawesome/fontawesome-svg-core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { nlNL } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useColorScheme } from '@mui/material';
import { useLocation } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import uiReducer, { initialUIState } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import { excludedPaths } from '@/shared/utils/constants';
import GlobalStyles from './globalStyles';
import { MailboxStateProvider } from '../Mailbox/store';
import mergedTheme from './theme';
import { reactPlugin } from './AppInsights';
import { trpc } from '@/config/trpc';
import useSocket from '@/shared/hooks/useSocket';
import useViewport from '@/shared/hooks/useViewport';

import Router from './Router/Router';
import ScrollToTop from './Wrappers/ScrollToTop';
import Sidebar from '../Layout/sidebar/Sidebar';
import SplashScreen from '../SplashScreen/SplashScreen';
import Topbar from '../Layout/topbar/Topbar';

import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import './yupExtensions';

const StyledComponentsThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { mode } = useColorScheme();

  return <ThemeProvider theme={{ mode }}>{children}</ThemeProvider>;
};

StyledComponentsThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const ThemeComponent = ({ children }: { children: React.ReactNode }) => (
  <CssVarsProvider theme={mergedTheme}>
    <StyledComponentsThemeProvider>
      <GlobalStyles />
      {children}
    </StyledComponentsThemeProvider>
  </CssVarsProvider>
);
ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

library.add(far);
library.add(fad);
library.add(fas);

const App = () => {
  const [ui, setUi] = useReducer(uiReducer, initialUIState);
  const { pathname } = useLocation();
  const viewport = useViewport();

  const {
    data: currentTeam,
    isFetching: currentTeamIsFetching,
    refetch: refetchCurrentTeam,
  } = trpc.team.currentTeam.useQuery(undefined, { enabled: !!ui.currentTeamId, retry: 0 });

  useEffect(() => {
    refetchCurrentTeam();
  }, [ui.currentTeamId, refetchCurrentTeam]);

  const currentTeamIsValid = useMemo(() => !!currentTeam, [currentTeam]);

  const { data: currentTeamUser } = trpc.team.user.current.useQuery(undefined, {
    enabled: currentTeamIsValid,
  });

  const { socket } = useSocket(ui.currentTeamId);

  const store = useMemo(
    () => ({
      // TODO: Remove server state from the store and use trpc instead inside the components to fetch from cache
      currentTeam,
      currentTeamUser,
      // TODO: Move socket to BFF and use trpc subscriptions to fetch from BFF
      refetchCurrentTeam,
      setUi,
      socket,
      // TODO: 8983: Performance: Refactor remaining redux store to tRPC
      // dispatch(getPolicies());
      // dispatch(getFsmaNumbers());
      // dispatch(getTeamIntegrations());
      ui,
    }),
    [currentTeam, currentTeamUser, refetchCurrentTeam, socket, ui],
  );

  const excludePaths = useMemo(
    () => excludedPaths.some((path) => pathname.includes(path)) || ui.toggles.pageNotFound,
    [pathname, ui.toggles.pageNotFound],
  );

  const mainStyles = useMemo(() => {
    if (!currentTeamIsValid || excludePaths) {
      return '';
    }

    return classNames('mr-4 mt-[60px] flex flex-col rounded-3xl bg-background overflow-auto', {
      'animate-pulse': currentTeamIsFetching,
      'ml-[180px]': ui.toggles.mainMenu,
      'ml-[60px]': !ui.toggles.mainMenu,
    });
  }, [excludePaths, currentTeamIsFetching, ui.toggles.mainMenu, currentTeamIsValid]);

  return (
    <ScrollToTop>
      <ConnectedThemeComponent>
        <DndProvider backend={HTML5Backend}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={nlNL.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <MailboxStateProvider>
              <AppContext.Provider value={store}>
                {currentTeamIsValid && !excludePaths && socket && (
                  <>
                    <Topbar />
                    <Sidebar />
                  </>
                )}
                <main
                  id="main"
                  style={{ height: viewport.mainContainerHeight }}
                  className={mainStyles}
                >
                  {!currentTeamIsValid && !currentTeamIsFetching && <SplashScreen />}
                  {currentTeamIsValid && socket && <Router />}
                </main>
              </AppContext.Provider>
            </MailboxStateProvider>
          </LocalizationProvider>
        </DndProvider>
      </ConnectedThemeComponent>
    </ScrollToTop>
  );
};

export default withAITracking(reactPlugin, App);
