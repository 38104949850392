import { useQuery } from '@tanstack/react-query';

import centralCompanyApi from '../../shared/api/centralCompanies/centralCompany';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetCentralCompany = (centralCompanyId) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!centralCompanyId,
    onError: (error) => sendDefaultError(error),
    queryFn: () => centralCompanyApi.getCentralCompany(centralCompanyId),
    queryKey: [queryIds.centralCompanies.GET_CENTRAL_COMPANY_DETAIL, centralCompanyId],
  });

  return {
    centralCompany: data,
    centralCompanyLoading: isLoading,
  };
};

export default useGetCentralCompany;
