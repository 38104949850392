import {
  Autocomplete,
  Box,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { FieldArray, getIn } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '@/shared/utils/helpers';
import { EmailAddressFormikValue } from '~/common/types/email/emailFormikValues';
import EmailContext from '~/common/enums/emailContext';
import { getEnumWithNumberValues } from '~/common/utils/utils';

import { AddIcon, DeleteIcon } from '@/shared/icons/Icons';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';

type Props = {
  name?: string;
};

const EmailAddressInput: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation();

  const emailContextOptions = useMemo(() => getEnumWithNumberValues(EmailContext), []);

  return (
    <Box
      sx={(theme) => ({
        borderColor: theme.palette.neutral.outlinedBorder,
        borderRadius: '0.5rem',
        borderWidth: 1,
        boxShadow: theme.shadow.xs,
        marginBottom: '1.75rem',
        marginTop: '1rem',
        paddingBottom: '0.75rem',
        paddingTop: '0.5rem',
        paddingX: '0.25rem',
        position: 'relative',
      })}
    >
      <FieldArray
        name={name || 'emailAddresses'}
        render={({ form, push, remove }) => (
          <>
            <div
              key="email-address-input_header"
              className="flex items-center justify-between pl-2 pr-1"
            >
              <Typography className="font-semibold" level="title-sm" textColor="text.secondary">
                {t('emailAddresses')}
              </Typography>
              <IconButton
                onClick={() => push({ email: '', emailContext: [EmailContext.All] })}
                size="sm"
              >
                <AddIcon />
              </IconButton>
            </div>
            <Grid
              key="email-address-input_emails"
              mt={1}
              container
              spacing={1}
              className="max-h-[200px] overflow-y-auto px-2"
            >
              {form.values?.emailAddresses?.map(
                (emailAddress: EmailAddressFormikValue, index: number) => (
                  <>
                    <Grid key={`email-${index}`} xs={4}>
                      <FormikTextField
                        name={`emailAddresses[${index}].email`}
                        label={t('email_one')}
                      />
                    </Grid>
                    <Grid key={`emailContext-${index}`} xs={6}>
                      <FormControl>
                        <FormLabel>{t('context')}</FormLabel>
                        <Autocomplete
                          multiple
                          renderTags={(val, props) =>
                            val.map((value, autoIndex) => (
                              <Tooltip
                                key={value.value}
                                title={t(`_emailContext.${decapitalize(value.name)}`)}
                              >
                                <Chip
                                  {...props}
                                  className="max-w-14 truncate 2xl:max-w-32"
                                  color="neutral"
                                  variant="soft"
                                  endDecorator={
                                    <ChipDelete
                                      onDelete={() => {
                                        emailAddress.emailContext.splice(autoIndex, 1);
                                        form.setFieldValue(
                                          `emailAddresses[${index}].emailContext`,
                                          emailAddress.emailContext,
                                        );
                                      }}
                                    />
                                  }
                                >
                                  {t(`_emailContext.${decapitalize(value.name)}`)}
                                </Chip>
                              </Tooltip>
                            ))
                          }
                          getOptionLabel={(option) =>
                            t(`_emailContext.${decapitalize(option.name)}`)
                          }
                          value={emailContextOptions.filter((option) =>
                            emailAddress.emailContext.includes(option.value),
                          )}
                          options={emailContextOptions ?? []}
                          onChange={(_, value) => {
                            const currentAllIndex = (
                              getIn(form.values, `emailAddresses[${index}].emailContext`) ?? []
                            ).indexOf(EmailContext.All);
                            const allIndex = value.indexOf(
                              value.find(
                                (emailContext) => emailContext.value === EmailContext.All,
                              ) ?? { name: '', value: -1 },
                            );

                            if (value.length > 1) {
                              if (currentAllIndex !== -1) {
                                value.splice(currentAllIndex, 1);
                              }
                              if (currentAllIndex === -1 && allIndex !== -1) {
                                form.setFieldValue(`emailAddresses[${index}].emailContext`, [
                                  EmailContext.All,
                                ]);
                                return;
                              }
                            }

                            form.setFieldValue(
                              `emailAddresses[${index}].emailContext`,
                              value.map((option) => option.value),
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key={`delete-${index}`} xs={2} marginTop={3}>
                      <IconButton onClick={() => remove(index)} className="w-full" color="danger">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                ),
              )}
            </Grid>
          </>
        )}
      />
    </Box>
  );
};

export default EmailAddressInput;
