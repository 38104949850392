import {
  AddEditAccountFormikValues,
  LinkDivisionKeysFormikValues,
} from '@/containers/HomeownerAssociation/GeneralLedgerAccounts/Forms/FormikValues';
import {
  AddHomeownerAssociationContractRequest,
  UpdateHomeownerAssociationContractRequest,
} from '~/common/types/homeownerAssociation/homeownerAssociationContract';
import { AutocompleteOption, TAutocompleteOption } from '~/common/types';
import {
  CreateDivisionKeyImportRequest,
  CreateGeneralLedgerAccountImportRequest,
  CreateHomeownerAssociationDivisionKeyRequest,
  DeleteHomeownerAssociationDivisionKeyRequest,
  DivisionKey,
  DivisionKeyImport,
  GeneralLedgerAccountImport,
  HomeownerAssociation,
  HomeownerAssociationBookyear,
  HomeownerAssociationUserWithResponsibilities,
  SyncHomeownerAssociationBookyearsRequest,
  UpdateHomeownerAssociationBookyearJournalsRequest,
  UpdateHomeownerAssociationDivisionKeyRequest,
} from '~/common/types/homeownerAssociation';
import {
  DeleteAllHomeownerAssociationResponsibilitiesForUsersRequest,
  HomeownerAssociationUserResponsibility,
  UpdateHomeownerAssociationResponsibilitiesForUserRequest,
} from '~/common/types/homeownerAssociation/homeownerAssociationUserResponsibility';
import config from '@/config/config';
import createRestClient from '../restClient';
import { HomeownerAssociationAccount } from '~/common/types/homeownerAssociation/accounts';
import { objectToFormData } from '@/shared/utils/helpers';
import { PushRemoveDefaultAccountFromHoaFormikValues } from '@/containers/Teams/TeamSettings/Finance/Forms/FormikValues';
import { SendMessageRequest } from '~/common/types/email/emailMessage';

const client = createRestClient(config.baseURL);

const restClient = createRestClient();

const getHomeownerAssociation = (id: string): Promise<HomeownerAssociation> =>
  client.get(`/homeownerAssociation/${id}`);

const getHomeownerAssociations = (): Promise<HomeownerAssociation[]> =>
  client.get('/homeownerAssociation');

const getHomeownerAssociationsAutocomplete = (): Promise<AutocompleteOption[]> =>
  client.get('/homeownerAssociation/autocomplete');

const getHomeownerAssociationDivisionKeys = (
  homeownerAssociationId: string,
): Promise<DivisionKey[]> =>
  client.get(`/homeownerAssociation/${homeownerAssociationId}/division-keys`);

const getHomeownerAssociationDivisionKeysAutocomplete = (
  homeownerAssociationId: string,
  language: string,
): Promise<AutocompleteOption[]> =>
  client.get(
    `/homeownerAssociation/${homeownerAssociationId}/division-keys/autocomplete?language=${language}`,
  );

const createHomeownerAssociationDivisionKey = (
  data: CreateHomeownerAssociationDivisionKeyRequest,
) => client.post('/homeownerAssociation/division-key', data);

const updateHomeownerAssociationDivisionKey = (
  data: UpdateHomeownerAssociationDivisionKeyRequest,
) => client.put('/homeownerAssociation/division-key', data);

const deleteHomeownerAssociationDivisionKey = (
  data: DeleteHomeownerAssociationDivisionKeyRequest,
) => client.delete('/homeownerAssociation/division-key', { data });

const getHomeownerAssociationUsersWithResponsibilities = (
  homeownerAssociationId: string,
): Promise<HomeownerAssociationUserWithResponsibilities[]> =>
  client.get(`/homeownerAssociation/${homeownerAssociationId}/users-with-responsibilities`);

const useGetHomeownerAssociationUsersResponsibilities = (
  homeownerAssociationId: string,
): Promise<HomeownerAssociationUserResponsibility[]> =>
  client.get(`/homeownerAssociation/${homeownerAssociationId}/user-responsibilities`);

const updateHomeownerAssociationResponsibilitiesForUser = (
  data: UpdateHomeownerAssociationResponsibilitiesForUserRequest,
) => client.put(`/homeownerAssociation/user-responsibilities`, data);

const deleteAllHomeownerAssociationResponsibilitiesForUsers = (
  data: DeleteAllHomeownerAssociationResponsibilitiesForUsersRequest,
) => client.delete(`/homeownerAssociation/user-responsibilities`, { data });

const getHomeownerAssociationBookyears = (
  homeownerAssociationId: string,
): Promise<HomeownerAssociationBookyear[]> =>
  client.get(`/homeownerAssociation/${homeownerAssociationId}/bookyears`);

const getHomeownerAssociationBookyearsAutocomplete = (
  homeownerAssociationId: string,
  allowOnlyActiveBookyears: boolean,
): Promise<AutocompleteOption[]> =>
  client.get(
    `/homeownerAssociation/${homeownerAssociationId}/bookyears/autocomplete?allowOnlyActiveBookyears=${allowOnlyActiveBookyears}`,
  );

const getHomeownerAssociationBookyearPeriodsAutocomplete = (
  homeownerAssociationId: string,
  bookyearId: string,
): Promise<TAutocompleteOption<number>[]> =>
  client.get(
    `/homeownerAssociation/${homeownerAssociationId}/bookyears/${bookyearId}/periods/autocomplete`,
  );

const syncHomeownerAssociationBookyears = (data: SyncHomeownerAssociationBookyearsRequest) =>
  client.post(`/homeownerAssociation/sync-accounting-bookyears `, data);

const updateHomeownerAssociationBookyear = (
  data: UpdateHomeownerAssociationBookyearJournalsRequest,
) => client.put('/homeownerAssociation/bookyear', data);

const getHomeownerAssociationAccounts = (
  homeownerAssociationId: string,
): Promise<HomeownerAssociationAccount[]> =>
  client.get(`/homeownerAssociation/${homeownerAssociationId}/account.list`);

const importDefaultAccounts = (id: string) =>
  client.put(`/homeownerAssociation/${id}/default-account.import`, {});

const addDefaultAccount = (data: PushRemoveDefaultAccountFromHoaFormikValues) =>
  client.put('/homeownerAssociation/default-account.add', data);

const removeDefaultAccount = (data: PushRemoveDefaultAccountFromHoaFormikValues) =>
  client.delete('/homeownerAssociation/default-account.remove', { data });

const addAccount = (data: AddEditAccountFormikValues) =>
  client.post('/homeownerAssociation/account', data);

const updateAccount = (data: AddEditAccountFormikValues) =>
  client.put('/homeownerAssociation/account', data);

const removeAccount = (data: { homeownerAssociationId: string; id: string }) =>
  client.delete('/homeownerAssociation/account', { data });

const bulkLinkDivisionKeyToAccounts = (
  data: LinkDivisionKeysFormikValues & { homeownerAssociationId: string },
) => client.put('/homeownerAssociation/account.bulk-link', data);

const downloadDivisionKeyImportFile: (
  homeownerAssociationId: string,
  importId: string,
) => Promise<string> = (homeOwnerAssociationId, importId) =>
  client.get(
    `/homeownerAssociation/divisionKeyImport/${homeOwnerAssociationId}/${importId}/download`,
  );

const getDivisionKeyImports = (homeownerAssociationId: string): Promise<DivisionKeyImport[]> =>
  client.get(`/homeownerAssociation/divisionKeyImport/list/${homeownerAssociationId}`);

const deleteDivisionKeyImport = (homeownerAssociationId: string, id: string) =>
  client.delete(`/homeownerAssociation/divisionKeyImport/${homeownerAssociationId}/${id}`);

const createDivisionKeyImport = ({
  data,
  onProgress,
}: {
  data: CreateDivisionKeyImportRequest;
  onProgress: CallableFunction;
}) =>
  client.postForm('/homeownerAssociation/divisionKeyImport', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

const downloadGeneralLedgerAccountImportFile: (
  homeownerAssociationId: string,
  importId: string,
) => Promise<string> = (homeOwnerAssociationId, importId) =>
  client.get(
    `/homeownerAssociation/generalLedgerAccountImport/${homeOwnerAssociationId}/${importId}/download`,
  );

const getGeneralLedgerAccountImports = (
  homeownerAssociationId: string,
): Promise<GeneralLedgerAccountImport[]> =>
  client.get(`/homeownerAssociation/generalLedgerAccountImport/list/${homeownerAssociationId}`);

const deleteGeneralLedgerAccountImport = (homeownerAssociationId: string, id: string) =>
  client.delete(`/homeownerAssociation/generalLedgerAccountImport/${homeownerAssociationId}/${id}`);

const createGeneralLedgerAccountImport = ({
  data,
  onProgress,
}: {
  data: CreateGeneralLedgerAccountImportRequest;
  onProgress: CallableFunction;
}) =>
  client.postForm('/homeownerAssociation/generalLedgerAccountImport', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

const addContract = ({
  data,
  homeownerAssociationId,
}: {
  data: AddHomeownerAssociationContractRequest;
  homeownerAssociationId: string;
}) =>
  restClient.postForm(
    `homeownerAssociation/${homeownerAssociationId}/contract`,
    objectToFormData(data),
  );

const updateContract = ({
  data,
  homeownerAssociationId,
  contractId,
}: {
  data: UpdateHomeownerAssociationContractRequest;
  homeownerAssociationId: string;
  contractId: string;
}) =>
  restClient.putForm(
    `homeownerAssociation/${homeownerAssociationId}/contract/${contractId}`,
    objectToFormData(data),
  );

const postSendEmailMessage = ({
  data,
  homeownerAssociationId,
}: {
  data: SendMessageRequest;
  homeownerAssociationId: string;
}) =>
  restClient.post(`homeownerAssociation/${homeownerAssociationId}/emails`, objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export default {
  addAccount,
  addContract,
  addDefaultAccount,
  bulkLinkDivisionKeyToAccounts,
  createDivisionKeyImport,
  createGeneralLedgerAccountImport,
  createHomeownerAssociationDivisionKey,
  deleteAllHomeownerAssociationResponsibilitiesForUsers,
  deleteDivisionKeyImport,
  deleteGeneralLedgerAccountImport,
  deleteHomeownerAssociationDivisionKey,
  downloadDivisionKeyImportFile,
  downloadGeneralLedgerAccountImportFile,
  getDivisionKeyImports,
  getGeneralLedgerAccountImports,
  getHomeownerAssociation,
  getHomeownerAssociationAccounts,
  getHomeownerAssociationBookyearPeriodsAutocomplete,
  getHomeownerAssociationBookyears,
  getHomeownerAssociationBookyearsAutocomplete,
  getHomeownerAssociationDivisionKeys,
  getHomeownerAssociationDivisionKeysAutocomplete,
  getHomeownerAssociations,
  getHomeownerAssociationsAutocomplete,
  getHomeownerAssociationUsersWithResponsibilities,
  importDefaultAccounts,
  postSendEmailMessage,
  removeAccount,
  removeDefaultAccount,
  syncHomeownerAssociationBookyears,
  updateAccount,
  updateContract,
  updateHomeownerAssociationBookyear,
  updateHomeownerAssociationDivisionKey,
  updateHomeownerAssociationResponsibilitiesForUser,
  useGetHomeownerAssociationUsersResponsibilities,
};
