import classNames from 'classnames';

import { Language } from '~/common/enums';

type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  (
    | { countryCode: string; language?: undefined }
    | { countryCode?: undefined; language: Language }
  ) & { unify?: 'w' | 'h' }; // flags will have the same height or width

export const LANGUAGE_TO_COUNTRY_CODE_MAP: Record<Language, string> = {
  Dutch: 'nl',
  English: 'gb',
  French: 'fr',
};

const Flag: React.FC<Props> = ({
  countryCode,
  language,
  width,
  unify = 'w',
  className,
  ...rest
}) => (
  <img
    width={width ?? 20}
    className={classNames('h-[unset] w-[unset]', className)}
    loading="lazy"
    src={`https://flagcdn.com/${unify}20/${
      countryCode?.toLowerCase() ?? LANGUAGE_TO_COUNTRY_CODE_MAP[language as Language]
    }.png`}
    srcSet={`https://flagcdn.com/${unify}40/${
      countryCode?.toLowerCase() ?? LANGUAGE_TO_COUNTRY_CODE_MAP[language as Language]
    }.png 2x`}
    alt={countryCode ?? language}
    {...rest}
  />
);
export default Flag;
