import { useQuery } from '@tanstack/react-query';

import { buildingApi } from '@/shared/api';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';

import useNotification from '@/shared/hooks/UseNotification';

const useGetBuildingLinkedCases = (buildingId?: Nullable<string>) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!buildingId,
    onError: sendDefaultError,
    queryFn: () => buildingApi.getLinkedCases(buildingId!),
    queryKey: [queryIds.buildings.GET_BUILDING_CASE_LINKS, buildingId],
  });

  return {
    linkedCases: data || [],
    linkedCasesAreLoading: isLoading,
  };
};

export default useGetBuildingLinkedCases;
