import { tinyMCEToolbarSections } from '@/shared/utils/constants';

import FormikRichTextEditor from '../../../shared/formik/FormikRichTextEditor';

const { ALIGN, FONT, LANGUAGE, LINK, LIST, STYLES, TEXT_DECORATION, UNDO_REDO } =
  tinyMCEToolbarSections;

const TOOLBAR_ITEMS = [UNDO_REDO, STYLES, TEXT_DECORATION, ALIGN, FONT, LIST, LINK, LANGUAGE];

const AddEditCaseNoteFormBody = (props) => (
  <div className="flex h-[300px] flex-grow flex-col">
    <FormikRichTextEditor
      name="message"
      height="100%"
      toolbarItems={TOOLBAR_ITEMS}
      statusbar={false}
      {...props}
    />
  </div>
);

export default AddEditCaseNoteFormBody;
