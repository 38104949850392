import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useUpdateNote = (caseId) => {
  const { t } = useTranslation('common');
  const { sendDefaultError, sendNotification } = useNotification();
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation((data) => caseApi.updateNote(data), {
    onError: (error) => sendDefaultError(error),
    onSuccess: (_, { noteId }) => {
      queryClient.invalidateQueries([queryIds.cases.GET_CASE_ENTITIES, caseId]);
      queryClient.invalidateQueries([queryIds.cases.GET_CASE_ENTITY_ACCESS, caseId, noteId]);
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('note') }),
        variant: 'success',
      });
    },
  });
  return {
    isUpdatingNote: isLoading,
    updateNote: (data, options = {}) => mutate({ ...data, caseId }, options),
  };
};

export default useUpdateNote;
