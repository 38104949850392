import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FormikDamageTypeSelector from '../../../../../shared/formik/FormikDamageTypeSelector';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikTextField from '../../../../../shared/formik/FormikTextField';
import FormikYesNo from '../../../../../shared/formik/FormikYesNo';

const StyledDiv = styled.div`
  margin-top: 20px;
`;

const Title = styled.h3`
  font-weight: bold;
`;

const Subtitle = styled.p`
  margin-top: 0px;
  margin-bottom: 20px;
`;

const DamageDetailsStep = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <div>
        <Title>{t('common:damageDetails')}</Title>
        <Subtitle>{t('common:whatIsTheNatureOfTheDamage')}</Subtitle>
        <FormikDamageTypeSelector name="damageType" />
      </div>
      <StyledDiv>
        <FormikTextField
          label={t('common:describeTheClaim')}
          name="description"
          multiline
          required
        />
      </StyledDiv>
      <StyledDiv>
        <FormikDatePicker
          name="incidentDate"
          label={t('damageDate')}
          options={{ disableFuture: true, format: 'YYYY-MM-DD' }}
          required
        />
      </StyledDiv>
      <StyledDiv>
        <div>
          <FormikYesNo
            name="damageToCommonUnits"
            yesText={t('common:damageToCommonUnits')}
            noText={t('common:noDamageToCommonUnits')}
          />
        </div>
        <div>
          <FormikYesNo
            name="damageToPrivateUnits"
            yesText={t('common:damageToPrivateUnits')}
            noText={t('common:noDamageToPrivateUnits')}
          />
        </div>
        <div>
          <FormikYesNo
            name="thirdPartyInvolved"
            yesText={t('common:thirdPartyInvolved')}
            noText={t('common:noThirdPartyInvolved')}
          />
        </div>
        <div>
          <FormikYesNo
            name="verbalProcess"
            yesText={t('common:verbalProcess')}
            noText={t('common:noVerbalProcess')}
          />
        </div>
      </StyledDiv>
    </>
  );
};

export default DamageDetailsStep;
