import { useQuery } from '@tanstack/react-query';

import caseApi from '@/shared/api/cases/case';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetCasesCount = (status: string) => {
  const { sendDefaultError } = useNotification();
  const { data, isLoading } = useQuery({
    onError: sendDefaultError,
    queryFn: () => caseApi.getCasesCount(status),
    queryKey: [queryIds.cases.GET_CASE_COUNT, status],
  });

  return {
    caseCountIsLoading: isLoading,
    totalCaseGroups: data?.totalCaseGroups || 0,
    totalCases: data?.totalCases || 0,
  };
};

export default useGetCasesCount;
