import { Box, Chip, ClickAwayListener, Popper, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Button } from '@mui/joy';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../api/ApiService';
import { AppContext } from '@/shared/context/context';

import AvatarWithColor from '../2.0/AvatarWithColor';
import KpcTooltip from '../Tooltips/KpcTooltip';

const propTypes = {
  callback: PropTypes.func.isRequired,
  caseId: PropTypes.string,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  selectSingle: PropTypes.bool,
  usersToSelect: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
};

const SelectTeamUser = (props) => {
  const {
    caseId,
    callback,
    label,
    disabled,
    selectSingle,
    required,
    isTouched,
    usersToSelect,
    value,
    ...rest
  } = props;
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation('common');

  const [isSelectOpen, setIsSelectOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const [teamUsers, setTeamUsers] = React.useState([]);
  const [selectedTeamUsers, setSelectedTeamUsers] = React.useState([]);
  const [selectedTeamUsersInited, setSelectedTeamUsersInited] = React.useState(false);

  const collapseSelect = () => {
    setIsSelectOpen(false);
  };

  const fetchCurrentTeamUsers = React.useCallback(async () => {
    const response = await API.fetchGetTeamUsers();

    if (response.serviceError != null || response.status !== 200 || response.data.length < 1) {
      setTeamUsers([]);
      return;
    }

    const current = {
      team: currentTeam.name,
      teamId: currentTeam.id,
      users: response.data.map((r) => ({
        isAdmin: false,
        name: r.name,
        teamUserId: r.id,
      })),
    };
    setTeamUsers([current]);
  }, [currentTeam]);

  const fetchCaseTeamUsers = React.useCallback(async () => {
    const response = await API.fetchGetTeamUsersWithAccessToCase(caseId);

    if (response.serviceError != null || response.status !== 200) {
      setTeamUsers([]);
      return;
    }

    if (response.data.length > 0) {
      const teamsWithAccess = [];
      for (let i = 0; i < response.data.length; i += 1) {
        const item = response.data[i];
        if (!teamsWithAccess.some((tu) => tu.teamId === item.teamId)) {
          teamsWithAccess.push({
            team: item.team,
            teamId: item.teamId,
            users: response.data
              .filter((r) => r.teamId === item.teamId)
              .map((r) => ({
                isAdmin: r.isAdmin,
                name: r.username,
                teamUserId: r.teamUserId,
              })),
          });
        }
      }
      setTeamUsers(teamsWithAccess);
    } else {
      setTeamUsers([]);
    }
  }, [caseId]);

  React.useEffect(() => {
    if (caseId == null) {
      fetchCurrentTeamUsers();
    } else {
      fetchCaseTeamUsers();
    }
  }, [caseId, fetchCaseTeamUsers, fetchCurrentTeamUsers]);

  const handleSelect = React.useCallback(
    (team, teamId, user) => {
      const { name, teamUserId, isAdmin } = user;
      const currentlySelected = selectedTeamUsers;
      const updated = {
        isAdmin,
        name,
        team,
        teamId,
        teamUserId,
      };
      let updatedList = null;
      if (!selectSingle) {
        updatedList = [...currentlySelected, updated];
      } else {
        updatedList = [updated];
      }
      setSelectedTeamUsers(updatedList);
      callback(updatedList.map((u) => u.teamUserId));
      setQuery('');
    },
    [callback, selectSingle, selectedTeamUsers],
  );

  const handleDeselect = React.useCallback(
    (teamUserId) => {
      const currentlySelected = selectedTeamUsers;
      const updated = currentlySelected.filter((s) => s.teamUserId !== teamUserId);
      setSelectedTeamUsers(updated);
      callback(updated.map((u) => u.teamUserId));
    },
    [callback, selectedTeamUsers],
  );

  const getSelectedChips = React.useMemo(() => {
    if (!selectedTeamUsers || selectedTeamUsers.length === 0) {
      return null;
    }

    return selectedTeamUsers.map((u) => {
      const { team, teamUserId, name, isAdmin } = u;
      return (
        <KpcTooltip key={teamUserId} disableFocusListener title={`${name} (${team})`}>
          <Chip
            avatar={<AvatarWithColor name={name} />}
            label={
              isAdmin ? <FontAwesomeIcon icon={['fad', 'user-crown']} className="ms-1" /> : null
            }
            key={teamUserId}
            onDelete={() => handleDeselect(teamUserId)}
            className="me-1"
          />
        </KpcTooltip>
      );
    });
  }, [selectedTeamUsers, handleDeselect]);

  useEffect(() => {
    if (
      selectedTeamUsersInited ||
      usersToSelect == null ||
      usersToSelect.length === 0 ||
      teamUsers == null ||
      teamUsers.length === 0
    ) {
      return;
    }

    let addedTeamUsers = [];
    teamUsers.forEach((team) =>
      team.users
        .filter((tu) => usersToSelect.includes(tu.teamUserId))
        .forEach((tu) => {
          const { name, teamUserId, isAdmin } = tu;
          addedTeamUsers = [
            ...addedTeamUsers,
            {
              isAdmin,
              name,
              team: team.team,
              teamId: team.teamId,
              teamUserId,
            },
          ];
        }),
    );
    setSelectedTeamUsers(addedTeamUsers);
    setSelectedTeamUsersInited(true);
    if (addedTeamUsers.length > 0) {
      callback(addedTeamUsers.map((tu) => tu.teamUserId));
    }
  }, [callback, selectedTeamUsersInited, teamUsers, usersToSelect]);

  useEffect(() => {
    if (!value) {
      return;
    }

    let addedTeamUsers = [];
    teamUsers.forEach((team) =>
      team.users
        .filter((tu) => value.includes(tu.teamUserId))
        .forEach((tu) => {
          const { name, teamUserId, isAdmin } = tu;
          addedTeamUsers = [
            ...addedTeamUsers,
            {
              isAdmin,
              name,
              team: team.team,
              teamId: team.teamId,
              teamUserId,
            },
          ];
        }),
    );

    setSelectedTeamUsers(addedTeamUsers);
  }, [teamUsers, value]);

  const anchorRef = React.useRef();

  const textFieldClasses = React.useMemo(
    () =>
      classNames({
        'chip-textfield': true,
        'chip-textfield-flex-wrap': !selectSingle,
      }),
    [selectSingle],
  );

  return (
    <ClickAwayListener onClickAway={collapseSelect}>
      <div className="custom__selector" {...rest}>
        {isSelectOpen && (
          <button
            className="topbar__back"
            aria-label="topbar__back"
            type="button"
            onClick={collapseSelect}
          />
        )}
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <TextField
            className={textFieldClasses}
            size="small"
            id="input-with-sx"
            label={`${label || t('common:users')}${required ? ' *' : ''}`}
            variant="standard"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
            onFocus={() => setIsSelectOpen(true)}
            autoComplete="off"
            fullWidth
            disabled={disabled}
            error={isTouched && required && !selectedTeamUsers.length}
            helperText={
              isTouched && required && !selectedTeamUsers.length ? t('errors:fieldIsRequired') : ''
            }
            InputProps={{
              startAdornment: getSelectedChips,
            }}
            ref={anchorRef}
          />
        </Box>
        <Popper
          open={isSelectOpen}
          className="custom__selector-wrap"
          anchorEl={anchorRef?.current}
          placement="top-start"
          disablePortal
        >
          <div className="h-72 w-96 overflow-auto rounded-xl border p-4 shadow-xl">
            {teamUsers
              .filter(
                (tu) =>
                  tu.team.toLowerCase().includes(query.toLowerCase()) ||
                  tu.users.some((u) => u.name.toLowerCase().includes(query.toLowerCase())),
              )
              .filter((tu) =>
                tu.users.some((u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId)),
              )
              .map((tu) => {
                const { team, users, teamId } = tu;
                return (
                  <div key={teamId} className="flex flex-col space-y-2">
                    <h5 className="text-primary">{team}</h5>
                    <div className="flex flex-col space-y-2">
                      {users
                        .filter(
                          (u) => !selectedTeamUsers.some((s) => s.teamUserId === u.teamUserId),
                        )
                        .filter(
                          (u) =>
                            team.toLowerCase().includes(query.toLowerCase()) ||
                            u.name.toLowerCase().includes(query.toLowerCase()),
                        )
                        .map((u) => {
                          const { name, teamUserId, isAdmin } = u;
                          return (
                            <Button
                              className="w-full justify-start font-normal"
                              variant="plain"
                              color="neutral"
                              startDecorator={
                                <>
                                  <AvatarWithColor name={name} size="sm" className="me-1" />
                                  {isAdmin && <FontAwesomeIcon icon={['fad', 'user-crown']} />}
                                </>
                              }
                              key={teamUserId}
                              onClick={() => handleSelect(team, teamId, u)}
                            >
                              {name}
                            </Button>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

SelectTeamUser.propTypes = propTypes;

SelectTeamUser.defaultProps = {
  caseId: null,
  disabled: false,
  isTouched: true,
  label: null,
  required: false,
  selectSingle: false,
  usersToSelect: [],
};

export default SelectTeamUser;
