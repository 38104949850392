import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItemButton,
} from '@mui/joy';
import React, { useContext, useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getMomentObject, getToday, removeTimeFromDate, sentencize } from '@/shared/utils/helpers';
import TaskPropTypes from '@/shared/prop-types/TaskPropTypes';
import useGetEnums from '../../../queries/enums/useGetEnums';
import { useGetTaskTypes } from '../../../queries';

import { CancelIcon, SaveIcon } from '../../icons/Icons';
import { AppContext } from '@/shared/context/context';
import Autocomplete from '../2.0/Autocomplete';
import ChangeCaseStatusOption from '../2.0/ChangeCaseStatusOption';
import DatePicker from '../2.0/DatePicker';
import PriorityIcon from '@/shared/icons/PriorityIcon';
import SelectTeamUser from '../2.0/select/SelectTeamUser';
import TextArea from '../2.0/input/TextArea';
import TextField from '../2.0/input/TextField';

const propTypes = {
  callback: PropTypes.func,
  currentCase: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func,
  hideStatus: PropTypes.bool,
  initialTask: TaskPropTypes,
  linkBtn: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelAvailable: PropTypes.bool,
  requiresPositiveRelativeDueDate: PropTypes.bool,
  showSubmit: PropTypes.bool,
  withRelativeDueDate: PropTypes.bool,
};

const defaultProps = {
  callback: () => {},
  handleSubmit: () => {},
  initialTask: {
    daysRelative: 0,
    description: '',
    dueDate: getToday().format('YYYY-MM-DD'),
    priority: 1,
    taskUsers: [],
  },
  linkBtn: false,
  onCancel: () => {},
  onCancelAvailable: false,
  requiresPositiveRelativeDueDate: false,
  showSubmit: false,
  withRelativeDueDate: false,
};

const TaskCreator = ({
  currentCase,
  callback,
  linkBtn,
  initialTask,
  showSubmit,
  handleSubmit,
  hideStatus = false,
  onCancelAvailable,
  onCancel,
  withRelativeDueDate,
  requiresPositiveRelativeDueDate,
}) => {
  const { currentTeamUser, currentTeam } = useContext(AppContext);
  const { t } = useTranslation(['common', 'errors']);

  const [updatedCaseStatus, setUpdatedCaseStatus] = React.useState();

  const teamUsers = useMemo(() => {
    if (initialTask.id) {
      return initialTask.taskUsers
        .filter((tu) => tu.isAssigned)
        .map((tu) => tu.teamUserId);
    }

    if (currentCase?.isRepairCase) {
      const currentRelation = currentCase.relations?.find(r => r.teamId === currentTeam?.id);

      const admins = currentCase.administrators?.filter(a => a.relationId === currentRelation?.id)
      return admins.length > 0 ? admins.map(a => a.teamUserId) : [currentTeamUser?.id]
    }
  
    return [currentTeamUser?.id];
  }, [currentCase, currentTeam?.id, currentTeamUser?.id, initialTask.id, initialTask.taskUsers]);

  const [taskToCreate, updateTaskToCreate] = React.useState({
    ...initialTask,
    teamUsers,
  });
  const [isDueDateAltered, setDueDateAltered] = React.useState(false);
  const {
    enums: { priorities },
  } = useGetEnums();

  const { taskTypeAutoComplete } = useGetTaskTypes();

  const [show, setShow] = React.useState(!linkBtn);

  const [validity, updateValidity] = React.useState({
    error: null,
    touched: false,
  });

useEffect(() => {
    if (initialTask && priorities.length) {
      const priority = priorities.find(
        (p) => p.name === initialTask.priority || p.value === initialTask.priority,
      );
      updateTaskToCreate((prev) => ({ ...prev, priority: priority?.value }));
    }
  }, [priorities, initialTask]);

  const isEmpty = (task) => {
    const { priority, description, teamUsers, teamTaskTypeId, systemTaskType } = task;
    return (
      priority === 1 &&
      !teamTaskTypeId &&
      !systemTaskType &&
      !description &&
      (!teamUsers || teamUsers.length === 0)
    );
  };

useEffect(() => {
    const task = { ...taskToCreate };
    let isValid = false;
    const yesterday = getToday().add(-1, 'd');
    const isNew = !task.id;
    const {
      priority,
      description,
      teamUsers,
      teamTaskTypeId,
      systemTaskType,
      dueDate,
      daysRelative,
    } = task;

    let error = null;
    let touched = true;

    if (isEmpty(task)) {
      touched = false;
    } else if (priority == null) {
      error = sentencize(t('errors:fieldXisRequired', { field: t('common:priority') }));
    } else if (priority < 0 || priority > 2) {
      error = sentencize(t('errors:fieldXisInvalid', { field: t('common:priority') }));
    } else if (!description || description.length === 0) {
      error = sentencize(t('errors:fieldXisRequired', { field: t('common:description') }));
    } else if (!teamUsers || teamUsers.length === 0) {
      error = sentencize(t('errors:fieldXisRequired', { field: t('common:assignTo') }));
    } else if (!teamTaskTypeId && !systemTaskType) {
      error = sentencize(t('errors:fieldXisRequired', { field: t('common:taskType') }));
    } else if (!dueDate && !withRelativeDueDate) {
      error = sentencize(t('errors:fieldXisRequired', { field: t('common:dueDate') }));
    } else if (daysRelative == null && withRelativeDueDate) {
      error = sentencize(
        t('errors:fieldXisRequired', { field: t('common:daysRelativeToCaseCreationDate') }),
      );
    } else if (
      daysRelative != null &&
      withRelativeDueDate &&
      requiresPositiveRelativeDueDate &&
      daysRelative < 0
    ) {
      error = sentencize(
        t('errors:fieldXmustBePositive', { field: t('common:daysRelativeToCaseCreationDate') }),
      );
    } else if (isNew && getMomentObject(dueDate, 'YYYY-MM-DD').isBefore(yesterday)) {
      error = sentencize(t('errors:fieldXisInvalid', { field: t('common:dueDate') }));
    } else {
      error = null;
      touched = true;
      isValid = true;
    }

    updateValidity({ error, touched });
    const callbackValue = { ...task, isValid };
    callback(callbackValue);
  }, [callback, taskToCreate, t, withRelativeDueDate, requiresPositiveRelativeDueDate]);

  const handleTaskTypeSelect = ({ value: selectedTaskType }) => {
    if (!selectedTaskType) {
      updateTaskToCreate({
        ...taskToCreate,
        description: '',
        dueDate: removeTimeFromDate(moment()),
        systemTaskType: null,
        teamTaskTypeId: null,
      });
      return;
    }

    const { value, isSystemDefault, systemTaskType, dueDays } = selectedTaskType;
    let task = { ...taskToCreate, systemTaskType, teamTaskTypeId: isSystemDefault ? null : value };
    if (!isDueDateAltered) {
      task = { ...task, dueDate: removeTimeFromDate(moment().add('days', dueDays)) };
    }

    updateTaskToCreate({ ...task, description: selectedTaskType.label });
  };

  const handleDescriptionChange = ({ target }) => {
    updateTaskToCreate({ ...taskToCreate, description: target.value });
  };

  const handlePriorityChange = (newPriority) => {
    if (newPriority !== null) {
      updateTaskToCreate({ ...taskToCreate, priority: newPriority });
    }
  };

  const handleDateChange = (dueDate) => {
    setDueDateAltered(true);
    updateTaskToCreate({ ...taskToCreate, dueDate });
  };

  const handleDaysRelativeChange = ({ target }) => {
    updateTaskToCreate({ ...taskToCreate, daysRelative: target.value });
  };

  const handleClose = () => {
    setShow(false);
    updateTaskToCreate({ ...initialTask });
  };

  const updateCaseStatus = (data) => {
    const { id, value } = data;
    if (!id && !value) {
      setUpdatedCaseStatus(null);
      return;
    }

    const status = {
      caseStatusId: value == null ? id : null,
      status: value,
    };
    setUpdatedCaseStatus(status);
  };

  return (
    <div>
      {linkBtn && !show && (
        <Button variant="plain" onClick={() => setShow(!show)}>
          {sentencize(t('common:addType', { type: t('common:task') }))}
        </Button>
      )}
      {show && (
        <>
          <FormControl>
            <FormLabel>{t('common:task')}</FormLabel>
            <div className="task-creator_wrapper pl-4">
              {!linkBtn && !showSubmit && (
                <div className="title">
                  {sentencize(t('common:addType', { type: t('common:task') }))}
                </div>
              )}
              <div className="flex w-full gap-x-2">
                <div className="grow">
                  <Grid container>
                    <FormControl size="sm">
                      <FormLabel>{t('common:priority')}</FormLabel>
                      <List
                        size="sm"
                        className="h-8 grow-0 rounded-lg"
                        variant="outlined"
                        orientation="horizontal"
                      >
                        {priorities.map((priority) => {
                          const { name, value } = priority;
                          return (
                            <ListItemButton
                              variant={value === taskToCreate.priority ? 'soft' : undefined}
                              key={value}
                              onClick={() => handlePriorityChange(value)}
                              selected={value === taskToCreate.priority}
                              className={`priority-${priority.name.toLowerCase()}`}
                            >
                              <PriorityIcon priority={name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </FormControl>
                    <div className="flex grow">
                      <div className="ml-1 mr-1 w-1/2">
                        <Autocomplete
                          name="taskType"
                          isRequired
                          noOptionsText={t('common:noOptions')}
                          value={
                            taskTypeAutoComplete.find(
                              (tt) =>
                                tt.value === taskToCreate.teamTaskTypeId ||
                                (tt.systemTaskType &&
                                  tt.systemTaskType === taskToCreate.systemTaskType),
                            ) ?? null
                          }
                          onChange={handleTaskTypeSelect}
                          options={taskTypeAutoComplete}
                          label={t('common:taskType')}
                        />
                      </div>
                      <div className="w-1/2">
                        <TextArea
                          isRequired
                          autoComplete="off"
                          minRows={1}
                          label={t('common:description')}
                          value={taskToCreate.description}
                          onChange={handleDescriptionChange}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid container className="pt-2">
                    <div className="flex grow">
                      <div className="mr-1 w-3/4">
                        <SelectTeamUser
                          label={t('common:assignTo')}
                          callback={(selectedTeamUsers) =>
                            updateTaskToCreate({ ...taskToCreate, teamUsers: selectedTeamUsers })
                          }
                          caseId={currentCase?.id}
                          usersToSelect={taskToCreate.teamUsers}
                        />
                      </div>
                      <div>
                        {withRelativeDueDate ? (
                          <TextField
                            name="daysRelative"
                            label={t('common:daysRelativeToCaseCreationDate')}
                            type="number"
                            value={taskToCreate.daysRelative}
                            onChange={handleDaysRelativeChange}
                            isRequired
                          />
                        ) : (
                          <DatePicker
                            label={t('dueDate')}
                            onChange={handleDateChange}
                            value={taskToCreate.dueDate}
                            required
                            options={{ disablePast: true }}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <FormHelperText size="sm" className="invalid">
                    {validity.error}
                  </FormHelperText>
                </div>
                {linkBtn && (
                  <div className="mt-[22px]">
                    <Button
                      className="w-8"
                      onClick={handleClose}
                      variant="outlined"
                      color="neutral"
                    >
                      <CloseIcon className="h-5 w-5 text-gray-400" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </FormControl>
          {!linkBtn && !hideStatus && (
            <ChangeCaseStatusOption
              className="mt-2"
              callback={updateCaseStatus}
              currentCase={currentCase}
            />
          )}
          <div className="flex justify-end space-x-4 pt-4">
            {onCancelAvailable && (
              <Button
                startDecorator={<CancelIcon />}
                size="sm"
                variant="soft"
                color="neutral"
                onClick={onCancel}
              >
                {t('common:buttons.cancel')}
              </Button>
            )}
            {showSubmit && (
              <Button
                startDecorator={<SaveIcon />}
                size="sm"
                variant="solid"
                disabled={!validity.touched || !!validity.error}
                onClick={() => {
                  if (validity.touched && !validity.error) {
                    handleSubmit(taskToCreate, updatedCaseStatus);
                  }
                }}
              >
                {t('common:buttons.save')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

TaskCreator.propTypes = propTypes;
TaskCreator.defaultProps = defaultProps;

export default TaskCreator;
