import { useCallback, useEffect, useState } from 'react';

export const offsets = {
  calendar: 218,
  default: 278,
  detail: 280,
  invoices: 190,
  main: 86,
};

const useViewport = (offset = offsets.default) => {
  const calculateSizes = useCallback(
    (window: Window) => ({
      calendarHeight: window.innerHeight - offsets.calendar,
      contentHeight: window.innerHeight - offset,
      detailContainerHeight: window.innerHeight - offsets.detail,
      height: window.innerHeight,
      invoiceContainerHeight: window.innerHeight - offsets.invoices,
      mainContainerHeight: window.innerHeight - offsets.main,
      width: window.innerWidth,
    }),
    [offset],
  );

  const defaultSizes = calculateSizes(window);

  const [viewportSize, setViewportSize] = useState(defaultSizes);

  useEffect(() => {
    const handleResize = () => {
      const sizes = calculateSizes(window);

      setViewportSize(sizes);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateSizes]);

  return viewportSize;
};

export default useViewport;
