import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CasePropTypes from '../../../../../../shared/prop-types/CasePropTypes';
import { trpc } from '@/config/trpc';

import { SaveIcon, SkipIcon } from '../../../../../../shared/icons/Icons';
import AppointmentFormBody from './AppointmentFormBody';

const SpecificationAppointmentOrSecurization = ({
  currentCase,
  isCaseOwnerOrContractor,
  skipStep,
  nextStep,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: registerFirstAppointment, isRegisteringFirstAppointment } =
    trpc.case.repair.registerFirstAppointment.useMutation();

  const submitForm = async (formData) => {
    if (isRegisteringFirstAppointment) {
      return;
    }

    try {
      await registerFirstAppointment({ caseId: currentCase.id, ...formData });

      enqueueSnackbar(t('firstAppointmentRegistered'));
      nextStep(formData.appointmentForSpecifications);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <div className="pe-3 ps-3">
      {!isCaseOwnerOrContractor && !currentCase.repairStartDate && (
        <p className="color-keypoint">{t('appointmentNeedsToBeRegistered')}</p>
      )}
      {(isCaseOwnerOrContractor || currentCase.repairStartDate) && (
        <>
          <h4 className="pb-3">
            {currentCase.appointmentDate == null
              ? t('registerFirstAppointment')
              : t('editAppointment')}
          </h4>
          <Formik
            onSubmit={submitForm}
            initialValues={{
              appointmentAmPm: currentCase.appointmentAmPm ?? false,
              appointmentDate: currentCase.appointmentDate ?? null,
              appointmentForSpecifications: currentCase.appointmentForSpecifications ?? true,
              appointmentTime: currentCase.appointmentTime ?? null,
              executeOnTimeAndExpenseBasis: currentCase.executeOnTimeAndExpenseBasis ?? false,
            }}
            validationSchema={yup.object().shape({
              appointmentAmPm: yup.bool().nullable(),
              appointmentDate: yup.date().nullable().required(t('errors:fieldIsRequired')),
              appointmentForSpecifications: yup.bool(),
              appointmentTime: yup.string().nullable(),
              executeOnTimeAndExpenseBasis: yup.bool(),
            })}
          >
            <Form>
              <AppointmentFormBody
                allowExecutionOnTimeAndExpenseBasis={
                  currentCase.allowExecutionOnTimeAndExpenseBasis
                }
                isCaseOwnerOrContractor={isCaseOwnerOrContractor}
              />
              <div className="flex justify-end space-x-4">
                {currentCase.appointmentDate == null && (
                  <Button
                    startDecorator={<SkipIcon />}
                    color="neutral"
                    variant="outlined"
                    onClick={skipStep}
                  >
                    {t('skipStep')}
                  </Button>
                )}
                <Button startDecorator={<SaveIcon />} type="submit">
                  {currentCase.appointmentDate == null ? t('register') : t('edit')}
                </Button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
};

export default SpecificationAppointmentOrSecurization;

SpecificationAppointmentOrSecurization.propTypes = {
  currentCase: CasePropTypes.isRequired,
  isCaseOwnerOrContractor: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  skipStep: PropTypes.func.isRequired,
};
