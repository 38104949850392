import actionTypes from '../actions/actionTypes';

const initialState = {
  fsmaNumbers: [],
  loading: false,
  error: false,
};

const fsmaNumbersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_FSMANUMBER_REQUESTED:
      return { ...state, loading: true };
    case actionTypes.GET_FSMANUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        fsmaNumbers: action.fsmaNumbers,
      };
    case actionTypes.REMOVE_FSMANUMBER:
      return {
        ...state,
        fsmaNumbers: state.fsmaNumbers.filter((fsmaNumber) => fsmaNumber.id !== action.id),
      };
    case actionTypes.GET_FSMANUMBER_FAILED:
      return { ...state, loading: false, error: true };
    case actionTypes.ADD_OR_UPDATE_FSMANUMBER:
      if (state.fsmaNumbers.some((fsmaNumber) => fsmaNumber.id === action.fsmaNumber.id)) {
        // Update
        return {
          ...state,
          fsmaNumbers: state.fsmaNumbers.map((obj) => {
            if (obj.id === action.fsmaNumber.id) {
              return { ...action.fsmaNumber };
            }
            return obj;
          }),
        };
      }
      // Add
      return { ...state, fsmaNumbers: [...state.fsmaNumbers, action.fsmaNumber] };
    default:
      return state;
  }
};

export default fsmaNumbersReducer;
