import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CancelIcon, SaveIcon } from '../icons/Icons';
import IconButton from './buttons/IconButton';

const propTypes = {
  canSave: PropTypes.bool,
  isDirty: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
const CustomTextFieldAdornment = ({ isDirty, onCancel, onSave, canSave = true }) => {
  const { t } = useTranslation('common');

  if (!isDirty) {
    return null;
  }
  return (
    <div className="d-flex">
      {canSave && (
        <IconButton
          onClick={onSave}
          iconComponent={<SaveIcon />}
          color="keypoint"
          tooltip={t('common:save')}
        />
      )}
      <IconButton
        onClick={onCancel}
        iconComponent={<CancelIcon />}
        color="red"
        tooltip={t('common:cancel')}
      />
    </div>
  );
};

CustomTextFieldAdornment.propTypes = propTypes;

export default CustomTextFieldAdornment;
