import actionTypes from '../actions/actionTypes';

const initialState = {
  isBusy: false,
  timesBusy: 0,
};

const layoutReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_IS_BUSY:
      if (state.isBusy) {
        return { ...state, isBusy: true, timesBusy: state.timesBusy + 1 };
      }
      return { ...state, isBusy: true, timesBusy: 1 };
    case actionTypes.SET_IS_NOT_BUSY:
      if (state.timesBusy <= 0) {
        return { ...state, isBusy: false, timesBusy: 0 };
      }
      return { ...state, isBusy: state.timesBusy - 1 > 0, timesBusy: state.timesBusy - 1 };
    default:
      return state;
  }
};

export default layoutReducer;
