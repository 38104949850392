import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '../../shared/api';
import useNotification from '../../shared/hooks/UseNotification';

const useAddNote = (caseId) => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();

  const { isLoading, mutate } = useMutation((data) => caseApi.addNote(data), {
    onError: (error) => sendDefaultError(error),
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyAdded', { type: t('note') }),
        variant: 'success',
      });
    },
  });
  return {
    addNote: (data, options = {}) => mutate({ ...data, caseId }, options),
    isAddingNote: isLoading,
  };
};

export default useAddNote;
