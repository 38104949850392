import {
  CreateMeetingTypeRequest,
  CreateStandardAgendaPointRequest,
  CreateStandardTaskRequest,
  DeleteStandardAgendaPointRequest,
  DeleteStandardTaskRequest,
  UpdateMeetingTypeRequest,
  UpdateStandardAgendaPointRequest,
  UpdateStandardTaskRequest,
} from '~/common/types/meeting/meetingTypeRequests';
import { ExtendedMeetingType, MeetingType } from '~/common/types/meeting/meetingType';
import { AutocompleteOption } from '~/common/types';

import config from '@/config/config';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';

const client = createRestClient(config.baseURL);

const getMeetingType = (meetingTypeId: string): Promise<ExtendedMeetingType> =>
  client.get(`/meeting/meetingType/${meetingTypeId}`);

const getMeetingTypes = (): Promise<MeetingType[]> => client.get('/meeting/meetingType');

const getMeetingTypesAutocomplete = (): Promise<AutocompleteOption[]> =>
  client.get('/meeting/meetingType/autocomplete');

const createMeetingType = (data: CreateMeetingTypeRequest) =>
  client.post('/meeting/meetingType', data);

const updateMeetingType = (data: UpdateMeetingTypeRequest) =>
  client.put('/meeting/meetingType', data);

const deleteMeetingType = (id: string) => client.delete(`/meeting/meetingType/${id}`);

const deleteStandardAgendaPoint = (data: DeleteStandardAgendaPointRequest) =>
  client.delete(`/meeting/meetingType/standard-agenda-point/${data.id}`, { data });

const createStandardTask = (data: CreateStandardTaskRequest) =>
  client.post('/meeting/meetingType/standard-task', data);

const updateStandardTask = (data: UpdateStandardTaskRequest) =>
  client.put('/meeting/meetingType/standard-task', data);

const deleteStandardTask = (data: DeleteStandardTaskRequest) =>
  client.delete(`/meeting/meetingType/standard-task/${data.id}`, { data });

const restClient = createRestClient();

const createStandardAgendaPoint = (data: CreateStandardAgendaPointRequest) =>
  restClient.post('meetingType/standard-agenda-point', objectToFormData(data));

const updateStandardAgendaPoint = (data: UpdateStandardAgendaPointRequest) =>
  restClient.put('meetingType/standard-agenda-point', objectToFormData(data));

export default {
  createMeetingType,
  createStandardAgendaPoint,
  createStandardTask,
  deleteMeetingType,
  deleteStandardAgendaPoint,
  deleteStandardTask,
  getMeetingType,
  getMeetingTypes,
  getMeetingTypesAutocomplete,
  updateMeetingType,
  updateStandardAgendaPoint,
  updateStandardTask,
};
