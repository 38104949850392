import { FormikValues } from 'formik';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import API from '../../../shared/api/ApiService';
import { decapitalize } from '../../../shared/utils/helpers';
import useNotification from '../../../shared/hooks/UseNotification';

import AddPolicyForm from './AddPolicyForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props<T> = {
  toggle: UseToggle<T>;
};

const AddPolicyModal = <T,>({ toggle }: Props<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const navigate = useNavigate();

  const handleSubmit = async (newPolicy: FormikValues) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const response = await API.postAddPolicy(newPolicy);
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 201) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyAdded', { type: decapitalize(t('policy')) }),
      onSuccessRoute: newPolicy.open ? null : `/policy/${response.data.id}`,
      variant: 'success',
    });

    if (newPolicy.open) {
      navigate(`/policy/${response.data.id}`);
    }
    toggle.hide();
  };

  return (
    <KpcModal toggle={toggle} title={t('createInsurancePolicy')}>
      <AddPolicyForm onSubmit={handleSubmit} onCancel={toggle.hide} />
    </KpcModal>
  );
};

export default AddPolicyModal;
