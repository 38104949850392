import { call, put, takeLatest } from 'redux-saga/effects';

import actionTypes from '../../actions/actionTypes';
import API from '../../../shared/api/ApiService';

function* handleGetIntegrations() {
  const response = yield call(API.fetchGetTeamIntegrations);

  if (response.serviceError != null || response.status !== 200) {
    yield put({ type: actionTypes.GET_INTEGRATIONS_FAILED });

    // eslint-disable-next-line no-console
    console.error(response.serviceError?.message ?? response.data.title);
    return;
  }

  yield put({
    integrations: response.data,
    type: actionTypes.GET_INTEGRATIONS_SUCCESS,
  });
}

function* watcherIntegrationsSaga() {
  yield takeLatest(actionTypes.GET_INTEGRATIONS_REQUESTED, handleGetIntegrations);
}

export default watcherIntegrationsSaga;
