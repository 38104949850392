import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AccidentAtWork from '../../icons/DamageTypeIcons/Ico-arbeidsongeval.svg';
import { colorAccent } from '../../utils/palette';
import DamageType from '../../enums/DamageType';
import Electricity from '../../icons/DamageTypeIcons/Ico-elektriciteit.svg';
import FireOrSmoke from '../../icons/DamageTypeIcons/Ico-brand-rook.svg';
import GlasDamage from '../../icons/DamageTypeIcons/Ico-glasschade.svg';
import Others from '../../icons/DamageTypeIcons/Ico-andere.svg';
import StormWeatherNaturalDisasters from '../../icons/DamageTypeIcons/Ico-natuurrampen.svg';
import Theft from '../../icons/DamageTypeIcons/Ico-diefstal.svg';
import Vandalism from '../../icons/DamageTypeIcons/Ico-vandalisme.svg';
import WaterDamage from '../../icons/DamageTypeIcons/Ico-waterschade.svg';

const DamageSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  width: 70%;
  margin: auto;
`;

const DamageSelectorItem = styled.div`
  border: 2px solid rgba(0, 0, 0, 0);
  border-color: ${({ dt }) => dt.selected && colorAccent};
  border-radius: 7%;
  padding: 7px;
  width: 110px;
  max-width: 120px;
  display: inline-block;
`;

const DamageTypeDescription = styled.p`
  text-align: center;
  overflow-wrap: break-word;
`;

const initialDamageTypes = [
  {
    icon: WaterDamage,
    selected: false,
    translationKey: 'waterDamage',
    type: DamageType.Water,
  },
  {
    icon: Vandalism,
    selected: false,
    translationKey: 'vandalism',
    type: DamageType.Vandalism,
  },
  {
    icon: GlasDamage,
    selected: false,
    translationKey: 'glassDamage',
    type: DamageType.Glass,
  },
  {
    icon: StormWeatherNaturalDisasters,
    selected: false,
    translationKey: 'StormWeatherNaturalDisasters',
    type: DamageType.StormWeatherNaturalDisasters,
  },
  {
    icon: Electricity,
    selected: false,
    translationKey: 'electricityDamage',
    type: DamageType.Electricity,
  },
  {
    icon: Theft,
    selected: false,
    translationKey: 'theft',
    type: DamageType.Theft,
  },
  {
    icon: FireOrSmoke,
    selected: false,
    translationKey: 'fireOrSmokeDamage',
    type: DamageType.FireOrSmoke,
  },
  {
    icon: AccidentAtWork,
    selected: false,
    translationKey: 'accidentAtWork',
    type: DamageType.AccidentAtWork,
  },
  {
    icon: Others,
    selected: false,
    translationKey: 'others',
    type: DamageType.Others,
  },
];

const SelectDamageTypes = ({ callback, initialDamageType }) => {
  const [damageTypes, setDamageTypes] = useState(initialDamageTypes);
  const { t } = useTranslation('common');

  const handleSelectOrDeselect = (type) => {
    setDamageTypes(
      damageTypes.map((dt) =>
        dt.type === type ? { ...dt, selected: !dt.selected } : { ...dt, selected: false },
      ),
    );
  };

  useEffect(() => {
    callback(damageTypes.find((dt) => dt.selected)?.type ?? null);
  }, [callback, damageTypes]);

  useEffect(() => {
    if (!initialDamageType) {
      return;
    }
    setDamageTypes(
      damageTypes.map((dt) =>
        dt.type === initialDamageType
          ? { ...dt, selected: !dt.selected }
          : { ...dt, selected: false },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DamageSelectorContainer>
      {damageTypes.map((dt) => (
        <DamageSelectorItem
          role="menu"
          key={dt.type}
          tabIndex={0}
          dt={dt}
          onClick={() => handleSelectOrDeselect(dt.type)}
        >
          <img alt={dt.type} src={dt.icon} width="80" height="50" />
          <DamageTypeDescription>{t(dt.translationKey)}</DamageTypeDescription>
        </DamageSelectorItem>
      ))}
    </DamageSelectorContainer>
  );
};

SelectDamageTypes.propTypes = {
  callback: PropTypes.func.isRequired,
  initialDamageType: PropTypes.string,
};

SelectDamageTypes.defaultProps = {
  initialDamageType: null,
};

export default SelectDamageTypes;
