import * as ibantools from 'ibantools';
import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import { removeWhitespaces } from '../../utils/helpers';

type IbanPayload = {
  iban: string;
  isValid: boolean;
};

type Props = {
  label?: string;
  name?: string;
  callback: (payload: IbanPayload) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  touched: boolean;
  value?: string;
  hasDuplicateError?: boolean;
  error?: string;
  [key: string]: unknown;
};

const IbanInput: React.FC<Props> = ({
  required = false,
  callback,
  disabled = false,
  error = '',
  label,
  touched,
  hasDuplicateError = false,
  value,
  className = '',
  name,
  ...rest
}) => {
  const [isValidIban, setIsValidIban] = useState(true);
  const [ibanError, setIbanError] = useState<Nullable<string>>(null);
  const { t } = useTranslation(['common', 'errors']);

  const getValidationError = (input: string) => {
    let message = null;

    if (!input && required) {
      message = t('errors:fieldIsRequired');
    }

    const { errorCodes } = ibantools.validateIBAN(input);

    if (errorCodes?.length) {
      message = t(`errors:${ibantools.ValidationErrorsIBAN[errorCodes[0]]}`);
    }

    return message;
  };

  const onChange = (input: string) => {
    const iban = removeWhitespaces(input);
    const validationError = getValidationError(iban);
    const isValid = !validationError;

    setIsValidIban(isValid);

    if (!isValid) {
      setIbanError(validationError);
    } else {
      setIbanError(null);
    }

    callback({
      iban,
      isValid,
    });
  };

  const errorMessage = (() => {
    let message = '';

    if (hasDuplicateError) {
      message = t('errors:ibanAlreadyAdded');
    }
    if (ibanError) {
      message = ibanError;
    }
    if (!message && touched) {
      message = error;
    }

    return message;
  })();

  const hasError = (value && !isValidIban) || hasDuplicateError || (touched && !!error);

  return (
    <FormControl className={classNames('flex-1 grow', className)} error={hasError}>
      <FormLabel required={required}>{label}</FormLabel>
      <Input
        id="iban"
        name={name}
        type="text"
        value={value}
        onChange={({ target }) => onChange(target.value)}
        disabled={disabled}
        {...rest}
      />
      {(hasDuplicateError || !!error) && touched && (
        <FormHelperText className="px-1 text-xs">{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default IbanInput;
