enum SystemTaskType {
  AcceptEstimate = 'AcceptEstimate',
  AcceptInvoice = 'AcceptInvoice',
  AcceptPurchaseCreditNote = 'AcceptPurchaseCreditNote',
  BookInvoiceInExactOnline = 'BookInvoiceInExactOnline',
  BookSalesInvoice = 'BookSalesInvoice',
  CaseApproved = 'CaseApproved',
  CaseDeclined = 'CaseDeclined',
  CheckAppointments = 'CheckAppointments',
  CheckCase = 'CheckCase',
  CheckDocuments = 'CheckDocuments',
  CheckEmails = 'CheckEmails',
  CheckPhonecalls = 'CheckPhonecalls',
  CloseCase = 'CloseCase',
  CreateAutomaticMessageTemplate = 'CreateAutomaticMessageTemplate',
  DeliverEstimate = 'DeliverEstimate',
  DeliverInvoice = 'DeliverInvoice',
  DoIntakeCall = 'DoIntakeCall',
  DraftSalesInvoice = 'DraftSalesInvoice',
  EmailFailedToSend = 'EmailFailedToSend',
  ReadNote = 'ReadNote',
  RegisterFirstAppointment = 'RegisterFirstAppointment',
  RequestEstimate = 'RequestEstimate',
  RequestFile = 'RequestFile',
  RequestInvoice = 'RequestInvoice',
  ScheduleWork = 'ScheduleWork',
  SpreadsheetFailed = 'SpreadsheetFailed',
}

export default SystemTaskType;
