import PropTypes from 'prop-types';

const { bool, func } = PropTypes;

const TogglePropTypes = PropTypes.shape({
  hide: func,
  inverse: func,
  load: func,
  setValue: func,
  show: func,
  value: bool,
});

export default TogglePropTypes;
