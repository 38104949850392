import { deepmerge } from '@mui/utils';
import { extendTheme as extendJoyTheme } from '@mui/joy/styles';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';

const extraColors = {
  background: {
    default: '#FFFFFF',
    surface: '#FFFFFF',
  },
  pinkAccent: {
    main: '#f50057',
  },
  teal: {
    main: '#4ce1b6',
  },
};

const joyTheme = extendJoyTheme({
  cssVarPrefix: 'mui',
});

const muiTheme = extendMuiTheme({
  colorSchemes: {
    dark: {
      palette: extraColors,
    },
    light: {
      palette: {
        ...extraColors,
      },
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
  },
});

const mergedTheme = {
  ...joyTheme,
  ...muiTheme,
  colorSchemes: deepmerge(joyTheme.colorSchemes, muiTheme.colorSchemes),
  components: {
    JoyAlert: { defaultProps: { color: 'primary', variant: 'soft' } },
    JoyAutocomplete: { defaultProps: { size: 'sm' } },
    JoyBadge: { defaultProps: { badgeInset: '0 -10px 0 0', color: 'primary', size: 'sm' } },
    JoyButton: { defaultProps: { color: 'primary', size: 'sm' } },
    JoyCheckbox: { defaultProps: { size: 'sm' } },
    JoyChip: { defaultProps: { color: 'primary', size: 'sm', variant: 'outlined' } },
    JoyDivider: { defaultProps: { sx: { opacity: 0.8 } } },
    JoyFormControl: { defaultProps: { size: 'sm' } },
    JoyIconButton: { defaultProps: { color: 'primary', size: 'sm', variant: 'outlined' } },
    JoyInput: { defaultProps: { size: 'sm' } },
    JoyMenu: { defaultProps: { size: 'sm' } },
    JoyMenuButton: { defaultProps: { color: 'primary' } },
    JoySelect: { defaultProps: { size: 'sm' } },
    JoySwitch: { defaultProps: { size: 'sm' } },
    JoyTab: {
      defaultProps: {
        className: 'rounded-lg',
        color: 'primary',
        disableIndicator: true,
        size: 'sm',
      },
    },
    JoyTabList: {
      defaultProps: {
        className: 'space-x-2 flex pb-2',
        size: 'sm',
      },
    },
    JoyTextarea: {
      defaultProps: { minRows: 4, placement: 'top', size: 'sm' },
    },
    JoyTooltip: { defaultProps: { placement: 'top', size: 'sm' } },
    JoyTypography: {
      defaultProps: {
        className: 'text-sm',
      },
    },
  },
  typography: {
    ...joyTheme.typography,
    ...muiTheme.typography,
  },
  zIndex: {
    ...joyTheme.zIndex,
    ...muiTheme.zIndex,
  },
};

mergedTheme.generateCssVars = (colorScheme) => ({
  css: {
    ...joyTheme.generateCssVars(colorScheme).css,
    ...muiTheme.generateCssVars(colorScheme).css,
  },
  vars: deepmerge(
    joyTheme.generateCssVars(colorScheme).vars,
    muiTheme.generateCssVars(colorScheme).vars,
  ),
});
mergedTheme.unstable_sxConfig = {
  ...muiTheme.unstable_sxConfig,
  ...joyTheme.unstable_sxConfig,
};

export default mergedTheme;
