import * as yup from 'yup';
import { Box, Chip, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { Popper as PopperUnstyled, Portal } from '@mui/base';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Divider } from '@mui/joy';
import API from '../../api/ApiService';
import { AppContext } from '@/shared/context/context';
import CaseRelationRole from '../../enums/CaseRelationRole';
import ReferencePropType from '../../prop-types/ReferencePropType';
import { sentencize } from '../../utils/helpers';
import { useGetCase } from '../../../queries';

import {
  AddIcon,
  HomeOwnerAssociationIcon,
  InsuranceEngineerIcon,
  InsuranceIntermediaryIcon,
  InsurerIcon,
  PropertyManagerIcon,
} from '../../icons/Icons';
import AddCaseRelationFormBody from '../../../containers/Cases/CaseLayout/BodySections/Forms/AddCaseRelationFormBody';
import ModalFooter from '../2.0/forms/ModalFooter';
import useNotification from '../../hooks/UseNotification';
import useOutsideAlerter from '../../hooks/UseOutsideAlerter';

const propTypes = {
  callback: PropTypes.func,
  caseId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  errorText: PropTypes.string,
  initialValues: PropTypes.arrayOf(PropTypes.string),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  popperContainer: ReferencePropType,
  selectOnly: PropTypes.bool,
  selectSingle: PropTypes.bool,
  showPhoneNumbers: PropTypes.bool,
};

const SelectCaseRelation = ({
  caseId,
  isRequired = false,
  label = '',
  errorText = '',
  defaultValue = null,
  selectSingle = false,
  disablePortal = true,
  popperContainer = null,
  callback = () => {},
  selectOnly = false,
  initialValues = null,
  disabled = false,
  onBlur = () => {},
  showPhoneNumbers = false,
}) => {
  const {
    currentTeam: { createCaseAskForClient },
  } = useContext(AppContext);
  const { t } = useTranslation('common');
  const { sendNotification, sendDefaultError } = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addingRelation, setAddingRelation] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { currentCase, caseIsLoading } = useGetCase(caseId);

  const [caseRelations, setCaseRelations] = useState([]);

  useEffect(() => {
    if (!caseId || caseIsLoading) {
      return;
    }

    if (currentCase && currentCase.relations) {
      setCaseRelations(currentCase?.relations || []);
      return;
    }
    setCaseRelations([]);
  }, [caseId, caseIsLoading, currentCase]);

  const [selectedRelations, updateSelectedRelations] = useState([]);

  const selectorMenuRef = useRef();
  const { isInside, setOutsideAlerterActive } = useOutsideAlerter(selectorMenuRef);

  const collapseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setOutsideAlerterActive(false);
    setAddingRelation(false);
    setQuery('');

    if (isInside) {
      onBlur();
    }
  }, [isInside, onBlur, setOutsideAlerterActive]);

  const handleSelectOrDeselect = useCallback(
    (relationId) => {
      if (selectSingle) {
        updateSelectedRelations((prev) => {
          if (prev.includes(relationId)) {
            return [];
          }
          collapseSelect();
          return [relationId];
        });
        return;
      }
      updateSelectedRelations((prev) => {
        if (!prev.includes(relationId)) {
          return [...prev, relationId];
        }
        return [...prev.filter((id) => id !== relationId)];
      });
    },
    [collapseSelect, selectSingle],
  );

  useEffect(() => {
    callback([...selectedRelations]);
  }, [selectedRelations, callback]);

  useEffect(() => {
    if (defaultValue) {
      if (selectSingle) {
        updateSelectedRelations([defaultValue]);
      }
    }
  }, [defaultValue, selectSingle]);

  const getLabel = useMemo(() => label || t('common:search.label'), [label, t]);
  const helperText = useMemo(() => {
    if (errorText != null && errorText.length > 0) {
      return errorText;
    }
    if (isRequired && selectedRelations.length === 0) {
      return t('errors:fieldIsRequired');
    }
    return null;
  }, [selectedRelations, errorText, isRequired, t]);

  const anchorRef = useRef();

  const getCaseRelationRoleIcon = useCallback((role) => {
    switch (role) {
      case CaseRelationRole.Insurer:
        return <InsurerIcon />;
      case CaseRelationRole.InsuranceEngineer:
        return <InsuranceEngineerIcon />;
      case CaseRelationRole.InsuranceIntermediary:
        return <InsuranceIntermediaryIcon />;
      case CaseRelationRole.HomeOwnerAssociation:
        return <HomeOwnerAssociationIcon />;
      case CaseRelationRole.PropertyManager:
        return <PropertyManagerIcon />;
      default:
        return null;
    }
  }, []);

  const getPhoneNumbersLabel = (phoneNumberLabel, mobilePhoneNumberLabel) => {
    if (phoneNumberLabel && mobilePhoneNumberLabel) {
      return ` - ${phoneNumberLabel}, ${mobilePhoneNumberLabel}`;
    }
    if (phoneNumberLabel && !mobilePhoneNumberLabel) {
      return ` - ${phoneNumberLabel}`;
    }
    if (!phoneNumberLabel && mobilePhoneNumberLabel) {
      return ` - ${mobilePhoneNumberLabel}`;
    }
    return '';
  };

  const getRelationLabel = useCallback(
    (relation) => {
      const {
        displayName,
        phoneNumber,
        mobilePhoneNumber,
        isClient,
        isRequestor,
        isOwner,
        isContractor,
      } = relation;

      const mobilePhoneNumberLabel = mobilePhoneNumber
        ? `${mobilePhoneNumber.countryCode ?? ''}${mobilePhoneNumber.number ?? ''}`
        : '';
      const phoneNumberLabel = phoneNumber
        ? `${phoneNumber.countryCode ?? ''}${phoneNumber.number ?? ''}`
        : '';

      const badges = [];
      if (isOwner) {
        badges.push(
          <Badge key="owner" bg="keypoint" pill>
            {t('common:owner')}
          </Badge>,
        );
      }
      if (isRequestor) {
        badges.push(
          <Badge key="requestor" bg="keypoint" pill>
            {t('common:requestor')}
          </Badge>,
        );
      }
      if (isClient) {
        badges.push(
          <Badge key="client" bg="keypoint" pill>
            {t('common:client')}
          </Badge>,
        );
      }
      if (isContractor) {
        badges.push(
          <Badge key="contractor" bg="keypoint" pill>
            {t('common:contractor')}
          </Badge>,
        );
      }

      return (
        <p>
          {badges} {displayName}{' '}
          {(mobilePhoneNumberLabel || phoneNumberLabel) && showPhoneNumbers
            ? getPhoneNumbersLabel(phoneNumberLabel, mobilePhoneNumberLabel)
            : ''}
        </p>
      );
    },
    [showPhoneNumbers, t],
  );

  const selectedChips = useMemo(() => {
    if (selectedRelations.length === 0) {
      return null;
    }
    return selectedRelations.map((relationId) => {
      const relation = caseRelations.find((rel) => rel.id === relationId);
      if (relation == null) {
        return null;
      }
      return (
        <Chip
          icon={getCaseRelationRoleIcon(relation.relationType)}
          key={relationId}
          label={getRelationLabel(relation)}
          onDelete={disabled ? null : () => handleSelectOrDeselect(relationId)}
          disabled={disabled}
        />
      );
    });
  }, [
    caseRelations,
    disabled,
    getCaseRelationRoleIcon,
    getRelationLabel,
    handleSelectOrDeselect,
    selectedRelations,
  ]);

  const handleCreateRelation = useCallback(
    async (relationData) => {
      if (isSubmitting) {
        return;
      }

      const { isClient, isRequestor } = relationData;

      setIsSubmitting(true);
      const response = await API.postSimpleCaseRelation({
        ...relationData,
        isClient: createCaseAskForClient ? isClient : isRequestor,
      });
      setIsSubmitting(false);

      if (response.serviceError != null || response.status !== 200) {
        sendDefaultError(response);
        return;
      }

      setAddingRelation(false);
      sendNotification({
        header: t('common:success'),
        message: t('common:typeSuccessfullyAdded', { type: t('common:involvedParty') }),
        variant: 'success',
      });
    },
    [createCaseAskForClient, isSubmitting, sendDefaultError, sendNotification, t],
  );

  const preselected = useRef(false);
  useEffect(() => {
    if (
      preselected.current ||
      initialValues == null ||
      initialValues.length === 0 ||
      caseRelations == null ||
      caseRelations.length === 0
    ) {
      return;
    }
    preselected.current = true;

    caseRelations
      .filter((cr) => initialValues.includes(cr.id))
      .forEach((cr) => handleSelectOrDeselect(cr.id));
  }, [caseRelations, handleSelectOrDeselect, initialValues]);

  const handleBlur = () => {
    if (isInside) {
      return;
    }
    onBlur();
    collapseSelect();
    setOutsideAlerterActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      collapseSelect();
    }
  };

  const textFieldClasses = classNames({
    'chip-textfield': true,
    'chip-textfield-flex-wrap': !selectSingle,
  });

  return (
    <div className="custom__selector">
      {isSelectOpen && (
        <Portal disablePortal={disablePortal} container={popperContainer?.current}>
          <button
            className="topbar__back"
            aria-label="topbar__back"
            type="button"
            onClick={() => collapseSelect()}
          />
        </Portal>
      )}
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
        }}
      >
        <TextField
          className={textFieldClasses}
          size="small"
          id="input-with-sx"
          label={isRequired ? `${getLabel} *` : getLabel}
          variant="standard"
          onChange={({ target }) => setQuery(target.value.toLowerCase())}
          onFocus={() => {
            setIsSelectOpen(!disabled);
            setOutsideAlerterActive(!disabled);
          }}
          autoComplete="off"
          fullWidth
          error={
            (isRequired && selectedRelations.length === 0) ||
            (helperText != null && helperText.length > 0)
          }
          helperText={helperText}
          disabled={disabled}
          InputProps={{
            startAdornment: selectedChips,
          }}
          ref={anchorRef}
          onBlur={() => handleBlur()}
          onKeyDown={handleKeyDown}
          value={query}
        />
      </Box>
      <PopperUnstyled
        disablePortal={disablePortal}
        container={popperContainer?.current}
        open={isSelectOpen}
        anchorEl={anchorRef?.current}
        className="custom__selector-wrap"
        placement="bottom-start"
      >
        <div ref={selectorMenuRef}>
          {!addingRelation ? (
            <div className="flex flex-col rounded-xl bg-white p-2 shadow-xl">
              {caseRelations
                .filter((rel) => !selectedRelations.includes(rel.id))
                .filter(
                  (rel) =>
                    query == null ||
                    query.length === 0 ||
                    rel.displayName?.toLowerCase()?.includes(query),
                )
                .map((rel) => (
                  <div>
                    <Button
                      className="w-full justify-start"
                      startDecorator={getCaseRelationRoleIcon(rel.relationType)}
                      key={rel.id}
                      variant="plain"
                      color="neutral"
                      onClick={() => handleSelectOrDeselect(rel.id)}
                    >
                      {getRelationLabel(rel)}
                    </Button>
                  </div>
                ))}
              {!selectOnly && (
                <>
                  <Divider />
                  <Button
                    className="justify-start"
                    variant="plain"
                    color="neutral"
                    onClick={() => setAddingRelation(true)}
                    startDecorator={<AddIcon />}
                  >
                    {sentencize(t('common:addType', { type: t('common:involvedParty') }))}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <Formik
              onSubmit={handleCreateRelation}
              className="p-3"
              initialValues={{
                caseId,
                isClient: false,
                isRequestor: false,
              }}
              validationSchema={yup.object().shape({
                createCaseAskForClient: yup.bool().nullable(),
                crmSelector: yup
                  .object()
                  .shape({
                    company: yup
                      .object()
                      .shape({
                        display: yup.string().nullable(),
                        id: yup.string().nullable(),
                      })
                      .nullable(),
                    contact: yup
                      .object()
                      .shape({
                        display: yup.string().nullable(),
                        id: yup.string().nullable(),
                      })
                      .nullable(),
                    team: yup
                      .object()
                      .shape({
                        display: yup.string().nullable(),
                        id: yup.string().nullable(),
                      })
                      .nullable(),
                  })
                  .test('clientIsNotNull', t('errors:fieldIsRequired'), (value) => {
                    const { company, contact, team } = value;
                    return company != null || contact != null || team != null;
                  }),
                isClient: yup.bool().nullable(),
                isRequestor: yup.bool().nullable(),
              })}
            >
              <Form className="rounded-xl p-4 shadow-xl">
                <AddCaseRelationFormBody
                  minified
                  caseId={caseId}
                  createCaseAskForClient={createCaseAskForClient}
                  popperContainer={popperContainer}
                  currentCaseRelations={caseRelations}
                />
                <ModalFooter onCancel={() => setAddingRelation(false)} />
              </Form>
            </Formik>
          )}
        </div>
      </PopperUnstyled>
    </div>
  );
};

SelectCaseRelation.propTypes = propTypes;

export default SelectCaseRelation;
