const FilterType = {
  Bool: 'Bool',
  Building: 'building',
  Case: 'Case',
  CaseStatus: 'CaseStatus',
  CaseType: 'CaseType',
  Classification: 'Classification',
  Company: 'company',
  Contact: 'contact',
  CustomEnumSelect: 'customEnumSelect',
  InsurancePolicyType: 'insurancePolicyType',
  String: 'String',
  StringOptions: 'StringOptions',
  Tag: 'Tag',
  TagType: 'TagType',
  Team: 'team',
  TeamUser: 'TeamUser',
};

export default FilterType;
