import * as yup from 'yup';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import API from '@/shared/api/ApiService';

import { AppContext } from '@/shared/context/context';
import EntityType from '@/shared/enums/EntityType';
import { formatUsernameAndTeamName } from '@/shared/utils/helpers';
import { useAddNote } from '@/mutations';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import { AccessibilityIcon } from '@/shared/icons/Icons';
import AddEditCaseNoteModal from '@/containers/Cases/Notes/AddEditCaseNoteModal';
import { CaseContext } from '../../CaseContext';
import CaseEntityAccessibility from '@/containers/Cases/CaseLayout/BodySections/Components/CaseEntityAccessibility';
import Chat from '@/shared/components/chat/Chat';
import ChatActionButton from '@/shared/components/chat/ChatActionButton';

const useGetCustomUpdateModal = ({ currentCase, note, canEdit }) => {
  const detailedNoteToggle = useToggle();
  return {
    modal: (
      <AddEditCaseNoteModal
        canEdit={canEdit}
        currentCase={currentCase}
        note={note}
        toggle={detailedNoteToggle}
      />
    ),
    toggle: detailedNoteToggle,
  };
};

const CaseNotes = () => {
  const { currentTeam } = useContext(AppContext);
  const { currentCase, canEditCase, refetchCaseEntities, caseEntities } = useContext(CaseContext);
  const { settings } = useSelector((state) => state.user);

  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendNotification, sendDefaultError } = useNotification();

  const { addNote, isAddingNote } = useAddNote(currentCase.id);

  const getCustomNoteButtons = useCallback(
    (showButtons, entity) => {
      if (!showButtons) {
        return null;
      }

      return (
        <CaseEntityAccessibility
          followCursor
          relations={currentCase.relations ?? []}
          entity={entity}
          customButton={
            <ChatActionButton>
              <AccessibilityIcon iconStyle={entity.showOnClientPage ? 'fas' : 'fad'} />
            </ChatActionButton>
          }
        />
      );
    },
    [currentCase.relations],
  );

  const messages = useMemo(() => {
    if (!caseEntities && !caseEntities.length) return [];

    const notes = caseEntities.filter((ent) => ent.type === EntityType.Note);
    if (!notes && !notes.length) return [];

    return notes.map((n) => {
      const {
        created,
        createdByCurrentTeam,
        createdByTeam,
        createdByUser,
        createdOnClientPage,
        id,
        isDetailedNote,
        note,
      } = n;
      const editable = createdByCurrentTeam && canEditCase;
      return {
        active: createdByCurrentTeam,
        buttons: getCustomNoteButtons(
          createdByCurrentTeam ||
            currentTeam.id === currentCase.relations.find((r) => r.isOwner).teamId,
          n,
        ),
        customUpdateModal: isDetailedNote ? useGetCustomUpdateModal : null,
        customUpdateModalProps: { canEdit: editable, currentCase, note: n },
        date: created,
        deletable: editable,
        displayName: createdOnClientPage
          ? t('clientPage')
          : formatUsernameAndTeamName(createdByUser, createdByTeam),
        editable,
        id,
        isDetailedNote,
        message: isDetailedNote ? t('detailedNote') : note,
        username: createdByUser ?? createdByTeam,
      };
    });
  }, [canEditCase, caseEntities, currentCase, currentTeam.id, t, getCustomNoteButtons]);

  const onUpdateNoteMessage = async (noteId, updatedMessage) => {
    if (isSubmitting) {
      return;
    }

    const data = {
      caseId: currentCase.id,
      message: updatedMessage,
      noteId,
    };

    setIsSubmitting(true);
    const response = await API.putUpdateCaseNote(data);
    refetchCaseEntities();
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:note') }),
      variant: 'success',
    });
  };

  const onDeleteNote = async (noteId) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const response = await API.deleteRemoveCaseEntity(currentCase.id, noteId);
    refetchCaseEntities();
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 204) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyRemoved', { type: t('common:note') }),
      variant: 'success',
    });
  };

  const handleSubmit = (values, { setFieldValue }) => {
    addNote(values, {
      onSuccess: () => {
        refetchCaseEntities();
      },
    });
    setFieldValue('message', '');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        message: null,
        showOnClientPage: settings.showOnClientPage || false,
      }}
      validationSchema={yup.object().shape({
        message: yup.string().required(t('required')).nullable(),
        showOnClientPage: yup.boolean(),
      })}
    >
      <Chat
        isLoading={isAddingNote}
        canSendMessage={canEditCase}
        messages={messages}
        onUpdate={onUpdateNoteMessage}
        onDelete={onDeleteNote}
      />
    </Formik>
  );
};

export default CaseNotes;
