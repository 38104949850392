import { Button, Checkbox, Input, Tooltip } from '@mui/joy';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EditIcon, InvalidIcon, LoadingIcon, RefreshIcon, ValidIcon } from '@/shared/icons/Icons';
import API from '../../../../shared/api/ApiService';
import { CaseContext } from '../../CaseContext';
import EditAxaServiceReferenceModal from './Components/EditAxaServiceReferenceModal';
import IconButton from '@/shared/components/buttons/IconButton';
import { sentencize } from '../../../../shared/utils/helpers';
import useDayjs from '@/shared/hooks/useDayjs';
import useDebounce from '../../../../shared/hooks/UseDebounce';
import useNotification from '../../../../shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import { AppContext } from '@/shared/context/context';
import { Contract } from '~/common/types/contract/contract';
import { InvoicingFlow } from '~/common/enums';
import { trpc } from '@/config/trpc';

type ContractorRemarks = {
  [key: string]: boolean;
};

const CaseRepairRemarks = ({
  currentContract,
  setHasRemarks,
  canEditCase,
}: {
  currentContract: Contract;
  setHasRemarks: Dispatch<SetStateAction<boolean>>;
  canEditCase: boolean;
}) => {
  const { currentCase, refetchCase } = useContext(CaseContext);
  const caseId = currentCase.id;
  const contractorId = currentCase.contractorId ?? '';
  const contractorName = currentCase.relations.find((r) => r.isContractor)?.displayName ?? '';
  const contractorRemarksList = [
    'caseCancelledBecauseOfContractor',
    'deliversEstimatesAndInvoicesCorrectly',
    'fillsInCase',
    'givesSufficientAndCorrectFeedback',
    'sendsEstimatesToKeypoint',
    'followsUpTasks',
  ];
  const { mutateAsync: UpdateCaseEthiasSync, isLoading: UpdatingService } =
    trpc.case.repair.syncEthiasService.useMutation();
  const caseRelationReference = currentCase.relations.find(
    (r) => r.role === 'Insurer',
  )?.relationReference;
  const [contractorRemarks, setContractorRemarks] = useState<ContractorRemarks>();
  const contractorRemarksChanged = useRef(false);
  const caseRemarksChanged = useRef(false);
  const { formatDateTime } = useDayjs();
  const [caseRemarks, setCaseRemarks] = useState({
    caseId: currentCase.id,
    clientDelayedProcessing: currentCase.clientDelayedProcessing,
    inAccordanceWithObtainedInformation: currentCase.inAccordanceWithObtainedInformation,
  });
  const axaServiceReferenceToggle = useToggle();
  const debouncedContractorRemarks = useDebounce(contractorRemarks, 750);
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();

  const { currentTeam } = useContext(AppContext);

  const ownRelation = useMemo(() => {
    if (!currentTeam || !currentCase.id) return null;

    return currentCase.relations.find((relation) => relation.teamId === currentTeam.id);
  }, [currentCase, currentTeam]);

  useEffect(() => {
    if (caseRemarks == null || contractorRemarks == null) {
      return;
    }
    const hasRemarks =
      caseRemarks.clientDelayedProcessing === true ||
      caseRemarks.inAccordanceWithObtainedInformation === false ||
      contractorRemarks.caseCancelledBecauseOfContractor === true ||
      contractorRemarks.deliversEstimatesAndInvoicesCorrectly === false ||
      contractorRemarks.fillsInCase === false ||
      contractorRemarks.givesSufficientAndCorrectFeedback === false ||
      contractorRemarks.followsUpTasks === false ||
      contractorRemarks.sendsEstimatesToKeypoint === false;
    setHasRemarks(hasRemarks);
  }, [caseRemarks, contractorRemarks, setHasRemarks]);

  useEffect(() => {
    const getContractorRemarks = async () => {
      if (contractorId) {
        const response = await API.fetchGetContractorRemarksForCase(contractorId, caseId);
        if (response.serviceError == null && response.status === 200) {
          setContractorRemarks(response.data);
        }
      }
    };
    getContractorRemarks();
  }, [caseId, contractorId]);
  const onContractorRemarksChange = (prop: string, value: boolean) => {
    contractorRemarksChanged.current = true;
    const currentRemarks = contractorRemarks;
    const updatedRemarks = { ...currentRemarks, [prop]: value };
    setContractorRemarks(updatedRemarks);
  };

  const onCaseRemarksChange = (prop: string, value: boolean) => {
    caseRemarksChanged.current = true;
    const temp = { ...caseRemarks, [prop]: value };
    setCaseRemarks(temp);
  };

  useEffect(() => {
    const updateCaseRemarks = async () => {
      try {
        const response = await API.putUpdateCaseRemarksForCase(caseRemarks);
        if (response.serviceError != null || response.status !== 200) {
          sendDefaultError(response);
          return;
        }
        sendNotification({
          header: t('success'),
          message: sentencize(t('typeSuccessfullyUpdated', { type: t('caseRemarks') })),
          variant: 'success',
        });
      } catch {
        sendDefaultError();
      }
    };
    if (!caseRemarksChanged.current) {
      return;
    }
    updateCaseRemarks();
  }, [caseRemarks, sendDefaultError, sendNotification, t]);

  useEffect(() => {
    const updateContractorRemarks = async () => {
      try {
        const response = await API.putUpdateContractorRemarksForCase(debouncedContractorRemarks);
        if (response.status === 200) {
          sendNotification({
            header: t('success'),
            message: sentencize(t('typeSuccessfullyUpdated', { type: t('contractorRemarks') })),
            variant: 'success',
          });
          return;
        }
        sendDefaultError(response);
      } catch {
        sendDefaultError();
      }
    };
    if (!contractorRemarksChanged.current) {
      return;
    }
    updateContractorRemarks();
  }, [debouncedContractorRemarks, sendDefaultError, sendNotification, t]);

  const updateCaseSyncDate = () => {
    UpdateCaseEthiasSync(
      { caseId: currentCase.id },
      {
        onError: () => {
          sendNotification({
            header: t('failed'),
            message: t(`errors:failedToUpdateType`, { type: t('_case.title') }),
            variant: 'error',
          });
        },
        onSuccess: () => {
          sendNotification({
            header: t('success'),
            message: t('typeSuccessfullyUpdated', { type: t('_case.title') }),
            variant: 'success',
          });
          refetchCase();
        },
      },
    );
  };

  return (
    <section className="h-full min-h-[400px] ">
      {currentContract.ethiasSyncServiceRequestId && (
        <div className="mb-8">
          <h4 className=" pb-4">{t('ethiasServiceReference')}</h4>
          <div className="justify-ar flex flex-row items-center gap-2">
            <Input
              readOnly
              className="w-3/4"
              value={caseRelationReference ?? 'No reference found'}
              endDecorator={
                <Tooltip
                  title={
                    currentCase.serviceRequestIdSyncedOn
                      ? formatDateTime(currentCase.serviceRequestIdSyncedOn.toString())
                      : t('serviceNotSynced', { company: 'Ethias' })
                  }
                >
                  <div>
                    {currentCase.serviceRequestIdSyncedOn ? (
                      <ValidIcon color="green" />
                    ) : (
                      <InvalidIcon color="red" />
                    )}
                  </div>
                </Tooltip>
              }
            />
            <Button
              className="pr-2"
              variant="outlined"
              startDecorator={UpdatingService ? <LoadingIcon /> : <RefreshIcon />}
              onClick={() => updateCaseSyncDate()}
            />
          </div>
        </div>
      )}
      {currentCase.contractInvoicingFlow === InvoicingFlow.AxaCsv && ownRelation?.isOwner && (
        <>
          <h5 className=" pb-2 pl-2">{t('axaServiceReference')}</h5>
          <div className="mb-4 rounded-lg bg-gray-100 p-4">
            <div className="flex flex-row items-center justify-between">
              {currentCase.axaServiceReference}
              <IconButton
                color="black"
                disabled={!canEditCase}
                iconComponent={<EditIcon />}
                onClick={axaServiceReferenceToggle.show}
              />
              <EditAxaServiceReferenceModal
                value={currentCase.axaServiceReference}
                caseId={currentCase.id}
                toggle={axaServiceReferenceToggle}
              />
            </div>
          </div>
        </>
      )}
      {contractorRemarks && (
        <div className="mb-3">
          <h4 className="pb-4">
            {t('contractorRemarks')}: {contractorName}
          </h4>
          <FormGroup className="pb-4">
            {contractorRemarksList.map((remark) => (
              <div
                key={remark}
                className="mb-2 flex justify-between space-x-4 rounded-lg bg-gray-100 p-4"
              >
                <Checkbox
                  className="items-center"
                  label={t(`${remark}`)}
                  onChange={(e) => onContractorRemarksChange(remark, e.target.checked)}
                  checked={contractorRemarks[remark]}
                />
              </div>
            ))}
          </FormGroup>
        </div>
      )}
      <div role="presentation" className="relative flex min-h-[400px] w-full flex-col">
        <h4 className="pb-4">{t('caseRemarks')}</h4>
        <div className="mb-2 flex justify-between space-x-4 rounded-lg bg-gray-100 p-4">
          <Checkbox
            className="items-center"
            label={t('caseRemarksInAccordance')}
            onChange={(e) =>
              onCaseRemarksChange('inAccordanceWithObtainedInformation', e.target.checked)
            }
            checked={!!caseRemarks?.inAccordanceWithObtainedInformation}
          />
        </div>
        <div className="mb-2 flex justify-between space-x-4 rounded-lg bg-gray-100 p-4">
          <Checkbox
            className="items-center"
            label={t('caseRemarksClientDelayedProcessing')}
            onChange={(e) => onCaseRemarksChange('clientDelayedProcessing', e.target.checked)}
            checked={caseRemarks?.clientDelayedProcessing}
          />
        </div>
      </div>
    </section>
  );
};

export default CaseRepairRemarks;
