import { Avatar, AvatarGroup, Tooltip } from '@mui/joy';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AvatarWithColor, { getSizeProps } from '../2.0/AvatarWithColor';

type Props = {
  max?: number;
  names: string[];
  size?: 'sm' | 'md' | 'lg';
};

const KpcAvatarGroup: React.FC<Props> = ({ max = 5, names, size = 'md' }) => {
  const namesToDisplay = useMemo(() => {
    const toDisplay = names.slice(0, max);

    return {
      avatarsList: toDisplay,
      stringifiedList: names.join(', '),
      surplus: names.length - toDisplay.length,
    };
  }, [max, names]);

  if (!names.length) return '-';

  return (
    <Tooltip arrow className="w-min" disableFocusListener title={namesToDisplay.stringifiedList}>
      <div className="flex">
        <AvatarGroup className="flex-row-reverse" size={size}>
          {!!namesToDisplay.surplus && (
            <Avatar sx={getSizeProps(size)}>+{namesToDisplay.surplus}</Avatar>
          )}
          {namesToDisplay.avatarsList.map((name) => (
            <AvatarWithColor key={uuidv4()} size={size} name={name} />
          ))}
        </AvatarGroup>
      </div>
    </Tooltip>
  );
};

export default KpcAvatarGroup;
