import { useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import EmailAddressInput from '../components/inputs/EmailAddressInput';

const FormikEmailInput = ({ name, ...rest }) => {
  const { values, setFieldValue, errors, touched, setTouched } = useFormikContext();

  return (
    <EmailAddressInput
      onChange={(emailAddresses) => {
        setTouched({ ...touched, [name]: true });
        setFieldValue(name, [...emailAddresses]);
      }}
      values={values[name]}
      errorText={touched[name] ? errors[name] : null}
      {...rest}
    />
  );
};

FormikEmailInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikEmailInput;
