import { Tooltip, Typography } from '@mui/joy';
import classNames from 'classnames';
import { InfoIcon } from '@/shared/icons/Icons';

type Props = {
  title: string;
  description: string;
  tooltipMessage?: string;
  className?: string;
};

const HeaderStep: React.FC<Props> = ({ title, description, tooltipMessage, className }) => (
  <div className={classNames('mb-2 space-y-2', className)}>
    <Typography
      className="text-lg font-medium"
      endDecorator={
        tooltipMessage ? (
          <Tooltip title={tooltipMessage}>
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        ) : null
      }
    >
      {title}
    </Typography>
    <p className="text-xs">{description}</p>
  </div>
);
export default HeaderStep;
