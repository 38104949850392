import { useEffect, useMemo, useRef } from 'react';
import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import useGetEnums from '@/queries/enums/useGetEnums';
import VatCode from '@/shared/enums/VatCode';

import FormikEnumSelector from '@/shared/formik/FormikEnumSelector';
import FormikTextField from '@/shared/formik/FormikTextField';
import FormikVatInput from '@/shared/formik/FormikVatInput';
import FormikYesNo from '@/shared/formik/FormikYesNo';

const propTypes = {
  selectedCompanyVat: PropTypes.string,
};

const MandateFormBody = ({ selectedCompanyVat = null }) => {
  const { t } = useTranslation('common');
  const {
    enums: { vatCodes, corporateForms },
  } = useGetEnums();
  const { values, setFieldValue } = useFormikContext();

  const isInitialMount = useRef(true);

  const handleBuildingChange = useMemo(() => {
    if (!isInitialMount.current) {
      return () => {
        const newVatPercentage = values?.buildingOlderThan10Years
          ? VatCode.InlandExclusive6
          : VatCode.InlandExclusive21;
        setFieldValue('vatPercentage', newVatPercentage);
      };
    }
    return null;
  }, [setFieldValue, values?.buildingOlderThan10Years]);

  useEffect(() => {
    if (handleBuildingChange) {
      handleBuildingChange();
    } else {
      isInitialMount.current = false;
    }
  }, [handleBuildingChange]);

  return (
    <>
      <FormGroup className="mb-3">
        <FormikYesNo
          yesText={t('buildingOlderThan10Years')}
          noText={t('buildingYoungerThan10Years')}
          name="buildingOlderThan10Years"
          id="buildingOlderThan10Years"
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormikEnumSelector
          id="vatPercentage"
          name="vatPercentage"
          loading
          enumValues={vatCodes}
          label={t('common:vatPercentage')}
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormikYesNo
          yesText={t('vatDeductible')}
          noText={t('vatNotDeductible')}
          name="vatDeductible"
          id="vatDeductible"
        />
      </FormGroup>

      {values?.vatDeductible && (
        <>
          <FormGroup className="mb-3">
            <FormikTextField
              id="percentageVatRecovery"
              name="percentageVatRecovery"
              type="number"
              label={t('common:percentageVatRecovery')}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormikEnumSelector
              id="corporateForm"
              name="corporateForm"
              loading
              enumValues={corporateForms}
              label={t('common:corporateForm')}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormikVatInput name="vatNumber" required defaultValue={selectedCompanyVat} />
          </FormGroup>
        </>
      )}
    </>
  );
};

export default MandateFormBody;

MandateFormBody.propTypes = propTypes;
