import { Fragment, memo } from 'react';

import { Nullable, PhoneNumber } from '~/common/types';

import { EmailIcon, MobilePhoneIcon, PhoneIcon } from '@/shared/icons/Icons';
import EllipsisContainer from '@/shared/components/overflow/ellipsisContainer';
import { formatPhoneNumber } from '@/shared/utils/helpers';

type Props = {
  rowData: {
    emailAddresses: string[];
    mobilePhoneNumber: Nullable<PhoneNumber>;
    phoneNumber: Nullable<PhoneNumber>;
  };
};

const RelationContactInfoCustomCell: React.FC<Props> = ({ rowData }) => {
  const { emailAddresses, mobilePhoneNumber, phoneNumber } = rowData;

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  const formattedMobilePhoneNumber = formatPhoneNumber(mobilePhoneNumber);

  const renderContactInfo = (title: string, href: string, IconComponent: React.ElementType) => (
    <EllipsisContainer title={title} ellipsisColor="#3ea3fc">
      <p>
        <i>
          <IconComponent color="accent" />{' '}
        </i>
        <a href={href}>{title}</a>
      </p>
    </EllipsisContainer>
  );

  return (
    <>
      {emailAddresses?.length > 0 &&
        emailAddresses.map((ea) => (
          <Fragment key={ea}>{renderContactInfo(ea, `mailto:${ea}`, EmailIcon)}</Fragment>
        ))}
      {formattedPhoneNumber !== '-' && (
        <>{renderContactInfo(formattedPhoneNumber, `tel:${formattedPhoneNumber}`, PhoneIcon)}</>
      )}
      {formattedMobilePhoneNumber !== '-' && (
        <>
          {renderContactInfo(
            formattedMobilePhoneNumber,
            `tel:${formattedMobilePhoneNumber}`,
            MobilePhoneIcon,
          )}
        </>
      )}
    </>
  );
};

export default memo(RelationContactInfoCustomCell);
