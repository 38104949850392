import { Button, Input } from '@mui/joy';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineSpinner, SaveIcon } from '@/shared/icons/Icons';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import useNotification from '@/shared/hooks/UseNotification';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { Nullable } from '~/common/types';
import { ToggleProps } from '@/types';
import useUpdateAxaServiceReference from '@/mutations/case/repair/useUpdateAxaServiceReference';

type Props = {
  caseId: string;
  toggle: ToggleProps;
  value: Nullable<string>;
};

const EditAxaServiceReferenceModal: React.FC<Props> = ({ caseId, toggle, value }) => {
  const [reference, setReference] = useState<Nullable<string>>(value);

  const { refetchCase } = useContext(CaseContext);

  const { t } = useTranslation();
  const { sendNotification } = useNotification();

  const { updateAxaServiceReference, isUpdatingAxaServiceReference } =
    useUpdateAxaServiceReference();

  const handleSave = () => {
    updateAxaServiceReference(
      {
        axaServiceReference: reference,
        caseId,
      },
      {
        onSuccess: () => {
          sendNotification({
            header: t('success'),
            message: t('typeSuccessfullyUpdated', { type: t('case_one') }),
            variant: 'success',
          });
          refetchCase();
          toggle.hide();
        },
      },
    );
  };

  return (
    <KpcModal
      disablePortal
      toggle={toggle}
      title={t('editType', { type: t('axaServiceReference') })}
    >
      <Input
        value={reference ?? ''}
        placeholder={t('axaServiceReference')}
        onChange={({ target: { value } }) => setReference(value)}
      />
      <div className="modal__footer">
        <Button
          disabled={reference === value || isUpdatingAxaServiceReference}
          startDecorator={isUpdatingAxaServiceReference ? <InlineSpinner /> : <SaveIcon />}
          variant="outlined"
          onClick={handleSave}
        >
          {t('save')}
        </Button>
      </div>
    </KpcModal>
  );
};

export default EditAxaServiceReferenceModal;
