import { useEffect, useState } from 'react';
import { EditingMode } from 'ka-table/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { decapitalize, getFileTypeIconForContentType } from '../../../../../shared/utils/helpers';
import AutocompleteEditor from '../../../../../shared/components/table/editors/AutocompleteEditor';
import CaseRelationRole from '../../../../../shared/enums/CaseRelationRole';
import DeleteAction from '../../../../../shared/components/DeleteAction';
import EnumEditor from '../../../../../shared/components/table/editors/EnumEditor';
import FileInput from '../../../../../shared/components/inputs/FileInput';
import KeypointTable from '../../../../../shared/components/table/KeypointTable';
import StringEditor from '../../../../../shared/components/table/editors/StringEditor';
import useGetEnums from '../../../../../queries/enums/useGetEnums';

const DocumentUploaderStep = () => {
  const { VITE_MAX_FILE_SIZE } = import.meta.env;
  const { setFieldValue, values } = useFormikContext();
  const [filesWithMetadata, updateFilesWithMetadata] = useState(values.files ?? []);
  const { t } = useTranslation('common');

  const handleFilesCallback = (uploadedFiles) => {
    const toAdd = [];
    const currentFiles = [...filesWithMetadata];
    uploadedFiles.forEach((uploadedFile) => {
      const { name, size, lastModified } = uploadedFile;
      const uniqueKey = `${lastModified}-${size}`;
      const newFile = currentFiles.find((f) => f.key === uniqueKey) ?? {
        caseRole: CaseRelationRole.PropertyManager,
        type: uploadedFile.type.includes('image') ? t('common:image') : t('common:document'),
        file: uploadedFile,
        key: uniqueKey,
        name,
      };
      toAdd.push(newFile);
    });
    const filesToKeep = currentFiles.filter((f) => !toAdd.some((a) => a.key === f.key));
    updateFilesWithMetadata([...filesToKeep, ...toAdd]);
  };

  useEffect(() => {
    setFieldValue('files', [...filesWithMetadata]);
  }, [filesWithMetadata, setFieldValue]);

  const getContentTypeIcon = (key) => {
    const contentType = filesWithMetadata.find((f) => f.key === key)?.file?.type;
    const icon = getFileTypeIconForContentType(contentType);
    return <FontAwesomeIcon icon={['fad', icon]} />;
  };

  const getCustomCells = ({ column, value, rowData }) => {
    switch (column.key) {
      case 'name': {
        const icon = getContentTypeIcon(rowData.key);
        return (
          <p>
            {icon} {value}
          </p>
        );
      }
      case 'caseRole': {
        return t(`common:${decapitalize(value)}`);
      }
      case 'key': {
        return (
          <DeleteAction
            onDelete={() => updateFilesWithMetadata((prev) => prev.filter((p) => p.key !== value))}
          />
        );
      }
      default:
        return value;
    }
  };

  const predefinedDescriptions = [
    t('common:paymentRequest'),
    t('common:estimate'),
    t('common:contract'),
    t('common:expertReport'),
    t('common:invoice'),
    t('common:image'),
    t('common:leakDetectionReport'),
  ].sort((a, b) => a.localeCompare(b));

  const {
    enums: { caseRoles },
  } = useGetEnums();

  const getEditableCells = (data) => {
    const { column } = data;
    switch (column.key) {
      case 'caseRole':
        return <EnumEditor {...data} enumValues={caseRoles} />;
      case 'type':
        return (
          <AutocompleteEditor
            options={predefinedDescriptions}
            label={t('common:type')}
            getValue={(val) => val}
            isOptionEqualToValue={() => true}
            freeSolo
            fullWidth
            {...data}
          />
        );
      case 'name':
        return <StringEditor {...data} />;
      default:
        return null;
    }
  };

  const defaultOptions = {
    columns: [
      {
        editable: true,
        key: 'name',
        title: t('common:name'),
      },
      {
        editable: true,
        key: 'type',
        title: t('common:type'),
      },
      {
        editable: true,
        key: 'caseRole',
        title: t('common:role'),
      },
      {
        key: 'key',
        width: 100,
      },
    ],
    editingMode: EditingMode.Cell,
    rowKeyField: 'key',
  };

  const formattedData = filesWithMetadata.map((uploadedFile) => {
    const { key, name, caseRole, type } = uploadedFile;
    return {
      caseRole,
      type,
      key,
      name,
    };
  });

  const updateFileMetadata = (original, updated) => {
    updateFilesWithMetadata((prev) =>
      prev.map((file) => {
        if (file.key !== updated.key) {
          return file;
        }
        const { name, type, caseRole } = updated;
        return {
          ...file,
          caseRole,
          type,
          name,
        };
      }),
    );
  };

  return (
    <div>
      <h3 className="fw-bold mb-3">{t('common:addTypes', { type: t('common:documents') })}</h3>
      <FileInput
        showIcon
        maxFileSize={parseInt(VITE_MAX_FILE_SIZE, 10)}
        callback={handleFilesCallback}
      />
      {filesWithMetadata.length > 0 && (
        <KeypointTable
          defaultOptions={defaultOptions}
          formattedData={formattedData}
          hideActionbar
          hideDisplayMenu
          localStorageKey="document-uploader-insurance-case-table"
          onUpdateRow={updateFileMetadata}
          childComponents={{
            cellEditor: {
              content: getEditableCells,
            },
            cellText: {
              content: getCustomCells,
            },
          }}
        />
      )}
    </div>
  );
};

export default DocumentUploaderStep;
