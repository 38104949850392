enum EsBicValue {
  BDEPESM1 = 'BDEPESM1',
  ALLFESMM = 'ALLFESMM',
  DEUTESBB = 'DEUTESBB',
  SABNESMM = 'SABNESMM',
  BSSSESM2 = 'BSSSESM2',
  BSCHESMM = 'BSCHESMM',
  BMARES2M = 'BMARES2M',
  OPENESMM = 'OPENESMM',
  BAPUES22 = 'BAPUES22',
  BSABESBB = 'BSABESBB',
  RENBESMM = 'RENBESMM',
  ALACESM1 = 'ALACESM1',
  SOGEESMM = 'SOGEESMM',
  OCBAESM1 = 'OCBAESM1',
  BKBKESMM = 'BKBKESMM',
  AREBESMM = 'AREBESMM',
  DEUTESM1 = 'DEUTESM1',
  BNPAESMH = 'BNPAESMH',
  CHASESM3 = 'CHASESM3',
  BPLCESMM = 'BPLCESMM',
  BSUIESMM = 'BSUIESMM',
  COBAESMX = 'COBAESMX',
  BOTKESMX = 'BOTKESMX',
  MIDLESMX = 'MIDLESMX',
  GEBAESMM = 'GEBAESMM',
  NACNESMM = 'NACNESMM',
  BBVAESMM = 'BBVAESMM',
  BEDFESM1 = 'BEDFESM1',
  BFIVESBB = 'BFIVESBB',
  ALCLESMM = 'ALCLESMM',
  BCOEESMM = 'BCOEESMM',
  DEGRESBB = 'DEGRESBB',
  PROAESMM = 'PROAESMM',
  FCEFESM1 = 'FCEFESM1',
  BMCEESMM = 'BMCEESMM',
  FIOFESM1 = 'FIOFESM1',
  SCFBESMM = 'SCFBESMM',
  FIEIESM1 = 'FIEIESM1',
  UBSWESMM = 'UBSWESMM',
  POPLESMM = 'POPLESMM',
  INVLESMM = 'INVLESMM',
  CCOCESMM = 'CCOCESMM',
  PICHESMM = 'PICHESMM',
  CSURES2C = 'CSURES2C',
  EVOBESMM = 'EVOBESMM',
  BCCAESMM = 'BCCAESMM',
  AYGBESMM = 'AYGBESMM',
  ICROESMM = 'ICROESMM',
  CRCGESB1 = 'CRCGESB1',
  LLISESM1 = 'LLISESM1',
  PRABESMM = 'PRABESMM',
  CRESESMM = 'CRESESMM',
  INGDESMM = 'INGDESMM',
  UCSSESM1 = 'UCSSESM1',
  PRIBESMX = 'PRIBESMX',
  CITIESMX = 'CITIESMX',
  NATXESMM = 'NATXESMM',
  VOWAES21 = 'VOWAES21',
  BOFAES2X = 'BOFAES2X',
  SELFESMM = 'SELFESMM',
  TRIOES21 = 'TRIOES21',
  BCITESMM = 'BCITESMM',
  ESSIESMM = 'ESSIESMM',
  ICBKESMM = 'ICBKESMM',
  BACAESMM = 'BACAESMM',
  ETICES21 = 'ETICES21',
  FASNESM1 = 'FASNESM1',
  REVOESM2 = 'REVOESM2',
  CECAESMM = 'CECAESMM',
  CAGLESMM = 'CAGLESMM',
  CAZRES2Z = 'CAZRES2Z',
  BASKES2B = 'BASKES2B',
  CAIXESBB = 'CAIXESBB',
  UCJAES2M = 'UCJAES2M',
  CDENESBB = 'CDENESBB',
  CCRIES2A = 'CCRIES2A',
  CLPEES2M = 'CLPEES2M',
  ERSVES22 = 'ERSVES22',
  CASDESBB = 'CASDESBB',
}

export default EsBicValue;
