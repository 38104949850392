import { Checkbox } from '@mui/joy';
import { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  toggles: PropTypes.shape({
    edit: PropTypes.shape({
      value: PropTypes.bool,
    }),
  }).isRequired,
};

const TableRowSelect = ({ rowData, setSelectedItems, toggles }) => {
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, rowData]);
    } else {
      setSelectedItems((prev) => prev.filter((addressWithId) => addressWithId.id !== rowData.id));
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {!!toggles.edit && toggles.edit.value && <Checkbox onChange={(e) => handleMultiSelect(e)} />}
    </div>
  );
};

TableRowSelect.propTypes = propTypes;
export default memo(TableRowSelect);
