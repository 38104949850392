import config from '~/frontend/config/config';
import { Contractor } from '~/common/types/contractor/Contractor';
import createRestClient from '../restClient';

const bffClient = createRestClient(config.baseURL);
const restClient = createRestClient();

const getContractorsAvailableForRepair = (): Promise<Contractor[]> =>
  bffClient.get('/contractor/repair');

// @ts-ignore
const deleteContractorSkill = (data) => restClient.delete('contractor/skill', { data });

// @ts-ignore
const createContractorSkill = (skill) => restClient.post('contractor/skill', skill);

// @ts-ignore
const updateContractorSkill = (skill) => restClient.put('contractor/skill', skill);

// @ts-ignore
const updateCapacity = (data) => restClient.put('contractor/capacity', data);

// @ts-ignore
const createContractorFee = (fee) => restClient.post('contractor/fee', fee);

// @ts-ignore
const updateContractorFee = (fee) => restClient.put('contractor/fee', fee);

// @ts-ignore
const deleteContractorFee = (data) => restClient.delete('contractor/fee', { data });

// @ts-ignore
const createContractorAbsence = (absence) => restClient.post('contractor/absence', absence);

// @ts-ignore
const updateContractorAbsence = (absence) => restClient.put('contractor/absence', absence);

// @ts-ignore
const deleteContractorAbsence = (data) => restClient.delete('contractor/absence', { data });

// @ts-ignore
const createContractorRegion = (region) => restClient.post('contractor/region', region);

// @ts-ignore
const updateContractorRegion = (region) => restClient.put('contractor/region', region);

// @ts-ignore
const deleteContractorRegion = (data) => restClient.delete('contractor/region', { data });

export default {
  createContractorAbsence,
  createContractorFee,
  createContractorRegion,
  createContractorSkill,
  deleteContractorAbsence,
  deleteContractorFee,
  deleteContractorRegion,
  deleteContractorSkill,
  getContractorsAvailableForRepair,
  updateCapacity,
  updateContractorAbsence,
  updateContractorFee,
  updateContractorRegion,
  updateContractorSkill,
};
