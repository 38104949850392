import { decapitalize, formatUtcDate } from '../helpers';
import SpreadsheetStatus from '../../enums/SpreadsheetStatus';
import SpreadsheetType from '../../enums/SpreadsheetType';

const SubmitOrEvaluateSpreadsheetTitle = (spreadsheets, t, type) => {
  const filteredSpreadsheets =
    type === SpreadsheetType.Estimate
      ? spreadsheets.filter((s) => s.isEstimate)
      : spreadsheets.filter((s) => s.isPurchaseInvoice);
  const typeTranslated = t(`common:${decapitalize(type)}`);

  if (!filteredSpreadsheets.length) {
    return t('common:submitOrEvaluateSpreadsheet', { type: typeTranslated });
  }

  const orderedSpreadsheets = filteredSpreadsheets.sort(
    (a, b) => new Date(b.validationDate) - new Date(a.validationDate),
  );

  if (!orderedSpreadsheets.some((e) => e.validationDate != null)) {
    const lastSubmittedSpreadsheet = orderedSpreadsheets[0];
    const date = formatUtcDate(lastSubmittedSpreadsheet.deliveryDate);
    return `${t('common:spreadsheetSubmitted', {
      date,
      type: typeTranslated,
    })}`;
  }

  const acceptedSpreadsheets = orderedSpreadsheets.filter(
    (e) => e.status === SpreadsheetStatus.Accepted,
  );
  if (acceptedSpreadsheets.length) {
    const lastAcceptedSpreadsheet = acceptedSpreadsheets[0];
    const date = formatUtcDate(lastAcceptedSpreadsheet.validationDate, 'DD/MM/YYYY HH:mm');
    return `${t('common:spreadsheetAccepted', {
      date,
      type: typeTranslated,
      user: lastAcceptedSpreadsheet.validatedBy ?? '-',
    })} ${acceptedSpreadsheets.length > 1 ? `(${acceptedSpreadsheets.length})` : ''}`;
  }

  const rejectedSpreadsheets = orderedSpreadsheets.filter(
    (e) => e.status === SpreadsheetStatus.Rejected,
  );
  const lastRejectedSpreadsheet = rejectedSpreadsheets[0];
  const date = formatUtcDate(lastRejectedSpreadsheet?.validationDate, 'DD/MM/YYYY HH:mm');
  return `${t('common:spreadsheetRejectedTitle', {
    date,
    type: typeTranslated,
    user: lastRejectedSpreadsheet?.validatedBy ?? '-',
  })}`;
};

export default SubmitOrEvaluateSpreadsheetTitle;
