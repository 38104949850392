import { useQuery } from '@tanstack/react-query';

import { buildingApi } from '@/shared/api';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';

import useNotification from '@/shared/hooks/UseNotification';

const useGetBuildingSummary = (buildingId?: Nullable<string>) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    enabled: !!buildingId,
    onError: sendDefaultError,
    queryFn: () => buildingApi.getBuildingSummary(buildingId!),
    queryKey: [queryIds.buildings.GET_BUILDING_SUMMARY, buildingId],
  });

  return {
    building: data || null,
    buildingIsLoading: isLoading,
  };
};

export default useGetBuildingSummary;
