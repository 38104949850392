import { CaseStatus } from '@/types/cases';
import createRestClient from '../restClient';

const restClient = createRestClient();

const getCaseStatuses: (onlyOwnStatuses: boolean) => Promise<CaseStatus[]> = (onlyOwnStatuses) =>
  restClient.get(`casestatus/list.all?onlyOwnStatuses=${onlyOwnStatuses}`);

const activateCaseStatus: (id: string) => Promise<CaseStatus[]> = (id) =>
  restClient.put(`caseStatus/activate?caseStatusId=${id}`);

const deactivateCaseStatus = (id: string) =>
  restClient.put(`caseStatus/deactivate?caseStatusId=${id}`);

const createCaseStatus = (data: unknown) => restClient.post('caseStatus', data);

const updateCaseStatus = (data: unknown) => restClient.put('caseStatus', data);

export default {
  activateCaseStatus,
  createCaseStatus,
  deactivateCaseStatus,
  getCaseStatuses,
  updateCaseStatus,
};
