import actionTypes from '../actions/actionTypes';

const initialState = {
  show: false,
  collapse: false,
};

const sidebarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case actionTypes.CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case actionTypes.COLLAPSE_SIDEBAR:
      return { ...state, collapse: false };
    default:
      return state;
  }
};

export default sidebarReducer;
