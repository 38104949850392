import { IconButton, Tooltip } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentencize } from '@/shared/utils/helpers';

import { CommentIcon } from '@/shared/icons/Icons';
import RemarkModal from '@/shared/components/2.0/RemarkModal';
import useToggle from '@/shared/hooks/UseToggle';
import useUpdateCaseEntityRemark from '@/mutations/case/useUpdateCaseEntityRemark';

const propTypes = {
  entity: PropTypes.shape({
    caseId: PropTypes.string.isRequired,
    createdByCurrentTeam: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    remark: PropTypes.string,
  }).isRequired,
};

const CaseEntityRemark = ({ entity }) => {
  const { t } = useTranslation();
  const toggle = useToggle();

  const { updateCaseEntityRemark } = useUpdateCaseEntityRemark(entity.caseId);
  const { createdByCurrentTeam, remark: entityRemark } = entity;

  if (!createdByCurrentTeam && !entityRemark) {
    return null;
  }

  return !createdByCurrentTeam ? (
    <Tooltip title={entityRemark}>
      <i className="text-gray-600">
        <CommentIcon className="pt-[7px]" />
      </i>
    </Tooltip>
  ) : (
    <>
      <Tooltip title={entityRemark || sentencize(t('addType', { type: t('remark') }))}>
        <IconButton color="neutral" variant="plain" onClick={toggle.show}>
          <CommentIcon iconStyle={entityRemark ? 'fas' : 'far'} />
        </IconButton>
      </Tooltip>
      <RemarkModal
        isOpen={toggle.value}
        setIsOpen={toggle.setValue}
        submit={(id, remark, setSubmitting) => {
          updateCaseEntityRemark(
            {
              caseId: entity.caseId,
              id: entity.id,
              remark,
            },
            {
              onSettled: () => setSubmitting(false),
              onSuccess: toggle.hide,
            },
          );
        }}
        remark={entityRemark}
      />
    </>
  );
};

CaseEntityRemark.propTypes = propTypes;

export default CaseEntityRemark;
