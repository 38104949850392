import PropTypes from 'prop-types';

const { arrayOf, bool, func, node, oneOfType, shape, string } = PropTypes;

export const MessageProps = shape({
  active: bool,
  buttons: oneOfType([arrayOf(node), node]),
  customUpdateModal: func,
  customUpdateModalProps: shape({}),
  date: string,
  displayName: string,
  editable: bool,
  id: string,
  message: string,
  username: string,
});

export const MessagesProps = arrayOf(MessageProps);
