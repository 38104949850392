import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeId } from '../../utils/helpers';
import useToggle from '@/shared/hooks/UseToggle';

import ConfirmationModal from '../ConfirmationModal';
import { InfoIcon } from '../../icons/Icons';
import KpcTooltip from '../Tooltips/KpcTooltip';

const TextCheckbox = ({
  yesText,
  noText,
  callback,
  hasConfirmationModalOnNo,
  hasConfirmationModalOnYes,
  confirmationModalNoText,
  confirmationModalYesText,
  color,
  icon,
  buttonVariant,
  isDefaultChecked,
  isChecked,
  customClass,
  paragraphProps,
  disabled,
  disabledInfo,
  id,
  hidden,
  infoText,
  ...rest
}) => {
  const [checked, setChecked] = useState(isDefaultChecked);
  const [confirmationModalText, setConfirmationModalText] = useState(null);

  const confirmationToggle = useToggle();

  useEffect(() => {
    if (isChecked == null) {
      return;
    }
    setChecked(isChecked);
  }, [isChecked]);

  if (hidden === true) return null;

  const toggleModal = (newCheckedValue) => {
    setConfirmationModalText(newCheckedValue ? confirmationModalYesText : confirmationModalNoText);
    if (confirmationToggle.value) {
      confirmationToggle.hide();
    } else {
      confirmationToggle.show();
    }
  };

  const setValues = () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    callback(newCheckedValue);
  };

  const handleToggle = () => {
    const newCheckedValue = !checked;
    if (newCheckedValue) {
      return hasConfirmationModalOnYes ? toggleModal(newCheckedValue) : setValues();
    }
    return hasConfirmationModalOnNo ? toggleModal(newCheckedValue) : setValues();
  };

  const handleModalConfirm = async () => {
    setValues();
    confirmationToggle.hide();
  };

  const customId = `bool_toggle_${id ?? makeId(7)}`;
  return (
    <>
      <label
        className={`toggle-btn ${!customClass ? 'topbar-toggle' : customClass} ${
          disabled && 'disabled'
        }`}
        htmlFor={customId}
      >
        <input
          className="toggle-btn__input"
          type="checkbox"
          name={customId}
          id={customId}
          checked={checked}
          onChange={handleToggle}
          disabled={disabled}
          {...rest}
        />
        <span className="toggle-btn__input-label-yes-no topbar__link-icon" />
        <div {...paragraphProps}>
          {checked ? yesText : noText}
          {disabled === true && disabledInfo != null && (
            <KpcTooltip title={disabledInfo}>
              <i style={{ cursor: 'pointer', marginLeft: '5px' }}>
                <InfoIcon color="keypoint" />
              </i>
            </KpcTooltip>
          )}
          {infoText != null && (
            <KpcTooltip title={infoText}>
              <i style={{ cursor: 'pointer', marginLeft: '5px' }}>
                <InfoIcon color="keypoint" />
              </i>
            </KpcTooltip>
          )}
        </div>
      </label>
      {confirmationToggle.value && (
        <ConfirmationModal
          onConfirm={() => handleModalConfirm()}
          primaryFilled
          icon={icon}
          color={color}
          toggle={confirmationToggle}
        >
          {confirmationModalText}
        </ConfirmationModal>
      )}
    </>
  );
};

TextCheckbox.propTypes = {
  buttonVariant: PropTypes.string,
  callback: PropTypes.func,
  color: PropTypes.string,
  confirmationModalNoText: PropTypes.string,
  confirmationModalYesText: PropTypes.string,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  disabledInfo: PropTypes.string,
  hasConfirmationModalOnNo: PropTypes.bool,
  hasConfirmationModalOnYes: PropTypes.bool,
  hidden: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  infoText: PropTypes.string,
  isChecked: PropTypes.bool,
  isDefaultChecked: PropTypes.bool,
  noText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paragraphProps: PropTypes.object,
  yesText: PropTypes.string.isRequired,
};

TextCheckbox.defaultProps = {
  buttonVariant: null,
  callback: () => {},
  confirmationModalNoText: null,
  confirmationModalYesText: null,
  customClass: '',
  disabled: false,
  disabledInfo: null,
  hasConfirmationModalOnNo: false,
  hasConfirmationModalOnYes: false,
  hidden: false,
  icon: null,
  id: null,
  infoText: null,
  isChecked: null,
  isDefaultChecked: false,
  paragraphProps: {},
};

export default TextCheckbox;
