const EntityType = {
  AgendaPoint: 'AgendaPoint',
  Appointment: 'Appointment',
  AutomaticMessageTemplate: 'AutomaticMessageTemplate',
  Building: 'Building',
  Case: 'Case',
  CaseRelation: 'CaseRelation',
  Company: 'Company',
  Contact: 'Contact',
  Contractor: 'Contractor',
  CreditNote: 'CreditNote',
  Email: 'Email',
  Entity: 'Entity',
  Export: 'Export',
  File: 'File',
  InternalMessage: 'InternalMessage',
  MeetingType: 'MeetingType',
  MessageOfTheDay: 'MessageOfTheDay',
  MessageTemplate: 'MessageTemplate',
  Note: 'Note',
  Payment: 'Payment',
  PhoneCall: 'PhoneCall',
  Policy: 'Policy',
  Spreadsheet: 'Spreadsheet',
  StandardAgendaPoint: 'StandardAgendaPoint',
  StandardTask: 'StandardTask',
  Tag: 'Tag',
  Task: 'Task',
  TaskType: 'TaskType',
  TextMessage: 'TextMessage',
  Unit: 'Unit',
  UserResponsibility: 'UserResponsibility',
};

export default EntityType;
