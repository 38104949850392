import { darken } from 'polished';

export const colorBackground = '#ffffff';
export const colorBackgroundBody = '#f2f4f7';

export const colorFieldsBorder = '#f2f4f7';

export const colorAdditional = '#f50057';

export const colorText = '#646777';

export const colorHover = '#fafbfe';

export const colorBubble = 'rgba(242, 244, 247, 0.65)';

export const colorBubbleActive = 'rgba(234, 238, 255, 0.6)';

export const colorPrimary = '#0047a0';

export const colorSecondary = '#f50057';

export const colorBorder = '#eff1f5';

export const colorTextAdditional = '#646777';

export const colorAccent = '#f50057';

export const colorWhite = '#FFF';

export const colorRed = '#ff4861';

export const colorGreen = '#04cc2f';

export const colorBlue = '#70bbfd';
export const colorBlueHover = darken(0.1, colorBlue);

export const colorOrange = 'rgb(255,127,80)';
export const colorYellow = 'rgb(255,215,0)';
export const colorPurple = 'rgb(138,43,226)';

export const colorDanger = '#f44336';
