import {
  ImportExternalDataRequest,
  LinkExternalDataRequest,
  SyncExternalDataRequest,
  UnlinkExternalDataRequest,
} from '~/common/types/import/importRequests';
import { Building } from '~/common/types/building';
import config from '@/config/config';
import createRestClient from '../restClient';

const client = createRestClient(config.baseURL);

const importExternalData = (data: ImportExternalDataRequest): Promise<Building[]> =>
  client.post(`/external/import`, data);

const linkExternalEntity = (data: LinkExternalDataRequest) => client.post(`/external/link`, data);

const unlinkExternalEntity = (data: UnlinkExternalDataRequest) =>
  client.post(`/external/unlink`, data);

const syncExternalData = (data: SyncExternalDataRequest) => client.post(`/external/sync`, data);

export default {
  importExternalData,
  linkExternalEntity,
  syncExternalData,
  unlinkExternalEntity,
};
