import classNames from 'classnames';

import { Nullable } from '~/common/types';
import useToggle from '@/shared/hooks/UseToggle';

type Props = {
  preText: Nullable<string>;
  recipients?: Nullable<
    readonly {
      name?: Nullable<string>;
      email: string;
    }[]
  >;
};

const CollapsibleRecipients: React.FC<Props> = ({ preText, recipients }) => {
  const displayToggle = useToggle();

  if (!recipients?.length) return null;

  return (
    <div className="flex overflow-hidden text-xs text-gray-500">
      <span>{preText}:</span>
      <button type="button" className="ml-1 min-w-0" onClick={displayToggle.inverse}>
        <div
          className={classNames(
            'min-w-0 truncate text-left',
            displayToggle.value && 'whitespace-normal',
          )}
        >
          {recipients.map((to) => to.email).join(', ')}
        </div>
      </button>
    </div>
  );
};

export default CollapsibleRecipients;
