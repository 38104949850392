import { FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FormikTextField from '../../../../shared/formik/FormikTextField';
import FormikYesNo from '../../../../shared/formik/FormikYesNo';

const EditSubscriptionFormBody = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <FormGroup className="mb-2 ml-1">
        <FormikYesNo
          yesText={t('common:caseManagementEnabled')}
          noText={t('common:caseManagementDisabled')}
          id="allowCaseManagement"
          name="allowCaseManagement"
        />
      </FormGroup>
      <FormGroup className="mb-2 ml-1">
        <FormikYesNo
          yesText={t('common:syndicManagementEnabled')}
          noText={t('common:syndicManagementDisabled')}
          id="allowSyndicManagement"
          name="allowSyndicManagement"
        />
      </FormGroup>
      <FormGroup className="mb-2">
        <div className="flex items-center">
          <p className="w-28">{t('common:maxUsers')}:</p>
          <FormikTextField
            className="w-16"
            type="number"
            variant="standard"
            id="maxUsers"
            name="maxUsers"
            autoComplete="off"
          />
        </div>
      </FormGroup>
      <FormGroup className="mb-2">
        <div className="flex items-center">
          <p className="w-28">{t('common:maxMailboxes')}:</p>
          <FormikTextField
            className="w-16"
            type="number"
            variant="standard"
            id="maxMailboxes"
            name="maxMailboxes"
            autoComplete="off"
          />
        </div>
      </FormGroup>
      <FormGroup className="mb-2">
        <div className="flex items-center">
          <p className="w-28">{t('common:maxQrTokens')}:</p>
          <FormikTextField
            className="w-16"
            type="number"
            variant="standard"
            id="maxQrTokens"
            name="maxQrTokens"
            autoComplete="off"
          />
        </div>
      </FormGroup>
    </>
  );
};

export default EditSubscriptionFormBody;
