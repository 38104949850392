import {
  Company,
  CompanyImport,
  CreateCompanyImportRequest,
  ExternalCompany,
} from '~/common/types/company/company';
import { CompanyResponse } from '~/backend/types/company';
import { CompanyWebData } from '~/common/types/companyWeb/companyWeb';
import { CreateWithholdingObligationInfoVariables } from '~/common/types/companyWithholdingObligationInfo/createWithholdingObligationInfoVariables';
import { DeleteWithholdingObligationInfoVariables } from '~/common/types/companyWithholdingObligationInfo/deleteWithholdingObligationInfoVariables';
import { ExternalDataProvider } from '~/common/enums';
import { Nullable } from '~/common/types';
import { RelationBuildingInfo } from '~/common/types/building';
import { UpdateWithholdingObligationInfoVariables } from '~/common/types/companyWithholdingObligationInfo/updateWithholdingObligationInfoVariables';
import { WithholdingObligationInfo } from '~/common/types/companyWithholdingObligationInfo/withholdingObligationInfo';

import config from '@/config/config';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';
import { SendMessageRequest } from '~/common/types/email/emailMessage';

const client = createRestClient(config.baseURL);

const getExternalCompanies = (
  provider: ExternalDataProvider,
  page: number,
  filter: Nullable<string>,
): Promise<ExternalCompany[]> =>
  client.get('external/companies', { params: { filter, page, provider } });

// @ts-ignore
const getCompaniesAutoComplete = (params): Promise<PaginatedAutocomplete> =>
  client.get('/company/autocomplete', { params });

// @ts-ignore
const getContractorsAutocomplete = (params) =>
  client.get('/company/autocomplete', { params: { ...params, isContractor: true } });

const restClient = createRestClient();

// @ts-ignore
const linkContactToCompany = (data) => restClient.post('company/contact', data);

// @ts-ignore
const unlinkContactFromCompany = (data) => restClient.delete('company/contact', data);

// @ts-ignore
const createCompany = (company): Promise<CompanyResponse> => restClient.post('company', company);

// @ts-ignore
const addCompanyAddress = (companyAddress) => restClient.post('company/address', companyAddress);

const deleteCompany = (companyId: string) => restClient.delete(`company/${companyId}`);

// @ts-ignore
const deleteCompanyAddress = (companyAddress) =>
  restClient.delete('company/address', { data: companyAddress });

const getCompanies = (): Promise<Company[]> => restClient.get('company');

const getCompanyTeams = () => restClient.get('company/teams');

const getCompany = (companyId: string): Promise<Company> => restClient.get(`company/${companyId}`);

const getCompanyBuildingInfo = (id: string): Promise<RelationBuildingInfo[]> =>
  client.get(`/company/${id}/building-info`);

const getCompanyTags = () => restClient.get('company/tags');

const getCompanyPublicData = (countryCode: string, vatNumber: string) =>
  restClient.get('company/public.data', { params: { countryCode, vatNumber } });

// @ts-ignore
const linkTag = (tag) => restClient.put('company/tag.link', tag);

// @ts-ignore
const createTag = (tag) => restClient.post('company/tag', tag);

// @ts-ignore
const updateTag = (tag) => restClient.put('company/tag', tag);

const deleteTag = (id: string) => restClient.delete('company/tag', { data: { tagId: id } });

// @ts-ignore
const mergeCompanies = (data) => restClient.post('company/merge', data);

// @ts-ignore
const updateCompany = (company) => restClient.put('company', company);

// @ts-ignore
const unLinkTag = (tag) => restClient.put('company/tag.unlink', tag);

// @ts-ignore
const updateCompanyAddress = (companyAddress) => restClient.put('company/address', companyAddress);

const getCompanyWebDetails = (vat: string): Promise<CompanyWebData> =>
  restClient.get(`companyweb/details/${vat}`);

// @ts-ignore
const updateCompanyPermissions = (permission) => restClient.post(`company/permissions`, permission);

const getCompanyImports = (): Promise<CompanyImport[]> => client.get('/company/import/list');

const deleteCompanyImport = (id: string) => client.delete(`/company/import/${id}`);

const createCompanyImport = ({
  data,
  onProgress,
}: {
  data: CreateCompanyImportRequest;
  onProgress: CallableFunction;
}) =>
  client.postForm('/company/import', data, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.loaded && progressEvent.total) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;

        onProgress(Math.round(progress));
      }
    },
  });

const downloadCompanyImportFile: (companyImportId: string) => Promise<string> = (companyImportId) =>
  client.get(`/company/import/${companyImportId}/download`);

const getCompanyWithholdingObligationInfo: (
  companyId: string,
) => Promise<WithholdingObligationInfo[]> = (companyId) =>
  client.get(`/company/${companyId}/withholdingObligationInfo`);

const getLatestWithholdingObligationInfoForCompanies: (
  companyIds: string[],
) => Promise<Record<string, WithholdingObligationInfo>> = (companyIds) =>
  client.get('/company/latestWithholdingObligationInfoForCompanies', { params: { companyIds } });

const updateCompanyWithholdingObligationInfo = (data: UpdateWithholdingObligationInfoVariables) => {
  const { companyId, id, request } = data;
  const formData = objectToFormData(request);

  return client.putForm(`/company/${companyId}/withholdingObligationInfo/${id}`, formData);
};

const createCompanyWithholdingObligationInfo = (data: CreateWithholdingObligationInfoVariables) => {
  const { companyId, request } = data;
  const formData = objectToFormData(request);

  return client.postForm(`/company/${companyId}/withholdingObligationInfo`, formData);
};

const deleteCompanyWithholdingObligationInfo = async (
  data: DeleteWithholdingObligationInfoVariables,
) => {
  await client.delete(`/company/${data.companyId}/withholdingObligationInfo`, {
    data: data.ids,
  });
};

const postSendEmailMessage = ({
  data,
  companyId,
}: {
  data: SendMessageRequest;
  companyId: string;
}) =>
  restClient.post(`company/${companyId}/emails`, objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export default {
  addCompanyAddress,
  createCompany,
  createCompanyImport,
  createCompanyWithholdingObligationInfo,
  createTag,
  deleteCompany,
  deleteCompanyAddress,
  deleteCompanyImport,
  deleteCompanyWithholdingObligationInfo,
  deleteTag,
  downloadCompanyImportFile,
  getCompanies,
  getCompaniesAutoComplete,
  getCompany,
  getCompanyBuildingInfo,
  getCompanyImports,
  getCompanyPublicData,
  getCompanyTags,
  getCompanyTeams,
  getCompanyWebDetails,
  getCompanyWithholdingObligationInfo,
  getContractorsAutocomplete,
  getExternalCompanies,
  getLatestWithholdingObligationInfoForCompanies,
  linkContactToCompany,
  linkTag,
  mergeCompanies,
  postSendEmailMessage,
  unlinkContactFromCompany,
  unLinkTag,
  updateCompany,
  updateCompanyAddress,
  updateCompanyPermissions,
  updateCompanyWithholdingObligationInfo,
  updateTag,
};
