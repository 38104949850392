import { combineReducers } from 'redux';

import fsmaNumbersReducer from './fsmaNumbersReducer';
import integrationsReducer from './integrationsReducer';
import layoutReducer from './layoutReducer';
import policiesReducer from './policiesReducer';
import sidebarReducer from './sidebarReducer';
import tagsReducer from './tagsReducer';
import teamsReducer from './teamsReducer';
import usersReducer from './usersReducer';

const createRootReducer = (routerReducer) =>
  combineReducers({
    fsmaNumbers: fsmaNumbersReducer,
    integrations: integrationsReducer,
    layout: layoutReducer,
    policies: policiesReducer,
    router: routerReducer,
    sidebar: sidebarReducer,
    tags: tagsReducer,
    teams: teamsReducer,
    user: usersReducer,
  });

export default createRootReducer;
