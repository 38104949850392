import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { caseApi } from '../../shared/api';
import useNotification from '../../shared/hooks/UseNotification';

type Options = {
  callback?: () => void;
};

const useUpdateCaseRelationClassification = (options: Options) => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => caseApi.updateCaseRelationClassification(data),
    onError: sendDefaultError,
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('case_one') }),
        variant: 'success',
      });

      if (options?.callback) {
        options.callback();
      }
    },
  });

  return {
    isUpdatingCaseRelation: isLoading,
    updateCaseRelationClassification: mutate,
  };
};

export default useUpdateCaseRelationClassification;
