import { Alert, Checkbox, FormControl, FormLabel, Input } from '@mui/joy';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleExclamation, InfoIcon } from '@/shared/icons/Icons';
import Autocomplete from '@/shared/components/2.0/autocomplete/AutoComplete';

import { AutocompleteOption, Nullable } from '~/common/types';
import { decapitalize, sentencize } from '@/shared/utils/helpers';
import { GeneralInformationManager } from '../hooks/useManageGeneralInformation';

type Props = {
  active: boolean;
  generalInformationManager: GeneralInformationManager;
};

const GeneralInformationStep: React.FC<Props> = ({ active, generalInformationManager }) => {
  const { t } = useTranslation();

  const insuredAssignmentLabel = useMemo(() => {
    if (!generalInformationManager.get.insurerCentralCompany) {
      return t('requestRepairInKind.insuredAssignment');
    }

    return `${t('requestRepairInKind.insuredAssignment')}, ${decapitalize(t('insurer'))}: ${
      generalInformationManager.get.insurerCentralCompany.label
    }`;
  }, [generalInformationManager.get.insurerCentralCompany, t]);

  if (!active) {
    return null;
  }

  return (
    <div>
      <Alert
        startDecorator={
          <i>
            <InfoIcon />
          </i>
        }
        variant="outlined"
        color="primary"
        className="mb-2"
      >
        {t('requestRepairInKind.info1')}
      </Alert>
      <p className="mb-2">{t('requestRepairInKind.info2')}</p>
      {generalInformationManager.errors.length > 0 && (
        <Alert
          startDecorator={
            <i>
              <CircleExclamation />
            </i>
          }
          variant="outlined"
          color="danger"
          className="mb-4"
        >
          <ul className="list-disc">
            {generalInformationManager.errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className="flex justify-between space-x-4 rounded-lg pl-4 pt-4">
        <Checkbox
          className="items-center"
          label={insuredAssignmentLabel}
          onChange={() => generalInformationManager.set.insurerCentralCompany(null)}
          disabled={!generalInformationManager.get.insurerCentralCompany}
          checked={!!generalInformationManager.get.insurerCentralCompany}
        />
        {!generalInformationManager.get.insurerCentralCompany && (
          <Autocomplete
            name="companies"
            onChange={({ value }) =>
              generalInformationManager.set.insurerCentralCompany(
                value as Nullable<AutocompleteOption>,
              )
            }
            options={generalInformationManager.get.fsmaAutocomplete}
            loading={generalInformationManager.get.fsmaAutocompleteIsLoading}
            placeholder={sentencize(t('selectType', { type: t('insurer') }))}
          />
        )}
      </div>
      <div className="rounded-lg p-4">
        <FormControl className="mb-4">
          <FormLabel required>{t('damageType')}</FormLabel>
          <Autocomplete
            name="damageType"
            value={generalInformationManager.get.damageType}
            onChange={({ value }) =>
              generalInformationManager.set.damageType(value as Nullable<AutocompleteOption>)
            }
            options={generalInformationManager.get.damageTypeAutocomplete}
          />
        </FormControl>
        <FormControl>
          <FormLabel required>{t('description')}</FormLabel>
          <Input
            size="sm"
            className="mb-4"
            value={generalInformationManager.get.description}
            onChange={(e) => generalInformationManager.set.description(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t('remark')}</FormLabel>
          <Input
            size="sm"
            value={generalInformationManager.get.remark || ''}
            onChange={(e) => generalInformationManager.set.remark(e.target.value)}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default GeneralInformationStep;
