import { Company, Team } from '~/common/types/company/company';
import config from '@/config/config';
import { Contact } from '~/common/types/contact/contact';
import createRestClient from '../restClient';
import { CrmAutoCompleteOption } from '~/common/types';
import { CrmOptionType } from '~/common/enums';

const client = createRestClient(config.baseURL);

const getCrmAutocomplete = ({
  buildingId,
  hoaId,
  hoaRelationsOnly,
  includeCentralCompanies,
  includeCompanies,
  includeContacts,
  includeTeams,
}: {
  buildingId?: string;
  hoaId?: string;
  hoaRelationsOnly?: boolean;
  includeCentralCompanies?: boolean;
  includeCompanies?: boolean;
  includeContacts?: boolean;
  includeTeams?: boolean;
}): Promise<CrmAutoCompleteOption[]> =>
  client.get('/crm/autocomplete', {
    params: {
      buildingId,
      hoaId,
      hoaRelationsOnly,
      includeCentralCompanies,
      includeCompanies,
      includeContacts,
      includeTeams,
    },
  });

const getCrmEntityInfo = ({
  id,
  type,
}: {
  id: string;
  type: CrmOptionType;
}): Promise<Company | Contact | Team> => client.get('/crm/entity-info', { params: { id, type } });

export default {
  getCrmAutocomplete,
  getCrmEntityInfo,
};
