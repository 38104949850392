import { IconButton } from '@mui/joy';
import { Table } from 'ka-table';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import { customCellWidth } from '@/shared/utils/constants';

import { AddIcon, DeleteIcon } from '@/shared/icons/Icons';
import HeaderStep from './HeaderStep';
import UploadFileModal from '../../Repair/Timeline/Steps/SubmitOfferOrInvoice/UploadFileModal';

const getFileName = (uri: string) => {
  const parts = uri.split('/');
  return parts[parts.length - 1];
};

const FilesStep = () => {
  const { ui, setUi } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-end space-x-4">
        <HeaderStep
          className="grow"
          title={t('repairCaseForm.files.title')}
          description={t('repairCaseForm.files.description')}
          tooltipMessage={t('repairCaseForm.files.tooltip')}
        />
        <IconButton
          onClick={() => {
            setUi({ type: actions.CASE_TOGGLE_UPLOAD_FILES });
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      <div className="rounded-xl border">
        <Table
          height={300}
          rowKeyField="uri"
          columns={[
            { key: 'name', title: t('name') },
            { key: 'uri', title: t('actions'), width: customCellWidth.S },
          ]}
          data={ui.files.temporary}
          childComponents={{
            cellText: {
              // eslint-disable-next-line react/no-unstable-nested-components
              content: (props) => {
                switch (props.column.key) {
                  case 'name': {
                    return getFileName(props.value);
                  }
                  case 'uri': {
                    return (
                      <IconButton
                        color="danger"
                        onClick={() => {
                          const filtered = ui.files.temporary.filter(
                            (file) => file.uri !== props.rowData.uri,
                          );
                          setUi({ payload: filtered, type: actions.FILES_SET_TEMPORARY_FILES });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    );
                  }

                  default:
                    return props.value;
                }
              },
            },
            noDataRow: { content: () => t('noDataToDisplay') },
          }}
        />
      </div>

      {ui.case.toggles.uploadFile.modal && <UploadFileModal />}
    </>
  );
};

export default FilesStep;
