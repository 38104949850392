import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '@/shared/api/cases/case';
import { Nullable } from '~/common/types';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetCase = (id: Nullable<string>, options = {}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { sendDefaultError } = useNotification();
  const { data, isLoading, refetch, isSuccess, isFetching } = useQuery({
    enabled: !!id,
    onError: sendDefaultError,
    queryFn: () => caseApi.getCase(id as string, { language }),
    queryKey: [queryIds.cases.GET_CASE, id],
    ...options,
  });

  return {
    currentCase: data,
    currentCaseIsFetching: isFetching,
    currentCaseIsLoading: isLoading,
    currentCaseIsSuccess: isSuccess,
    refetchCase: refetch,
  };
};

export default useGetCase;
