import { useQuery } from '@tanstack/react-query';

import { crmApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

type Options = {
  buildingId?: string;
  hoaId?: string;
  hoaRelationsOnly?: boolean;
  includeCentralCompanies?: boolean;
  includeCompanies?: boolean;
  includeContacts?: boolean;
  includeTeams?: boolean;
};

const useGetCrmOptions = ({
  buildingId,
  hoaId,
  hoaRelationsOnly,
  includeTeams,
  includeCentralCompanies,
  includeCompanies,
  includeContacts,
}: Options) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    onError: sendDefaultError,
    queryFn: () =>
      crmApi.getCrmAutocomplete({
        buildingId,
        hoaId,
        hoaRelationsOnly,
        includeCentralCompanies,
        includeCompanies,
        includeContacts,
        includeTeams,
      }),
    queryKey: [
      queryIds.crm.GET_OPTIONS,
      buildingId,
      hoaId,
      hoaRelationsOnly,
      includeTeams,
      includeCentralCompanies,
      includeCompanies,
      includeContacts,
    ],
  });

  return {
    areCrmOptionsLoading: isLoading,
    crmOptions: data,
  };
};

export default useGetCrmOptions;
