import { Breadcrumbs, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/types';

type Props = {
  items: Breadcrumb[];
  currentItem?: string;
};

const BreadCrumb: React.FC<Props> = ({ items, currentItem }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs className="lowercase" size="sm">
      {items.map((item) => (
        <Link to={item.route} key={item.name}>
          {t(item.name)}
        </Link>
      ))}
      {!!currentItem && <Typography>{currentItem}</Typography>}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
