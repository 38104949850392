import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';

import IconButton from '../buttons/IconButton';
import { EllipsisIcon } from '../../icons/Icons';

const DropDownMore = ({ primaryChildren, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = !!anchorEl;

  return (
    <div className="dropdown-more-mui">
      {primaryChildren && primaryChildren}
      <IconButton onClick={handleOpen} iconComponent={<EllipsisIcon />} />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="dropdown-more__menu"
      >
        {children}
      </Menu>
    </div>
  );
};

DropDownMore.propTypes = {
  primaryChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

DropDownMore.defaultProps = {
  primaryChildren: null,
};

export default DropDownMore;
