import axios from 'axios';

import { getAccessToken } from '../utils/authUtils';
import { localStorageKeys } from '../utils/constants';

const { VITE_KEYPOINT_API_URI } = import.meta.env;

const createRestClient = (baseURL, getRawResponse = false) => {
  const restClient = axios.create({
    baseURL: baseURL || VITE_KEYPOINT_API_URI,
  });

  const handleRequest = (config) => {
    const teamId = localStorage.getItem(localStorageKeys.teamId);
    const accessToken = getAccessToken();

    if (!accessToken) {
      return config;
    }
    const { headers } = config;
    const authorizationHeaders = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      team: teamId,
    };

    return {
      ...config,
      headers: authorizationHeaders,
    };
  };

  restClient.interceptors.request.use(
    (config) => handleRequest(config),
    (error) => Promise.reject(error),
  );

  restClient.interceptors.response.use(
    (response) => {
      if (getRawResponse) {
        return response;
      }

      return response.data;
    },
    (error) => Promise.reject(error),
  );

  return restClient;
};

export default createRestClient;
