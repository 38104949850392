import { useQuery } from '@tanstack/react-query';

import { blogApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

const useGetRecentBlogs = (options = { enabled: true }) => {
  const { sendDefaultError } = useNotification();
  const { data, isLoading, isFetching } = useQuery({
    enabled: options.enabled,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: blogApi.getRecentBlogs,
    queryKey: [queryIds.blogs.GET_RECENT_BLOGS],
  });

  return {
    pagination: data?.pagination || {},
    recentBlogs: data?.items || [],
    recentBlogsIsLoading: isLoading,
  };
};

export default useGetRecentBlogs;
