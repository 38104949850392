import * as yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CommentIcon, SaveIcon, SkipIcon } from '../../../../../../shared/icons/Icons';
import {
  decapitalize,
  formatUtcDate,
  getCreatedBy,
  getMomentObject,
  getToday,
} from '../../../../../../shared/utils/helpers';
import API from '../../../../../../shared/api/ApiService';
import { CaseContext } from '@/containers/Cases/CaseContext';
import CaseEntityPropTypes from '../../../../../../shared/prop-types/CaseEntityPropTypes';
import IntakeCallFormBody from './IntakeCallFormBody';
import PhoneCallOutcome from '../../../../../../shared/enums/PhoneCallOutcome';
import useNotification from '../../../../../../shared/hooks/UseNotification';
import VatCode from '../../../../../../shared/enums/VatCode';

const IntakeCall = ({ intakeCalls, skipStep }) => {
  const { t } = useTranslation('common');
  const { currentCase, ownRelation } = useContext(CaseContext);
  const isCaseOwner = ownRelation.isOwner;

  const formRef = React.useRef();

  const originalCall = React.useMemo(
    () => intakeCalls.find((c) => c.outcome === PhoneCallOutcome.Success),
    [intakeCalls],
  );

  const initialFormValues = React.useMemo(
    () => ({
      buildingOlderThan10Years: false,
      callDate: originalCall?.callDate ?? getToday(),
      calleeRelationId: originalCall?.calleeRelationId,
      corporateForm: null,
      note: originalCall?.note ?? '',
      percentageVatRecovery: 100,
      phoneCallOutcome: originalCall?.outcome,
      vatDeductible: false,
      vatNumber: '',
      vatPercentage: VatCode.InlandExclusive21,
    }),
    [originalCall],
  );

  const filteredVoicemails = React.useMemo(() => {
    if (intakeCalls == null || !intakeCalls.length) {
      return [];
    }
    return intakeCalls
      .filter((c) => c.outcome !== PhoneCallOutcome.Success)
      .sort((a, b) => {
        const { callDate: currentCallDate } = a;
        const { callDate: nextCallDate } = b;
        const current = getMomentObject(currentCallDate);
        const next = getMomentObject(nextCallDate);
        return current.unix() - next.unix();
      });
  }, [intakeCalls]);

  const popperContainer = React.useRef();

  const { sendNotification, sendDefaultError } = useNotification();

  const handleSubmit = React.useCallback(
    async (data) => {
      const {
        buildingOlderThan10Years,
        vatPercentage,
        vatDeductible,
        percentageVatRecovery,
        corporateForm,
        vatNumber,
        ...rest
      } = data;
      const requestData = {
        ...rest,
        caseId: currentCase.id,
        mandate:
          rest.phoneCallOutcome === PhoneCallOutcome.Success
            ? {
                buildingOlderThan10Years,
                caseId: currentCase.id,
                corporateForm,
                vatDeductible,
                vatNumber,
                vatPercentage,
                vatRecoveryPercentage: percentageVatRecovery,
              }
            : null,
      };
      if (originalCall != null) {
        // Edit
        requestData.phoneCallId = originalCall.id;
        requestData.showOnClientPage = originalCall.showOnClientPage;
        const response = await API.putUpdatePhoneCall(requestData);

        if (response.serviceError != null || response.status !== 200) {
          sendDefaultError(response);
          return;
        }
        sendNotification({
          header: t('success'),
          message: t('typeSuccessfullyUpdated', { type: t('intakeCall') }),
          variant: 'success',
        });

        skipStep();
      } else {
        // Add
        const response = await API.putIntakeCall(requestData);

        if (response.serviceError != null || response.status !== 200) {
          sendDefaultError(response);
          return;
        }

        sendNotification({
          header: t('success'),
          message: t('typeSuccessfullyAdded', { type: t('intakeCall') }),
          variant: 'success',
        });

        formRef.current.resetForm();
        skipStep();
      }
    },
    [currentCase.id, originalCall, sendDefaultError, skipStep],
  );

  return (
    <div>
      <Timeline position="right">
        {filteredVoicemails != null &&
          filteredVoicemails.length > 0 &&
          filteredVoicemails.map((call) => (
            <TimelineItem key={call.id}>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: '18px' }} />
                <TimelineDot sx={{ height: '45px', width: '45px' }} />
                <TimelineConnector sx={{ height: '18px' }} />
              </TimelineSeparator>
              <div className="m-auto ml-3 leading-[14px]">
                <h4>
                  {t(`${decapitalize(call.outcome)}`)} -{' '}
                  {formatUtcDate(call.callDate, 'DD-MM-YYYY HH:mm')}
                </h4>
                <p>
                  <i>
                    <FontAwesomeIcon icon={['fad', 'phone-arrow-up-right']} />
                  </i>{' '}
                  {getCreatedBy(call.createdByUser, call.createdByTeam)}
                </p>
                <p>
                  <i>
                    <FontAwesomeIcon icon={['fad', 'phone-arrow-down-left']} />
                  </i>{' '}
                  {call.callee}
                </p>
                {call.note != null && call.note.length > 0 && (
                  <p>
                    <i>
                      <CommentIcon />
                    </i>{' '}
                    {call.note}
                  </p>
                )}
              </div>
            </TimelineItem>
          ))}
        {!isCaseOwner &&
          !originalCall &&
          (filteredVoicemails == null || filteredVoicemails.length === 0) && (
            <span className="color-keypoint">{t('noDataToDisplay')}</span>
          )}
        {(isCaseOwner || originalCall) && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: isCaseOwner ? '100px' : '18px' }} />
              <TimelineDot sx={{ height: '45px', width: '45px' }} />
              <TimelineConnector sx={{ height: isCaseOwner ? '100px' : '18px' }} />
            </TimelineSeparator>
            {isCaseOwner ? (
              <div
                className="ms-3 mt-5 leading-[14px]"
                style={{ margin: 'auto 0px', width: '100%' }}
              >
                <h4 className="mb-3">
                  {originalCall ? t('editPhoneCall') : t('registerPhoneCallAndVerifyData')}
                </h4>
                <Formik
                  innerRef={formRef}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  initialValues={initialFormValues}
                  validationSchema={yup.object().shape({
                    buildingOlderThan10Years: yup.bool(),
                    callDate: yup
                      .date()
                      .max(getToday().endOf('day'), t('errors:dateCannotBeInTheFuture'))
                      .nullable()
                      .required(t('errors:fieldIsRequired')),
                    calleeRelationId: yup.string().nullable().required(t('errors:fieldIsRequired')),
                    percentageVatRecovery: yup
                      .number()
                      .nullable()
                      .when('vatDeductible', {
                        is: true,
                        then: yup
                          .number()
                          .nullable()
                          .required(t('errors:fieldIsRequired'))
                          .min(1, t('errors:minValueX', { val: 1 }))
                          .max(100, t('errors:maxValueX', { val: 100 })),
                      }),
                    phoneCallOutcome: yup.string().nullable().required(t('errors:fieldIsRequired')),
                    vatDeductible: yup.bool(),
                    vatNumber: yup
                      .string()
                      .nullable()
                      .when('vatDeductible', {
                        is: true,
                        then: yup
                          .string()
                          .nullable()
                          .required(t('errors:xIsRequired', { field: t('validVatNumber') })),
                      }),
                  })}
                >
                  <Form>
                    {currentCase != null && <IntakeCallFormBody editing={originalCall != null} />}
                    <div className="mt-2 flex justify-end space-x-4">
                      {originalCall == null && (
                        <Button variant="outlined" startDecorator={<SkipIcon />} onClick={skipStep}>
                          {t('skipStep')}
                        </Button>
                      )}
                      <Button startDecorator={<SaveIcon />} type="submit">
                        {originalCall == null ? t('register') : t('edit')}
                      </Button>
                    </div>
                  </Form>
                </Formik>
                <div ref={popperContainer} />
              </div>
            ) : (
              <div className="my-0 ml-3 mr-auto leading-[14px]">
                <h4>
                  {t(`${decapitalize(originalCall.outcome)}`)} -{' '}
                  {formatUtcDate(originalCall.callDate, 'DD-MM-YYYY HH:mm')}
                </h4>
                <p>
                  <i>
                    <FontAwesomeIcon icon={['fad', 'phone-arrow-up-right']} />
                  </i>{' '}
                  {getCreatedBy(originalCall.createdByUser, originalCall.createdByTeam)}
                </p>
                <p>
                  <i>
                    <FontAwesomeIcon icon={['fad', 'phone-arrow-down-left']} />
                  </i>{' '}
                  {originalCall.callee}
                </p>
                {originalCall.note != null && originalCall.note.length > 0 && (
                  <p>
                    <i>
                      <CommentIcon />
                    </i>{' '}
                    {originalCall.note}
                  </p>
                )}
              </div>
            )}
          </TimelineItem>
        )}
      </Timeline>
    </div>
  );
};

export default IntakeCall;

IntakeCall.propTypes = {
  intakeCalls: PropTypes.arrayOf(CaseEntityPropTypes),
  skipStep: PropTypes.func.isRequired,
};

IntakeCall.defaultProps = {
  intakeCalls: [],
};
