/* eslint-disable no-console */
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';
import { getAccessToken } from '../utils/authUtils';

const { VITE_SIGNALR_HUBS_URI } = import.meta.env;

type SocketData = {
  socket: HubConnection | null;
};

const useSocket = (teamId?: string): SocketData => {
  const activeConnectionsRef = useRef<HubConnection[]>([]);
  const [connection, setConnection] = useState<HubConnection | null>(null);

  const accessToken = getAccessToken();

  useEffect(() => {
    const initConnection = async () => {
      let newConnection: HubConnection | null = null;

      try {
        newConnection = new HubConnectionBuilder()
          .withUrl(VITE_SIGNALR_HUBS_URI, { accessTokenFactory: () => accessToken as string })
          .configureLogging(LogLevel.Critical)
          .withAutomaticReconnect()
          .build();

        await newConnection.start();

        if (newConnection.state === 'Connected' && teamId) {
          await newConnection.invoke('JoinTeamGroup', teamId);
        }

        setConnection(newConnection);
        activeConnectionsRef.current.push(newConnection);
      } catch (error) {
        console.error(error);
      } finally {
        if (activeConnectionsRef.current.length && newConnection) {
          const activeConnections = activeConnectionsRef.current;
          const keepAliveConnectionId = newConnection.connectionId;

          const connectionsToClose = activeConnections.filter(
            (c) => c.connectionId !== keepAliveConnectionId,
          );

          connectionsToClose.forEach(async (c) => {
            await c.stop();
          });
        }
      }
    };

    initConnection();
  }, [accessToken, teamId]);

  return {
    socket: connection,
  };
};

export default useSocket;
