import React from 'react';
import 'ka-table/style.css';
import { Table } from 'ka-table';
import { ActionType, DataType, EditingMode } from 'ka-table/enums';
import { hideColumn, showColumn } from 'ka-table/actionCreators';
import CellEditorBoolean from 'ka-table/Components/CellEditorBoolean/CellEditorBoolean';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ColumnSettings = ({ columns, dispatch }) => {
  const { t } = useTranslation('common');
  const columnsSettingsProps = {
    data: columns
      .filter((c) => c.title != null)
      .map((c) => ({ ...c, visible: c.visible !== false })),
    rowKeyField: 'key',
    columns: [
      {
        key: 'title',
        isEditable: false,
        title: t('common:field'),
        dataType: DataType.String,
      },
      {
        key: 'visible',
        title: t('common:visible'),
        isEditable: false,
        style: { textAlign: 'center' },
        dataType: DataType.Boolean,
      },
    ],
    editingMode: EditingMode.None,
  };
  const dispatchSettings = (action) => {
    if (action.type === ActionType.UpdateCellValue) {
      dispatch(action.value ? showColumn(action.rowKeyValue) : hideColumn(action.rowKeyValue));
    }
  };
  const getCell = React.useCallback((data) => {
    switch (data.column.key) {
      case 'visible':
        return <CellEditorBoolean {...data} />;
      default:
        return null;
    }
  }, []);
  return (
    <Table
      {...columnsSettingsProps}
      childComponents={{
        rootDiv: { elementAttributes: () => ({ style: { width: 400, marginBottom: 20 } }) },
        cell: {
          content: (props) => getCell(props),
        },
      }}
      dispatch={dispatchSettings}
    />
  );
};

export default ColumnSettings;

ColumnSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};
