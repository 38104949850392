import classNames from 'classnames';

import BreadCrumb from './BreadCrumb';

type Props = {
  className?: string;
  subTitle?: string;
  title: string;
  breadCrumb: any[];
};

const PageTitle: React.FC<Props> = ({
  className = '',
  subTitle = undefined,
  title = '',
  breadCrumb,
}) => (
  <div className={classNames('flex', className)}>
    <div className="grow space-y-2">
      <h1 className="text-xl font-medium capitalize">{title}</h1>
      {!!subTitle && <h3 className="text-sm text-gray-600">{subTitle}</h3>}
    </div>
    <div>{!!breadCrumb.length && <BreadCrumb items={breadCrumb} currentItem={title} />}</div>
  </div>
);

export default PageTitle;
