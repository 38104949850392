import { Autocomplete, AutocompleteOption } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import useDebounce from '../../hooks/UseDebounce';
import { useGetPostalCodeCity } from '@/queries';

import { InlineSpinner } from '@/shared/icons/Icons';

export type CitySuggestion = {
  id?: string;
  city: string;
  postalCode: string;
};

type BaseProps = {
  country?: Nullable<string>;
  disabled?: boolean;
  onInputChange: (value: string) => void;
  onChange?: (value: CitySuggestion | null) => void;
  required?: boolean;
  value?: Nullable<string>;
  onBlur: () => void;
};

type CitySelectorProps = BaseProps & {
  type: 'city';
  postalCode?: Nullable<string>;
};

type PostalCodeSelectorProps = BaseProps & {
  type: 'postalCode';
  city?: Nullable<string>;
};

export type PostalCodeCitySelectorProps = CitySelectorProps | PostalCodeSelectorProps;

const isCitySelector = (props: PostalCodeCitySelectorProps): props is CitySelectorProps =>
  props.type === 'city';

const PostalCodeCitySelector: React.FC<PostalCodeCitySelectorProps> = (props) => {
  const { value, country, type, onChange, onInputChange, disabled, required, onBlur } = props;

  const { t } = useTranslation();

  const debouncedValue = useDebounce(value, 500);

  const selectedSuggestion = (() => {
    if (!value) {
      return null;
    }

    if (isCitySelector(props)) {
      const { postalCode } = props;

      return {
        city: value,
        postalCode: postalCode || '',
      };
    }

    const { city } = props;

    return {
      city: city || '',
      postalCode: value,
    };
  })();

  const { postalCodeCity: suggestions, isFetchingPostalCodeCity } = useGetPostalCodeCity({
    countryCode: country || '',
    term: debouncedValue,
  });

  return (
    <Autocomplete
      noOptionsText={t('noOptions')}
      freeSolo
      options={suggestions}
      value={selectedSuggestion}
      onChange={(_, option) => {
        if (typeof option !== 'string') {
          onChange?.(option);
        }
      }}
      isOptionEqualToValue={(option, v) =>
        option.city.toLowerCase() === v.city.toLowerCase() &&
        option.postalCode.toLowerCase() === v.postalCode.toLowerCase()
      }
      onInputChange={(_, input) => onInputChange(input)}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return option[type];
      }}
      renderOption={(propsOption, option) => (
        <AutocompleteOption {...propsOption} key={option.city}>
          {`[${option.postalCode}] ${option.city}`}
        </AutocompleteOption>
      )}
      disabled={disabled}
      required={required}
      onBlur={onBlur}
      startDecorator={isFetchingPostalCodeCity ? <InlineSpinner /> : null}
    />
  );
};

export default PostalCodeCitySelector;
