type Props = {
  width?: string;
  height?: string;
};

const SkeletonRow: React.FC<Props> = ({ width = '100%', height = '2.5vh' }) => (
  <div style={{ height, width }} className="rounded-xl bg-gray-200" />
);

const DocumentSkeleton = () => (
  <div className="mx-[25%] my-[5%] flex h-[80vh] animate-pulse flex-col gap-6 md:gap-8 lg:gap-10 xl:gap-14 2xl:gap-20">
    <div className="flex h-1/4 gap-2 md:gap-5 lg:gap-8 xl:gap-12">
      <div className="aspect-square w-1/4 self-start rounded-full bg-gray-200" />
      <div className="flex w-full flex-col justify-between">
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow width="75%" />
      </div>
    </div>
    <div className="flex grow flex-col justify-between">
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </div>
    <div className="flex h-1/4 gap-2 md:gap-5 lg:gap-8 xl:gap-12">
      <div className="w-1/2 rounded-xl bg-gray-200" />
      <div className="flex w-full flex-col justify-between">
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow width="75%" />
      </div>
    </div>
  </div>
);

export default DocumentSkeleton;
