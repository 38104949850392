import { Autocomplete, FormControl, FormLabel } from '@mui/joy';
import { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormikValues } from './AddRepairCaseStepper';
import { trpc } from '@/config/trpc';

import { CompanyIcon } from '@/shared/icons/Icons';
import HeaderStep from './HeaderStep';
import { Nullable } from '~/common/types';

const ManagementStep = () => {
  const { t } = useTranslation();

  const { data: repairCompanies } = trpc.company.repairCompanies.useQuery();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [selectedName, setSelectedName] = useState<Nullable<string>>(null);
  const possibleRepairCompanies = useMemo(
    () => repairCompanies?.filter((team) => team.repairInKindPossible),
    [repairCompanies],
  );
  const onlyOneRepairCompany = possibleRepairCompanies?.length === 1;

  useEffect(() => {
    if (onlyOneRepairCompany) {
      setFieldValue('sendTo', possibleRepairCompanies[0].teamId);
      setSelectedName(possibleRepairCompanies[0].name ?? '');
    }
  }, [setFieldValue, onlyOneRepairCompany, possibleRepairCompanies]);

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.management.title')}
        description={t('repairCaseForm.management.description')}
        tooltipMessage={t('repairCaseForm.management.tooltip')}
      />

      <FormControl>
        <FormLabel required>{t('company')}</FormLabel>
        <Autocomplete
          disabled={onlyOneRepairCompany}
          value={possibleRepairCompanies?.find((c) => c.teamId === values.sendTo) || null}
          startDecorator={<CompanyIcon />}
          getOptionKey={(option) => option.teamId}
          getOptionLabel={(option) => option.name || ''}
          options={possibleRepairCompanies || []}
          onChange={(_e, option) => {
            if (option) {
              setFieldValue('sendTo', option?.teamId);
              setSelectedName(option?.name ?? '');
            }
          }}
        />
      </FormControl>
      {selectedName && (
        <p className="text-xs text-primary">
          {t('repairCaseForm.management.selectedCompanyDescription', { name: selectedName })}
        </p>
      )}
    </>
  );
};

export default ManagementStep;
