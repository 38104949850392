import PropTypes from 'prop-types';

import { BuildingIcon } from '../../../icons/Icons';

const propTypes = {
  addressLine: PropTypes.string,
  city: PropTypes.string,
  isBuilding: PropTypes.bool,
  name: PropTypes.string,
  postalCode: PropTypes.string,
};

const AddressCell = ({ name, addressLine, postalCode, city, isBuilding }) => (
  <div>
    {name && (
      <div>
        {isBuilding && <BuildingIcon className="mr-2" />}
        <span className="break-words">{name}</span>
      </div>
    )}
    {addressLine && <div className="break-words">{addressLine}</div>}
    {city
      ? postalCode && (
          <div className="break-words">
            {postalCode} {city}
          </div>
        )
      : addressLine
      ? ''
      : '-'}
  </div>
);

AddressCell.propTypes = propTypes;

export default AddressCell;
