import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@mui/material';

const StringFilter = ({ callback, name, label, defaultValue }) => (
  <div>
    <TextField
      size="small"
      variant="standard"
      fullWidth
      defaultValue={defaultValue}
      onChange={(event) => {
        callback(name, event.currentTarget.value);
      }}
      label={label}
    />
  </div>
);

StringFilter.propTypes = {
  callback: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
};

StringFilter.defaultProps = {
  callback: () => {},
  defaultValue: '',
  name: '',
};

export default StringFilter;
