import { useQuery } from '@tanstack/react-query';

import caseApi from '../../shared/api/cases/case';
import { queryIds } from '../../shared/utils/constants';

const useGetCaseEntityAccess = (caseId, entityId) => {
  const { data, isLoading, refetch } = useQuery({
    enabled: !!entityId,
    queryFn: () => caseApi.getCaseEntityAccess(caseId, entityId),
    queryKey: [queryIds.cases.GET_CASE_ENTITY_ACCESS, caseId, entityId],
    staleTime: 60000,
  });

  return {
    areTeamsWithAccessLoading: isLoading,
    refetchTeamsWithAccess: refetch,
    teamsWithAccessToEntity: data,
  };
};

export default useGetCaseEntityAccess;
