import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../api/ApiService';
import CaseType from '../../../enums/CaseType';
import { getCurrentTranslation } from '../../../utils/helpers';

const propTypes = {
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
};

const ClassificationFilter = ({
  callback = () => {},
  name = '',
  defaultValue = [],
  label = null,
}) => {
  const { language } = i18n;
  const { t } = useTranslation('common');
  const [caseClassifications, setCaseClassifications] = useState([]);

  useEffect(() => {
    async function fetchTeamClassifications() {
      const response = await API.fetchGetTeamCaseClassifications(CaseType.Case);

      if (response.serviceError != null || response.status !== 200) {
        setCaseClassifications([]);
        return;
      }

      setCaseClassifications(
        response.data.sort((a, b) => {
          const translationA = getCurrentTranslation(a.translations, language) ?? '';
          const translationB = getCurrentTranslation(b.translations, language) ?? '';
          return translationA.localeCompare(translationB);
        }),
      );
    }

    fetchTeamClassifications();
  }, [language]);

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      options={caseClassifications}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="classificationId"
      onChange={(e, values) => callback(name, values)}
      filterSelectedOptions
      getOptionLabel={(opt) => {
        if (opt === null) return '';
        const classification = caseClassifications.find((c) => c.id === opt.id);
        if (classification == null) return '';
        const currentTranslation = getCurrentTranslation(classification.translations, language);
        return currentTranslation ?? '';
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:classification')} />
      )}
      label={t('common:classification')}
      getValue={(val) => val?.id ?? val}
      value={defaultValue}
    />
  );
};

ClassificationFilter.propTypes = propTypes;

export default ClassificationFilter;
