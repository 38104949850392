import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import IconButton from '../buttons/IconButton';
import { makeId } from '../../utils/helpers';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
      iconComponent: PropTypes.node,
      iconStyle: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),
};

const ButtonMenu = ({ items = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (action) => {
    handleClose();
    action();
  };

  return (
    <>
      <IconButton onClick={handleMenuClick} icon="ellipsis-v" iconStyle="fad" color="keypoint" />
      <Menu id="Button-Menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((option) => (
          <MenuItem key={makeId(7)} onClick={() => handleClick(option.action)}>
            <IconButton
              color="keypoint"
              onClick={option.action}
              iconStyle={option.iconStyle}
              icon={option.icon}
              iconComponent={option.iconComponent}
              tooltip={option.tooltip}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ButtonMenu.propTypes = propTypes;

export default ButtonMenu;
