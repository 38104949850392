import { useMutation } from '@tanstack/react-query';

import createRestClient from '@/shared/api/restClient';

export type TemporaryFileResponse = {
  blobContainerName: string;
  name: string;
  uri: string;
  originalFileName: string;
  contentType: string;
};

const client = createRestClient();

export const useUploadTemporaryFile = () => {
  const { mutate, mutateAsync, isLoading } = useMutation(
    async (file: File): Promise<TemporaryFileResponse> => {
      const data = new FormData();

      data.append('file', file);

      return client.postForm('temporaryFiles', data);
    },
  );

  return {
    isUploadingTemporaryFile: isLoading,
    uploadTemporaryFile: mutate,
    uploadTemporaryFileAsync: mutateAsync,
  };
};

export const useUploadTemporaryFiles = () => {
  const { mutate, mutateAsync, isLoading } = useMutation(
    async (files: File[]): Promise<TemporaryFileResponse[]> => {
      const data = new FormData();

      files.forEach((f) => {
        data.append('files', f);
      });

      return client.postForm('temporaryFiles/multiple', data);
    },
  );

  return {
    isUploadingTemporaryFiles: isLoading,
    uploadTemporaryFiles: mutate,
    uploadTemporaryFilesAsync: mutateAsync,
  };
};
