import { Button, IconButton, Tooltip } from '@mui/joy';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Swiper } from 'swiper/types';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  CenterIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@/shared/icons/Icons';

type Coordinates = { x: number; y: number };

const ZOOM_LIMIT = 5;
const ZOOM_MULTIPLIER = 1.5;
const ZOOM_MULTIPLIER_SCROLL = 1.1;

const initialZoom = {
  prevClientX: 0,
  prevClientY: 0,
  scale: 1,
  translateX: 0,
  translateY: 0,
};

const usePanAndZoom = <T extends HTMLElement>() => {
  const [transformValues, setTransformValues] = useState(initialZoom);

  const containerRef = useRef<T>(null);

  const calculateOffset = (mousePosition: Coordinates, zoomFactor: number, rect: DOMRect) => {
    const origin = {
      x: mousePosition.x - rect.x,
      y: mousePosition.y - rect.y,
    };

    const distanceToCenter = {
      x: rect.width / 2 - origin.x,
      y: rect.height / 2 - origin.y,
    };
    const scaledDistanceToCenter = {
      x: distanceToCenter.x * zoomFactor,
      y: distanceToCenter.y * zoomFactor,
    };

    return {
      x: scaledDistanceToCenter.x - distanceToCenter.x,
      y: scaledDistanceToCenter.y - distanceToCenter.y,
    };
  };

  const zoom = (factor: number, offset?: Coordinates) =>
    setTransformValues((prevValues) => {
      let newScale = prevValues.scale * factor;

      const minZoom = 1 / ZOOM_LIMIT;
      const maxZoom = ZOOM_LIMIT * 2;

      if (newScale < minZoom) newScale = minZoom;
      if (newScale > maxZoom) newScale = maxZoom;

      if (!offset || newScale === maxZoom || newScale === minZoom) {
        return {
          ...prevValues,
          scale: newScale,
        };
      }

      return {
        ...prevValues,
        scale: newScale,
        translateX: prevValues.translateX * factor + offset.x,
        translateY: prevValues.translateY * factor + offset.y,
      };
    });

  const savePrevMousePosition = (e: MouseEvent | React.MouseEvent<T>) =>
    setTransformValues((prevValues) => ({
      ...prevValues,
      prevClientX: e.clientX,
      prevClientY: e.clientY,
    }));

  const pan = (e: MouseEvent) => {
    e.preventDefault();

    setTransformValues((prevValues) => ({
      ...prevValues,
      translateX: prevValues.translateX + (e.clientX - prevValues.prevClientX),
      translateY: prevValues.translateY + (e.clientY - prevValues.prevClientY),
    }));
    savePrevMousePosition(e);
  };

  const panStop = () => {
    window.removeEventListener('mouseup', panStop);
    window.removeEventListener('mousemove', pan);
  };

  const onMouseDown = (e: React.MouseEvent<T>) => {
    savePrevMousePosition(e);

    window.addEventListener('mouseup', panStop);
    window.addEventListener('mousemove', pan);
  };

  const onScroll = (e: React.WheelEvent<T>) => {
    if (!e.deltaY || !containerRef.current) return;

    const boundingRect = containerRef.current.getBoundingClientRect();
    const mousePosition = { x: e.clientX, y: e.clientY };

    const zoomFactor = e.deltaY > 0 ? 1 / ZOOM_MULTIPLIER_SCROLL : ZOOM_MULTIPLIER_SCROLL;
    const zoomOffset = calculateOffset(mousePosition, zoomFactor, boundingRect);

    zoom(zoomFactor, zoomOffset);
  };

  const resetZoom = () => setTransformValues(initialZoom);

  const zoomIn = () => zoom(ZOOM_MULTIPLIER);

  const zoomOut = () => zoom(1 / ZOOM_MULTIPLIER);

  const transform = `translate(${transformValues.translateX}px, ${transformValues.translateY}px) scale(${transformValues.scale})`;

  return {
    containerRef,
    onMouseDown,
    onScroll,
    resetZoom,
    transform,
    zoomIn,
    zoomOut,
  };
};

type Props<T> = {
  alt?: string;
  className?: string;
  download: () => Promise<void>;
  isLoading?: boolean;
  onLoad?: () => void;
  onError?: () => void;
  swiper?: Swiper;
  toggle: UseToggle<T>;
  uri: string;
};

const ImageRenderer = <T,>({
  alt,
  className,
  download,
  isLoading = false,
  onLoad,
  onError,
  swiper,
  toggle,
  uri,
}: Props<T>) => {
  const { t } = useTranslation();
  const { containerRef, onMouseDown, onScroll, resetZoom, transform, zoomIn, zoomOut } =
    usePanAndZoom<HTMLDivElement>();

  const cycle = useCallback(
    (direction: 'next' | 'prev') => {
      if (!swiper) return;

      resetZoom();

      if (direction === 'next') {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
    },
    [resetZoom, swiper],
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const { key } = e;

      if (key !== 'ArrowLeft' && key !== 'ArrowRight') return;

      const direction = key === 'ArrowLeft' ? 'prev' : 'next';

      cycle(direction);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [cycle]);

  const toolbarItems = [
    { actionKey: 'resetZoom', icon: <CenterIcon />, onClick: resetZoom },
    { actionKey: 'zoomOut', icon: <ZoomOutIcon />, onClick: zoomOut },
    { actionKey: 'zoomIn', icon: <ZoomInIcon />, onClick: zoomIn },
    { actionKey: 'download', icon: <DownloadIcon />, onClick: download },
  ];

  return (
    <>
      <div role="toolbar" className="flex justify-end gap-8">
        <div className="flex gap-4">
          {toolbarItems.map(({ actionKey, onClick, icon }) => (
            <Tooltip
              key={actionKey}
              title={t(`imageRenderer.toolbar.${actionKey}`)}
              placement="bottom"
            >
              <IconButton variant="plain" size="md" onClick={onClick} disabled={isLoading}>
                {icon}
              </IconButton>
            </Tooltip>
          ))}
        </div>
        <Button variant="outlined" onClick={toggle.hide}>
          {t('close')}
        </Button>
      </div>
      {isLoading && <div className="h-[80vh] w-full animate-pulse bg-gray-200" />}
      <div
        role="button"
        tabIndex={-1}
        ref={containerRef}
        className={classNames('relative h-[80vh] overflow-hidden', className)}
        onWheelCapture={onScroll}
        onMouseDown={onMouseDown}
      >
        <div
          className="flex h-full cursor-grab items-center justify-center active:cursor-grabbing"
          style={{ transform }}
        >
          <img
            draggable={false}
            src={uri}
            alt={alt || t('previewImage')}
            className="h-auto max-h-full w-auto max-w-full"
            onLoad={onLoad}
            onError={onError}
          />
        </div>
        {!!swiper && (
          <>
            <IconButton
              className="absolute left-0 top-1/2"
              onClick={() => cycle('prev')}
              variant="plain"
              size="lg"
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              className="absolute right-0 top-1/2"
              onClick={() => cycle('next')}
              variant="plain"
              size="lg"
            >
              <ChevronRightIcon />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
};
export default ImageRenderer;
