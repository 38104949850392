import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TagType from '../../../enums/TagType';

const TagFilter = ({ callback, name, defaultValue, tagType }) => {
  const tags = useSelector((state) => {
    const { tags: stateTags } = state;
    switch (tagType) {
      case TagType.Building:
        return stateTags.buildingTags;
      case TagType.Company:
        return stateTags.companyTags;
      case TagType.Contact:
        return stateTags.contactTags;
      default:
        return [];
    }
  });
  const { t } = useTranslation('common');

  return (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={tags}
      fullWidth
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      value={defaultValue}
      onChange={(e, values) =>
        callback(
          name,
          values.map((val) => ({
            id: val.id,
            description: val.description,
          })),
        )
      }
      filterSelectedOptions
      getOptionLabel={(option) => option.description}
      renderInput={(params) => <TextField {...params} variant="standard" label={t('common:tag')} />}
    />
  );
};

TagFilter.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  tagType: PropTypes.oneOf([TagType.Building, TagType.Company, TagType.Contact]).isRequired,
};

TagFilter.defaultProps = {
  callback: () => {},
  name: '',
  defaultValue: [],
};

export default TagFilter;
