import { Button } from '@mui/joy';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';

import FormikCrmSelector from '@/shared/formik/FormikCrmSelector';
import { trpc } from '@/config/trpc';

const CaseManagementStep = () => {
  const { data: teams } = trpc.team.teamsAndInvitations.useQuery();
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  const repairInKindPossible = teams?.some((team) => team?.repairInKindPossible);

  const { values, setFieldValue } = useFormikContext();
  /* @ts-ignore */
  const { selfManagement, repairByKeypoint } = values;

  function handleSelfManagement(value: boolean) {
    if (value) {
      setFieldValue('sendTo', null);
    }
    setFieldValue('selfManagement', value);
  }

  function handleRepairByKeypoint(value: boolean) {
    setFieldValue('repairByKeypoint', value);
    setFieldValue('rikRequested', value);
  }

  return (
    <div>
      <div>
        <h3 className="font-bold">{t('management')}</h3>
        <h5 className="text-center">
          {t('wouldYouManageCaseYourselfOrForwardItToAnInsuranceIntermediaryOrInsurer')}
        </h5>
      </div>
      <div className="mt-8 flex justify-center">
        <div>
          {currentTeam?.canHandleInsuranceCases && (
            <Button
              className="mr-2"
              onClick={() => handleSelfManagement(true)}
              variant={selfManagement ? 'solid' : 'outlined'}
            >
              {t('managingItYourself')}
            </Button>
          )}
          <Button
            variant={selfManagement === false ? 'solid' : 'outlined'}
            onClick={() => handleSelfManagement(false)}
            type="button"
          >
            {t('forward')}
          </Button>
        </div>
      </div>
      {selfManagement === false && (
        /* @ts-ignore */
        <FormikCrmSelector
          teamsToExclude={[currentTeam?.id]}
          selectSingle
          id="sendTo"
          name="sendTo"
          label={t('_company.title')}
          isRequired
          showTeams
          hideTeamsThatCannotManageInsuranceClaims
        />
      )}
      {((selfManagement && repairInKindPossible) ||
        (selfManagement === false && repairInKindPossible)) && (
        <div className="mt-8">
          <h5 style={{ textAlign: 'center' }}>{t('doYouWishToRepairTheClaimByKeypoint')}</h5>
          <div className="mt-8 flex justify-center space-x-4">
            <Button
              onClick={() => handleRepairByKeypoint(true)}
              variant={repairByKeypoint ? 'solid' : 'outlined'}
            >
              {t('yes')}
            </Button>
            <Button
              variant={repairByKeypoint === false ? 'solid' : 'outlined'}
              onClick={() => handleRepairByKeypoint(false)}
            >
              {t('no')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseManagementStep;
