import { Alert, Card, FormControl, FormLabel, Input } from '@mui/joy';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { SimpleBuildingRelation } from '~/common/types/building/building';

import { ContactIcon, SearchIcon } from '@/shared/icons/Icons';
import AvatarWithColor from '@/shared/components/2.0/AvatarWithColor';
import FormHelperText from '@/shared/components/2.0/forms/FormHelperText';

type BuildingContactsSuggestionsProps = {
  callback: (value: SimpleBuildingRelation) => void;
  debounceSearch: (value: string) => void;
  isRequired: boolean;
  filteredBuildingRelations: SimpleBuildingRelation[];
  title: string;
  valueName: string;
};

const BuildingContactsSuggestions: React.FC<BuildingContactsSuggestionsProps> = ({
  callback,
  debounceSearch,
  isRequired = false,
  filteredBuildingRelations,
  title,
  valueName,
}) => {
  const { t } = useTranslation();
  const { values, errors, touched } = useFormikContext();

  const isMatchingId = (id, type) =>
    ((values[type]?.value ?? values[type]) === id && id !== null) ||
    values[valueName]?.value === id;

  return (
    <FormControl>
      <div className="flex justify-between pb-2">
        {title && (
          <FormLabel required className="self-center">
            {t(title)}
          </FormLabel>
        )}
        <Input
          className="rounded-full"
          placeholder={t('search.placeholder')}
          onChange={(e) => debounceSearch(e.target.value)}
          startDecorator={<SearchIcon />}
        />
      </div>
      <div className="grid max-h-[216px] grid-cols-1 gap-2 overflow-y-auto lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {filteredBuildingRelations?.map((relation) => (
          <Card
            key={relation.relationId}
            className={classNames('cursor-pointer self-start p-3', {
              'bg-green-100':
                isMatchingId(relation.contactId, 'contactId') ||
                isMatchingId(relation.companyId, 'companyId'),
            })}
            onClick={() => callback(relation)}
          >
            <div className="flex items-center">
              <AvatarWithColor className="mr-4" name={relation.requestorData.label} size="lg" />
              <div className="flex flex-col space-y-1">
                <p className="line-clamp-1 text-xs text-black no-underline">
                  {relation.requestorData.label}
                </p>
                <p className="line-clamp-1 text-xs text-black no-underline">
                  {t('units')}:{' '}
                  {filteredBuildingRelations.length
                    ? relation.units.map((u) => u.registrationNumber).join(', ')
                    : t('none')}
                </p>
              </div>
            </div>
          </Card>
        ))}

        {!filteredBuildingRelations?.length && (
          <Alert
            size="sm"
            className="col-span-full mt-2 self-start"
            startDecorator={<ContactIcon />}
            color="warning"
          >
            {t('noDataToDisplay')}
          </Alert>
        )}
      </div>

      {isRequired && (
        <FormHelperText
          error={errors.requestor as string | undefined}
          touched={touched.requestor as boolean | undefined}
        />
      )}
    </FormControl>
  );
};

export default BuildingContactsSuggestions;
