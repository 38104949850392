import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import EnumSelector from '../components/select/EnumSelector';

const propTypes = {
  enumValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  translationToUse: PropTypes.string,
};

const FormikEnumSelector = ({
  enumValues,
  label,
  multiple = false,
  name,
  onChange = () => {},
  translationToUse = 'common',
  ...rest
}) => {
  const { values, setValues, errors, touched, setTouched } = useFormikContext();
  return (
    <EnumSelector
      onChange={(selectedValues) => {
        setTouched({ ...touched, [name]: true });

        if (selectedValues == null) {
          setValues((prev) => ({ ...prev, [name]: null }));
          onChange(null);
          return;
        }
        if (multiple) {
          setValues((prev) => ({ ...prev, [name]: selectedValues }));
          onChange(selectedValues);
          return;
        }
        setValues((prev) => ({ ...prev, [name]: selectedValues[0] }));
        onChange(selectedValues[0]);
      }}
      defaultValue={values[name]}
      enumValues={enumValues}
      label={label}
      errorText={touched[name] ? errors[name] : null}
      multiple={multiple}
      translationToUse={translationToUse}
      {...rest}
    />
  );
};

FormikEnumSelector.propTypes = propTypes;

export default FormikEnumSelector;
