import {
  Badge,
  Checkbox,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
} from '@mui/joy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Action } from '@/types/kaTable';
import { ColumnProps } from '@/shared/hooks/table/types';

import actionTypes from '@/redux/actions/actionTypes';

import { ColumnsIcon, ColumnsIconSlash } from '../../icons/Icons';

type Props = {
  columns: ColumnProps[];
  onSelectFilter: (action: Action) => void;
  type?: string;
};

const MultiSelectColumns: React.FC<Props> = ({ columns, onSelectFilter, type }) => {
  const { t } = useTranslation();

  const activeFilterCount = useMemo(
    () =>
      columns.filter((column) => column.visible !== false && column.allowDeselect !== false).length,
    [columns],
  );

  const getLabel = (column: ColumnProps) => {
    const { title, key } = column;

    if (!type && !title) return '';

    return title ? t(title) : t(`_${type}.columns.${key}`);
  };

  return (
    <Dropdown>
      <div className="align-self-end">
        <Badge badgeContent={activeFilterCount} badgeInset={0}>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { color: 'primary', variant: 'outlined' } }}
          >
            <Tooltip title={t('app.filters.columns')}>
              <div>{activeFilterCount ? <ColumnsIcon /> : <ColumnsIconSlash />}</div>
            </Tooltip>
          </MenuButton>
        </Badge>
      </div>
      <Menu placement="bottom-end">
        {columns
          .filter((column) => column.allowDeselect !== false)
          .map((column) => (
            <MenuItem key={column.key}>
              <Checkbox
                checked={column.visible !== false}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  onSelectFilter({
                    payload: {
                      checked: e.target.checked,
                      columnKey: e.target.name,
                    },
                    type: actionTypes.CHANGE_COLUMN_VISIBILITY,
                  })
                }
                name={column.key}
                label={getLabel(column)}
                size="sm"
              />
            </MenuItem>
          ))}
      </Menu>
    </Dropdown>
  );
};

export default MultiSelectColumns;
