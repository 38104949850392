import dayjs, { Dayjs, isDayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useTranslation } from 'react-i18next';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import LanguageCode from '../enums/LanguageCode';
import { Nullable } from '~/common/types';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl-be';

const getLocale = (language: string) =>
  ({
    [LanguageCode.nl]: 'nl-be',
    [LanguageCode.fr]: 'fr',
  }[language] || 'en-gb');

const useDayjs = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = getLocale(language);

  dayjs.extend(localizedFormat);
  dayjs.extend(weekOfYear);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime);
  dayjs.extend(isBetween);
  dayjs.extend(isToday);
  dayjs.extend(utc);
  dayjs.extend(updateLocale);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.updateLocale(locale, {
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  });
  dayjs.locale(locale);

  const formatDate = (dateString: string, format: string | undefined = 'L') =>
    dayjs(dateString).locale(locale).format(format);

  const isBetweenNullable = (date: string, startDate?: string | null, endDate?: string | null) => {
    if (!startDate) {
      return false;
    }

    const today = dayjs(date);
    const start = dayjs(startDate);

    if (start.isSameOrBefore(today)) {
      if (!endDate) {
        return true;
      }

      const end = dayjs(endDate);
      if (end.isSameOrAfter(today)) {
        return true;
      }
    }

    return false;
  };

  const isTodayBetweenNullable = (startDate?: string | null, endDate?: string | null) =>
    isBetweenNullable(dayjs().format('YYYY-MM-DD'), startDate, endDate);

  const max = (first: Dayjs, second: Dayjs): Dayjs => (first > second ? first : second);

  const min = (first: Nullable<Dayjs>, second: Nullable<Dayjs>): Dayjs => {
    if (!second) {
      if (first) {
        return first;
      }

      return dayjs('0001-01-01');
    }

    if (!first) {
      return dayjs('0001-01-01');
    }

    return first < second ? first : second;
  };

  // code copied from the BE where it is unit tested
  const hasOverlap = (
    start1: Nullable<Dayjs>,
    end1: Nullable<Dayjs>,
    start2: Nullable<Dayjs>,
    end2: Nullable<Dayjs>,
  ) => {
    if (!start1) {
      if (start2) {
        // start1 null, start2 not null, only overlap when end1 is not null and later than start2, end2 doesn't matter
        if (!end1) {
          return false;
        }
        return end1 >= start2;
      }

      // start1 and start2 are null, -> overlap exists when end1 and end2 both not null
      return !!end1 && !!end2;
    }

    if (!start2) {
      if (!end2) {
        return false;
      }
      // start1 not null, start2 null, only overlap when end2 is not null and later than start1, end2 doesn't matter
      return end2 >= start1;
    }

    // start1 and start2 are not null, overlap exists when end1 and end2 both null
    if (!end1 && !end2) {
      return true;
    }

    return max(start1, start2) <= min(end1, end2);
  };

  const dateRangesOverlap = (
    start1: Nullable<string>,
    end1: Nullable<string>,
    start2: Nullable<string>,
    end2: Nullable<string>,
  ) => {
    const start1Date = start1 ? dayjs(start1) : null;
    const end1Date = end1 ? dayjs(end1) : null;
    const start2Date = start2 ? dayjs(start2) : null;
    const end2Date = end2 ? dayjs(end2) : null;
    return hasOverlap(start1Date, end1Date, start2Date, end2Date);
  };

  return {
    dateRangesOverlap,
    dayjs,
    formatDate,
    formatDateTime: (dateString: string) => dayjs(dateString).locale(locale).format('L LT'),
    formatRelativeDate: (dateString: string) => dayjs(dateString).locale(locale).fromNow(),
    formatTime: (dateString: string) => dayjs(dateString).locale(locale).format('LT'),
    formatTimeWithSeconds: (dateString: string | Date) =>
      dayjs(dateString).local().utc().format('HH:mm:ss'),
    formatUtcDate: (dateString: string | Date, format = 'L') =>
      dayjs.utc(dateString).local().format(format),
    formatUtcDateTime: (dateString: string) => dayjs.utc(dateString).local().format(),
    formatUtcTime: (timeString: string) =>
      dayjs.utc(timeString, 'HH:mm:ss').local().format('HH:mm'),
    getUtcDateObject: (dateString: string, inputFormat?: string) =>
      dayjs.utc(dateString, inputFormat),
    getWeekOfYear: (dateString: string) => dayjs(dateString).locale(locale).week(),
    isBetween: (date: string, startDate: string, endDate: string) =>
      dayjs(date).isBetween(startDate, endDate, 'day', '[]'),
    isBetweenNullable,
    isFutureDate: (dateString: string) => dayjs(dateString).isAfter(dayjs()),
    isTodayBetweenNullable,
    localeData: dayjs.localeData(),
    toDateOnlyString: (date: Nullable<string | Date | Dayjs>) => {
      if (!date) {
        return null;
      }
      if (isDayjs(date)) {
        return date.format('YYYY-MM-DD');
      }
      return dayjs(date).format('YYYY-MM-DD');
    },
  } as const;
};

export default useDayjs;
