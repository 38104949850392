import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '../../shared/api/cases/case';
import { decapitalize } from '../../shared/utils/helpers';
import useNotification from '../../shared/hooks/UseNotification';

const useDeleteCaseEntity = () => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate } = useMutation({
    mutationFn: async (entity) => caseApi.deleteCaseEntity(entity.caseId, entity.id),
    onError: async (err) => {
      sendDefaultError(err);
    },
    onSuccess: (data, entity) => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyRemoved', {
          type: t(`common:${decapitalize(entity.type)}`),
        }),
        variant: 'success',
      });
    },
  });

  return mutate;
};

export default useDeleteCaseEntity;
