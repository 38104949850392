import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Chip, TextField } from '@mui/material';

import DamageType from '../enums/DamageType';
import DamageTypeIcon from '@/shared/icons/DamageTypeIcon';
import { getDamageTypeTranslation } from '../utils/helpers';

const DamageTypeSelector = ({ name, label, required, multiple, ...rest }) => {
  const { t } = useTranslation('common');

  const { values, setValues, touched, setTouched, errors } = useFormikContext();

  const [selected, setSelected] = useState(multiple ? [] : null);

  const defaultValue = values[name];

  useEffect(() => {
    if (multiple) {
      if (defaultValue == null || !defaultValue.length) {
        setSelected([]);
        return;
      }
      setSelected(Object.values(DamageType).filter((val) => defaultValue.includes(val)) ?? []);
      return;
    }
    setSelected(Object.values(DamageType).find((val) => val === defaultValue) ?? null);
  }, [defaultValue, multiple]);

  const getDamageTypeItem = (damageType) => (
    <>
      <i className="me-2 pb-1">
        <DamageTypeIcon damageType={damageType} />
      </i>
      {getDamageTypeTranslation(t, damageType)}
    </>
  );

  const getOption = (caseType) => {
    if (caseType == null) {
      return null;
    }
    return getDamageTypeItem(caseType);
  };

  const getOptionRender = (props, caseType) => <li {...props}>{getOption(caseType)}</li>;

  const errorText = touched[name] ? errors[name] : null;

  const getValue = () => {
    if (multiple) {
      return selected ?? [];
    }
    if (typeof selected === 'string' && selected.length === 0) {
      return null;
    }

    return selected ?? null;
  };

  return (
    <div>
      <Autocomplete
        noOptionsText={t('common:noOptions')}
        value={getValue()}
        options={Object.values(DamageType)}
        getOptionLabel={(option) => getDamageTypeTranslation(t, option)}
        fullWidth
        multiple={multiple}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} label={getOption(option)} key={option} />
          ))
        }
        renderOption={getOptionRender}
        renderInput={(params) => (
          <TextField
            label={required ? `${label} *` : label}
            variant="standard"
            fullWidth
            error={errorText != null && errorText.length > 0}
            helperText={errorText}
            {...params}
          />
        )}
        onChange={(e, selectedValues) => {
          setTouched({ ...touched, [name]: true });

          if (selectedValues == null) {
            setValues((prev) => ({ ...prev, [name]: null }));
            return;
          }

          setValues((prev) => ({ ...prev, [name]: selectedValues }));
        }}
        {...rest}
      />
    </div>
  );
};

DamageTypeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
};

DamageTypeSelector.defaultProps = {
  required: false,
  multiple: false,
};

export default DamageTypeSelector;
