import { Autocomplete, FormControl, FormLabel } from '@mui/joy';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import useDocumentTypeOptions from '@/shared/hooks/useDocumentTypeOptions';

import FormikTextField from '../../2.0/formik/FormikTextField';

type FormikValues = {
  type?: string;
};

const DocumentUpdateForm = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const documentTypeSuggestions = useDocumentTypeOptions();
  const mappedDocumentTypes = documentTypeSuggestions.map((suggestion) => ({
    label: suggestion,
    value: suggestion,
  }));

  return (
    <div className="py-4">
      <FormControl>
        <FormLabel>{t('type')}</FormLabel>
        <Autocomplete
          freeSolo
          forcePopupIcon
          value={
            mappedDocumentTypes.find((docType) => docType.value === values.type) || values.type
          }
          options={mappedDocumentTypes}
          onInputChange={(_, value) => {
            setFieldValue('type', value);
          }}
        />
      </FormControl>
      {/* @ts-ignore */}
      <FormikTextField name="fileName" label={t('fileName')} />
    </div>
  );
};

export default DocumentUpdateForm;
