import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import API from '../../../../../../shared/api/ApiService';
import CasePropTypes from '../../../../../../shared/prop-types/CasePropTypes';
import { isNullOrEmpty } from '../../../../../../shared/utils/helpers';
import useNotification from '../../../../../../shared/hooks/UseNotification';

import { SaveIcon, SkipIcon } from '../../../../../../shared/icons/Icons';
import InlineEditInput from '../../../../../../shared/components/inputs/InlineEditInput';
import ScheduleRepairStartFormBody from './ScheduleRepairStartFormBody';

const ScheduleRepairStart = ({
  currentCase,
  isCaseOwner,
  isContractor,
  worksiteRegistrationNbr,
  contractorCanEdit,
  nextStep,
}) => {
  const { t } = useTranslation('common');
  const { sendNotification, sendDefaultError } = useNotification();
  const handleSaveStartOfWork = async (formData, isFirstAppointment) => {
    let response;
    if (isFirstAppointment) {
      const firstAppointment = {
        repairStartAmPm: currentCase.appointmentAmPm,
        repairStartDate: currentCase.appointmentDate,
        repairStartTime: currentCase.appointmentTime,
      };
      response = await API.putUpdateRepairStart({ caseId: currentCase.id, ...firstAppointment });
    } else {
      const { repairStartAmPm, repairStartTime, repairStartDate } = formData;
      const amPm = repairStartAmPm ?? (repairStartTime == null ? false : null);
      response = await API.putUpdateRepairStart({
        caseId: currentCase.id,
        repairStartAmPm: amPm,
        repairStartDate,
        repairStartTime,
      });
    }

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:startOfWorks') }),
      variant: 'success',
    });
    nextStep();
  };

  const handleSaveWorkSiteRegistrationNumber = async (workSiteRegistrationNumber) => {
    const response = await API.putUpdateWorkSiteRegistrationNumber({
      caseId: currentCase.id,
      workSiteRegistrationNumber,
    });

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:worksiteRegistrationNbr') }),
      variant: 'success',
    });
  };

  const startDateValues = {
    repairStartAmPm: currentCase.repairStartAmPm,
    repairStartDate: currentCase.repairStartDate,
    repairStartTime: currentCase.repairStartTime,
  };

  const firstAppointmentValues = {
    repairStartAmPm: currentCase.appointmentForSpecifications ? null : currentCase.appointmentAmPm,
    repairStartDate: currentCase.appointmentForSpecifications ? null : currentCase.appointmentDate,
    repairStartTime: currentCase.appointmentForSpecifications ? null : currentCase.appointmentTime,
  };
  return (
    <div className="mb-1 ms-3 mt-1">
      {!isCaseOwner && !isContractor && !currentCase.repairStartDate && (
        <p className="color-keypoint">{t('common:worksNeedToBeRegistered')}</p>
      )}
      {(isCaseOwner || isContractor || currentCase.repairStartDate) && (
        <>
          <h4 className="pb-3">
            {currentCase.appointmentDate == null
              ? t('common:registerStartOfWorks')
              : t('common:editStartOfWorks')}
          </h4>
          <Formik
            initialValues={currentCase.repairStartDate ? startDateValues : firstAppointmentValues}
            validationSchema={yup.object().shape({
              repairStartAmPm: yup.bool().nullable(),
              repairStartDate: yup.string().nullable().required(t('errors:fieldIsRequired')),
              repairStartTime: yup.string().nullable(),
            })}
            onSubmit={(formData) => handleSaveStartOfWork(formData, false)}
          >
            <Form>
              <ScheduleRepairStartFormBody
                disabled={!isCaseOwner && !contractorCanEdit}
                firstAppointmentDate={currentCase.appointmentDate}
              />
              {isCaseOwner ? (
                <Row>
                  <InlineEditInput
                    initialValue={worksiteRegistrationNbr}
                    label={t('common:worksiteRegistrationNbr')}
                    onSave={handleSaveWorkSiteRegistrationNumber}
                  />
                </Row>
              ) : (
                <p>
                  {t('common:worksiteRegistrationNbr')}:{' '}
                  {isNullOrEmpty(worksiteRegistrationNbr) ? '-' : worksiteRegistrationNbr}
                </p>
              )}
              {isContractor && (
                <p className="color-keypoint mt-3">{t('common:agreeWithTheClient')}</p>
              )}

              {(isCaseOwner || isContractor) && (
                <div className="flex justify-end space-x-4">
                  {currentCase.repairStartDate == null &&
                    !currentCase.appointmentForSpecifications && (
                      <Button
                        startDecorator={<SkipIcon />}
                        color="neutral"
                        variant="outlined"
                        onClick={() => handleSaveStartOfWork(null, true)}
                      >
                        {t('common:workDoneFirstAppointment')}
                      </Button>
                    )}
                  <Button startDecorator={<SaveIcon />} type="submit">
                    {currentCase.repairStartDate == null ? t('common:register') : t('common:edit')}
                  </Button>
                </div>
              )}
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
};

ScheduleRepairStart.propTypes = {
  contractorCanEdit: PropTypes.bool,
  currentCase: CasePropTypes.isRequired,
  isCaseOwner: PropTypes.bool.isRequired,
  isContractor: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  worksiteRegistrationNbr: PropTypes.string,
};

ScheduleRepairStart.defaultProps = {
  contractorCanEdit: false,
  worksiteRegistrationNbr: null,
};

export default ScheduleRepairStart;
