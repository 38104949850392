import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CasePaymentType } from '~/common/enums';
import { DeepNullable } from '~/common/types';
import { RouteParams } from '@/types';
import { UseToggle } from '@/shared/hooks/UseToggle';

import AddEditPaymentForm from './AddEditPaymentForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type CasePayment = {
  amount?: number;
  caseId?: string;
  description?: string;
  id: string;
  linkedPaymentId?: string;
  relationId?: string;
  type?: CasePaymentType;
};

type CasePaymentValues = DeepNullable<CasePayment>;

type Props<T> = {
  inbound?: boolean;
  inboundPayments: CasePayment[];
  initialPayment?: CasePaymentValues;
  onSubmit: (values: CasePaymentValues) => void;
  toggle: UseToggle<T>;
};

const AddEditPaymentModal = <T,>({
  toggle,
  onSubmit,
  initialPayment,
  inbound = false,
  inboundPayments,
}: Props<T>) => {
  const { t } = useTranslation();
  const { caseId } = useParams() as RouteParams;

  return (
    <KpcModal
      title={
        initialPayment
          ? t('editType', { type: t('payment') })
          : t('addType', {
              type: inbound ? t('inboundPayment') : t('outboundPayment'),
            })
      }
      toggle={toggle}
    >
      <AddEditPaymentForm
        onSubmit={onSubmit}
        initialPayment={initialPayment}
        onCancel={toggle.hide}
        inbound={inbound}
        caseId={caseId}
        inboundPayments={inboundPayments}
      />
    </KpcModal>
  );
};

export default AddEditPaymentModal;
