import { Checkbox, FormControl, IconButton, Textarea } from '@mui/joy';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { InlineSpinner, SendIcon } from '@/shared/icons/Icons';

const minRows = 1;
const maxRows = 4;

type Note = {
  message: string;
  showOnClientPage: boolean;
};

type Props = {
  isLoading: boolean;
};

const ChatNewMessageForm: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation();
  const { values, handleSubmit, setFieldValue } = useFormikContext<Note>();

  return (
    <Form className="sticky bottom-0 space-y-4 border-t border-gray-300 bg-white px-4 py-4">
      <FormControl className="flex-row space-x-2">
        <Textarea
          className="grow rounded-2xl pt-1.5 text-xs"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
              e.preventDefault();
            }
          }}
          minRows={minRows}
          maxRows={maxRows}
          onChange={(e) => {
            setFieldValue('message', e.target.value);
          }}
          placeholder={t('typeHere')}
          value={values.message}
        />
        <IconButton disabled={isLoading} className="self-center" onClick={() => handleSubmit()}>
          {isLoading ? <InlineSpinner /> : <SendIcon />}
        </IconButton>
      </FormControl>

      <FormControl>
        <Checkbox
          checked={!!values.showOnClientPage}
          onChange={(e) => setFieldValue('showOnClientPage', e.target.checked)}
          label={t('showOnClientPage')}
        />
      </FormControl>
    </Form>
  );
};

export default ChatNewMessageForm;
