import { Box } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import HeroLeft01 from './Blocks/HeroLeft01';
import HeroLeft02 from './Blocks/HeroLeft02';
import mergedTheme from '../theme';

import teaser from '~/frontend/shared/img/teaser.png';
import teaser2 from '~/frontend/shared/img/teaser2.png';

library.add(fad);

const Start = () => (
  <CssVarsProvider disableTransitionOnChange theme={mergedTheme}>
    <Box
      sx={{
        '& > div': {
          scrollSnapAlign: 'start',
        },
        height: '100vh',
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
      }}
    >
      <HeroLeft01 teaser={teaser} />
      <HeroLeft02 teaser={teaser2} />
    </Box>
  </CssVarsProvider>
);

export default Start;
