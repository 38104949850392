import { Alert, Tooltip } from '@mui/joy';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';
import { sortTasksByPriority } from '../../../../shared/utils/helpers';

import { TaskIcon, WarningIcon } from '../../../../shared/icons/Icons';
import { CaseContext } from '../../CaseContext';
import { trpc } from '@/config/trpc';
import useDayjs from '@/shared/hooks/useDayjs';

const TaskAlert = () => {
  const { currentTeamUser } = useContext(AppContext);
  const { currentCase } = useContext(CaseContext);
  const { t } = useTranslation();
  const { dayjs, formatDate, formatRelativeDate } = useDayjs();

  const { data: caseTasks } = trpc.task.caseTasks.useQuery({ caseId: currentCase.id });

  const taskToDisplay = useMemo(() => {
    if (caseTasks && caseTasks.length && currentTeamUser) {
      const assignedTasks = caseTasks
        .filter(
          (task) =>
            task.taskUsers.some((tu) => tu.isAssigned && tu.teamUserId === currentTeamUser.id) &&
            !task.completedDate,
        )
        .sort(sortTasksByPriority)
        .sort((a, b) => dayjs(a.dueDate).diff(dayjs(b.dueDate)));
      return assignedTasks[0];
    }

    return null;
  }, [dayjs, caseTasks, currentTeamUser]);

  const taskDueDate = formatDate(taskToDisplay?.dueDate);

  return (
    taskToDisplay && (
      <Alert
        startDecorator={taskDueDate <= dayjs() && <WarningIcon />}
        className="mb-4 items-center justify-center self-center rounded-xl bg-[#f8e3d2] text-[#fd811d]"
      >
        <div className="flex items-center">
          <Tooltip title={taskDueDate <= dayjs() ? t('dueDateHasBeenPassed') : ''}>
            <span>
              <TaskIcon style={{ marginRight: '10px' }} />
            </span>
          </Tooltip>
          <span className="inline-block max-w-5xl truncate">{taskToDisplay.description}</span>
          <Tooltip title={taskDueDate}>
            <span className="ml-1"> - {formatRelativeDate(taskToDisplay?.dueDate)} </span>
          </Tooltip>
        </div>
      </Alert>
    )
  );
};

export default TaskAlert;
