import { useQuery } from '@tanstack/react-query';

import { enumApi } from '../../shared/api';
import { queryIds } from '../../shared/utils/constants';
import useNotification from '../../shared/hooks/UseNotification';

import { formatDataForAutoComplete } from '../../shared/utils/helpers';

const useGetCountries = () => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery({
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: async () => {
      const result = await enumApi.getCountries();

      const formatted = formatDataForAutoComplete({
        data: result,
        label: 'name',
        value: 'code',
      });

      return { formatted, result };
    },
    queryKey: [queryIds.enums.GET_COUNTRIES],
  });

  return {
    countries: data?.result || [],
    countriesAutocomplete: data?.formatted || [],
    countriesIsLoading: isLoading,
  };
};

export default useGetCountries;
