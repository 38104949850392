import { useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { AddEditAppointmentFormikValues } from '../FormikValues';
import AsyncFormikSelector from '@/shared/components/2.0/formik/AsyncFormikSelector';
import { AutocompleteOption } from '~/common/types';
import { CalendarEvent } from '~/common/types/calendars/events';
import { caseApi } from '~/frontend/shared/api';
import FormikCalendarSelector from '@/shared/components/2.0/formik/FormikCalendarSelector';
import FormikCaseEntityOptions from '@/shared/components/2.0/formik/FormikCaseEntityOptions';
import FormikCheckbox from '@/shared/components/2.0/formik/FormikCheckbox';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikDateTimePicker from '@/shared/components/2.0/formik/FormikDateTimePicker';
import FormikRichTextEditor from '@/shared/components/2.0/formik/FormikRichTextEditor';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import { queryIds } from '~/frontend/shared/utils/constants';
import useDayjs from '@/shared/hooks/useDayjs';
import { useGetCase } from '@/queries';

type Dates = {
  end: string | null;
  start: string | null;
};

type Props = {
  showLinkedCase?: boolean;
  initialAppointment?: CalendarEvent;
};

const ALL_DAY_FORMAT = 'YYYY-MM-DD';
const DEFAULT_APPOINTMENT_DURATION_IN_MIN = 30;

const AddEditAppointmentFormBody: React.FC<Props> = ({
  initialAppointment,
  showLinkedCase = false,
}) => {
  const intialDateTimes = (() => {
    const dateTimes: Dates = {
      end: null,
      start: null,
    };

    if (initialAppointment && !initialAppointment.allDay) {
      dateTimes.end = initialAppointment.endTime || null;
      dateTimes.start = initialAppointment.startTime || null;
    }

    return dateTimes;
  })();

  const prevDateTimesRef = useRef<Dates>(intialDateTimes);
  const prevEndDatePrefillRef = useRef<string | null>(null);

  const { dayjs } = useDayjs();
  const { t } = useTranslation();
  const { values, initialValues, setFieldValue } =
    useFormikContext<AddEditAppointmentFormikValues>();
  const { currentCase, currentCaseIsLoading } = useGetCase(values.caseId);
  const { getUtcDateObject } = useDayjs();

  const caseOption = currentCase
    ? {
        data: {
          description: currentCase.description,
        },
        label: currentCase.currentTeamReference,
        value: currentCase.id,
      }
    : undefined;

  // time zone not saved in BE for all day
  const handleAllDayChange = (checked: boolean) => {
    const getDate = (key: 'start' | 'end') => {
      const dateValue = values[key];

      if (checked) return dateValue ? dayjs(dateValue).format(ALL_DAY_FORMAT) : null;

      const prevDate = prevDateTimesRef.current[key] || new Date();

      return dayjs.utc(prevDate).toISOString();
    };

    const newEndDate = getDate('end');
    const newStartDate = getDate('start');

    setFieldValue('start', newStartDate);
    setFieldValue('end', newEndDate === newStartDate && checked ? null : newEndDate);
  };

  const handleDateChange = useCallback(
    (key: 'start' | 'end', value: string | null) => {
      if (!values.allDay && value) {
        prevDateTimesRef.current = {
          ...prevDateTimesRef.current,
          [key]: value,
        };
      }
    },
    [values.allDay],
  );

  const handleStartDateChange = useCallback(
    (value: string | null) => {
      handleDateChange('start', value);

      if (!value) prevEndDatePrefillRef.current = null;

      if (!value || value === initialValues.start || values.end || values.allDay) return;

      const newEnd = getUtcDateObject(value)
        .add(DEFAULT_APPOINTMENT_DURATION_IN_MIN, 'm')
        .toISOString();

      if (newEnd !== prevEndDatePrefillRef.current) {
        setFieldValue('end', newEnd);

        prevEndDatePrefillRef.current = newEnd;
      }
    },
    [
      getUtcDateObject,
      handleDateChange,
      initialValues.start,
      setFieldValue,
      values.allDay,
      values.end,
    ],
  );

  const handleEndDateChange = useCallback(
    (value: string | null) => handleDateChange('end', value),
    [handleDateChange],
  );

  useEffect(() => {
    handleEndDateChange(values.end);
  }, [handleEndDateChange, values.end]);

  useEffect(() => {
    handleStartDateChange(values.start);
  }, [handleStartDateChange, values.start]);

  return (
    <>
      <div className="mb-3 flex space-x-4">
        {!initialValues.linkedCalendarId && (
          <div className="w-1/2 flex-initial">
            <FormikCalendarSelector name="linkedCalendarId" label={t('common:calendar')} />
          </div>
        )}

        <div
          className={`${
            !initialValues.linkedCalendarId ? 'w-64 flex-initial' : 'w-1/3 flex-initial'
          }`}
        >
          {values.allDay ? (
            <FormikDatePicker
              name="start"
              label={t('startDate')}
              required
              clearable
              options={{
                format: ALL_DAY_FORMAT,
              }}
            />
          ) : (
            <FormikDateTimePicker name="start" label={t('startDate')} required />
          )}
        </div>
        <div
          className={`${
            !initialValues.linkedCalendarId ? 'w-64 flex-initial' : 'w-1/3 flex-initial'
          }`}
        >
          {values.allDay ? (
            <FormikDatePicker
              name="end"
              label={t('endDate')}
              clearable
              options={{
                format: ALL_DAY_FORMAT,
              }}
            />
          ) : (
            <FormikDateTimePicker name="end" label={t('endDate')} required />
          )}
        </div>
        <div className={`${!initialValues.linkedCalendarId ? 'w-64 flex-initial' : '2'} mt-auto`}>
          <FormikCheckbox
            callback={handleAllDayChange}
            size="md"
            name="allDay"
            label={t('allDay')}
          />
        </div>
      </div>
      <FormikTextField className="mb-3" name="title" required label={t('title')} />
      <div className="mb-3">
        <FormikRichTextEditor name="description" label={t('common:description')} />
      </div>
      {(!currentCase || showLinkedCase) && (
        <AsyncFormikSelector
          defaultValue={!currentCaseIsLoading ? (caseOption as AutocompleteOption) : undefined}
          name="caseId"
          label={t('case_one')}
          queryFn={caseApi.getCasesAutocomplete}
          queryId={queryIds.cases.GET_CASES_AUTOCOMPLETE}
        />
      )}
      {!!currentCase && (
        <FormikCaseEntityOptions
          // @ts-ignore
          selectedCase={currentCase}
          // @ts-ignore
          entity={initialAppointment}
          shouldNotBeShownOnPublicPage
        />
      )}
    </>
  );
};

export default AddEditAppointmentFormBody;
