import { DetailedHTMLProps, HTMLAttributes, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Case } from '@/types/cases';
import CaseType from '@/shared/enums/CaseType';
import { Task } from '~/common/types/task/task';

import { distinct, formatDate } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { useTaskActions } from '@/mutations';

import { CompletedIcon, TaskIcon } from '@/shared/icons/Icons';
import IconButton from '@/shared/components/buttons/IconButton';

type Props = {
  task: Task;
  currentCase?: Case;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const FinishTaskButton: React.FC<Props> = ({ task, currentCase, ...rest }) => {
  const { currentTeam } = useContext(AppContext);
  const { className } = rest;
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  const { finishTask } = useTaskActions();

  const isTaskFinished = !!task.completedBy?.length || !!task.completedDate;

  const getIcon = () => {
    if (isTaskFinished || hover) {
      return <CompletedIcon />;
    }
    return <TaskIcon />;
  };

  const isDisabled = useMemo(() => {
    if (task == null) return true;
    if (isTaskFinished) return true;

    if (
      currentCase &&
      currentTeam &&
      currentCase.caseType === CaseType.Repair &&
      currentTeam.id === currentCase.relations.find((r) => r.isOwner)?.teamId
    ) {
      return false;
    }

    const taskExecutors = task.taskUsers;
    const executorTeams = taskExecutors.map((te) => te.teamId).filter(distinct);
    return !(currentTeam?.id && executorTeams.includes(currentTeam.id));
  }, [task, isTaskFinished, currentCase, currentTeam]);

  const getTaskTooltipInfo = () => {
    if (!isTaskFinished) return t('finish');

    if (task.completedByUsername) {
      return `${task.completedByUsername}, ${formatDate(task.completedDate)}`;
    }

    return `${t('digitalAssistantVera')}, ${formatDate(task.completedDate)}`;
  };

  return (
    task != null && (
      <div {...rest} className={`finish-task-button-wrapper ${className}`}>
        <IconButton
          key={task.id}
          className={`finish-task-button ${isTaskFinished ? 'task-finished' : ''}`}
          iconComponent={getIcon()}
          tooltip={getTaskTooltipInfo()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => finishTask(task.id)}
          disabled={isDisabled}
        />
      </div>
    )
  );
};

export default FinishTaskButton;
