import { FormHelperText as MuiJoyFormHelperText } from '@mui/joy';

type Props = {
  error?: string;
  touched?: boolean;
};

const FormHelperText = ({ error = undefined, touched = undefined }: Props) => (
  <MuiJoyFormHelperText>
    {error && touched && <p className="px-1 text-xs italic text-red-500">{touched && error}</p>}
  </MuiJoyFormHelperText>
);

export default FormHelperText;
