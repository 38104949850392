import { IconButton, Tooltip } from '@mui/joy';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import { HideFiltersIcon, MoreFiltersIcon } from '../../../icons/Icons';
import useToggleHeight from '@/shared/hooks/useToggleHeight';

type Props<T> = {
  className?: string;
  toggle: UseToggle<T> | ReturnType<typeof useToggleHeight>;
};

const FiltersToggleButton = <T,>({ toggle, className }: Props<T>) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={toggle.value ? t('app.filters.toggleHide') : t('app.filters.toggleShow')}>
      <IconButton
        className={classNames(className, 'align-self-end')}
        size="sm"
        onClick={toggle.inverse}
      >
        {toggle.value ? <HideFiltersIcon /> : <MoreFiltersIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default FiltersToggleButton;
