import { getIn, useFormikContext } from 'formik';

import CurrencyInput from '../components/inputs/CurrencyInput';

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  onlyNegative?: boolean;
  onChange?: (value: number) => void;
  required?: boolean;
  allowNegative?: boolean;
};

const FormikCurrencyInput: React.FC<Props> = ({
  name,
  label,
  disabled = false,
  max,
  onChange,
  required = false,
  allowNegative = false,
  onlyNegative = false,
  ...rest
}) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();

  return (
    <CurrencyInput
      allowNegative={onlyNegative ?? allowNegative}
      callback={(amount) => {
        setFieldTouched(name);
        let value = amount;
        if (max) {
          value = amount > max ? max : amount;
        }

        if (onlyNegative && value > 0) {
          value = -value;
        }
        setFieldValue(name, value);
        onChange?.(value);
      }}
      currencyValue={getIn(values, name)}
      label={label}
      disabled={disabled}
      errorText={getIn(touched, name) ? getIn(errors, name) : null}
      required={required}
      {...rest}
    />
  );
};

export default FormikCurrencyInput;
