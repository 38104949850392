import { Autocomplete, Badge, Checkbox, TextField } from '@mui/joy';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import CaseRelationPropTypes from '@/shared/prop-types/CaseRelationPropTypes';
import { CrmOptionType } from '~/common/enums';
import { getAddressString } from '@/shared/utils/helpers';
import Language from '@/shared/enums/Language';
import { PhoneNumberType } from '@/shared/components/phoneNumber/InternationalPhoneNumber';
import { trpc } from '@/config/trpc';
import useGetEnums from '@/queries/enums/useGetEnums';
import useGetSimpleBuildingRelations from '@/queries/buildings/useGetSimpleBuildingRelations';
import { useSearchFilter } from '@/shared/hooks/UseFilters';
import useToggle from '@/shared/hooks/UseToggle';

import BuildingContactsSuggestions from '@/containers/Cases/BuildingContactsSuggestions';
import FormikAddressForm from '@/shared/formik/FormikAddressForm';
import FormikAutocomplete from '@/shared/formik/FormikAutocomplete';
import FormikCheckbox from '@/shared/components/2.0/formik/FormikCheckbox';
import FormikCrmSelectorJoy from '@/shared/components/2.0/formik/FormikCrmSelectorJoy';
import FormikEmailInput from '@/shared/formik/FormikEmailInput';
import FormikEnumSelector from '@/shared/formik/FormikEnumSelector';
import FormikIbanInput from '@/shared/formik/FormikIbanInput';
import FormikInternationalPhoneNumberInput from '@/shared/components/2.0/formik/FormikInternationalPhoneNumberInput';
import FormikTextField from '@/shared/formik/FormikTextField';
import FormikVatInput from '@/shared/formik/FormikVatInput';

const propTypes = {
  buildingId: PropTypes.string,
  caseRelationToUpdate: CaseRelationPropTypes,
  createCaseAskForClient: PropTypes.bool,
  currentCaseRelations: PropTypes.arrayOf(CaseRelationPropTypes),
  editMode: PropTypes.bool,
  minified: PropTypes.bool,
};

const AddCaseRelationFormBody = ({
  buildingId = null,
  currentCaseRelations = null,
  editMode = false,
  caseRelationToUpdate = [],
  createCaseAskForClient = false,
  minified = false,
}) => {
  const { t } = useTranslation(['common', 'errors']);
  const { setValues, resetForm, values, setFieldValue } = useFormikContext();
  const [selectedCrmInfo, setSelectedCrmInfo] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const { data: contact } = trpc.contact.byId.useQuery(selectedContactId, {
    enabled: !!selectedContactId && selectedType === CrmOptionType.Contact,
  });
  const { data: company } = trpc.company.byId.useQuery(selectedCompanyId, {
    enabled:
      !!selectedCompanyId &&
      (selectedType === CrmOptionType.Company || selectedType === CrmOptionType.Team),
  });

  const buildingContactsToggle = useToggle();

  const { relations: buildingRelations } = useGetSimpleBuildingRelations(buildingId);

  const { debounceSearch, search } = useSearchFilter({ defaultSearch: '', startQueryLength: 0 });

  const otherCaseRelations = useMemo(() => {
    if (caseRelationToUpdate?.id == null) {
      return [];
    }
    return currentCaseRelations.filter((rel) => rel.id !== caseRelationToUpdate.id);
  }, [currentCaseRelations, caseRelationToUpdate]);

  const relationIdsToExclude = useMemo(
    () =>
      currentCaseRelations
        .flatMap((rel) => [rel.contactId, rel.companyId, rel.teamId])
        .filter((id) => id),
    [currentCaseRelations],
  );

  useEffect(() => {
    if (!editMode || !caseRelationToUpdate) {
      return;
    }
    setSelectedCrmInfo({
      type: caseRelationToUpdate.relationType,
    });
  }, [editMode, caseRelationToUpdate]);

  const prefillContactData = (contactInfo, crmOption) => {
    const payload = {
      ...values,
      addressLine: contactInfo?.address?.addressLine ?? '',
      city: contactInfo?.address?.city ?? '',
      companyId: null,
      contactId: contactInfo?.id,
      country: contactInfo?.address?.country ?? null,
      crmSelector: crmOption,
      emailAddresses: contactInfo?.emailAddresses?.map((address) => address.email),
      firstName: contactInfo.firstName ?? '',
      gender: contactInfo?.gender,
      iban: contactInfo?.iban,
      language: contactInfo?.language ?? Language.Dutch,
      lastName: contactInfo?.lastName ?? '',
      mobilePhoneNumber: {
        countryCode: contactInfo?.mobilePhoneNumber?.countryCode,
        number: contactInfo?.mobilePhoneNumber?.number,
      },
      phoneNumber: {
        countryCode: contactInfo?.phoneNumber?.countryCode,
        number: contactInfo?.phoneNumber?.number,
      },
      postalCode: contactInfo?.address?.postalCode ?? '',
      role: contactInfo?.defaultCaseRole,
      teamId: null,
    };
    setValues(payload);
  };

  const [companyAddresses, setCompanyAddresses] = useState();

  const prefillCompanyData = (companyInfo, crmOption, hasTeam = false) => {
    const payload = {
      ...values,
      addressLine: companyInfo?.companyAddresses?.[0]?.address?.addressLine ?? '',
      city: companyInfo?.companyAddresses?.[0]?.address?.city ?? '',
      // if there is a team do not send companyId
      companyId: hasTeam ? null : companyInfo?.companyId ?? companyInfo.id,
      companyName: companyInfo?.displayName ?? companyInfo.companyName,
      contactId: null,
      country: companyInfo?.companyAddresses?.[0]?.address?.country ?? null,
      crmSelector: crmOption,
      emailAddresses: companyInfo?.emailAddresses?.map((address) => address.email),
      iban: companyInfo?.iban ?? '',
      language: companyInfo?.language ?? Language.Dutch,
      phoneNumber: {
        countryCode: companyInfo?.phoneNumber?.countryCode,
        number: companyInfo?.phoneNumber?.number,
      },
      postalCode: companyInfo?.companyAddresses?.[0]?.address?.postalCode ?? '',
      role: companyInfo?.defaultCaseRole,
      // if there is a team send teamId
      teamId: hasTeam ? crmOption?.value : null,
      vatnumber: companyInfo?.vatNumber ?? '',
    };
    setValues(payload);
  };

  const reset = () => {
    resetForm();
    setCompanyAddresses(null);
  };

  useEffect(() => {
    if (!contact) {
      reset();
      return;
    }

    prefillContactData(contact, selectedCrmInfo);
  }, [contact]);

  useEffect(() => {
    if (!company) {
      reset();
      return;
    }

    prefillCompanyData(company, selectedCrmInfo, selectedType === CrmOptionType.Team);
  }, [company]);

  const checkCrmSelection = async (crmOption) => {
    reset();
    setSelectedCrmInfo(crmOption);
    if (!crmOption) {
      return;
    }
    const { value, type, data } = crmOption;

    switch (type) {
      case CrmOptionType.Contact:
        setSelectedContactId(value);
        break;
      case CrmOptionType.Company:
        setSelectedCompanyId(value);
        break;
      case CrmOptionType.Team:
        setSelectedCompanyId(data.companyId);
        break;
      default:
        break;
    }

    setSelectedType(type);
  };

  const {
    enums: { languages, genders, caseInvolvementTypes, caseRoles },
  } = useGetEnums();

  const prefillAddress = (e, val) => {
    if (val == null) {
      setFieldValue('addressLine', '');
      setFieldValue('postalCode', '');
      setFieldValue('city', '');
      setFieldValue('country', null);
      return;
    }
    const { addressLine, postalCode, city, country } = val;
    setFieldValue('addressLine', addressLine ?? '');
    setFieldValue('postalCode', postalCode ?? '');
    setFieldValue('city', city ?? '');
    setFieldValue('country', country ?? null);
  };

  const getPossibleSubordinateValues = () => {
    const subordinates = currentCaseRelations.filter((rel) =>
      currentCaseRelations.some(
        (other) => other.id === rel.parentRelationId || other.id === rel.subordinate,
      ),
    );
    return otherCaseRelations.filter((rel) => !subordinates.some((sub) => sub.id === rel.id));
  };

  const filteredBuildingRelations = useMemo(() => {
    const query = search.toLowerCase();

    return buildingRelations.filter((relation) => {
      const isExcluded = currentCaseRelations.some(
        (caseRelation) =>
          (relation.contactId !== null && caseRelation.contactId === relation.contactId) ||
          (relation.companyId !== null && caseRelation.companyId === relation.companyId),
      );

      if (isExcluded) {
        return false;
      }

      if (
        relation.units &&
        relation.units.some((unit) => unit.info?.toLowerCase().includes(query))
      ) {
        return true;
      }

      return relation.displayName.toLowerCase().includes(query);
    });
  }, [buildingRelations, currentCaseRelations, search]);

  return (
    <>
      {!editMode && (
        <>
          {buildingId && (
            <div className="mb-3 flex items-center justify-between">
              <div className="flex-grow-0" />
              <Badge badgeContent={filteredBuildingRelations?.length || null}>
                <Checkbox
                  checked={buildingContactsToggle.value}
                  disabled={!filteredBuildingRelations?.length}
                  label={t('suggestedContactsPerBuilding')}
                  className="items-center text-xs"
                  onChange={() => {
                    buildingContactsToggle.inverse();
                    reset();
                  }}
                />
              </Badge>
            </div>
          )}

          {!buildingContactsToggle.value && (
            <FormGroup className="mb-3">
              <FormikCrmSelectorJoy
                name="crmSelector"
                label={t('clickHereToAddInvolvedParty')}
                callback={checkCrmSelection}
                excludeIds={relationIdsToExclude}
                includeTeams
                isRequired
              />
            </FormGroup>
          )}

          {buildingContactsToggle.value && !!buildingRelations.length && (
            <BuildingContactsSuggestions
              callback={(relation) => {
                checkCrmSelection({
                  crmType: relation.contactId ? CrmOptionType.Contact : CrmOptionType.Company,
                  label: relation.displayName,
                  type: relation.contactId ? CrmOptionType.Contact : CrmOptionType.Company,
                  value: relation.contactId ?? relation.companyId,
                });
              }}
              debounceSearch={debounceSearch}
              filteredBuildingRelations={filteredBuildingRelations}
              valueName="contactId"
            />
          )}
        </>
      )}
      <FormGroup
        className="mb-3"
        hidden={otherCaseRelations == null || otherCaseRelations.length === 0 || minified}
      >
        <FormikAutocomplete
          id="subordinate"
          name="subordinate"
          isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value}
          options={getPossibleSubordinateValues()}
          getOptionLabel={(opt) => {
            if (opt === null) {
              return '';
            }
            if (opt.displayName != null) {
              return opt.displayName;
            }
            const tryRel = otherCaseRelations.find((clas) => clas.id === opt);
            return tryRel?.displayName ?? '';
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.displayName}
            </li>
          )}
          label={t('common:subordinateTo')}
          getValue={(val) => val?.id ?? val}
        />
      </FormGroup>
      <FormGroup
        className="mb-3"
        hidden={selectedCrmInfo?.type !== CrmOptionType.Company || minified}
      >
        <FormikVatInput name="vatnumber" defaultValue={values.vatnumber} />
      </FormGroup>
      <FormGroup className="m-3">
        <Row>
          <Col>
            <FormikCheckbox name="isRequestor" label={t('common:isRequestor')} />
          </Col>
          {createCaseAskForClient && (
            <Col>
              <FormikCheckbox name="isClient" label={t('common:isClient')} />
            </Col>
          )}
        </Row>
      </FormGroup>
      {!minified && (
        <>
          <FormGroup className="mb-3" hidden={!selectedCrmInfo}>
            <FormikEnumSelector
              name="language"
              id="language"
              label={t('common:language')}
              enumValues={languages}
            />
          </FormGroup>
          {selectedCrmInfo?.type !== CrmOptionType.Contact && (
            <FormGroup className="mb-3">
              <FormikTextField
                id="companyName"
                name="companyName"
                variant="standard"
                fullWidth
                label={t('common:companyName')}
                type="text"
                required
              />
            </FormGroup>
          )}
          {selectedCrmInfo?.type === CrmOptionType.Contact && (
            <>
              <FormGroup className="mb-3">
                <FormikEnumSelector
                  required
                  name="gender"
                  label={t('common:gender')}
                  enumValues={genders}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Row>
                  <Col>
                    <FormikTextField
                      id="firstName"
                      name="firstName"
                      variant="standard"
                      fullWidth
                      label={t('common:firstName')}
                      type="text"
                    />
                  </Col>
                  <Col>
                    <FormikTextField
                      id="lastName"
                      name="lastName"
                      variant="standard"
                      fullWidth
                      label={t('common:lastName')}
                      type="text"
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
            </>
          )}
          <FormGroup className="mb-3" hidden={!selectedCrmInfo}>
            <FormikIbanInput name="iban" />
          </FormGroup>
          <FormGroup className="mb-3 flex space-x-7">
            <div className="w-1/2">
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Landline}
                id="phoneNumber"
                name="phoneNumber"
                label={t('phoneNumber')}
              />
            </div>
            <div className="w-1/2">
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Mobile}
                id="mobilePhoneNumber"
                name="mobilePhoneNumber"
                label={t('mobilePhoneNumber')}
              />
            </div>
          </FormGroup>
          <FormGroup className="mb-3">
            <FormikEmailInput name="emailAddresses" />
          </FormGroup>
          <FormGroup className="mb-3">
            <Row>
              <Col>
                <FormikEnumSelector name="role" label={t('common:role')} enumValues={caseRoles} />
              </Col>
              <Col>
                <FormikEnumSelector
                  name="involvementType"
                  label={t('common:involvementType')}
                  enumValues={caseInvolvementTypes}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="mb-3">
            {!editMode &&
              selectedCrmInfo?.type === CrmOptionType.Company &&
              companyAddresses != null &&
              companyAddresses.length > 1 && (
                <Autocomplete
                  noOptionsText={t('common:noOptions')}
                  filterSelectedOptions
                  disableCloseOnSelect
                  options={companyAddresses}
                  onChange={prefillAddress}
                  getOptionLabel={(opt) =>
                    getAddressString(opt.addressLine, opt.city, opt.postalCode, opt.country)
                  }
                  renderOption={(props, opt) => (
                    <li {...props}>
                      {getAddressString(opt.addressLine, opt.city, opt.postalCode, opt.country)}
                    </li>
                  )}
                  renderInput={(params) => {
                    // MUI mess fix
                    const saneParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        autoComplete: 'nope',
                      },
                    };
                    return (
                      <TextField
                        label={t('common:selectExistingAddress')}
                        variant="standard"
                        {...saneParams}
                      />
                    );
                  }}
                />
              )}
            <FormikAddressForm />
          </FormGroup>
        </>
      )}
      {editMode && (
        <FormGroup className="mb-3">
          <FormikCheckbox name="pushCrmChanges" label={t('common:pushCrmChanges')} />
        </FormGroup>
      )}
    </>
  );
};

AddCaseRelationFormBody.propTypes = propTypes;

export default AddCaseRelationFormBody;
