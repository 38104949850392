import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormGroup, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import { Button } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isNullOrEmpty, sentencize } from '../../../../../../shared/utils/helpers';
import { actions } from '@/reducers/ui';
import API from '../../../../../../shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import SpreadsheetPropTypes from '../../../../../../shared/prop-types/SpreadsheetPropTypes';
import { SpreadsheetType } from '~/common/enums';
import useNotification from '../../../../../../shared/hooks/UseNotification';

import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import InlineEditInput from '../../../../../../shared/components/inputs/InlineEditInput';
import { SaveIcon } from '../../../../../../shared/icons/Icons';
import SpreadsheetDisplay from '../Components/SpreadsheetDisplay';
import TextCheckbox from '../../../../../../shared/components/checkboxes/TextCheckbox';
import UploadEstimateModal from './UploadEstimateModal';
import UploadOfferModal from './UploadOfferModal';
import useToggle from '@/shared/hooks/UseToggle';

const SubmitOfferOrInvoice = ({
  caseId,
  isCaseOwner,
  isContractor,
  repairEndDate,
  repairStartDate,
  worksiteRegistrationNbr,
  spreadsheets,
  photosRequired,
  isSubmitInvoice,
}) => {
  const { ui, setUi } = useContext(AppContext);

  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const handleSaveWorkSiteRegistrationNumber = async (workSiteRegistrationNumber) => {
    const data = {
      caseId,
      workSiteRegistrationNumber,
    };
    const response = await API.putUpdateWorkSiteRegistrationNumber(data);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', { type: t('worksiteRegistrationNbr') }),
      variant: 'success',
    });
  };

  const handleSaveRequirePhotos = async (requirePhotos) => {
    const data = {
      caseId,
      requirePhotos,
    };
    let response;
    if (isSubmitInvoice) {
      response = await API.putUpdateRequireInvoicePhotos(data);
    } else {
      response = await API.putUpdateRequireEstimatePhotos(data);
    }

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: requirePhotos ? t('photosAreNowRequired') : t('photosAreNoLongerRequired'),
      variant: 'success',
    });
  };

  const handleSaveEndOfWork = async (data) => {
    const response = await API.putUpdateRepairEnd(data);
    if (response.status === 200) {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('endOfWorks') }),
        variant: 'success',
      });
    } else {
      sendDefaultError(response);
    }
  };

  const invoiceToggle = useToggle();
  const estimateToggle = useToggle();

  return (
    <div className="pe-3 ps-3">
      {isSubmitInvoice &&
        (isCaseOwner || isContractor || repairEndDate ? (
          <div>
            <h4 className="pb-3">
              {repairEndDate == null
                ? t('registerEndOfWorks')
                : sentencize(t('editType', { type: t('endOfWorks') }))}
            </h4>
            <Formik
              onSubmit={handleSaveEndOfWork}
              initialValues={{
                caseId,
                repairEndDate,
              }}
              validationSchema={yup.object().shape({
                repairEndDate: yup.string().nullable().required(t('errors:fieldIsRequired')),
              })}
            >
              <Form>
                <FormGroup className="mb-3">
                  <FormikDatePicker
                    className="w-25"
                    name="repairEndDate"
                    label={t('date')}
                    options={{
                      format: 'YYYY-MM-DD',
                      minDate: repairStartDate,
                    }}
                    disabled={!isCaseOwner && !isContractor}
                    required
                  />
                </FormGroup>

                {(isCaseOwner || isContractor) && (
                  <Button startDecorator={<SaveIcon />} type="submit">
                    {repairEndDate == null ? t('register') : t('edit')}
                  </Button>
                )}
              </Form>
            </Formik>
          </div>
        ) : (
          <span className="color-keypoint">{t('awaitingInvoice')}</span>
        ))}
      {((isSubmitInvoice && repairEndDate) || !isSubmitInvoice) && (
        <>
          {(isCaseOwner || isContractor || spreadsheets.length > 0) && (
            <h4 className="pb-3">
              {isSubmitInvoice ? t('deliveredInvoices') : t('deliveredEstimates')}
            </h4>
          )}
          <div className="d-flex flex-wrap pb-3">
            {spreadsheets?.map((e) => (
              <SpreadsheetDisplay key={e.id} spreadsheet={e} />
            ))}
            {spreadsheets?.length === 0 &&
              (isCaseOwner || isContractor ? (
                <span>{t('noDataToDisplay')}</span>
              ) : (
                <span className="color-keypoint">
                  {isSubmitInvoice ? t('awaitingInvoice') : t('awaitingEstimate')}
                </span>
              ))}
          </div>

          {(isContractor || (!isContractor && !isCaseOwner)) && !isSubmitInvoice && (
            <Row>
              <p>
                {t('worksiteRegistrationNbr')}:{' '}
                {isNullOrEmpty(worksiteRegistrationNbr) ? '-' : worksiteRegistrationNbr}
              </p>
            </Row>
          )}

          {isCaseOwner && (
            <>
              <Row className="pb-3">
                <TextCheckbox
                  yesText={t('photosAreRequired')}
                  noText={
                    isSubmitInvoice
                      ? t('invoicesWithoutPhotosAreAllowed')
                      : t('estimatesWithoutPhotosAreAllowed')
                  }
                  isDefaultChecked={photosRequired}
                  callback={handleSaveRequirePhotos}
                />
              </Row>
              {!isSubmitInvoice && (
                <Row>
                  <InlineEditInput
                    initialValue={worksiteRegistrationNbr}
                    label={t('worksiteRegistrationNbr')}
                    onSave={handleSaveWorkSiteRegistrationNumber}
                  />
                </Row>
              )}
            </>
          )}
          {(isCaseOwner || isContractor) && (
            <>
              {isSubmitInvoice ? (
                <div className="flex justify-end space-x-4">
                  <Button onClick={invoiceToggle.show}>{t('invoice')}</Button>
                </div>
              ) : (
                <div className="flex justify-end space-x-4">
                  <Button onClick={estimateToggle.show}>{t('uploadEstimateAndImages')}</Button>

                  <Button onClick={() => setUi({ type: actions.CASE_TOGGLE_UPLOAD_ESTIMATE })}>
                    {t('createEstimateOnline')}
                  </Button>
                </div>
              )}

              {invoiceToggle.value && (
                <UploadOfferModal
                  title={t('invoice')}
                  type={SpreadsheetType.PurchaseInvoice}
                  toggle={invoiceToggle}
                />
              )}

              {estimateToggle.value && (
                <UploadOfferModal title={t('estimate')} toggle={estimateToggle} />
              )}

              {ui.case.toggles.uploadEstimate && <UploadEstimateModal title={t('estimate')} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

SubmitOfferOrInvoice.propTypes = {
  caseId: PropTypes.string.isRequired,
  isCaseOwner: PropTypes.bool.isRequired,
  isContractor: PropTypes.bool.isRequired,
  isSubmitInvoice: PropTypes.bool,
  photosRequired: PropTypes.bool.isRequired,
  repairEndDate: PropTypes.string,
  repairStartDate: PropTypes.string,
  spreadsheets: PropTypes.arrayOf(SpreadsheetPropTypes).isRequired,
  worksiteRegistrationNbr: PropTypes.string,
};

SubmitOfferOrInvoice.defaultProps = {
  isSubmitInvoice: false,
  repairEndDate: null,
  repairStartDate: null,
  worksiteRegistrationNbr: null,
};

export default SubmitOfferOrInvoice;
