import React, { useMemo } from 'react';
import { Tab, TabList, Tabs } from '@mui/joy';
import PropTypes from 'prop-types';
import { TabPanel } from '@mui/base';
import { useTranslation } from 'react-i18next';

import DocumentRelation from '../../enums/DocumentRelation';
import { imageFileTypes } from '@/shared/utils/helpers';

import DocumentTab from './Documents/DocumentTab';
import { FileContext } from '@/FileContext';
import GalleryTab from './Images/GalleryTab';

const Documents = ({ documentRelation, canEdit, canHandleAccess = true, ...rest }) => {
  const { t } = useTranslation('common');
  const { files } = React.useContext(FileContext);

  const galleryKey = 'gallery';
  const documentsKey = 'documents';

  const [key, setKey] = React.useState(documentsKey);

  React.useEffect(() => {
    if (key !== galleryKey) {
      return;
    }
    if (!files.some((f) => imageFileTypes.includes(f.contentType))) {
      setKey(documentsKey);
    }
  }, [files, key]);

  const showGallery = useMemo(
    () => files.some((f) => imageFileTypes.includes(f.contentType)),
    [files],
  );

  return (
    <Tabs>
      <TabList>
        <Tab>{t('documents')}</Tab>
        {showGallery && <Tab>{t('gallery')}</Tab>}
      </TabList>

      <TabPanel>
        <DocumentTab
          documentRelation={documentRelation}
          canHandleAccess={canHandleAccess}
          canEdit={canEdit}
          {...rest}
        />
      </TabPanel>

      <TabPanel>
        <GalleryTab documentRelation={documentRelation} />
      </TabPanel>
    </Tabs>
  );
};

Documents.propTypes = {
  canEdit: PropTypes.bool,
  canHandleAccess: PropTypes.bool,
  documentRelation: PropTypes.oneOf([
    DocumentRelation.Building,
    DocumentRelation.Case,
    DocumentRelation.Contact,
    DocumentRelation.MeetingType,
    DocumentRelation.Policy,
    DocumentRelation.Spreadsheet,
    DocumentRelation.Unit,
    DocumentRelation.Meeting,
  ]).isRequired,
};

Documents.defaultProps = {
  canEdit: true,
};

export default Documents;
