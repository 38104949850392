import PropTypes from 'prop-types';

import PhoneCallOutcome from '../enums/PhoneCallOutcome';

const CaseEntityPropTypes = PropTypes.shape({
  callDate: PropTypes.string,
  calleeRelationId: PropTypes.string,
  callRelationId: PropTypes.string,
  caseId: PropTypes.string,
  caseRelationId: PropTypes.string,
  categoryDescription: PropTypes.string,
  contentType: PropTypes.string,
  created: PropTypes.string,
  createdByTeam: PropTypes.string,
  createdByUser: PropTypes.string,
  description: PropTypes.string,
  endTime: PropTypes.string,
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  id: PropTypes.string,
  isDetailedNote: PropTypes.bool,
  isInbound: PropTypes.bool,
  isPinned: PropTypes.bool,
  linkedCalendarId: PropTypes.string,
  location: PropTypes.string,
  note: PropTypes.string,
  nylasEventId: PropTypes.string,
  outcome: PropTypes.oneOf([
    null,
    PhoneCallOutcome.CallBack,
    PhoneCallOutcome.InvalidNumber,
    PhoneCallOutcome.Success,
    PhoneCallOutcome.Voicemail,
    PhoneCallOutcome.WrongNumber,
  ]),
  read: PropTypes.bool,
  showOnClientPage: PropTypes.bool,
  startTime: PropTypes.string,
  title: PropTypes.string,
  uploadedBy: PropTypes.string,
});

export default CaseEntityPropTypes;
