import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ButtonMenu from './ButtonMenu';
import IconButton from '../buttons/IconButton';
import { makeId } from '../../utils/helpers';

const propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      iconComponent: PropTypes.node,
      iconStyle: PropTypes.string,
      isHidden: PropTypes.bool,
      tooltip: PropTypes.string,
    }),
  ),
  buttonsToShowInline: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  titleAddons: PropTypes.node,
};

const KpcCard = ({
  title,
  titleAddons = null,
  buttons = [],
  buttonsToShowInline = 2,
  children = [],
  ...rest
}) => {
  const visibleButtons = buttons.filter((b) => !b.isHidden);

  return (
    <Card {...rest}>
      <Card.Header as="h4">
        <div className="float-start">
          {title}
          {titleAddons}
        </div>
        <div className="float-end">
          {visibleButtons.length <= buttonsToShowInline ? (
            visibleButtons.map((btn) => (
              <IconButton
                className="mr-2"
                color="keypoint"
                key={makeId(7)}
                onClick={btn.action}
                iconStyle={btn.iconStyle}
                icon={btn.icon}
                iconComponent={btn.iconComponent}
                tooltip={btn.tooltip}
                disabled={btn.disabled}
              />
            ))
          ) : (
            <>
              <IconButton
                color="keypoint"
                onClick={visibleButtons[0].action}
                iconStyle={visibleButtons[0].iconStyle}
                icon={visibleButtons[0].icon}
                iconComponent={visibleButtons[0].iconComponent}
                tooltip={visibleButtons[0].tooltip}
                disabled={visibleButtons[0].disabled}
              />
              <ButtonMenu items={visibleButtons.slice(1)} />
            </>
          )}
        </div>
      </Card.Header>
      <Card.Body className="rounded-b-md bg-white">{children}</Card.Body>
    </Card>
  );
};

KpcCard.propTypes = propTypes;

export default KpcCard;
