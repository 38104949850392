import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@mui/joy';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FileContext } from '../../../../FileContext';

import DeleteAction from '../../DeleteAction';
import DocumentRelation from '../../../enums/DocumentRelation';
import downloadFile from '@/shared/utils/fileHelpers';
import FilePropTypes from '../../../prop-types/FilePropTypes';

import {
  CalendarIcon,
  CancelIcon,
  DownloadIcon,
  EditIcon,
  FileIcon,
  InfoIcon,
  SaveIcon,
  UserIcon,
} from '@/shared/icons/Icons';
import useDayjs from '@/shared/hooks/useDayjs';

const ImageDetails = ({ file, updateFile, documentRelation }) => {
  const { formatDate } = useDayjs();
  const { t } = useTranslation(['common', 'errors']);

  const [isEditing, setIsEditing] = useState(false);
  const [updatedFile, setUpdatedFile] = useState(file);

  useEffect(() => setUpdatedFile(file), [file]);
  const { deleteFile, currentTeamCanDeleteOverride } = React.useContext(FileContext);

  const onCancel = () => {
    setIsEditing(false);
    setUpdatedFile(file);
  };

  const onUpdate = () => {
    if (updatedFile.fileName) {
      updateFile(updatedFile);
      setIsEditing(false);
      setUpdatedFile(file);
    }
  };

  return (
    <>
      <div className="flex items-center justify-end space-x-2">
        <Tooltip title={t('common:download')}>
          <IconButton variant="plain" onClick={() => downloadFile(file)}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>

        {isEditing && !file.isReadonly && (
          <>
            <Tooltip title={t('common:save')}>
              <IconButton variant="plain" onClick={onUpdate}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('common:cancel')}>
              <IconButton variant="plain" onClick={onCancel}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {!isEditing && !file.readOnly && (
          <Tooltip title={t('common:edit')}>
            <IconButton variant="plain" onClick={() => setIsEditing(true)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {!isEditing && (!file.readOnly || currentTeamCanDeleteOverride) && (
          <DeleteAction onDelete={() => deleteFile(file.id)} />
        )}
      </div>
      <div className="flex flex-row space-x-8">
        <Typography
          startDecorator={
            <Tooltip title={t('common:addedBy')}>
              <i>
                <UserIcon />
              </i>
            </Tooltip>
          }
        >
          {file.uploadedBy}
        </Typography>
        <Typography
          startDecorator={
            <Tooltip title={t('common:addedOn')}>
              <i>
                <CalendarIcon />
              </i>
            </Tooltip>
          }
        >
          {formatDate(file.uploadDate)}
        </Typography>

        {isEditing ? (
          <FormControl>
            <FormLabel>{t('common:fileName')}</FormLabel>
            <Input
              startDecorator={<FileIcon />}
              value={updatedFile.fileName}
              onChange={({ target }) => setUpdatedFile({ ...updatedFile, fileName: target.value })}
            />
            {!updatedFile.fileName && (
              <FormHelperText color="danger">{t('errors:fieldIsRequired')}</FormHelperText>
            )}
          </FormControl>
        ) : (
          <Typography
            startDecorator={
              <Tooltip title={t('common:fileName')}>
                <i>
                  <FileIcon />
                </i>
              </Tooltip>
            }
          >
            {file.fileName}
          </Typography>
        )}

        {documentRelation === DocumentRelation.Case && isEditing ? (
          <FormControl>
            <FormLabel>{t('common:description')}</FormLabel>
            <Input
              startDecorator={<InfoIcon />}
              value={file.categoryDescription}
              onChange={({ target }) =>
                setUpdatedFile({ ...updatedFile, categoryDescription: target.value })
              }
            />
          </FormControl>
        ) : (
          <Typography
            startDecorator={
              <Tooltip title={t('common:description')}>
                <i>
                  <InfoIcon />
                </i>
              </Tooltip>
            }
          >
            {file.categoryDescription ?? '-'}
          </Typography>
        )}
      </div>
    </>
  );
};

ImageDetails.propTypes = {
  documentRelation: PropTypes.oneOf([
    DocumentRelation.Case,
    DocumentRelation.Building,
    DocumentRelation.Unit,
    DocumentRelation.Policy,
    DocumentRelation.Meeting,
  ]).isRequired,
  file: FilePropTypes.isRequired,
  updateFile: PropTypes.func.isRequired,
};

export default ImageDetails;
