import { useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

import SelectCaseRelation from '../select/SelectCaseRelation';

const FormikCaseRelationSelector = ({ name, label, selectSingle, isRequired, ...rest }) => {
  const { setFieldValue, errors, touched, setTouched, values } = useFormikContext();

  const handleCallback = React.useCallback(
    (relations) => {
      if (relations.length > 0) {
        if (selectSingle) {
          setFieldValue(name, relations[0]);
          return;
        }
        setFieldValue(name, relations);
        return;
      }
      setFieldValue(name, null);
    },
    [name, selectSingle, setFieldValue],
  );
  return (
    <SelectCaseRelation
      callback={handleCallback}
      selectSingle={selectSingle}
      label={isRequired ? `${label} *` : label}
      errorText={touched[name] ? errors[name] : null}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      initialValues={selectSingle ? null : values[name]}
      defaultValue={selectSingle ? values[name] : null}
      {...rest}
    />
  );
};

FormikCaseRelationSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectSingle: PropTypes.bool,
  isRequired: PropTypes.bool,
};

FormikCaseRelationSelector.defaultProps = {
  selectSingle: false,
  isRequired: false,
};

export default FormikCaseRelationSelector;
